import { useDispatch } from "react-redux";
import { ErrorDialog } from "../../../../components/errorDialog"
import { useAppSelector } from "../../../../store/configureStore"
import { ErrorDisplayType } from "../../../../store/factory/sagaWithProgress/types";

import { dismissFirstError } from "../store/featureActions/dismissFirstError";
import { getNextError } from "../store/selectors/queue";
import { Toast } from 'primereact/toast';
import { useRef } from "react";
import { useEffect } from "react";
import { isValue } from "../../../../utils/valueHelper";
import { messageFromErrorContent } from "../../../errorHandling/errorMessageHelper";
import { useCallback } from "react";
import './index.css';

export const GlobalErrorQueue: React.FC = () => {

    const dispatch = useDispatch();
    const toast = useRef<Toast>(null);
    const nextError = useAppSelector(getNextError);

    const onDismiss = useCallback(() => {
        dispatch(dismissFirstError());
    }, [dispatch]);

    useEffect(() => {
        if (!isValue(toast.current)) return;
        if (!isValue(nextError)) return;

        if (nextError.errorDisplayType === ErrorDisplayType.Toast) {
            const messageDetail = messageFromErrorContent(nextError.error);
            toast.current.show({ severity: "error", summary: nextError.title ?? undefined, detail: messageDetail, sticky: true })
            onDismiss();
        }

    }, [nextError, onDismiss]);

    const dialogError = isValue(nextError) && nextError.errorDisplayType === ErrorDisplayType.Dialog ? nextError.error : undefined;

    return <div>
        <Toast ref={toast} />
        <ErrorDialog
            error={dialogError}
            title={nextError?.title ?? "Error"}
            dismissAction={onDismiss}
            actionTitle="Ok"
            className="global-error-dialog"
        />
    </div>
}