import { useDispatch } from "react-redux";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { QualityMetricKind } from "../../models/metricKind";
import { qualityMetricKindValidationSchema } from "../../validations/qualityMetricKindValidations";
import { addQualityMetricKindCancel, addQualityMetricKindComplete } from "../store/featureActions/addQualityMetricKind";
import { getAddQualityMetricKindState, getAddQualityMetricKindValue, getAddQualityMetricKindVisible } from "../store/selectors/editing";
import { AddQualityMetricKindEditor } from "./addQualityMetricKindEditor";

export const AddQualityMetricKindDialog: React.FC = () => {

    const dispatch = useDispatch();

    const onSave = (values: QualityMetricKind): void => {
        dispatch(addQualityMetricKindComplete(values));
    };

    const onCancel = (): void => {
        dispatch(addQualityMetricKindCancel());
    }

    return <FormikModalEditor<QualityMetricKind>
        statusSelector={getAddQualityMetricKindState}
        valueSelector={getAddQualityMetricKindValue}
        visibleSelector={getAddQualityMetricKindVisible}
        onSave={onSave}
        onCancel={onCancel}
        validationSchema={qualityMetricKindValidationSchema}        
        title="Add Quality Metric Kind"
    >
        <AddQualityMetricKindEditor />
    </FormikModalEditor>
}