import { ColumnProps } from "primereact/column";
import { InputSwitch, InputSwitchChangeParams } from 'primereact/inputswitch';
import React, { CSSProperties } from 'react';
import { BooleanOrNullKeyOf, getBooleanOrNullValue, PropertiesOfType } from "../typeHelpers";

interface SwitchTemplateProps<T> {
    getValueFn: (rowData: T) => boolean | null | undefined;
    onChange?: (value: T, checked: boolean) => void;
    disabledFn?: (value: T) => boolean;
}
const createSwitchTemplate = <T,>({ getValueFn, onChange, disabledFn }: React.PropsWithChildren<SwitchTemplateProps<T>>) => {
    return (rowData: T) => {
        const value = getValueFn(rowData);
        const disabled = !!disabledFn ? disabledFn(rowData) : false;

        const onChangeHandler = !!onChange
            ? (e: InputSwitchChangeParams) => onChange(rowData, e.value)
            : undefined;

        return value !== undefined && value !== null
            ? <InputSwitch checked={value} onChange={onChangeHandler} disabled={disabled} />
            : <InputSwitch checked={false} />
    }
};

export interface CreateSwitchColumnOptions<T extends {}> extends Omit<ColumnProps, "field" | "header" | "body" | "style"> {
    field: BooleanOrNullKeyOf<T> & string,
    header: string,
    disabledFn?: (value: T) => boolean,
    style?: CSSProperties;
    onChange?: (value: T, checked: boolean) => void,
    customTemplateFn?: (value: T) => React.ReactNode
}

export function createSwitchColumnProps<T extends PropertiesOfType<T, boolean | null | undefined>>(
    props: CreateSwitchColumnOptions<T>): ColumnProps {

    const getValueFn = (rowData: T) => getBooleanOrNullValue(rowData, props.field);
    const bodyTemplate = props.customTemplateFn ?? createSwitchTemplate({ getValueFn: getValueFn, disabledFn: props.disabledFn, onChange: props.onChange });

    return { columnKey: props.columnKey ?? props.field, ...props, body: bodyTemplate };
}