import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { createAddMenuSaga } from "../featureActions/addMenu";
import { createDeleteMenuSaga } from "../featureActions/deleteMenu";
import { createLoadSliceDataSaga } from "../featureActions/loadSliceData";

// Register this method in the root saga
export function createMenuAdminDashboardSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        createLoadSliceDataSaga(context),
        createAddMenuSaga(context),
        createDeleteMenuSaga(context)
    ]
}