import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { FullyCentered } from "../../../components/containers/fullyCentered";
import { useAppSelector } from "../../../store/configureStore";
import { useMountEffect } from "../../../utils/useEffectHelper";
import { isValue } from "../../../utils/valueHelper";
import { homeRoute } from "../../navigation/routes";
import { isAuthRoutePath } from "../routes/authRoutes";
import { completeLogin } from "../store/featureActions/completeLogin";
import { getAuthenticationState } from "../store/selectors/authState";
import { getIsLoggedIn, getIsLoggingIn, getIsSignInComplete } from "../store/selectors/signIn";
import { postLoginRedirectUriStorageKey } from "../store/types";

export const CompleteSignIn = () => {

    const dispatch = useDispatch();
    const completeLoginState = useAppSelector(getAuthenticationState);
    const isLoggingIn = useAppSelector(getIsLoggingIn);
    const isLoggedIn = useAppSelector(getIsLoggedIn)
    const isSignInComplete = useAppSelector(getIsSignInComplete)

    useMountEffect(() => {        
        if (!isValue(completeLoginState.ErrorMessage)) {
            dispatch(completeLogin());
        }
    });

    const postLoginRedirect = localStorage.getItem(postLoginRedirectUriStorageKey);    
    const redirectTo = isValue(postLoginRedirect) && !isAuthRoutePath(postLoginRedirect) ? postLoginRedirect : homeRoute.path;

    return isLoggingIn
        ? <FullyCentered inlineStyle={{ fontSize: "28px", height: "calc(90vh - 60px)" }}>
            Authenticating...
        </FullyCentered>
        : isValue(completeLoginState.ErrorMessage)
            ? <div>An error occured during authentication</div>
            : isSignInComplete && isLoggedIn
                ? <Redirect to={redirectTo} />
                : <div>An error occured during authentication</div>
}