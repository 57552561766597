import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, TermsOfServiceAdminState } from "../types";

export const setInactiveFilter = actionFactory.createAppAction<boolean[]>("setInactiveFilter");

export function setInactiveFilterReducers(reducerFactory: IReducerFactory<TermsOfServiceAdminState>): IReducerFactory<TermsOfServiceAdminState> {
    return reducerFactory
        .forAction(setInactiveFilter)
        .addHandler((state, action) => {
            state.termsFilter.inactiveFilterValues = action.payload;            
        })
        .build();
}