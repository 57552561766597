import { Dialog } from "primereact/dialog";
import React from "react";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { getCategoryForProductAssignment } from "../store/selectors/productAssignment";
import { CategoryProductAssignment } from "./categoryProductAssignment";

export const CategoryProductAssignmentDialog: React.FC = () => {

    const categoryForProductAssignment = useAppSelector(getCategoryForProductAssignment);

    const onHideAssignProductsDialog = () => {};

    return <div>
        <Dialog
            onHide={onHideAssignProductsDialog}
            visible={isValue(categoryForProductAssignment)}
            closable={false}
            draggable={false}
            header={`Assign Items to Category: ${categoryForProductAssignment?.name}`}
        >
            <CategoryProductAssignment />
        </Dialog>
    </div>
}