import React from "react";
import { ActionStatus } from "../../features/actionStatus/actionStatus"
import { useAppSelector } from "../../store/configureStore"
import { RootState } from "../../store/rootReducer"
import { WaitDisplay } from "../waitDisplay";

export interface LoadingContainerProps {
    actionStatusSelector: (state: RootState) => ActionStatus
};

export const LoadingContainer: React.FC<LoadingContainerProps> = ({actionStatusSelector, children}) => {

    const actionStatus = useAppSelector(actionStatusSelector);

    return actionStatus.isExecuting
            ? <WaitDisplay />
            : <div>{children}</div>
}