import ApiClient from "../../apiCommunication/apiClient";
import { createDeserializeUtcToLocalDateFns } from "../../apiCommunication/serialization";
import { SerializationBuilder } from "../../apiCommunication/serializationBuilder";
import { Contact, Customer, CustomerNote, NewCustomer, SalesOrder, SalesOrderItem, SalesOrderWithDetail, UpdateSalesOrder } from "../common/types";

export const { single: processCustomer, set: processCustomers } = createDeserializeUtcToLocalDateFns<Customer>(["createdUtc", "modifiedUtc"]);
export const { single: processCustomerNote, set: processCustomerNotes } = createDeserializeUtcToLocalDateFns<CustomerNote>(["createdUtc", "modifiedUtc"]);

export const salesOrderItemSerializer = SerializationBuilder
    .forType<SalesOrderItem>()
    .parseUtcDatesToLocalTime(["createdUtc", "modifiedUtc"])
    .build();

export const salesOrderSerializer = SerializationBuilder
    .forType<SalesOrder>()
    .parseUtcDatesToLocalTime(["createdUtc", "modifiedUtc"])   
    .build();


export default class CustomerApi {

    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    getCustomers = (): Promise<Customer[]> => {
        return this
            .apiClient
            .executeGet<Customer[]>(`customer`)
            .then(processCustomers);
    };

    getCustomerById = (id: number): Promise<Customer> => {
        return this
            .apiClient
            .executeGet<Customer>(`customer/${id}`)
            .then(processCustomer);
    };

    getCustomerForOrderId = (orderId: number): Promise<Customer> => {
        return this
            .apiClient
            .executeGet<Customer>(`customer/forOrder/${orderId}`)
            .then(processCustomer);
    };    

    upsertCustomer = (customer: Customer | NewCustomer): Promise<Customer> => {
        return this
            .apiClient
            .executePostWithResult<Customer>(`customer`, customer)
            .then(processCustomer);
    };

    getCustomerNotes = (customerId: number): Promise<CustomerNote[]> => {
        return this
            .apiClient
            .executeGet<CustomerNote[]>(`customer/${customerId}/notes`)
            .then(processCustomerNotes);
    };

    upsertCustomerNote = (customerNote: CustomerNote): Promise<CustomerNote> => {
        return this
            .apiClient
            .executePostWithResult<CustomerNote>(`customer/notes`, customerNote)
            .then(processCustomerNote);
    };

    deleteCustomerNote = (customerNote: CustomerNote): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`customer/notes/${customerNote.id}`);
    };



    getCustomerContacts = (customerId: number): Promise<Contact[]> => {
        return this
            .apiClient
            .executeGet<Contact[]>(`customer/${customerId}/contacts`);
    };

    upsertCustomerContact = (contact: Contact): Promise<Contact> => {
        return this
            .apiClient
            .executePostWithResult<Contact>(`customer/contacts`, contact);
    };

    deleteCustomerContact = (contact: Contact): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`customer/contacts/${contact.id}`);
    };

    getCustomerOrders = (customerId: number): Promise<SalesOrderWithDetail[]> => {
        return this
            .apiClient
            .executeGet<SalesOrderWithDetail[]>(`customer/${customerId}/orders`)
            .then(dtos => {

                dtos.forEach(dto => {
                    dto.salesOrder = salesOrderSerializer.serializeSingle(dto.salesOrder);
                    dto.salesOrderItems = salesOrderItemSerializer.serializeSet(dto.salesOrderItems);
                });
                
                return dtos;
            });
    };

    createCustomerOrder = (customerId: number): Promise<SalesOrder> => {
        return this
            .apiClient
            .executePostWithResult<SalesOrder>(`customer/${customerId}/orders`)
            .then(salesOrderSerializer.serializeSingle);
    };

    getOrder = (orderId: number): Promise<SalesOrderWithDetail> => {
        return this
            .apiClient
            .executeGet<SalesOrderWithDetail>(`customer/orders/${orderId}`)
            .then(dto => {
                dto.salesOrder = salesOrderSerializer.serializeSingle(dto.salesOrder);
                dto.salesOrderItems = salesOrderItemSerializer.serializeSet(dto.salesOrderItems);
                return dto;
            });
    };

    
    upsertOrderItem = (salesOrderItem: SalesOrderItem): Promise<SalesOrderItem> => {
        return this
            .apiClient
            .executePostWithResult<SalesOrderItem>(`customer/orders/items`, salesOrderItem)
            .then(salesOrderItemSerializer.serializeSingle);
    };

    deleteOrderItem = (salesOrderItemId: number): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`customer/orders/items/${salesOrderItemId}`);
    };

    updateOrder = (update: UpdateSalesOrder): Promise<SalesOrder> => {
        return this
            .apiClient
            .executePostWithResult<SalesOrder>(`customer/orders`, update)
            .then(salesOrderSerializer.serializeSingle);
    };

}