import { Load6Result, parallelLoad } from "../../../../../utils/promiseHelpers";
import { AssessmentTemplate } from "../../../../qualityGradeTemplates/models/assessmentTemplate";
import { QualityGradeKind } from "../../../../qualityGradeTemplates/models/gradeKind";
import { QualityGradeValue } from "../../../../qualityGradeTemplates/models/gradeValue";
import { QualityMetricKind } from "../../../../qualityGradeTemplates/models/metricKind";
import { QualityMetricValue } from "../../../../qualityGradeTemplates/models/metricValue";
import { TemplateMetric } from "../../../../qualityGradeTemplates/models/templateMetric";
import { qualityGradeKindAdapter, qualityGradeValueAdapter, qualityMetricKindAdapter, qualityMetricValueAdapter, templateAdapter, templateMetricAdapter } from "../state";
import { actionFactory } from "../types";

type LoadResult = Load6Result<
    QualityGradeKind[] | null, 
    QualityGradeValue[] | null, 
    QualityMetricKind[] | null, 
    QualityMetricValue[] | null,
    TemplateMetric[] | null,
    AssessmentTemplate[] | null>;

export const {

    loadReferenceData,
    loadReferenceDataReducer,
    loadReferenceDataSagaFactory

} = actionFactory
    .withMappedName("loadReferenceData")
    .createAsyncAction<void, LoadResult>({
        actionStatusSelector: state => state.loadReferenceDataState,
        asyncCall: (_arg, context) => {

            const sliceState = context.getState().qualityAssessmentEditor;

            const loadGradeKinds = sliceState.gradeKinds.ids.length === 0;
            const loadGradeValues = sliceState.gradeValues.ids.length === 0;
            const loadMetricKinds = sliceState.metricKinds.ids.length === 0;
            const loadMetricValues = sliceState.metricValues.ids.length === 0;
            const loadTemplateMetrics = sliceState.templateMetrics.ids.length === 0;
            const loadTemplates = sliceState.templates.ids.length === 0;

            return parallelLoad(
                () => loadGradeKinds ? context.marketplaceApiClient.quality.getQualityGradeKinds() : Promise.resolve(null),
                () => loadGradeValues ? context.marketplaceApiClient.quality.getQualityGradeValues() : Promise.resolve(null),
                () => loadMetricKinds ? context.marketplaceApiClient.quality.getQualityMetricKinds() : Promise.resolve(null),
                () => loadMetricValues ? context.marketplaceApiClient.quality.getQualityMetricValues() : Promise.resolve(null),
                () => loadTemplateMetrics ? context.marketplaceApiClient.quality.getAllTemplateMetrics() : Promise.resolve(null),
                () => loadTemplates ? context.marketplaceApiClient.quality.getAssessmentTemplates() : Promise.resolve(null)
            );
        },
        onFulfilled: (state, action) => {
            if (action.payload.result.result1 !== null) {
                qualityGradeKindAdapter.setAll(state.gradeKinds, action.payload.result.result1);
            }
            if (action.payload.result.result2 !== null) {
                qualityGradeValueAdapter.setAll(state.gradeValues, action.payload.result.result2);
            }
            if (action.payload.result.result3 !== null) {
                qualityMetricKindAdapter.setAll(state.metricKinds, action.payload.result.result3);
            }
            if (action.payload.result.result4 !== null) {
                qualityMetricValueAdapter.setAll(state.metricValues, action.payload.result.result4);
            } 
            if (action.payload.result.result5 !== null) {
                templateMetricAdapter.setAll(state.templateMetrics, action.payload.result.result5);
            } 
            if (action.payload.result.result6 !== null) {
                templateAdapter.setAll(state.templates, action.payload.result.result6);
            }            
        }
    });