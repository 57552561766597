import { EntityVideo } from "../../../../../commonModels/entityVideo";
import { productVideoAdapter } from "../../state";
import { actionFactory } from "../../types";

export const {

    deleteProductVideo,
    deleteProductVideoReducer,
    deleteProductVideoSagaFactory

} = actionFactory
    .withMappedName("deleteProductVideo")
    .createAsyncAction<EntityVideo, Response>({
        actionStatusSelector: state => state.mediaLoadState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.productManagement.deleteProductVideo(arg);
        },
        onFulfilled: (state, action) => {
            productVideoAdapter.removeOne(state.editVideos, action.payload.arg.id);
        }
    });