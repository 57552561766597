import { useDispatch } from "react-redux";
import { cardThumbnailSize } from "../../constants";
import { deleteNewThumbnail } from "../store/featureActions/images/deleteNewThumbnail";
import { deleteThumbnail } from "../store/featureActions/images/deleteThumbnail";
import { saveThumbnail } from "../store/featureActions/images/saveThumbnail";
import { setNewThumbnail } from "../store/featureActions/images/setNewThumbnail";
import { getDefaultThumbnail, getLoadThumbnailState, getNewThumbnail, getSaveThumbnailState } from "../store/selectors/thumbnail";
import { ImageSelector } from "./imageSelector";

export const ThumbnailSetup: React.FC = () => {

    const dispatch = useDispatch();

    const onSelectThumbnail = (fileUrl: string) => {
        dispatch(setNewThumbnail(fileUrl));
    }

    const onReplaceThumbnail = () => {
        dispatch(saveThumbnail());
    }

    const onDeleteThumbnail = () => {
        dispatch(deleteThumbnail());
    }

    const onDeleteNewThumbnail = () => {
        dispatch(deleteNewThumbnail());
    }

    return <div className="p-mt-2">

        <ImageSelector
            currentImageSelector={getDefaultThumbnail}
            newImageSelector={getNewThumbnail}
            saveStateSelector={getSaveThumbnailState}
            loadStateSelector={getLoadThumbnailState}
            title="Default Thumbnail"
            onNewImageSet={onSelectThumbnail}
            onReplaceImage={onReplaceThumbnail}
            onDeleteCurrentImage={onDeleteThumbnail}
            onDeleteNewImage={onDeleteNewThumbnail}
            imageSize={{ width:`${cardThumbnailSize.width}px`, height:`${cardThumbnailSize.height}px` }}
            imageContainerSize={{ width:`${cardThumbnailSize.width + 100}px`, height:`${cardThumbnailSize.height + 100 }px`}}
        />
    </div>
}