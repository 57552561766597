import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, StrictEffect, takeLatest } from "redux-saga/effects";
import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { ErrorDisplayType } from "../../../../../../store/factory/sagaWithProgress/types";
import { SagaAppContext } from "../../../../../../store/rootSaga";
import { enqueueError } from "../../../../../dialogs/error/store/featureActions/enqueueErrorAction";
import { getErrorResult } from "../../../../../errorHandling/errorMessageHelper";
import { assignCategories } from "../../../../categoryAssignment/store/featureActions/categoryAssignment/assignCategories";
import { actionFactory, Product, ProductManagementState } from "../../types";


export const beginCategoryAssignment = actionFactory.createAppAction<Product>("beginCategoryAssignment");

export function beginCategoryAssignmentReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forAction(beginCategoryAssignment)
        .addHandler((state, action) => {
            state.categoryAssignmentState.parentModel = action.payload;
        })
        .build();
}

async function loadCurrentAssignments(arg: Product, context: SagaAppContext): Promise<number[]> {
    return context.marketplaceApiClient.productManagement.getCategoryIdsForProduct(arg.id);
}

function* workflow(context: SagaAppContext, action: PayloadAction<Product>): Generator<StrictEffect, void, number[]> {
    try {

        const result = yield call(loadCurrentAssignments, action.payload, context);
        yield put(assignCategories(result));
    }
    catch (error) {
        const errorResult = getErrorResult(error);        
        yield put(enqueueError({ error: errorResult, title: "An error occured while loading category assignments", errorDisplayType: ErrorDisplayType.Dialog }));

    }
};

export function createBeginCategoryAssignmentSaga(context: SagaAppContext) {
    return function* watchRequests(): Generator<StrictEffect, void, void> {
        yield takeLatest(beginCategoryAssignment, workflow, context);
    }
};