import { Load2Result, parallelLoad } from "../../../../../utils/promiseHelpers";
import { EntityImage } from "../../../../commonModels/entityImage";
import { InventoryView } from "../../../endUser/store/types";
import { actionFactory } from "../types";

export const {

    loadInventoryDetails,
    loadInventoryDetailsReducer,
    loadInventoryDetailsSagaFactory

} = actionFactory
    .withMappedName("loadInventoryDetails")
    .createAsyncAction<{ vendorName: string, menuName: string,  inventoryId: number }, Load2Result<InventoryView, EntityImage | null>>({
        actionStatusSelector: state => state.inventoryLoadingState,
        asyncCall: (arg, context) => {
            return parallelLoad(
                () => context.marketplaceApiClient.liveMenu.getInventory(arg.vendorName, arg.menuName, arg.inventoryId),
                () => context.marketplaceApiClient.liveMenu.getInventoryThumbnail(arg.inventoryId)
            );
        },
        onFulfilled: (state, action) => {
            state.inventoryView = action.payload.result.result1;
            state.thumbnailImage = action.payload.result.result2;
        }
    });