import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { PageBreak } from "../../../../components/printing/pageBreak";
import { useAppSelector } from "../../../../store/configureStore";
import { getVendorMenuSections } from "../store/selectors/vendorMenu";
import { PrintableMenuSection } from "./printableMenuSection";

export const PrintableSectionList: React.FC = () => {

    const menuSections = useAppSelector(getVendorMenuSections);

    return <FlexColumnContainer gap="20px">
        {
            menuSections.map(section => <div>
                <PrintableMenuSection key={section.id} sectionId={section.id} />
                <PageBreak />
            </div>)
        }

    </FlexColumnContainer>

}