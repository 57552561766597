import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createCategoryProductAssignmentInitialState } from "../state";
import { actionFactory, CategoryManagementState } from "../types";

export const cancelAssignProductsToCategory = actionFactory.createAppAction("cancelAssignProductsToCategory");

export function cancelAssignProductsToCategoryReducers(reducerFactory: IReducerFactory<CategoryManagementState>): IReducerFactory<CategoryManagementState> {
    return reducerFactory
        .forAction(cancelAssignProductsToCategory)
        .addHandler((state, _) => {          
            state.categoryProductAssignment = createCategoryProductAssignmentInitialState();
        })
        .build();
}