import { PricingModel } from "../../../sharedTypes";
import { pricingModelAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    loadSliceData,
    loadSliceDataReducer,
    loadSliceDataSagaFactory

} = actionFactory
    .withMappedName("loadSliceData")
    .createAsyncAction<void, PricingModel[]>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (_arg, context) => {
            return context.marketplaceApiClient.pricing.getPricingModels();
        },
        onFulfilled: (state, action) => {
            pricingModelAdapter.setAll(state.pricingModels, action.payload.result);
        }
    });