import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../store/factory/reducerFactory';
import { hideSidePanelReducer } from './featureActions/hideSidePanel';
import { setUseOverlaySideMenuReducer } from './featureActions/setOverlaySidemenuState';
import { toggleSidePanelVisibleReducer } from './featureActions/toggleSidePanelVisible';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(toggleSidePanelVisibleReducer)
            .add(hideSidePanelReducer)
            .add(setUseOverlaySideMenuReducer);            
    }
});

export default slice.reducer;

export const { resetState } = slice.actions;