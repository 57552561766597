import clsx from 'clsx';
import React from "react";
import { Switch } from 'react-router-dom';
import { NotAuthorized } from '../../../components/notAuthorized';
import { useAppSelector } from "../../../store/configureStore";
import { getIsAuthenticated } from '../../authentication/store/selectors/isAuthenticated';
import { getUserIsMarketplaceUser, getUserRoles } from '../../authentication/store/selectors/userRoles';
import { getNotAuthorizedState } from '../../authorization/notAuthorized/store/selectors';
import { appRoutes } from '../../navigation/routes';
import { mapRouteModelToReactRoute } from '../../navigation/routes/helper';
import { getUseOverlaySideMenu } from "../store/selectors/sideMenu";

const AppContentPanel: React.FC = () => {

  const userRoles = useAppSelector(getUserRoles);
  const isLoggedIn = useAppSelector(getIsAuthenticated);  
  const isMarketplaceUser = useAppSelector(getUserIsMarketplaceUser);
  const useOverlaySideMenu = useAppSelector(getUseOverlaySideMenu);
  const sideMenuHidden = !isLoggedIn || !isMarketplaceUser || useOverlaySideMenu;
  const notAuthorizedState = useAppSelector(getNotAuthorizedState);

  const mapRoute = mapRouteModelToReactRoute(userRoles);

  return <div
    className={clsx({
      "app-content-panel": sideMenuHidden,
      "app-content-panel-with-side-menu": !sideMenuHidden
    })}
  >

    {notAuthorizedState.isNotAuthorized
        ? <NotAuthorized />
        : <Switch>
          {appRoutes.map(mapRoute)}
        </Switch>}
  </div>

}

export default AppContentPanel;
