
export enum ImageKind {
    Logo = "Logo",
    Banner = "Banner",
    ImageUnavailable = "ImageUnavailable"
}

export interface ImageContent {
    imageData: string | null;
    mimeType: string;
}

export interface ImageDto extends ImageContent {
    menuId: number;    
    imageType: ImageKind | null;
}
