import { TabPanel, TabView } from "primereact/tabview";
import React from "react";
import { useDispatch } from "react-redux";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { useAppSelector } from "../../../../store/configureStore";
import { loadInventoryLicenses } from "../store/featureActions/licenses/loadInventoryLicenses";
import { loadUnitOfMeasures } from "../store/featureActions/unitOfMeasures/loadUnitOfMeasures";
import { AddMarketingDetails } from "../../general/ui/addMarketingDetails";
import { addInventoryCancel, addInventoryComplete } from "../store/featureActions/InventoryDetails/addInventory";
import { getAddInventoryState, getAddInventoryValue, getAddInventoryVisible} from "../store/selectors/addedInventory";
import { getQualityGradeKinds, getQualityGradeValues } from "../store/selectors/qualityGrade";
import { Inventory } from "../store/types";
import { addMarketingDetailsValidations } from "../../general/validations";
import { getInventoryLicenses } from "../store/selectors/inventoryLicenses";
import { getUnitOfMeasures } from "../store/selectors/unitOfMeasures";

export const AddInventoryDialog: React.FC = () => {

    const dispatch = useDispatch();
    const licenses = useAppSelector(getInventoryLicenses);
    const unitOfMeasures = useAppSelector(getUnitOfMeasures);
    const qualityGrades = useAppSelector(getQualityGradeValues);
    const qualityGradeKinds = useAppSelector(getQualityGradeKinds);

    const onCancel = () => { dispatch(addInventoryCancel()); };
    const onSave = (value: Inventory) => { dispatch(addInventoryComplete(value)); };

    useMountEffect(() => {
        dispatch(loadInventoryLicenses());
        dispatch(loadUnitOfMeasures());
    });

    return <FormikModalEditor<Inventory>
        onCancel={onCancel}
        onSave={onSave}
        statusSelector={getAddInventoryState}
        valueSelector={getAddInventoryValue}
        visibleSelector={getAddInventoryVisible}
        position="top"
        title="Add new package"
        validationSchema={addMarketingDetailsValidations}
    >
        <TabView>
            <TabPanel header="Details">
                    <AddMarketingDetails
                        subTitle=""
                        licenses={licenses}
                        unitOfMeasures={unitOfMeasures}
                        showPrice={true}
                        qualityGrades={qualityGrades}
                        qualityGradeKinds={qualityGradeKinds}
                    />
            </TabPanel>
        </TabView>
    </FormikModalEditor>
}