import { isValue } from "../../utils/valueHelper";
import { cloneAndRemoveTimeZone, cloneAndTransformDatesToUtc, cloneAndTransformAllDatesToUtcSingle, convertUtcToLocal, deserializeLocalDateTime, deserializeUtcDateTime, NullableDateProperties, cloneAndRemoveAllTimeZones } from "./dateSerialization";

export type SerializationTarget<T> = NullableDateProperties<T> & {};

export interface ISerializer<T> {
    serializeSet(value: T[]): T[];
    serializeSingle(value: T): T;
}

class Serializer<T> implements ISerializer<T> {

    private transforms: ((value: T) => T)[] = [];

    constructor(transforms: ((value: T) => T)[]) {
        this.transforms = transforms;
    }

    public serializeSet = (values: T[]): T[] => {        
        return values.map(this.serializeSingle);
    }

    public serializeSingle = (value: T): T => {
        return this
            .transforms
            .reduce<T>((acc, transform, _index) => {
                return transform(acc);
            }, value);
    }
}

export class SerializationBuilder<TItem extends SerializationTarget<TItem>> {

    private transforms: ((value: TItem) => TItem)[] = [];

    private constructor() {}

    public static forType = <TItem extends SerializationTarget<TItem>>() => {
        return new SerializationBuilder<TItem>();
    }

    public addTransform = (transform: (value: TItem) => TItem) => {
        this.transforms.push(transform);
        return this;
    }

    public parseLocalDates = (properties: (keyof NullableDateProperties<TItem>)[]) => {

        this.transforms.push((model: TItem) => {
            deserializeLocalDateTime(model, properties);
            return model;
        });

        return this;
    }

    public parseUtcDatesToLocalTime = (properties: (keyof NullableDateProperties<TItem>)[]) => {

        this.transforms.push((model: TItem) => {
            deserializeUtcDateTime(model, properties);
            convertUtcToLocal(model, properties);
            return model;
        });

        return this;
    }

    public transformDatesToUtc = (properties?: (keyof NullableDateProperties<TItem>)[]) => {

        if (isValue(properties)) {
            this.transforms.push((model: TItem) => {
                return cloneAndTransformDatesToUtc(model, properties);            
            });
        }
        else {
            this.transforms.push((model: TItem) => {
                return cloneAndTransformAllDatesToUtcSingle(model);            
            })
        }        

        return this;
    }

    public removeTimeZone = (properties?: (keyof NullableDateProperties<TItem>)[]) => {

        if (isValue(properties)) {
            this.transforms.push((model: TItem) => {
                return cloneAndRemoveTimeZone(model, properties);            
            });
        }
        else {
            this.transforms.push((model: TItem) => {
                return cloneAndRemoveAllTimeZones(model);            
            })
        }        

        return this;
    }

    public build = (): ISerializer<TItem> => {
        return new Serializer<TItem>(this.transforms);
    }
}