import Bowser from 'bowser';
import React from "react";
import { useDispatch } from 'react-redux';
import { useMountEffect } from '../../utils/useEffectHelper';
import { loadExistingAuth } from '../authentication/store/featureActions/loadExistingAuth';
import { AppDialogs } from '../dialogs';
import { InitialSetupWorkflow } from '../initialSetup/workflow/ui';
import { SignalRHubConnection } from '../signalR/hubConnection/ui';
import { AuthWatcher } from './authWatcher';
import AppContentPanel from './contentPanel/contentPanel';
import './index.css';
import { RouteWatcher } from './routeWatcher';
import SideMenu from './sideMenu/sideMenu';
import TopBar from './topBar/topBar';

const App: React.FC = () => {

  const dispatch = useDispatch();
  const browser = Bowser.getParser(window.navigator.userAgent);
  const browserName = browser.getBrowserName(true);
  const platformType = browser.getPlatformType(true);

  console.log(`Browser detection: ${browserName}, Platform Type: ${platformType}`);

  useMountEffect(() => {
    dispatch(loadExistingAuth());
  });

  return <div className="p-d-flex p-flex-column">

    <AppDialogs />
    <RouteWatcher />
    <TopBar />
    <SignalRHubConnection />
    <AuthWatcher />

    <div className="app-content-container">

      <InitialSetupWorkflow />
      <SideMenu />
      <AppContentPanel />

    </div>

  </div>
}

export default App;
