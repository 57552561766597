import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { isValue } from "../../../../../utils/valueHelper";
import { getTenantId } from "../../../../authentication/store/selectors/tenant";
import { PricingModelGrade } from "../../../sharedTypes";
import { gradeKindAdapter, gradeValueAdapter, pricingModelGradeAdapter, pricingModelSaveStateAdapter } from "../state";
import { getPricingModel } from "./pricingModel";

const pricingModelGradeSelectors = pricingModelGradeAdapter.getSelectors((state: RootState) => state.pricingEditor.pricingModelGrades);
const getPricingModelGrades = pricingModelGradeSelectors.selectAll;

const gradeValueSelectors = gradeValueAdapter.getSelectors((state: RootState) => state.pricingEditor.gradeValues);
const getQualityGradeValues = gradeValueSelectors.selectAll;

const gradeKindSelectors = gradeKindAdapter.getSelectors((state: RootState) => state.pricingEditor.gradeKinds);


export const getQualityGradeKinds = createSelector(
    [
        gradeKindSelectors.selectAll
    ],
    (allGradeKinds) => {
        return allGradeKinds
            .filter(gradeKind => isValue(gradeKind.publishedDate) && !gradeKind.inactive)
            .sort((left, right) => left.name.localeCompare(right.name));
    });

export const getPricingModelGradesForGradeKind = createSelector([
    getPricingModel,
    getQualityGradeValues,
    gradeValueSelectors.selectEntities,
    getPricingModelGrades,
    getTenantId
], (pricingModel, qualityGradeValues, qualityGradeValueMap, pricingModelGrades, tenantId) => {

    if (!isValue(tenantId)) throw new Error("User's tenant could not be determined. Try refreshing the page");
    if (!isValue(pricingModel)) throw new Error("Pricing model was not available. Try refreshing the page");

    return (gradeKindId: number) => {

        const pricingModelGradesForGradeKind = pricingModelGrades
            .filter(pricingModelGrade => {
                const gradeValue = qualityGradeValueMap[pricingModelGrade.gradeValueId];
                return isValue(gradeValue) && gradeValue.gradeKindId === gradeKindId;
            });

        const assignedGradeValueMap: { [key: number]: boolean } = {};

        pricingModelGradesForGradeKind            
            .forEach(pricingModelGrade => {
                assignedGradeValueMap[pricingModelGrade.gradeValueId] = true;
            });

        const unspecifiedQualityGrades = qualityGradeValues
            .filter(qualityGrade => qualityGrade.gradeKindId === gradeKindId)
            .filter(qualityGrade => !isValue(assignedGradeValueMap[qualityGrade.id]));

        let nextId: number = 0;

        return pricingModelGradesForGradeKind
            .concat(unspecifiedQualityGrades.map<PricingModelGrade>(qualityGrade => ({
                id: nextId--,
                name: "",
                unitPrice: 0,
                pricingModelId: pricingModel.id,
                gradeValueId: qualityGrade.id,
                gradeValueName: qualityGrade.name,
                gradeValueRank: qualityGrade.sequence,
                tenantId: tenantId
            })))
            .sort((left, right) => left.gradeValueRank - right.gradeValueRank);
    }
});

const pricingModelSaveStateSelectors = pricingModelSaveStateAdapter.getSelectors((state: RootState) => state.pricingEditor.pricingModelSaveStates);

export const getSaveStateMap = pricingModelSaveStateSelectors.selectEntities;
