import React from "react";
import { useAppSelector } from "../../../../store/configureStore";
import { EditMarketingDetails } from "../../general/ui/editMarketingDetails";
import { getPricingModels } from "../store/selectors/priceModels";
import { Product } from "../store/types";
import './editProduct.css';

export interface EditProductProps {
    product: Product;
}
 
export const EditProduct: React.FC<EditProductProps> = ({ product }) => {

    const pricingModels = useAppSelector(getPricingModels);

    return <EditMarketingDetails        
        marketingDetails={product}    
        licenses={[]}    
        unitOfMeasures={[]}
        showPrice={false}
        pricingModels={pricingModels}
        subTitle="If specified, these values will override any Metrc values when inventory is displayed in the live menu"
        showMetricData={true}
    />
}