import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useAppSelector } from "../../../../store/configureStore";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { isValue } from "../../../../utils/valueHelper";
import { getVendorImageSize, smallDeviceQuery } from "../../../responsiveconfiguration";
import { loadVendorLogo } from "../store/featureActions/loadVendorLogo";
import { getVendorLogo } from "../store/selectors";

export interface VendorLogoProps {
    vendorUrlName: string | undefined,
    menuUrlName: string | undefined
}

export const VendorLogo: React.FC<VendorLogoProps> = ({ vendorUrlName, menuUrlName }) => {

    const dispatch = useDispatch();
    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    const vendorLogo = useAppSelector(getVendorLogo);
    const dimension = getVendorImageSize(isSmallDevice);

    useMountEffect(() => {
        if (isValue(vendorUrlName) && isValue(menuUrlName)) {
            dispatch(loadVendorLogo({ vendorUrlName, menuUrlName }));
        }
    });

    return <div
        style={{
            width: dimension,
            height: dimension,
            zIndex: 20
        }}
    >
        {isValue(vendorLogo) &&
            <img
                src={vendorLogo}
                alt="vendor logo"
                width={dimension}
                height={dimension}
                style={{
                    display: !isValue(vendorLogo) ? "none" : undefined,
                    zIndex: 20

                }}
            />
        }
    </div>
}