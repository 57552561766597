import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { completeTableRemountSagaFactory } from "../featureActions/completeTableRemount";
import { deleteUserTableSettingsSagaFactory } from "../featureActions/deleteUserTableSettings";
import { loadUserTableSettingsSagaFactory } from "../featureActions/loadUserTableSettings";
import { remountTableSagaFactory } from "../featureActions/remountTable";
import { saveNewUserTableSettingsSagaFactory } from "../featureActions/saveNewUserTableSettings";
import { saveUserTableSettingsSagaFactory } from "../featureActions/saveUserTableSettings";

// Register this method in the root saga
export function createUserTableSettingsSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        loadUserTableSettingsSagaFactory(context),
        saveNewUserTableSettingsSagaFactory(context),
        deleteUserTableSettingsSagaFactory(context),
        saveUserTableSettingsSagaFactory(context),
        remountTableSagaFactory(context),
        completeTableRemountSagaFactory(context)
    ]
}