import { actionFactory, Model1 } from "../types";

export const {

    loadSliceData,
    loadSliceDataReducer,
    loadSliceDataSagaFactory

} = actionFactory
    .withMappedName("loadSliceData")
    .createAsyncAction<void, Model1[]>({        
        actionStatusSelector: state => state.loadingState,
        asyncCall: (_arg, _context) => {
            const models: Model1[] = []; // use _context.api...
            return Promise.resolve(models);
        },
        onFulfilled: (state, action) => {
            state.data = action.payload.result;
        },
        successCallback: (_arg, _result, _context) => { },
        debounceInMilliseconds: 50
    });