import { any } from "../../../utils/arrayHelpers";
import { isValue } from "../../../utils/valueHelper";
import { AuthRoute } from "../../navigation/routes/types";
import { BeginSignOut } from "../ui/beginSignOut";
import { CompleteSignIn } from "../ui/completeSignIn";
import { CompleteSignOut } from "../ui/completeSignOut";
import { LogoutConfirmation } from "../ui/logoutConfirmation";
import { SessionExpired } from "../ui/sessionExpired";
import { SilentRenew } from "../ui/silentRenew";
import { AuthRoutePaths } from "./paths";

export const signInCallbackRoute: AuthRoute = {
  kind: "Auth",
  path: AuthRoutePaths.signInCallback,
  exact: true,
  view: CompleteSignIn
};

export const beginSignOutRoute: AuthRoute = {
  kind: "Auth",
  path: AuthRoutePaths.beginSignOut,
  exact: true,
  view: BeginSignOut
};

export const signOutCallbackRoute: AuthRoute = {
  kind: "Auth",
  path: AuthRoutePaths.signOutCallback,
  exact: true,
  view: CompleteSignOut
};

export const silentRenewRoute: AuthRoute = {
  kind: "Auth",
  path: AuthRoutePaths.silentRenew,
  exact: true,
  view: SilentRenew
};

export const logOutConfirmationRoute: AuthRoute = {
  kind: "Auth",
  path: AuthRoutePaths.logOutConfirmation,
  exact: true,
  view: LogoutConfirmation
};

export const sessionExpiredRoute: AuthRoute = {
  kind: "Auth",
  path: AuthRoutePaths.sessionExpired,
  exact: true,
  view: SessionExpired
};

export const authRoutes: AuthRoute[] = [ 
  signInCallbackRoute,
  beginSignOutRoute,
  signOutCallbackRoute, 
  silentRenewRoute,
  logOutConfirmationRoute,
  sessionExpiredRoute
];

export function isAuthRoutePath(path: string | null | undefined) : boolean {
  return isValue(path) && any(authRoutes, route => path.endsWith(route.path));
}