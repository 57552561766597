import { Checkbox } from 'primereact/checkbox';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import React from 'react';
import { FlexColumnContainer } from '../../../components/containers/flexColumnContainer';
import { FlexRowContainer } from '../../../components/containers/flexRowContainer';
import { BooleanFilterOptions } from './booleanFilterTypes';

export interface BooleanFilterElementProps extends BooleanFilterOptions {  
    elementOptions: ColumnFilterElementTemplateOptions;
}

export const BooleanFilterElement: React.FC<BooleanFilterElementProps> = ({ trueLabel, falseLabel, elementOptions }) => {

    const boolOptions: (boolean | string)[] = elementOptions.value ?? [true, false];

    return <FlexColumnContainer gap="20px">

        <FlexRowContainer gap="10px" centerContent >
            <Checkbox
                checked={boolOptions[0] === true}
                onChange={(e) => {
                    elementOptions.filterCallback([e.checked ? true : "noMatch", boolOptions[1]]);
                }}
            ></Checkbox>
            <div>{trueLabel}</div>
        </FlexRowContainer>

        <FlexRowContainer gap="10px" centerContent >
            <Checkbox
                checked={boolOptions[1] === false}
                onChange={(e) => {
                    elementOptions.filterCallback([boolOptions[0], e.checked ? false : "noMatch"]);
                }}
            />
            <div>{falseLabel}</div>
        </FlexRowContainer>

    </FlexColumnContainer>
};