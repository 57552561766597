import { ColumnProps } from "primereact/column";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import React from 'react';
import { SelectItem } from "../../features/commonModels/selectItem";

export interface DropDownColumnOptions<T, TValue>
    extends Omit<ColumnProps, "field" | "header" | "body" | "className"> {
    availableValues: SelectItem<TValue>[] | ((rowData: T) => SelectItem<TValue>[]);
    field: string & keyof T;
    header: string;

    getValue: (rowData: T) => TValue;
    onSubmit: (rowData: T, newValue: TValue) => void;

    inputOptions: Omit<DropdownProps, "onBlur" | "onChange" | "value" | "options" | "className">;    
    inputClassName?: string | ((rowData: T) => string);
}

interface DropDownTemplateProps<T, TValue> {
    getValue: (rowData: T) => TValue;
    onSubmit: (rowData: T, newValue: TValue) => void;
    availableValues: SelectItem<TValue>[] | ((rowData: T) => SelectItem<TValue>[]);
    inputOptions: Omit<DropdownProps, "onBlur" | "onChange" | "value" | "options" | "className">;    
    inputClassName?: string | ((rowData: T) => string);
}

interface DropDownBodyProps<T, TValue> extends DropDownTemplateProps<T, TValue> {
    rowData: T;
}

const DropDownBody = <T, TValue>({ rowData, getValue, onSubmit, availableValues, inputOptions, inputClassName }: DropDownBodyProps<T, TValue>) => {

    const value = getValue(rowData);

    const options = typeof (availableValues) === "function"
        ? availableValues(rowData)
        : availableValues;

    return <Dropdown        
        {...inputOptions}
        options={options}
        value={value}
        className={typeof (inputClassName) === "function"
            ? inputClassName(rowData)
            : inputClassName}
        onChange={(e) => {
            onSubmit(rowData, e.value);
        }}
    />
}

function createDropDownTemplate<T, TValue>({    
    availableValues,
    getValue,
    inputOptions,
    onSubmit,
    inputClassName
}: DropDownTemplateProps<T, TValue>): (rowData: T) => React.ReactNode {

    return (value: T) => {

        return <DropDownBody<T, TValue>
            rowData={value}
            getValue={getValue}
            onSubmit={onSubmit}
            availableValues={availableValues}
            inputOptions={inputOptions}
            inputClassName={inputClassName}
        />
    };
}

export function createDropDownColumnProps<T, TValue>(props: DropDownColumnOptions<T, TValue>): ColumnProps {

    return {
        columnKey: props.columnKey ?? props.field,
        body: createDropDownTemplate<T, TValue>(props),
        ...props
    }
}