import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, TermsOfServiceAdminState } from "../types";

export const setState = actionFactory.createAppAction<string>("setState");

export function setStateReducers(reducerFactory: IReducerFactory<TermsOfServiceAdminState>): IReducerFactory<TermsOfServiceAdminState> {
    return reducerFactory
        .forAction(setState)
        .addHandler((state, action) => {
            state.editState.stateAbbreviation = action.payload;            
        })
        .build();
}