import React, { useEffect } from 'react';
import packageJson from '../../../package.json';

const appVersion = packageJson.version;

export const CacheBuster: React.FC = (props: any) => {

  useEffect(() => {

    console.log('cache buster useEffect');
    
    const myHeaders = new Headers();
    
    myHeaders.append("content-type", "application/json");
    myHeaders.append("Cache-Control", "no-cache");
    myHeaders.append("Pragma", "no-cache");
    myHeaders.append("Expires", "0");    

    fetch('/meta.json', 
        {
            method: "GET",
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached                
            headers: myHeaders,
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
        })
      .then((response) => response.json())
      .then((meta) => {

        console.log('meta res', meta);

        const latestVersion = meta.version;
        const currentVersion = appVersion;

        console.log('latest:', latestVersion, 'current:', currentVersion);
        const shouldForceRefresh = latestVersion !== currentVersion;

        if (shouldForceRefresh) {

          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          refreshCacheAndReload();

        } else {

          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
        }
      });
  }, []);

  const refreshCacheAndReload = () => {

    console.log('Clearing cache and hard reloading...');

    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (let name of names) caches.delete(name);
      });
    }

    // delete browser cache and hard reload
    window.location.reload();
  };

  return props.children;
}