import { RootState } from "../../../../../store/rootReducer";
import { createLocalImageUrl } from "../../../../../utils/imageHelper";
import { isValue } from "../../../../../utils/valueHelper";
 
export const getCompanyLogo = (state: RootState) => {    
    const logoImageData = state.companyProfile.logoState.currentImage;
    if (!isValue(logoImageData)) return null;
    if (!isValue(logoImageData.imageData)) return null;
    return createLocalImageUrl(logoImageData);
}

export const getNewLogo = (state: RootState) => {
    return state.companyProfile.logoState.newImagePreview;
}

export const getLoadLogoState = (state: RootState) => {
    return state.companyProfile.logoState.loadState;
}

export const getSaveLogoState = (state: RootState) => {
    return state.companyProfile.logoState.saveState;
}