import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { createLoadSliceDataSaga } from "../featureActions/loadSliceData";
import { createSubmitVerificationRequestSaga } from "../featureActions/submitVerificationRequest";

// Register this method in the root saga
export function createVerificationSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        createLoadSliceDataSaga(context),
        createSubmitVerificationRequestSaga(context)
    ]
}