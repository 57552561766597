import { ActionCreatorWithPayload, CaseReducer, createAction, PayloadAction } from "@reduxjs/toolkit";
import { StrictEffect } from "redux-saga/effects";
import { Prefix, PrefixWithReplace } from "../../../utils/mappedTypeHelpers";
import { isValue } from "../../../utils/valueHelper";
import { SagaAppContext } from "../../rootSaga";
import { IReducerFactory } from "../interfaces";
import { ModalEditOptions, withPayloadType } from "../modalEdit/modalEdit";
import { createExecuteWithProgressSaga } from "../sagaWithProgress/executeWithProgress";
import { SagaActions } from "../sagaWithProgress/types";
import { IActionFactory } from "./actionFactory";
import { AsyncActionOptions, isEntityActionStatusOption } from "./asyncActionOptions";

interface NamedActionResult<TSliceState, TArg> {
    action: ActionCreatorWithPayload<TArg>;
    reducer: (reducerFactory: IReducerFactory<TSliceState>) => IReducerFactory<TSliceState>;
}

type MappedActionResult<TSliceState, TArg, TActionName extends string> = PrefixWithReplace<NamedActionResult<TSliceState, TArg>, TActionName, "action">;

const mapActionResult = <TSliceState, TArg, TActionName extends string>(
    result: NamedActionResult<TSliceState, TArg>,
    actionName: TActionName)
    : MappedActionResult<TSliceState, TArg, TActionName> => {

    const mappedValue: MappedActionResult<TSliceState, TArg, TActionName> = {
        [`${actionName}`]: result.action,
        [`${actionName}Reducer`]: result.reducer
    };
    return mappedValue;
}

interface AyncActionResult<TSliceState, TArg, TResult> {
    action: ActionCreatorWithPayload<TArg>;
    reducer: (reducerFactory: IReducerFactory<TSliceState>) => IReducerFactory<TSliceState>;
    sagaFactory: (context: SagaAppContext) => () => Generator<StrictEffect<any, any>, void, void>;
    sagaActions: SagaActions<TArg, TResult>;
}

type MappedAsyncResult<TSliceState, TArg, TAsyncResult, TActionName extends string> = PrefixWithReplace<AyncActionResult<TSliceState, TArg, TAsyncResult>, TActionName, "action">;

function mapAsyncResult<TSliceState, TArg, TAsyncResult, TActionName extends string>(
    result: AyncActionResult<TSliceState, TArg, TAsyncResult>,
    actionName: TActionName)
    : MappedAsyncResult<TSliceState, TArg, TAsyncResult, TActionName> {
    const mappedValue: MappedAsyncResult<TSliceState, TArg, TAsyncResult, TActionName> = {
        [`${actionName}`]: result.action,
        [`${actionName}Reducer`]: result.reducer,
        [`${actionName}SagaFactory`]: result.sagaFactory,
        [`${actionName}SagaActions`]: result.sagaActions
    };
    return mappedValue;
}

export type NamedModalEditOptions<TSliceState, TEditedValue extends {}, TBeginEditArg, TCompleteEditResult> =
    Omit<ModalEditOptions<TSliceState, TEditedValue, TBeginEditArg, TCompleteEditResult>, "entityName" | "actionFactory">;

export interface IMappedNameFactory<TSliceState, TActionName extends string> {
    createAction<TArg>(reducer: CaseReducer<TSliceState, PayloadAction<TArg>>): MappedActionResult<TSliceState, TArg, TActionName>;
    createAsyncAction<TArg, TAsyncResult>(options: AsyncActionOptions<TSliceState, TArg, TAsyncResult>): MappedAsyncResult<TSliceState, TArg, TAsyncResult, TActionName>;
    createModal<TEditedValue extends {}, TBeginEditArg, TCompleteEditResult>(options: NamedModalEditOptions<TSliceState, TEditedValue, TBeginEditArg, TCompleteEditResult>):
        NamedModalResult<TSliceState, TEditedValue, TBeginEditArg, TCompleteEditResult, TActionName>;
}

export class MappedNameFactory<TSliceState, TActionName extends string> implements IMappedNameFactory<TSliceState, TActionName> {

    private actionName: string;
    private actionFactory: IActionFactory<TSliceState>;

    constructor(actionName: string, actionFactory: IActionFactory<TSliceState>) {
        this.actionName = actionName;
        this.actionFactory = actionFactory;
    }

    public createAction = <TArg>(reducer: CaseReducer<TSliceState, PayloadAction<TArg>>) => {
        const action: ActionCreatorWithPayload<TArg> = this.actionFactory.createAppAction<TArg>(this.actionName) as ActionCreatorWithPayload<TArg>;

        const reducerBuilder = (reducerFactory: IReducerFactory<TSliceState>): IReducerFactory<TSliceState> => {

            return reducerFactory
                .forAction(action)
                .addHandler(reducer)
                .build();
        }

        const result = {
            action,
            reducer: reducerBuilder
        };

        return mapActionResult(result, this.actionName)
    }

    public createAsyncAction = <TArg, TAsyncResult>({
        asyncCall,
        actionStatusSelector,
        onFulfilled,
        onPending,
        onRejected,
        debounceInMilliseconds,
        successCallback,
        rejectedCallback,
        predicate,
        notAuthorizedAction,
        errorDisplay
    }: AsyncActionOptions<TSliceState, TArg, TAsyncResult>
    ) => {


        const result = createAsyncActionInternal<TSliceState, TArg, TAsyncResult>({
            actionName: this.actionName,
            actionFactory: this.actionFactory,
            asyncCall,
            actionStatusSelector,
            onFulfilled,
            onPending,
            onRejected,
            debounceInMilliseconds,
            successCallback,
            rejectedCallback,
            predicate,
            notAuthorizedAction,
            errorDisplay
        });

        return mapAsyncResult(result, this.actionName)
    }

    public createModal = <TEditedValue extends {}, TBeginEditArg, TCompleteEditResult>(options: NamedModalEditOptions<TSliceState, TEditedValue, TBeginEditArg, TCompleteEditResult>) => {
        const modalResult = createModalEdit<TSliceState, TEditedValue, TBeginEditArg, TCompleteEditResult>({
            ...options,
            entityName: this.actionName,
            actionFactory: this.actionFactory
        })

        return mapModalResult(modalResult, this.actionName);
    }
}

const createAsyncActionInternal = <TSliceState, TArg, TAsyncResult>({
    actionName,
    actionFactory,
    asyncCall,
    actionStatusSelector,
    onFulfilled,
    onPending,
    onRejected,
    debounceInMilliseconds,
    successCallback,
    rejectedCallback,
    predicate,
    notAuthorizedAction,
    errorDisplay
}: AsyncActionOptions<TSliceState, TArg, TAsyncResult> & { actionName: string, actionFactory: IActionFactory<TSliceState> }
) => {
    //TODO: Figure out how to work with redux toolkit createAction to let the compiler know the
    // return type should be ActionCreatorWithPayload. TArg might need some type constraints.
    const action: ActionCreatorWithPayload<TArg> = actionFactory.createAppAction<TArg>(actionName) as ActionCreatorWithPayload<TArg>;

    const sagaActions = actionFactory.createSagaWithProgressActions<TArg, TAsyncResult>(action.type);

    const reducer = (reducerFactory: IReducerFactory<TSliceState>): IReducerFactory<TSliceState> => {

        const factory = isEntityActionStatusOption(actionStatusSelector)
            ? reducerFactory
                .forSagaWithProgress(sagaActions)
                .withEntityActionStatus(actionStatusSelector)
            : reducerFactory
                .forSagaWithProgress(sagaActions)
                .withActionStatus(actionStatusSelector);

        if (isValue(onPending)) {
            factory.onPending(onPending);
        }

        if (isValue(onRejected)) {
            factory.onRejected(onRejected);
        }

        return factory
            .onFulfilled(onFulfilled)
            .build();
    }

    const sagaFactory = (context: SagaAppContext) => {

        return createExecuteWithProgressSaga({
            options: {
                action: asyncCall,
                appContext: context,
                notificationActions: sagaActions,
                successCallback: successCallback,
                rejectedCallback: rejectedCallback,
                predicate: predicate,
                notAuthorizedAction: notAuthorizedAction,
                errorDisplay: errorDisplay
            },
            actionPattern: action.type,
            debounceInMilliseconds: debounceInMilliseconds

        });
    };

    const result = {
        action,
        reducer,
        sagaFactory,
        sagaActions
    };

    return result;
}

class TypeWrapper<TSliceState, TEditedValue extends {}, TBeginEditArg, TCompleteEditResult> {
    wrapped(options: ModalEditOptions<TSliceState, TEditedValue, TBeginEditArg, TCompleteEditResult>) {
        return createModalEdit<TSliceState, TEditedValue, TBeginEditArg, TCompleteEditResult>(options);
    }
}

type CreateModalEditResult<TSliceState, TEditedValue extends {}, TBeginEditArg, TCompleteEditResult> =
    ReturnType<TypeWrapper<TSliceState, TEditedValue, TBeginEditArg, TCompleteEditResult>['wrapped']>;

type NamedModalResult<TSliceState, TEditedValue extends {}, TBeginEditArg, TCompleteEditResult, TActionName extends string> =
    Prefix<CreateModalEditResult<TSliceState, TEditedValue, TBeginEditArg, TCompleteEditResult>, TActionName>

function mapModalResult<TSliceState, TEditedValue extends {}, TBeginEditArg, TCompleteEditResult, TActionName extends string>(
    result: CreateModalEditResult<TSliceState, TEditedValue, TBeginEditArg, TCompleteEditResult>,
    actionName: TActionName)
    : NamedModalResult<TSliceState, TEditedValue, TBeginEditArg, TCompleteEditResult, TActionName> {
    const mappedValue: NamedModalResult<TSliceState, TEditedValue, TBeginEditArg, TCompleteEditResult, TActionName> = {
        [`${actionName}Begin`]: result.begin,
        [`${actionName}Cancel`]: result.cancel,
        [`${actionName}Complete`]: result.complete,
        [`${actionName}Reducers`]: result.reducers,
        [`${actionName}SagaFactory`]: result.sagaFactory
    };
    return mappedValue;
}



const createModalEdit = <TSliceState, TEditedValue extends {}, TBeginEditArg, TCompleteEditResult>(
    { entityName, actionFactory, selector, modelFactory, onCompleteEdit, onFulfilled: onFulFilled, successCallback, beforeBeginEdit, errorDisplay }: ModalEditOptions<TSliceState, TEditedValue, TBeginEditArg, TCompleteEditResult>) => {

    const begin = createAction(actionFactory.createActionName(`beginEdit-${entityName}`), withPayloadType<TBeginEditArg>());

    const beginEditReducer = (reducerFactory: IReducerFactory<TSliceState>): IReducerFactory<TSliceState> => {

        const factory = reducerFactory
            .forAction(begin)
            .addHandler((state, action) => {
                const modalEditState = selector(state);
                modalEditState.isVisible = true;

                try {
                    modalEditState.editedValue = modelFactory(action.payload, state)
                }
                catch (error: any) {
                    modalEditState.isVisible = false;
                    console.log(error);
                }
            });

        if (isValue(beforeBeginEdit)) {
            factory.addHandler(beforeBeginEdit)
        }

        return factory.build();
    };

    const cancel = actionFactory.createAppAction(`cancelEdit-${entityName}`);

    const cancelEditReducer = (reducerFactory: IReducerFactory<TSliceState>): IReducerFactory<TSliceState> => {

        return reducerFactory
            .forAction(cancel)
            .addHandler((state, _) => {
                const modalEditState = selector(state);
                modalEditState.isVisible = false;
                modalEditState.editedValue = null;
                modalEditState.errorContent = null;
                modalEditState.hasExecuted = undefined;
            })
            .build();
    };

    const {

        action: complete,
        reducer: completeEditReducer,
        sagaFactory

    } = createAsyncActionInternal<TSliceState, TEditedValue, TCompleteEditResult>({
        actionName: `completeEdit-${entityName}`,
        actionFactory: actionFactory,
        actionStatusSelector: selector,
        asyncCall: onCompleteEdit,
        onFulfilled: (state, action) => {
            const modalEditState = selector(state);
            modalEditState.isVisible = false;
            modalEditState.editedValue = null;
            modalEditState.errorContent = null;
            modalEditState.hasExecuted = undefined;

            onFulFilled(state, action.payload);
        },
        successCallback: successCallback,
        errorDisplay: errorDisplay,
        debounceInMilliseconds: 50
    });

    const reducers = [beginEditReducer, cancelEditReducer, completeEditReducer];
    return {
        begin,
        cancel,
        complete,
        reducers,
        sagaFactory
    };
}