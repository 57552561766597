import { createEntityAdapter } from '@reduxjs/toolkit';
import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { PricingModel } from '../../sharedTypes';
import { PriceModelDashboardState } from './types';

export const pricingModelAdapter = createEntityAdapter<PricingModel>();

export const createInitialState = (): PriceModelDashboardState => {
    return {
        loadingState: CreateActionStatusInitialState(),
        pricingModels: pricingModelAdapter.getInitialState(),
        addPricingModelState: CreateActionStatusInitialState(),
        navigateToNewModelId: null
    };
};

export const initialState: PriceModelDashboardState = createInitialState();