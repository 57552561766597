import { EntityId } from "@reduxjs/toolkit";
import { isValue } from "./valueHelper";

export const createValueOrErrorSelector = <TState, TValue>(selector: (state: TState) => TValue | null | undefined) => {
    return  (state: TState) => {
        const value = selector(state);
        if (isValue(value)) return value;
        throw new Error("value was expected to be a valid instance but was null or undefined");
    };        
}

export const createValueByIdOrErrorSelector = <TState, TValue>(selector: (state: TState, id: EntityId) => TValue | null | undefined) => {
    return  (state: TState, id: EntityId) => {
        const value = selector(state, id);
        if (isValue(value)) return value;
        throw new Error("value was expected to be a valid instance but was null or undefined");
    };        
}

export const createValueSelector = <TState, TValue>(value: TValue) => {
    return (_state: TState) => value;        
}