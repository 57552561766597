import { inventoryAdapter } from "../../state";
import { actionFactory, Inventory } from "../../types";

export const {

    editInventoryBegin,
    editInventoryCancel,
    editInventoryComplete,
    editInventoryReducers,
    editInventorySagaFactory

} = actionFactory
    .withMappedName("editInventory")
    .createModal<Inventory, Inventory, Inventory>({
        modelFactory: (arg, _state) => {
            const editableProduct : Inventory = {...arg};

            editableProduct.marketingName = editableProduct.marketingName ?? "";
            editableProduct.marketingStrainName = editableProduct.marketingStrainName ?? "";
            editableProduct.marketingDescription = editableProduct.marketingDescription ?? "";   

            return editableProduct;      
        },
        onCompleteEdit: (value, context) => {
            return context.marketplaceApiClient.inventoryManagement.saveInventory(value);
        },
        onFulfilled: (state, action) => {
            inventoryAdapter.updateOne(state.inventory, {
                id: action.result.id,
                changes: action.result
            });            
        },
        selector: state => state.editInventoryState
    });