import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Column } from "primereact/column";
import { DataTable, DataTableSelectionChangeParams } from "primereact/datatable";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../store/configureStore";
import { RootState } from "../../store/rootReducer";

export interface SelectableTableProps<T>  {
    selectedItemsSelector: (state: RootState) => T[];
    allItemsSelector: (state: RootState) => T[];
    setSelectedItemAction: ActionCreatorWithPayload<T[]>;    
}

export const SelectableTable = <T,>({ selectedItemsSelector, allItemsSelector, setSelectedItemAction, children }: React.PropsWithChildren<SelectableTableProps<T>>) => {

    const dispatch = useDispatch();

    const selectedItems = useAppSelector(selectedItemsSelector);
    const allItems = useAppSelector(allItemsSelector);

    const onSelectionChanged = (e: DataTableSelectionChangeParams) => {
        dispatch(setSelectedItemAction(e.value));
    }

    return <DataTable
        dataKey="id"
        value={allItems}
        className="p-datatable-sm"
        scrollable
        scrollHeight="60vh"
        onSelectionChange={onSelectionChanged}
        selection={selectedItems}
        paginator
        rows={20}
        rowsPerPageOptions={[10, 20, 50]}
    >
        <Column key="selection-column" selectionMode="multiple" headerStyle={{ width: '3em' }} />
        {children}
    </DataTable>

}