import { EntityId } from '@reduxjs/toolkit';
import { DataView } from 'primereact/dataview';
import React from 'react';
import { useDispatch } from 'react-redux';
import { OverlayWaitDisplay } from '../../../../components/waitDisplay';
import { useAppSelector } from '../../../../store/configureStore';
import { useMountEffect } from '../../../../utils/useEffectHelper';
import { isValue } from '../../../../utils/valueHelper';
import { loadMenuSections } from '../store/featureActions/loadMenuSections';
import { getMenuSectionIds, getMenuSectionLoadState } from '../store/selectors/menuSections';
import { MenuActionsToolbar } from './menuActionsToolbar';
import './menuSectionList.css';
import { MenuSectionView } from './menuSectionView';

export interface MenuSectionListProps {
    menuId: number | undefined;
}

export const MenuSectionList: React.FC<MenuSectionListProps> = ({ menuId }) => {

    const dispatch = useDispatch();

    const menuSectionIds = useAppSelector(getMenuSectionIds);
    const menuSectionLoadState = useAppSelector(getMenuSectionLoadState);    

    useMountEffect(() => {
        if (isValue(menuId)) {
            dispatch(loadMenuSections({ menuId: menuId }))
        }
    });

    const itemTemplate = (id: EntityId) => <MenuSectionView key={id} sectionId={id} />


    return <OverlayWaitDisplay isWaiting={menuSectionLoadState.isExecuting}>
        <div className="p-shadow-2 p-p-2 p-mr-2 menu-section-list-container">
            <div className="p-d-flex p-ai-center p-flex-wrap">
                <div style={{
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    paddingLeft: "8px",
                    marginRight: "25px"
                }}>
                    Sections
                </div>
                <MenuActionsToolbar />
            </div>
            <DataView
                className="menu-section-list p-p-2"
                value={menuSectionIds}
                itemTemplate={itemTemplate}
            />
        </div>
    </OverlayWaitDisplay>
}