import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { loadLatestSyncRequestSagaFactory } from "../featureActions/loadLatestSyncRequest";
import { createLoadSliceDataSaga } from "../featureActions/loadSliceData";
import { createRequestLicenseSyncSaga } from "../featureActions/requestLicenseSync";
import { createSaveLicenseSaga } from "../featureActions/saveLicense";


export function createSyncAdminLicenseSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        createLoadSliceDataSaga(context),
        createRequestLicenseSyncSaga(context),
        createSaveLicenseSaga(context),
        loadLatestSyncRequestSagaFactory(context)
    ]
}