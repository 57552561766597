import clsx from 'clsx';
import { Field, FieldProps, FormikContextType, useFormikContext } from "formik";
import { InputSwitch, InputSwitchChangeParams } from 'primereact/inputswitch';
import { CSSProperties } from 'react';
import { BooleanOrNullKeyOf, getBooleanOrNullValue, PropertiesOfType } from '../../utils/typeHelpers';
import { FormikEditorClassStyles } from './classNameAttributes';

export interface SwitchFieldProps<T extends PropertiesOfType<T, boolean | undefined>> extends FormikEditorClassStyles {

    defaultLabel: string;
    fieldName: keyof T & string & BooleanOrNullKeyOf<T>;
    invertLogic?: boolean;
    containerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    submitOnChange?: boolean;
    disabled?: boolean;
}

export const SwitchField = <T extends PropertiesOfType<T, boolean | undefined>>({

    defaultLabel,
    fieldName,
    invertLogic = false,
    labelStyle,
    containerStyle,
    inputStyle,
    submitOnChange = false,
    disabled,
    containerClassName,
    inputClassName,
    labelClassName
}: SwitchFieldProps<T>) => {

    const formikProps: FormikContextType<T> = useFormikContext<T>();
    const labelText = defaultLabel;


    return <div style={containerStyle} className={containerClassName}>
        <label className={labelClassName} style={labelStyle} htmlFor={fieldName} >{labelText}</label>
        <Field
            name={fieldName}
        >
            {
                (props: FieldProps<T>) =>
                    <InputSwitch                    
                        style={inputStyle}
                        disabled={disabled}
                        className={clsx(inputClassName, { "p-invalid": !!formikProps.errors[fieldName] })}
                        checked={invertLogic ? !getBooleanOrNullValue(formikProps.values, fieldName) : !!getBooleanOrNullValue(formikProps.values, fieldName)}
                        onChange={async (event: InputSwitchChangeParams) => {
                            await formikProps.setFieldValue(props.field.name, invertLogic ? !event.value : event.value);
                            if (submitOnChange) {
                                formikProps.submitForm();
                            }
                        }}
                    />
            }
        </Field>
        <div>
            <small>{formikProps.errors[fieldName]}</small>
        </div>
    </div>
}
