import { ReactNode } from "react";
import { ConstraintNodeKind, TemplateConstraintNode } from "../../models/constraintNode";
import { ConstraintForm } from "./constraintForm";
import { LogicBlockEditor } from "./logicBlockEditor";

export const createViewForNode = (node: TemplateConstraintNode, gradeValueId: number): ReactNode => {

    switch (node.kind) {
        case ConstraintNodeKind.And:
            return <LogicBlockEditor key={node.id} constraintNodeId={node.id} gradeValueId={gradeValueId} />
        case ConstraintNodeKind.Or:
            return <LogicBlockEditor key={node.id} constraintNodeId={node.id} gradeValueId={gradeValueId} />
        case ConstraintNodeKind.Constraint:
            return <ConstraintForm key={node.id} constraintNodeId={node.id} gradeValueId={gradeValueId} />
        default:
            throw new Error(`The node kind ${node.kind} is not supported`);
    }

}