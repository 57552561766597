import { Column, ColumnProps } from "primereact/column";
import React from 'react';
import { getNumberOrNullValue, NumberOrNullKeyOf, PropertiesOfType } from "../typeHelpers";
//import { CreateNumberFilterProps, NumberFilterOptions } from "./filters/numberFilter";

// String

export interface NumberColumnOptions<T extends PropertiesOfType<T, number | null | undefined>> extends Omit<ColumnProps, "field"> {
    field: NumberOrNullKeyOf<T> & string;    
    formatOptions?: Intl.NumberFormatOptions    
}

function createNumberTemplate<T>(
    getNumberFn: (rowData: T) => number | null | undefined,
    formatOptions: Intl.NumberFormatOptions): (rowData: T) => React.ReactNode {
    return (value: T) => {
        const numberValue = getNumberFn(value);
        return !!numberValue
            ? numberValue.toLocaleString(undefined, formatOptions)
            : "";

    };
}

const defaultFormatOptions: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2
}

export function createNumberColumn<T extends PropertiesOfType<T, number | null | undefined>>(props: NumberColumnOptions<T>): React.ReactNode {

    const getValueFn = (rowData: T) => getNumberOrNullValue(rowData, props.field);

    const formatOptions: Intl.NumberFormatOptions = props.formatOptions ?? defaultFormatOptions;

    return <Column key={props.columnKey ?? props.field} sortable body={createNumberTemplate<T>(getValueFn, formatOptions)} {...props} />;
}

export function createNumberColumnProps<T extends PropertiesOfType<T, number | null | undefined>>(
    { columnKey, field, formatOptions, style, ...rest } : NumberColumnOptions<T>): ColumnProps {

    const getValueFn = (rowData: T) => getNumberOrNullValue(rowData, field);

    const formatOptionsToUse: Intl.NumberFormatOptions = formatOptions ?? defaultFormatOptions;

    return {
        columnKey: columnKey ?? field,
        field: field,
        body: createNumberTemplate<T>(getValueFn, formatOptionsToUse),
        sortable: true,
        alignHeader: "right",
        style: { textAlign: "right", ...style },
        ...rest
    }
}

export interface DerivedNumberColumnOptions<T> extends Omit<ColumnProps, "field"> {
    getValue: (record: T) => number | null | undefined,
    formatOptions?: Intl.NumberFormatOptions    
}

export function createDerivedNumberColumnProps<T>(
    { getValue, columnKey, formatOptions, style, ...rest } : DerivedNumberColumnOptions<T>): ColumnProps {

    const formatOptionsToUse: Intl.NumberFormatOptions = formatOptions ?? defaultFormatOptions;

    return {
        columnKey: columnKey,        
        body: createNumberTemplate<T>(getValue, formatOptionsToUse),
        sortable: true,
        alignHeader: "right",
        style: { textAlign: "right", ...style },
        ...rest
    }
}