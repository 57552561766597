import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, TermsOfServiceAdminState, } from "../types";

export const setUploadTermsDocument = actionFactory.createAppAction<string>("setUploadTermsDocument");

export function setUploadTermsDocumentReducers(reducerFactory: IReducerFactory<TermsOfServiceAdminState>): IReducerFactory<TermsOfServiceAdminState> {
    return reducerFactory
        .forAction(setUploadTermsDocument)
        .addHandler((state, action) => {            
            state.editState.preview = action.payload;       
        })
        .build();
}