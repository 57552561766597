import { Field, FieldProps, FormikContextType, useFormikContext } from "formik";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import { OverlayPanel } from "primereact/overlaypanel";
import React, { ChangeEvent, CSSProperties, useRef } from 'react';
import { useAppSelector } from '../../store/configureStore';
import { RootState } from '../../store/rootReducer';
import { createIdentityMap } from "../../utils/identity";
import { getStringOrNullValue, PropertiesOfType } from '../../utils/typeHelpers';
import { isValue } from '../../utils/valueHelper';
import { FlexColumnContainer } from '../containers/flexColumnContainer';
import { FlexRowContainer } from '../containers/flexRowContainer';
import './liveSearchField.css';

interface SelectOption {
    key: number | string,
    label: string,
    value: number | string
}

export interface LiveSearchFieldProps<T extends PropertiesOfType<T, TField | null | undefined> & PropertiesOfType<T, string | null | undefined>, TField, TOption> {
    label?: string;
    valueFieldName: keyof T & string & keyof PropertiesOfType<T, TField | null | undefined>;
    labelFieldName: keyof T & string & keyof PropertiesOfType<T, string | null | undefined>;
    onSearch: (searchText: string) => void;
    searchResultsSelector: (rootState: RootState) => TOption[];
    getOptionValue: (option: TOption) => string | number;
    getOptionLabel: (option: TOption) => string;
    onChange?: (value: TOption | null) => void;
    onCancelSelection?: () => void;
    containerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    disabled?: boolean;
    overlayClassName?: string;
    resultsListClassName?: string;
    searchPlaceHolder?: string;
    submitOnChange?: boolean;
    showErrors?: boolean;
}

export const LiveSearchField = <T extends PropertiesOfType<T, TField | null | undefined> & PropertiesOfType<T, string | null | undefined>, TField, TOption>({
    label,
    valueFieldName,
    labelFieldName,
    onSearch,
    searchResultsSelector,
    getOptionValue,
    getOptionLabel,
    onChange,
    onCancelSelection,
    containerStyle,
    labelStyle,
    inputStyle,
    disabled,
    overlayClassName,
    resultsListClassName,
    searchPlaceHolder,
    submitOnChange = false,
    showErrors = true
}: LiveSearchFieldProps<T, TField, TOption>) => {

    const formikProps: FormikContextType<T> = useFormikContext<T>();
    const searchResults = useAppSelector(searchResultsSelector);
    const labeledValues: SelectOption[] = searchResults.map(opt => {
        const optionValue = getOptionValue(opt);
        return {
            key: optionValue,
            value: optionValue,
            label: getOptionLabel(opt)
        }
    });
    const optionLookup = createIdentityMap(searchResults, getOptionValue);

    const searchInputId = `live-search-field-input-${valueFieldName}`;
    const overlayPanelRef = useRef<OverlayPanel>(null);

    const onSearchTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
        onSearch(e.target.value);
    }

    const onLabelInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
        if (!isValue(overlayPanelRef.current)) return;
        overlayPanelRef.current.toggle(e);

        setTimeout(() => {
            const searchInput = document.getElementById(searchInputId);
            if (isValue(searchInput)) {
                searchInput.focus();
            }
        }, 150)

    }

    return <div style={containerStyle}>

        {isValue(label) && <label style={labelStyle} htmlFor={valueFieldName} >{label}</label>}
        <Field
            name={valueFieldName}
        >
            {
                (_props: FieldProps<T>) =>
                    <FlexColumnContainer>
                        <FlexRowContainer>
                            <InputText
                                className="live-search-field-readonly-display"
                                style={inputStyle}
                                value={getStringOrNullValue(formikProps.values, labelFieldName) ?? ""}
                                readOnly
                                disabled={disabled}
                                onClick={onLabelInputClick}
                            />
                            <Button
                                onClick={() => {
                                    formikProps.setFieldValue(valueFieldName, null);
                                    formikProps.setFieldValue(labelFieldName, null);

                                    if (isValue(onChange)) {
                                        onChange(null);
                                    }
                                }}
                                disabled={disabled}
                                className="live-search-field-clear-value"
                                type="button"
                                icon={PrimeIcons.TIMES}
                            />
                        </FlexRowContainer>
                        <OverlayPanel
                            ref={overlayPanelRef}
                            className={overlayClassName}
                            style={containerStyle}
                            onHide={onCancelSelection}
                        >
                            <FlexColumnContainer gap="10px">
                                <InputText
                                    id={searchInputId}
                                    placeholder={searchPlaceHolder ?? "Type to search..."}
                                    onChange={onSearchTextChanged}
                                    className="live-search-field-input"                                    
                                />
                                <ListBox
                                    className={resultsListClassName ?? "live-search-field-results"}
                                    disabled={disabled}
                                    options={labeledValues}
                                    onChange={(e) => {
                                        if (typeof (e.value) === "number") {
                                            const option = optionLookup[e.value];
                                            formikProps.setFieldValue(valueFieldName, getOptionValue(option));
                                            formikProps.setFieldValue(labelFieldName, getOptionLabel(option));

                                            if (submitOnChange) {
                                                formikProps.submitForm();
                                            }

                                            if (isValue(onChange)) {
                                                onChange(option);
                                            }

                                            if (isValue(overlayPanelRef.current)) {
                                                overlayPanelRef.current.hide();
                                            }
                                        }

                                    }}
                                />
                            </FlexColumnContainer>
                        </OverlayPanel>

                    </FlexColumnContainer>
            }
        </Field>
        {
            showErrors &&
            <div>
                <small>{formikProps.errors[valueFieldName]}</small>
            </div>
        }
    </div>
}