import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { actionFactory, Product, ProductManagementState } from "../../types";

export const beginEditProductImages = actionFactory.createAppAction<Product>("beginEditProductImages");

export function beginEditProductImagesReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forAction(beginEditProductImages)
        .addHandler((state, action) => {          
            state.editMediaEntity = action.payload;
        })
        .build();
}