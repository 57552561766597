import clsx from "clsx";
import { CSSProperties } from "react";
import './flipCard.css';

export enum FlipCardFace {
    Front,
    Back
}

export interface FlipCardProps {
    className?: string;
    style?: CSSProperties;
    currentFace: FlipCardFace;
    frontCard: React.ReactNode;
    backCard: React.ReactNode;
}

export const FlipCard: React.FC<FlipCardProps> = ({ style, className, currentFace, frontCard, backCard }) => {    

    const isFrontOfCard: boolean = currentFace === FlipCardFace.Front;

    return <div
        style={style}
        className={clsx("flip-card", className)}
    >
        <div
            className={clsx("flip-card-inner", {
                "back-of-card-selected": !isFrontOfCard
            })}
        >
            <div
                className="flip-card-front"                
            >
                {frontCard}
            </div>

            <div
                className="flip-card-back"                
            >
                {backCard}
            </div>

        </div>

    </div >
}