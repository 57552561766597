import ApiClient from "../../apiCommunication/apiClient";
import { SerializationBuilder } from "../../apiCommunication/serializationBuilder";
import { QualityAssessment } from "../../qualityAssessments/models/qualityAssessment";
import { QualityAssessmentValue } from "../../qualityAssessments/models/qualityAssessmentValue";
import { AssessmentEvaluationRequest } from "../models/assessmentEvaluationRequest";
import { AssessmentTemplate } from "../models/assessmentTemplate";
import { TemplateConstraintNode } from "../models/constraintNode";
import { QualityGradeKind } from "../models/gradeKind";
import { QualityGradeValue } from "../models/gradeValue";
import { QualityMetricKind } from "../models/metricKind";
import { QualityMetricValue } from "../models/metricValue";
import { TemplateConstraint } from "../models/templateConstraint";
import { TemplateMetric } from "../models/templateMetric";
import { TemplateMetricAssignment } from "../templateEditor/store/types";



export const metricKindSerializer = SerializationBuilder
    .forType<QualityMetricKind>()
    .parseUtcDatesToLocalTime(["publishedDate"])
    .build();


export const gradeKindSerializer = SerializationBuilder
    .forType<QualityGradeKind>()
    .parseUtcDatesToLocalTime(["publishedDate"])
    .build();

export const templateSerializer = SerializationBuilder
    .forType<AssessmentTemplate>()
    .parseUtcDatesToLocalTime(["publishedDate"])
    .build();

export const assessmentSerializer = SerializationBuilder
    .forType<QualityAssessment>()
    .parseUtcDatesToLocalTime(["completedOn", "createdOn"])
    .build();

export const assessmentValueSerializer = SerializationBuilder
    .forType<QualityAssessmentValue>()
    .parseUtcDatesToLocalTime(["measuredOn"])
    .build();

export default class QualityApiClient {

    apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    getQualityMetricKinds = (): Promise<QualityMetricKind[]> => {
        return this.apiClient
            .executeGet<QualityMetricKind[]>("QualityGradeTemplate/metricKinds")
            .then(metricKindSerializer.serializeSet);
    }

    upsertQualityMetricKind = (value: QualityMetricKind) => {
        return this.apiClient
            .executePostWithResult<QualityMetricKind>(`QualityGradeTemplate/metricKinds`, value)
            .then(metricKindSerializer.serializeSingle);
    }

    publishQualityMetricKind = (value: QualityMetricKind) => {
        return this.apiClient
            .executePostWithResult<QualityMetricKind>(`QualityGradeTemplate/metricKinds/${value.id}/publish`)
            .then(metricKindSerializer.serializeSingle);
    }

    deleteQualityMetricKind = (id: number) => {
        return this.apiClient
            .executeDelete(`QualityGradeTemplate/metricKinds/${id}`);
    }

    getQualityMetricValues = (): Promise<QualityMetricValue[]> => {
        return this.apiClient
            .executeGet<QualityMetricValue[]>("QualityGradeTemplate/metricValues");
    }

    upsertQualityMetricValue = (value: QualityMetricValue) => {
        return this.apiClient
            .executePostWithResult<QualityMetricValue>(`QualityGradeTemplate/metricValues`, value);
    }

    deleteQualityMetricValue = (id: number) => {
        return this.apiClient
            .executeDeleteWithResult<QualityMetricValue[]>(`QualityGradeTemplate/metricValues/${id}`);
    }

    resequenceQualityMetricValue = (qualityMetricValueId: number, newSequence: number) => {
        return this.apiClient
            .executePostWithResult<QualityMetricValue[]>(`QualityGradeTemplate/metricValues/resequence`, {
                entityId: qualityMetricValueId,
                newSequenceValue: newSequence
            });
    }







    getQualityGradeKinds = (): Promise<QualityGradeKind[]> => {
        return this.apiClient
            .executeGet<QualityGradeKind[]>("QualityGradeTemplate/gradeKinds")
            .then(gradeKindSerializer.serializeSet);
    }

    upsertQualityGradeKind = (value: QualityGradeKind) => {
        return this.apiClient
            .executePostWithResult<QualityGradeKind>(`QualityGradeTemplate/gradeKinds`, value)
            .then(gradeKindSerializer.serializeSingle);
    }

    publishQualityGradeKind = (value: QualityGradeKind) => {
        return this.apiClient
            .executePostWithResult<QualityGradeKind>(`QualityGradeTemplate/gradeKinds/${value.id}/publish`)
            .then(gradeKindSerializer.serializeSingle);
    }

    deleteQualityGradeKind = (id: number) => {
        return this.apiClient
            .executeDelete(`QualityGradeTemplate/gradeKinds/${id}`);
    }

    getQualityGradeValues = (): Promise<QualityGradeValue[]> => {
        return this.apiClient
            .executeGet<QualityGradeValue[]>("QualityGradeTemplate/gradeValues");
    }

    upsertQualityGradeValue = (value: QualityGradeValue) => {
        return this.apiClient
            .executePostWithResult<QualityGradeValue>(`QualityGradeTemplate/gradeValues`, value);
    }

    deleteQualityGradeValue = (id: number) => {
        return this.apiClient
            .executeDeleteWithResult<QualityGradeValue[]>(`QualityGradeTemplate/gradeValues/${id}`);
    }

    resequenceQualityGradeValue = (qualityGradeValueId: number, newSequence: number) => {
        return this.apiClient
            .executePostWithResult<QualityGradeValue[]>(`QualityGradeTemplate/gradeValues/resequence`, {
                entityId: qualityGradeValueId,
                newSequenceValue: newSequence
            });
    }

    getAssessmentTemplates = (): Promise<AssessmentTemplate[]> => {
        return this.apiClient
            .executeGet<AssessmentTemplate[]>("QualityGradeTemplate/templates")
            .then(templateSerializer.serializeSet);
    }

    getAssessmentTemplateById = (templateId: number): Promise<AssessmentTemplate> => {
        return this.apiClient
            .executeGet<AssessmentTemplate>(`QualityGradeTemplate/templates/${templateId}`)
            .then(templateSerializer.serializeSingle);
    }

    copyAssessmentTemplate = (templateId: number): Promise<AssessmentTemplate> => {
        return this.apiClient
            .executePostWithResult<AssessmentTemplate>(`QualityGradeTemplate/templates/${templateId}/copy`)
            .then(templateSerializer.serializeSingle);
    }

    upsertAssessmentTemplate = (value: AssessmentTemplate) => {
        return this.apiClient
            .executePostWithResult<AssessmentTemplate>(`QualityGradeTemplate/templates`, value)
            .then(templateSerializer.serializeSingle);
    }

    publishAssessmentTemplate = (value: AssessmentTemplate) => {
        return this.apiClient
            .executePostWithResult<AssessmentTemplate>(`QualityGradeTemplate/templates/${value.id}/publish`)
            .then(templateSerializer.serializeSingle);
    }

    deleteAssessmentTemplate = (id: number) => {
        return this.apiClient
            .executeDelete(`QualityGradeTemplate/templates/${id}`);
    }


    getAllTemplateMetrics = (): Promise<TemplateMetric[]> => {
        return this.apiClient
            .executeGet<TemplateMetric[]>(`QualityGradeTemplate/templates/metrics`);
    }

    getTemplateMetrics = (templateId: number): Promise<TemplateMetric[]> => {
        return this.apiClient
            .executeGet<TemplateMetric[]>(`QualityGradeTemplate/templates/${templateId}/metrics`);
    }

    addMetricsToTemplate = (assignmentRequest: TemplateMetricAssignment) => {
        return this.apiClient
            .executePostWithResult<TemplateMetric[]>(`QualityGradeTemplate/templates/metrics/assign`, assignmentRequest);
    }

    updateTemplateMetric = (templateMetric: TemplateMetric) => {
        return this.apiClient
            .executePostWithResult<TemplateMetric>(`QualityGradeTemplate/templates/metrics`, templateMetric);
    }

    removeMetricFromTemplate = (templateMetric: TemplateMetric) => {
        return this.apiClient
            .executeDeleteWithResult<TemplateMetric[]>(`QualityGradeTemplate/templates/metrics/${templateMetric.id}`);
    }

    resequenceTemplateMetric = (templateMetricId: number, newSequence: number) => {
        return this.apiClient
            .executePostWithResult<TemplateMetric[]>(`QualityGradeTemplate/templates/metrics/resequence`, {
                entityId: templateMetricId,
                newSequenceValue: newSequence
            });
    }

    getTemplateConstraints = (templateId: number): Promise<TemplateConstraint[]> => {
        return this.apiClient
            .executeGet<TemplateConstraint[]>(`QualityGradeTemplate/templates/${templateId}/constraints`);
    }

    getTemplateConstraintNodes = (templateId: number): Promise<TemplateConstraintNode[]> => {
        return this.apiClient
            .executeGet<TemplateConstraintNode[]>(`QualityGradeTemplate/templates/${templateId}/constraints/nodes`);
    }

    upsertTemplateConstraintNode = (templateId: number, gradeValueId: number, node: TemplateConstraintNode): Promise<TemplateConstraintNode> => {
        return this.apiClient
            .executePostWithResult<TemplateConstraintNode>(`QualityGradeTemplate/templates/${templateId}/constraints/${gradeValueId}/nodes`, node);
    }

    deleteTemplateConstraintNode = (nodeId: number): Promise<Response> => {
        return this.apiClient
            .executeDelete(`QualityGradeTemplate/templates/constraints/nodes/${nodeId}`);
    }

    upsertTemplateConstraint = (constraint: TemplateConstraint) => {
        return this.apiClient
            .executePostWithResult<TemplateConstraint>(`QualityGradeTemplate/templates/constraints`, constraint);
    }

    deleteTemplateConstraint = (constraint: TemplateConstraint) => {
        return this.apiClient
            .executeDelete(`QualityGradeTemplate/templates/constraints/${constraint.id}`);
    }

    evaluateAssessment = (assessmentEvaluationRequest: AssessmentEvaluationRequest) => {
        return this.apiClient
            .executePostWithResult<QualityGradeValue>(`Quality/assessment/evaluate`, assessmentEvaluationRequest);
    }

    getAssessment = (assessmentId: number) => {
        return this.apiClient
            .executeGet<QualityAssessment>(`Quality/assessment/${assessmentId}`)
            .then(assessmentSerializer.serializeSingle);
    }

    completeAssessment = (assessmentId: number) => {
        return this.apiClient
            .executePostWithResult<QualityAssessment>(`Quality/assessment/${assessmentId}/complete`)
            .then(assessmentSerializer.serializeSingle);
    }

    reopenAssessment = (assessmentId: number) => {
        return this.apiClient
            .executePostWithResult<QualityAssessment>(`Quality/assessment/${assessmentId}/reopen`)
            .then(assessmentSerializer.serializeSingle);
    }

    getAssessmentValues = (assessmentId: number) => {
        return this.apiClient
            .executeGet<QualityAssessmentValue[]>(`Quality/assessment/${assessmentId}/values`)
            .then(assessmentValueSerializer.serializeSet);
    }

    saveAssessmentValue = (assessmentValue: QualityAssessmentValue) => {
        return this.apiClient
            .executePostWithResult<QualityAssessmentValue>(`Quality/assessment/values`, assessmentValue)
            .then(assessmentValueSerializer.serializeSingle);
    }

    deleteAssessmentValue = (assessmentValueId: number) => {
        return this.apiClient
            .executeDelete(`Quality/assessment/values/${assessmentValueId}`);
    }
}