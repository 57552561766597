import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { StringField } from "../../../../components/formik/stringField";
import { QualityMetricKind } from "../../models/metricKind";

export const AddQualityMetricKindEditor: React.FC = () => {

    return <FlexColumnContainer gap="20px" padding="20px">

        <StringField<QualityMetricKind>
            fieldName="name"
            defaultLabel="Name"            
            containerStyle={{ display: "flex", alignItems: "center" }}
            labelStyle={{ display: "inline-block", width: "80px" }}
            inputStyle={{ width: "250px" }}
        />

    </FlexColumnContainer>

}
