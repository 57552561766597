import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { Menu } from "../../../menu/editor/store/types";

export const sliceName: string = "favorite-menus";

export const actionFactory = new ActionFactory<FavoriteMenuState>(sliceName);

export interface FavoriteMenuState {
    loadingState: ActionStatus;  
    favorites: Menu[];      
    invites: Menu[];      
}