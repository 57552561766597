import ApiClient from "../../apiCommunication/apiClient";
import { deserializeSyncRequestDates, SyncRequestDto } from "../../commonModels/syncRequest";
import { ImageDetailsUpdate, Product, ProductNote } from "../productManagement/store/types";
import { EntityImage } from "../../commonModels/entityImage";
import { BulkCategoryAssignmentRequest, BulkCategoryClearRequest } from "../general/models/bulkCategoryRequests";
import { BulkPricingModelRequest } from "../general/models/bulkPricingModelRequest";
import { EntityVideo } from "../../commonModels/entityVideo";
import { ISerializer, SerializationBuilder } from "../../apiCommunication/serializationBuilder";

export default class ProductManagement {

    


    private apiClient: ApiClient;
    private productNoteSerializer: ISerializer<ProductNote>;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
        this.productNoteSerializer = SerializationBuilder
            .forType<ProductNote>()
            .parseUtcDatesToLocalTime(["createdUtc", "modifiedUtc"])            
            .build();
    }

    getProducts = (): Promise<Product[]> => {
        return this
            .apiClient
            .executeGet<Product[]>(`product`);
    };

    searchProducts = (searchText: string): Promise<Product[]> => {
        return this
            .apiClient
            .executePostWithResult<Product[]>(`product/search`, { productName: searchText });
    };

    getProductImages = (productId: number): Promise<EntityImage[]> => {
        return this
            .apiClient
            .executeGet<EntityImage[]>(`product/${productId}/image`);
    };

    getProductVideos = (inventoryId: number): Promise<EntityVideo[]> => {
        return this
            .apiClient
            .executeGet<EntityVideo[]>(`product/${inventoryId}/video`);
    };

    upsertProductVideo = (video: EntityVideo): Promise<EntityVideo> => {
        return this
            .apiClient
            .executePostWithResult<EntityVideo>(`product/video`, video);
    };

    deleteProductVideo = (video: EntityVideo): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`product/video/${video.id}`);
    };

    updateImageDetails = (imageDetailsUpdate: ImageDetailsUpdate): Promise<EntityImage> => {
        return this
            .apiClient
            .executePostWithResult<EntityImage>(`product/image`, imageDetailsUpdate);
    };

    deleteProductImage = (id: number): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`product/image/${id}`);
    };

    uploadProductImage = async (productId: number, file: Blob | string | File): Promise<EntityImage> => {

        const formData = new FormData();
        formData.append("file", file);

        return this
            .apiClient
            .executeFormDataPostWithResult<EntityImage>(`product/${productId}/image`, formData);
    };
    
    saveProduct = (productUpdate: Product): Promise<Product> => {
        return this
            .apiClient
            .executePostWithResult<Product>(`product`, productUpdate);
    };

    getCategoryIdsForProduct = (productId: number): Promise<number[]> => {
        return this
            .apiClient
            .executeGet<number[]>(`product/${productId}/categoryIds`);
    }; 

    setCategoryIdsForProduct = (productId: number, categoryIds: number[]): Promise<Response> => {
        return this
            .apiClient
            .executePost(`product/${productId}/categoryIds`, categoryIds);
    }; 
    

    assignPricingModelToProducts = (pricingModelAssignment: BulkPricingModelRequest): Promise<Response> => {
        return this
        .apiClient
        .executePost(`product/pricingModel/assignment`, pricingModelAssignment);
    }

    assignCategoriesToProducts = (productCategoryAssignments: BulkCategoryAssignmentRequest): Promise<Response> => {
        return this
        .apiClient
        .executePost(`product/category/assignment`, productCategoryAssignments);
    }

    clearInventoryCategories = (productCategoryAssignments: BulkCategoryClearRequest): Promise<Response> => {
        return this
        .apiClient
        .executePost(`product/category/clear`, productCategoryAssignments);
    }
    
    getLatestProductSyncRequest = (): Promise<SyncRequestDto | null> => {
        return this
            .apiClient
            .executeGet<SyncRequestDto | null>(`SyncAdmin/product/sync`)
            .then(result => {
                if (result !== null) {
                    return deserializeSyncRequestDates(result);
                }
                return result;
            });
    };

    requestProductSync = (): Promise<SyncRequestDto> => {
        return this
            .apiClient
            .executePostWithResult<SyncRequestDto>(`SyncAdmin/product/sync`)
            .then(deserializeSyncRequestDates);
    };

    getProductNotes = (productId: number): Promise<ProductNote[]> => {
        return this
            .apiClient
            .executeGet<ProductNote[]>(`product/${productId}/notes`)
            .then(this.productNoteSerializer.serializeSet);
    };

    deleteProductNote = (id: number): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`product/notes/${id}`);
    };

    upsertProductNote = (productNote: ProductNote): Promise<ProductNote> => {
        return this
            .apiClient
            .executePostWithResult<ProductNote>(`product/notes`, productNote)
            .then(this.productNoteSerializer.serializeSingle);
    };

}