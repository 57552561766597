import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { ErrorDisplayType } from "../../../../../store/factory/sagaWithProgress/types";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory, TermsOfServiceUserAcceptance, AcceptTermsOfServiceState } from "../types";

export const loadSliceData = actionFactory.createAppAction("loadSliceData");

const sagaActions = actionFactory.createSagaWithProgressActions<void, TermsOfServiceUserAcceptance>(loadSliceData.type);

export function loadSliceDataReducers(reducerFactory: IReducerFactory<AcceptTermsOfServiceState>): IReducerFactory<AcceptTermsOfServiceState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.loadingState)
        .onFulfilled((state, action) => {
            state.termsOfServiceAcceptance = action.payload.result;            
        })
        .build();
}

async function performLoad(_arg: void, _context: SagaAppContext): Promise<TermsOfServiceUserAcceptance> {   
    return _context.marketplaceApiClient.termsOfService.getTermsOfServiceAcceptance();    
}

export function createLoadSliceDataSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions,
            errorDisplay: ErrorDisplayType.None
        },
        actionPattern: loadSliceData.type,
        debounceInMilliseconds: 250
    });
};