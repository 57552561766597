import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { GalleryTestingState } from './types';

export const createInitialState = (): GalleryTestingState => {
    return {        
        loadingState: CreateActionStatusInitialState(),      
        data: [] 
    };
};

export const initialState: GalleryTestingState = createInitialState();