import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { getAssignedCategories } from "../../categoryAssignment/store/selectors/categoryAssignment";
import { CategoryAssignment } from "../../categoryAssignment/ui/categoryAssignment";
import { cancelBulkCategoryAssignment } from "../store/featureActions/bulkCategoryAssignment/cancelBulkCategoryAssignment";
import { completeBulkCategoryAssignment } from "../store/featureActions/bulkCategoryAssignment/completeBulkCategoryAssignment";
import { getBulkAssignmentSavingState, getBulkCategoryAssignmentDialogTitle, getBulkCategoryAssignmentDialogVisible } from "../store/selectors/bulkCategoryAssignment";

export const BulkAssignCategoriesDialog: React.FC = () => {

    const bulkCategoryAssignmentDialogVisible = useAppSelector(getBulkCategoryAssignmentDialogVisible);
    const bulkAssignmentSavingState = useAppSelector(getBulkAssignmentSavingState)
    const assignedCategoryIds = useAppSelector(getAssignedCategories);
    const title = useAppSelector(getBulkCategoryAssignmentDialogTitle);

    const dispatch = useDispatch();

    const onHideDialog = () => { };

    const onSave = () => {
        dispatch(completeBulkCategoryAssignment(assignedCategoryIds));
    }

    const onCancel = () => {
        dispatch(cancelBulkCategoryAssignment());
    }

    return <Dialog
        onHide={onHideDialog}
        visible={bulkCategoryAssignmentDialogVisible}
        closable={false}
        draggable={false}
        header={title}
    >
        <CategoryAssignment />
        <div>
            <Button className="p-ml-2" label="Save" style={{ float: "right" }} onClick={onSave} loading={bulkAssignmentSavingState.isExecuting} />
            <Button label="Cancel" style={{ float: "right" }} onClick={onCancel} disabled={bulkAssignmentSavingState.isExecuting} />
        </div>
    </Dialog>
}