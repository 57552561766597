import { Tree, TreeExpandedParams } from 'primereact/tree';
import { useDispatch } from 'react-redux';
import { useAppSelector } from "../../../../store/configureStore";
import { mapToTreeNode, TypedTreeNode } from "../../../../utils/treeHelper";
import { isValue } from "../../../../utils/valueHelper";
import { TemplateConstraintNode } from "../../models/constraintNode";
import { setExpandedTreeNodes } from '../store/featureActions/setExpandedTreeNodes';
import { getConstraintNodeById, getExpandedTreeNodesForGradeValue } from "../store/selectors/data";
import { createViewForNode } from './constrainNodeViewFactory';

export interface ConstraintNodeTreeProps {
    constraintNodeId: number;
    gradeValueId: number;
}

export const ConstraintNodeTree: React.FC<ConstraintNodeTreeProps> = ({ constraintNodeId, gradeValueId }) => {

    const dispatch = useDispatch();
    const constraint = useAppSelector(state => getConstraintNodeById(state, constraintNodeId));
    const expandedTreeNodes = useAppSelector(state => getExpandedTreeNodesForGradeValue(state, gradeValueId));

    const rootTreeNode = isValue(constraint)
        ? mapToTreeNode<TemplateConstraintNode>(
            constraint,
            {
                getKey: node => node.id,
                getChildren: node => node.children ?? [],
                title: node => node.kind, // createViewForNode(node, gradeValueId),                
                className: _node => "constraint-editor-tree-node"
            })
        : null;

    const nodeTemplate = (node: TypedTreeNode<TemplateConstraintNode>): React.ReactNode => {
        return createViewForNode(node.data, gradeValueId);
    }

    const onExpandToggle = (e: TreeExpandedParams) => {
        dispatch(setExpandedTreeNodes({
            gradeValueId: gradeValueId,
            expandedKeys: e.value
        }));
    };

    return !isValue(rootTreeNode)
        ? <div>Constraint Not Found</div>
        : <Tree
            value={[rootTreeNode]}
            nodeTemplate={nodeTemplate}
            className="quality-template-constraint-node-tree"
            selectionMode='single'
            onToggle={onExpandToggle}     
            expandedKeys={expandedTreeNodes?.expandedKeys ?? {}}   
        />
}