import { EntityState } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ModalEditState } from "../../../../store/factory/modalEdit/modalEdit";
import { IdentityMap } from "../../../../utils/identity";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { BulkAssignmentKind, BulkValueAssignmentState } from "../../../commonModels/bulkAssignmentState";
import { EntityImage } from "../../../commonModels/entityImage";
import { EntityVideo } from "../../../commonModels/entityVideo";
import { IntegerId } from "../../../commonModels/integerId";
import { SyncRequestDto } from "../../../commonModels/syncRequest";
import { PricingModel } from "../../../pricing/sharedTypes";
import { ProductMenuAssignment } from "../../general/models/menuAssignment";

export const sliceName: string = "product-management";

export const actionFactory = new ActionFactory<ProductManagementState>(sliceName);

export enum InventorySyncKind {
    Automated = "Automated",
    Manual = "Manual",
    Exclude = "Exclude"
}

export interface Product {
    id: number;
    tenantId: string;
    categoryIds: number[];
    menuThumbnailId: number | null;
    licenseNumber: string | null;
    licenseId: number | null;
    metrcItemId: number;
    metrcName: string;
    metrcCategoryName: string;
    metrcCategoryType: string;
    metrcStrainName: string;
    inventorySyncKind: InventorySyncKind;
    marketingName: string | null;
    marketingStrainName: string | null;
    marketingDescription: string | null;
    thcPercentage: number | null;
    cbdPercentage: number | null;
    unitOfMeasureName: string;
    pricingModelId: number | null;
    onMenuDirectly?: boolean; // View Projection
}

export interface ImageDetailsUpdate {
    id: number;
    imageTitle: string;
    useAsMenuThumbnail: boolean;
}

export interface BulkCategoryAssignmentState {
    savingState: ActionStatus;
    products: Product[];
};

export interface BulkSyncKindAssignmentState extends BulkValueAssignmentState<Product, InventorySyncKind> { };

export interface CategoryAssignmentState {
    loadingState: ActionStatus;
    savingState: ActionStatus;
    parentModel?: Product;
}

export interface BulkPricingModelAssignmentState {
    savingState: ActionStatus;
    products: Product[];
    pricingModelIdToAssign: number | null;
    bulkAssignmentKind: BulkAssignmentKind;
};

export interface ProductNote extends IntegerId {
    productId: number;
    tenantId: string;
    notes: string;
    createdBy: string;
    createdUtc: DateTime;
    modifiedUtc: DateTime;
}

export interface ProductManagementState {
    loadingState: ActionStatus;
    products: EntityState<Product>;
    latestSyncRequest: SyncRequestDto | null;
    requestProductSyncState: ActionStatus;
    selectedProducts: Product[];
    editProductState: ModalEditState<Product>;
    editSaveState: ActionStatus;
    productNoteLoadStatus: ActionStatus;
    productNoteSaveStatus: ActionStatus;
    editProductNotes: EntityState<ProductNote>;

    // editMediaEntity?: Product;
    // editImages: EntityImage[];
    // mediaLoadState: ActionStatus;
    // editImageSaveState: ActionStatus;

    editMediaEntity?: Product;
    editImages: EntityImage[];
    editVideos: EntityState<EntityVideo>;
    addVideoState: ModalEditState<EntityVideo>;
    videoSaveState: ActionStatus;
    mediaLoadState: ActionStatus;
    editImageSaveState: ActionStatus;


    bulkCategoryAssignmentState: BulkCategoryAssignmentState;
    bulkClearCategoryAssignmentState: BulkCategoryAssignmentState;
    categoryAssignmentState: CategoryAssignmentState;
    bulkSyncKindAssignmentState: BulkSyncKindAssignmentState;
    menuAssignmentsLoadState: ActionStatus;
    menuAssignments: ProductMenuAssignment[];
    pricingModelMap: IdentityMap<PricingModel>;
    pricingModels: PricingModel[];
    bulkPricingModelAssignmentState: BulkPricingModelAssignmentState;
}