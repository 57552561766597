import { TemplateConstraint } from "../../../models/templateConstraint";
import { templateConstraintAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    deleteTemplateConstraint,
    deleteTemplateConstraintReducer,
    deleteTemplateConstraintSagaFactory

} = actionFactory
    .withMappedName("deleteTemplateConstraint")
    .createAsyncAction<TemplateConstraint, Response>({
        actionStatusSelector: state => state.templateMetricSaveState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.quality.deleteTemplateConstraint(arg);
        },
        onFulfilled: (state, action) => {
            templateConstraintAdapter.removeOne(state.templateConstraints, action.payload.arg.id);
        }
    });