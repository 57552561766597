import * as yup from 'yup';
import { ComparisonOperation } from '../models/comparisonOperation12';
import { ConstraintNodeKind, TemplateConstraintNode } from '../models/constraintNode';

export const constraintNodeValidationsSchema: yup.SchemaOf<TemplateConstraintNode> = yup.object().shape({
    id: yup.number().required("ID is required"),
    children: yup.array().optional(),
    comparison: yup.mixed<ComparisonOperation>().required(),
    kind: yup.mixed<ConstraintNodeKind>().required(),
    metricKindId: yup.number().required(),
    metricValueIds: yup.array().required().min(1),
    parentNodeId: yup.number().defined().nullable(),
    rootNodeId: yup.number().defined().nullable()
});