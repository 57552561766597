import { Customer } from "../../../common/types";
import { actionFactory } from "../types";

export const {

    saveCustomer,
    saveCustomerReducer,
    saveCustomerSagaFactory

} = actionFactory
    .withMappedName("saveCustomer")
    .createAsyncAction<Customer, Customer>({    
    actionStatusSelector: state => state.loadingState,
    asyncCall: (arg, context) => {
       return context.marketplaceApiClient.customer.upsertCustomer(arg);
    },
    onFulfilled: (state, action) => {
        state.customer = action.payload.result;
    }
});