import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../store/factory/reducerFactory';
import { acknowledgeLoginErrorReducer } from './featureActions/acknowledgeLoginError';
import { beginLoginReducers } from './featureActions/beginLogin';
import { beginLogoutReducers } from './featureActions/beginLogout';
import { clearSessionReducer } from './featureActions/clearSession';
import { completeLoginReducer } from './featureActions/completeLogin';
import { completeLogoutReducers } from './featureActions/completeLogout';
import { loadExistingAuthReducer } from './featureActions/loadExistingAuth';
import { silentRenewReducers } from './featureActions/silentRenew';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(loadExistingAuthReducer)
            .add(beginLoginReducers)
            .add(completeLoginReducer)
            .add(beginLogoutReducers)
            .add(completeLogoutReducers)
            .add(acknowledgeLoginErrorReducer)
            .add(clearSessionReducer)
            .add(silentRenewReducers);
    }
});

export default slice.reducer;

export const { resetState } = slice.actions;