import { useDispatch } from "react-redux";
import { deleteLogo } from "../store/featureActions/images/deleteLogo";
import { deleteNewLogo } from "../store/featureActions/images/deleteNewLogo";
import { getCompanyLogo, getLoadLogoState, getNewLogo, getSaveLogoState } from "../store/selectors/logo";
import { ImageSelector } from "./imageSelector";
import { setNewLogo } from "../store/featureActions/images/setNewLogo";
import { saveLogo } from "../store/featureActions/images/saveLogo";
 
export const LogoSetup: React.FC = () => {

    const dispatch = useDispatch();

    const onSelectLogo = (fileUrl: string) => {
        dispatch(setNewLogo(fileUrl));
    }

    const onReplaceLogo = () => {
        dispatch(saveLogo());
    }

    const onDeleteLogo = () => {
        dispatch(deleteLogo());
    }

    const onDeleteNewLogo = () => {
        dispatch(deleteNewLogo());
    }

    return <div className="p-mt-2" title="Logo">

        <ImageSelector
            currentImageSelector={getCompanyLogo}
            newImageSelector={getNewLogo}
            saveStateSelector={getSaveLogoState}
            loadStateSelector={getLoadLogoState}
            title="Logo"
            onNewImageSet={onSelectLogo}
            onReplaceImage={onReplaceLogo}
            onDeleteCurrentImage={onDeleteLogo}
            onDeleteNewImage={onDeleteNewLogo}
            imageSize={{ width: "120px", height: "120px" }}
            imageContainerSize={{ width: "240px", height: "220px" }}
        />
    </div>
}