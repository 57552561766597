import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ErrorContent } from '../../../features/errorHandling/types';

export enum NotAuthorizedAction {
    NotAuthorizedEvent,
    BeginLogin
}

export enum ErrorDisplayType {
    Dialog,
    Toast,
    None
}

export interface PendingPayload<TArg> {
    arg: TArg;
}
export interface RejectedPayload<TArg> {
    arg: TArg;
    errorResult?: ErrorContent;
}
export interface FulfilledPayload<TArg, TResult> {
    arg: TArg;
    result: TResult;
}
export interface SagaActions<TArg, TResult> {
    pending: ActionCreatorWithPayload<PendingPayload<TArg>>;
    rejected: ActionCreatorWithPayload<RejectedPayload<TArg>>;
    fulfilled: ActionCreatorWithPayload<FulfilledPayload<TArg, TResult>>;   
}
