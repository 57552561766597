import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, CorporateEntityState } from "../types";

export const setInactiveFilter = actionFactory.createAppAction<boolean[]>("setInactiveFilter");

export function setInactiveFilterReducers(reducerFactory: IReducerFactory<CorporateEntityState>): IReducerFactory<CorporateEntityState> {
    return reducerFactory
        .forAction(setInactiveFilter)
        .addHandler((state, action) => {
            state.corporateEntityFilter.inactiveValues = action.payload;            
        })
        .build();
}