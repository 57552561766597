import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory, MenuAdminState, Menu } from "../types";

export const upsertMenu = actionFactory.createAppAction<Menu>("upsertMenu");

const sagaActions = actionFactory.createSagaWithProgressActions<Menu, Menu>(upsertMenu.type);

export function upsertMenuReducers(reducerFactory: IReducerFactory<MenuAdminState>): IReducerFactory<MenuAdminState> {
    return reducerFactory
        .forAction(upsertMenu)
        .addHandler((state, action) => {
            state.menu = { ...state.menu, ...action.payload };
        })
        .build()
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.loadingState)
        .onFulfilled((state, action) => {
            state.menu = action.payload.result;
        })
        .build();
}

async function performLoad(arg: Menu, context: SagaAppContext): Promise<Menu> {
    return context.marketplaceApiClient.menuAdmin.upsertMenu(arg);
}

export function createUpsertMenuSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: upsertMenu.type,
        debounceInMilliseconds: 750
    });
};