import clsx from "clsx";
import { Field, FieldProps, useFormikContext } from "formik";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { CompanyProfile } from "../store/types";


export const ContactDetails: React.FC = () => {
 
    const formik = useFormikContext<Partial<CompanyProfile>>();

    return <div style={{ maxWidth: "1000px" }}>

        <div className="p-fluid">

            <div className="p-field">
                <label>Company Website Url</label>
                <Field
                    type="text"
                    name="contactUrl"
                    className={clsx({ "p-invalid": !!formik.errors.contactUrl })}
                    onBlur={() => formik.submitForm()}
                    as={InputText}>
                </Field>
                <small>{formik.errors.contactUrl}</small>
            </div>

            <div className="p-field">
                <label>Subscription Url</label>
                <Field
                    type="text"
                    name="subscriptionUrl"
                    className={clsx({ "p-invalid": !!formik.errors.subscriptionUrl })}
                    onBlur={() => formik.submitForm()}
                    as={InputText}>
                </Field>
                <small>{formik.errors.subscriptionUrl}</small>
            </div>

            <div className="p-field">
                <label>Company Email</label>
                <Field
                    type="text"
                    name="contactEmail"
                    className={clsx({ "p-invalid": !!formik.errors.contactEmail })}
                    onBlur={() => formik.submitForm()}
                    as={InputText}>
                </Field>
                <small>{formik.errors.contactEmail}</small>
            </div>

            <div className="p-field">
                <label>Company Phone Number</label>
                <Field
                    type="text"
                    name="contactPhone"
                >
                    {
                        (props: FieldProps<Partial<CompanyProfile>>) =>
                            <InputMask
                                mask="(999) 999-9999"
                                id="contactPhone"
                                name="contactPhone"
                                value={formik.values.contactPhone ?? undefined}
                                onChange={(event) => {
                                    formik.setFieldValue(props.field.name, event.target.value);
                                }}
                                onBlur={() => formik.submitForm()}
                            />
                    }
                </Field>
                <small>{formik.errors.contactPhone}</small>
            </div>

        </div>
    </div>


}