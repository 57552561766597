import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { isValue } from "../../../../../utils/valueHelper";
import { ImageContent } from "../../../../commonModels/imageTypes";
import { inventoryAdapter, inventoryImageAdapter } from "../state";

const inventorySelectors = inventoryAdapter.getSelectors((state: RootState) => state.liveMenu.inventoryLookup);

export const getInventoryById = inventorySelectors.selectById;

const inventoryImageSelectors = inventoryImageAdapter.getSelectors((state: RootState) => state.liveMenu.inventoryImages);

const getDefaultThumbnail = (state: RootState) => state.liveMenu.defaultThumbnail;

export const getInventoryImageById = createSelector([
    inventoryImageSelectors.selectById,
    getDefaultThumbnail
], (inventoryImage, defaultThumbnail) => {
    const imageContext: ImageContent | undefined =
        isValue(inventoryImage) &&
            isValue(inventoryImage.imageData) &&
            isValue(inventoryImage.mimeType)
            ? inventoryImage
            : isValue(defaultThumbnail) &&
                isValue(defaultThumbnail.imageData) &&
                isValue(defaultThumbnail.mimeType)
                ? defaultThumbnail
                : undefined;
    return imageContext;
});