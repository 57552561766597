import { EntityImage } from "../features/commonModels/entityImage";
import { ImageContent } from "../features/commonModels/imageTypes";


export function createLocalImageUrl(imageDto: ImageContent): string {
    return `data:${imageDto.mimeType};base64,${imageDto.imageData}`;    
}

export function createLocalImageUrlFromEntityImage(entityImage: EntityImage): string {
    return `data:${entityImage.mimeType};base64,${entityImage.imageData}`;
}

export const AcceptedImageFileExtensions: string = ".jpg,.png,.tga,.bmp,.gif";

export const DefaultMaxImageSizeBytes: number = 1000000;
export const DefaultMaxImageSizeMB: number = 1;
export const MaxLogoSize: number = DefaultMaxImageSizeBytes;
export const MaxBannerSize: number = DefaultMaxImageSizeBytes;
export const MaxProductImageSize: number = DefaultMaxImageSizeBytes;