import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { cancelBulkExclude } from "../store/featureActions/bulkExcludeFromMarketplace/cancelBulkExclude";
import { completeBulkExclude } from "../store/featureActions/bulkExcludeFromMarketplace/completeBulkExclude";
import { getActionDescription, getActionTitle, getBulkExcludeSaveState, getShowBulkExcludeConfirmation } from "../store/selectors/bulkExclude";
import './bulkExcludeDialog.css';

export const BulkExcludeDialog: React.FC = () => {

    const dispatch = useDispatch();
    const showConfirmation = useAppSelector(getShowBulkExcludeConfirmation);
    const saveState = useAppSelector(getBulkExcludeSaveState);
    const title = useAppSelector(getActionTitle);
    const description = useAppSelector(getActionDescription);

    const onHideDialog = () => { };

    const onCancel = () => {
        dispatch(cancelBulkExclude());
    }

    const onComplete = () => {
        dispatch(completeBulkExclude());
    }


    return <Dialog
        onHide={onHideDialog}
        visible={showConfirmation}
        closable={false}
        draggable={false}
        header={title}
    >
        <div>
            <div className="bulk-exclude-info">{description}</div>

            <div className="p-d-flex p-jc-end">
                <Button className="" label="Cancel" onClick={onCancel} disabled={saveState.isExecuting} />
                <Button className="p-ml-2" label="Save" onClick={onComplete} loading={saveState.isExecuting} />
            </div>

        </div>

    </Dialog>
}