import { getFilteredEntities } from "../../../../../utils/entityStateHelpers";
import { isValue } from "../../../../../utils/valueHelper";
import { mergeViewDefintionWithTableState } from "../logic/columnHelper";
import { deserializeTableState } from "../logic/settingsSerialization";
import { viewSettingsAdapter } from "../state";
import { actionFactory, TableState, ViewSettings } from "../types";

export const {

    selectFirstTableSettings,
    selectFirstTableSettingsReducer

} = actionFactory
    .withMappedName("selectFirstTableSettings")
    .createAction<{ viewId: string }>((state, action) => {
        const settings = state.viewSettings.entities[action.payload.viewId];

        const userTableSettingsForView = getFilteredEntities(
            state.userTableSettings, 
            userTableSettings => userTableSettings.viewId === action.payload.viewId);

        const defaultUserSettings = userTableSettingsForView.length > 0
            ? userTableSettingsForView[0]
            : null;
            
        const deserializedTableState = isValue(defaultUserSettings)
            ? deserializeTableState(defaultUserSettings.settings)
            : undefined;
        
        if (!isValue(settings)) return;

        const tableStateToUse: TableState = deserializedTableState ?? settings.currentState;

        const viewColumnDefinitions = state.viewDefinitions.entities[action.payload.viewId];        
        if (isValue(viewColumnDefinitions)) {
            mergeViewDefintionWithTableState(viewColumnDefinitions, tableStateToUse);
        }

        const mergedTableSettings: ViewSettings = {
            id: settings.id,
            selectedUserTableSettings: defaultUserSettings?.id ?? null,
            currentState: tableStateToUse,
            remountState: settings.remountState,
            restoreSentinel: false
        };

        viewSettingsAdapter.upsertOne(state.viewSettings, mergedTableSettings)
    });