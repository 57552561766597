import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { ProductDetailsState } from './types';

export const createInitialState = (): ProductDetailsState => {
    return {
        vendor: null,
        vendorLoadingState: CreateActionStatusInitialState(),
        
        inventoryView: null,
        inventoryLoadingState: CreateActionStatusInitialState(),              
        
        vendorMenu: null,
        vendorMenuLoadingState: CreateActionStatusInitialState(),

        thumbnailImage: null
    };
};

export const initialState: ProductDetailsState = createInitialState();