import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { ManageProfileState } from './types';

export const createInitialState = (): ManageProfileState => {
    return {
        loadingState: CreateActionStatusInitialState(),
        savingState: CreateActionStatusInitialState(),
        MarketplaceProfile: {
            email: "",
            firstName: "",
            id: 0,
            lastName: "",
            phoneNumber: "",
            tenantId: "",
            username: ""
        }  
    };
};

export const initialState: ManageProfileState = createInitialState();