import { createEntityAdapter } from '@reduxjs/toolkit';
import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { Category, CategoryManagementState, CategoryProductAssignmentState } from './types';

export const categoryAdapter = createEntityAdapter<Category>();

export const createCategoryProductAssignmentInitialState = () : CategoryProductAssignmentState => {
    return {
        allProducts: [],            
        assignedProductMap: {},
        loadingState: CreateActionStatusInitialState(),
        selectedAssignedProducts: [],
        selectedAvailableProducts: []
    }
}

export const createInitialState = (): CategoryManagementState => {
    return {        
        categoryProductAssignment: createCategoryProductAssignmentInitialState()
    };
};

export const initialState: CategoryManagementState = createInitialState();