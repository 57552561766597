import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { addQualityMetricKindSagaFactory } from "../featureActions/addQualityMetricKind";
import { deleteQualityMetricValueSagaFactory } from "../featureActions/deleteQualityMetricValue";
import { loadSliceDataSagaFactory } from "../featureActions/loadSliceData";
import { publishQualityMetricKindSagaFactory } from "../featureActions/publishQualityMetricKind";
import { resequenceMetricValueSagaFactory } from "../featureActions/resequenceMetricValue";
import { upsertQualityMetricKindSagaFactory } from "../featureActions/upsertQualityMetricKind";
import { upsertQualityMetricValueSagaFactory } from "../featureActions/upsertQualityMetricValue";

// Register this method in the root saga
export function createQualityMetricKindSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        loadSliceDataSagaFactory(context),
        addQualityMetricKindSagaFactory(context),
        upsertQualityMetricKindSagaFactory(context),
        upsertQualityMetricValueSagaFactory(context),
        resequenceMetricValueSagaFactory(context),
        deleteQualityMetricValueSagaFactory(context),
        publishQualityMetricKindSagaFactory(context)
    ]
}