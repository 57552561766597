import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { getIsAuthenticated } from "../../../authentication/store/selectors/isAuthenticated";
import { loadMarketplaceProfile } from "../../../marketplaceUserProfile/manageProfile/store/featureActions/loadProfile";
import { getUserProfile } from "../../../marketplaceUserProfile/manageProfile/store/selectors/userProfile";
import WithoutSessionMenu from "./withoutSession";
import WithSessionMenu from "./withSessionMenu";

const SmallDeviceTopBarMenu: React.FC = () => {
   
    const dispatch = useDispatch();
    const userProfile = useAppSelector(getUserProfile);
    const isLoggedIn = useAppSelector(getIsAuthenticated);

    useEffect(() => {
        if (isLoggedIn && !isValue(userProfile)) {
            dispatch(loadMarketplaceProfile());
        }
    }, [dispatch, isLoggedIn, userProfile]);

    return isLoggedIn
        ? <WithSessionMenu />
        : <WithoutSessionMenu />
}

export default SmallDeviceTopBarMenu;
