import { QualityGradeValue } from "../../../models/gradeValue";
import { gradeValueAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    deleteQualityGradeValue,
    deleteQualityGradeValueReducer,
    deleteQualityGradeValueSagaFactory

} = actionFactory
    .withMappedName("deleteQualityGradeValue")
    .createAsyncAction<QualityGradeValue, QualityGradeValue[]>({
        actionStatusSelector: state => state.gradeValueSaveState,
        asyncCall: (arg, context) => {            
            return context.marketplaceApiClient.quality.deleteQualityGradeValue(arg.id);
        },
        onFulfilled: (state, action) => {
            gradeValueAdapter.setAll(state.gradeValues, action.payload.result);
        }        
    });