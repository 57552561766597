import { Card } from "primereact/card";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { getLoadingState } from "../store/selectors/priceModels";
import { resetState } from "../store/slice";
import { PricingModelTable } from "./pricingModelTable";
import { TopCommandBar } from "./topCommandBar";

export const PricingDashboard: React.FC = () => {

    const dispatch = useDispatch();
    const loadingState = useAppSelector(getLoadingState);    

    useMountEffect(() => {
        dispatch(loadSliceData());

        return () => {
            dispatch(resetState());
        }
    });

    return <OverlayWaitDisplay
        isWaiting={loadingState.isExecuting}
    >
        <Card className="full-height-content-panel" title="Pricing Models">

            <FlexColumnContainer gap="15px">
                <TopCommandBar />
                <PricingModelTable />
            </FlexColumnContainer>

        </Card>
    </OverlayWaitDisplay>
}
