import ApiClient from "../../apiCommunication/apiClient";
import { ISerializer, SerializationBuilder } from "../../apiCommunication/serializationBuilder";
import { EntityImage } from "../../commonModels/entityImage";
import { EntityVideo } from "../../commonModels/entityVideo";
import { deserializeSyncRequestDates, SyncRequestDto } from "../../commonModels/syncRequest";
import { BulkCategoryAssignmentRequest, BulkCategoryClearRequest } from "../general/models/bulkCategoryRequests";
import { BulkQualityGradeRequest } from "../general/models/bulkQualityGradeRequest";
import { InventorySearchFilter } from "../general/models/searchFilter";
import { ImageDetailsUpdate, Inventory, InventoryNote, OrderedInventoryDetails, OrderedInventoryQuantity, OrderedQuantityFilter, QualityAssessmentAssignment, ReadyDateAssignment, RemoveQualityAssessment, SyncInventoryRequest, InventoryLicense, UnitOfMeasure } from "../inventoryManagement/store/types";

export const inventorySerializer = SerializationBuilder
    .forType<Inventory>()
    .parseUtcDatesToLocalTime(["inactiveDate", "readyDate"])
    .parseLocalDates(["packagedDate"])
    .build();

export default class InventoryManagement {

    private apiClient: ApiClient;
    private inventoryNoteSerializer: ISerializer<InventoryNote>;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
        this.inventoryNoteSerializer = SerializationBuilder
            .forType<InventoryNote>()
            .parseUtcDatesToLocalTime(["createdUtc", "modifiedUtc"])            
            .build();
    }
  
    getInventory = (): Promise<Inventory[]> => {
        return this
            .apiClient
            .executeGet<Inventory[]>(`inventory`)
            .then(inventorySerializer.serializeSet);
    }; 

    getInventoryByIds = (inventoryIds: number[]): Promise<Inventory[]> => {
        return this
            .apiClient
            .executePostWithResult<Inventory[]>(`inventory/byIds`, {inventoryIds: inventoryIds })
            .then(inventorySerializer.serializeSet);
    }; 

    saveInventory = (inventory: Inventory): Promise<Inventory> => {
        return this
            .apiClient
            .executePostWithResult<Inventory>(`inventory`, inventory)
            .then(inventorySerializer.serializeSingle);
    }; 
    
    addInventory = (inventory: Inventory): Promise<Inventory> => {
        return this
            .apiClient
            .executePostWithResult<Inventory>(`inventory/upsert`, inventory)
            .then(inventorySerializer.serializeSingle);
    }; 

    
    deleteInventory = (id: number): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`inventory/delete/${id}`);
    };

    getLatestInventorySyncRequest = (): Promise<SyncRequestDto | null> => {
        return this
            .apiClient
            .executeGet<SyncRequestDto | null>(`SyncAdmin/inventory/sync`)
            .then(result => {
                if (result !== null) {
                    return deserializeSyncRequestDates(result);
                }
                return result;
            });
    };
    
    requestInventorySync = (request: SyncInventoryRequest): Promise<SyncRequestDto> => {
        return this
            .apiClient
            .executePostWithResult<SyncRequestDto>(`SyncAdmin/inventory/sync`, request)
            .then(deserializeSyncRequestDates);
    };

    requestPackageSync = (): Promise<SyncRequestDto> => {
        return this
            .apiClient
            .executePostWithResult<SyncRequestDto>(`SyncAdmin/package/sync`)
            .then(deserializeSyncRequestDates);
    };

    bulkExcludeFromMarketplace = (inventoryIds: number[]): Promise<Response> => {
        return this
            .apiClient
            .executePost(`inventory/bulkExclude`, inventoryIds);
    }; 

    bulkRemoveExclusion = (inventoryIds: number[]): Promise<Response> => {
        return this
            .apiClient
            .executePost(`inventory/bulkRemoveExclude`, inventoryIds);
    }; 

    getCategoryIdsForInventory = (inventoryId: number): Promise<number[]> => {
        return this
            .apiClient
            .executeGet<number[]>(`inventory/${inventoryId}/categoryIds`);
    }; 

    setCategoryIdsForInventory = (inventoryId: number, categoryIds: number[]): Promise<Response> => {
        return this
            .apiClient
            .executePost(`inventory/${inventoryId}/categoryIds`, categoryIds);
    }; 

    assignCategoriesToInventory = (assignments: BulkCategoryAssignmentRequest): Promise<Response> => {
        return this
        .apiClient
        .executePost(`inventory/category/assignment`, assignments);
    }

    clearInventoryCategories = (command: BulkCategoryClearRequest): Promise<Response> => {
        return this
        .apiClient
        .executePost(`inventory/category/clear`, command);
    }

    getInventoryImages = (productId: number): Promise<EntityImage[]> => {
        return this
            .apiClient
            .executeGet<EntityImage[]>(`inventory/${productId}/image`);
    };

    updateImageDetails = (imageDetailsUpdate: ImageDetailsUpdate): Promise<EntityImage> => {
        return this
            .apiClient
            .executePostWithResult<EntityImage>(`inventory/image`, imageDetailsUpdate);
    };

    deleteInventoryImage = (id: number): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`inventory/image/${id}`);
    };

    uploadInventoryImage = async (productId: number, file: Blob | string | File): Promise<EntityImage> => {

        const formData = new FormData();
        formData.append("file", file);

        return this
            .apiClient
            .executeFormDataPostWithResult<EntityImage>(`inventory/${productId}/image`, formData);
    };

    getInventoryVideos = (inventoryId: number): Promise<EntityVideo[]> => {
        return this
            .apiClient
            .executeGet<EntityVideo[]>(`inventory/${inventoryId}/video`);
    };

    upsertInventoryVideo = (video: EntityVideo): Promise<EntityVideo> => {
        return this
            .apiClient
            .executePostWithResult<EntityVideo>(`inventory/video`, video);
    };

    deleteInventoryVideo = (video: EntityVideo): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`inventory/video/${video.id}`);
    };


    assignQualityGradeToProducts = (qualityGradeAssignment: BulkQualityGradeRequest): Promise<Response> => {
        return this
        .apiClient
        .executePost(`inventory/qualityGrade/assignment`, qualityGradeAssignment);
    }

    getOrderedQuantities = (filter: OrderedQuantityFilter): Promise<OrderedInventoryQuantity[]> => {
        return this
            .apiClient
            .executePostWithResult<OrderedInventoryQuantity[]>(`inventory/orderedQuantities`, filter);
    }; 

    getOrderedQuantityDetails = (filter: OrderedQuantityFilter): Promise<OrderedInventoryDetails[]> => {
        return this
            .apiClient
            .executePostWithResult<OrderedInventoryDetails[]>(`inventory/orderedQuantityDetails`, filter);
    }; 
    
    searchInventory = (filter: InventorySearchFilter): Promise<Inventory[]> => {
        return this
            .apiClient
            .executePostWithResult<Inventory[]>(`inventory/search`, filter)
            .then(inventorySerializer.serializeSet);
    };

    createQualityAssessment = (request: QualityAssessmentAssignment): Promise<Inventory[]> => {
        return this
        .apiClient
        .executePostWithResult<Inventory[]>(`inventory/qualityGrade/assessment`, request)
        .then(inventorySerializer.serializeSet);
    }

    removeQualityAssessment = (request: RemoveQualityAssessment): Promise<Inventory[]> => {
        return this
        .apiClient
        .executeDeleteWithResult<Inventory[]>(`inventory/qualityGrade/assessment`, request)
        .then(inventorySerializer.serializeSet);
    }

    assignReadyDate = (request: ReadyDateAssignment): Promise<Inventory[]> => {
        return this
            .apiClient
            .executePostWithResult<Inventory[]>(`inventory/readyDate`, request)
            .then(inventorySerializer.serializeSet);
    };

    getInventoryNotes = (inventoryId: number): Promise<InventoryNote[]> => {
        return this
            .apiClient
            .executeGet<InventoryNote[]>(`inventory/${inventoryId}/notes`)
            .then(this.inventoryNoteSerializer.serializeSet);
    };

    deleteInventoryNote = (id: number): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`inventory/notes/${id}`);
    };

    upsertInventoryNote = (inventoryNote: InventoryNote): Promise<InventoryNote> => {
        return this
            .apiClient
            .executePostWithResult<InventoryNote>(`inventory/notes`, inventoryNote)
            .then(this.inventoryNoteSerializer.serializeSingle);
    };


    getInventoryLicenses = (): Promise<InventoryLicense[]> => {
        return this
            .apiClient
            .executeGet<InventoryLicense[]>(`inventory/license`);
    };

    getUnitOfMeasures = (): Promise<UnitOfMeasure[]> => {
        return this
            .apiClient
            .executeGet<UnitOfMeasure[]>(`inventory/unitOfMeasures`);
    };


}