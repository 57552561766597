import clsx from 'clsx';
import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { ErrorDialog } from '../../../../components/errorDialog';
import { OverlayWaitDisplay } from '../../../../components/waitDisplay';
import { useAppSelector } from '../../../../store/configureStore';
import { useMountEffect } from '../../../../utils/useEffectHelper';
import { isValue } from '../../../../utils/valueHelper';
import { smallDeviceQuery } from '../../../responsiveconfiguration';
import { setAgreeToTerms } from '../store/featureActions/agreeToTerms';
import { loadSliceData } from '../store/featureActions/loadSliceData';
import { getLoadingState, getSavingState } from '../store/selectors/actionStatus';
import { getTermsOfService, getUserAgreesToNewTerms } from '../store/selectors/termsOfService';
import { resetState } from '../store/slice';
import './index.css';


export interface AcceptTermsOfServiceProps {
    showTitle?: boolean;
}

export const AcceptTermsOfService: React.FC<AcceptTermsOfServiceProps> = ({ showTitle }) => {

    const dispatch = useDispatch();
    const termsOfService: string = useAppSelector(getTermsOfService);
    const userAgreesToTerms: boolean = useAppSelector(getUserAgreesToNewTerms);
    const loadingState = useAppSelector(getLoadingState);
    const savingState = useAppSelector(getSavingState);

    const isSmallDevice = useMediaQuery(smallDeviceQuery);

    const hasValidTerms = isValue(termsOfService) && termsOfService.length > 0;
    const canAccept = hasValidTerms && !isValue(loadingState.errorContent);

    useMountEffect(() => {
        dispatch(loadSliceData());

        return () => {
            dispatch(resetState());
        }
    });

    const [pdfPageCount, setPdfPageCount] = useState<number>(0);
    function onDocumentLoadSuccess(args: { numPages: number }) {
        setPdfPageCount(args.numPages);
    }

    const onAgreeToTermsChanged = (e: CheckboxChangeParams) => {
        dispatch(setAgreeToTerms(e.checked));
    }

    const onLoadFailureTryAgain = () => {
        dispatch(loadSliceData());
    }
    
    return <OverlayWaitDisplay isWaiting={loadingState.isExecuting || savingState.isExecuting}>

        <ErrorDialog
            title="An error occured while loading the terms of service"
            error={loadingState.errorContent}
            actionTitle="Retry"
            dismissAction={onLoadFailureTryAgain}
        />

        <div className='p-p-1' style={{ maxWidth: '800px' }}>

            {!!showTitle && <h3>You must accept the terms of service to continue using Node Marketplace</h3>}

            <div
                className={clsx("p-shadow-1 p-mb-2", {
                    "terms-viewer-large-device": !isSmallDevice,
                    "terms-viewer-small-device": isSmallDevice
                })}
            >
                {
                    hasValidTerms &&
                    <Document
                        file={termsOfService}
                        error=""
                        loading={<ProgressSpinner />}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {
                            Array.from(new Array(pdfPageCount),
                                (_, index) => <Page key={index + 1} pageNumber={index + 1} width={isSmallDevice ? 350 : 750} />
                            )
                        }

                    </Document>
                }
            </div>

            <div className="accept-terms-checkbox" >
                <Checkbox inputId='agreeCheckBox' disabled={!canAccept} checked={userAgreesToTerms} onChange={onAgreeToTermsChanged} />
                <label htmlFor='agreeCheckBox' className="p-checkbox-label">I agree to the terms of service</label>
            </div>

        </div>
    </OverlayWaitDisplay>
}