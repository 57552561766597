import { isValue } from "../../../../../utils/valueHelper";
import { actionFactory, VendorSearchResult } from "../types";

export const {
    
    performSearch,
    performSearchReducer,
    performSearchSagaFactory

} = actionFactory
    .withMappedName("performSearch")
    .createAsyncAction<void, VendorSearchResult[]>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (_arg, context) => {
            const emptyResultSet: VendorSearchResult[] = [];
            const searchRequest = context.getState().search.searchRequest;
            if (!isValue(searchRequest.searchText) || searchRequest.searchText.trim().length < 1) {
                return Promise.resolve(emptyResultSet);
            }
            return context.marketplaceApiClient.search.searchVendor(searchRequest);
        },
        debounceInMilliseconds: 50,
        onPending: (state, _action) => {
            state.searchRequest.pageNumber = 0;
            state.viewPageState.first = 0;
            state.viewPageState.page = 0;
            state.viewPageState.pageCount = 0;
        },
        onFulfilled: (state, action) => {
            state.vendorSearchResults = action.payload.result;
            state.noMoreResults = false;
        }
    });

// export const performSearch = actionFactory.createAppAction("performSearch");

// const sagaActions = actionFactory.createSagaWithProgressActions<void, InventorySearchResult[]>(performSearch.type);

// export function performSearchReducers(reducerFactory: IReducerFactory<SearchState>): IReducerFactory<SearchState> {
//     return reducerFactory
//         .forAction(performSearch)
//         .addHandler((state, _) => {
//             state.searchRequest.pageNumber = 0;
//             state.viewPageState.first = 0;
//             state.viewPageState.page = 0;
//             state.viewPageState.pageCount = 0;
//         })
//         .build()
//         .forSagaWithProgress(sagaActions)
//         .withActionStatus(state => state.loadingState)
//         .onFulfilled((state, action) => {
//             state.inventorySearchResults = action.payload.result;
//             state.noMoreResults = false;
//         })
//         .build();
// }

// async function performAction(_: void, context: SagaAppContext): Promise<InventorySearchResult[]> {
//     const searchRequest = context.getState().search.searchRequest;
//     if (!isValue(searchRequest.searchText) || searchRequest.searchText.trim().length < 1) return [];
//     return context.marketplaceApiClient.search.searchInventory(context.getState().search.searchRequest);
// }

// export function createPerformSearchSaga(context: SagaAppContext) {

//     return createExecuteWithProgressSaga({
//         options: {
//             action: performAction,
//             appContext: context,
//             notificationActions: sagaActions            
//         },
//         actionPattern: performSearch.type,
//         debounceInMilliseconds: 10
//     });
// };