import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { selectMetricsBegin } from "../store/featureActions/selectMetrics";
import { getIsEditable } from "../store/selectors/editing";

export const TemplateMetricCommandBar: React.FC = () => {

    const dispatch = useDispatch();
    const isEditable = useAppSelector(getIsEditable);

    const onSelectMetrics = () => {
        dispatch(selectMetricsBegin());
    }

    return <FlexRowContainer>

        <Button             
            onClick={onSelectMetrics}
            disabled={!isEditable}
        >Select Metrics</Button>

    </FlexRowContainer>
}