import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { loadCustomerProfileSagaFactory } from "../featureActions/loadCustomerProfile";
import { createSaveCompanyProfileSaga } from "../featureActions/saveCustomerProfile";
import { deleteBannerSagaFactory } from "../featureActions/images/deleteBanner";
import { deleteLogoSagaFactory } from "../featureActions/images/deleteLogo";
import { loadBannerSagaFactory } from "../featureActions/images/loadBanner";
import { loadLogoSagaFactory } from "../featureActions/images/loadLogo";
import { saveBannerSagaFactory } from "../featureActions/images/saveBanner";
import { saveLogoSagaFactory } from "../featureActions/images/saveLogo";

// Register this method in the root saga
export function createCustomerSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [
        loadCustomerProfileSagaFactory(context),
        createSaveCompanyProfileSaga(context),
        loadLogoSagaFactory(context),
        saveLogoSagaFactory(context),
        loadBannerSagaFactory(context),
        saveBannerSagaFactory(context),
        deleteLogoSagaFactory(context),
        deleteBannerSagaFactory(context),
    ]
}