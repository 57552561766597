import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { isValue } from "../../../../../utils/valueHelper";
import { getTenantId } from "../../../../authentication/store/selectors/tenant";

export const getCustomerId = (state: RootState) => state.productDetails.vendor?.id ?? null;

export const getCustomerDisplayName = (state: RootState) => state.productDetails.vendor?.companyName ?? "";

export const getVendor = (state: RootState) => state.productDetails.vendor;

export const getInventoryView = (state: RootState) => state.productDetails.inventoryView;

export const getMenu = (state: RootState) => state.productDetails.vendorMenu;

export const getThumbnailImage = (state: RootState) => state.productDetails.thumbnailImage;

export const getProductDetailsLoadingState = (state: RootState) => state.productDetails.vendorLoadingState;

export const getPrimaryColor = (state: RootState) => isValue(state.productDetails.vendorMenu?.primaryColor) ? `#${state.productDetails.vendorMenu?.primaryColor}` : "white";

export const getSecondaryColor = (state: RootState) => isValue(state.productDetails.vendorMenu?.secondaryColor) ? `#${state.productDetails.vendorMenu?.secondaryColor}` : "black";

export const getPrimaryFont = (state: RootState) => state.productDetails.vendorMenu?.primaryFont ?? undefined;

export const getSecondaryFont = (state: RootState) => state.productDetails.vendorMenu?.secondaryFont ?? undefined;

export const getUserOwnsMenu = createSelector([
    getMenu,
    getTenantId,

], (menu, tenant) => {
    return isValue(menu) && isValue(tenant) && menu.tenantId === tenant;
})