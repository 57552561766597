import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { actionFactory, BulkExcludeAction, InventoryManagementState } from "../../types";

export const beginBulkExclude = actionFactory.createAppAction<BulkExcludeAction>("beginBulkExclude");

export function beginBulkExcludeReducers(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forAction(beginBulkExclude)
        .addHandler((state, action) => {
            state.bulkExcludeState.action = action.payload;
            state.bulkExcludeState.showConfirmation = true;
        })
        .build();
}