import { useSelector } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { getMenuIds } from "../store/selectors";
import { MenuListItem } from "./menuListItem";

export const MenuList: React.FC = () => {

    const menuIds = useSelector(getMenuIds);

    return <FlexColumnContainer className="menu-list-container">
        <div className="menu-title">Menus</div>
        {
            menuIds.map(id => <MenuListItem key={id} menuId={id} />)
        }
    </FlexColumnContainer>
} 