import { Column, ColumnProps } from 'primereact/column';
import { DataTable, DataTablePageParams } from 'primereact/datatable';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/configureStore';
import { createTextColumnProps } from '../../../../utils/table/stringColumn';
import { isValue } from '../../../../utils/valueHelper';
import { fetchNextPage } from '../store/featureActions/fetchNextPage';
import { setViewPageState } from '../store/featureActions/setViewPageState';
import { getNoMoreResults, getVendorSearchResults } from '../store/selectors/searchResults';
import { getViewPageState } from '../store/selectors/viewPageState';
import './searchResults.css';
import { VendorSearchResult } from '../store/types';
import { createFillRemainingSpaceColumnProps } from '../../../../utils/table/fillRemainingSpaceColumn';
import { createActionColumnProps } from '../../../../utils/table/actionColumn';
import { push } from 'connected-react-router';
import { CompanyMarketplaceViewRoute } from '../../../navigation/routes';

export const TableResultsView: React.FC = () => {

    const dispatch = useDispatch();
    const searchResults = useAppSelector(getVendorSearchResults);
    const noMoreResults = useAppSelector(getNoMoreResults);
    const pageParameters = useAppSelector(getViewPageState);

    // const onViewCustomerMenu = (inventorySearchResult: InventorySearchResult) => {
    //     console.log("onViewCustomerMenu", inventorySearchResult);
    //     if (!isValue(inventorySearchResult.vendorUrlName)) return;
    //     window.open(liveMenuRoute.createFullUrl(inventorySearchResult.vendorUrlName));
    // }

    // const onContactVendor = (inventorySearchResult: InventorySearchResult) => {
    //     if (!isValue(inventorySearchResult.vendorUrlName)) return;
    //     dispatch(showVendorContactDetails(inventorySearchResult.vendorUrlName));
    // }

    const onPageResults = (e: DataTablePageParams) => {
        dispatch(setViewPageState(e));
        if (noMoreResults) return;
        if (!isValue(e.page)) return;

        // If the user navigates to the last view page, load the next data page 
        // to see if there are more results
        if ((e.page + 1) * e.rows >= searchResults.length) {
            dispatch(fetchNextPage());
        }
    }


    const columns: ColumnProps[] = [
        createActionColumnProps<VendorSearchResult>({ 
            header: "Name", 
            actionName: (vendor) => vendor.displayName, 
            actionHandler: (vendor) => {
                dispatch(push(CompanyMarketplaceViewRoute.createPath(vendor.id)));
            },            
            style: { width: 200, maxWidth: 300 }, 
            buttonClassName: "p-button-link",
            sortable: true 
        }),
        createTextColumnProps<VendorSearchResult>({ header: "Type", field: "companyType", style: { width: 200, maxWidth: 300 }, sortable: true }),
        createTextColumnProps<VendorSearchResult>({ header: "State", field: "stateAbbreviation", style: { width: 200, maxWidth: 300 }, sortable: true }),
        createTextColumnProps<VendorSearchResult>({ header: "Phone", field: "contactPhone", style: { width: 200, maxWidth: 300 }, sortable: true }),
        createTextColumnProps<VendorSearchResult>({ header: "Email", field: "contactEmail", style: { width: 200, maxWidth: 300 }, sortable: true }),
        createFillRemainingSpaceColumnProps({})
    ];

    return <DataTable
        dataKey="id"
        value={searchResults.slice(pageParameters.first, pageParameters.first + pageParameters.rows)}
        scrollable
        autoLayout={true}
        style={{ width: '80vw', minHeight: "800px" }}
        paginator
        lazy
        emptyMessage={searchResults.length > 0 && noMoreResults ? "There are no more records associated with this search." : undefined}
        first={pageParameters.first}
        totalRecords={searchResults.length}
        onPage={onPageResults}
        alwaysShowPaginator={false}
        paginatorPosition="top"
        rows={pageParameters.rows}
        rowsPerPageOptions={[10, 20, 50]}
    >
        {columns.map(c => <Column key={c.columnKey ?? c.field} {...c} />)}
    </DataTable>
}
