import { FormikHelpers } from 'formik';
import { Card } from 'primereact/card';
import React, { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { primaryFontFamilies } from '../../../../assets/fonts';
import { FlexColumnContainer } from '../../../../components/containers/flexColumnContainer';
import { FlexRowContainer } from '../../../../components/containers/flexRowContainer';
import { ColorField } from '../../../../components/formik/colorField';
import { DropDownField } from '../../../../components/formik/dropDownField';
import { FormikEmbeddedEditor } from '../../../../components/formik/embeddedEditor';
import { FontField } from '../../../../components/formik/fontField';
import { StringField } from '../../../../components/formik/stringField';
import { useAppSelector } from '../../../../store/configureStore';
import { SortDirection, SortField } from '../../common/types';
import { upsertMenu } from '../store/featureActions/upsertMenu';
import { getMenuForEditing } from '../store/selectors/menu';
import { isSaveableMenu, Menu } from '../store/types';
import { menuValidations } from '../validations';
import { DemoButtonWithColors } from './demoButtonWithColors';
import './menuDetails.css';
import { MenuUrlHelper } from './menuUrlHelper';

export const MenuDetails: React.FC = () => {

    const dispatch = useDispatch();
    const menu = useAppSelector(getMenuForEditing);

    const onSubmit = (values: Menu, formikHelpers: FormikHelpers<Menu>): void | Promise<any> => {
        if (isSaveableMenu(values)) {
            dispatch(upsertMenu(values));
            formikHelpers.setSubmitting(false);
        }
    };
  
    const availableSortFields = [
        { label: "Name", value: SortField.Name },
        { label: "Price", value: SortField.Price },
        { label: "Quantity", value: SortField.Quantity },
        { label: "Strain", value: SortField.Strain },
    ];

    const availableSortDirections = [
        { label: "Ascending", value: SortDirection.Ascending },
        { label: "Descending", value: SortDirection.Descending }
    ];

    const labelStyle: CSSProperties = { display: "inline-block", width: "165px" }
    const inputStyle: CSSProperties = { width: "400px" }
    const dropDownClassName: string = "p-d-flex p-flex-row p-ai-center";

    return <div>

        <Card title="Menu Details" >
            <FormikEmbeddedEditor<Menu>
                initialValues={menu}
                validationSchema={menuValidations}
                onSave={onSubmit}
            >
                <FlexColumnContainer gap="10px">

                    <StringField<Menu> defaultLabel='Menu Name' fieldName='name' submitOnBlur={true} labelStyle={labelStyle} inputStyle={inputStyle} />

                    <FlexRowContainer gap="25px" centerContent>
                        <ColorField<Menu> defaultLabel='Primary Color' fieldName='primaryColor' defaultColor='#FFFFFF' submitOnChange={true} labelStyle={labelStyle} />
                        <ColorField<Menu> defaultLabel='Secondary Color' fieldName='secondaryColor' defaultColor='#000000' submitOnChange={true} labelStyle={{ display: "inline-block", width: "132px" }} />
                        <DemoButtonWithColors />
                    </FlexRowContainer>

                    <FontField<Menu>
                        defaultLabel='Primary Font'
                        availableFontFamilies={primaryFontFamilies}
                        fieldName='primaryFont'
                        labelStyle={labelStyle}
                        inputStyle={inputStyle}
                    />

                    <FontField<Menu>
                        defaultLabel='Secondary Font'
                        availableFontFamilies={primaryFontFamilies}
                        fieldName='secondaryFont'
                        labelStyle={labelStyle}
                        inputStyle={inputStyle}
                    />

                    <DropDownField<Menu, SortField>
                        defaultLabel='Default Sort Field'
                        fieldName='defaultSortField'
                        containerClassName={dropDownClassName}
                        labelStyle={labelStyle}
                        inputStyle={inputStyle}
                        availableValues={availableSortFields}
                        submitOnChange={true}                   
                    />

                    <DropDownField<Menu, SortDirection>
                        defaultLabel='Default Sort Direction'
                        fieldName='defaultSortDirection'
                        containerClassName={dropDownClassName}
                        labelStyle={labelStyle}
                        inputStyle={inputStyle}
                        availableValues={availableSortDirections}
                        submitOnChange={true}                   
                    />

                </FlexColumnContainer>


            </FormikEmbeddedEditor>
            {/* <Formik
                initialValues={menu}
                enableReinitialize={true}
                validationSchema={menuValidations}
                onSubmit={onSubmit}
            >
                {
                    (actions) => <Form autoComplete="off">

                        <div className="p-d-flex p-flex-row p-ai-center">


                            <label className="menu-details-label">Menu Name</label>
                            <div className="p-mr-4">
                                <Field
                                    type="text"
                                    name="name"
                                >
                                    {
                                        (props: FieldProps<Menu>) =>
                                            <InputText
                                                id="name"
                                                name="name"
                                                className={clsx("menu-name-input-text", { "p-invalid": !!actions.errors.name })}
                                                value={actions.values.name}
                                                onBlur={(event) => {
                                                    actions.setFieldValue(props.field.name, event.target.value);
                                                    actions.submitForm()
                                                }}
                                                onChange={(event) => {
                                                    actions.setFieldValue(props.field.name, event.target.value);
                                                }}
                                            />
                                    }

                                </Field>
                            </div>
                            <small>{actions.errors.name}</small>

                            <label className="menu-details-label">Primary Color</label>
                            <div className="p-ml-2 p-mr-4">
                                <Field
                                    type="text"
                                    name="primaryColor"
                                >
                                    {
                                        (props: FieldProps<Menu>) =>
                                            <ColorPicker
                                                id="primaryColor"
                                                name="primaryColor"
                                                className={clsx({ "p-invalid": !!actions.errors.primaryColor })}
                                                value={actions.values.primaryColor ?? "#FFFFFF"}
                                                onChange={(event) => {
                                                    actions.setFieldValue(props.field.name, event.target.value);
                                                    actions.submitForm()
                                                }}
                                            />
                                    }
                                </Field>
                            </div>
                            <small>{actions.errors.primaryColor}</small>

                            <label className="menu-details-label">Secondary Color</label>
                            <div className="p-ml-2 p-mr-4">
                                <Field
                                    type="text"
                                    name="secondaryColor"
                                >
                                    {
                                        (props: FieldProps<Menu>) =>
                                            <ColorPicker
                                                id="secondaryColor"
                                                name="secondaryColor"
                                                className={clsx({ "p-invalid": !!actions.errors.secondaryColor })}
                                                value={actions.values.secondaryColor ?? "#000000"}
                                                onChange={(event) => {
                                                    actions.setFieldValue(props.field.name, event.target.value);
                                                    actions.submitForm()
                                                }}
                                            />
                                    }
                                </Field>
                            </div>
                            <small>{actions.errors.secondaryColor}</small>

                            <DemoButtonWithColors />
                        </div>

                        <FlexColumnContainer gap="10px">
                            <div className="p-d-flex p-flex-row p-ai-center">

                                <label className="menu-details-label">Menu Privacy</label>
                                <div>
                                    <Field
                                        type="text"
                                        name="menuPrivacy"
                                    >
                                        {
                                            (props: FieldProps<Menu>) =>
                                                <Dropdown
                                                    id="menuPrivacy"
                                                    name="menuPrivacy"
                                                    className={clsx("menu-name-input-text", { "p-invalid": !!actions.errors.menuPrivacy })}
                                                    value={actions.values.menuPrivacy}
                                                    options={availableMenuPrivacyOptions}
                                                    onChange={(event) => {
                                                        actions.setFieldValue(props.field.name, event.target.value);
                                                        actions.submitForm()
                                                    }}
                                                />
                                        }

                                    </Field>
                                    <small>{actions.errors.menuPrivacy}</small>
                                </div>
                            </div>

                            <div className="p-d-flex p-flex-row p-ai-center">

                                <label className="menu-details-label">Price Privacy</label>
                                <div>
                                    <Field
                                        type="text"
                                        name="pricePrivacy"
                                    >
                                        {
                                            (props: FieldProps<Menu>) =>
                                                <Dropdown
                                                    id="pricePrivacy"
                                                    name="pricePrivacy"
                                                    className={clsx("menu-name-input-text", { "p-invalid": !!actions.errors.pricePrivacy })}
                                                    value={actions.values.pricePrivacy}
                                                    options={availablePricePrivacyOptions}
                                                    onChange={(event) => {
                                                        actions.setFieldValue(props.field.name, event.target.value);
                                                        actions.submitForm()
                                                    }}
                                                />
                                        }

                                    </Field>
                                    <small>{actions.errors.pricePrivacy}</small>
                                </div>
                            </div>

                            <div className="p-d-flex p-flex-row p-ai-center">

                                <label className="menu-details-label">Primary Font</label>
                                <div>
                                    <Field
                                        type="text"
                                        name="primaryFont"
                                    >
                                        {
                                            (props: FieldProps<Menu>) =>
                                                <FontPicker
                                                    className={clsx("menu-name-input-text", { "p-invalid": !!actions.errors.primaryFont })}
                                                    value={actions.values.primaryFont}
                                                    onChange={(value) => {
                                                        actions.setFieldValue(props.field.name, value);
                                                        actions.submitForm()
                                                    }}
                                                    availableFontFamilies={primaryFontFamilies}

                                                />
                                        }

                                    </Field>
                                    <small>{actions.errors.primaryFont}</small>
                                </div>
                            </div>

                            <div className="p-d-flex p-flex-row p-ai-center">

                                <label className="menu-details-label">Secondary Font</label>
                                <div>
                                    <Field
                                        type="text"
                                        name="secondaryFont"
                                    >
                                        {
                                            (props: FieldProps<Menu>) =>
                                                <FontPicker
                                                    className={clsx("menu-name-input-text", { "p-invalid": !!actions.errors.secondaryFont })}
                                                    value={actions.values.secondaryFont}
                                                    onChange={(value) => {
                                                        actions.setFieldValue(props.field.name, value);
                                                        actions.submitForm()
                                                    }}
                                                    availableFontFamilies={secondaryFontFamilies}

                                                />
                                        }

                                    </Field>
                                    <small>{actions.errors.secondaryFont}</small>
                                </div>
                            </div>

                        </FlexColumnContainer>
                    </Form>
                }
            </Formik> */}

            <div className="p-d-flex p-flex-row p-ai-center">
                <MenuUrlHelper />
            </div>

        </Card>

    </div>
}