import * as yup from 'yup';
import { InventorySyncKind } from '../../productManagement/store/types';
import { MarketingDetails } from '../models/marketingDetails';

export const marketingDetailsValidations: yup.SchemaOf<Partial<MarketingDetails>> = yup.object().shape({
    id: yup.number().optional(),
    tenantId: yup.string().optional(),
    licenseId: yup.number().optional(),    
    licenseNumber: yup.string().optional().nullable(),
    metrcName: yup.string().optional(),
    quantity: yup.number().optional(),
    metrcStrainName: yup.string().optional(),        
    unitOfMeasureName: yup.string().optional(),
    inventorySyncKind: yup.mixed<InventorySyncKind>().optional(),
    marketingName: yup.string().optional().nullable()
        .max(128, "Must have less than 128 characters"),
    marketingStrainName: yup.string().optional().nullable()
        .max(128, "Must have less than 128 characters"),
    marketingDescription: yup.string().optional().nullable()
        .max(255, "Must have less than 512 characters"),
    thcPercentage: yup.number().optional().nullable(),
    cbdPercentage: yup.number().optional().nullable(),    
    unitPrice: yup.number().optional().nullable(),    
    excludeFromMarket: yup.boolean().optional(),
    reservedQuantity:  yup.number().optional().nullable(),
    pricingModelId: yup.number().optional().nullable(),
    qualityGradeId: yup.number().optional().nullable()
});

export const addMarketingDetailsValidations: yup.SchemaOf<Partial<MarketingDetails>> = yup.object().shape({
    id: yup.number().optional(),
    tenantId: yup.string().optional(),
    licenseId: yup.number().min(1, "License is required").required(),
    licenseNumber: yup.string().optional().nullable(),
    metrcName: yup.string().optional().nullable(),
    quantity: yup.number().min(1, "Queantity must be bigger then 0.").required("Quantity is required"),
    metrcStrainName: yup.string().optional().nullable(),        
    unitOfMeasureName: yup.string().required("Unit of measure is required"),
    inventorySyncKind: yup.mixed<InventorySyncKind>().optional().nullable(),
    marketingName: yup.string().optional().nullable()
        .max(128, "Must have less than 128 characters")
        .required("Marketing name is required"),
    marketingStrainName: yup.string().optional().nullable()
        .max(128, "Must have less than 128 characters"),
    marketingDescription: yup.string().optional().nullable()
        .max(255, "Must have less than 512 characters"),
    thcPercentage: yup.number().optional().nullable(),
    cbdPercentage: yup.number().optional().nullable(),    
    unitPrice: yup.number().optional().nullable(),    
    excludeFromMarket: yup.boolean().optional(),
    reservedQuantity:  yup.number().optional().nullable(),
    pricingModelId: yup.number().optional().nullable(),
    qualityGradeId: yup.number().optional().nullable()
});