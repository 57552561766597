import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { isValue } from "../../../../../utils/valueHelper";
import { actionFactory, TermsOfService, TermsOfServiceAdminState } from "../types";

export const completeEditTermsOfService = actionFactory.createAppAction("completeEditTermsOfService");

const sagaActions = actionFactory.createSagaWithProgressActions<void, TermsOfService>(completeEditTermsOfService.type);

export function completeEditTermsOfServiceReducers(reducerFactory: IReducerFactory<TermsOfServiceAdminState>): IReducerFactory<TermsOfServiceAdminState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.saveState)
        .onFulfilled((state, action) => {
            state.termsOfService = state
                .termsOfService
                .filter(terms => terms.id !== action.payload.result.id)
                .concat(action.payload.result);

            state.editedTermsOfServiceId = undefined;
        })
        .build();
}

async function performAction(_: void, context: SagaAppContext): Promise<TermsOfService> {

    const state = context.getState();
    const editState = state.termsOfServiceAdmin.editState;

    if (isValue(editState.preview) && isValue(editState.stateAbbreviation)) {

        const blob = await fetch(editState.preview).then(localFile => localFile.blob());    
        return context.marketplaceApiClient.termsOfServiceAdmin.upsertTermsOfService(blob, editState.stateAbbreviation);
    }
    else {
        throw new Error("You must select a valid terms of service document and state before saving.");
    }
    
}

export function createCompleteEditTermsOfServiceSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: completeEditTermsOfService.type,
        debounceInMilliseconds: 250
    });
};