import { isValue } from "../../../../../utils/valueHelper";
import { AssessmentTemplate } from "../../../models/assessmentTemplate";
import { actionFactory } from "../types";

export const {

    publishAssessmentTemplate,
    publishAssessmentTemplateReducer,
    publishAssessmentTemplateSagaFactory

} = actionFactory
    .withMappedName("publishAssessmentTemplate")
    .createAsyncAction<void, AssessmentTemplate>({
        actionStatusSelector: state => state.templateSaveState,
        asyncCall: (_arg, context) => {
            const template = context.getState().qualityTemplateEditor.template;
            if (!isValue(template)) throw new Error("Cannot publish a template without a valid template instance");
            return context.marketplaceApiClient.quality.publishAssessmentTemplate(template);
        },
        onFulfilled: (state, action) => {
            state.template = action.payload.result;           
        }
    });