import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { BulkAssignmentKind } from "../../../commonModels/bulkAssignmentState";
import { PricingModel } from "../../../pricing/sharedTypes";
import { cancelBulkPricingModelAssignment } from "../store/featureActions/priceModels/cancelBulkPricingModelAssignment";
import { completeBulkPricingModelAssignment } from "../store/featureActions/priceModels/completeBulkPricingModelAssignment";
import { setPricingModelToAssign } from "../store/featureActions/priceModels/setPricingModelToAssign";
import { getBulkPricingModelAssignmentDialogTitle, getBulkPricingModelSavingState, getPricingModelAssignmentKind, getPricingModelToBulkAssign, getProductsForBulkPricingModelAssignment } from "../store/selectors/bulkPricingModelAssignment";
import { getPricingModels } from "../store/selectors/priceModels";

export const BulkAssignPricingModelDialog: React.FC = () => {

    const pricingModels: PricingModel[] = useAppSelector(getPricingModels);
    const productsForBulkAssign = useAppSelector(getProductsForBulkPricingModelAssignment);
    const pricingModelIdToAssign = useAppSelector(getPricingModelToBulkAssign);
    const title = useAppSelector(getBulkPricingModelAssignmentDialogTitle);
    const bulkAssignmentKind = useAppSelector(getPricingModelAssignmentKind);
    const saveState = useAppSelector(getBulkPricingModelSavingState);
    const dispatch = useDispatch();

    const onHideDialog = () => { };

    const onSave = () => {
        dispatch(completeBulkPricingModelAssignment());
    }

    const onCancel = () => {
        dispatch(cancelBulkPricingModelAssignment());
    }

    const onSelectedPricingModelChanged = (e: DropdownChangeParams) => {
        if (bulkAssignmentKind === BulkAssignmentKind.Assign && typeof (e.value) === "number") {
            dispatch(setPricingModelToAssign(e.value));
        }
    }

    return <Dialog
        onHide={onHideDialog}
        visible={productsForBulkAssign.length > 0}
        closable={false}
        draggable={false}
        header={title}
    >
        <FlexColumnContainer gap="20px" paddingTop="20px">

            {
                bulkAssignmentKind === BulkAssignmentKind.Assign &&
                <FlexRowContainer gap="10px" centerContent>
                    <label style={{ display: "inline-block", width: "150px" }}>Pricing Model</label>
                    <Dropdown
                        value={pricingModelIdToAssign}
                        style={{ width: "100%" }}
                        options={pricingModels.map(pricingModel => ({ label: pricingModel.name, value: pricingModel.id }))}
                        onChange={onSelectedPricingModelChanged}
                    />
                </FlexRowContainer>
            }
            <div>
                <Button className="p-ml-2" label="Save" style={{ float: "right" }} onClick={onSave} loading={saveState.isExecuting} />
                <Button label="Cancel" style={{ float: "right" }} onClick={onCancel} disabled={saveState.isExecuting} />
            </div>
        </FlexColumnContainer>
    </Dialog>
}