import { FormikHelpers } from "formik";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { confirmPopup } from "primereact/confirmpopup";
import { Menu } from "primereact/menu";
import { MenuItem, MenuItemCommandParams } from "primereact/menuitem";
import React, { useRef } from "react";
import { useDrag } from 'react-dnd';
import { useDispatch } from "react-redux";
import { FormikEmbeddedEditor } from "../../../../components/formik/embeddedEditor";
import { StringField } from "../../../../components/formik/stringField";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { beginAssignProductsToCategory } from "../../categoryManagement/store/featureActions/beginAssignProductsToCategory";
import { Category } from "../../categoryManagement/store/types";
import { addCategoryToSection } from "../store/featureActions/addCategoryToSection";
import { deleteCategory } from "../store/featureActions/deleteCategory";
import { upsertCategory } from "../store/featureActions/upsertCategory";
import { getCategoryById } from "../store/selectors/category";
import { MenuSection } from "../store/types";
import { categoryValidations } from "../validations";
import './categoryView.css';
import { dragAndDropTypes, DragCollectPayload } from "./dragAndDropTypes";

export interface CategoryViewProps {
    categoryId: number
}

export const CategoryView: React.FC<CategoryViewProps> = ({ categoryId }) => {

    const dispatch = useDispatch();
    const category = useAppSelector(state => getCategoryById(state, categoryId));
    const overlayMenuRef = useRef<Menu>(null);
    const overlayMenuDivRef = useRef<HTMLDivElement>(null);

    const [dragCollectProps, drag] = useDrag<Category, MenuSection, DragCollectPayload<Category>>(() => ({
        type: dragAndDropTypes.Category,
        item: category,
        collect: (monitor) => {
            return {
                draggedItem: monitor.getItem(),
                canDrag: monitor.canDrag(),
                isDragging: monitor.isDragging()
            }
        },
        end: (category, monitor) => {
            const droppedOnMenu = monitor.getDropResult();
            if (droppedOnMenu !== null) {
                dispatch(addCategoryToSection({ menuSectionId: droppedOnMenu.id, categoryId: category.id }));
            }
        }
    }));

    // const onCategoryNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     if (!isValue(category)) return;
    //     dispatch(editCategoryName({ id: category.id, name: e.target.value }));
    // }

    // const onCategoryNameBlur = (_: React.FocusEvent<HTMLInputElement>) => {
    //     if (!isValue(category)) return;
    //     dispatch(upsertCategory(category));
    // }

    const onSaveCategory = (value: Category, _formikHelpers: FormikHelpers<Category>) => {
        dispatch(upsertCategory(value));
    }

    const onDeleteCategory = (_: MenuItemCommandParams) => {
        if (!isValue(category)) return;

        confirmPopup({
            target: overlayMenuDivRef.current ?? undefined,
            message: 'Are you sure you want to delete this category?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => dispatch(deleteCategory({ categoryId: category.id })),
            reject: () => { }
        });
    }

    const onAssignProductsToCategory = (_: MenuItemCommandParams) => {
        if (!isValue(category)) return;
        dispatch(beginAssignProductsToCategory(category));
    }

    const categoryActions: MenuItem[] = [
        {
            label: "Delete",
            command: onDeleteCategory
        },
        {
            label: "Assign Items",
            command: onAssignProductsToCategory,
            icon: PrimeIcons.EXTERNAL_LINK
        },
    ];

    return !isValue(category)
        ? <div></div>
        : <div
            className="p-shadow-5 p-col-12 p-p-3 p-mb-2"
            style={{ backgroundColor: dragCollectProps.isDragging ? "lightgrey" : undefined }}
        >
            <div className="p-d-flex p-ai-center">
                <div
                    className="category-view-grab-icon"
                    ref={drag}
                    style={{ cursor: "grab" }}
                >
                    <span className={PrimeIcons.ELLIPSIS_V}></span>
                </div>

                <FormikEmbeddedEditor<Category>
                    initialValues={category}
                    onSave={onSaveCategory}
                    validationSchema={categoryValidations}
                    className="category-view-editor"
                >
                    <StringField<Category>
                        defaultLabel=""
                        fieldName="name"
                        inputClassName="category-view-name-editor"
                        submitOnBlur={true}
                    />

                </FormikEmbeddedEditor>

                {/* <InputText
                className="category-view-name-editor"
                value={category?.name}
                onChange={onCategoryNameChange}
                onBlur={onCategoryNameBlur}
            /> */}

                <div ref={overlayMenuDivRef}>
                    <Button
                        icon="pi pi-bars"
                        onClick={(event) => overlayMenuRef.current?.toggle(event)}
                    />
                    <Menu popup ref={overlayMenuRef} model={categoryActions} />
                </div>

            </div>
        </div>
}