import { InputText } from 'primereact/inputtext';
import clsx from 'clsx';
import { Field, FormikContextType, FieldProps, useFormikContext } from "formik";
import { FocusEvent, ChangeEvent, CSSProperties } from 'react';
import { getStringOrNullValue, PropertiesOfType, StringOrNullKeyOf } from '../../utils/typeHelpers';
import { isValue } from '../../utils/valueHelper';
import { FormikEditorClassStyles } from './classNameAttributes';
import { FlexColumnContainer } from '../containers/flexColumnContainer';

export interface StringFieldProps<T extends PropertiesOfType<T, string | null | undefined>> extends FormikEditorClassStyles {
    defaultLabel?: string;
    fieldName: keyof T & string & StringOrNullKeyOf<T>;
    containerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    placeHolder?: string;
    allowNoValue?: boolean;
    submitOnBlur?: boolean;
    disabled?: boolean;
    showErrors?: boolean;
}


export const StringField = <T extends PropertiesOfType<T, string | null | undefined>,>({
    defaultLabel,
    fieldName,
    containerStyle,
    labelStyle,
    inputStyle,
    placeHolder,
    disabled,
    submitOnBlur = false,
    allowNoValue = true,
    containerClassName,
    inputClassName,
    labelClassName,
    showErrors = true
}: StringFieldProps<T>) => {

    const formikProps: FormikContextType<T> = useFormikContext<T>();

    const onChange = (event: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>, props: FieldProps<T>) => {

        if (isValue(event.target.value) || allowNoValue) {
            formikProps.setFieldValue(props.field.name, event.target.value);
        }
    }

    return <FlexColumnContainer>
        <div style={containerStyle} className={containerClassName}>

            {isValue(defaultLabel) && <label className={labelClassName} style={labelStyle} htmlFor={fieldName} >{defaultLabel}</label>}
            <Field
                name={fieldName}
            >
                {
                    (props: FieldProps<T>) =>
                        <InputText
                            placeholder={placeHolder}
                            style={inputStyle}
                            id={fieldName}
                            disabled={disabled}
                            name={fieldName}
                            className={clsx(inputClassName, { "p-invalid": !!formikProps.errors[fieldName] })}
                            value={getStringOrNullValue(formikProps.values, fieldName) ?? undefined}
                            onChange={(event) => onChange(event, props)}
                            onBlur={(_) => {
                                if (submitOnBlur && formikProps.dirty) {
                                    formikProps.submitForm();
                                }
                            }}
                        />
                }
            </Field>
        </div>
        {
            showErrors &&
            <div>
                <small>{formikProps.errors[fieldName]}</small>
            </div>
        }
    </FlexColumnContainer>


}