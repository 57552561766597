import { MediaQueryAllQueryable } from "react-responsive";

export const smallDeviceTableBreakpointWidth = 800;
export const smallDeviceTableBreakpoint = `${smallDeviceTableBreakpointWidth}px`;
export const smallDeviceMaxWidth = 1023;
export const largeDeviceMinWidth = smallDeviceMaxWidth + 1;
export const smallDeviceQuery: Partial<MediaQueryAllQueryable & { query?: string }> = { minWidth: 0, maxWidth: smallDeviceMaxWidth };


export const vendorImageSizeSmall = "60px";
export const vendorSizeLarge = "120px";

export function getVendorImageSize(isSmallDevice: boolean) : string {
    return isSmallDevice ? vendorImageSizeSmall : vendorSizeLarge;
}

export const spinnerSizeSmall = "40px";
export const spinnerSizeLarge = "50px";

export const spinnerMarginSmall = "0px";
export const spinnerMarginLarge = "0px";

export function getSpinnerSize(isSmallDevice: boolean) : string {
    return isSmallDevice ? spinnerSizeSmall : spinnerSizeLarge;
}


export function getSpinnerMargin(isSmallDevice: boolean) : string {
    return isSmallDevice ? spinnerMarginSmall : spinnerMarginLarge;
}