import { Load2Result, parallelLoad } from "../../../../../utils/promiseHelpers";
import { QualityAssessment } from "../../../models/qualityAssessment";
import { QualityAssessmentValue } from "../../../models/qualityAssessmentValue";
import { loadAssesmentStateAdapter, qualityAssessmentAdapter, qualityAssessmentValueAdapter } from "../state";
import { actionFactory } from "../types";

type LoadResult = Load2Result<QualityAssessment, QualityAssessmentValue[]>;

export const {

    loadSliceData,
    loadSliceDataReducer,
    loadSliceDataSagaFactory

} = actionFactory
    .withMappedName("loadSliceData")
    .createAsyncAction<{ assessmentId: number }, LoadResult>({
        actionStatusSelector: {
            adapter: loadAssesmentStateAdapter,
            adapterStateSelector: state => state.loadAssessmentState,
            getId: (_state, arg) => arg.assessmentId
        },
        asyncCall: (arg, context) => {
            return parallelLoad(
                () => context.marketplaceApiClient.quality.getAssessment(arg.assessmentId),
                () => context.marketplaceApiClient.quality.getAssessmentValues(arg.assessmentId)
            );
        },
        onFulfilled: (state, action) => {
            qualityAssessmentAdapter.upsertOne(state.assessments, action.payload.result.result1);
            qualityAssessmentValueAdapter.upsertMany(state.assessmentValues, action.payload.result.result2);
        }
    });