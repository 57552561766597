import { EntityState } from "@reduxjs/toolkit";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ModalEditState } from "../../../../store/factory/modalEdit/modalEdit";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { AssessmentEntry } from "../../models/assessmentEvaluationRequest";
import { AssessmentTemplate } from "../../models/assessmentTemplate";
import { TemplateConstraintNode } from "../../models/constraintNode";
import { QualityGradeKind } from "../../models/gradeKind";
import { QualityGradeValue } from "../../models/gradeValue";
import { QualityMetricKind } from "../../models/metricKind";
import { QualityMetricValue } from "../../models/metricValue";
import { TemplateConstraint } from "../../models/templateConstraint";
import { TemplateMetric } from "../../models/templateMetric";

export const sliceName: string = "quality-template-editor";

export const actionFactory = new ActionFactory<QualityTemplateEditorState>(sliceName);

export interface MetricKindSelection {
    metricKinds: QualityMetricKind[];
}

export interface TemplateMetricAssignment {
    assessmentTemplateId: number;
    metricKindIds: number[];
}

export interface EntityExpansionMap {
    [key: string]: boolean;
}
export interface TreeNodeExpandedKeys {
    gradeValueId: number,
    expandedKeys: EntityExpansionMap
}


export interface QualityTemplateEditorState {
    loadingState: ActionStatus;
    template: AssessmentTemplate | null;
    templateConstraints: EntityState<TemplateConstraint>;
    constraintNodes: EntityState<TemplateConstraintNode>;
    nextConstraintNodeId: number;

    templateMetrics: EntityState<TemplateMetric>;
    gradeKinds: QualityGradeKind[];
    gradeValues: EntityState<QualityGradeValue>;
    metricKinds: EntityState<QualityMetricKind>;
    metricValues: QualityMetricValue[];
    templateSaveState: ActionStatus;
    templateMetricSaveState: ActionStatus;
    selectMetricsState: ModalEditState<MetricKindSelection>;
    addTemplateConstraintState: ModalEditState<Partial<TemplateConstraint>>;
    changeGradeKindState: ModalEditState<AssessmentTemplate>;

    testAssessmentEntries: AssessmentEntry[];
    testResult: QualityGradeValue | null;
    constraintTreeExpandedKeys: EntityState<TreeNodeExpandedKeys>;
}