import { ActionStatus, CreateActionStatusInitialState } from "../actionStatus/actionStatus";

export enum BulkAssignmentKind {
    Assign,
    Clear
}

export interface BulkSetAssignmentState<TParentModel, TValue> {
    loadingState: ActionStatus;
    savingState: ActionStatus;
    parentModels: TParentModel[];
    available: TValue[];
    selected: TValue[];
};

export const createBulkSetAssignmentInitialState = <TParent, TValue>(inititalValues?: TValue[]): BulkSetAssignmentState<TParent, TValue> => {
    return {
        available: [],
        loadingState: CreateActionStatusInitialState(),
        savingState:  CreateActionStatusInitialState(),
        parentModels: [],
        selected: inititalValues ?? []
    };
}

export interface BulkValueAssignmentState<TParent, TValue> {
    loadingState: ActionStatus;
    savingState: ActionStatus;
    parentModels: TParent[];
    available: TValue[];
    selected?: TValue;
};

export const createBulkValueAssignmentInitialState = <TParent, TValue>(inititalValue?: TValue): BulkValueAssignmentState<TParent, TValue> => {
    return {
        available: [],
        loadingState: CreateActionStatusInitialState(),
        savingState:  CreateActionStatusInitialState(),
        parentModels: [],
        selected: inititalValue
    };
}