import { PurchaseOrder } from "../../../sharedTypes";
import { actionFactory } from "../types";

export const {

    loadSliceData,
    loadSliceDataReducer,
    loadSliceDataSagaFactory

} = actionFactory
    .withMappedName("loadSliceData")
    .createAsyncAction<void, PurchaseOrder[]>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (_arg, context) => {
            return context.marketplaceApiClient.orders.getBuyerOrders();
        },
        onFulfilled: (state, action) => {
            state.holdRequests = action.payload.result;
        },
        successCallback: (_arg, _result, _context) => { },
        debounceInMilliseconds: 50
    });