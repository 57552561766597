import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { FavoriteMenuState } from './types';

export const createInitialState = (): FavoriteMenuState => {
    return {
        loadingState: CreateActionStatusInitialState(),
        favorites: [],
        invites: []
    };
};

export const initialState: FavoriteMenuState = createInitialState();