import { join } from "lodash";
import { hasValue } from "./valueHelper";

/**
 * Returns the distinct set of elements from the given array. 
 * Currently only tested with native types.
 * 
 * @param list The array from which to take the distinct set of elements
 */
export function distinct<T>(list: T[]): T[] {
    return Array.from(new Set<T>(list));
};

export function toJoinedString<T>(list: T[], selector: (item: T) => string | null | undefined, maxCharacterLength?: number) {
    const fullString = join(distinct(list.map(selector).filter(textValue => hasValue(textValue))), ", ");
    return (maxCharacterLength !== undefined && fullString.length > maxCharacterLength)
        ? `${fullString.substring(0, 75)}...`
        : fullString;
}

export function any<T>(list: T[], predicate: (item: T) => boolean): boolean {
    return list.filter(predicate).length > 0;
};

export function none<T>(list: T[], predicate: (item: T) => boolean): boolean {
    return !any(list, predicate);
};


export function all<T>(list: T[], predicate: (item: T) => boolean): boolean {
    return list.filter(predicate).length === list.length;
};

export function firstOrDefault<T>(list: T[], predicate: (item: T) => boolean, defaultIfNotFound: T): T {
    const filteredResults = list.filter(predicate);
    if (filteredResults.length > 0) return filteredResults[0];
    return defaultIfNotFound;
};

export function firstOrNull<T>(list: T[], predicate?: (item: T) => boolean): T | null {
    const filteredResults = predicate !== undefined ? list.filter(predicate) : list;
    if (filteredResults.length > 0) return filteredResults[0];
    return null;
};

export function firstOrUndefined<T>(list: T[], predicate?: (item: T) => boolean): T | undefined {
    const filteredResults = predicate !== undefined ? list.filter(predicate) : list;
    if (filteredResults.length > 0) return filteredResults[0];
    return undefined;
};

export function firstOrException<T>(list: T[], predicate: (item: T) => boolean): T {
    const filteredResults = list.filter(predicate);
    if (filteredResults.length > 0) return filteredResults[0];
    throw new Error("FirstOrException: no matching element was found for the given predicate and array");
};

export function singleOrDefault<T>(list: T[], predicate: (item: T) => boolean, defaultIfNotExactlyOne: T): T {
    const filteredResults = list.filter(predicate);
    if (filteredResults.length === 1) return filteredResults[0];
    return defaultIfNotExactlyOne;
};

export function singleOrNull<T>(list: T[], predicate: (item: T) => boolean): T | null {
    const filteredResults = list.filter(predicate);
    if (filteredResults.length === 1) return filteredResults[0];
    return null;
};

export function singleOrException<T>(list: T[], predicate: (item: T) => boolean): T {
    const filteredResults = list.filter(predicate);
    if (filteredResults.length === 1) return filteredResults[0];
    if (filteredResults.length === 0) {
        throw new Error("SingleOrException: no matching element was found for the given predicate and array");
    }
    else {
        throw new Error("SingleOrException: more than one matching element was found for the given predicate and array");
    }
};

export interface GroupOptions<TAggregate, TDetail> {
    details: TDetail[],
    deriveKey: (detail: TDetail) => string,
    aggregateFactory: (detail: TDetail, key: string) => TAggregate,
    addDetailToGroup: (detail: TDetail, aggregate: TAggregate) => void
}

export const group = <TAggregate, TDetail>({ details, deriveKey, aggregateFactory, addDetailToGroup }: GroupOptions<TAggregate, TDetail>): TAggregate[] => {

    const groupedData = details.reduce<{ [k: string]: TAggregate }>((a, c) => {
        const key: string = deriveKey(c);
        if (a[key] === undefined) {
            a[key] = aggregateFactory(c, key);
        }

        const aggregate: TAggregate = a[key];

        addDetailToGroup(c, aggregate);

        return a;
    }, Object.create(null));

    let groupings: TAggregate[] = [];
    for (const key in groupedData) {
        groupings.push(groupedData[key]);
    }

    return groupings;
}