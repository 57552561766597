import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { EntityImage } from "../../../commonModels/entityImage";
import { IntegerId } from "../../../commonModels/integerId";
import { InventoryView, VendorMenu } from "../../endUser/store/types";

export const sliceName: string = "product-details";

export const actionFactory = new ActionFactory<ProductDetailsState>(sliceName);

export interface Vendor extends IntegerId {
    companyName: string;
    stateAbbreviation: string;
    contactUrl: string;
    contactPhone: string;
    contactEmail: string;
    vendorUrlName: string;
    subscriptionUrl: string;
}

export interface ProductDetailsState {
    vendorLoadingState: ActionStatus;
    vendor: Vendor | null;

    vendorMenuLoadingState: ActionStatus;
    vendorMenu: VendorMenu | null;

    inventoryLoadingState: ActionStatus;
    inventoryView: InventoryView | null;

    thumbnailImage: EntityImage | null;
}