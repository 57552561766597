import { actionFactory } from "../types";

export const {

    removeFromFavorites,
    removeFromFavoritesReducer,
    removeFromFavoritesSagaFactory

} = actionFactory
    .withMappedName("removeFromFavorites")
    .createAsyncAction<{ menuId: number }, Response>({
        actionStatusSelector: state => state.isCurrentUserFavoriteActionState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.userProfile.removeMenuFromFavorites(arg.menuId);
        },
        onFulfilled: (_state, _action) => {
            _state.isCurrentUserFavorite = false;
        },
        debounceInMilliseconds: 50
    });