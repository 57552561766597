import { DateTime, Duration } from "luxon";
import { ColumnProps } from "primereact/column";
import { CustomTable } from ".";
import { createFilteredBooleanColumnProps } from "../../../../utils/table/booleanColumn";
import { createFilteredDateColumnProps } from "../../../../utils/table/filteredDateColumn";
import { createNumberColumnProps } from "../../../../utils/table/numberColumn";
import { createTextColumnProps } from "../../../../utils/table/stringColumn";

interface Widget {
    name: string;
    id: number;
    favoriteFood: string;
    favoriteCandy: string;
    isAwesome: boolean;
    created: DateTime;
}

const startDate = DateTime.now().minus(Duration.fromObject({ days: 365 }));

const widgetData: Widget[] = [...Array(10000).keys()].map(id => ({
    id: id,
    name: `I am widget # ${id}`,
    favoriteCandy: `My favorite candy is candy # ${id}`,
    favoriteFood: `My favorite food is food # ${id}`,
    isAwesome: id % 2 === 0,
    created: startDate.plus(Duration.fromObject({ days: id }))
}));


export const TestTable: React.FC = () => {

    const columns: ColumnProps[] = [
        createNumberColumnProps<Widget>({ header: "ID #", field: "id", style: { width: "100px" }, filter: true }),
        createTextColumnProps<Widget>({ header: "Widget Name", field: "name", style: { width: "200px" }, sortable: true, filter: true }),
        createTextColumnProps<Widget>({ header: "Favorite Food", field: "favoriteFood", style: { width: "200px" }, sortable: true, filter: true }),
        createTextColumnProps<Widget>({ header: "Favorite Candy", field: "favoriteCandy", style: { width: "200px" }, sortable: true, filter: true }),
        createFilteredDateColumnProps<Widget>({ header: "Created", field: "created", style: { width: "200px" } }),
        createFilteredBooleanColumnProps<Widget>({ header: "Is Awesome", field: "isAwesome", falseLabel: "Not Awesome", trueLabel: "Awesome", style: { width: "200px" } }),        
    ];

    return <CustomTable
        containerStyle={{ padding: "5px" }}
        viewId="TestTable"
        id="TestTable"
        columns={columns}
        size="small"
        value={widgetData}        
        paginator
        rows={20}
        rowsPerPageOptions={[10, 20, 50]}
        scrollHeight="calc(100vh - 220px)"
    />
} 