import { EntityState } from "@reduxjs/toolkit";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ModalEditState } from "../../../../store/factory/modalEdit/modalEdit";
import { IdentityMap } from "../../../../utils/identity";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { Inventory, OrderedInventoryDetails } from "../../../inventorySetup/inventoryManagement/store/types";
import { Product } from "../../../inventorySetup/productManagement/store/types";
import { Customer, SalesOrder, SalesOrderItem } from "../../common/types";

export const sliceName: string = "order-editor";

export const actionFactory = new ActionFactory<OrderEditorState>(sliceName);

export interface SearchProductsState extends ActionStatus {
    searchResults: Product[];
}

export interface SalesOrderItemEditModel extends Partial<SalesOrderItem> {
    remainingQuantity: number | null;
}

export interface OrderEditorState {
    loadingState: ActionStatus;    
    customer: Customer | null;
    salesOrder: SalesOrder | null;    
    salesOrderItems: EntityState<SalesOrderItem>;
    orderInventory: EntityState<Inventory>;
    products: Product[];   
    productMap: IdentityMap<Product>; 

    inventorySearchResults: Inventory[];
    inventorySearchState: ActionStatus;

    // State for editing an order item
    orderItemEditState: ModalEditState<SalesOrderItemEditModel>;
    orderedQuantities: OrderedInventoryDetails[];
    orderedQuantitiesLoadState: ActionStatus;  
    selectedInventory: Inventory | null;  
}