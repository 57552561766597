import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { createLoadMarketplaceProfileSaga } from "../featureActions/loadProfile";
import { createSaveMarketplaceProfileSaga } from "../featureActions/updateProfile";


export function createMarketplaceProfileSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        createLoadMarketplaceProfileSaga(context),
        createSaveMarketplaceProfileSaga(context),
    ]
}