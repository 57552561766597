import { Dropdown } from "primereact/dropdown";
import { useDispatch } from "react-redux";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { clearTestAssessmentValue } from "../store/featureActions/clearTestAssessmentValue";
import { setTestAssessmentValue } from "../store/featureActions/setTestAssessmentValue";
import { getQualityMetricKindById, getValuesForQualityMetricKind } from "../store/selectors/data";
import { getTestEntryValueForMetricKind } from "../store/selectors/editing";

export interface TestMetricEditorProps {
    metricKindId: number
}

export const TestMetricEditor: React.FC<TestMetricEditorProps> = ({ metricKindId }) => {

    const dispatch = useDispatch();
    const metricKind = useAppSelector(state => getQualityMetricKindById(state, metricKindId));
    const metricValues = useAppSelector(state => getValuesForQualityMetricKind(state)(metricKindId));
    const selectionViews = metricValues.map(metricValue => ({ label: metricValue.name, value: metricValue.id }));
    const entryValue = useAppSelector(state => getTestEntryValueForMetricKind(state)(metricKindId))


    return !isValue(metricKind)
        ? <div>Metric Not Found</div>
        : <FlexRowContainer
            key={metricKind.id}
            className="quality-assessment-test-field"
        >
            <div className="quality-assessment-test-editor-label">{metricKind.name}</div>

            <Dropdown
                className="quality-assessment-test-editor-input"
                options={selectionViews}
                showClear
                onChange={(e) => {
                    if (isValue(e.value)) {
                        dispatch(setTestAssessmentValue({
                            qualityMetricKindId: metricKindId,
                            qualityMetricValueId: e.value
                        }));
                    }
                    else {
                        dispatch(clearTestAssessmentValue({ qualityMetricKindId: metricKindId }));
                    }
                }}
                value={entryValue?.qualityMetricValueId}
            />

        </FlexRowContainer>
}