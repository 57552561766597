import { Vendor } from "../../../../menu/productDetails/store/types";
import { actionFactory } from "../types";

export const {

    showVendorContactDetails,
    showVendorContactDetailsReducer,
    showVendorContactDetailsSagaFactory
    
} = actionFactory
    .withMappedName("showVendorContactDetails")
    .createAsyncAction<string, Vendor>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.liveMenu.getVendor(arg);
        },
        onPending: (state, _) => {
            state.showVendor = true;
        },
        onFulfilled: (state, action) => {
            state.vendor = action.payload.result;
        },
        debounceInMilliseconds: 250
    });