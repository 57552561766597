import { ColumnProps } from "primereact/column";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { DataTableSelectionChangeParams } from "primereact/datatable";
import React from "react";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { any } from "../../../../utils/arrayHelpers";
import { createFilteredBooleanColumnProps } from "../../../../utils/table/booleanColumn";
import { createCalculatedValueColumnProps } from "../../../../utils/table/calculatedValueColumn";
import { createDateColumnProps } from "../../../../utils/table/dateColumn";
import { createMultiSelectFilterProps, MultiSelectFilterModel } from "../../../../utils/table/filters/multiSelectFilterElement";
import { createCircleCheckIconTemplate } from "../../../../utils/table/iconColumn";
import { createMultiActionColumnProps } from "../../../../utils/table/multiActionColumn";
import { createRowSelectionColumn } from "../../../../utils/table/multiSelectColumn";
import { createNumberColumnProps } from "../../../../utils/table/numberColumn";
import { createTextColumnProps } from "../../../../utils/table/stringColumn";
import { TypedCommandMenuItem } from "../../../../utils/table/typedMenuItem";
import { isValue } from "../../../../utils/valueHelper";
import { editQualityAssessmentBegin } from "../../../qualityAssessments/assessmentEditor/store/featureActions/editQualityAssessment";
import { CustomTable } from "../../../userAppSettings/userTableSettings/ui";
import { beginInventoryCategoryAssignment } from "../store/featureActions/categoryAssignment/beginInventoryCategoryAssignment";
import { editInventoryBegin } from "../store/featureActions/InventoryDetails/editInventory";
import { addInventoryBegin } from "../store/featureActions/InventoryDetails/addInventory";
import { beginEditInventoryImages } from "../store/featureActions/inventoryImages/beginEditInventoryImages";
import { createQualityAssessmentBegin } from "../store/featureActions/qualityGrades/createQualityAssessment";
import { removeQualityAssessmentBegin } from "../store/featureActions/qualityGrades/removeQualityAssessment";
import { setSelectedInventory } from "../store/featureActions/setSelectedInventory";
import { viewOrdersForInventory } from "../store/featureActions/viewOrdersForInventory";
import { getHasLoaded } from "../store/selectors/actionState";
import { getSelectedInventory } from "../store/selectors/bulkEdit";
import { getInventory } from "../store/selectors/inventory";
import { getQualityGradeFilterOptions, getQualityGradeValueMap } from "../store/selectors/qualityGrade";
import { InventoryType, InventoryView } from "../store/types";
import { deleteInventory } from "../store/featureActions/InventoryDetails/deleteInventory";
import { BulkActionToolbar } from "./bulkActionsToolbar";
import { createInitInventory } from "../../../../utils/inventoryHelper";
import './inventoryTable.css';

export const InventoryTable: React.FC = () => {

    const dispatch = useDispatch();
    const hasLoaded = useAppSelector(getHasLoaded);
    const inventory = useAppSelector(getInventory);
    const selectedInventory = useAppSelector(getSelectedInventory);

    const qualityGradeMap = useAppSelector(getQualityGradeValueMap);
    const qualityValueFilterOptions = useAppSelector(getQualityGradeFilterOptions);

    const onSelectionChange = (e: DataTableSelectionChangeParams): void => {
        console.log("onSelectionChange", e.value);
        const selectedInv: InventoryView[] = e.value;
        dispatch(setSelectedInventory(selectedInv.map(inv => inv.id)));
    };

    const onBeginCategoryAssignment = (inventory: InventoryView) => {
        dispatch(beginInventoryCategoryAssignment(inventory));
    }

    const onEditInventoryImages = (inventory: InventoryView) => {
        dispatch(beginEditInventoryImages(inventory));
    };

    const onDeleteInventory = (inventory: InventoryView) => {
        dispatch(deleteInventory(inventory));
    };

    const onViewOrders = (inventory: InventoryView) => {
        dispatch(viewOrdersForInventory(inventory.id));
    }

    const onViewQA = (inventory: InventoryView) => {
        if (!isValue(inventory.qualityAssessmentId)) return;
        dispatch(editQualityAssessmentBegin({
            qualityAssessmentId: inventory.qualityAssessmentId,
            loadInventoryOnComplete: true
        }));
    }

    const onRemoveQA = (inventory: InventoryView) => {
        if (!isValue(inventory.qualityAssessmentId)) return;
        dispatch(removeQualityAssessmentBegin({ inventoryId: inventory.id }));
    }

    const onLogQA = (inventory: InventoryView) => {
        dispatch(createQualityAssessmentBegin({ inventoryId: inventory.id }));
    }

    const individualActionMenuItems: TypedCommandMenuItem<InventoryView>[] = [
        {
            label: "Edit Images",
            command: onEditInventoryImages
        },
        {
            label: "Delete",
            command: onDeleteInventory,
            disabled: (inventory) => inventory.inventoryType === InventoryType.Metric
        },
        {
            label: "Assign Categories",
            command: onBeginCategoryAssignment
        },
        {
            label: "View Orders",
            command: onViewOrders,
            disabled: (inventory) => inventory.orderedQuantity === 0
        },
        {
            label: "View Quality Assessment",
            command: onViewQA,
            disabled: (inventory) => !isValue(inventory.qualityAssessmentId),
            visible: (inventory) => isValue(inventory.qualityAssessmentId)
        },
        {
            label: "Remove Quality Assessment",
            command: onRemoveQA,
            disabled: (inventory) => !isValue(inventory.qualityAssessmentId),
            visible: (inventory) => isValue(inventory.qualityAssessmentId)
        },
        {
            label: "Log Quality Assessment",
            command: onLogQA,
            disabled: (inventory) => isValue(inventory.qualityAssessmentId),
            visible: (inventory) => !isValue(inventory.qualityAssessmentId)
        }
    ];

    const onEditInventory = (target: InventoryView) => {
        dispatch(editInventoryBegin(target));
    }

    const onAddInventory = () => {
        dispatch(addInventoryBegin(createInitInventory()));
    }

    const getInventoryExcluded = (inv: InventoryView): boolean => {

        return inv.excludeFromMarket;
    }

    const getInventoryMenuAssignment = (inv: InventoryView): boolean => {
        return !!inv.onMenuDirectly;
    }

    const getInventoryProductMenuAssignment = (inv: InventoryView): boolean => {
        return !!inv.onMenuViaProduct;
    }

    const getQualityGradeId = (value: InventoryView) => {
        return isValue(value.qualityGradeId)
            ? value.qualityGradeId
            : isValue(value.qualityAssessmentGradeValueId)
                ? value.qualityAssessmentGradeValueId
                : null;
    }

    const getQualityGradeName = (value: InventoryView) => {
        const gradeId = getQualityGradeId(value);
        return isValue(gradeId)
            ? qualityGradeMap[gradeId]?.name ?? null
            : null;
    }

    const qualityFilterFunction = (value: number | null, filter: MultiSelectFilterModel<number>): boolean => {
        if (filter === null) return true;

        return !isValue(value)
            ? any(filter.selectedOptions, option => option === -1)
            : any(filter.selectedOptions, option => option === value);
    };

    const columns: ColumnProps[] = [
        createRowSelectionColumn(),
        createMultiActionColumnProps<InventoryView>({
            columnKey: "id",
            header: "Actions",
            actionName: "Edit",
            actionHandler: onEditInventory,
            style: { width: "120px" },
            menuItems: individualActionMenuItems,
            menuClassName: 'inv-table-menu-option'
        }),
        createFilteredBooleanColumnProps<InventoryView>({
            field: "onMenuDirectly",
            header: "On Menu - Direct",
            style: { width: "120px" },
            customTemplateFn: createCircleCheckIconTemplate(getInventoryMenuAssignment),
            falseLabel: "Not Assigned",
            trueLabel: "Assigned"
        }),
        createFilteredBooleanColumnProps<InventoryView>({
            field: "onMenuViaProduct",
            header: "On Menu - Via Item",
            style: { width: "120px" },
            customTemplateFn: createCircleCheckIconTemplate(getInventoryProductMenuAssignment),
            falseLabel: "Not Assigned",
            trueLabel: "Assigned"
        }),
        createFilteredBooleanColumnProps<InventoryView>({
            field: "excludeFromMarket",
            header: "Excluded",
            style: { width: "120px" },
            customTemplateFn: createCircleCheckIconTemplate(getInventoryExcluded),
            falseLabel: "Not Excluded",
            trueLabel: "Excluded"
        }),
        createCalculatedValueColumnProps<InventoryView>({
            columnKey: "calulatedQualityGradeValueId",
            header: "Quality Grade",
            field: "calulatedQualityGradeValueId",
            style: { width: "120px" },
            getValueFn: getQualityGradeName,
            ...createMultiSelectFilterProps<number>({
                filterFunction: qualityFilterFunction,
                selectOptions: qualityValueFilterOptions
            }),
            sortable: true
        }),
        createTextColumnProps<InventoryView>({ field: "metrcPackageLabel", header: "Package Label", style: { width: "250px" }, filter: true }),
        createTextColumnProps<InventoryView>({ field: "metrcPackageType", header: "Package Type", style: { width: "250px" }, filter: true }),
        createDateColumnProps<InventoryView>({ field: "readyDate", header: "Ready Date", style: { width: "250px" }, filter: true }),
        createTextColumnProps<InventoryView>({ field: "licenseNumber", header: "License", style: { width: "200px" }, filter: true }),
        createTextColumnProps<InventoryView>({ field: "marketingName", header: "Marketing Name", style: { width: "200px" }, filter: true }),
        createTextColumnProps<InventoryView>({ field: "marketingStrainName", header: "Marketing Strain", style: { width: "200px" }, filter: true }),
        createTextColumnProps<InventoryView>({ field: "metrcName", header: "Metrc Name", style: { width: "200px" }, filter: true }),
        createTextColumnProps<InventoryView>({ field: "metrcStrainName", header: "Metrc Strain", style: { width: "200px" }, filter: true }),
        createNumberColumnProps<InventoryView>({ field: "quantity", header: "Quantity", style: { width: "120px" }, filter: true, dataType: "numeric", filterType: "numeric" }),
        createNumberColumnProps<InventoryView>({ field: "orderedQuantity", header: "Ordered", style: { width: "120px" }, filter: true, dataType: "numeric", filterType: "numeric" }),
        createNumberColumnProps<InventoryView>({ field: "reservedQuantity", header: "Reserved", style: { width: "120px" }, filter: true, dataType: "numeric", filterType: "numeric" }),
        createTextColumnProps<InventoryView>({ field: "unitOfMeasureName", header: "UOM", style: { width: "200px" }, filter: true }),
        createTextColumnProps<InventoryView>({ field: "inventoryType", header: "Type", style: { width: "200px" }, filter: true }),
    ];

    return ((isValue(inventory) && inventory.length > 0) || hasLoaded)
        ? <FlexColumnContainer gap="15px" padding="0px 20px">
            <div className="p-d-flex p-jc-left p-ai-center">
                <BulkActionToolbar />
            </div>
            <div>
                <Button label="Add new"  
                        icon={PrimeIcons.PLUS} 
                        tooltip="Add new inventory" 
                        tooltipOptions={{ position: "top" }} 
                        onClick={onAddInventory}
                        />
            </div>

            <CustomTable
                viewId="inventory-management"
                columns={columns}
                dataKey="id"
                value={inventory}
                size="small"
                scrollHeight='calc(100vh - 330px)'
                onSelectionChange={onSelectionChange}
                selection={selectedInventory}
                selectionMode="checkbox"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
            />

        </FlexColumnContainer>
        : <div></div>
}