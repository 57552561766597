import { Button } from 'primereact/button';
import { Galleria } from 'primereact/galleria';
import React, { useRef } from "react";
import { FlexRowContainer } from '../../../../components/containers/flexRowContainer';
import { useAppSelector } from '../../../../store/configureStore';
import { RootState } from '../../../../store/rootReducer';
import { createLocalImageUrlFromEntityImage } from '../../../../utils/imageHelper';
import { isEntityImage } from '../../../commonModels/entityImage';
import { MixedMedia } from '../../../commonModels/mixedMedia';

export interface ShowGalleryProps {
    mediaSelector: (state: RootState) => MixedMedia[];
};

export const ShowGallery: React.FC<ShowGalleryProps> = ({ mediaSelector }) => {

    const galleriaRef = useRef<Galleria>(null);
    const entityMedia = useAppSelector(mediaSelector);

    const galleryTemplate = (item: MixedMedia) => {
        if (isEntityImage(item)) {
            const localImageUrl = createLocalImageUrlFromEntityImage(item);
            return <img src={localImageUrl} alt={item.imageTitle} style={{ width: '100%', display: 'block' }} />;
        }
        else {
            return <FlexRowContainer>
                <iframe
                    title={item.title}
                    src={item.embedUrl}
                    width="640"
                    height="360"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"                    
                />
            </FlexRowContainer>
        }
    }

    const onShowGallery = () => {
        if (galleriaRef.current === null) return;
        galleriaRef.current.show();
    };

    return <div>

        <Galleria
            ref={galleriaRef}
            value={entityMedia}
            numVisible={7}
            style={{ maxWidth: '850px' }}
            circular
            fullScreen
            showItemNavigators
            showThumbnails={false}
            item={galleryTemplate}
        />

        <Button
            label="View as live menu gallery"
            onClick={onShowGallery}
        />
    </div>
}