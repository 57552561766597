import { EntityId } from "@reduxjs/toolkit";
import clsx from "clsx";
import { FormikHelpers } from "formik";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { confirmPopup } from 'primereact/confirmpopup';
import { DataView } from 'primereact/dataview';
import { Divider } from "primereact/divider";
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch } from "react-redux";
import { FormikEmbeddedEditor } from '../../../../components/formik/embeddedEditor';
import { StringField } from '../../../../components/formik/stringField';
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { Category } from "../../categoryManagement/store/types";
import { deleteMenuSection } from "../store/featureActions/deleteMenuSection";
import { resequenceMenuSection } from "../store/featureActions/resequenceMenuSection";
import { updateMenuSection } from "../store/featureActions/updateMenuSection";
import { getMenuSectionById, getUseSmallMenuSectionView } from "../store/selectors/menuSections";
import { isMenuSection, MenuSection } from "../store/types";
import { menuSectionValidations } from '../validations';
import { dragAndDropTypes, DragCollectPayload, DropCollectPayload } from "./dragAndDropTypes";
import { MenuSectionCategoryView } from "./menuSectionCategoryView";
import './menuSectionView.css';


export interface MenuSectionViewProps {
    sectionId: EntityId
}

export const MenuSectionView: React.FC<MenuSectionViewProps> = ({ sectionId }) => {

    const dispatch = useDispatch();
    const menuSection = useAppSelector(state => getMenuSectionById(state, sectionId));
    const useSmallMenuSectionView = useAppSelector(getUseSmallMenuSectionView);

    const onResequenceMenuSection = (target: MenuSection, newSequence: number) => {
        dispatch(resequenceMenuSection({ ...target, sequence: newSequence }));
    }

    const [dropCollectPayload, drop] = useDrop<Category | MenuSection, MenuSection, DropCollectPayload>(() => ({
        accept: [dragAndDropTypes.Category, dragAndDropTypes.MenuSection],
        canDrop: (dropItem, _monitor) => {

            if (isMenuSection(dropItem)) {
                return dropItem !== menuSection;
            }
            else {
                return !menuSection?.categoryIds.includes(dropItem.id);
            }

        },
        drop: (_category, _monitor) => menuSection,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    }), [menuSection?.categoryIds]);

    const [dragCollectProps, drag] = useDrag<MenuSection, MenuSection, DragCollectPayload<MenuSection>>(() => ({
        type: dragAndDropTypes.MenuSection,
        item: menuSection,
        canDrag: true,
        collect: (monitor) => {
            return {
                draggedItem: monitor.getItem(),
                canDrag: monitor.canDrag(),
                isDragging: monitor.isDragging()
            };
        },
        end: (droppedMenuSection, monitor) => {
            const droppedOnMenu = monitor.getDropResult();
            if (droppedOnMenu !== null) {
                onResequenceMenuSection(droppedMenuSection, droppedOnMenu.sequence);
            }
        }
    }));

    const onSubmit = (values: MenuSection, formikHelpers: FormikHelpers<MenuSection>): void | Promise<any> => {
        dispatch(updateMenuSection(values));
        formikHelpers.setSubmitting(false);
    };

    const itemTemplate = (id: number) => <MenuSectionCategoryView key={id} categoryId={id} menuSectionId={menuSection.id} />

    const isDraggingBelow = isValue(dragCollectProps.draggedItem) && dropCollectPayload.isOver && dragCollectProps.draggedItem.sequence < menuSection.sequence;
    const isDraggingAbove = isValue(dragCollectProps.draggedItem) && dropCollectPayload.isOver && dragCollectProps.draggedItem.sequence > menuSection.sequence;

    const onDeleteMenuSection = (e: React.MouseEvent<HTMLButtonElement>) => {
        confirmPopup({
            target: e.currentTarget,
            message: 'Are you sure you want to delete this menu section?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => dispatch(deleteMenuSection(menuSection)),
            reject: () => { }
        });
    }

    return <div className="p-shadow-5 p-col-12 p-mb-2">
        <div
            className={clsx("p-p-2", {
                "drag-below-container": isDraggingBelow,
                "drag-above-container": isDraggingAbove,
                "drop-target": dropCollectPayload.isOver && !isMenuSection(dragCollectProps.draggedItem)
            })}
            ref={drop}
        >
            <div className="p-grid">
                <div className="p-col-1" style={{ cursor: "grab" }} ref={drag} >
                    <span className={PrimeIcons.ELLIPSIS_V}></span>
                </div>
                <div className="p-col-9">
                    <FormikEmbeddedEditor<MenuSection>
                        initialValues={menuSection}
                        onSave={onSubmit}
                        validationSchema={menuSectionValidations}
                        className="section-view-editor"
                    >
                        <StringField<MenuSection>
                            defaultLabel=""
                            fieldName="name"
                            inputClassName="section-view-name-editor"
                            submitOnBlur={true}
                        />

                    </FormikEmbeddedEditor>                   
                </div>
                <div className="p-col-2"  >
                    <Button
                        icon="pi pi-times"
                        className="p-button-rounded p-button-danger p-button-outlined p-button-sm"
                        onClick={onDeleteMenuSection}
                    />
                </div>
            </div>
            <div
                style={{ display: useSmallMenuSectionView ? "none" : "block" }}
            >
                <Divider style={{ margin: "0px 0px 12px 0px" }}>Categories</Divider>
                <div className="p-grid p-p-4">
                    <DataView
                        value={menuSection.categoryIds}
                        itemTemplate={itemTemplate}
                    />
                </div>
            </div>
        </div>

    </div>
}