import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";

export const sliceName: string = "gallery-testing";

export const actionFactory = new ActionFactory<GalleryTestingState>(sliceName);

export interface Model1 {

}

export interface GalleryTestingState {
    loadingState: ActionStatus;  
    data: Model1[];      
}