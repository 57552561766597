import { isValue } from "../../../../../../utils/valueHelper";
import { ImageKind } from "../../../../../commonModels/imageTypes";
import { actionFactory } from "../../types";
import { loadBanner } from "./loadBanner";

export const {

    saveBanner,
    saveBannerReducer,
    saveBannerSagaFactory

} = actionFactory
    .withMappedName("saveBanner")
    .createAsyncAction<void, Response>({
        actionStatusSelector: state => state.bannerState.saveState,
        asyncCall: async (_arg, context) => {
            const state = context.getState();

            const companyId = state.companyProfile.companyProfile.id;
            if (!isValue(companyId)) throw new Error("Cannot save image without valid company instance");

            if (state.companyProfile.bannerState.newImagePreview !== undefined) {
                const blob = await fetch(state.companyProfile.bannerState.newImagePreview).then(localFile => localFile.blob());
                return context.marketplaceApiClient.company.uploadImage(companyId, blob, ImageKind.Banner);
            }

            throw new Error("Attempt to save banner without new image specified")
        },
        onFulfilled: (state, _action) => {
            state.bannerState.newImagePreview = undefined;
        },
        successCallback: (_arg, _result, context) => {
            const companyId = context.getState().companyProfile.companyProfile?.id;
            if (!isValue(companyId)) throw new Error("Company instance was not valid");
            context.dispatch(loadBanner({ companyId: companyId }));
        }
    });