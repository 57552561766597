import { FormikHelpers } from "formik";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { FormikEmbeddedEditor } from "../../../../components/formik/embeddedEditor";
import { StringField } from "../../../../components/formik/stringField";
import { EntityVideo } from "../../../commonModels/entityVideo";
import { entityVideoValidations } from "../../inventoryManagement/validations/entityVideoValidations";

export interface VideoActions {   
    onSave: (update: EntityVideo) => void;
    onDelete: (data: EntityVideo) => void;
}

export interface VideoListDisplayProps extends VideoActions {
    data: EntityVideo;     
}

export const VideoListDisplay: React.FC<VideoListDisplayProps> = ({ data, onDelete, onSave }) => {

    const onSubmit = (values: EntityVideo, _formikHelpers: FormikHelpers<EntityVideo>): void | Promise<any> => {
        onSave(values);        
    };

    const onDeleteVideo = () => {
        onDelete(data);
    }

    return <FlexRowContainer className="entity-media-list-display">

        <iframe
            title={data.title}
            src={data.embedUrl}
            width="150"
            height="150"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
        />

        <FormikEmbeddedEditor<EntityVideo>
            initialValues={data}
            onSave={onSubmit}          
            className="entity-media-details-form"   
            validationSchema={entityVideoValidations}           
        >

            <FlexColumnContainer gap="20px">

                <StringField<EntityVideo>
                    fieldName="title"
                    defaultLabel="Title"
                    submitOnBlur={true}
                    inputStyle={{ width: "100%" }}
                />

                <StringField<EntityVideo>
                    fieldName="embedUrl"
                    defaultLabel="Embed Url"
                    submitOnBlur={true}
                    inputStyle={{ width: "100%" }}
                />

            </FlexColumnContainer>

        </FormikEmbeddedEditor>

        <Button onClick={onDeleteVideo} icon={PrimeIcons.TIMES} label="Delete" />

    </FlexRowContainer>
}