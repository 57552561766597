import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { loadCustomerProfileReducer } from './featureActions/loadCustomerProfile';
import { saveCompanyProfileReducers } from './featureActions/saveCustomerProfile';
import { searchStatesReducers } from './featureActions/searchStates';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';
import { deleteBannerReducer } from './featureActions/images/deleteBanner';
import { deleteLogoReducer } from './featureActions/images/deleteLogo';
import { deleteNewBannerReducer } from './featureActions/images/deleteNewBanner';
import { deleteNewLogoReducer } from './featureActions/images/deleteNewLogo';
import { loadBannerReducer } from './featureActions/images/loadBanner';
import { loadLogoReducer } from './featureActions/images/loadLogo';
import { saveBannerReducer } from './featureActions/images/saveBanner';
import { saveLogoReducer } from './featureActions/images/saveLogo';
import { setNewBannerReducer } from './featureActions/images/setNewBanner';
import { setNewLogoReducer } from './featureActions/images/setNewLogo';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(loadCustomerProfileReducer)
            .add(saveCompanyProfileReducers)
            .add(searchStatesReducers)
            .add(deleteBannerReducer)
            .add(deleteLogoReducer)
            .add(deleteNewBannerReducer)
            .add(deleteNewLogoReducer)
            .add(loadBannerReducer)
            .add(loadLogoReducer)
            .add(saveBannerReducer)
            .add(saveLogoReducer)
            .add(setNewBannerReducer)
            .add(setNewLogoReducer);
            // Create your feature actions in ./featureActions and then add them to the reducer factory here
            // with .Add(<your-action-reducer-name>);
            
    }
});

export default slice.reducer;

export const { resetState } = slice.actions;