import { push } from "connected-react-router";
import { PrimeIcons } from "primereact/api";
import { Column, ColumnProps } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { createFilteredBooleanColumnProps, createInitialInactiveFilterState } from "../../../../utils/table/booleanColumn";
import { createDateColumnProps } from "../../../../utils/table/dateColumn";
import { useInitialFilterState } from "../../../../utils/table/filters/initialFilterStateFunctions";
import { createMultiActionColumnProps } from "../../../../utils/table/multiActionColumn";
import { createTextColumnProps } from "../../../../utils/table/stringColumn";
import { isValue } from "../../../../utils/valueHelper";
import { QualityTemplateEditorRoute } from "../../../navigation/routes";
import { AssessmentTemplate } from "../../models/assessmentTemplate";
import { copyTemplate } from "../store/featureActions/copyTemplate";
import { deleteTemplate } from "../store/featureActions/deleteTemplate";
import { getQualityTemplates } from "../store/selectors/data";


export const QualityTemplateTable: React.FC = () => {

    const dispatch = useDispatch();
    const templates = useAppSelector(getQualityTemplates);
    const filterState = useInitialFilterState({
        filterMeta: {
            'inactive': createInitialInactiveFilterState()
        }}
    );

    const onDelete = (value: AssessmentTemplate) => {
        
        confirmDialog({
            className: "publish-template-confirm-dialog",
            rejectLabel: "Keep this template",
            acceptLabel: "Delete this template",
            header: "Delete Quality Template",
            message: "Are you sure you want to delete this template?",
            accept: () => {
                dispatch(deleteTemplate(value));
            }
        });
    }

    const columns: ColumnProps[] = [
        createMultiActionColumnProps<AssessmentTemplate>({
            header: "",
            columnKey: "action-column",
            style: { width: 110, maxWidth: 110 },
            actionName: "Edit",
            actionHandler: (value) => {
                dispatch(push(QualityTemplateEditorRoute.createPath(value.id)));
            },
            menuItems: [               
                {                    
                    label: "Create Copy",
                    icon: PrimeIcons.COPY,
                    command: (value) => {
                        dispatch(copyTemplate(value.id));
                    }
                },
                {                    
                    label: "Delete",
                    icon: PrimeIcons.TRASH,
                    disabled: (value) => isValue(value.publishedDate),
                    command: onDelete
                }
            ]
        }),
        createTextColumnProps<AssessmentTemplate>({ header: "Name", field: "name", style: { minWidth: 300, maxWidth: 600 } }),
        createDateColumnProps<AssessmentTemplate>({ header: "Published", field: "publishedDate", style: { width: 140, maxWidth: 140 } }),        
        createFilteredBooleanColumnProps<AssessmentTemplate>({
            header: "Inactive",
            field: "inactive",
            disabledFn: () => true,
            style: { width: 140, maxWidth: 140 },
            falseLabel: "Active",
            trueLabel: "Inactive"
        })
    ];

    return <DataTable
        {...filterState}
        size="small"
        dataKey="id"
        value={templates}
        scrollable        
        scrollHeight='flex'
        paginator
        rows={20}
        rowsPerPageOptions={[10, 20, 50]}        
    >
        {columns.map(c => <Column key={c.columnKey ?? c.field} {...c} />)}
    </DataTable>
}