import { DataView, DataViewLayoutType } from 'primereact/dataview';
import { MenuItem } from "primereact/menuitem";
import { SplitButton } from "primereact/splitbutton";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { Menu } from "../../../menu/editor/store/types";
import { openMenuInNewWindow } from "../../../navigation/helper";
import { loadFavoriteMenus } from "../store/featureActions/loadFavoriteMenus";
import { removeFromFavorites } from "../store/featureActions/removeFromFavorites";
import { getFavoriteMenus } from "../store/selectors/favoriteMenus";

export const FavoritesList: React.FC = () => {

    const menus = useAppSelector(getFavoriteMenus);
    const dispatch = useDispatch();

    useMountEffect(() => {
        dispatch(loadFavoriteMenus());
    });

    const itemTemplate = (favoriteMenu: Menu, _layout: DataViewLayoutType) => {

        const onRemoveFromFavorites = () => {
            dispatch(removeFromFavorites({ menuId: favoriteMenu.id }));
        }

        const onViewMenu = () => {
            openMenuInNewWindow(favoriteMenu);
        }

        const menuItems: MenuItem[] = [
            {
                label: "Remove From Favorites",
                command: onRemoveFromFavorites,
                style: { width: "600px" }
            }
        ];

        return <div className="p-d-flex p-flex-column p-jc-start p-ai-start p-mt-2" style={{ width: "100%" }}>

            <div className="favorites-list-menu-name p-mb-2">{favoriteMenu.name}</div>

            <div className="p-d-flex p-flex-row p-jc-start p-ai-center" style={{ width: "100%" }}>

                <div className="favorites-list-vendor-name" style={{ width: "100%" }}>Vendor: {favoriteMenu.vendorName}</div>
                <div className="p-d-flex p-flex-row p-jc-end p-ai-center" style={{ width: "100%" }}>
                    <SplitButton
                        label="View"
                        onClick={onViewMenu}
                        model={menuItems}
                        className="p-mb-2 p-button-sm"
                    />
                </div>
            </div>


        </div>
    }

    return <DataView
            dataKey="id"
            value={menus}
            paginator
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            itemTemplate={itemTemplate}
            alwaysShowPaginator={false}
        >
        </DataView>    
}