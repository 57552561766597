import { useDispatch } from "react-redux";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { AssessmentTemplate } from "../../models/assessmentTemplate";
import { assessmentTemplateValidationSchema } from "../../validations/assessmentTemplateValidations";
import { addTemplateCancel, addTemplateComplete } from "../store/featureActions/addTemplate";
import { getAddTemplateState, getAddTemplateValue, getAddTemplateVisible } from "../store/selectors/editing";
import { AddTemplateEditor } from "./addTemplateEditor";


export const AddTemplateDialog: React.FC = () => {

    const dispatch = useDispatch();

    const onSave = (values: Partial<AssessmentTemplate>): void => {
        dispatch(addTemplateComplete(values));
    };

    const onCancel = (): void => {
        dispatch(addTemplateCancel());
    }

    return <FormikModalEditor<Partial<AssessmentTemplate>>
        statusSelector={getAddTemplateState}
        valueSelector={getAddTemplateValue}
        visibleSelector={getAddTemplateVisible}
        onSave={onSave}
        onCancel={onCancel}
        validationSchema={assessmentTemplateValidationSchema}        
        title="Add Template"
    >
       <AddTemplateEditor />
    </FormikModalEditor>
}