import { PricingModel } from "../../../sharedTypes";
import { pricingModelAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    deletePricingModel,
    deletePricingModelReducer,
    deletePricingModelSagaFactory

} = actionFactory
    .withMappedName("deletePricingModel")
    .createAsyncAction
        <PricingModel, Response>({            
            actionStatusSelector: state => state.addPricingModelState,
            asyncCall: (arg, context) => {
                return context.marketplaceApiClient.pricing.deletePricingModel(arg);
            },
            onFulfilled: (state, action) => {
                pricingModelAdapter.removeOne(state.pricingModels, action.payload.arg.id);
            }
        });