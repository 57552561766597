import { qualityGradeKindAdapter, qualityGradeValueAdapter, qualityMetricKindAdapter, qualityMetricValueAdapter, templateMetricAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    clearQualityReferenceData,
    clearQualityReferenceDataReducer

} = actionFactory
    .withMappedName("clearQualityReferenceData")
    .createAction<void>((state, _action) => {
        qualityGradeKindAdapter.removeAll(state.gradeKinds);
        qualityGradeValueAdapter.removeAll(state.gradeValues);
        qualityMetricKindAdapter.removeAll(state.metricKinds);
        qualityMetricValueAdapter.removeAll(state.metricValues);
        templateMetricAdapter.removeAll(state.templateMetrics);
    });