import { isValue } from "../../../../../utils/valueHelper";
import { viewSettingsAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    completeTableRemount,
    completeTableRemountReducer,    
    completeTableRemountSagaFactory

} = actionFactory
    .withMappedName("completeTableRemount")
    .createAsyncAction<{ viewId: string }, void>({
        actionStatusSelector: (_state) => ({ isExecuting: false }),
        asyncCall: (_arg, _context) => {
            return new Promise(resolve => setTimeout(resolve, 10))
        },        
        onFulfilled: (state, action) => {
            const viewSettings = state.viewSettings.entities[action.payload.arg.viewId];
            if (!isValue(viewSettings)) return;
    
            viewSettings.remountState = { 
                remountInProgress: false,
                showTable: true,
                tableRemountNeeded: false,
                needsStateReapplied: false
             };
            viewSettingsAdapter.upsertOne(state.viewSettings, viewSettings);        
        }
    });