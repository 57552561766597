import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit';
import { call, delay, put, race, StrictEffect, take, takeLatest } from 'redux-saga/effects';
import { messageFromError } from '../../../features/errorHandling/errorMessageHelper';
import { isValue } from '../../../utils/valueHelper';
import { SagaAppContext } from '../../rootSaga';

export interface RejectedPayload<TArg> {
    arg: TArg,
    error: string
}
export interface FulfilledPayload<TArg, TResult> {
    arg: TArg,
    result: TResult
}
export interface SagaActions<TArg, TResult> {
    beginPollingAction: ActionCreatorWithPayload<TArg>,
    onPollSucessAction: ActionCreatorWithPayload<FulfilledPayload<TArg, TResult>>,
    onPollFailureAction: ActionCreatorWithPayload<RejectedPayload<TArg>>,
    cancelPollingAction: ActionCreatorWithoutPayload,
    onPollingComplete?: ActionCreatorWithoutPayload,
}

interface PollingSagaOptions<TArg, TResult> {
    appContext: SagaAppContext,
    sagaActions: SagaActions<TArg, TResult>,
    action: (arg: TArg, context: SagaAppContext) => Promise<TResult>,
    pollResultPredicate: (arg: TArg, result: TResult) => Promise<boolean>,
    pollIntervalMs?: number | ((arg: TArg, context: SagaAppContext) => Promise<number>)
};

type CallFnType<TArg, TResult> = (arg: PollingSagaOptions<TArg, TResult>, action: PayloadAction<TArg>) => any;

function* executePollAction<TArg, TResult>(options: PollingSagaOptions<TArg, TResult>, action: PayloadAction<TArg>): Generator<StrictEffect, void, TResult | boolean | number> {
    try {
        while (true) {
            const result = yield call(options.action, action.payload, options.appContext);

            if (typeof (result) !== "boolean" && typeof (result) !== "number") {
                yield put(options.sagaActions.onPollSucessAction({ arg: action.payload, result: result }));

                const continuePolling = yield call<(arg: TArg, result: TResult) => Promise<boolean>>(options.pollResultPredicate, action.payload, result);

                if (typeof (continuePolling) === "boolean" && !continuePolling) {
                    return;
                }
            }

            const pollInterval = options.pollIntervalMs ?? 4000;

            let pollIntervalMilliseconds: number = 4000;

            if (typeof (pollInterval) === "number") {
                pollIntervalMilliseconds = pollInterval;
            }
            else {
                const pollIntervalFuncResult = yield call<(arg: TArg, context: SagaAppContext) => Promise<number>>(pollInterval, action.payload, options.appContext);
                if (typeof (pollIntervalFuncResult) === "number") {
                    pollIntervalMilliseconds = pollIntervalFuncResult;
                }
            }

            yield delay(pollIntervalMilliseconds, "poll delay complete");
        }
    }
    catch (error) {
        yield put(options.sagaActions.onPollFailureAction({ arg: action.payload, error: messageFromError(error) }));
    }
};

function* pollUntilCanceled<TArg, TResult>(options: PollingSagaOptions<TArg, TResult>, action: PayloadAction<TArg>): Generator<StrictEffect, void, void> {
    yield race({
        poll: call<(options: PollingSagaOptions<TArg, TResult>, action: PayloadAction<TArg>) => any>(executePollAction, options, action),
        cancel: take(options.sagaActions.cancelPollingAction.type)
    });

    if (isValue(options.sagaActions.onPollingComplete)) {
        yield put(options.sagaActions.onPollingComplete());
    }
};


export function createPollingSaga<TArg = void, TResult = void>(options: PollingSagaOptions<TArg, TResult>) {
    return function* watchRequests(): Generator<StrictEffect, void, void> {
        yield takeLatest<PayloadAction<TArg>, CallFnType<TArg, TResult>>(options.sagaActions.beginPollingAction.type, pollUntilCanceled, options);
    }
};