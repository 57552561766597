import { isValue } from "../../../../../../utils/valueHelper";
import { ImageKind } from "../../../../../commonModels/imageTypes";
import { actionFactory } from "../../types";

export const {

    deleteBanner,
    deleteBannerReducer,
    deleteBannerSagaFactory
} = actionFactory
    .withMappedName("deleteBanner")
    .createAsyncAction<void, Response>({
        actionStatusSelector: state => state.bannerState.saveState,
        asyncCall: (_, context) => {
            const menuId = context.getState().menuAdmin.menu?.id;
            if (!isValue(menuId)) throw new Error("Cannot delete image without valid menu instance");
            return context.marketplaceApiClient.menuAdmin.deleteImage(menuId, ImageKind.Banner);
        },
        onFulfilled: (state, _) => {
            state.bannerState.currentImage = null;
        },
        debounceInMilliseconds: 100
    });