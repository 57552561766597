import { isValue } from "../../../../../utils/valueHelper";
import { TemplateConstraintNode } from "../../../models/constraintNode";
import { TemplateConstraint } from "../../../models/templateConstraint";
import { EntityExpansionMap, TreeNodeExpandedKeys } from "../types";

export const getInitialExpansionStateForGradeKind = (templateConstraints: TemplateConstraint[], constraintNodes: TemplateConstraintNode[]) => {

    return templateConstraints
        .map<TreeNodeExpandedKeys>(templateConstraint => {
            const keys = isValue(templateConstraint.rootConstraintNodeId)
                ? constraintNodes
                    .filter(node => node.rootNodeId === templateConstraint.rootConstraintNodeId)
                    .map(node => node.id)
                : [];

            if (isValue(templateConstraint.rootConstraintNodeId)) {
                keys.push(templateConstraint.rootConstraintNodeId);
            }

            const expansionMap: EntityExpansionMap = {};
            keys.forEach(key => {
                expansionMap[key] = true;
            });

            return {
                gradeValueId: templateConstraint.gradeValueId,
                expandedKeys: expansionMap
            };
        });
}