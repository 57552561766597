import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, LiveMenuState } from "../types";

export const setOptionsDialogVisibility = actionFactory.createAppAction<boolean>("setOptionsDialogVisibility");

export function showOptionsDialogReducers(reducerFactory: IReducerFactory<LiveMenuState>): IReducerFactory<LiveMenuState> {
    return reducerFactory
        .forAction(setOptionsDialogVisibility)
        .addHandler((state, action) => {
            state.optionDialogVisible = action.payload;
        })
        .build();
}