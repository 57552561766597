import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { contactAdapter } from "../state";

const customerContactSelectors = contactAdapter.getSelectors((state: RootState) => state.customerEditor.customerContacts);
export const getCustomerContacts = createSelector(
    [
        customerContactSelectors.selectAll
    ],
    (contacts) => {
        return contacts
            .slice()
            .sort((left, right) => left.name.localeCompare(right.name))
    }
);

export const getEditContactValue = (state: RootState) => state.customerEditor.editContactState.editedValue;

export const getEditContactStatus = (state: RootState) => state.customerEditor.editContactState;

export const getEditContactVisible = (state: RootState) => state.customerEditor.editContactState.isVisible;