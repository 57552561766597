import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { validStates } from "../../../../../utils/usStates";
import { actionFactory, TermsOfServiceAdminState } from "../types";

export const searchStates = actionFactory.createAppAction<string>("searchStates");

export function searchStatesReducers(reducerFactory: IReducerFactory<TermsOfServiceAdminState>): IReducerFactory<TermsOfServiceAdminState> {
    return reducerFactory
        .forAction(searchStates)
        .addHandler((state, action) => {
            state.stateSearchResults = validStates.filter(state => state.includes(action.payload));
        })
        .build();
}