import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { StringField } from "../../../../components/formik/stringField";
import { QualityGradeKind } from "../../models/gradeKind";

export const AddQualityGradeKindEditor: React.FC = () => {

    return <FlexColumnContainer gap="20px">

        <StringField<QualityGradeKind>
            fieldName="name"
            defaultLabel="Name"
            containerStyle={{ display: "flex" }}
            labelStyle={{ display: "inline-block", width: "80px" }}
        />

    </FlexColumnContainer>

}