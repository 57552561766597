import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { loadUserSetupInfoReducers } from './featureActions/loadUserSetupData';
import { setCompanyProfileValidReducers } from './featureActions/setCompanyProfileValid';
import { setUserProfileValidReducers } from './featureActions/setUserProfileIsValid';
import { setWorkflowStepReducers } from './featureActions/workflowNavigation';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(setWorkflowStepReducers)
            .add(loadUserSetupInfoReducers)
            .add(setCompanyProfileValidReducers)
            .add(setUserProfileValidReducers);
            // Create your feature actions in ./featureActions and then add them to the reducer factory here
            // with .Add(<your-action-reducer-name>);
    }
});

export default slice.reducer;

export const { resetState } = slice.actions;