import { isValue } from "../../utils/valueHelper";
import { UserAuthState } from "../authentication/store/types";
import { FailedApiResponse, isFailureMessage, isProblemDetail, isValidationFailure } from "../errorHandling/types";

type Method = "POST" | "GET" | "DELETE" | "PUT";

export default class ApiClient {

    apiUri: string;
    authToken: string | null = null;
    getUserAuth: (() => UserAuthState) | null = null;
    onUnauthorized: (() => void) | null = null;

    constructor(apiUri: string) {
        this.apiUri = apiUri
    }

    addGetUserAuth = (getUserAuth: () => UserAuthState) => {
        this.getUserAuth = getUserAuth;
    }

    addOnUnauthorized = (onUnauthorized: () => void) => {
        this.onUnauthorized = onUnauthorized;
    }

    public executeGet = <Return,>(relativePath: string): Promise<Return> => {
        const myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        this.AddAuthHeader(myHeaders);
        return this.executeFetchWithResult<Return>("GET", relativePath, myHeaders, null);
    }

    public executePost = (relativePath: string, input: any = null, anonymous: boolean = false): Promise<Response> => {
        const bodyOfPost = input !== null ? JSON.stringify(input) : null;
        const myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        if (!anonymous) {
            this.AddAuthHeader(myHeaders);
        }

        return this.executeFetch("POST", relativePath, myHeaders, bodyOfPost);
    }

    public executePostWithResult = <Result,>(relativePath: string, input: any = null): Promise<Result> => {
        const bodyOfPost = input !== null ? JSON.stringify(input) : null;
        const myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        this.AddAuthHeader(myHeaders);
        return this.executeFetchWithResult<Result>("POST", relativePath, myHeaders, bodyOfPost);
    }

    public executePutWithResult = <Result,>(relativePath: string, input: any = null): Promise<Result> => {
        const bodyOfPost = input !== null ? JSON.stringify(input) : null;
        const myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        this.AddAuthHeader(myHeaders);
        return this.executeFetchWithResult<Result>("PUT", relativePath, myHeaders, bodyOfPost);
    }

    public executeDelete = (relativePath: string, input: any = null): Promise<Response> => {
        const bodyOfPost = input !== null ? JSON.stringify(input) : null;
        const myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        this.AddAuthHeader(myHeaders);

        return this.executeFetch("DELETE", relativePath, myHeaders, bodyOfPost);
    }

    public executeDeleteWithResult = <Result,>(relativePath: string, input: any = null): Promise<Result> => {
        const bodyOfPost = input !== null ? JSON.stringify(input) : null;
        const myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        this.AddAuthHeader(myHeaders);

        return this.executeFetchWithResult<Result>("DELETE", relativePath, myHeaders, bodyOfPost);
    }

    public executeFormDataPost = (relativePath: string, formData: FormData): Promise<Response> => {
        const myHeaders = new Headers();
        this.AddAuthHeader(myHeaders);
        return this.executeFetch("POST", relativePath, myHeaders, formData);
    }

    public executeFormDataPostWithResult = <Result,>(relativePath: string, formData: FormData): Promise<Result> => {
        const myHeaders = new Headers();
        this.AddAuthHeader(myHeaders);
        return this.executeFetchWithResult<Result>("POST", relativePath, myHeaders, formData);
    }

    private executeFetchWithResult = <Result,>(method: Method, relativePath: string, headers: Headers, body: string | FormData | null = null): Promise<Result> => {
        return this.executeFetch(method, relativePath, headers, body)
            .then(res => {
                if (res.status === 204) {
                    return null;
                }
                return res.json();
            })
            .then((result: Result) => {
                return result;
            });
    };

    private AddAuthHeader = (headers: Headers) => {
        if (this.authToken === null && this.getUserAuth !== null) {
            const userauth: UserAuthState = this.getUserAuth();
            if (userauth.CurrentUser !== null) {
                this.authToken = userauth.CurrentUser.userSettings.access_token;
            }
        }
        if (this.authToken !== null) {
            headers.append('Authorization', 'Bearer ' + this.authToken);
        }

    };

    private executeFetch = (method: Method, relativePath: string, headers: Headers, body: string | FormData | null = null): Promise<Response> => {
        headers.append('pragma', 'no-cache');
        headers.append('cache-control', 'no-cache');
        return fetch(`${this.apiUri}${relativePath}`,
            {
                method: method,
                mode: 'cors', // no-cors, *cors, same-origin
                //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached                
                headers: headers,
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: body
            })
            .then(async res => {
                if (res.ok) {
                    return res
                }
                else {
                    if (res.status === 401) {                        
                        if (this.onUnauthorized !== null) {                        
                            this.onUnauthorized();
                        }
                    }                    

                    if (isValue(res.body)) {
                        try {
                            
                            const responseBody = await res.json();
                            if (isFailureMessage(responseBody)) {
                                const failedResponse: FailedApiResponse = {
                                    message: responseBody.message,
                                    status: res.status,
                                    statusText: res.statusText,
                                    url: res.url
                                };
                                return Promise.reject(failedResponse);
                            }
                            else if (isValidationFailure(responseBody)) {
                                return Promise.reject(responseBody);
                            }
                            else if (isProblemDetail(responseBody)) {
                                return Promise.reject(responseBody);
                            }
                        }
                        catch (_) { }
                    }

                    return Promise.reject(res);
                }
            });
    };
}