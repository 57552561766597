import { menuSectionAdapter } from "../state";
import { actionFactory, MenuSection } from "../types";

export const {

    deleteMenuSection,
    deleteMenuSectionReducer,
    deleteMenuSectionSagaFactory

} = actionFactory
.withMappedName("deleteMenuSection")
.createAsyncAction<MenuSection, Response>({    
    actionStatusSelector: state => state.loadingState,
    asyncCall: (arg, context) => {
        return context.marketplaceApiClient.menuAdmin.deleteMenuSection(arg);
    },
    onFulfilled: (state, action) => {
        menuSectionAdapter.removeOne(state.menuSections, action.payload.arg.id);        
    },
    debounceInMilliseconds: 250
});