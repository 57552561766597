import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ActionPattern, debounce, put, StrictEffect } from 'redux-saga/effects';
import { messageFromError } from '../../../features/errorHandling/errorMessageHelper';
import { isValue } from '../../../utils/valueHelper';
import { SagaAppContext } from '../../rootSaga';

export interface ResponseWithPayloadOptions<T> {
    kind: "WithPayload",
    responseAction: ActionCreatorWithPayload<T>,
    value: T,
    errorAction?: ActionCreatorWithPayload<string>
}

export function createResponseWithPayload<T>(
    responseAction: ActionCreatorWithPayload<T>,
    value: T,
    errorAction?: ActionCreatorWithPayload<string>): ResponseWithPayloadOptions<T> {

    return {
        kind: "WithPayload",
        responseAction: responseAction,
        value: value,
        errorAction: errorAction
    }
}

export interface ResponseOptions {
    kind: "NoPayload",
    responseAction: ActionCreatorWithoutPayload,
    errorAction?: ActionCreatorWithPayload<string>
}

export function createResponse(
    responseAction: ActionCreatorWithoutPayload,
    errorAction?: ActionCreatorWithPayload<string>): ResponseOptions {

    return {
        kind: "NoPayload",
        responseAction: responseAction,
        errorAction: errorAction
    }
}

export interface WatchAndRespondOptions<T = void> {
    response: ResponseWithPayloadOptions<T> | ResponseOptions,
    context: SagaAppContext,
    watchActionPattern: ActionPattern,
    respondPredicate?: (context: SagaAppContext) => boolean,
    debounceDuration?: number
}

export function CreateWatchAndRespondSaga<T>({
    watchActionPattern,
    response,
    debounceDuration = 250,
    respondPredicate,
    context,
}: WatchAndRespondOptions<T>) {

    function* performResponseAction() {
        if (isValue(respondPredicate)) {
            if (!respondPredicate(context)) {
                return;
            }
        }

        try {
            if (response.kind === "WithPayload") {
                yield put(response.responseAction(response.value));
            }
            else {
                yield put(response.responseAction());
            }
        }
        catch (error) {
            if (response.errorAction !== undefined) {
                yield put(response.errorAction(messageFromError(error)));
            }
        }
    };

    return function* watchRequests(): Generator<StrictEffect, void, any> {
        yield debounce(debounceDuration, watchActionPattern, performResponseAction);
    }

};

