import { clone } from "lodash";
import { getEntities } from "../../../../../utils/entityStateHelpers";
import { isValue } from "../../../../../utils/valueHelper";
import { assignChildrenNodes, ConstraintNodeKind, TemplateConstraintNode } from "../../../models/constraintNode";
import { expandedTreeNodeAdapter, templateConstraintNodeAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    addNewConstraint,
    addNewConstraintReducer

} = actionFactory
    .withMappedName("addNewConstraint")
    .createAction<{ parentNodeId: number, rootNodeId: number, gradeValueId: number }>((state, action) => {

        const newConstraint: TemplateConstraintNode = {
            id: state.nextConstraintNodeId,
            parentNodeId: action.payload.parentNodeId,
            rootNodeId: action.payload.rootNodeId,
            children: [],
            kind: ConstraintNodeKind.Constraint,
            comparison: null,
            metricKindId: null,
            metricValueIds: []
        }
        templateConstraintNodeAdapter.upsertOne(state.constraintNodes, newConstraint);

        const allNodes = getEntities(state.constraintNodes);
        assignChildrenNodes(allNodes);

        templateConstraintNodeAdapter.upsertMany(state.constraintNodes, allNodes);

        state.nextConstraintNodeId--;

        // If a node was added to a parent, ensure the parent node is expanded
        const currentExpandedKeys = state.constraintTreeExpandedKeys.entities[action.payload.gradeValueId];
        if (isValue(currentExpandedKeys)) {

            const copy = clone(currentExpandedKeys.expandedKeys);
            copy[action.payload.parentNodeId] = true;

            expandedTreeNodeAdapter.upsertOne(state.constraintTreeExpandedKeys, {
                gradeValueId: action.payload.gradeValueId,
                expandedKeys: copy
            });
        }
    });