import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { isValue } from "../../../../../utils/valueHelper";
import { loadAssesmentStateAdapter, saveAssesmentStateAdapter, saveValueStateAdapter } from "../state";

const assessmentLoadingSelectors = loadAssesmentStateAdapter.getSelectors((state: RootState) => state.qualityAssessmentEditor.loadAssessmentState);
export const getAssessmentLoadStateById = assessmentLoadingSelectors.selectById

export const getIsLoading = createSelector(
    [
        getAssessmentLoadStateById,
        (state: RootState) => state.qualityAssessmentEditor.loadReferenceDataState
    ],
    (loadState, referenceDataLoadState) => {
        return (isValue(loadState) && loadState.isExecuting) || referenceDataLoadState.isExecuting;
    }
);


const assessmentSavingSelectors = saveAssesmentStateAdapter.getSelectors((state: RootState) => state.qualityAssessmentEditor.saveAssessmentState);
export const getAssessmentSaveStateById = assessmentSavingSelectors.selectById

export const getIsSavingAssessment = createSelector(
    [
        getAssessmentSaveStateById
    ],
    (saveState) => {
        return isValue(saveState) && saveState.isExecuting;
    }
);

const valueSavingSelectors = saveValueStateAdapter.getSelectors((state: RootState) => state.qualityAssessmentEditor.saveValueState);
export const getValueSaveStateById = valueSavingSelectors.selectById

export const getIsSavingAssessmentValue = createSelector(
    [
        getValueSaveStateById
    ],
    (saveState) => {
        return isValue(saveState) && saveState.isExecuting;
    }
);