import { isValue } from "../../../../../../utils/valueHelper";
import { inventoryAdapter } from "../../state";
import { actionFactory, Inventory, RemoveQualityAssessment } from "../../types";
import { updateInventoryAssignmentMaps } from "../updateInventoryAssignmentMaps";

type InventoryIdParam = { inventoryId: number };
type RemoveQualityAssessmentArg = void | InventoryIdParam;

const isRemoveQualityAssessmentArg = (value: RemoveQualityAssessmentArg): value is InventoryIdParam => {
    return isValue((value as { inventoryId: number })?.inventoryId)
}

export const {

    removeQualityAssessmentBegin,
    removeQualityAssessmentCancel,
    removeQualityAssessmentComplete,
    removeQualityAssessmentReducers,
    removeQualityAssessmentSagaFactory

} = actionFactory
    .withMappedName("removeQualityAssessment")
    .createModal<RemoveQualityAssessment, RemoveQualityAssessmentArg, Inventory[]>({
        modelFactory: (arg, state) => {
            if (isRemoveQualityAssessmentArg(arg)) {
                return {
                    inventoryIds: [arg.inventoryId]
                };
            }
            else {
                if (state.selectedInventoryIds.length === 0) throw new Error("At least inventory record must be selected.");
                return {
                    inventoryIds: state.selectedInventoryIds
                };
            }
        },
        selector: state => state.removeQualityAssessmentState,
        onCompleteEdit: (value, context) => {
            return context.marketplaceApiClient.inventoryManagement.removeQualityAssessment(value);
        },
        onFulfilled: (state, action) => {
            inventoryAdapter.upsertMany(state.inventory, action.result.map(inv => ({ ...inv, orderedQuantity: 0 })));
        },
        successCallback: (_arg, _result, context) => {
            context.dispatch(updateInventoryAssignmentMaps());
        }
    });