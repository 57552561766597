import { createCustomerUrl } from "../../utils/safeUrlHelper";
import { isValue } from "../../utils/valueHelper";
import { Menu } from "../menu/editor/store/types";

export const openMenuInNewWindow = (menu: Menu) => {
    if (isValue(menu.vendorUrlName) && isValue(menu.urlName)) {
        const menuUrl = createCustomerUrl(menu);
        window.open(menuUrl);    
    }
}

