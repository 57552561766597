import * as yup from 'yup';
import { EditCorporateEntityRequest } from "../store/types";

export const corporateEntityValidationSchema: yup.SchemaOf<EditCorporateEntityRequest> = yup.object().shape({
    id: yup.number().min(0).optional(),
    metrcApiKey: yup.string()       
        .when("id", {
            is: (value: number | undefined) => value === undefined,
            then: yup.string()
                .min(1, "Api Key must be at least 1 character")
                .max(255, "Api Key must be at most 255 characters")
                .required("Api Key is required"),
            otherwise: yup.string()
                .min(1, "Api Key must be at least 1 character")
                .max(255, "Api Key must be at most 255 characters")
                .optional()
        }),

    name: yup.string()
        .min(1, "Name must be at least 1 character")
        .max(255, "Name must be at most 255 characters")
        .required("Name is required"),

    inactive: yup.boolean()
        .required("Inactive is a required value")
});