import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { loadSliceDataSagaFactory } from "../featureActions/loadSliceData";
import { upsertPricingModelSagaFactory } from "../featureActions/upsertPricingModel";
import { upsertPricingModelGradeSagaFactory } from "../featureActions/upsertPricingModelGrade";

// Register this method in the root saga
export function createPricingEditorSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        loadSliceDataSagaFactory(context),
        upsertPricingModelSagaFactory(context),
        upsertPricingModelGradeSagaFactory(context)
    ]
}