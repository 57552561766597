import { inventoryAdapter } from "../state";
import { actionFactory, Inventory } from "../types";
import { updateInventoryAssignmentMaps } from "./updateInventoryAssignmentMaps";

export const {

    loadInventoryByIds,
    loadInventoryByIdsReducer,
    loadInventoryByIdsSagaFactory

} = actionFactory
    .withMappedName("loadInventoryByIds")
    .createAsyncAction<{ inventoryIds: number[] }, Inventory[]>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.inventoryManagement.getInventoryByIds(arg.inventoryIds);
        },
        onFulfilled: (state, action) => {
            inventoryAdapter.upsertMany(state.inventory, action.payload.result.map(inv => ({ ...inv, orderedQuantity: 0 })));
        },
        successCallback: (_arg, _result, context) => { context.dispatch(updateInventoryAssignmentMaps()); }
    }); 