import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import React from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import logo_node from '../../../assets/marketplace-logo.svg';
import { useAppSelector } from "../../../store/configureStore";
import { getIsAuthenticated } from '../../authentication/store/selectors/isAuthenticated';
import { homeRoute } from '../../navigation/routes';
import { smallDeviceQuery } from '../../responsiveconfiguration';
import { toggleSidePanelVisible } from "../store/featureActions/toggleSidePanelVisible";
import { getUseOverlaySideMenu } from "../store/selectors/sideMenu";
import { LargeDeviceTopBarMenu } from './largeDevice/largeDeviceTopBarMenu';
import SmallDeviceTopBarMenu from './smallDevice/smallDeviceTopBarMenu';
import './topBar.css';

const TopBar: React.FC = () => {

    const dispatch = useDispatch();
    const isLoggedIn = useAppSelector(getIsAuthenticated);
    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    const history = useHistory();

    const useOverlaySideMenu = useAppSelector(getUseOverlaySideMenu);

    const onToggleDrawerOpenState = () => {
        dispatch(toggleSidePanelVisible());
    };

    const onTopBarLogoClick = () => {
        history.push(homeRoute.path);
    }

    return <div className="app-top-bar p-shadow-2 p-p-2 p-d-flex p-flex-row">

        <div 
            className="p-d-flex p-flex-row p-jc-start left-top-bar-menu-section"
        >
            {useOverlaySideMenu && isLoggedIn && <Button type="button" icon={PrimeIcons.BARS} onClick={onToggleDrawerOpenState}></Button>}


            <img
                className={useOverlaySideMenu ? "app-top-bar-logo-small" : "app-top-bar-logo"}
                src={logo_node}
                style={{ cursor: "pointer" }}
                alt=""
                height="40px"
                width={useOverlaySideMenu ? "140px" : "260px"}
                onClick={onTopBarLogoClick}
            >
            </img>
        </div>
        <div className="p-d-flex p-flex-row p-jc-end right-top-bar-menu-section">
            {
                isSmallDevice
                    ? <SmallDeviceTopBarMenu />
                    : <LargeDeviceTopBarMenu />
            }
        </div>

    </div>
}

export default TopBar;
