import { DateTime } from "luxon";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";

export const sliceName: string = "app-alerts";

export const actionFactory = new ActionFactory(sliceName);

export enum AlertKind {
    "BuyerVerificationPending",
    "SellerApiKeySetupPending",
    "SellerLicenseSetupPending",
    "SellerInventorySetupPending",
    "NewHolds",
    "HoldCanceled",
    "HoldRejected",
    "HoldAccepted",
    "General"
}

export interface Alert {
    id: number;
    tenantId: string | null;
    isDismissed: boolean;
    isCompleted: boolean;
    message: string | null;
    kind: AlertKind;
    createdDateUtc: DateTime;
}

export interface UserAlert extends Alert {    
    marketplaceUserId: number;    
}

export interface CompanyAlert extends Alert  {    
    companyId: number;    
}

export interface AlertBarState {
    loadingState: ActionStatus;        
    userAlerts: UserAlert[],
    companyAlerts: CompanyAlert[],
}