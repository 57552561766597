import { useSelector } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { isValue } from "../../../../utils/valueHelper";
import { getVendorBanner } from "../store/selectors";


export const Banner: React.FC = () => {

    const banner = useSelector(getVendorBanner);

    return <FlexColumnContainer>
        {
            isValue(banner) &&
            <img
                className="vendor-image-banner"
                src={banner}
                alt="vendor logo"
                style={{
                    display: !isValue(banner) ? "none" : undefined
                }}
            />
        }
    </FlexColumnContainer>

} 