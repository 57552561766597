import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { useDispatch } from "react-redux";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { useEffectWithPartialDependencies, useMountEffect } from "../../../../utils/useEffectHelper";
import { isValue } from "../../../../utils/valueHelper";
import { beginSaveAs } from "../store/featureActions/beginSaveAs";
import { clearRestoreSentinel } from "../store/featureActions/clearRestoreSentinel";
import { deleteUserTableSettings } from "../store/featureActions/deleteUserTableSettings";
import { resetView } from "../store/featureActions/resetView";
import { saveUserTableSettings } from "../store/featureActions/saveUserTableSettings";
import { selectTableSettings } from "../store/featureActions/selectTableSettings";
import { getSelectedUserSettingsIdForView, getSettingsForView, getUserTableSettingsForView } from "../store/selectors";
import { TableState } from "../store/types";
import { ColumnPicker } from "./columnPicker";
import './index.css';
import { SaveAsDialog } from "./saveAsDialog";

export interface ControlPanelProps {
    viewId: string;
    onSelectedSettingsChanges: (tableState: TableState) => void;
    onSelectedSettingsFocused: () => void;
}

export const ControlPanel: React.FC<ControlPanelProps> = ({ viewId, onSelectedSettingsChanges, onSelectedSettingsFocused }) => {

    const dispatch = useDispatch();

    const viewSettings = useAppSelector(state => getSettingsForView(state, viewId));
    const userTableSettings = useAppSelector(state => getUserTableSettingsForView(state, viewId));
    const selectedUserSettingsId = useAppSelector(state => getSelectedUserSettingsIdForView(state, viewId));

    useMountEffect(() => {
        return () => {
            dispatch(resetView({ viewId: viewId }));
        }
    });

    useEffectWithPartialDependencies(() => {

        if (!!!viewSettings?.remountState.remountInProgress &&
            !!viewSettings?.remountState.showTable) {
            const tableState = viewSettings?.currentState;
            if (isValue(tableState)) {
                onSelectedSettingsChanges(tableState);
            }
        } 
    }, [viewSettings?.remountState.remountInProgress, viewSettings?.remountState.showTable]);

    useEffectWithPartialDependencies(() => {
        if (isValue(selectedUserSettingsId)) {
            const tableState = viewSettings?.currentState;
            if (isValue(tableState)) {
                onSelectedSettingsChanges(tableState);
            }
        }
    }, [selectedUserSettingsId]);

    useEffectWithPartialDependencies(() => {

        if (!!(viewSettings?.restoreSentinel)) {

            const tableState = viewSettings?.currentState;
            if (isValue(tableState)) {
                onSelectedSettingsChanges(tableState);
            }

            dispatch(clearRestoreSentinel({ viewId: viewId }));
        }
    }, [viewSettings?.restoreSentinel]);

    const onDelete = () => {
        if (!isValue(selectedUserSettingsId)) return;
        dispatch(deleteUserTableSettings({ viewId: viewId, id: selectedUserSettingsId }))
    }

    const onSaveAs = () => {
        dispatch(beginSaveAs({ viewId: viewId }))
    }

    const onSave = () => {
        dispatch(saveUserTableSettings({ viewId: viewId }))
    }

    const onUserTableSettingsSelectionChanged = (e: DropdownChangeParams) => {
        dispatch(selectTableSettings({ viewId: viewId, id: e.value }))
    }

    const onTableSettingsSelectionFocus = () => {
        onSelectedSettingsFocused();
    }

    return <FlexRowContainer gap="5px" padding="0px 0px 5px 0px">
        <SaveAsDialog />
        <Dropdown
            options={userTableSettings}
            optionLabel="name"
            optionValue="id"
            style={{ width: 350 }}
            onChange={onUserTableSettingsSelectionChanged}
            value={selectedUserSettingsId}
            onFocus={onTableSettingsSelectionFocus}
        />
        <Button label="" disabled={!isValue(selectedUserSettingsId)} onClick={onSave} icon={PrimeIcons.SAVE} tooltip="Save to current settings" tooltipOptions={{ position: "top" }} />
        <ColumnPicker viewId={viewId} />
        <Button label="" disabled={!isValue(selectedUserSettingsId)} onClick={onDelete} icon={PrimeIcons.TRASH} tooltip="Delete current settings" tooltipOptions={{ position: "top" }} />
        <Button label="Save as" onClick={onSaveAs} icon={PrimeIcons.SAVE} tooltip="Save as new settings" tooltipOptions={{ position: "top" }} />
    </FlexRowContainer>
} 