import { IntegerId } from "../../../../../commonModels/integerId";
import { productNotesAdapter } from "../../state";
import { actionFactory } from "../../types";

export const {

    deleteProductNote,
    deleteProductNoteReducer,
    deleteProductNoteSagaFactory

} = actionFactory
    .withMappedName("deleteProductNote")
    .createAsyncAction<IntegerId, Response>({
        actionStatusSelector: state => state.productNoteSaveStatus,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.productManagement.deleteProductNote(arg.id);
        },
        onFulfilled: (state, action) => {
            productNotesAdapter.removeOne(state.editProductNotes, action.payload.arg.id);            
        }
    });