import { ProductMenuAssignment } from "../../../general/models/menuAssignment";
import { actionFactory } from "../types";
import { updateProductAssignmentMaps } from "./updateInventoryAssignmentMaps";

export const {

    loadMenuAssignments,
    loadMenuAssignmentsReducer,
    loadMenuAssignmentsSagaFactory

} = actionFactory
    .withMappedName("loadMenuAssignments")
    .createAsyncAction<void, ProductMenuAssignment[]>({
        actionStatusSelector: state => state.menuAssignmentsLoadState,
        asyncCall: (_, context) => {
            return context.marketplaceApiClient.menuAdmin.getMenuProductAssignments();
        },
        onFulfilled: (state, action) => {
            state.menuAssignments = action.payload.result;
        },
        successCallback: (_arg, _result, context) => { context.dispatch(updateProductAssignmentMaps()); },
        debounceInMilliseconds: 50
    });