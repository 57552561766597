import { productsAdapter } from "../../state";
import { actionFactory, Product } from "../../types";

export const {

    editProductBegin,
    editProductCancel,
    editProductComplete,
    editProductReducers,
    editProductSagaFactory

} = actionFactory
    .withMappedName("editProduct")
    .createModal<Product, Product, Product>({
        modelFactory: (arg, _state) => {
            const editableProduct : Product = {...arg};

            editableProduct.marketingName = editableProduct.marketingName ?? "";
            editableProduct.marketingStrainName = editableProduct.marketingStrainName ?? "";
            editableProduct.marketingDescription = editableProduct.marketingDescription ?? "";   

            return editableProduct;      
        },
        onCompleteEdit: (value, context) => {
            return context.marketplaceApiClient.productManagement.saveProduct(value);
        },
        onFulfilled: (state, action) => {
            productsAdapter.updateOne(state.products, {
                id: action.result.id,
                changes: action.result
            });            
        },
        selector: state => state.editProductState
    });