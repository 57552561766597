import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { isValue } from "../../../../../utils/valueHelper";
import { actionFactory, InventorySearchResult, SearchState } from "../types";

export const fetchNextPage = actionFactory.createAppAction("fetchNextPage");

const sagaActions = actionFactory.createSagaWithProgressActions<void, InventorySearchResult[]>(fetchNextPage.type);

export function fetchNextPageReducers(reducerFactory: IReducerFactory<SearchState>): IReducerFactory<SearchState> {
    return reducerFactory
        .forAction(fetchNextPage)
        .addHandler((state, _) => {
            state.searchRequest.pageNumber++; 
        })
        .build()
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.loadingState)
        .onFulfilled((state, action) => {
            state.inventorySearchResults = state.inventorySearchResults.concat(action.payload.result);   
            state.noMoreResults = action.payload.result.length === 0;
        })
        .build();
}

async function performAction(_: void, context: SagaAppContext): Promise<InventorySearchResult[]> {   
   const searchRequest = context.getState().search.searchRequest;      
    if (!isValue(searchRequest.searchText) || searchRequest.searchText.trim().length < 1) return [];
   return context.marketplaceApiClient.search.searchInventory(searchRequest);
}

export function createFetchNextPageSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: fetchNextPage.type,
        debounceInMilliseconds: 10
    });
};