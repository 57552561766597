import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import clsx from "clsx";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import React from "react";
import { useDispatch } from "react-redux";
import { getUserIsNodeAdmin } from "../../features/authentication/store/selectors/userRoles";
import { SyncRequestDto } from "../../features/commonModels/syncRequest";
import { useMarketplaceHub } from "../../features/signalR/hubConnection/hubContext";
import { HubMethodNames, SyncRequestComplete } from "../../features/signalR/hubConnection/store/types";
import { useAppSelector } from "../../store/configureStore";
import { RootState } from "../../store/rootReducer";
import { useMountEffect } from "../../utils/useEffectHelper";
import { isValue } from "../../utils/valueHelper";
import { FlexColumnContainer } from "../containers/flexColumnContainer";
import { FlexRowContainer } from "../containers/flexRowContainer";
import { getSyncStatusDisplay, isSyncInProgress } from "./logic/syncStatus";
import './syncStatus.css';

export interface SyncStatusProps {
    getLatestSyncRequestSelector: (state: RootState) => SyncRequestDto | null;
    loadLastestSyncRequestAction: ActionCreatorWithPayload<void, string>;
    requestNewSync?: () => void;
}

export const SyncStatus: React.FC<SyncStatusProps> = ({
    getLatestSyncRequestSelector,
    loadLastestSyncRequestAction,
    requestNewSync

}) => {

    const dispatch = useDispatch();
    const marketplaceHub = useMarketplaceHub();

    const latestSyncRequest = useAppSelector(getLatestSyncRequestSelector);
    const isNodeAdmin = useAppSelector(getUserIsNodeAdmin);
    const syncInProgress = isSyncInProgress(latestSyncRequest);
    const hasError = latestSyncRequest !== null && latestSyncRequest.errorDetail !== null;

    const statusText = getSyncStatusDisplay(latestSyncRequest);

    const onSyncComplete = (_message: SyncRequestComplete) => {
        dispatch(loadLastestSyncRequestAction());
    }

    useMountEffect(() => {
        marketplaceHub.registerCallback(HubMethodNames.SyncComplete, onSyncComplete);
        dispatch(loadLastestSyncRequestAction());

        return () => {
            marketplaceHub.unregisterCallback(HubMethodNames.SyncComplete, onSyncComplete);
        }
    });

    const onRequestNewSync = () => {
        if (!isValue(requestNewSync)) return;
        requestNewSync();
    }

    return <FlexColumnContainer gap="20px" className="p-mx-2 p-shadow-2 p-p-3">
        <FlexRowContainer centerContent gap="20px"  >

            <div className="sync-status-label p-mr-2">
                Sync Status:
            </div>

            <i className={clsx("pi pi-exclamation-circle p-ml-2", { "p-d-none": !hasError })} style={{ fontSize: "1.5em", color: "red" }}></i>
            <i className={clsx("pi pi-spin pi-spinner", { "p-d-none": !syncInProgress })} style={{ fontSize: "1.5em", color: "blue" }}></i>
            <div className={clsx("p-ml-2")}>{statusText}</div>
            {
                !!requestNewSync && <Button
                    label="Sync Now"
                    loading={syncInProgress}
                    onClick={onRequestNewSync}
                />
            }

        </FlexRowContainer>

        {
            isNodeAdmin && isValue(latestSyncRequest?.errorDetail) &&
            <FlexRowContainer centerContent >
                <Message severity="error" text={latestSyncRequest?.errorDetail} style={{ width: "100%" }}></Message>
            </FlexRowContainer>
        }


    </FlexColumnContainer>

} 