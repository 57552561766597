import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { SearchState } from './types';

export const createInitialState = (): SearchState => {
    return {        
        loadingState: CreateActionStatusInitialState(),      
        inventorySearchResults: [],
        vendorSearchResults: [],
        searchRequest: {
            pageNumber: 0,
            pageSize: 200,
            searchText: ""
        },
        noMoreResults: false,
        viewPageState: {
            first: 0,
            page: 0,
            pageCount: 0,
            rows: 10,
        },
        showVendorDetails: null,
        vendor: null,
        vendorLoadingState: CreateActionStatusInitialState()
    };
};

export const initialState: SearchState = createInitialState();