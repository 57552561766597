import { DateTime, DateTimeFormatOptions, LocaleOptions } from "luxon";
import { Column, ColumnProps } from "primereact/column";
import React from 'react';
import { DateTimeOrNullKeyOf, getDateTimeOrNullValue, PropertiesOfType } from "../typeHelpers";

// DateTime

export interface CreateDateColumnOptions<T extends {}> extends Omit<ColumnProps, "field" | "body"> {
    field: DateTimeOrNullKeyOf<T> & string;    
    formatOptions?: LocaleOptions & DateTimeFormatOptions;
}

export const ShortDateFormatOptions: LocaleOptions & DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"    
};
function createLuxonDateTemplate<T>(
    getDateFn: (rowData: T) => DateTime | null | undefined,
    formatOptions: LocaleOptions & DateTimeFormatOptions): (rowData: T) => React.ReactNode {
    return (value: T) => {
        const dateValue = getDateFn(value);
        return <div>
            {!!dateValue
                ? dateValue.toLocaleString(formatOptions)
                : ""}
        </div>;
    };
}

export function createDateColumn<T extends PropertiesOfType<T, DateTime | null | undefined>>(
    props: CreateDateColumnOptions<T>
): React.ReactNode {

    const getValueFn = (rowData: T) => getDateTimeOrNullValue(rowData, props.field);
    const formatOptions = props.formatOptions ?? ShortDateFormatOptions;
    return <Column key={props.columnKey ?? props.field} {...props} body={createLuxonDateTemplate(getValueFn, formatOptions)} />;
}


export function createDateColumnProps<T extends PropertiesOfType<T, DateTime | null | undefined>>(
    props: CreateDateColumnOptions<T>
): ColumnProps {

    const getValueFn = (rowData: T) => getDateTimeOrNullValue(rowData, props.field);
    const formatOptions = props.formatOptions ?? ShortDateFormatOptions;

    return {
        sortable: true,
        ...props,
        body: createLuxonDateTemplate(getValueFn, formatOptions)
    }    
}