import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { isValue } from "../../../../utils/valueHelper";
import { CategoryProductAssignmentDialog } from "../../categoryManagement/ui/categoryProductAssignmentDialog";
import { loadBanner } from "../store/featureActions/images/loadBanner";
import { loadLogo } from "../store/featureActions/images/loadLogo";
import { loadThumbnail } from "../store/featureActions/images/loadThumbnail";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { resetState } from "../store/slice";
import { MenuTabView } from './menuTabView';

export interface MenuPageParams {
    id: string | undefined
};

export const MenuEditor: React.FC = () => {
    const params = useParams<MenuPageParams>();
    const menuId: number | undefined = isValue(params.id) ? parseInt(params.id) : undefined;
    
    const dispatch = useDispatch();

    useMountEffect(() => {
        if (isValue(menuId)) {
            dispatch(loadSliceData({ menuId: menuId }));
            dispatch(loadLogo({ menuId: menuId }))
            dispatch(loadBanner({ menuId: menuId }))
            dispatch(loadThumbnail({ menuId: menuId }))
        }
        return () => {
            dispatch(resetState());
        }
    });

    return <div className="p-p-2">

        <CategoryProductAssignmentDialog />

        <MenuTabView />

    </div>
}