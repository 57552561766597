import { Card } from 'primereact/card';
import { ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/configureStore';
import { createBooleanColumnProps } from '../../../../utils/table/booleanColumn';
import { mapColumns } from '../../../../utils/table/mapPropsToColumns';
import { createTextColumnProps } from "../../../../utils/table/stringColumn";
import { useMountEffect } from '../../../../utils/useEffectHelper';
import { useMarketplaceHub } from '../../../signalR/hubConnection/hubContext';
import { GroupNames, HubMethodNames, SyncRequestComplete, SyncRequestKind } from '../../../signalR/hubConnection/store/types';
import { loadSliceData } from '../store/featureActions/loadSliceData';
import { saveLicense } from '../store/featureActions/saveLicense';
import { getLicenses, getSaveStates } from '../store/selectors/licenses';
import { resetState } from '../store/slice';
import { License } from '../store/types';

export const SyncAdminLicense: React.FC = () => {

    const dispatch = useDispatch();
    const marketplaceHub = useMarketplaceHub();
    const licenses = useAppSelector(getLicenses);    
    const licenseSaveStates = useAppSelector(getSaveStates);

    const onSyncComplete = (message: SyncRequestComplete) => {
        if (message.kind === SyncRequestKind.Licenses) {
            dispatch(loadSliceData());
        }
    }

    useMountEffect(() => {
        dispatch(loadSliceData());

        marketplaceHub.joinGroup(GroupNames.SyncUpdates);
        marketplaceHub.registerCallback(HubMethodNames.SyncComplete, onSyncComplete);
        
        return () => {
            marketplaceHub.unregisterCallback(HubMethodNames.SyncComplete, onSyncComplete);
            marketplaceHub.leaveGroup(GroupNames.SyncUpdates);
            dispatch(resetState());
        }
       
    });

    // const onRequestNewSyncLicense = () => {
    //     dispatch(requestLicenseSync());
    // }

    const includeInventoryEditor = (value: License, includeInventory: boolean): void => {        
        dispatch(saveLicense({ ...value, includeInventory: includeInventory }));
    }

    const isDisabled = (value: License) => {
        const saveState = licenseSaveStates[value.id];
        return saveState !== undefined && saveState.isExecuting;
    }

    const columns: ColumnProps[] = [
        createTextColumnProps<License>({ field: "corporateEntityName", header: "Corporate Entity" }),
        createTextColumnProps<License>({ field: "facilityName", header: "Name" }), 
        createTextColumnProps<License>({ field: "number", header: "License Number" }), 
        createBooleanColumnProps<License>({
            field: "includeInventory",
            header: "Include Inventory",
            disabledFn: isDisabled,
            onChange: includeInventoryEditor
        })
    ]

    return <div >
        <Card className="full-height-content-panel" title="License Management" subTitle="Manage your METRC licenses and control which license's inventory is available">
            {/* <SyncStatus 
                getLatestSyncRequestSelector={getLatestSyncRequest}
                loadLastestSyncRequestAction={loadLatestSyncRequest}
                requestNewSync={onRequestNewSyncLicense}
            /> */}
            {/* <Card className="p-m-2" title="Sync Status">
                <div className="p-grid p-align-center">
                    <div className="p-col-4">
                        <Button
                            className="p-ml-2"
                            onClick={onRequestNewSyncLicense}
                            loading={requestLicenseSyncState.isExecuting}
                            disabled={licenseSyncInProgress}
                            label="Sync Now"
                        />
                    </div>
                    <i className={clsx("pi pi-exclamation-circle p-ml-2", { "p-d-none": !hasError })} style={{ fontSize: "1.5em", color: "red" }}></i>
                    <div className={clsx("p-ml-2", { "p-d-none": !hasError, "p-d-inline": hasError })}>{`The last sync request failed with error: ${latestSyncRequest?.errorDetail}`}</div>
                    <i className={clsx("pi pi-spin pi-spinner", { "p-d-none": !licenseSyncInProgress })} style={{ fontSize: "1.5em", color: "blue" }}></i>
                    <div className={clsx("p-ml-2", { "p-d-none": !licenseSyncInProgress, "p-d-inline": licenseSyncInProgress })}>The license sync is in progress...</div>
                </div>
            </Card> */}
            <Card className="p-m-2" title="Licenses">
                <DataTable
                    value={licenses}
                    editMode="cell"
                    scrollable
                    scrollDirection='vertical'
                    className="editable-cells-table"
                >
                    {mapColumns(columns)}
                </DataTable>
            </Card>
        </Card>
    </div>
} 