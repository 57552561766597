import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory } from "../types";
import { beginRefreshInventory, cancelRefreshInventory } from "./refreshInventory";

export const {

    setRefreshInterval,
    setRefreshIntervalReducer,
    setRefreshIntervalSagaFactory

} = actionFactory
    .withMappedName("setRefreshInterval")
    .createAsyncAction<number, void>({
        actionStatusSelector: state => state.vendorMenuLoadingState,
        asyncCall: (_arg, _context) => { return Promise.resolve(); },
        onFulfilled: (state, action) => {
            state.refreshOptions.refreshIntervalMilliseconds = action.payload.arg;
        },
        debounceInMilliseconds: 50,
        successCallback: (arg: number, _result: void, context: SagaAppContext) => {
            if (arg === 0) {
                context.dispatch(cancelRefreshInventory());
            }
            else {
                context.dispatch(beginRefreshInventory());
            }
        }
    });