import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { TabPanel, TabView } from "primereact/tabview";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { isValue } from "../../../../utils/valueHelper";
import { companyListRoute } from "../../../navigation/routes";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { getCustomer, getCustomerLoadingState } from "../store/selectors/customer";
import { ContactDialog } from "./contactDialog";
import { CustomerContacts } from "./customerContacts";
import { CustomerDetails } from "./customerDetails";
import { CustomerNotes } from "./customerNotes";
import { CustomerOrders } from "./customerOrders";
import './index.css';

export interface CustomerEditorParams {
    id: string | undefined
};

export const CustomerEditor: React.FC = () => {

    const dispatch = useDispatch();
    const loadingState = useAppSelector(getCustomerLoadingState);
    const customer = useAppSelector(getCustomer);
    const history = useHistory();

    const params = useParams<CustomerEditorParams>();
    const customerId: number | undefined = isValue(params.id) ? parseInt(params.id) : undefined;

    useMountEffect(() => {
        if (!isValue(customerId)) return;
        dispatch(loadSliceData(customerId));
    });

    const onReturnToCustomerList = () => {        
        history.push(companyListRoute.path);
    }

    return <OverlayWaitDisplay
        isWaiting={loadingState.isExecuting}
    >
        <ContactDialog />
        <Card 
            className="full-height-content-panel" 
            title={`Customer: ${customer?.name ?? ""}`}
            subTitle={<Button className="p-button-link" label="Return to list" onClick={onReturnToCustomerList}/>}
        >

            <TabView>
                <TabPanel key="general"  header="General" >
                    <CustomerDetails />
                </TabPanel>

                <TabPanel key="notes" header="Notes">
                    <CustomerNotes />
                </TabPanel>

                <TabPanel key="contacts" header="Contacts">
                    <CustomerContacts />
                </TabPanel>
                
                <TabPanel key="orders" header="Orders">
                    <CustomerOrders />
                </TabPanel>
            </TabView>
            

        </Card>

    </OverlayWaitDisplay>
}   