import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../../store/rootSaga";
import { EntityImage } from "../../../../../commonModels/entityImage";
import { actionFactory, InventoryManagementState,  } from "../../types";

export const deleteInventoryImage = actionFactory.createAppAction<EntityImage>("deleteInventoryImage");

const sagaActions = actionFactory.createSagaWithProgressActions<EntityImage, Response>(deleteInventoryImage.type);

export function deleteInventoryImageReducers(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.editImageSaveState)
        .onFulfilled((state, action) => {
            state.editImages = state.editImages.filter(image => image.id !== action.payload.arg.id);
        })
        .build();
}

async function performAction(arg: EntityImage, context: SagaAppContext) {          
    return context.marketplaceApiClient.inventoryManagement.deleteInventoryImage(arg.id);
}

export function createDeleteInventoryImageSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: deleteInventoryImage.type,
        debounceInMilliseconds: 400
    });
};