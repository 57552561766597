import { ColumnEditorOptions, ColumnProps } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { getStringOrNullValue, PropertiesOfType, StringOrNullKeyOf } from "../typeHelpers";
import { isValue } from "../valueHelper";
import { EditCompleteParams } from "./editCompleteParams";


export interface EditableTextColumnOptions<T extends PropertiesOfType<T, string | null | undefined>> extends Omit<ColumnProps, "field" | "header"> {
    field: StringOrNullKeyOf<T> & string;
    header: string;
    onSubmit: (newValues: T) => void;
    disabled?: boolean | ((value: T) => boolean);
}
interface EditorColumnProps<T> extends Omit<ColumnEditorOptions, "rowData"> {
    rowData: T;
}

export function createEditableTextColumnProps<T extends PropertiesOfType<T, string | null | undefined>>(props: EditableTextColumnOptions<T>): ColumnProps {

    const onTextEditorSubmit = (e: EditCompleteParams<T, string>) => {
        props.onSubmit(e.newRowData);
    };

    const textEditor = (editorProps: EditorColumnProps<T>) => {
        const editTarget: T = editorProps.rowData;

        const isDisabled = !isValue(props.disabled)
            ? undefined
            : typeof (props.disabled) === "boolean"
                ? props.disabled
                : props.disabled(editTarget);

        return <InputText
            disabled={isDisabled}
            defaultValue={getStringOrNullValue(editTarget, props.field) ?? undefined}
            onChange={(e) => {
                if (isValue(editorProps.editorCallback)) {
                    editorProps.editorCallback(e.target.value);
                }
            }} />;
    };

    return {
        ...props,
        editor: textEditor,
        onCellEditComplete: onTextEditorSubmit
    };
}
