import { SyncRequestDto } from "../../../../commonModels/syncRequest";
import { actionFactory } from "../types";

export const {

    loadLatestSyncRequest,
    loadLatestSyncRequestReducer,
    loadLatestSyncRequestSagaFactory

} = actionFactory
    .withMappedName("loadLatestSyncRequest")
    .createAsyncAction<void, SyncRequestDto | null>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (_arg, context) => {
            return context.marketplaceApiClient.syncAdmin.getLatestLicenseSyncRequest();
        },
        onFulfilled: (state, action) => {
            state.latestSyncLicenseRequest = action.payload.result;
        }
    });