import { Galleria } from 'primereact/galleria';
import { ReactNode } from 'react';
import { FlexColumnContainer } from '../../../../components/containers/flexColumnContainer';
import { FlexRowContainer } from '../../../../components/containers/flexRowContainer';

export const GalleryTestingDashboard: React.FC = () => {

    const videoUrls: string[] = [
        "https://www.youtube.com/embed/ezHQNcfIUzQ",
        "https://player.vimeo.com/video/753494554?h=140504b2f5"
    ];

    const itemTemplate = (urlvalue: string) : ReactNode => {
        return <FlexRowContainer>
            <iframe 
                title={urlvalue}
                src={urlvalue} 
                width="640" 
                height="360" 
                frameBorder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowFullScreen
            />                     
        </FlexRowContainer>
    }

    return <FlexColumnContainer>
        

        <Galleria 
            value={videoUrls}
            item={itemTemplate}
            showItemNavigators={true}
            showThumbnails={false}            
        />

    </FlexColumnContainer> 
} 