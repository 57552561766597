import { inventoryAdapter } from "../state";
import { actionFactory, Inventory } from "../types";
import { updateInventoryAssignmentMaps } from "./updateInventoryAssignmentMaps";

export const {

    loadInventory,
    loadInventoryReducer,
    loadInventorySagaFactory

} = actionFactory
    .withMappedName("loadInventory")
    .createAsyncAction<void, Inventory[]>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (_, context) => {
            return context.marketplaceApiClient.inventoryManagement.getInventory();
        },
        onFulfilled: (state, action) => {
            inventoryAdapter.upsertMany(state.inventory, action.payload.result.map(inv => ({ ...inv, orderedQuantity: 0 })));
        },
        successCallback: (_arg, _result, context) => { context.dispatch(updateInventoryAssignmentMaps()); }
    }); 