import { isValue } from "../../../../../utils/valueHelper";
import { QualityGradeValue } from "../../../models/gradeValue";
import { actionFactory } from "../types";

export const {

    evaluateTestAssessment,
    evaluateTestAssessmentReducer,
    evaluateTestAssessmentSagaFactory

} = actionFactory
    .withMappedName("evaluateTestAssessment")
    .createAsyncAction<void, QualityGradeValue>({
        actionStatusSelector: state => state.templateSaveState,
        asyncCall: (_arg, context) => {
            const sliceState = context.getState().qualityTemplateEditor;
            const template = sliceState.template;
            if (!isValue(template)) throw new Error("Cannot evaluate a test assessment without a valid template instance");

            return context.marketplaceApiClient.quality.evaluateAssessment({
                assessmentTemplateId: template.id,
                assessmentEntries: sliceState.testAssessmentEntries
            });
        },
        onFulfilled: (state, action) => {
            state.testResult = action.payload.result;
        }
    });