import React from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { smallDeviceQuery } from '../responsiveconfiguration';
import { useAppSelector } from "../../store/configureStore";
import { useEffect } from "react";
import { setUseOverlaySideMenu } from "./store/featureActions/setOverlaySidemenuState";
import { getNotAuthorizedState } from "../authorization/notAuthorized/store/selectors";
import { clearNotAuthorized } from "../authorization/notAuthorized/store/featureActions/clearNotAuthorized";

export const RouteWatcher: React.FC = () => {

  const dispatch = useDispatch(); 
  const isSmallDevice = useMediaQuery(smallDeviceQuery);
  const location = useLocation();
  const isLiveMenuRoute = location.pathname.startsWith('/vendor/');
  const notAuthorizedState = useAppSelector(getNotAuthorizedState);

  useEffect(() => {

    // If an unauthorized action was made (typically an api call), the content view is set to NotAuthorized instead of the intended view.
    // The not authorized event should be cleared when the user changes routes.
    if (notAuthorizedState.isNotAuthorized && notAuthorizedState.route !== location.pathname) {
      dispatch(clearNotAuthorized())
    }

    // Small devices should always use the overlay menu. In addition, some routes are requied to use the overlay menu for aesthetic purposes.
    if (isSmallDevice || isLiveMenuRoute) {
      dispatch(setUseOverlaySideMenu(true));
    }
    else {
      dispatch(setUseOverlaySideMenu(false));
    }
  }, [location, isSmallDevice, isLiveMenuRoute, dispatch, notAuthorizedState.isNotAuthorized, notAuthorizedState.route]);

  return <div />
}
