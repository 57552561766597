import { RootState } from "../../../../../store/rootReducer";
import { createLocalImageUrl } from "../../../../../utils/imageHelper";
import { isValue } from "../../../../../utils/valueHelper";

export const getVendorLogo = (state: RootState) => {
    if (!isValue(state.vendorTopPanel.vendorLogo)) return null;
    return createLocalImageUrl(state.vendorTopPanel.vendorLogo);
}

export const getVendorLogoLoadingState = (state: RootState) => state.vendorTopPanel.vendorLogoLoadingState;

export const getVendorBanner = (state: RootState) => {
    if (!isValue(state.vendorTopPanel.vendorBanner)) return null;
    return createLocalImageUrl(state.vendorTopPanel.vendorBanner);
}

export const getVendorBannerLoadingState = (state: RootState) => state.vendorTopPanel.vendorBannerLoadingState;