import { Category, NewCategory } from "../../../categoryManagement/store/types";
import { categoryAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    upsertCategory,
    upsertCategoryReducer,
    upsertCategorySagaFactory

} = actionFactory
    .withMappedName("upsertCategory")
    .createAsyncAction<Category | void, Category>({
        actionStatusSelector: state => state.addCategorySaveState,
        asyncCall: (arg, context) => {

            const state = context.getState();
            const menu = state.menuAdmin.menu;
            if (menu === null) throw new Error("Attempted to save category to menu without a valid menu reference");

            const upsertTarget: Category | NewCategory = arg ?? {
                name: "New Category"
            }

            return context.marketplaceApiClient.menuAdmin.upsertCategory(menu.id, upsertTarget);
        },
        onFulfilled: (state, action) => {
            categoryAdapter.upsertOne(state.categories, action.payload.result);
        },
        debounceInMilliseconds: 100
    });