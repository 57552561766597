import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { MenuCategoryView } from "../../general/models/menuCategoryView";

export const sliceName: string = "category-assignment";

export const actionFactory = new ActionFactory<CategoryAssignmentState>(sliceName);

export interface CategoryAssignmentState {
    loadingState: ActionStatus;       
    available: MenuCategoryView[];    
    selected: number[];
}