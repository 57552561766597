import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { firstOrException } from "../../../../../utils/arrayHelpers";

export const getLoadingState = (state: RootState) => state.alerts.loadingState;

export const getTotalAlertCount = createSelector(
    [
        (state: RootState) => state.alerts.userAlerts,
        (state: RootState) => state.alerts.companyAlerts
    ],
    (userAlerts, companyAlerts) => {
        return userAlerts.length + companyAlerts.length;
    }
);

export const getActiveAlertCount = createSelector(
    [
        (state: RootState) => state.alerts.userAlerts,
        (state: RootState) => state.alerts.companyAlerts
    ],
    (userAlerts, companyAlerts) => {
        return userAlerts.filter(alert => !alert.isDismissed && !alert.isCompleted).length +
            companyAlerts.filter(alert => !alert.isDismissed && !alert.isCompleted).length;
    }
);

export const getUserAlertById = (state: RootState, id: number) => firstOrException(state.alerts.userAlerts, alert => alert.id === id);

export const getCustomerAlertById = (state: RootState, id: number) => firstOrException(state.alerts.companyAlerts, alert => alert.id === id);

export const getActiveUserAlertIds = createSelector(
    [
        (state: RootState) => state.alerts.userAlerts
    ],
    (userAlerts) => {
        return userAlerts
            .filter(alert => !alert.isDismissed && !alert.isCompleted)
            .map(alert => alert.id);
    }
);

export const getActiveCustomerAlertIds = createSelector(
    [
        (state: RootState) => state.alerts.companyAlerts
    ],
    (companyAlerts) => {
        return companyAlerts
            .filter(alert => !alert.isDismissed && !alert.isCompleted)
            .map(alert => alert.id);
    }
);