import { isValue } from "../../../../../../utils/valueHelper";
import { ImageKind } from "../../../../../commonModels/imageTypes";
import { actionFactory } from "../../types";
import { loadThumbnail } from "./loadThumbnail";

export const {

    saveThumbnail,
    saveThumbnailReducer,
    saveThumbnailSagaFactory

} = actionFactory
    .withMappedName("saveThumbnail")
    .createAsyncAction<void, Response>({
        actionStatusSelector: state => state.thumbnailState.saveState,
        asyncCall: async (_arg, context) => {
            const state = context.getState();

            const menuId = state.menuAdmin.menu?.id;
            if (!isValue(menuId)) throw new Error("Cannot load image without valid menu instance");

            if (state.menuAdmin.thumbnailState.newImagePreview !== undefined) {
                const blob = await fetch(state.menuAdmin.thumbnailState.newImagePreview).then(localFile => localFile.blob());
                return context.marketplaceApiClient.menuAdmin.uploadImage(menuId, blob, ImageKind.ImageUnavailable);
            }

            throw new Error("Attempt to save image without new image specified")
        },
        onFulfilled: (state, _action) => {
            state.thumbnailState.newImagePreview = undefined;
        },
        successCallback: (_arg, _result, context) => {
            const menuId = context.getState().menuAdmin.menu?.id;
            if (!isValue(menuId)) throw new Error("Menu instance was not valid");
            context.dispatch(loadThumbnail({ menuId: menuId }));
        }
    });