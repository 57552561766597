import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory, TermsOfService, TermsOfServiceAdminState } from "../types";

export const loadSliceData = actionFactory.createAppAction("loadSliceData");

const sagaActions = actionFactory.createSagaWithProgressActions<void, TermsOfService[]>(loadSliceData.type);

export function loadSliceDataReducers(reducerFactory: IReducerFactory<TermsOfServiceAdminState>): IReducerFactory<TermsOfServiceAdminState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.loadingState)
        .onFulfilled((state, action) => {           
            state.termsOfService = action.payload.result;            
        })
        .build();
}

async function performLoad(_arg: void, context: SagaAppContext): Promise<TermsOfService[]> {   
   return context.marketplaceApiClient.termsOfServiceAdmin.getTermsOfService();
}

export function createLoadSliceDataSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: loadSliceData.type,
        debounceInMilliseconds: 250
    });
};