import { RootState } from "../../../../../store/rootReducer";
import { InventoryView } from "../types";
import { SortDirection, SortField } from "../../../common/types";

export const getSortSetting = (state: RootState) => state.liveMenu.sortSetting;

export const getSortFunction = (state: RootState) => {
    const sortSetting = state.liveMenu.sortSetting;
    const sortDirection = sortSetting.direction === SortDirection.Ascending ? 1 : -1;

    if (sortSetting.sortField === SortField.Name) {
        return (left: InventoryView, right: InventoryView) => (left.marketingName ?? '').localeCompare(right.marketingName ?? '') * sortDirection;
    }
    else if (sortSetting.sortField === SortField.Price) {        
        return (left: InventoryView, right: InventoryView) => {            
            return ( (left.unitPrice ?? 0) - (right.unitPrice ?? 0)) * sortDirection;
        }
    }
    else if (sortSetting.sortField === SortField.Quantity) {
        return (left: InventoryView, right: InventoryView) => ((left.quantity ?? 0) - (right.quantity ?? 0)) * sortDirection;
    }
    else if (sortSetting.sortField === SortField.THC) {
        return (left: InventoryView, right: InventoryView) => ((left.thcPercentage ?? 0) - (right.thcPercentage ?? 0)) * sortDirection;
    }
    else {
        return (left: InventoryView, right: InventoryView) => ((left.marketingStrainName ?? '').localeCompare(right.marketingStrainName ?? '')) * sortDirection;
    }
} 