import { Button } from "primereact/button";
import { CSSProperties } from "react";
import { useAppSelector } from "../../../../store/configureStore";
import { getPrimaryColor, getSecondaryColor } from "../store/selectors/menuColors";

export const DemoButtonWithColors: React.FC = () => {

    const primaryColor = useAppSelector(getPrimaryColor);
    const secondaryColor = useAppSelector(getSecondaryColor);

    const buttonStyle: CSSProperties = {
        background: primaryColor ?? undefined,
        color: secondaryColor ?? undefined
    }

    return <div className="p-p-2">

        <Button label="Example Button" style={buttonStyle} />

    </div>
}