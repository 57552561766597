import { QualityAssessmentValue } from "../../../models/qualityAssessmentValue";
import { qualityAssessmentValueAdapter, saveValueStateAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    saveAssessmentValue,
    saveAssessmentValueReducer,
    saveAssessmentValueSagaFactory

} = actionFactory
    .withMappedName("saveAssessmentValue")
    .createAsyncAction<QualityAssessmentValue, QualityAssessmentValue>({
        actionStatusSelector: {
            adapter: saveValueStateAdapter,
            adapterStateSelector: state => state.saveValueState,
            getId: (_state, arg) => arg.id
        },
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.quality.saveAssessmentValue(arg);
        },
        onFulfilled: (state, action) => {            
            qualityAssessmentValueAdapter.upsertOne(state.assessmentValues, action.payload.result);
        }
    });