import { Card } from "primereact/card";
import React from "react";
import { DropDownField } from "../../../../components/formik/dropDownField";
import { NumberField } from "../../../../components/formik/numberField";
import { SingleSelectField } from "../../../../components/formik/singleSelectField";
import { StringField } from "../../../../components/formik/stringField";
import { SwitchField } from "../../../../components/formik/switchField";
import { TextAreaField } from "../../../../components/formik/textAreaField";
import { createIntegerIdMap } from "../../../../utils/identity";
import { isValue } from "../../../../utils/valueHelper";
import { PricingModel } from "../../../pricing/sharedTypes";
import { QualityGradeKind } from "../../../qualityGradeTemplates/models/gradeKind";
import { QualityGradeValue } from "../../../qualityGradeTemplates/models/gradeValue";
import { MarketingDetails } from "../models/marketingDetails";
import { InventoryLicense, UnitOfMeasure } from "../../inventoryManagement/store/types";
import './editMarketingDetails.css';

export interface AddMarketingDetailsProps {
    subTitle: string;
    showPrice: boolean;
    licenses: InventoryLicense[];
    unitOfMeasures: UnitOfMeasure[];
    pricingModels?: PricingModel[];
    qualityGrades?: QualityGradeValue[];
    qualityGradeKinds?: QualityGradeKind[];
    validationSchema?: any;
}

export const AddMarketingDetails: React.FC<AddMarketingDetailsProps> = ({
    subTitle,
    showPrice,
    licenses,
    unitOfMeasures,
    pricingModels,
    qualityGrades,
    qualityGradeKinds
}) => {
    const pricingModelMap = isValue(pricingModels) ? createIntegerIdMap(pricingModels) : {};
    const qualityGradeKindMap = isValue(qualityGradeKinds) ? createIntegerIdMap(qualityGradeKinds) : {};

    return <div className="edit-marketing-details-container">
        <Card className="p-mt-2" title="Marketplace Details" subTitle={subTitle}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-6">
                    <StringField<MarketingDetails>
                        defaultLabel="Marketing Name"
                        fieldName="marketingName"
                    />
                </div>
                <div className="p-field p-col-6">
                    <StringField<MarketingDetails>
                        defaultLabel="Marketing Strain Name"
                        fieldName="marketingStrainName"
                    />
                </div>
                <div className="p-field p-col-12">
                    <TextAreaField<MarketingDetails>
                        defaultLabel="Description"
                        fieldName="marketingDescription"
                        autoResize={false}
                        inputClassName="marketing-details-description-textarea"
                    />
                </div>
                <div className="p-field p-col-6">
                    <NumberField<MarketingDetails>
                        defaultLabel="THC Percentage"
                        fieldName="thcPercentage"
                        mode="decimal"
                        suffix="%"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        min={0}
                        max={100}
                        isPercent={true}
                    />
                </div>
                <div className="p-field p-col-6">
                    <NumberField<MarketingDetails>
                        defaultLabel="CBD Percentage"
                        fieldName="cbdPercentage"
                        mode="decimal"
                        suffix="%"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        min={0}
                        max={100}
                        isPercent={true}
                    />
                </div>
                <div className="p-field p-col-6">
                    <DropDownField<MarketingDetails, number>
                        availableValues={licenses.map(license => ({ label: license.number, value: license.id }))}
                        fieldName="licenseId"
                        defaultLabel="License"
                        showErrors={true}
                        inputStyle={{ width: "100%" }}
                        panelStyle={{ width: "auto" }}
                        allowClear={false} />
                </div>
                {
                    showPrice &&
                    <div className="p-field p-col-6">
                        <NumberField<MarketingDetails>
                            defaultLabel="Unit Price"
                            fieldName="unitPrice"
                            isCurrency={true}
                            maxFractionDigits={2}
                            min={0}
                        />
                    </div>
                }
                {isValue(pricingModels) &&
                    <div className="p-field p-col-6">
                        <SingleSelectField<MarketingDetails, PricingModel>
                            defaultLabel="Pricing Model"
                            availableValues={pricingModels}
                            getValue={m => isValue(m.pricingModelId) ? pricingModelMap[m.pricingModelId] : null}
                            optionLabel="name"
                            optionValue="id"
                            fieldName="pricingModelId"
                            allowClear={true}
                            inputStyle={{ width: "100%" }}
                        />
                    </div>
                }
                 <div className="p-field p-col-6">
                    <DropDownField<MarketingDetails, string>
                        availableValues={unitOfMeasures.map(unit => ({ label: unit.name, value: unit.name }))}
                        fieldName="unitOfMeasureName"
                        defaultLabel="Unit of measure"
                        inputStyle={{ width: "100%" }}
                        panelStyle={{ width: "auto" }}
                        showErrors={true}
                        allowClear={false} />
                </div>
                 <div className="p-field p-col-6">
                  
                        <NumberField<MarketingDetails>
                                defaultLabel="Quantity"
                                fieldName="quantity"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                min={0}
                        />
                  

                </div>
                 <div className="p-field p-col-6">
                        <NumberField<MarketingDetails>
                            defaultLabel="Reserved Quantity"
                            fieldName="reservedQuantity"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            min={0}
                        />
                </div>
                {isValue(qualityGrades) &&
                    <div className="p-field p-col-6">
                        <DropDownField<MarketingDetails, number>
                            defaultLabel="Quality Grade"
                            availableValues={qualityGrades
                                .map(gradeValue => ({
                                    label: `${qualityGradeKindMap[gradeValue.gradeKindId].name} - ${gradeValue.name}`,
                                    value: gradeValue.id
                                }))}
                            fieldName="qualityGradeId"
                            allowClear={true}
                            panelStyle={{ width: "auto" }}
                            inputStyle={{ width: "100%" }}
                        />
                    </div>
                }
                <div className="p-field p-col-6 p-d-flex p-ai-center p-ac-center p-mt-4">
                    <SwitchField<MarketingDetails>
                        defaultLabel="Exclude From Menus"
                        fieldName="excludeFromMarket"
                         containerStyle={{ display: "flex", alignItems: "center", gap: "20px" }}
                    />
               </div>
            </div>
        </Card>
    </div >
}
