import { DataViewLayoutType } from "primereact/dataview";
import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, LiveMenuState } from "../types";


export const setSectionLayout = actionFactory.createAppAction<DataViewLayoutType>("setSectionLayout");

export function setSectionLayoutReducers(reducerFactory: IReducerFactory<LiveMenuState>): IReducerFactory<LiveMenuState> {
    return reducerFactory
        .forAction(setSectionLayout)
        .addHandler((state, action) => {
            state.sectionLayout = action.payload;
        })
        .build();
}