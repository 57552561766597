import { QualityMetricKind } from "../../../models/metricKind";
import { metricKindAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    publishQualityMetricKind,
    publishQualityMetricKindReducer,
    publishQualityMetricKindSagaFactory

} = actionFactory
    .withMappedName("publishQualityMetricKind")
    .createAsyncAction<QualityMetricKind, QualityMetricKind>({        
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {            
            return context.marketplaceApiClient.quality.publishQualityMetricKind(arg);            
        },
        onFulfilled: (state, action) => {
            metricKindAdapter.upsertOne(state.metricKinds, action.payload.result);
        }
    });