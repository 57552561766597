import { PrimeIcons } from "primereact/api";
import { IsBuyer, IsDistributor, IsNonDistributor, IsSeller, IsSellerAdmin, IsWholesaleAdmin, IsWholesaleUser, SecurityRole } from "../../authorization/roles";
import * as Routes from "../routes";
import { RouteModel, SecurityRolePredicate } from "../routes/types";
import { CompanyType } from "../../company/profile/store/types";

export type AppMenuModel = {
    key: string,
    icon: string,
    title: string,
    role: SecurityRole | SecurityRolePredicate,
    routeModel: RouteModel | null,
    routeParameter?: string | number,
    children: AppMenuModel[]
}

export const menuItems: AppMenuModel[] = [   
    {
        key: "menu-admin",
        icon: "",
        title: 'Menu',
        role: IsSeller,
        routeModel: null,
        children: [
            {
                key: "company-profile",
                icon: "",
                title: 'Dashboard',
                role: Routes.menuDashboardRoute.role,
                routeModel: Routes.menuDashboardRoute,
                children: []
            }
        ]
    },
    {
        key: "crm",
        icon: "",
        title: 'CRM',
        role: IsWholesaleUser,
        routeModel: null,
        children: [
            {
                key: "customer-list-supplier",
                icon: "",
                title: 'Suppliers',
                role: IsDistributor ,
                routeModel: Routes.companyListRoute,
                routeParameter: CompanyType.Supplier,
                children: []
            },
            {
                key: "customer-list-distributor",
                icon: "",
                title: 'Distributors',
                role: IsNonDistributor,
                routeModel: Routes.companyListRoute,
                routeParameter: CompanyType.Distributor,
                children: []
            },
            {
                key: "customer-list-retail",
                icon: "",
                title: 'Retailers',
                role: IsDistributor,
                routeModel: Routes.companyListRoute,
                routeParameter: CompanyType.Retail,
                children: []
            }
        ]
    },
    {
        key: "admin",
        icon: "",
        title: 'Admin',
        role: IsWholesaleAdmin,
        routeModel: null,
        children: [
            {
                key: "company-profile",
                icon: "",
                title: 'Profile',
                role: Routes.companyProfileRoute.role,
                routeModel: Routes.companyProfileRoute,
                children: []
            },
            {
                key: "corporate-entities",
                icon: "",
                title: 'Api Keys',
                role: Routes.corporateEntityManagementRoute.role,
                routeModel: Routes.corporateEntityManagementRoute,
                children: []
            },
            {
                key: "license-management",
                icon: "",
                title: 'License Sync',
                role: Routes.syncAdminLicenseRoute.role,
                routeModel: Routes.syncAdminLicenseRoute,
                children: []
            }
            // ,
            // {
            //     key: "license-verification",
            //     icon: "",
            //     title: 'Buyer Verification',
            //     role: Routes.apiKeyVerificationRoute.role,
            //     routeModel: Routes.apiKeyVerificationRoute,
            //     children: []
            // }
        ]
    },
    {
        key: "inventory-management",
        icon: "",
        title: 'Inventory',
        role: IsSeller,
        routeModel: null,
        children: [          
            {
                key: "product-management",
                icon: "",
                title: 'Items',
                role: Routes.productManagementDashboardRoute.role,
                routeModel: Routes.productManagementDashboardRoute,
                children: []
            },
            {
                key: "inventory-management",
                icon: "",
                title: 'Packages',
                role: Routes.inventoryManagementDashboardRoute.role,
                routeModel: Routes.inventoryManagementDashboardRoute,
                children: []
            },
        ]
    },
    {
        key: "buyer-profile",
        icon: "",
        title: 'User Profile',
        role: IsBuyer,
        routeModel: null,
        children: [
            {
                key: "buyer-favorite-menus",
                icon: "",
                title: 'Favorite Menus',
                role: Routes.favoriteMenusRoute.role,
                routeModel: Routes.favoriteMenusRoute,
                children: []
            },
            {
                key: "buyer-user-alerts",
                icon: "",
                title: 'Alerts',
                role: Routes.manageAlertsRoute.role,
                routeModel: Routes.manageAlertsRoute,
                children: []
            },
        ]
    },    
    {
        key: "pricing",
        icon: "",
        title: 'Pricing',
        role: IsSeller,
        routeModel: null,
        children: [
            {
                key: "price-model-dashboard",
                icon: "",
                title: 'Price Models',
                role: Routes.priceModelsDashboardRoute.role,
                routeModel: Routes.priceModelsDashboardRoute,
                children: []
            }            
        ]
    },
    {
        key: "QualityMenu",
        icon: PrimeIcons.STAR,
        title: 'Quality',
        role: IsSellerAdmin,
        routeModel: null,
        children: [
            {
                key: "quality-metrics",
                icon: PrimeIcons.CHART_LINE,
                title: 'Metrics',
                role: Routes.QualityMetricKindsRoute.role,
                routeModel: Routes.QualityMetricKindsRoute,
                children: []
            },
            {
                key: "quality-grades",
                icon: PrimeIcons.CHECK_SQUARE,
                title: 'Grades',
                role: Routes.QualityGradeKindsRoute.role,
                routeModel: Routes.QualityGradeKindsRoute,
                children: []
            },
            {
                key: "quality-templates",
                icon: PrimeIcons.BOOK,
                title: 'Templates',
                role: Routes.QualityTemplateDashboardRoute.role,
                routeModel: Routes.QualityTemplateDashboardRoute,
                children: []
            },
        ]
    },
    {
        key: "node-administration",
        icon: "",
        title: 'Node Admin',
        role: SecurityRole.NodeAdmin,
        routeModel: null,
        children: [
            {
                key: "server-health",
                icon: "",
                title: 'Server Health',
                role: Routes.diagnosticsRoute.role,
                routeModel: Routes.diagnosticsRoute,
                children: []
            },
            {
                key: "company-provisioning-dashboard",
                icon: "",
                title: 'Provisioning',
                role: Routes.CompanyProvisioningDashboardRoute.role,
                routeModel: Routes.CompanyProvisioningDashboardRoute,
                children: []
            },
            {
                key: "terms-of-service-dashboard",
                icon: "",
                title: 'Terms of Service',
                role: Routes.termsOfServiceAdminDashboardRoute.role,
                routeModel: Routes.termsOfServiceAdminDashboardRoute,
                children: []
            },
            {
                key: "data-view-scroll-testing",
                icon: "",
                title: 'Dataview Scroll Tests',
                role: Routes.dataViewScrollTestsRoute.role,
                routeModel: Routes.dataViewScrollTestsRoute,
                children: []
            },
            {
                key: "signalR-testing",
                icon: "",
                title: 'SignalR Testing',
                role: Routes.signalRTestRoute.role,
                routeModel: Routes.signalRTestRoute,
                children: []
            },
            {
                key: "custom-table",
                icon: "",
                title: 'Custom Table',
                role: Routes.customTableTestRoute.role,
                routeModel: Routes.customTableTestRoute,
                children: []
            },
            {
                key: "gallery-testing",
                icon: "",
                title: 'Gallery Testing',
                role: Routes.galleryTestingDashboardRoute.role,
                routeModel: Routes.galleryTestingDashboardRoute,
                children: []
            }
            
        ]
    }
];