import { Accordion, AccordionEventParams, AccordionTab } from 'primereact/accordion';
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { getGradeValuesForTemplate } from "../store/selectors/data";
import { GradeConstraintList } from "./gradeConstraintList";
import { ConstraintListHeader } from "./constraintListHeader";
import { useEffect, useState } from 'react';
import { isArray } from 'lodash';

export const TemplateConstraintsEditor: React.FC = () => {

    const gradeValues = useAppSelector(getGradeValuesForTemplate);

    const [activeIndexes, setActiveIndexes] = useState<number[]>([]);

    useEffect(() => {
        setActiveIndexes(gradeValues.map((_value, index) => index));
    }, [gradeValues]);

    
    const onAccordionTabChange = (e: AccordionEventParams) => {

        console.log("onAccordionTabChange", e);

        const indexes: number | number[] = e.index;

        if (isArray(indexes)) {
            setActiveIndexes(indexes);
        }
        
    }

    return <FlexColumnContainer className="template-constraints-editor">

        <Accordion
            className="template-constraints-collapse"
            multiple
            activeIndex={activeIndexes}
            onTabChange={onAccordionTabChange}
        >
            {
                gradeValues.map(gradeValue => <AccordionTab
                    className="template-constraints-collapse-panel"
                    key={gradeValue.id}                    
                    headerTemplate={<ConstraintListHeader key={gradeValue.id} gradeValueId={gradeValue.id} />}
                >
                    <GradeConstraintList key={gradeValue.id} gradeValueId={gradeValue.id} />
                </AccordionTab>)
            }
        </Accordion>

    </FlexColumnContainer>
}