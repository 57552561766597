import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { BuyerVerificationState } from './types';

export const createInitialState = (): BuyerVerificationState => {
    return {
        loadingState: CreateActionStatusInitialState(),
        submitState: CreateActionStatusInitialState(),
        verifications: []
    };
};

export const initialState: BuyerVerificationState = createInitialState();