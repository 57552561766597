import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { SyncRequestDto } from "../../../../commonModels/syncRequest";
import { licenseAdapter } from "../state";
import { actionFactory, License, SyncAdminLicenseState } from "../types";

export const loadSliceData = actionFactory.createAppAction("loadSliceData");

interface LoadSliceDataResult {
    licenses: License[],
    latestSyncRequest: SyncRequestDto | null
}

const sagaActions = actionFactory.createSagaWithProgressActions<void, LoadSliceDataResult>(loadSliceData.type);

export function loadSliceDataReducers(reducerFactory: IReducerFactory<SyncAdminLicenseState>): IReducerFactory<SyncAdminLicenseState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.loadingState)
        .onFulfilled((state, action) => {
            licenseAdapter.setAll(state.licenses, action.payload.result.licenses);
            state.latestSyncLicenseRequest = action.payload.result.latestSyncRequest;
            action.payload.result.licenses.forEach(license => {
                state.licenseSaveState[license.id] = { errorContent: null, isExecuting: false }
            });
        })
        .build();
}

async function performLoad(_: void, context: SagaAppContext): Promise<LoadSliceDataResult> {

    return Promise.all(
        [
            context.marketplaceApiClient.syncAdmin.getLatestLicenseSyncRequest(), 
            context.marketplaceApiClient.syncAdmin.getLicenses()
        ])
        .then((values) => {
            return {
                latestSyncRequest: values[0],
                licenses: values[1]
            }
        });
    
}

export function createLoadSliceDataSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: loadSliceData.type
    });
};