import { Card } from 'primereact/card';
import { DataView, DataViewLayoutType } from 'primereact/dataview';
import { useState } from 'react';
import { useMountEffect } from '../../../../../utils/useEffectHelper';
import './index.css';

interface TestModel {
    name: string;
    value: string;
}



const itemTemplate = (model: TestModel, _: DataViewLayoutType): React.ReactNode => {
    return <div className="item-template">
        <Card
            header={model.name}
        >
            <div>{model.value}</div>
        </Card>
    </div>
}

export const DataViewScrollTests: React.FC = () => {

    const [models, setModels] = useState<TestModel[]>([]);

    useMountEffect(() => {
        const tempModels: TestModel[] = [];

        for (let index = 1; index <= 30; index++) {
            tempModels.push({
                name: `This is test item: ${index}`,
                value: `This is a description for test item: ${index}`
            });
        }
        setModels(tempModels);
    });

    return <div id="scroll-test-root">
        <div id="top-fixed-container" className="p-d-flex p-flex-column p-jc-center p-ai-center">
            This is a fixed position top bar
        </div>
        <div id="scroll-content-container">
            <DataView
                value={models}
                layout="list"
                itemTemplate={itemTemplate}
            />
        </div>
    </div>
}