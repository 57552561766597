import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory, InitialSetupWorkflowState, UserSetupInfo } from "../types";


export const loadUserSetupInfo = actionFactory.createAppAction("loadUserSetupInfo");

const sagaActions = actionFactory.createSagaWithProgressActions<void, UserSetupInfo>(loadUserSetupInfo.type);

export function loadUserSetupInfoReducers(reducerFactory: IReducerFactory<InitialSetupWorkflowState>): IReducerFactory<InitialSetupWorkflowState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.userSetupRequiredState.loadingState)
        .onFulfilled((state, action) => {
            state.userSetupRequiredState.userSetupInfo = action.payload.result;            
        })
        .build();
}

async function performLoad(_arg: void, _context: SagaAppContext): Promise<UserSetupInfo> {   
    return _context.marketplaceApiClient.userProfile.getUserSetupInfo();    
}

export function createLoadUserSetupInfoSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: loadUserSetupInfo.type,
        debounceInMilliseconds: 250
    });
};