import { DateTime } from "luxon";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";

export const sliceName: string = "acceptTermsOfService";

export const actionFactory = new ActionFactory<AcceptTermsOfServiceState>(sliceName);

export interface TermsOfServiceUserAcceptance {
    id: number | null;
    termsOfServiceDocument: string;    
    acceptedUtc: DateTime | null;
    acceptedBy: string | null;    
    stateAbbreviation: string;   
    termsOfServiceId: number;
}

export interface AcceptTermsOfServiceState {
    loadingState: ActionStatus;  
    savingState: ActionStatus;  
    userAgreesToNewTerms: boolean;
    termsOfServiceAcceptance: TermsOfServiceUserAcceptance | null;      
}