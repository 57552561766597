import { push } from "connected-react-router";
import { UserSettings } from "oidc-client";
import { IsWholesaleUser } from "../../../authorization/roles";
import { messageFromError } from "../../../errorHandling/errorMessageHelper";
import { noApplicationAccessRoute } from "../../../navigation/routes";
import { parseJwt } from "../../helper";
import { getUserRoles } from "../selectors/userRoles";
import { actionFactory, AuthenticationAction } from "../types";

// export const completeLogin = actionFactory
//     .createAppAction("completeLogin");

export const {
    completeLogin,
    completeLoginReducer,
    completeLoginSagaFactory
} = actionFactory
    .withMappedName("completeLogin")
    .createAsyncAction<void, UserSettings>({
        actionStatusSelector: (state) => state.CompleteLoginState,
        onPending: (state) => {
            state.AuthenticationAction = AuthenticationAction.LoggingIn;
        },
        onRejected: (state, action) => {
            state.AuthenticationAction = null;
            state.ErrorMessage = action.payload.errorResult;
        },
        asyncCall: async (_arg, context) => {
            const user = await context.authService.completeLogin();

            if (user === null) {
                throw new Error("The login could not be completed. No user record was returned by the authentication service.")
            }
        
            return {
                id_token: user.id_token,
                session_state: user.session_state ?? "",
                access_token: user.access_token,
                refresh_token: user.refresh_token ?? "",
                token_type: user.token_type,
                scope: user.scope,
                profile: user.profile,
                expires_at: user.expires_at,
                state: user.state
            };
        },
        onFulfilled: (state, action) => {
            state.AuthenticationAction = null;
            state.ErrorMessage = null;
            state.signInCompleted = true;

            try {
                const user = action.payload.result;

                if (user === null) {
                    state.CurrentUser = null;
                }
                else {
                    state.CurrentUser = {
                        userSettings: user,
                        accessTokenData: parseJwt(user.access_token)
                    };                    
                }
            }
            catch (error) {
                state.ErrorMessage = messageFromError(error);
            }
        },
        successCallback: (_arg, _result, context) => {
            const state = context.getState();
            const roles = getUserRoles(state)
            if (!IsWholesaleUser(roles)) {
                console.log("No application access. Redirecting...");
                context.dispatch(push(noApplicationAccessRoute.path));
            }
        }
    })

// const sagaActions = actionFactory.createSagaWithProgressActions<void, UserSettings | null>(completeLogin.type);

// export const completeLoginFulfilledAction = sagaActions.fulfilled.type;

// export function completeLoginReducers(reducerFactory: IReducerFactory<UserAuthState>): IReducerFactory<UserAuthState> {
//     return reducerFactory
//         .forSagaWithProgress(sagaActions)
//         .onPending((state, _) => {
//             state.AuthenticationAction = AuthenticationAction.LoggingIn;
//         })
//         .onRejected((state, action) => {
//             state.AuthenticationAction = null;
//             state.ErrorMessage = action.payload.errorResult;
//         })
//         .onFulfilled((state, action) => {

//             state.AuthenticationAction = null;
//             state.ErrorMessage = null;
//             state.signInCompleted = true;

//             try {
//                 const user = action.payload.result;

//                 if (user === null) {
//                     state.CurrentUser = null;
//                 }
//                 else {
//                     state.CurrentUser = {
//                         userSettings: user,
//                         accessTokenData: parseJwt(user.access_token)
//                     };                    
//                 }
//             }
//             catch (error) {
//                 state.ErrorMessage = messageFromError(error);
//             }
//         })        
//         .build();
// }

// async function onCompleteLogin(_: void, context: SagaAppContext): Promise<UserSettings | null> {

//     const user = await context.authService.completeLogin();

//     if (user === null) {
//         throw new Error("The login could not be completed. No user record was returned by the authentication service.")
//     }

//     return {
//         id_token: user.id_token,
//         session_state: user.session_state ?? "",
//         access_token: user.access_token,
//         refresh_token: user.refresh_token ?? "",
//         token_type: user.token_type,
//         scope: user.scope,
//         profile: user.profile,
//         expires_at: user.expires_at,
//         state: user.state
//     };
// }

// const verifyMarketplaceRoles = (_arg: void, _result: UserSettings | null, context: SagaAppContext) => {
//     const state = context.getState();
        
//     const roles = getUserRoles(state)
//     if (!IsWholesaleUser(roles)) {
//         console.log("No application access. Redirecting...");
//         context.dispatch(push(noApplicationAccessRoute.path));
//     }
// }

// export function createCompleteLoginSaga(context: SagaAppContext) {

//     return createExecuteWithProgressSaga<void, UserSettings | null>({
//         options: {
//             action: onCompleteLogin,
//             appContext: context,
//             notificationActions: sagaActions,
//             logMetric: true,
//             getMetricDetail: (_, result) => result?.profile.email,
//             successCallback: verifyMarketplaceRoles
//         },
//         actionPattern: completeLogin.type
//     });
// };