import { isValue } from "../../../../../utils/valueHelper";
import { TemplateMetric } from "../../../models/templateMetric";
import { templateMetricAdapter } from "../state";
import { actionFactory, MetricKindSelection } from "../types";

export const {

    selectMetricsBegin,
    selectMetricsCancel,
    selectMetricsComplete,
    selectMetricsReducers,
    selectMetricsSagaFactory

} = actionFactory
    .withMappedName("selectMetrics")
    .createModal<MetricKindSelection, void, TemplateMetric[]>({
        selector: state => state.selectMetricsState,
        modelFactory: (_arg, _state) => {
            return { metricKinds: [] };
        },
        onCompleteEdit: (value, context) => {
            const sliceState = context.getState().qualityTemplateEditor;
            const template = sliceState.template
            if (!isValue(template)) throw new Error("Cannot assign quality metrics without a valid template");

            const metricKindIds = value.metricKinds.map(metricKind => metricKind.id);

            return context.marketplaceApiClient.quality.addMetricsToTemplate({
                assessmentTemplateId: template.id,
                metricKindIds: metricKindIds
            });
        },
        onFulfilled: (state, action) => {
            templateMetricAdapter.upsertMany(state.templateMetrics, action.result);            
        }
    });