import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, CorporateEntityState, EditCorporateEntityRequest } from "../types";

export const beginEdit = actionFactory.createAppAction<EditCorporateEntityRequest>("beginEdit");

export function beginEditReducers(reducerFactory: IReducerFactory<CorporateEntityState>): IReducerFactory<CorporateEntityState> {
    return reducerFactory
        .forAction(beginEdit)
        .addHandler((state, action) => {
            state.entityEditDialogVisible = true;

            state.entityEditValue = { ...action.payload };

        })
        .build();
}