import clsx from 'clsx';
import { Card } from 'primereact/card';
import { Tooltip } from 'primereact/tooltip';
import { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { FlexColumnContainer } from '../../../../components/containers/flexColumnContainer';
import { FlexRowContainer } from '../../../../components/containers/flexRowContainer';
import { PopUpMenuButton } from '../../../../components/popMenuButton';
import { formatToShortDateString } from '../../../../utils/datetimeHelper';
import { currencyFormatOptions, percentFormatOptions } from '../../../../utils/formatHelpers';
import { createLocalImageUrl } from '../../../../utils/imageHelper';
import { TypedMenuItem } from '../../../../utils/table/typedMenuItem';
import { isValue } from '../../../../utils/valueHelper';
import { ImageContent } from '../../../commonModels/imageTypes';
import { cardThumbnailSize } from '../../constants';
import { showMediaForInventory } from '../../inventoryImageGallery/store/featureActions/showMediaForInventory';
import { packageTypeIsImmaturePlant } from '../logic/packageType';
import { InventoryView } from '../store/types';
import './inventoryGridCard.css';

export interface InventoryGridCardProps {
    onMoreInfoClick?: () => void;
    primaryColor: string;
    secondaryColor: string;
    primaryFont: string | undefined;
    secondaryFont: string | undefined;
    userOwnsMenu: boolean;
    menuItems: TypedMenuItem<InventoryView>[];
    image: ImageContent | undefined | null;
    inventory: InventoryView | undefined | null;
    showDescription?: boolean;
}

export const InventoryGridCard: React.FC<InventoryGridCardProps> = ({
    primaryColor,
    secondaryColor,
    primaryFont,
    secondaryFont,
    userOwnsMenu,
    menuItems,
    image,
    inventory,
    showDescription = false
}) => {

    const dispatch = useDispatch();

    const localImageUrl = isValue(image) && isValue(image.imageData) && isValue(image.mimeType)
        ? createLocalImageUrl(image)
        : "";

    const buttonStyle: CSSProperties = {
        background: primaryColor ?? undefined,
        color: secondaryColor ?? undefined
    }

    const onImageClick = () => {
        if (!isValue(inventory)) return;
        dispatch(showMediaForInventory(inventory.id));
    }

    const isImmaturePlantPackage = packageTypeIsImmaturePlant(inventory);

    return <Card
        key={inventory?.id}
        title={<div>
            <Tooltip target=".node-inv-card-title" position='top' />
            <div
                className='node-inv-card-title'
                data-pr-tooltip={inventory?.marketingName}
                style={{ fontFamily: primaryFont }}
            >{inventory?.marketingName}</div>
        </div>}
        subTitle={<FlexRowContainer centerContent width="100%">
            <FlexColumnContainer alignItems="start" className='node-inv-card-sub-title-left'>
                <div className='node-inv-card-sub-title' style={{ fontFamily: secondaryFont }}>{inventory?.marketingStrainName ?? ""}</div>
            </FlexColumnContainer>
        </FlexRowContainer>}
        className="node-menu-card"
    >
        <FlexColumnContainer centerContent paddingTop="5px" borderTop="1px solid lightgrey">

            <FlexRowContainer centerContent justifyContent="center" width={`${cardThumbnailSize.width}px`} height={`${cardThumbnailSize.height}px`}>
                {
                    isValue(localImageUrl) && localImageUrl.length > 0
                        ? <img
                            onClick={onImageClick}
                            src={localImageUrl}
                            alt="Coming soon!"
                            width={`${cardThumbnailSize.width}px`}
                            height={`${cardThumbnailSize.height}px`}
                        />
                        : <div
                            onClick={onImageClick}
                            style={{
                                width: `${cardThumbnailSize.width}px`,
                                height: `${cardThumbnailSize.height}px`,
                                textAlign: "center"
                            }}
                        >Image coming soon!</div>
                }
            </FlexRowContainer>


            <FlexRowContainer centerContent className='node-inv-card-split-content-container node-card-content-shadow' >

                <FlexColumnContainer centerContent justifyContent="center" width="100%" >
                    <div style={{ fontFamily: primaryFont }} className='node-inv-card-split-content-label'>Quantity</div>
                    <div style={{ fontFamily: secondaryFont }} className={clsx({
                        "node-inv-card-split-content-novalue": !isValue(inventory?.quantity),
                        "node-inv-card-split-content-value": isValue(inventory?.quantity)
                    })}
                    >{`${inventory?.quantity?.toLocaleString(undefined, { maximumFractionDigits: 4 }) ?? ""} ${inventory?.unitOfMeasureName}`}</div>
                </FlexColumnContainer>

            </FlexRowContainer>

            <FlexRowContainer centerContent className='node-inv-card-split-content-container'>

                <FlexRowContainer className='node-card-content-shadow node-inv-card-split-content'>
                    <FlexColumnContainer centerContent>
                        <div style={{ fontFamily: primaryFont }} className='node-inv-card-split-content-label'>THC %</div>
                        <div style={{ fontFamily: secondaryFont }} className={clsx({
                            "node-inv-card-split-content-novalue": !isValue(inventory?.thcPercentage),
                            "node-inv-card-split-content-value": isValue(inventory?.thcPercentage)
                        })}
                        >{inventory?.thcPercentage?.toLocaleString(undefined, percentFormatOptions) ?? "Contact Vendor"}</div>
                    </FlexColumnContainer>

                </FlexRowContainer>

                <FlexRowContainer className='node-card-content-shadow node-inv-card-split-content'>
                    <FlexColumnContainer centerContent>
                        <div style={{ fontFamily: primaryFont }} className='node-inv-card-split-content-label'>Unit Price</div>
                        <div style={{ fontFamily: secondaryFont }} className={clsx({
                            "node-inv-card-split-content-novalue": !isValue(inventory?.unitPrice),
                            "node-inv-card-split-content-value": isValue(inventory?.unitPrice)
                        })}
                        >{inventory?.unitPrice?.toLocaleString(undefined, currencyFormatOptions) ?? "Contact Vendor"}</div>
                    </FlexColumnContainer>

                </FlexRowContainer>

            </FlexRowContainer>

            <FlexRowContainer centerContent className='node-inv-card-split-content-container'>

                <FlexRowContainer className='node-card-content-shadow node-inv-card-split-content'>
                    <FlexColumnContainer centerContent>
                        <div style={{ fontFamily: primaryFont }} className='node-inv-card-split-content-label'>CBD %</div>
                        <div style={{ fontFamily: secondaryFont }} className={clsx({
                            "node-inv-card-split-content-novalue": !isValue(inventory?.cbdPercentage),
                            "node-inv-card-split-content-value": isValue(inventory?.cbdPercentage)
                        })}
                        >{inventory?.cbdPercentage?.toLocaleString(undefined, percentFormatOptions) ?? "Contact Vendor"}</div>
                    </FlexColumnContainer>

                </FlexRowContainer>

                <FlexRowContainer className='node-card-content-shadow node-inv-card-split-content'>
                    <FlexColumnContainer centerContent>
                        <div style={{ fontFamily: primaryFont }} className='node-inv-card-split-content-label'>{isImmaturePlantPackage ? "Ready" : "Grade"}</div>
                        <div style={{ fontFamily: secondaryFont }} className={clsx({
                            "node-inv-card-split-content-novalue": isImmaturePlantPackage
                                ? !isValue(inventory?.readyDate)
                                : !isValue(inventory?.grade),
                            "node-inv-card-split-content-value": isImmaturePlantPackage
                                ? isValue(inventory?.readyDate)
                                : isValue(inventory?.grade)
                        })}
                        >
                            {
                                isImmaturePlantPackage
                                    ? isValue(inventory?.readyDate) 
                                        ? formatToShortDateString(inventory?.readyDate) 
                                        : "Contact Vendor"
                                    : inventory?.grade ?? "Contact Vendor"
                            }
                        </div>
                    </FlexColumnContainer>

                </FlexRowContainer>

            </FlexRowContainer>

            {
                showDescription &&
                <div className='node-inv-card-description-container' style={{ fontFamily: secondaryFont }}>{inventory?.marketingDescription}</div>
            }

            <FlexRowContainer centerContent alignSelf="flex-start" width="100%" marginTop="5px">

                {
                    userOwnsMenu &&
                    <div className="node-inv-card-command-bar node-inv-card-package-label">{`Inv#: ${inventory?.packageLabel}`}</div>
                }

                {/* {
                    !userOwnsMenu &&
                    <div className="node-inv-card-command-bar node-inv-card-package-label">{`Packaged: ${formatToShortDateString(inventory?.packagedDate)}`}</div>
                } */}


                <div className="node-inv-card-command-bar">
                    {
                        isValue(inventory) &&
                        menuItems.length > 0 &&
                        <PopUpMenuButton<InventoryView>
                            id="inventory-popup-menu"
                            menuItems={menuItems}
                            value={inventory}
                            buttonStyle={buttonStyle}
                        />
                    }
                </div>

            </FlexRowContainer>

        </FlexColumnContainer>
    </Card >
}