import { ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { createFillRemainingSpaceColumnProps } from "../../../../utils/table/fillRemainingSpaceColumn";
import { mapColumns } from "../../../../utils/table/mapPropsToColumns";
import { createMultiActionColumnProps } from "../../../../utils/table/multiActionColumn";
import { createFilteredTextColumnProps } from "../../../../utils/table/stringColumn";
import { TypedCommandMenuItem } from "../../../../utils/table/typedMenuItem";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { Menu } from "../../../menu/editor/store/types";
import { openMenuInNewWindow } from "../../../navigation/helper";
import { loadFavoriteMenus } from "../store/featureActions/loadFavoriteMenus";
import { removeFromFavorites } from "../store/featureActions/removeFromFavorites";
import { getFavoriteMenus, getLoadingState } from "../store/selectors/favoriteMenus";

export const FavoritesTable: React.FC = () => {
    
    const menus = useAppSelector(getFavoriteMenus);
    const loadingState = useAppSelector(getLoadingState);
    const dispatch = useDispatch();

    useMountEffect(() => {
        dispatch(loadFavoriteMenus());
    });

    const onViewMenu = (menu: Menu) => {
        openMenuInNewWindow(menu);
    }

    const onRemoveFromFavorites = (menu: Menu) => {
        dispatch(removeFromFavorites({ menuId: menu.id }));
    }

    const menuItems: TypedCommandMenuItem<Menu>[] = [
        {
            label: "Remove From Favorites",
            command: onRemoveFromFavorites,
            style: { width: "600px" }
        }
    ];

    const columns: ColumnProps[] = [
        createMultiActionColumnProps<Menu>({
            columnKey: "action-column",
            header: "Actions",
            actionName: "View",
            menuClassName: "favorite-menus-action-menu-item",
            actionHandler: onViewMenu,
            style: { width: "150px" },
            menuItems: menuItems
        }),
        createFilteredTextColumnProps<Menu>({ field: "vendorName", header: "Vendor", style: { width: "300px" } }),
        createFilteredTextColumnProps<Menu>({ field: "name", header: "Name", style: { width: "300px" } }),
        createFillRemainingSpaceColumnProps({})
    ];

    return <DataTable 
        loading={loadingState.isExecuting}
        dataKey="id"
        value={menus}
        scrollable
        scrollDirection='both'        
        scrollHeight="flex"
        paginator
        rows={20}
        rowsPerPageOptions={[10, 20, 50]}
    >
        {mapColumns(columns)}
    </DataTable>
}