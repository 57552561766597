import { createSafeUrlName } from "../../../utils/safeUrlHelper";
import ApiClient from "../../apiCommunication/apiClient";
import { ImageDto } from "../../commonModels/imageTypes";
import { EntityImage } from "../../commonModels/entityImage";
import { InventoryView, LoadThumbnailsRequest, ProductView, VendorMenu } from "../endUser/store/types";
import { Vendor } from "../productDetails/store/types";
import { EntityVideo } from "../../commonModels/entityVideo";
import { SerializationBuilder } from "../../apiCommunication/serializationBuilder";
import { Menu } from "../editor/store/types";


export const inventoryViewSerializer = SerializationBuilder
    .forType<InventoryView>()
    .parseUtcDatesToLocalTime(["readyDate"])
    .parseLocalDates(["packagedDate"])
    .build();

export default class LiveMenu {

    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    getLiveMenu = (vendorName: string, menuName: string): Promise<VendorMenu> => {

        const vendorUrlName = createSafeUrlName(vendorName);
        const menuUrlName = createSafeUrlName(menuName);

        return this
            .apiClient
            .executeGet<VendorMenu>(`vendor/${vendorUrlName}/menu/${menuUrlName}`);
    };

    getLiveMenuIsFavorite = (vendorName: string, menuName: string): Promise<boolean> => {

        const vendorUrlName = createSafeUrlName(vendorName);
        const menuUrlName = createSafeUrlName(menuName);

        return this
            .apiClient
            .executeGet<boolean>(`vendor/${vendorUrlName}/menu/${menuUrlName}/favorite`);
    };
    
    getVendor = (vendorUrlName: string): Promise<Vendor> => {

        const safeUrlName = createSafeUrlName(vendorUrlName);

        return this
            .apiClient
            .executeGet<Vendor>(`vendor/${safeUrlName}`);
    };   

    getVendorMenus = (companyId: number): Promise<Menu[]> => {
        return this
            .apiClient
            .executeGet<Menu[]>(`vendor/${companyId}/menu`);
    }; 

    getVendorLicenses = (vendorUrlName: string): Promise<string[]> => {

        const safeUrlName = createSafeUrlName(vendorUrlName);

        return this
            .apiClient
            .executeGet<string[]>(`vendor/${safeUrlName}/license`);
    };

    
    getInventoryImages = (inventoryId: number): Promise<EntityImage[]> => {
        return this
            .apiClient
            .executeGet<EntityImage[]>(`vendor/images/inventory/${inventoryId}`);
    };

    getInventoryVideo = (inventoryId: number): Promise<EntityVideo[]> => {
        return this
            .apiClient
            .executeGet<EntityVideo[]>(`vendor/videos/inventory/${inventoryId}`);
    };
    
    getInventoryThumbnail = (inventoryId: number): Promise<EntityImage | null> => {
        return this
            .apiClient
            .executeGet<EntityImage | null>(`vendor/images/inventory/${inventoryId}/thumbnail`);
    };

    getInventoryThumbnails = (request: LoadThumbnailsRequest): Promise<EntityImage[]> => {
        return this
            .apiClient
            .executePostWithResult<EntityImage[]>(`vendor/images/inventory/thumbnail`, request);
    };

    getSectionProducts = (companyId: number, sectionId: number): Promise<ProductView[]> => {
        return this
            .apiClient
            .executeGet<ProductView[]>(`vendor/${companyId}/menu/section/${sectionId}/products`);
    };

    getSectionInventory = (companyId: number, sectionId: number): Promise<InventoryView[]> => {
        return this
            .apiClient
            .executeGet<InventoryView[]>(`vendor/${companyId}/menu/section/${sectionId}/inventory`)
            .then(inventoryViewSerializer.serializeSet);
    };

    getInventory = (vendorUrlName: string, menuUrlName: string,inventoryId: number): Promise<InventoryView> => {
        return this
            .apiClient
            .executeGet<InventoryView>(`vendor/${vendorUrlName}/menu/${menuUrlName}/inventory/${inventoryId}`)
            .then(inventoryViewSerializer.serializeSingle);
    };

    getVendorLogo = (vendorUrlName: string, menuUrlName: string): Promise<ImageDto> => {
        return this
            .apiClient
            .executeGet<ImageDto>(`vendor/${vendorUrlName}/menu/${menuUrlName}/logo`);
    };

    getVendorBanner = (vendorUrlName: string, menuUrlName: string): Promise<ImageDto> => {
        return this
            .apiClient
            .executeGet<ImageDto>(`vendor/${vendorUrlName}/menu/${menuUrlName}/banner`);
    };

    getDefaultThumbnail = (vendorUrlName: string, menuUrlName: string): Promise<ImageDto> => {
        return this
            .apiClient
            .executeGet<ImageDto>(`vendor/${vendorUrlName}/menu/${menuUrlName}/imageUnavailable`);
    };

}