import { isValue } from "../../../../../../utils/valueHelper";
import { BulkAssignmentKind } from "../../../../../commonModels/bulkAssignmentState";
import { actionFactory } from "../../types";
import { loadInventory } from "../loadInventory";

export const {

    completeBulkQualityGradeAssignment,
    completeBulkQualityGradeAssignmentReducer,
    completeBulkQualityGradeAssignmentSagaFactory

} = actionFactory
    .withMappedName("completeBulkQualityGradeAssignment")
    .createAsyncAction<void, Response>({        
        actionStatusSelector: state => state.bulkQualityGradeAssignmentState.savingState,
        asyncCall: (_arg, context) => {
            const bulkAssignmentState = context.getState().inventoryManagement.bulkQualityGradeAssignmentState;
            if (bulkAssignmentState.bulkAssignmentKind === BulkAssignmentKind.Assign && !isValue(bulkAssignmentState.qualityGradeIdToAssign)) throw new Error("A quality grade must be selected!");
            
            return context.marketplaceApiClient.inventoryManagement.assignQualityGradeToProducts({
                qualityGradeId: bulkAssignmentState.qualityGradeIdToAssign,
                entityIds: bulkAssignmentState.inventory.map(inv => inv.id)
            });
        },
        onFulfilled: (state, _action) => {
            state.bulkQualityGradeAssignmentState.inventory = [];
            state.bulkQualityGradeAssignmentState.qualityGradeIdToAssign = null;
        },
        successCallback: (_arg, _result, context) => {
            context.dispatch(loadInventory())
        }
    });