import clsx from "clsx";
import { Field, FieldProps, FormikContextType, useFormikContext } from "formik";
import { CSSProperties } from 'react';
import { getStringOrNullValue, PropertiesOfType, StringOrNullKeyOf } from '../../utils/typeHelpers';
import { FontPicker } from "../fonts/fontPicker";

export interface FontFieldProps<T extends PropertiesOfType<T, string | null | undefined>> {
    defaultLabel: string;
    fieldName: keyof T & string & StringOrNullKeyOf<T>;
    containerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    disabled?: boolean;
    submitOnChange?: boolean;
    availableFontFamilies: string[];
}

export const FontField = <T extends PropertiesOfType<T, string | null | undefined>,>({

    defaultLabel,
    fieldName,
    labelStyle,
    containerStyle,
    inputStyle,
    disabled,
    availableFontFamilies,
    submitOnChange = false
}: FontFieldProps<T>) => {

    const formikProps: FormikContextType<T> = useFormikContext<T>();
    const labelText = defaultLabel;

    return <div style={containerStyle}>

        <label style={labelStyle} htmlFor={fieldName} >{labelText}</label>
        <Field
            name={fieldName}
        >
            {
                (props: FieldProps<T>) =>
                    <FontPicker
                        disabled={disabled}
                        style={inputStyle}                        
                        className={clsx({ "p-invalid": !!formikProps.errors[fieldName] })}
                        value={getStringOrNullValue(formikProps.values, fieldName) ?? undefined}
                        onChange={(value) => {
                            formikProps.setFieldValue(props.field.name, value);
                            if (submitOnChange) {
                                formikProps.submitForm();
                            }
                        }}
                        availableFontFamilies={availableFontFamilies}

                    />                
            }
        </Field>
        <div>
            <small>{formikProps.errors[fieldName]}</small>
        </div>
    </div>
}
