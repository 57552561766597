import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { StringField } from "../../../../components/formik/stringField";
import { cancelSaveAs } from "../store/featureActions/cancelSaveAs";
import { saveNewUserTableSettings } from "../store/featureActions/saveNewUserTableSettings";
import { getSaveAsEditModel, getSaveAsStatus, getSaveAsVisible } from "../store/selectors";
import { SaveAsModel } from "../store/types";
import { saveAsValidationSchema } from "../validations/saveAsValidationSchema";


export const SaveAsDialog: React.FC = () => {

    const dispatch = useDispatch();

    const onCancel = () => {
        dispatch(cancelSaveAs());
    };  

    const onSave = (value: SaveAsModel) => {        
        dispatch(saveNewUserTableSettings({ viewId: value.viewId, name: value.newName }));
    }

    return <FormikModalEditor<SaveAsModel>
        title="Save settings"
        valueSelector={getSaveAsEditModel}
        visibleSelector={getSaveAsVisible}
        onSave={onSave}
        onCancel={onCancel}
        statusSelector={getSaveAsStatus}
        validationSchema={saveAsValidationSchema}
    >
        <FlexColumnContainer gap="20px" marginTop="20px">

            <StringField<SaveAsModel>
                fieldName="newName"
                allowNoValue={false}
                defaultLabel="Name"
                labelStyle={{ width: "120px" }}
                inputStyle={{ width: "350px", marginLeft: "10px" }}
            />            

        </FlexColumnContainer>

    </FormikModalEditor>
} 