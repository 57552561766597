import { Button } from "primereact/button"
import { useDispatch } from "react-redux"
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer"
import { useAppSelector } from "../../../../store/configureStore"
import { formatToShortDateString } from "../../../../utils/datetimeHelper"
import { isValue } from "../../../../utils/valueHelper"
import { getUserIsSeller } from "../../../authentication/store/selectors/userRoles"
import { completeAssessment } from "../store/featureActions/completeAssessment"
import { reopenAssessment } from "../store/featureActions/reopenAssessment"
import { getIsSavingAssessment } from "../store/selectors/actionStatus"
import { getFinalGradeForAssessment, getQualityAssessmentById } from "../store/selectors/editing"

export interface AssessmentCommandBarProps {
    assessmentId: number
}


export const AssessmentCommandBar: React.FC<AssessmentCommandBarProps> = ({ assessmentId }) => {

    const dispatch = useDispatch();
    const assessment = useAppSelector(state => getQualityAssessmentById(state, assessmentId))
    const finalGrade = useAppSelector(state => getFinalGradeForAssessment(state, assessmentId));
    const isSaving = useAppSelector(state => getIsSavingAssessment(state, assessmentId));
    const useCanReopen = useAppSelector(getUserIsSeller);

    const isCompleted = isValue(assessment) && isValue(assessment.completedOn);
    const hasFinalGrade = isValue(finalGrade);



    const onComplete = () => {
        dispatch(completeAssessment(assessmentId));
    }

    const onReopen = () => {
        dispatch(reopenAssessment(assessmentId));
    }

    return <FlexRowContainer className="quality-assessment-editor-cmd-bar">
        {
            !isCompleted &&
            <Button                
                onClick={onComplete}
                loading={isSaving}
                disabled={isCompleted}
                label="Complete"
            />
        }

        {
            isCompleted &&
            <Button                
                onClick={onReopen}
                loading={isSaving}
                disabled={!isCompleted || !useCanReopen}
                label="Reopen"
            />
        }

        {isCompleted && <div>|</div>}

        {
            isCompleted &&
            <div>
                {`Completed on ${formatToShortDateString(assessment.completedOn)} by ${assessment.completedBy}`}
            </div>
        }


        {hasFinalGrade && <div>|</div>}

        {
            hasFinalGrade &&
            <div>
                {`Calculated Grade:  ${finalGrade.name}`}
            </div>
        }

    </FlexRowContainer>
}