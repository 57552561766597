import { SecurityRole } from "../../authorization/roles";

export type AuthRoute = {
  kind: "Auth";
  path: string;
  exact: boolean;
  view: React.FC<any>;
};

export type NonSecuredRoute = {
  kind: "NonSecured";
  path: string;
  exact: boolean;
  view: React.FC<any>;
};

export interface NonSecureParameterizedRoute {
  kind: "NonSecureParameterized";
  path: string;
  createPath: (...parameter: (number | string)[]) => string;
  createFullUrl: (...parameter: (number | string)[]) => string;
  exact: boolean;
  view: React.FC<any>;
};

export type SecurityRolePredicate = ((securityRoles: string[]) => boolean);

export function isSecurityRolePredicate(value: SecurityRole | SecurityRolePredicate): value is SecurityRolePredicate {
  return (value as SecurityRolePredicate).call !== undefined;
}

export interface SecureRoute {
  role: SecurityRole | SecurityRolePredicate;
}

export interface SimpleRoute extends SecureRoute {
  kind: "Secured";
  path: string;
  exact: boolean;
  view: React.FC<any>;
}
;

export interface ParameterizedRoute extends SecureRoute {
  kind: "Parameterized";
  path: string;
  createPath: (...parameter: (number | string)[]) => string;
  createFullUrl: (...parameter: (number | string)[]) => string;
  exact: boolean;
  view: React.FC<any>;
}
;


export type RouteModel = AuthRoute | NonSecuredRoute | SimpleRoute | ParameterizedRoute | NonSecureParameterizedRoute;

export function isSecuredRoute(route: RouteModel): route is SimpleRoute | ParameterizedRoute {
  return (route as SecureRoute).role !== undefined;
}

export function isParameterizedRoute(route: RouteModel): route is ParameterizedRoute {
  return route.kind === "Parameterized";
}
