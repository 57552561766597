import { groupBy } from "lodash";
import { Load6Result, parallelLoad } from "../../../../../utils/promiseHelpers";
import { AssessmentTemplate } from "../../../../qualityGradeTemplates/models/assessmentTemplate";
import { QualityGradeKind } from "../../../../qualityGradeTemplates/models/gradeKind";
import { QualityGradeValue } from "../../../../qualityGradeTemplates/models/gradeValue";
import { InventoryMenuAssignment } from "../../../general/models/menuAssignment";
import { inventoryAdapter, qualityGradeAdapter, qualityGradeKindAdapter } from "../state";
import { actionFactory, Inventory, OrderedInventoryDetails } from "../types";
import { updateInventoryAssignmentMaps } from "./updateInventoryAssignmentMaps";

type LoadResult = Load6Result<
    InventoryMenuAssignment[], 
    Inventory[], 
    QualityGradeValue[], 
    OrderedInventoryDetails[], 
    AssessmentTemplate[],
    QualityGradeKind[]>;

export const {

    loadSliceData,
    loadSliceDataReducer,
    loadSliceDataSagaFactory

} = actionFactory
    .withMappedName("loadSliceData")
    .createAsyncAction<void, LoadResult>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (_, context) => parallelLoad(
            () => context.marketplaceApiClient.menuAdmin.getMenuInventoryAssignments(),
            () => context.marketplaceApiClient.inventoryManagement.getInventory(),
            () => context.marketplaceApiClient.quality.getQualityGradeValues(),
            () => context.marketplaceApiClient.inventoryManagement.getOrderedQuantityDetails({ inventoryIds: [] }),
            () => context.marketplaceApiClient.quality.getAssessmentTemplates(),
            () => context.marketplaceApiClient.quality.getQualityGradeKinds(),
        ),
        onFulfilled: (state, action) => {
            state.menuAssignments = action.payload.result.result1;

            inventoryAdapter.upsertMany(state.inventory, action.payload.result.result2.map(inv => ({ ...inv, orderedQuantity: 0 })));            
            qualityGradeAdapter.setAll(state.qualityGrades, action.payload.result.result3);

            var groupedOrderedQuantity = groupBy(action.payload.result.result4, (arg) => arg.inventoryId);
            state.orderedQuantities = groupedOrderedQuantity;

            state.qualityAssessmentTemplates = action.payload.result.result5;
            
            qualityGradeKindAdapter.setAll(state.qualityGradeKinds, action.payload.result.result6);
        },
        successCallback: (_arg, _result, context) => { context.dispatch(updateInventoryAssignmentMaps()); }
    });