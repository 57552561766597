import { assignChildrenNodes, TemplateConstraintNode } from "../../../models/constraintNode";
import { templateConstraintNodeAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    loadTemplateConstraintNodes,
    loadTemplateConstraintNodesReducer,
    loadTemplateConstraintNodesSagaFactory

} = actionFactory
    .withMappedName("loadTemplateConstraintNodes")
    .createAsyncAction<number, TemplateConstraintNode[]>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.quality.getTemplateConstraintNodes(arg);
        },
        onFulfilled: (state, action) => {

            const constraintNodes = action.payload.result;
            assignChildrenNodes(constraintNodes);
            templateConstraintNodeAdapter.setAll(state.constraintNodes, constraintNodes);
            
        }
    });