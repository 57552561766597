import clsx from "clsx";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Divider } from 'primereact/divider';
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { createDateColumn } from "../../../../utils/table/dateColumn";
import { createQueueStatusColumn } from "../../../../utils/table/queueStatusColumn";
import { createTextColumn } from "../../../../utils/table/stringColumn";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { submitVerificationRequest } from "../store/featureActions/submitVerificationRequest";
import { getLoadingState, getSubmitState } from "../store/selectors/actionStatus";
import { getPendingVerifications } from "../store/selectors/pendingVerifications";
import { resetState } from "../store/slice";
import { ApiKeyVerification, VerifyRequest } from "../store/types";
import { verifyRequestValidationSchema } from "../validations";
import './index.css';

export const ApiKeyVerificationDashboard: React.FC = () => {

    const dispatch = useDispatch();
    const pendingVerifications = useAppSelector(getPendingVerifications);
    const loadingState = useAppSelector(getLoadingState);
    const submitState = useAppSelector(getSubmitState);
    const toast = useRef<Toast>(null);

    useMountEffect(() => {
        dispatch(loadSliceData());

        return () => {
            dispatch(resetState());
        }
    });

    const initialFormState: VerifyRequest = {
        apiKey: "",
        license: ""
    };

    const onSubmit = (values: VerifyRequest, formikHelpers: FormikHelpers<VerifyRequest>): void | Promise<any> => {
        dispatch(submitVerificationRequest(values));
        formikHelpers.setSubmitting(false);
        formikHelpers.resetForm();
    };

    return <OverlayWaitDisplay
        isWaiting={loadingState.isExecuting}
    >
        <div>
            <Toast ref={toast} />
            <Card className="main-content-panel" title="Identity Verification">

                <h4>License and METRC Api Key Verification</h4>

                <div className="p-shadow-2 p-mx-1 p-my-4 p-p-3">
                    <i className="pi pi-info-circle p-mr-4 info-icon"></i>
                    Enter a METRC license number and a METRC api key that has read access to that license.
                </div>
                <div className="p-shadow-2 p-mx-1 p-my-4 p-p-3">
                    <i className="pi pi-info-circle p-mr-4 info-icon"></i>
                    Once the api key is verified to have access to the specified license number, your profile will appear as verified to other users.
                </div>

                <Formik
                    initialValues={initialFormState}
                    validationSchema={verifyRequestValidationSchema}

                    onSubmit={onSubmit}
                >
                    {
                        (actions) => <Form autoComplete="off">


                            <div className="p-fluid">

                                <div className="p-field">
                                    <label>License</label>
                                    <Field
                                        type="text"
                                        name="license"
                                        autoComplete="new-password"
                                        //disabled={isRegistering}
                                        className={clsx({ "p-invalid": !!actions.errors.license })}
                                        as={InputText}>
                                    </Field>
                                    <small>{actions.errors.license}</small>
                                </div>

                                <div className="p-field">
                                    <label>Api Key</label>
                                    <Field
                                        type="text"
                                        name="apiKey"
                                        feedback={false}
                                        autoComplete="new-password"
                                        className={clsx({ "p-invalid": !!actions.errors.apiKey })}
                                        as={Password}
                                    >
                                    </Field>
                                    <small>{actions.errors.apiKey}</small>
                                </div>

                            </div>

                            <Button
                                className="submit-button"
                                disabled={actions.isSubmitting || !actions.isValid}
                                loading={submitState.isExecuting}
                                type="submit"
                                label="Verify"
                                icon={PrimeIcons.CHECK}
                            />

                        </Form>
                    }

                </Formik>

                <Divider />

                <h4>Pending Verifications</h4>

                <DataTable value={pendingVerifications} dataKey="id" loading={loadingState.isExecuting}>
                    {createTextColumn<ApiKeyVerification>({ field: "license", header: "License" })}
                    {createDateColumn<ApiKeyVerification>({ field: "createdUtc", header: "Created" })}
                    {createQueueStatusColumn<ApiKeyVerification>({ columnKey: "queueStatusIcon", field: "status", header: "Verified" }, (verification) => verification.status)}
                    {createTextColumn<ApiKeyVerification>({ field: "status", header: "Status" })}
                    {createTextColumn<ApiKeyVerification>({ field: "errorMessage", header: "Failure Information" })}
                </DataTable>

            </Card>
        </div>
    </OverlayWaitDisplay>
}