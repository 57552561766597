import { useFormikContext } from "formik";
import { Message } from "primereact/message";
import React from "react";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { RemoveQualityAssessment } from "../store/types";

export const RemoveQualityAssessmentEditor: React.FC = () => {
    
    const formikContext = useFormikContext<RemoveQualityAssessment>();
    const message = `Quality assessments will be removed from ${formikContext.values.inventoryIds.length} package${formikContext.values.inventoryIds.length > 1 ? "s" : ""}.`;

    return <FlexColumnContainer gap="20px">

            <Message severity="info" text={message}></Message>

        </FlexColumnContainer>
}