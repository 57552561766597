import { createIdentityMap } from "../../../../../utils/identity";
import { Load3Result, parallelLoad } from "../../../../../utils/promiseHelpers";
import { Product } from "../../../../inventorySetup/productManagement/store/types";
import { Customer, SalesOrderWithDetail } from "../../../common/types";
import { orderInventoryAdapter, salesOrderItemAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    loadSliceData,
    loadSliceDataReducer,
    loadSliceDataSagaFactory

} = actionFactory
    .withMappedName("loadSliceData")
    .createAsyncAction<number, Load3Result<SalesOrderWithDetail, Customer, Product[]>>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {
            return parallelLoad(
                () => context.marketplaceApiClient.customer.getOrder(arg),
                () => context.marketplaceApiClient.customer.getCustomerForOrderId(arg),
                () => context.marketplaceApiClient.productManagement.getProducts()
            );
        },
        onFulfilled: (state, action) => {
            state.salesOrder = action.payload.result.result1.salesOrder;            
            salesOrderItemAdapter.setAll(state.salesOrderItems, action.payload.result.result1.salesOrderItems);
            orderInventoryAdapter.setAll(state.orderInventory, action.payload.result.result1.inventory);

            state.customer = action.payload.result.result2;            
            state.products = action.payload.result.result3;               
            state.productMap = createIdentityMap(action.payload.result.result3, product => product.id);       
        }
    });