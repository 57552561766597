import { ColumnProps } from "primereact/column";
import { CSSProperties } from "react";
import { createIdentityMap } from "../../../../../utils/identity";
import { isString, isValue } from "../../../../../utils/valueHelper";
import { TableState, ViewDefinition } from "../types";

export const getColumnId = (col: ColumnProps) => {
    const columnId = col.columnKey ?? col.field;
    if (!isValue(columnId)) {
        throw new Error(`CustomTable requires that all columns have a ColumnKey or Field property set.`);
    }
    return columnId;
};
export const getColumnWidth = (col: ColumnProps) => {
    const columnId: CSSProperties | undefined | null = col.style as CSSProperties | undefined | null;
    return columnId?.width;
};
export const getColumnHeaderText = (col: ColumnProps) => {
    return isString(col.header) ? col.header : getColumnId(col);
};

export const mergeViewDefintionWithTableState = (viewDefinitions: ViewDefinition, tableState: TableState) => {
    // Add new columns into the TableState
    viewDefinitions
        .columnState
        .forEach(currentColumn => {

            const matchingColumn = tableState
                .columnState
                .find(storedColumn => storedColumn.columnId === currentColumn.columnId);

            if (!isValue(matchingColumn)) {
                tableState.columnState.push({ ...currentColumn, isVisible: false });
            }
        });

    // Find removed columns
    const removedColumns = tableState
        .columnState
        .filter(column => !isValue(viewDefinitions
            .columnState
            .find(definition => definition.columnId === column.columnId)));
    const removedColumnsMap = createIdentityMap(removedColumns, column => column.columnId);



    // Remove columns no longer in the defintion
    tableState.columnState = tableState
        .columnState
        .filter(column => !isValue(removedColumnsMap[column.columnId]));

    // Remove filters that reference removed columns 
    if (isValue(tableState.additionalSettings)) {
        const storedState = tableState.additionalSettings;
        Object
            .keys(storedState.filters)
            .forEach(key => {
            if (isValue(removedColumnsMap[key])) {                
                delete storedState.filters[key];
            }
        });
    }

    // Merge Filters
    const definitionTableState = viewDefinitions.tableState;

    if (isValue(definitionTableState) && isValue(tableState.additionalSettings)) {

        // Add new filters into the TableState
        const storedState = tableState.additionalSettings;
        Object.keys(definitionTableState.filters).forEach(key => {
            if (!isValue(storedState.filters[key])) {
                storedState.filters[key] = definitionTableState.filters[key];
            }
        })

        tableState.additionalSettings = storedState;
    }
}