import { menuSectionAdapter } from "../state";
import { actionFactory, MenuSection } from "../types";

export const {

    resequenceMenuSection,
    resequenceMenuSectionReducer,
    resequenceMenuSectionSagaFactory

} = actionFactory
    .withMappedName("resequenceMenuSection")
    .createAsyncAction<MenuSection, MenuSection[]>({
        actionStatusSelector: state => state.addSectionSaveState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.menuAdmin.resequenceMenuSection(arg);
        },
        onFulfilled: (state, action) => {
            menuSectionAdapter.setAll(state.menuSections, action.payload.result);
        },
        debounceInMilliseconds: 250
    });