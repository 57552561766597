import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { MenuDashboardState } from './types';

export const createInitialState = (): MenuDashboardState => {
    return {
        loadingState: CreateActionStatusInitialState(),
        addMenuState: CreateActionStatusInitialState(),
        data: []
    };
};

export const initialState: MenuDashboardState = createInitialState();