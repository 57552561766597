import { IReducerFactory } from "../../../../store/factory/interfaces";
import { actionFactory, AppComponentState } from "../types";

export const setUseOverlaySideMenu = actionFactory.createAppAction<boolean>("setUseOverlaySideMenu");

export function setUseOverlaySideMenuReducer(reducerFactory: IReducerFactory<AppComponentState>): IReducerFactory<AppComponentState> {
    return reducerFactory
        .forAction(setUseOverlaySideMenu)
        .addHandler((state, action) => {
            state.useOverlaySideMenu = action.payload;
        })
        .build();
}