import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";

export const sliceName: string = "setup-workflow";

export const actionFactory = new ActionFactory<InitialSetupWorkflowState>(sliceName);

export enum WorkflowStep {
    CompanyInfo,
    UserInfo,
    TermsOfService,
    Complete
}

export interface UserSetupInfo {
    userProfileNeeded: boolean;
    companyProfileNeeded: boolean;
    termsOfServiceNeeded: boolean;
}

export interface UserSetupRequiredState {
    loadingState: ActionStatus;
    userSetupInfo: UserSetupInfo | null;
}

export interface InitialSetupWorkflowState {    
    userSelectedWorkflowStep?: WorkflowStep;
    userSetupRequiredState: UserSetupRequiredState;
    companyProfileIsValid: boolean;
    userProfileIsValid: boolean;
}