import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { ErrorQueueState } from './types';

export const createInitialState = (): ErrorQueueState => {
    return {        
        loadingState: CreateActionStatusInitialState(),      
        errorQueue: [] 
    };
};

export const initialState: ErrorQueueState = createInitialState();