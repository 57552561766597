import { Column, ColumnProps } from "primereact/column";
import React, { CSSProperties } from 'react';
import { getStringOrNullValue, PropertiesOfType, StringOrNullKeyOf } from "../typeHelpers";

export interface TextColumnOptions<T extends PropertiesOfType<T, string | null | undefined>> extends Omit<ColumnProps, "field"> {
    field: StringOrNullKeyOf<T> & string;    
}

export function createTextColumn<T extends PropertiesOfType<T, string | null | undefined>>(props: TextColumnOptions<T>): React.ReactNode {
    return <Column key={props.columnKey ?? props.field} sortable {...props} />;
}

export function createTextColumnProps<T extends PropertiesOfType<T, string | null | undefined>>(props: TextColumnOptions<T>): ColumnProps {
    return {
        columnKey: props.columnKey ?? props.field,
        sortable: true,       
        ...props
    };
}

function createResponsiveTextTemplate<T>(
    getValueFn: (rowData: T) => string | null | undefined,
): (rowData: T, props: ColumnProps) => React.ReactNode {

    return (rowData: T, props: ColumnProps) => {

        const value = getValueFn(rowData);
        return <React.Fragment>
            <span className="responsive-column-header">{props.header}</span>
            {value ?? ""}
        </React.Fragment>
    };
}

export interface ResponsiveTextColumnOptions<T extends PropertiesOfType<T, string | null | undefined>> extends Omit<ColumnProps, "field" | "header" | "body"> {
    field: StringOrNullKeyOf<T> & string;
    header: string;
}

export function createResponsiveTextColumnProps<T extends PropertiesOfType<T, string | null | undefined>>(props: ResponsiveTextColumnOptions<T>): ColumnProps {

    const getValueFn = (rowData: T) => getStringOrNullValue(rowData, props.field);

    return {
        body: createResponsiveTextTemplate(getValueFn),
        ...props
    };
}

export interface FilterTextColumnOptions<T extends PropertiesOfType<T, string | null | undefined>> extends Omit<ColumnProps, "field" | "header" | "style"> {
    field: StringOrNullKeyOf<T> & string;
    header: string;
    style?: CSSProperties;
}

export function createFilteredTextColumnProps<T extends PropertiesOfType<T, string | null | undefined>>(props: FilterTextColumnOptions<T>): ColumnProps {
    return { ...props, filter: true };
}