import { Card } from "primereact/card";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { CompanyType } from "../../../company/profile/store/types";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { getLoadingState } from "../store/selectors/loadingState";
import { resetState } from "../store/slice";
import { CustomerTable } from "./customerTable";

export interface CompanyListParams {
    companyType: CompanyType | undefined;
};

export const CompanyList: React.FC = () => {

    const routeParams = useParams<CompanyListParams>();
    const companyType: CompanyType | undefined = routeParams.companyType;
    
    const dispatch = useDispatch();
    const loadingState = useAppSelector(getLoadingState);

    useEffect(() => {
        if (!isValue(companyType)) return;
        dispatch(loadSliceData({ companyType }));

        return () => {
            dispatch(resetState())
        }
    }, [companyType, dispatch]);

    return <OverlayWaitDisplay
        isWaiting={loadingState.isExecuting}
    >
        <Card className="full-height-content-panel" title="Customer List">
            <CustomerTable />
        </Card>
    </OverlayWaitDisplay>
} 