import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory, ApiKeyVerification, BuyerVerificationState } from "../types";

export const loadSliceData = actionFactory.createAppAction("loadSliceData");

const sagaActions = actionFactory.createSagaWithProgressActions<void, ApiKeyVerification[]>(loadSliceData.type);

export function loadSliceDataReducers(reducerFactory: IReducerFactory<BuyerVerificationState>): IReducerFactory<BuyerVerificationState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.loadingState)
        .onFulfilled((state, action) => {
            state.verifications = action.payload.result;            
        })
        .build();
}

async function performLoad(_arg: void, _context: SagaAppContext): Promise<ApiKeyVerification[]> {   
    return _context.marketplaceApiClient.identityVerification.getCurrentApiKeyVerifications();
}

export function createLoadSliceDataSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: loadSliceData.type,
        debounceInMilliseconds: 250
    });
};