import { useDispatch } from "react-redux";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { Company } from "../../../company/profile/store/types";
import { AddCompanyEditor } from "./addCompanyEditor";
import { getCompanyEditState, getCompanyEditValue, getCompanyEditVisible } from "../store/selectors";
import { companyValidationSchema } from "../validations/companyValidations";
import { addCompanyCancel, addCompanyComplete } from "../store/featureActions/addCompany";

export const AddCompanyDialog: React.FC = () => {

    const dispatch = useDispatch();

    const onSave = (values: Company): void => {
        dispatch(addCompanyComplete(values));
    };

    const onCancel = (): void => {
        dispatch(addCompanyCancel());
    }

    return <FormikModalEditor<Company>
        statusSelector={getCompanyEditState}
        valueSelector={getCompanyEditValue}
        visibleSelector={getCompanyEditVisible}
        onSave={onSave}
        onCancel={onCancel}
        validationSchema={companyValidationSchema}
        title="Add Company"        
    >
        <AddCompanyEditor />
    </FormikModalEditor>
}