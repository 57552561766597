import { DateTime } from "luxon";
import { Inventory, InventoryType } from "../features/inventorySetup/inventoryManagement/store/types";


export const createInitInventory = () : Inventory => {
    const inventory: Inventory = {
        id: 0,
        tenantId: "",
        productId: null,
        licenseId: 0,
        menuThumbnailId: null,
        licenseNumber: "",
        metrcName: null,
        metrcStrainName: null,
        metrcPackageId: null,
        metrcPackageLabel: "",
        metrcPackageType: null,
        quantity: 0,
        unitOfMeasureName: "",
        marketingName: "",
        marketingStrainName: "",
        marketingDescription: "",
        thcPercentage: null,
        cbdPercentage: null,
        unitPrice: null,
        packagedDate: DateTime.utc(),
        inactiveDate: null,
        readyDate: null,
        excludeFromMarket: false,
        reservedQuantity: null,
        qualityGradeId: null,
        qualityAssessmentId: null,
        inventoryType: InventoryType.Custom
    }
    return inventory;    
}