import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { Menu } from "../../editor/store/types";

export const sliceName: string = "menu-dashboard";

export const actionFactory = new ActionFactory<MenuDashboardState>(sliceName);

export interface MenuDashboardState {
    loadingState: ActionStatus;  
    addMenuState: ActionStatus;
    data: Menu[];      
}