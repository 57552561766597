import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { loadLiveMenuSagaFactory } from "../featureActions/loadLiveMenu";
import { createLoadSectionInventorySaga } from "../featureActions/loadSectionInventory";
import { loadVendorSagaFactory } from "../featureActions/loadVendor";
import { loadVendorBannerSagaFactory } from "../../../vendorTopPanel/store/featureActions/loadVendorBanner";
import { loadVendorLogoSagaFactory } from "../../../vendorTopPanel/store/featureActions/loadVendorLogo";
import { loadIsFavoriteSagaFactory } from "../featureActions/loadIsFavorite";
import { addToFavoritesSagaFactory } from "../featureActions/addToFavorites";
import { removeFromFavoritesSagaFactory } from "../featureActions/removeFromFavorites";
import { createRefreshInventorySaga } from "../featureActions/refreshInventory";
import { setRefreshIntervalSagaFactory } from "../featureActions/setRefreshInterval";
import { loadInventoryThumbnailSagaFactory } from "../featureActions/loadInventoryThumbnail";
import { loadSectionThumbnailsSagaFactory } from "../featureActions/loadSectionThumbnails";
import { loadLicensesSagaFactory } from "../featureActions/loadLicenses";
import { loadDefaultThumbnailSagaFactory } from "../featureActions/loadDefaultThumbnail";
 
// Register this method in the root saga
export function createLiveMenuSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        loadLiveMenuSagaFactory(context),      
        loadVendorLogoSagaFactory(context),
        loadVendorBannerSagaFactory(context),        
        createLoadSectionInventorySaga(context),        
        loadVendorSagaFactory(context),
        loadIsFavoriteSagaFactory(context),
        addToFavoritesSagaFactory(context),
        removeFromFavoritesSagaFactory(context),
        setRefreshIntervalSagaFactory(context),
        createRefreshInventorySaga(context),
        loadInventoryThumbnailSagaFactory(context),
        loadSectionThumbnailsSagaFactory(context),
        loadLicensesSagaFactory(context),
        loadDefaultThumbnailSagaFactory(context)
    ]
}