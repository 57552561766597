import { EntityState } from "@reduxjs/toolkit";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus, EntityActionStatus } from "../../../actionStatus/actionStatus";
import { QualityGradeKind } from "../../../qualityGradeTemplates/models/gradeKind";
import { QualityGradeValue } from "../../../qualityGradeTemplates/models/gradeValue";
import { PricingModel, PricingModelGrade } from "../../sharedTypes";

export const sliceName: string = "pricing-model-editor";

export const actionFactory = new ActionFactory<PriceModelEditorState>(sliceName);

export interface PriceModelEditorState {
    loadingState: ActionStatus;  
    priceModel: PricingModel | null;          
    pricingModelGrades: EntityState<PricingModelGrade>;
    pricingModelSaveStates: EntityState<EntityActionStatus>;    
    gradeKinds: EntityState<QualityGradeKind>;
    gradeValues: EntityState<QualityGradeValue>
}