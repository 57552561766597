//import { DataTable } from "primereact/datatable";
import { ColumnProps } from "primereact/column";
import { SplitButton } from 'primereact/splitbutton';
import React from 'react';
import { isValue } from "../valueHelper";
import { mapToMenuItem, TypedMenuItem } from "./typedMenuItem";

export interface MultiActionColumnOptions<T> extends Omit<ColumnProps, "field" | "header" | "body" | "columnKey"> {        
    columnKey: string;
    header: string,
    icon?: string,
    disabled?: (value: T) => boolean,
    actionName: string,
    actionHandler: (value: T, event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    menuClassName?: string,
    menuItems?: TypedMenuItem<T>[]
}

interface MultiActionTemplateProps<T> {
    options: MultiActionColumnOptions<T>,
    value: T
}

const MultiActionTemplate = <T,>({ options, value }: MultiActionTemplateProps<T>) => {
    const buttonId: string = `${options.columnKey}-${options.header}`;

    const onInvokeAction = (event: React.MouseEvent<HTMLElement, MouseEvent>) => options.actionHandler(value, event);
    const isDisabled = options.disabled !== undefined ? options.disabled(value) : false;
    const menuModel = options
        .menuItems?.filter(menuItem => !isValue(menuItem.visible) || menuItem.visible(value))
        .map(menuItem => mapToMenuItem(menuItem, value, buttonId));

    return <SplitButton
        id={buttonId}
        icon={options.icon}
        className="p-button-sm"
        label={options.actionName}
        disabled={isDisabled}
        menuClassName={options.menuClassName}
        model={menuModel}
        onClick={onInvokeAction}
    />
}

function createMultiActionTemplate<T>(options: MultiActionColumnOptions<T>): (rowData: T) => React.ReactNode {
    return (value: T) => {
        return <MultiActionTemplate options={options} value={value} />
    }
}

export function createMultiActionColumnProps<T>(
    props: MultiActionColumnOptions<T>
): ColumnProps {
    return {        
        body: createMultiActionTemplate(props),
        ...props
    };
}

