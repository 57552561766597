import ApiClient from "../../../apiCommunication/apiClient";
import { UpsertTableSettingsRequest, UserTableSettings } from "../store/types";


export default class UserTableSettingsApi {

    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    getUserTableSettings = (): Promise<UserTableSettings[]> => {
        return this
            .apiClient
            .executeGet<UserTableSettings[]>(`UserTableSettings`);
    };

    upsertUserTableSettings = (upsertRequest: UpsertTableSettingsRequest): Promise<UserTableSettings> => {
        return this
            .apiClient
            .executePostWithResult<UserTableSettings>(`UserTableSettings`, upsertRequest);
    };

    deleteUserTableSettings = (id: number): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`UserTableSettings/${id}`);
    };
}