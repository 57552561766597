import { useDispatch } from "react-redux";
import { FeatureContentPanel } from "../../../../components/containers/featureContentPanel";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { getLoadingState } from "../store/selectors/loadingState";
import { resetState } from "../store/slice";
import { CommandBar } from "./commandBar";
import { QualityTemplateTable } from "./qualityTemplateTable";
import './index.css';
import { AddTemplateDialog } from "./addTemplateDialog";
import { clearQualityReferenceData } from "../../../qualityAssessments/assessmentEditor/store/featureActions/clearQualityReferenceData";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { ConfirmDialog } from "primereact/confirmdialog";

export const QualityAssessmentTemplateDashboard: React.FC = () => {

    const dispatch = useDispatch();

    useMountEffect(() => {
        dispatch(loadSliceData());

        return () => {
            dispatch(resetState());
            dispatch(clearQualityReferenceData());
        }
    });

    return <FeatureContentPanel
        loadingStateSelector={getLoadingState}
        title="Quality Assessment Templates"
    >
        <AddTemplateDialog />
        <ConfirmDialog />
        
        <FlexColumnContainer className="quality-template-dashboard">

            <CommandBar />

            <QualityTemplateTable />

        </FlexColumnContainer>

    </FeatureContentPanel>
} 