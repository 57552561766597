import { isValue } from "../../../../utils/valueHelper";
import { loadCustomerProfile } from "../../../company/profile/store/featureActions/loadCustomerProfile";
import { loadMarketplaceProfile } from "../../../marketplaceUserProfile/manageProfile/store/featureActions/loadProfile";
import { getAuthFromStorage, isTokenExpired } from "../../helper";
import { CurrentUserState, actionFactory } from "../types";


export const {
    loadExistingAuth,
    loadExistingAuthReducer,
    loadExistingAuthSagaFactory
} = actionFactory
    .withMappedName("loadExistingAuth")
    .createAsyncAction<void, CurrentUserState | null>({
        actionStatusSelector: state => state.LoadAuthState,
        asyncCall: (_arg, _context) => {
            const userAuthFromStorage = getAuthFromStorage();
            return Promise.resolve(userAuthFromStorage);            
        },
        onFulfilled: (state, action) => {
            const userAuth = action.payload.result;
            if (isValue(userAuth) && !isTokenExpired(userAuth.accessTokenData)) {
                state.CurrentUser = action.payload.result;
            }
        },
        successCallback: (_arg, result, context) => {
            if (isValue(result) && !isTokenExpired(result.accessTokenData)) {
                context.dispatch(loadMarketplaceProfile());
                context.dispatch(loadCustomerProfile())    
            }
        }
    });

// export const loadExistingAuth = createAction(actionFactory.createActionName("loadExistingAuth"));

// export function loadExistingAuthReducer(reducerFactory: IReducerFactory<UserAuthState>): IReducerFactory<UserAuthState> {
//     return reducerFactory
//         .forAction(loadExistingAuth)
//         .addHandler((state, _) => {
//             const userAuthFromStorage = getAuthFromStorage();

//             if (isValue(userAuthFromStorage) && !isTokenExpired(userAuthFromStorage.accessTokenData)) {
//                 state.CurrentUser = userAuthFromStorage;
//             }
//         })
//         .build();
// }