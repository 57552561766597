import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { SyncRequestDto } from "../../../../commonModels/syncRequest";
import { actionFactory, SyncAdminLicenseState } from "../types";

export const requestLicenseSync = actionFactory.createAppAction("requestLicenseSync");

const sagaActions = actionFactory.createSagaWithProgressActions<void, SyncRequestDto>(requestLicenseSync.type);

export function requestLicenseSyncReducers(reducerFactory: IReducerFactory<SyncAdminLicenseState>): IReducerFactory<SyncAdminLicenseState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)     
        .withActionStatus(state => state.requestLicenseSyncState)           
        .onFulfilled((state, action) => {
            state.latestSyncLicenseRequest = action.payload.result;            
        })
        .build();
}

async function performAction(_: void, context: SagaAppContext): Promise<SyncRequestDto> {
    return context.marketplaceApiClient.syncAdmin.requestLicenseSync();    
}

export function createRequestLicenseSyncSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: requestLicenseSync.type,
        debounceInMilliseconds: 250
    });
};