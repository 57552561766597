import { inventoryAdapter } from "../../state";
import { actionFactory, Inventory} from "../../types";

export const {
    addInventoryBegin,
    addInventoryCancel,
    addInventoryComplete,
    addInventoryReducers,
    addInventorySagaFactory
} = actionFactory
    .withMappedName("addInventory")
    .createModal<Inventory, Inventory, Inventory>({
        modelFactory: (arg, _state) => arg,
        onCompleteEdit: (value, context) => {
            return context.marketplaceApiClient.inventoryManagement.addInventory(value);
        },
        onFulfilled: (state, action) => {
            inventoryAdapter.upsertOne(state.inventory, {...action.result, orderedQuantity: 0});
        },
        selector: state => state.addInventoryState
    });

    