import { RootState } from "../../../../../store/rootReducer";
import { metricKindAdapter, metricValueAdapter } from "../state";

const metricKindSelectors = metricKindAdapter.getSelectors((state: RootState) => state.qualityMetricKinds.metricKinds)
export const getMetricKindEntities = metricKindSelectors.selectEntities;
export const getMetricKinds = metricKindSelectors.selectAll;

const metricValueSelectors = metricValueAdapter.getSelectors((state: RootState) => state.qualityMetricKinds.metricValues)
export const getMetricValues = metricValueSelectors.selectAll;



