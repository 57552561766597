import { actionFactory } from "../../types";

export const {

    setNewThumbnail,
    setNewThumbnailReducer
    
} = actionFactory
    .withMappedName("setNewThumbnail")
    .createAction<string>((state, action) => {
        state.thumbnailState.newImagePreview = action.payload;
    });