import { ResequenceRequest } from "../../../../pricing/sharedTypes";
import { QualityGradeValue } from "../../../models/gradeValue";
import { gradeValueAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    resequenceGradeValue,
    resequenceGradeValueReducer,
    resequenceGradeValueSagaFactory

} = actionFactory
    .withMappedName("resequenceGradeValue")
    .createAsyncAction<ResequenceRequest, QualityGradeValue[]>({
        actionStatusSelector: (state) => state.gradeValueSaveState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.quality.resequenceQualityGradeValue(arg.entityId, arg.newSequenceValue);
        },       
        onFulfilled: (state, action) => {
            gradeValueAdapter.upsertMany(state.gradeValues, action.payload.result);
        }
    });