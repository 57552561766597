import { CustomerNote } from "../../../common/types";
import { customerNoteAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    deleteCustomerNote,
    deleteCustomerNoteReducer,
    deleteCustomerNoteSagaFactory

} = actionFactory
    .withMappedName("deleteCustomerNote")
    .createAsyncAction<CustomerNote, Response>({    
    actionStatusSelector: state => state.loadingState,
    asyncCall: (arg, context) => {
       return context.marketplaceApiClient.customer.deleteCustomerNote(arg);
    },
    onFulfilled: (state, action) => {
       customerNoteAdapter.removeOne(state.customerNotes, action.payload.arg.id);
    }
});