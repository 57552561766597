import { useState } from 'react';
import { FlipCard, FlipCardFace } from '../../../../components/containers/flipCard';
import { InventoryGridCard } from './inventoryGridCard';
import './inventoryFlipCard.css';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../store/configureStore';
import { TypedMenuItem } from '../../../../utils/table/typedMenuItem';
import { getInventoryById, getInventoryImageById } from '../store/selectors/inventory';
import { getPrimaryColor, getSecondaryColor } from '../store/selectors/menuColors';
import { getPrimaryFont, getSecondaryFont } from '../store/selectors/menuFonts';
import { getVendorRouteName, getMenuUrlName, getUserOwnsMenu } from '../store/selectors/vendorMenu';
import { InventoryView } from '../store/types';
import { createMenuItems } from './inventoryCommands';
import { useDispatch } from 'react-redux';

export interface InventoryFlipCardProps {
    inventoryId: number;
}

export const InventoryFlipCard: React.FC<InventoryFlipCardProps> = ({ inventoryId }) => {
    
    const dispatch = useDispatch();
    const history = useHistory();
    const inventory = useAppSelector(state => getInventoryById(state, inventoryId));
    const image = useAppSelector(state => getInventoryImageById(state, inventoryId));
    const vendorRouteName = useAppSelector(getVendorRouteName);
    const menuUrlName = useAppSelector(getMenuUrlName);
    const menuItems: TypedMenuItem<InventoryView>[] = createMenuItems(dispatch, history, vendorRouteName, menuUrlName);
    const primaryColor = useAppSelector(getPrimaryColor);
    const secondaryColor = useAppSelector(getSecondaryColor);
    const primaryFont = useAppSelector(getPrimaryFont);
    const secondaryFont = useAppSelector(getSecondaryFont);
    const userOwnsMenu = useAppSelector(getUserOwnsMenu);

    const [currentCardFace, setCurrentCardFace] = useState<FlipCardFace>(FlipCardFace.Front);

    const onChangeCardFace = () => {
        setCurrentCardFace(currentCardFace === FlipCardFace.Front ? FlipCardFace.Back : FlipCardFace.Front);
    }

    const card = <InventoryGridCard
        key={inventoryId}
        inventory={inventory}
        image={image}
        menuItems={menuItems}
        primaryColor={primaryColor}
        primaryFont={primaryFont}
        secondaryColor={secondaryColor}
        secondaryFont={secondaryFont}
        userOwnsMenu={userOwnsMenu}
        onMoreInfoClick={onChangeCardFace}
    />;

    return <FlipCard
        className='node-inv-flip-card'
        key={inventoryId}
        frontCard={card}
        backCard={card}
        currentFace={currentCardFace}
    >
    </FlipCard>

}