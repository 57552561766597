import { IntegerId } from "../commonModels/integerId"
import { ErrorContent } from "../errorHandling/types"

export interface ErrorResult {
    errorContent?: ErrorContent
}

export function CreateErrorResultInitialState() : ErrorResult {
    return {}
}

export interface ActionStatus extends ErrorResult {
    isExecuting: boolean,
    hasExecuted?: boolean
}

export function CreateActionStatusInitialState() : ActionStatus {
    return {
        isExecuting: false,        
        hasExecuted: false
    }
}

export interface ActionWithResult<TResult> extends ActionStatus {
    result: TResult | null
}

export function CreateActionWithResultInitialState<TResult>(): ActionWithResult<TResult> {
    return {
        isExecuting: false,        
        result: null,
        hasExecuted: false
    }
}

export interface EntityActionStatus extends ActionStatus, IntegerId {
    
}
