import { EntityVideo } from "../../../../../commonModels/entityVideo";
import { productVideoAdapter } from "../../state";
import { actionFactory } from "../../types";

export const {
    
    addVideoBegin,
    addVideoCancel,
    addVideoComplete,
    addVideoReducers,
    addVideoSagaFactory

} = actionFactory
    .withMappedName("addVideo")
    .createModal<EntityVideo, EntityVideo, EntityVideo>({
        modelFactory: (arg, _state) => arg,
        selector: (state) => state.addVideoState,
        onCompleteEdit: (value, context) => {
            return context.marketplaceApiClient.productManagement.upsertProductVideo(value);
        },
        onFulfilled: (state, action) => {
            productVideoAdapter.upsertOne(state.editVideos, action.result);
        }
    });