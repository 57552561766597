import { QualityAssessment } from "../../../models/qualityAssessment";
import { qualityAssessmentAdapter, saveAssesmentStateAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    reopenAssessment,
    reopenAssessmentReducer,
    reopenAssessmentSagaFactory

} = actionFactory
    .withMappedName("reopenAssessment")
    .createAsyncAction<number, QualityAssessment>({
        actionStatusSelector: {
            adapter: saveAssesmentStateAdapter,
            adapterStateSelector: state => state.saveAssessmentState,
            getId: (_state, arg) => arg
        },
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.quality.reopenAssessment(arg);
        },
        onFulfilled: (state, action) => {            
            qualityAssessmentAdapter.upsertOne(state.assessments, action.payload.result);
        }
    });