import { RootState } from "../../../../../store/rootReducer";
import { salesOrderItemAdapter } from "../state";

export const getOrderItemSaveState = (state: RootState) => state.orderEditor.orderItemEditState;

export const getEditOrderItemVisible = (state: RootState) => state.orderEditor.orderItemEditState.isVisible;

export const getEditedOrderItem = (state: RootState) => state.orderEditor.orderItemEditState.editedValue;

const orderItemSelectors = salesOrderItemAdapter.getSelectors((state: RootState) => state.orderEditor.salesOrderItems);

export const getOrderItems = orderItemSelectors.selectAll;