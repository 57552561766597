import { isValue } from "../../../../../../utils/valueHelper";
import { ImageKind } from "../../../../../commonModels/imageTypes";
import { actionFactory } from "../../types";
import { loadLogo } from "./loadLogo";

export const {

    saveLogo,
    saveLogoReducer,
    saveLogoSagaFactory

} = actionFactory
    .withMappedName("saveLogo")
    .createAsyncAction<void, Response>({
        actionStatusSelector: state => state.logoState.saveState,
        asyncCall: async (_arg, context) => {
            const state = context.getState();

            const companyId = state.companyProfile.companyProfile.id;
            if (!isValue(companyId)) throw new Error("Cannot save image without valid company instance");

            if (state.companyProfile.logoState.newImagePreview !== undefined) {
                const blob = await fetch(state.companyProfile.logoState.newImagePreview).then(localFile => localFile.blob());
                return context.marketplaceApiClient.company.uploadImage(companyId, blob, ImageKind.Logo);
            }

            throw new Error("Attempt to save logo without new image specified")
        },
        onFulfilled: (state, _action) => {
            state.logoState.newImagePreview = undefined;
        },
        successCallback: (_arg, _result, context) => {
            const companyId = context.getState().companyProfile.companyProfile?.id;
            if (!isValue(companyId)) throw new Error("Menu instance was not valid");
            context.dispatch(loadLogo({ companyId: companyId }));
        }
    });