import { Load2Result, parallelLoad } from "../../../../../utils/promiseHelpers";
import { Menu } from "../../../../menu/editor/store/types";
import { CompanyProfile } from "../../../profile/store/types";
import { menuAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    loadSliceData,
    loadSliceDataReducer,
    loadSliceDataSagaFactory

} = actionFactory
    .withMappedName("loadSliceData")
    .createAsyncAction<{ companyId: number }, Load2Result<CompanyProfile, Menu[]>>({        
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {
            return parallelLoad(
                () => context.marketplaceApiClient.company.getCompanyMarketingView(arg.companyId),
                () => context.marketplaceApiClient.liveMenu.getVendorMenus(arg.companyId));
        },
        onFulfilled: (state, action) => {
            state.companyProfile = action.payload.result.result1;
            menuAdapter.setAll(state.menus, action.payload.result.result2);
        }
        
    });