import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { clearAssessmentStateReducer } from './featureActions/clearAssessmentState';
import { clearQualityReferenceDataReducer } from './featureActions/clearQualityReferenceData';
import { completeAssessmentReducer } from './featureActions/completeAssessment';
import { deleteAssessmentValueReducer } from './featureActions/deleteAssessmentValue';
import { editQualityAssessmentReducers } from './featureActions/editQualityAssessment';
import { loadReferenceDataReducer } from './featureActions/loadReferenceData';
import { loadSliceDataReducer } from './featureActions/loadSliceData';
import { reopenAssessmentReducer } from './featureActions/reopenAssessment';
import { saveAssessmentValueReducer } from './featureActions/saveAssessmentValue';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(loadSliceDataReducer)
            .add(loadReferenceDataReducer)
            .add(clearAssessmentStateReducer)
            .add(clearQualityReferenceDataReducer)
            .add(saveAssessmentValueReducer)
            .add(deleteAssessmentValueReducer)
            .add(completeAssessmentReducer)
            .add(reopenAssessmentReducer)
            .addRange(editQualityAssessmentReducers);
            // Create your feature actions in ./featureActions and then add them to the reducer factory here
            // with .Add(<your-action-reducer-name>);
            
    }
});

export default slice.reducer;

export const { resetState } = slice.actions;