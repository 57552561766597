import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { cancelEditTermsOfServiceReducers } from './featureActions/cancelEditTermsOfService';
import { completeEditTermsOfServiceReducers } from './featureActions/completeEditTermsOfService';
import { editTermsOfServiceReducers } from './featureActions/editTermsOfService';
import { inactivateTermsOfServiceReducers } from './featureActions/inactivateTermsOfService';
import { loadSliceDataReducers } from './featureActions/loadSliceData';
import { publishTermsOfServiceReducers } from './featureActions/publishTermsOfService';
import { searchStatesReducers } from './featureActions/searchStates';
import { setInactiveFilterReducers } from './featureActions/setInactiveFilter';
import { setStateReducers } from './featureActions/setState';
import { setUploadTermsDocumentReducers } from './featureActions/setUploadTermsDocument';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(loadSliceDataReducers)
            .add(searchStatesReducers)
            .add(editTermsOfServiceReducers)
            .add(cancelEditTermsOfServiceReducers)
            .add(completeEditTermsOfServiceReducers)
            .add(inactivateTermsOfServiceReducers)
            .add(publishTermsOfServiceReducers)
            .add(setInactiveFilterReducers)
            .add(setUploadTermsDocumentReducers)
            .add(setStateReducers);
            // Create your feature actions in ./featureActions and then add them to the reducer factory here
            // with .Add(<your-action-reducer-name>);
            
    }
});

export default slice.reducer;

export const { resetState } = slice.actions;