import { DateTime } from "luxon";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";

export const sliceName: string = "terms-of-service-admin";

export const actionFactory = new ActionFactory<TermsOfServiceAdminState>(sliceName);

export interface TermsOfService {
    id: number;
    termsAndConditions: string;
    createdBy: string;
    createdUtc: string;
    updatedBy: string;
    updatedUtc: string;
    stateAbbreviation: string;
    publishedUtc: DateTime | null;
    inactivatedUtc: DateTime | null;
}

export interface TermsOfServiceUpsert {    
    termsAndConditions: string;
    stateAbbreviation: string;
}

export interface TermsFilter {
    inactiveFilterValues: boolean[]
}

export interface EditState {
    saveState: ActionStatus;    
    preview?: string;   
    stateAbbreviation: string; 
}

export interface TermsOfServiceAdminState {
    loadingState: ActionStatus;  
    termsOfService: TermsOfService[];     
    stateSearchResults: string[]; 
    editedTermsOfServiceId?: number | null;
    saveState: ActionStatus;
    termsFilter: TermsFilter;
    editState: EditState;
}