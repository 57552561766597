import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../../store/rootSaga";
import { CreateActionStatusInitialState } from "../../../../../actionStatus/actionStatus";
import { BulkCategoryClearRequest } from "../../../../general/models/bulkCategoryRequests";
import { actionFactory, ProductManagementState } from "../../types";
import { loadMenuAssignments } from "../loadMenuAssignments";

export const completeBulkClearCategoryAssignment = actionFactory.createAppAction("completeBulkClearCategoryAssignment");

const sagaActions = actionFactory.createSagaWithProgressActions<void, Response>(completeBulkClearCategoryAssignment.type);

export function completeBulkClearCategoryAssignmentReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.bulkClearCategoryAssignmentState.savingState)
        .onFulfilled((state, _) => {
            state.bulkClearCategoryAssignmentState = {                
                savingState: CreateActionStatusInitialState(),
                products: []
            };
        })
        .build();
}

async function performLoad(_: void, context: SagaAppContext) {
    const state = context.getState();
    const clearCommand: BulkCategoryClearRequest = {
        entityIds: state.productManagement.bulkClearCategoryAssignmentState.products.map(p => p.id),
    };
    return context.marketplaceApiClient.productManagement.clearInventoryCategories(clearCommand);

}

export function createCompleteBulkClearCategoryAssignmentSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions,
            successCallback: (_arg, _result, context) => context.dispatch(loadMenuAssignments())
        },
        actionPattern: completeBulkClearCategoryAssignment.type,
        debounceInMilliseconds: 250
    });
};