import { createEntityAdapter } from '@reduxjs/toolkit';
import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { Category } from '../../categoryManagement/store/types';
import { MenuAdminState, MenuSection } from './types';

export const menuSectionAdapter = createEntityAdapter<MenuSection>();
export const categoryAdapter = createEntityAdapter<Category>();

export const createInitialState = (): MenuAdminState => {
    return {
        loadingState: CreateActionStatusInitialState(),
        menu: null,
        menuSections: menuSectionAdapter.getInitialState(),
        menuSectionLoadState: CreateActionStatusInitialState(),
        addSectionSaveState:  CreateActionStatusInitialState(),
        addCategorySaveState: CreateActionStatusInitialState(),
        categories: categoryAdapter.getInitialState(),
        categoriesLoadState: CreateActionStatusInitialState(),
        useSmallMenuSectionView: false,        
        logoState: {
            loadState: CreateActionStatusInitialState(),
            saveState: CreateActionStatusInitialState(),
            currentImage: null
        },
        bannerState: {
            loadState: CreateActionStatusInitialState(),
            saveState: CreateActionStatusInitialState(),
            currentImage: null
        },
        thumbnailState: {
            loadState: CreateActionStatusInitialState(),
            saveState: CreateActionStatusInitialState(),
            currentImage: null
        }
    };
};

export const initialState: MenuAdminState = createInitialState();