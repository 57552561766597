import { actionFactory } from "../types";

export const {

    clearTestAssessmentValue,
    clearTestAssessmentValueReducer

} = actionFactory
    .withMappedName("clearTestAssessmentValue")
    .createAction<{qualityMetricKindId: number}>((state, action) => {
        state.testAssessmentEntries = state.testAssessmentEntries.filter(entry => entry.qualityMetricKindId !== action.payload.qualityMetricKindId);
    });