import { Button } from 'primereact/button';
import { InputSwitch, InputSwitchChangeParams } from 'primereact/inputswitch';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/configureStore';
import { addSectionToMenu } from '../store/featureActions/addSectionToMenu';
import { setUseSmallMenuSectionView } from '../store/featureActions/setUseSmallMenuSectionView';
import { getUseSmallMenuSectionView } from '../store/selectors/menuSections';

export const MenuActionsToolbar: React.FC = () => {

    const dispatch = useDispatch();
    const useSmallMenuSectionView = useAppSelector(getUseSmallMenuSectionView);

    const onAddNewSection = () => {
        dispatch(addSectionToMenu());
    }

    const onUseCompactViewChanged = (e: InputSwitchChangeParams) => {
        dispatch(setUseSmallMenuSectionView(e.value));
    }
    return <div className="p-d-flex p-ai-center p-flex-wrap">
        <Button label="Add" icon="pi pi-plus" className="p-mr-2" onClick={onAddNewSection} />
        <div>Compact View</div>
        <InputSwitch style={{ marginLeft: "5px" }} onChange={onUseCompactViewChanged} checked={useSmallMenuSectionView} />
    </div>;

}