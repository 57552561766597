import { SecurityRolePredicate } from "../navigation/routes/types";


export enum SecurityRole {
    Anonymous = "Anonymous",
    NodeAdmin = "NodeAdmin",
    TenantAdmin = "TenantAdmin",
    Supplier = "Wholesale-Supplier",
    SupplierAdmin = "Wholesale-SupplierAdmin",
    Distributor = "Wholesale-Distributor",
    DistributorAdmin = "Wholesale-DistributorAdmin",
    Retail = "Wholesale-Retail",
    RetailAdmin = "Wholesale-RetailAdmin"
}

export const isSecurityRole = (value: string) : value is SecurityRole => {
    return value === SecurityRole.Anonymous ||
    value === SecurityRole.NodeAdmin ||
    value === SecurityRole.TenantAdmin ||
    value === SecurityRole.Supplier ||
    value === SecurityRole.SupplierAdmin ||
    value === SecurityRole.Distributor ||
    value === SecurityRole.DistributorAdmin ||
    value === SecurityRole.Retail ||
    value === SecurityRole.RetailAdmin;
}

export type RoleNamesType = keyof typeof SecurityRole;

export const IsTenantAdmin: SecurityRolePredicate = roles => roles.includes(SecurityRole.TenantAdmin);

export const IsSeller: SecurityRolePredicate = roles => 
    roles.includes(SecurityRole.Supplier) || 
    roles.includes(SecurityRole.Distributor) ||
    roles.includes(SecurityRole.SupplierAdmin) || 
    roles.includes(SecurityRole.DistributorAdmin);

export const IsSellerAdmin: SecurityRolePredicate = roles => 
    roles.includes(SecurityRole.SupplierAdmin) || 
    roles.includes(SecurityRole.DistributorAdmin);

export const IsBuyer: SecurityRolePredicate = roles => 
    roles.includes(SecurityRole.Retail) || 
    roles.includes(SecurityRole.Distributor) ||
    roles.includes(SecurityRole.RetailAdmin) || 
    roles.includes(SecurityRole.DistributorAdmin);

export const IsBuyerAdmin: SecurityRolePredicate = roles => 
    roles.includes(SecurityRole.RetailAdmin) || 
    roles.includes(SecurityRole.DistributorAdmin);

export const IsSupplier: SecurityRolePredicate = roles => 
    roles.includes(SecurityRole.Supplier) || 
    roles.includes(SecurityRole.SupplierAdmin);

export const IsDistributor: SecurityRolePredicate = roles => 
    roles.includes(SecurityRole.Distributor) || 
    roles.includes(SecurityRole.DistributorAdmin);

export const IsRetail: SecurityRolePredicate = roles => 
    roles.includes(SecurityRole.Retail) || 
    roles.includes(SecurityRole.RetailAdmin);

export const IsNonDistributor: SecurityRolePredicate = roles => 
    roles.includes(SecurityRole.Supplier) || 
    roles.includes(SecurityRole.SupplierAdmin) ||
    roles.includes(SecurityRole.Retail) || 
    roles.includes(SecurityRole.RetailAdmin);;

export const IsNodeAdmin: SecurityRolePredicate = roles => roles.includes(SecurityRole.NodeAdmin);

export const IsWholesaleUser: SecurityRolePredicate = roles => 
    roles.includes(SecurityRole.Supplier) || 
    roles.includes(SecurityRole.Distributor) ||
    roles.includes(SecurityRole.Retail) || 
    roles.includes(SecurityRole.SupplierAdmin) || 
    roles.includes(SecurityRole.DistributorAdmin) ||
    roles.includes(SecurityRole.RetailAdmin);

export const IsWholesaleAdmin: SecurityRolePredicate = roles => 
    roles.includes(SecurityRole.SupplierAdmin) || 
    roles.includes(SecurityRole.DistributorAdmin) ||
    roles.includes(SecurityRole.RetailAdmin);