import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { ImageDto } from "../../../commonModels/imageTypes";

export const sliceName: string = "vendor-top-panel";

export const actionFactory = new ActionFactory<VendorTopPanelState>(sliceName);

export interface VendorTopPanelState {
    vendorLogoLoadingState: ActionStatus;   
    vendorLogo: ImageDto | null;

    vendorBannerLoadingState: ActionStatus;   
    vendorBanner: ImageDto | null;
}