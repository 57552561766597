import { Load2Result, parallelLoad } from "../../../../../utils/promiseHelpers";
import { QualityGradeKind } from "../../../models/gradeKind";
import { QualityGradeValue } from "../../../models/gradeValue";
import { gradeKindAdapter, gradeValueAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    loadSliceData,
    loadSliceDataReducer,
    loadSliceDataSagaFactory

} = actionFactory
    .withMappedName("loadSliceData")
    .createAsyncAction<void, Load2Result<QualityGradeKind[], QualityGradeValue[]>>({        
        actionStatusSelector: state => state.loadingState,
        asyncCall: (_arg, context) => {            
            return parallelLoad(
                () => context.marketplaceApiClient.quality.getQualityGradeKinds(),
                () => context.marketplaceApiClient.quality.getQualityGradeValues()
            );            
        },
        onFulfilled: (state, action) => {
            gradeKindAdapter.upsertMany(state.gradeKinds, action.payload.result.result1);
            gradeValueAdapter.upsertMany(state.gradeValues, action.payload.result.result2);

            if (action.payload.result.result1.length > 0) {
                state.selectedGradeKindId = action.payload.result.result1
                    .sort((left, right) => left.name.localeCompare(right.name))
                    [0]?.id ?? null;
            }
        }
    });