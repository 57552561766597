import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, Product, ProductManagementState } from "../types";

export const setSelectedProducts = actionFactory.createAppAction<Product[]>("setSelectedProducts");

export function setSelectedProductsReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forAction(setSelectedProducts)        
        .addHandler((state, action) => {            
            state.selectedProducts = action.payload;
        })
        .build();
}