import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { Menu } from "../../../editor/store/types";
import { actionFactory, MenuDashboardState } from "../types";
import { push } from 'connected-react-router';
import { menuEditorRoute } from "../../../../navigation/routes";
export const addMenu = actionFactory.createAppAction("addMenu");

const sagaActions = actionFactory.createSagaWithProgressActions<void, Menu>(addMenu.type);

export function addMenuReducers(reducerFactory: IReducerFactory<MenuDashboardState>): IReducerFactory<MenuDashboardState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.addMenuState)
        .onFulfilled((state, action) => {
            state.data.push(action.payload.result);            
        })
        .build();
}

async function performLoad(_arg: void, _context: SagaAppContext): Promise<Menu> {   
    return _context.marketplaceApiClient.menuAdmin.upsertMenu({ name: "Menu", inactive: false });    
}

async function onSuccess(_arg: void, result: Menu, _context: SagaAppContext) {   
    _context.dispatch(push(menuEditorRoute.createPath(result.id)));
}

export function createAddMenuSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions,
            successCallback: onSuccess
        },
        actionPattern: addMenu.type,
        debounceInMilliseconds: 250
    });
};