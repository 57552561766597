import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { Category } from "../../../categoryManagement/store/types";
import { categoryAdapter } from "../state";
import { actionFactory, MenuAdminState } from "../types";

export const loadCategories = actionFactory.createAppAction<{menuId: number}>("loadCategories");

const sagaActions = actionFactory.createSagaWithProgressActions<{menuId: number}, Category[]>(loadCategories.type);

export function loadCategoriesReducers(reducerFactory: IReducerFactory<MenuAdminState>): IReducerFactory<MenuAdminState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.categoriesLoadState)
        .onFulfilled((state, action) => {
            categoryAdapter.setAll(state.categories, action.payload.result);
        })
        .build();
}

async function performLoad(arg: {menuId: number}, context: SagaAppContext): Promise<Category[]> {
    return context.marketplaceApiClient.menuAdmin.getCategories(arg.menuId);
}

export function createLoadCategoriesSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: loadCategories.type,
        debounceInMilliseconds: 250
    });
};