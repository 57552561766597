import { useDispatch } from "react-redux";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { orderItemValidationSchema } from "../../validations/orderItemValidations";
import { editOrderItemCancel, editOrderItemComplete } from "../store/featureActions/editOrderItem";
import { getEditedOrderItem, getEditOrderItemVisible, getOrderItemSaveState } from "../store/selectors/orderItem";
import { SalesOrderItemEditModel } from "../store/types";
import { OrderItemEditor } from "./orderItemEditor";

export const OrderItemDialog: React.FC = () => {

    const dispatch = useDispatch();

    const onCancel = () => {
        dispatch(editOrderItemCancel());
    };

    const onSave = (value: SalesOrderItemEditModel) => {        
        dispatch(editOrderItemComplete(value));
    }

    return <FormikModalEditor<SalesOrderItemEditModel>
        title="Order Item"
        valueSelector={getEditedOrderItem}
        visibleSelector={getEditOrderItemVisible}
        onSave={onSave}
        onCancel={onCancel}
        statusSelector={getOrderItemSaveState}
        style={{ width: "500px", maxWidth: "95vw" }}
        validationSchema={orderItemValidationSchema}
    >
        <OrderItemEditor />
    </FormikModalEditor>
} 