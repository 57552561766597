import { ActionFactory } from "../../../../store/factory/action/actionFactory";

export const sliceName: string = "message-dialog";

export const actionFactory = new ActionFactory<MessageDialogState>(sliceName);

export interface MessageInfo {
    message: string;
    title?: string;    
    actionTitle?: string;
}
export interface MessageDialogState {    
    messageInfo?: MessageInfo;    
}