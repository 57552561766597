import { RootState } from "../../../../../store/rootReducer";

export const getHasLoaded = (state: RootState) => {
    return state.inventoryManagement.loadingState.hasExecuted;
}

export const getIsBusy = (state: RootState) => {
    const inventoryState = state.inventoryManagement;

    return inventoryState.loadingState.isExecuting || 
    inventoryState.menuAssignmentsLoadState.isExecuting;    
}