import { AssessmentTemplate } from "../../../models/assessmentTemplate";
import { actionFactory } from "../types";

export const {

    deleteTemplate,
    deleteTemplateReducer,
    deleteTemplateSagaFactory

} = actionFactory
    .withMappedName("deleteTemplate")
    .createAsyncAction<AssessmentTemplate, Response>({        
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {            
            return context.marketplaceApiClient.quality.deleteAssessmentTemplate(arg.id);
        },
        onFulfilled: (state, action) => {
            state.templates = state.templates.filter(template => template.id !== action.payload.arg.id);
        }
    });