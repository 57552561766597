import { FormikHelpers } from "formik";
import { useDispatch } from "react-redux";
import { FormikEmbeddedEditor } from "../../../../components/formik/embeddedEditor"
import { StringField } from "../../../../components/formik/stringField";
import { useAppSelector } from "../../../../store/configureStore"
import { isValue } from "../../../../utils/valueHelper";
import { PricingModel } from "../../sharedTypes";
import { upsertPricingModel } from "../store/featureActions/upsertPricingModel";
import { getPricingModel } from "../store/selectors/pricingModel";

export const PricingModelForm: React.FC = () => {

    const dispatch = useDispatch();
    const pricingModel = useAppSelector(getPricingModel);

    const onSave = (value: PricingModel, _formikHelpers: FormikHelpers<PricingModel>) => {
        if (isValue(value)) {
            dispatch(upsertPricingModel(value));
        }
    }

    return pricingModel && <FormikEmbeddedEditor
        initialValues={pricingModel}
        onSave={onSave}
    >

        <StringField<PricingModel> 
            fieldName="name"
            defaultLabel="Name"
            submitOnBlur={true}
            labelStyle={{ width: "60px", display: "inline-block" }}
            inputStyle={{ width: "350px" }}
        />

    </FormikEmbeddedEditor>
} 