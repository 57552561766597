import { DateTime } from 'luxon';
import * as yup from 'yup';
import { QualityMetricKind } from "../models/metricKind";

export const qualityMetricKindValidationSchema: yup.SchemaOf<QualityMetricKind> = yup.object().shape({
    id: yup.number().required("ID is required"),

    name: yup.string()
        .min(1, "Name must be at least 1 character")
        .max(128, "Name must be at most 128 characters")
        .required("Name is required"),

    inactive: yup.boolean()
        .required("Inactive is a required value"),

    publishedDate: yup.mixed<DateTime>().nullable().defined("Published Date must be defined")

});