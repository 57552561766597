
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { dismissAlert } from "../store/featureActions/dismissUserAlert";
import { getCustomerAlertById, getUserAlertById } from "../store/selectors/alerts";
import './alertCard.css';

export interface AlertCardProps {
    alertId: number,
    isUser: boolean
}

export const AlertCard: React.FC<AlertCardProps> = (props) => {

    const dispatch = useDispatch();
    const userAlert = useAppSelector(state => {
        return props.isUser
            ? getUserAlertById(state, props.alertId)
            : getCustomerAlertById(state, props.alertId)
    });

    const onDismissAlert = () => {
        dispatch(dismissAlert({ alertId: userAlert.id, isUser: props.isUser }));
    }

    const footer = <Button
        className="p-button-sm"
        label="Dismiss"
        icon="pi pi-trash"
        style={{ marginRight: '.25em' }}
        onClick={onDismissAlert}
    />;

    return <Card
        className="alert-card p-mt-4 p-shadow-7"
        title={userAlert.kind}
        footer={footer}
    >
        {userAlert.message}
    </Card>

}

