import { DateTime, FixedOffsetZone } from "luxon";
import { DateTimeOrNullKeyOf, getDateTimeOrNullValue, PropertiesOfType, setDateTimeOrNullValue } from "../../utils/typeHelpers";


/**
 * Converts string properties to Luxon DateTime. Assumes the serialized string was stored in the client local time.
 * @param model The model containing Luxon DateTime properties that are serialized as strings. The serialized string is a date stored in the clients local time zone
 * @param properties The properties of 'model' that should be deserialized
 */
export function deserializeLocalDateTime<T extends PropertiesOfType<T, DateTime | null | undefined>>(properties: (DateTimeOrNullKeyOf<T>)[], model: T): T {
    properties.forEach(key => {
        const value = getDateTimeOrNullValue(model, key);
        setDateTimeOrNullValue(model, key, EnsureDateTimeOrNull(value));
    });
    return model;
}

/**
 * Converts string properties to Luxon DateTime. Assumes the serialized string was stored in Utc.
 * @param model The model containing Luxon DateTime properties that are serialized as strings. The serialized string is a date stored in Utc
 * @param properties The properties of 'model' that should be deserialized
 */
export function deserializeUtcDateTime<T extends PropertiesOfType<T, DateTime | null | undefined>>(properties: (DateTimeOrNullKeyOf<T>)[], model: T): T {
    properties.forEach(key => {
        const value = model[key];
        setDateTimeOrNullValue(model, key, EnsureDateTimeOrNullUtc(value));
    });
    return model;
}

/**
 * Converts Luxon DateTime properties from Utc to Local time.
 * @param model The model containing Luxon DateTime properties. The properties listed are assumed to be stored in Utc
 * @param properties The properties of 'model' that should be converted from Utc to local time
 */
export function convertUtcToLocal<T extends PropertiesOfType<T, DateTime | null | undefined>>(properties: (DateTimeOrNullKeyOf<T>)[], model: T): T {
    properties.forEach(key => {
        const value = model[key];
        if (value === null || value === undefined) return;
        setDateTimeOrNullValue(model, key, value.toLocal());
    });
    return model;
}

export const createLocalDateSerializationFunction = <T extends PropertiesOfType<T, DateTime | null | undefined>>(properties: (DateTimeOrNullKeyOf<T>)[]) => (model: T) => {
    return deserializeLocalDateTime(properties, model);
}

export interface DateSerializationOptions<T extends PropertiesOfType<T, DateTime | null | undefined>> {
    properties: (DateTimeOrNullKeyOf<T>)[],
    convertToLocal?: boolean
}

export const createUtcDateSerializationFunction = <T extends PropertiesOfType<T, DateTime | null | undefined>>({ properties, convertToLocal }: DateSerializationOptions<T>) => (model: T) => {
    const dateTime = deserializeUtcDateTime(properties, model);
    return !!convertToLocal
        ? convertUtcToLocal(properties, model)
        : dateTime;
}

export const createDeserializeUtcToLocalDateFns = <T extends PropertiesOfType<T, DateTime | null | undefined>>(properties: (DateTimeOrNullKeyOf<T>)[]) => {
    const singleFn = (model: T) => {
        deserializeUtcDateTime(properties, model);
        convertUtcToLocal(properties, model);
        return model;
    }
    const setFn = (models: T[]) => {
        models.forEach(singleFn);
        return models;
    }

    return {
        single: singleFn,
        set: setFn
    };
}

function EnsureDateTimeOrNullUtc(value: DateTime | string | null | undefined): DateTime | null {
    if (value === null || value === undefined) {
        return null;
    }
    else if (typeof (value) === "string") {
        return DateTime.fromISO(value, { zone: FixedOffsetZone.utcInstance });
    }
    else {
        return value;
    }
}

function EnsureDateTimeOrNull(value: DateTime | string | null | undefined): DateTime | null {
    if (value === null || value === undefined) {
        return null;
    }
    else if (typeof (value) === "string") {
        return DateTime.fromISO(value);
    }
    else {
        return value;
    }
}