import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { CSSProperties, useRef } from "react";
import { mapToMenuItem, TypedMenuItem } from "../../utils/table/typedMenuItem";

interface PopUpMenuButtonProps<T> {
    id: string,
    value: T;
    disabled?: (value: T) => boolean,
    menuItems: TypedMenuItem<T>[],
    buttonStyle?: CSSProperties,
    icon?: React.ReactNode,
    menuStyle?: CSSProperties,
    buttonClassName?: string
}

export const PopUpMenuButton = <T,>(props: React.PropsWithChildren<PopUpMenuButtonProps<T>>) => {

    const overlayMenuRef = useRef<Menu>(null);
    const isDisabled = props.disabled !== undefined ? props.disabled(props.value) : false;

    return <div>
        <Menu 
            popup 
            ref={overlayMenuRef} 
            model={props.menuItems?.map(menuItem => mapToMenuItem(menuItem, props.value, props.id))} 
            style={props.menuStyle}
        />
        <Button
            id={props.id}
            icon={ props.icon ?? "pi pi-bars"}
            onClick={(event) => overlayMenuRef.current?.toggle(event)}
            disabled={isDisabled}
            style={props.buttonStyle}
            className={props.buttonClassName}
        />
    </div>
}
