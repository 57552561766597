import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../store/configureStore";
import { getIsAuthenticated } from "../authentication/store/selectors/isAuthenticated";
import { loadUserTableSettings } from "../userAppSettings/userTableSettings/store/featureActions/loadUserTableSettings";

export const AuthWatcher: React.FC = () => {

  const dispatch = useDispatch();     
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(loadUserTableSettings())
    }
  }, [isAuthenticated, dispatch]);

  return null;
}
