import { IReducerFactory } from "../../../../store/factory/interfaces";
import { SagaAppContext } from "../../../../store/rootSaga";
import { createExecuteWithProgressSaga } from "../../../../store/factory/sagaWithProgress/executeWithProgress";
import { actionFactory, AuthenticationAction, UserAuthState } from "../types";
import { messageFromError } from "../../../errorHandling/errorMessageHelper";
import { beginLoginActionName } from "./beginLoginActions";

const sagaActions = actionFactory.createSagaWithProgressActions<void, void>(beginLoginActionName);
export function beginLoginReducers(reducerFactory: IReducerFactory<UserAuthState>): IReducerFactory<UserAuthState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .onPending((state, _) => {
            state.AuthenticationAction = AuthenticationAction.LoggingIn;
        })
        .onRejected((state, action) => {
            state.AuthenticationAction = null;
            state.ErrorMessage = messageFromError(action.payload.errorResult);
        })
        .onFulfilled((state, _) => {
            state.AuthenticationAction = null;
            state.ErrorMessage = null;
        })
        .build();
}

async function login(_: void, context: SagaAppContext): Promise<void> {
    return context.authService.newLogin()
}

export function createBeginLoginSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: login,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: beginLoginActionName,
        debounceInMilliseconds: 250
    });
};