import { Column, ColumnProps } from "primereact/column";
import React from 'react';
import { QueueStatus } from "../../features/commonModels/queueStatus";
import './queueStatusColumn.css';

export interface QueueStatusColumnOptions<T> extends Omit<ColumnProps, "field" | "header"> {
    field: keyof T & string;
    header: string;
}
function createQueueStatusTemplate<T>(getValueFn: (rowData: T) => QueueStatus | null | undefined): (rowData: T) => React.ReactNode {
    return (rowData: T) => {
        const value = getValueFn(rowData);
        switch (value) {
            case QueueStatus.Queued: {
                return <i className="pi pi-clock queued-icon"></i>;
            }
            case QueueStatus.InProgress: {
                return <i className="pi pi-spin pi-spinner in-progress-icon"></i>;
            }
            case QueueStatus.Success: {
                return <i className="pi pi-check-circle success-icon"></i>;
            }
            case QueueStatus.Failure: {
                return <i className="pi pi-exclamation-triangle fail-icon"></i>;
            }
            default: {
                return <div></div>;
            }
        }
    };
}

export function createQueueStatusColumn<T>(
    props: QueueStatusColumnOptions<T>,
    getValueFn: (rowData: T) => QueueStatus): React.ReactNode {
    return <Column key={props.columnKey ?? props.field} style={{ textAlign: "center" }} {...props} body={createQueueStatusTemplate(getValueFn)} />;
}
