import { CreateActionStatusInitialState } from "../../actionStatus/actionStatus";
import { UserAuthState } from "./types";

export const createInitialState = () : UserAuthState => {
    return {
        CompleteLoginState: CreateActionStatusInitialState(),
        LoadAuthState: CreateActionStatusInitialState(),
        CurrentUser: null,
        AuthenticationAction: null,
        ErrorMessage: null,
        signInCompleted: false
    }
}

export const initialState = createInitialState();