import { createEntityAdapter } from '@reduxjs/toolkit';
import { CreateInitialModalEditState } from '../../../../store/factory/modalEdit/modalEdit';
import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { BulkAssignmentKind } from '../../../commonModels/bulkAssignmentState';
import { EntityVideo } from '../../../commonModels/entityVideo';
import { QualityGradeKind } from '../../../qualityGradeTemplates/models/gradeKind';
import { QualityGradeValue } from '../../../qualityGradeTemplates/models/gradeValue';
import { BulkExcludeAction, InventoryManagementState, InventoryNote, InventoryView } from './types';

export const inventoryAdapter = createEntityAdapter<InventoryView>();
export const qualityGradeAdapter = createEntityAdapter<QualityGradeValue>();
export const qualityGradeKindAdapter = createEntityAdapter<QualityGradeKind>();
export const inventoryVideoAdapter = createEntityAdapter<EntityVideo>();
export const inventoryNotesAdapter = createEntityAdapter<InventoryNote>();

export const createInitialState = (): InventoryManagementState => {
    return {
        loadingState: CreateActionStatusInitialState(),
        latestSyncRequest: null,
        requestSyncState: CreateActionStatusInitialState(),
        inventory: inventoryAdapter.getInitialState(),
        selectedInventoryIds: [],
        editInventoryState: CreateInitialModalEditState(),
        addInventoryState: CreateInitialModalEditState(),
        inventoryDeleteStatus: CreateInitialModalEditState(),
        editInventoryNotes: inventoryNotesAdapter.getInitialState(),
        inventoryNoteLoadStatus: CreateActionStatusInitialState(),
        inventoryNoteSaveStatus: CreateActionStatusInitialState(),

        inventoryLicensesLoadState: CreateActionStatusInitialState(),
        inventoryLicenses: [],

        inventoryUnitOfMeasuresState: CreateActionStatusInitialState(),
        inventoryUnitOfMeasures:[],
       
        categoryAssignmentState: {
            loadingState: CreateActionStatusInitialState(),
            savingState: CreateActionStatusInitialState(),
        },
        bulkCategoryAssignmentState: {
            savingState: CreateActionStatusInitialState(),
            parentModels: [],
        },
        bulkClearCategoryAssignmentState: {
            savingState: CreateActionStatusInitialState(),
            parentModels: [],
        },

        editImageSaveState: CreateActionStatusInitialState(),        
        editImages: [],
        editVideos: inventoryVideoAdapter.getInitialState(),        
        addVideoState: CreateInitialModalEditState(),
        videoSaveState: CreateActionStatusInitialState(),
        mediaLoadState: CreateActionStatusInitialState(),
        
        bulkExcludeState: {
            savingState: CreateActionStatusInitialState(),
            showConfirmation: false,
            action: BulkExcludeAction.Exclude
        },
        menuAssignmentsLoadState: CreateActionStatusInitialState(),
        menuAssignments: [],
        qualityGrades: qualityGradeAdapter.getInitialState(),
        qualityGradeKinds: qualityGradeKindAdapter.getInitialState(),
        bulkQualityGradeAssignmentState: {
            qualityGradeIdToAssign: null,
            inventory: [],
            savingState: CreateActionStatusInitialState(),
            bulkAssignmentKind: BulkAssignmentKind.Assign
        },
        orderedQuantities: {},
        viewOrdersForInventoryId: null,
        qualityAssessmentTemplates: [],
        assignQualityAssessmentState: CreateInitialModalEditState(),
        removeQualityAssessmentState: CreateInitialModalEditState(),
        syncInventoryRequestState: CreateInitialModalEditState(),
        readyDateAssignmentState: CreateInitialModalEditState(),
    };
};

export const initialState: InventoryManagementState = createInitialState();