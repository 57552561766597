import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { CategoryAssignmentState } from './types';

export const createInitialState = (): CategoryAssignmentState => {
    return {                
        loadingState: CreateActionStatusInitialState(),        
        available: [],
        selected: []
    };
};

export const initialState: CategoryAssignmentState = createInitialState();