import { useFormikContext } from "formik";
import { useEffect } from "react";

export const AutoSubmit: React.FC = () => {

  const { values, submitForm, isValid, dirty } = useFormikContext();

  useEffect(() => {
    if (isValid && dirty) {
      submitForm();
    }
  }, [values, submitForm, isValid, dirty]);

  return null;
};

