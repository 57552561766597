import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { clearSearchResultsReducer } from './featureActions/clearSearchResults';
import { deleteOrderItemReducer } from './featureActions/deleteOrderItem';
import { editOrderItemReducers } from './featureActions/editOrderItem';
import { getOrderedQuantitiesReducer } from './featureActions/getOrderedQuantities';
import { loadSliceDataReducer } from './featureActions/loadSliceData';
import { searchInventoryReducer } from './featureActions/searchInventory';
import { selectInventoryForOrderItemReducer } from './featureActions/selectInventoryForOrderItem';
import { updateSalesOrderReducer } from './featureActions/updateSalesOrder';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(loadSliceDataReducer)
            .addRange(editOrderItemReducers)
            .add(updateSalesOrderReducer)
            .add(deleteOrderItemReducer)
            .add(searchInventoryReducer)
            .add(clearSearchResultsReducer)
            .add(getOrderedQuantitiesReducer)
            .add(selectInventoryForOrderItemReducer);
            // Create your feature actions in ./featureActions and then add them to the reducer factory here
            // with .Add(<your-action-reducer-name>);
            
    } 
});

export default slice.reducer;

export const { resetState } = slice.actions;