
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import React, { CSSProperties, useRef } from "react";
import ReactToPrint from "react-to-print";
import { ClassPrintWrapper, HiddenClassPrintWrapper } from "./classPrintWrapper";

export interface PrintContentsProps {
	printButtonTitle?: string;
	showIcon?: boolean;
	documentTitle?: string;
	buttonStyle?: CSSProperties;	
	hidePrintedContents?: boolean;
}

export const PrintContents: React.FC<PrintContentsProps> = ({ printButtonTitle, showIcon = true, documentTitle, children, buttonStyle, hidePrintedContents = true }) => {
	const wrapperContentRef = useRef<HiddenClassPrintWrapper>(null);

	return <>
		<ReactToPrint
			trigger={() => <Button
				icon={showIcon ? PrimeIcons.PRINT : undefined}
				style={buttonStyle}
			>
				{printButtonTitle}
			</Button>}
			content={() => wrapperContentRef.current}
			documentTitle={documentTitle}
		/>

		{hidePrintedContents
			? <HiddenClassPrintWrapper ref={wrapperContentRef}>
				{children}
			</HiddenClassPrintWrapper>
			: <ClassPrintWrapper ref={wrapperContentRef}>
				{children}
			</ClassPrintWrapper>
		}
	</>;
};