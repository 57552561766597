import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { firstOrUndefined } from "../../../../../utils/arrayHelpers";
import { createIdentityMap } from "../../../../../utils/identity";
import { isValue } from "../../../../../utils/valueHelper";
import { getQualityMetricKinds, getTemplateMetrics } from "./data";

export const getSelectMetricsState = (state: RootState) => state.qualityTemplateEditor.selectMetricsState;
export const getSelectMetricsValue = (state: RootState) => state.qualityTemplateEditor.selectMetricsState.editedValue;
export const getSelectMetricsVisible = (state: RootState) => state.qualityTemplateEditor.selectMetricsState.isVisible;

export const getAddTemplateConstraintState = (state: RootState) => state.qualityTemplateEditor.addTemplateConstraintState;
export const getAddTemplateConstraintValue = (state: RootState) => state.qualityTemplateEditor.addTemplateConstraintState.editedValue;
export const getAddTemplateConstraintVisible = (state: RootState) => state.qualityTemplateEditor.addTemplateConstraintState.isVisible;

export const getChangeGradeKindState = (state: RootState) => state.qualityTemplateEditor.changeGradeKindState;
export const getChangeGradeKindValue = (state: RootState) => state.qualityTemplateEditor.changeGradeKindState.editedValue;
export const getChangeGradeKindVisible = (state: RootState) => state.qualityTemplateEditor.changeGradeKindState.isVisible;

export const getIsEditable = (state: RootState) => isValue(state.qualityTemplateEditor.template) && !isValue(state.qualityTemplateEditor.template.publishedDate);

export const getMetricKindsForSelection = createSelector(
    [
        getQualityMetricKinds,
        getTemplateMetrics
    ],
    (allMetricKinds, assignedMetrics) => {

        const assignmentMap = createIdentityMap(assignedMetrics, assignment => assignment.metricKindId);

        return allMetricKinds
            .filter(metricKind => isValue(metricKind.publishedDate))
            .filter(metricKind => !metricKind.inactive)
            .filter(metricKind => assignmentMap[metricKind.id] === undefined);
    }
);

export const getMetricKindsForConstraint = createSelector(
    [
        getQualityMetricKinds,
        getTemplateMetrics
    ],
    (allMetricKinds, assignedMetrics) => {

        const assignmentMap = createIdentityMap(assignedMetrics, assignment => assignment.metricKindId);

        return allMetricKinds            
            .filter(metricKind => isValue(assignmentMap[metricKind.id]));
    }
);

export const getMetricKindsForTest = createSelector(
    [
        getQualityMetricKinds,
        getTemplateMetrics
    ],
    (allMetricKinds, assignedMetrics) => {

        const assignmentMap = createIdentityMap(assignedMetrics, assignment => assignment.metricKindId);

        return allMetricKinds
            .filter(metricKind => isValue(assignmentMap[metricKind.id]))
            .sort((left, right) => {
                const leftTemplateMetric = assignmentMap[left.id];
                const rightTemplateMetric = assignmentMap[right.id];
                return leftTemplateMetric.sequence - rightTemplateMetric.sequence;
            });
    }
);

export const getTestEntryValues = (state: RootState) => state.qualityTemplateEditor.testAssessmentEntries;

export const getTestEntryValueForMetricKind = createSelector(
    [
        getTestEntryValues
    ],
    (testEntries) => {
        return (metricKindId: number) => firstOrUndefined(
            testEntries,
            entry => entry.qualityMetricKindId === metricKindId
        );
    }
);

export const getTestEvaluationResult = (state: RootState) => state.qualityTemplateEditor.testResult;