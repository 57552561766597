import * as yup from 'yup';
import { Company, CompanyType } from '../../../company/profile/store/types';

export const companyValidationSchema: yup.SchemaOf<Company> = yup.object().shape({
    id: yup.number().required("ID is required"),

    displayName: yup.string()
        .min(1, "Name must be at least 1 character")
        .max(128, "Name must be at most 128 characters")
        .required("Name is required"),

    companyType:yup.mixed<CompanyType>().required(),
    stateAbbreviation: yup.string().required(),
    tenantId: yup.string()
        .min(1, "Tenant must be at least 1 character")
        .max(255, "Tenant must be at most 255 characters")
        .required("Tenant is required")   

});