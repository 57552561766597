import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { createForceDialogErrorInSagaSaga } from "../featureActions/forceDialogErrorInSaga";
import { createForceToastErrorInSagaSaga } from "../featureActions/forceToastErrorInSaga";
import { createLoadSliceDataSaga } from "../featureActions/loadSliceData";

export function createDiagnosticSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [
        createLoadSliceDataSaga(context),
        createForceDialogErrorInSagaSaga(context),
        createForceToastErrorInSagaSaga(context)
    ]
}