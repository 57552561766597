import { ResequenceRequest } from "../../../../pricing/sharedTypes";
import { QualityMetricValue } from "../../../models/metricValue";
import { metricValueAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    resequenceMetricValue,
    resequenceMetricValueReducer,
    resequenceMetricValueSagaFactory

} = actionFactory
    .withMappedName("resequenceMetricValue")
    .createAsyncAction<ResequenceRequest, QualityMetricValue[]>({
        actionStatusSelector: (state) => state.metricValueSaveState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.quality.resequenceQualityMetricValue(arg.entityId, arg.newSequenceValue);
        },       
        onFulfilled: (state, action) => {
            metricValueAdapter.upsertMany(state.metricValues, action.payload.result);
        }
    });