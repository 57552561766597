import { useFormikContext } from "formik";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { confirmPopup } from "primereact/confirmpopup";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { FormikEditorClassStyles } from "../../../../components/formik/classNameAttributes";
import { DropDownField } from "../../../../components/formik/dropDownField";
import { MappedDropDownField } from "../../../../components/formik/mappedDropDownField";
import { MultiSelectField } from "../../../../components/formik/multiSelectField";
import { useAppSelector } from "../../../../store/configureStore";
import { setFormikNumberArrayField } from "../../../../utils/formikHelper";
import { useEffectWithPartialDependencies } from "../../../../utils/useEffectHelper";
import { isValue } from "../../../../utils/valueHelper";
import { ComparisonOperation, ComparisonOperationViews } from "../../models/comparisonOperation12";
import { TemplateConstraintNode } from "../../models/constraintNode";
import { QualityMetricValue } from "../../models/metricValue";
import { deleteTemplateConstraintNode } from "../store/featureActions/deleteTemplateConstraintNode";
import { getQualityMetricValues } from "../store/selectors/data";
import { getIsEditable, getMetricKindsForConstraint } from "../store/selectors/editing";

export interface ConstraintEditorProps {
    submitOnChange?: boolean;
    allowDelete?: boolean;
}

export const ConstraintEditor: React.FC<ConstraintEditorProps> = ({
    submitOnChange = true,
    allowDelete = true
}) => {

    const dispatch = useDispatch();
    const formikContext = useFormikContext<TemplateConstraintNode>();
    const metricKinds = useAppSelector(getMetricKindsForConstraint);
    const isEditable = useAppSelector(getIsEditable);

    const allMetricValues = useAppSelector(getQualityMetricValues)
    const [availableMetricValues, setAvailableMetricValues] = useState<QualityMetricValue[]>([]);

    const onDeleteConstraint = (e: React.MouseEvent<HTMLButtonElement>) => {
        confirmPopup({        
            target: e.currentTarget,
            message: 'Are you sure you want to delete this rule?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => dispatch(deleteTemplateConstraintNode(formikContext.values))            
        });
    }

    useEffectWithPartialDependencies(() => {
        setAvailableMetricValues(allMetricValues
            .filter(value => value.metricKindId === formikContext.values.metricKindId)
            .sort((left, right) => left.sequence - right.sequence)
        );

        const currentValues = allMetricValues.filter(value => formikContext.values.metricValueIds.includes(value.id));
        if (currentValues.some(value => value.metricKindId !== formikContext.values.metricKindId)) {
            setFormikNumberArrayField<TemplateConstraintNode>(formikContext, "metricValueIds", []);
        }

    }, [formikContext.values.metricKindId, allMetricValues]);

    const fieldClassNames: FormikEditorClassStyles = {
        containerClassName: "edit-templateconstraint-field-container",
        inputClassName: "edit-templateconstraint-field-input",
        labelClassName: "edit-templateconstraint-field-label"
    }

    return <FlexRowContainer centerContent className="template-constraint-embedded-editor">

        <div className="constraint-node-icon"></div>

        <DropDownField<TemplateConstraintNode, number>
            availableValues={metricKinds.map(metricKind => ({ label: metricKind.name, value: metricKind.id }))}
            fieldName="metricKindId"
            defaultLabel=""
            allowClear={false}
            inputClassName={fieldClassNames.inputClassName}
            submitOnChange={submitOnChange}
            showErrors={false}
            disabled={!isEditable}
        />

        <div className="edit-templateconstraint-field-joining-text">is</div>

        <DropDownField<TemplateConstraintNode, ComparisonOperation>
            availableValues={ComparisonOperationViews}
            defaultLabel=""
            fieldName="comparison"
            allowClear={false}
            inputClassName={"edit-templateconstraint-comparison-selector"}
            submitOnChange={submitOnChange}
            showErrors={false}
            disabled={!isEditable}
        />

        {
            formikContext.values.comparison === ComparisonOperation.In
                ? < MultiSelectField<TemplateConstraintNode, number, { label: string, value: number }>
                    availableValues={availableMetricValues.map(value => ({ label: `[${value.sequence}]   ${value.name}`, value: value.id }))}
                    fieldName="metricValueIds"
                    defaultLabel=""
                    submitOnBlur={submitOnChange}
                    inputClassName={fieldClassNames.inputClassName}
                    disabled={!isEditable}
                    getValue={node => node.metricValueIds}
                    optionLabel="label"
                    optionValue="value"
                    showErrors={false}
                />
                : <MappedDropDownField<TemplateConstraintNode, number[], number | undefined>
                    availableValues={availableMetricValues.map(value => ({ label: `[${value.sequence}]   ${value.name}`, value: value.id }))}
                    defaultLabel=""
                    fieldName="metricValueIds"
                    getValue={(model => model.metricValueIds)}
                    fieldToValueTransform={fieldValue => fieldValue.length > 0 ? fieldValue[0] : undefined}
                    valueToFieldTransform={value => isValue(value) ? [value] : []}
                    allowClear={false}
                    inputClassName={fieldClassNames.inputClassName}
                    submitOnChange={submitOnChange}
                    showErrors={false}
                    disabled={!isEditable}
                />
        }

        {
            allowDelete &&
            <Button 
                icon={PrimeIcons.TIMES} 
                type="button" 
                onClick={onDeleteConstraint}
                disabled={!isEditable}
            />
        }
    </FlexRowContainer>
}