import clsx from 'clsx';
import { DataView, DataViewLayoutType } from 'primereact/dataview';
import { Tooltip } from 'primereact/tooltip';
import { CSSProperties } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FlexColumnContainer } from '../../../../components/containers/flexColumnContainer';
import { FlexRowContainer } from '../../../../components/containers/flexRowContainer';
import { PopUpMenuButton } from "../../../../components/popMenuButton";
import { OverlayWaitDisplay } from '../../../../components/waitDisplay';
import { useAppSelector } from "../../../../store/configureStore";
import { formatToShortDateString } from '../../../../utils/datetimeHelper';
import { currencyFormatOptions, percentFormatOptions } from '../../../../utils/formatHelpers';
import { TypedMenuItem } from '../../../../utils/table/typedMenuItem';
import { isValue } from '../../../../utils/valueHelper';
import { packageTypeIsImmaturePlant } from '../logic/packageType';
import { getPrimaryColor, getSecondaryColor } from "../store/selectors/menuColors";
import { getPrimaryFont, getSecondaryFont } from '../store/selectors/menuFonts';
import { getInventoryForSection, getInventoryLoadingStateForSection, getMenuUrlName, getVendorRouteName } from "../store/selectors/vendorMenu";
import { InventoryView } from "../store/types";
import { createMenuItems } from './inventoryCommands';
import "./smallDeviceSectionListView.css";

export interface SmallDeviceSectionListViewProps {
    sectionId: number;
}

export const SmallDeviceSectionListView: React.FC<SmallDeviceSectionListViewProps> = ({ sectionId }) => {

    const dispatch = useDispatch();
    const inventory = useAppSelector(state => getInventoryForSection(state, sectionId));
    const inventoryLoadState = useAppSelector(state => getInventoryLoadingStateForSection(state, sectionId));
    const vendorRouteName = useAppSelector(getVendorRouteName);
    const menuUrlName = useAppSelector(getMenuUrlName);
    const history = useHistory();

    const primaryColor = useAppSelector(getPrimaryColor);
    const secondaryColor = useAppSelector(getSecondaryColor);
    const primaryFont = useAppSelector(getPrimaryFont);
    const secondaryFont = useAppSelector(getSecondaryFont);

    const buttonStyle: CSSProperties = {
        background: primaryColor ?? undefined,
        color: secondaryColor ?? undefined,
    }

    const menuItems: TypedMenuItem<InventoryView>[] = createMenuItems(dispatch, history, vendorRouteName, menuUrlName);

    const itemTemplate = (item: InventoryView, _: DataViewLayoutType): React.ReactNode => {

        const isImmaturePlantPackage = packageTypeIsImmaturePlant(item);

        return <FlexColumnContainer className='mobile-list-card-container'>
            <FlexRowContainer centerContent gap="5px" className='mobile-list-card-title-bar'>
                <PopUpMenuButton
                    id="inventory-small-device-listview-popup-menu"
                    menuItems={menuItems}
                    value={item}
                    buttonStyle={buttonStyle}
                    buttonClassName="mobile-list-card-menu-button"
                />
                <div>
                    <Tooltip target={`#inv-title-${item.id}`} position='top' />
                    <div style={{ fontFamily: primaryFont }} id={`inv-title-${item.id}`} className='mobile-list-card-title' data-pr-tooltip={item?.marketingName} >{item?.marketingName}</div>
                </div>
            </FlexRowContainer>

            <FlexRowContainer
                centerContent
                className='mobile-list-card-split-content-container mobile-list-card-shadow'
            >

                <FlexColumnContainer centerContent className='mobile-list-card-split-content' >
                    <div style={{ fontFamily: secondaryFont }} className={clsx({
                        "mobile-list-card-split-content-novalue": !isValue(item?.quantity),
                        "mobile-list-card-split-content-value": isValue(item?.quantity)
                    })}
                    >{`${item?.quantity?.toLocaleString(undefined, { maximumFractionDigits: 4 }) ?? ""} ${item?.unitOfMeasureName}`}</div>
                </FlexColumnContainer>

                <FlexColumnContainer centerContent className='mobile-list-card-split-content'>
                    <div style={{ fontFamily: secondaryFont }} className={clsx({
                        "mobile-list-card-split-content-novalue": !isValue(item?.unitPrice),
                        "mobile-list-card-split-content-value": isValue(item?.unitPrice)
                    })}
                    >{item?.unitPrice?.toLocaleString(undefined, currencyFormatOptions) ?? "$$$: NA"}</div>
                </FlexColumnContainer>



            </FlexRowContainer>

            <FlexRowContainer centerContent className='mobile-list-card-split-content-container mobile-list-card-shadow' >
                <FlexColumnContainer centerContent className='mobile-list-card-split-content'>
                    <div style={{ fontFamily: secondaryFont }} className={clsx({
                        "mobile-list-card-split-content-novalue": !isValue(item?.thcPercentage),
                        "mobile-list-card-split-content-value": isValue(item?.thcPercentage)
                    })}
                    >{`THC%: ${item?.thcPercentage?.toLocaleString(undefined, percentFormatOptions) ?? "NA"}`}</div>
                </FlexColumnContainer>

                <FlexColumnContainer centerContent className='mobile-list-card-split-content'>
                    <div style={{ fontFamily: secondaryFont }} className={clsx({
                        "mobile-list-card-split-content-novalue": isImmaturePlantPackage
                            ? !isValue(item?.readyDate)
                            : !isValue(item?.cbdPercentage),
                        "mobile-list-card-split-content-value": isImmaturePlantPackage
                            ? isValue(item?.readyDate)
                            : isValue(item?.cbdPercentage)
                    })}
                    >
                        {
                            isImmaturePlantPackage
                                ? `Ready: ${isValue(item?.readyDate)
                                    ? formatToShortDateString(item?.readyDate)
                                    : "NA"
                                }`
                                : `CBD%: ${item?.cbdPercentage?.toLocaleString(undefined, percentFormatOptions) ?? "NA"}`
                        }
                    </div>
                </FlexColumnContainer>
            </FlexRowContainer>

        </FlexColumnContainer>
    };

    return <div style={{ width: "100%" }}>
        <OverlayWaitDisplay
            isWaiting={inventoryLoadState.isExecuting}
        >
            <DataView
                className='mobile-inv-list'
                dataKey="id"
                itemTemplate={itemTemplate}
                layout="list"
                value={inventory}
                paginator
                rows={10}
                paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                paginatorPosition="both"
                pageLinkSize={3}
                alwaysShowPaginator={false}
            />
        </OverlayWaitDisplay>
    </div>
}