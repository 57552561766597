import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { EntityImage } from "../../../commonModels/entityImage";
import { EntityVideo } from "../../../commonModels/entityVideo";

export const sliceName: string = "inventory-images";

export const actionFactory = new ActionFactory<InventoryImagesState>(sliceName);

export interface InventoryImagesState {
    imageLoadState: ActionStatus;    
    images: EntityImage[];
    videos: EntityVideo[];
    inventoryId?: number;
}