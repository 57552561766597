import { DateTime } from "luxon";
import { isValue } from "../../../utils/valueHelper";
import { IntegerId } from "../../commonModels/integerId";
import { SelectItem } from "../../commonModels/selectItem";
import { Inventory } from "../../inventorySetup/inventoryManagement/store/types";

export interface Customer extends IntegerId {
    name: string;
    accountNumber: string;
    tenantId: string;
    createdUtc: DateTime;
    modifiedUtc: DateTime;
}

export interface NewCustomer {
    name: string;
    accountNumber: string;
}
export interface CustomerNote extends IntegerId {
    customerId: number;
    notes: string;
    createdBy: string;
    createdUtc: DateTime;
    modifiedUtc: DateTime;
}

export const isCustomerNote = (value: CustomerNote | Partial<CustomerNote>): value is CustomerNote => {
    return isValue((value as CustomerNote).customerId) &&
        isValue((value as CustomerNote).notes);
}

export enum OrderStatus {
    Approved = "Approved",
    Canceled = "Canceled",
    Pending = "Pending",    
    Completed = "Completed"
}

export const OrderStatusOptions: SelectItem<OrderStatus>[] = [
    {
        label: "Pending",
        value: OrderStatus.Pending
    },
    {
        label: "Approved",
        value: OrderStatus.Approved
    },
    {
        label: "Canceled",
        value: OrderStatus.Canceled
    },   
    {
        label: "Completed",
        value: OrderStatus.Completed
    }

]

export const isTerminalOrderStatus = (value: OrderStatus | null | undefined): boolean => {    
    return isValue(value) && (
        value === OrderStatus.Canceled || 
        value === OrderStatus.Completed
    );
}

export interface SalesOrder extends IntegerId {
    customerId: number;
    companyId: number;
    createdUtc: DateTime;
    modifiedUtc: DateTime;
    status: OrderStatus;
    orderNumber: string;
    salesOrderItems: SalesOrderItem[];
}

export interface SalesOrderItem extends IntegerId {
    salesOrderId: number;
    productId: number;
    createdUtc: DateTime;
    modifiedUtc: DateTime;
    quantity: number;
    unitOfMeasure: string;
    price: number | null;
    inventoryName: string | null;
    inventoryId: number | null;
}

export const isSalesOrderItem = (value: SalesOrderItem | Partial<SalesOrderItem>): value is SalesOrderItem => {
    return isValue((value as SalesOrderItem).id) &&
        isValue((value as SalesOrderItem).productId) &&
        isValue((value as SalesOrderItem).salesOrderId) &&
        isValue((value as SalesOrderItem).quantity);
}

export interface SalesOrderWithDetail {
    salesOrder: SalesOrder;
    salesOrderItems: SalesOrderItem[];
    inventory: Inventory[];
}

export interface UpdateSalesOrder extends IntegerId {
    status: OrderStatus;
}

export interface Contact extends IntegerId {
    customerId: number;
    name: string;
    email: string | null;
    phoneNumber: string | null;
    position: string | null;
}

export const isContact = (value: Contact | Partial<Contact>): value is Contact => {
    return isValue((value as Contact).id) &&
        isValue((value as Contact).customerId) &&
        isValue((value as Contact).name);
} 