import { getEntities } from "../../../../../utils/entityStateHelpers";
import { assignChildrenNodes, TemplateConstraintNode } from "../../../models/constraintNode";
import { templateConstraintNodeAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    deleteTemplateConstraintNode,
    deleteTemplateConstraintNodeReducer,
    deleteTemplateConstraintNodeSagaFactory

} = actionFactory
    .withMappedName("deleteTemplateConstraintNode")
    .createAsyncAction<TemplateConstraintNode, Response>({
        actionStatusSelector: state => state.templateMetricSaveState,
        asyncCall: (arg, context) => {   
            if (arg.id > 0)        {
                return context.marketplaceApiClient.quality.deleteTemplateConstraintNode(arg.id);
            }
            else {
                return Promise.resolve(new Response(null, { status: 200 }));
            }            
        },
        onFulfilled: (state, action) => {

            templateConstraintNodeAdapter.removeOne(state.constraintNodes, action.payload.arg.id);

            const allNodes = getEntities(state.constraintNodes);
            assignChildrenNodes(allNodes);

            templateConstraintNodeAdapter.upsertMany(state.constraintNodes, allNodes);
        }
    });