import { CaseReducer, Draft, EntityAdapter, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { StrictEffect } from "redux-saga/effects";
import { ActionStatus, EntityActionStatus } from "../../../features/actionStatus/actionStatus";
import { CreateActionReturnType } from "../../../utils/reduxTypeHelper";
import { SagaAppContext } from "../../rootSaga";
import { IReducerFactory } from "../interfaces";
import { ErrorDisplayType, FulfilledPayload, NotAuthorizedAction, PendingPayload, RejectedPayload, SagaActions } from "../sagaWithProgress/types";


export interface EntityActionStatusOption<TSliceState, TArg> {
    adapterStateSelector: (state: Draft<TSliceState>) => EntityState<EntityActionStatus>,
    getId: (state: Draft<TSliceState>, arg: TArg) => number,
    adapter: EntityAdapter<EntityActionStatus>
}

export type ActionStatusOptions<TSliceState, TArg> =
    EntityActionStatusOption<TSliceState, TArg> |
    ((state: Draft<TSliceState>, arg: TArg) => ActionStatus)

export const isEntityActionStatusOption = <TSliceState, TArg>(value: ActionStatusOptions<TSliceState, TArg>): value is EntityActionStatusOption<TSliceState, TArg> => {
    return (value as EntityActionStatusOption<TSliceState, TArg>).adapter !== undefined &&
        (value as EntityActionStatusOption<TSliceState, TArg>).getId !== undefined &&
        (value as EntityActionStatusOption<TSliceState, TArg>).adapterStateSelector !== undefined;
}

export interface AsyncActionOptions<TSliceState, TArg, TAsyncResult> {
    /** The async call to execute. */
    asyncCall: (arg: TArg, context: SagaAppContext) => Promise<TAsyncResult>;

    /** The redux selector for the ActionStatus state to be updated when this action executes. */
    actionStatusSelector: ActionStatusOptions<TSliceState, TArg>;

    /** The redux reducer to be called when the async action completes successfully. */
    onFulfilled: CaseReducer<TSliceState, PayloadAction<FulfilledPayload<TArg, TAsyncResult>>>;

    /** An optional redux reducer to be called just prior to the async action executing. */
    onPending?: CaseReducer<TSliceState, PayloadAction<PendingPayload<TArg>>>;

    /** An optional redux reducer to be called when the async action fails. */
    onRejected?: CaseReducer<TSliceState, PayloadAction<RejectedPayload<TArg>>>;

    /** An optional debounce duration specified in milliseconds. */
    debounceInMilliseconds?: number;

    /** An optional callback that will be executed after the onFulfilled reducer is finished executing. */
    successCallback?: (arg: TArg, result: TAsyncResult, context: SagaAppContext) => void;

    /** An optional callback that will be executed after the onRejected reducer is finished executing. */
    rejectedCallback?: (arg: TArg, result: RejectedPayload<TArg>, context: SagaAppContext) => void;

    /** An optional control predicate. When the predicate returns true, the action is processed otherwise it is discarded. */
    predicate?: (arg: TArg, context: SagaAppContext) => boolean;
    
    errorDisplay?: ErrorDisplayType,
    
    notAuthorizedAction?: NotAuthorizedAction,
}

export interface NamedAsyncActionOptions<TSliceState, TArg, TApiResult> extends AsyncActionOptions<TSliceState, TArg, TApiResult>{
    /** The name of the action to create. */
    actionName: string
}
export interface CreateAsyncActionResult<TSliceState, TArg, TApiResult> {
    action: CreateActionReturnType<TArg>;
    reducerFactory: (reducerFactory: IReducerFactory<TSliceState>) => IReducerFactory<TSliceState>;
    sagaFactory: (appContext: SagaAppContext) =>  () => Generator<StrictEffect<any, any>, void, void>;
    sagaActions: SagaActions<TArg, TApiResult>
}
