import clsx from "clsx";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory, useParams } from "react-router-dom";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { createSafeUrlName } from "../../../../utils/safeUrlHelper";
import { TypedMenuItem } from "../../../../utils/table/typedMenuItem";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { isNumber, isValue } from "../../../../utils/valueHelper";
import { liveMenuRoute } from "../../../navigation/routes";
import { smallDeviceQuery } from "../../../responsiveconfiguration";
import { InventoryView } from "../../endUser/store/types";
import { createProductDetailsMenuItems } from "../../endUser/ui/inventoryCommands";
import { InventoryGridCard } from "../../endUser/ui/inventoryGridCard";
import { InventoryImageGallery } from "../../inventoryImageGallery/ui/inventoryImageGallery";
import { VendorTopPanel } from "../../vendorTopPanel/ui/vendorTopPanel";
import { loadInventoryDetails } from "../store/featureActions/loadInventoryDetails";
import { loadLiveMenu } from "../store/featureActions/loadLiveMenu";
import { loadVendor } from "../store/featureActions/loadVendor";
import { getCustomerDisplayName, getInventoryView, getPrimaryColor, getPrimaryFont, getProductDetailsLoadingState, getSecondaryColor, getSecondaryFont, getThumbnailImage, getUserOwnsMenu, getVendor } from "../store/selectors";
import { resetState } from "../store/slice";
import './index.css';

export interface ProductDetailsPageParams {
    companyName: string | undefined,
    menuName: string | undefined,
    inventoryId: string | undefined
};

export const ProductDetailsPage: React.FC = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const params = useParams<ProductDetailsPageParams>();
    const inventory = useAppSelector(getInventoryView);
    const thumbnailImage = useAppSelector(getThumbnailImage);  
    const productDetailsLoadingState = useAppSelector(getProductDetailsLoadingState);
    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    const vendor = useAppSelector(getVendor);

    const menuItems: TypedMenuItem<InventoryView>[] = createProductDetailsMenuItems(dispatch, params.companyName);
    const primaryColor = useAppSelector(getPrimaryColor);
    const secondaryColor = useAppSelector(getSecondaryColor);
    const primaryFont = useAppSelector(getPrimaryFont);
    const secondaryFont = useAppSelector(getSecondaryFont);
    const userOwnsMenu = useAppSelector(getUserOwnsMenu);

    

    useMountEffect(() => {

        if (isNumber(params.inventoryId) && isValue(params.companyName) && isValue(params.menuName)) {
            dispatch(loadVendor(params.companyName));
            dispatch(loadLiveMenu({ vendorName: params.companyName, menuName: params.menuName }));
            dispatch(loadInventoryDetails({ vendorName: params.companyName, menuName: params.menuName, inventoryId: params.inventoryId }));
        }

        return () => {
            dispatch(resetState());
        }
    });
   
    const onNavigateBackToMenu = () => {
        if (isValue(vendor)) {
            history.push(liveMenuRoute.createPath(vendor.vendorUrlName, createSafeUrlName(params.menuName)));
        }
    }

    return !isValue(params.companyName)
        ? <div>An unknown error occured</div>
        : <div className="main-content-panel">

            <div className="product-page-top-panel-container">
                <VendorTopPanel
                    vendorUrlName={params.companyName}
                    menuUrlName={params.menuName}
                    companyDisplayNameSelector={getCustomerDisplayName}
                    primaryColorSelector={getPrimaryColor}
                    secondardColorSelector={getSecondaryColor}
                />
            </div>

            <InventoryImageGallery />

            <div
                className={clsx("p-mx-2", {
                    "product-details-container-small": isSmallDevice,
                    "product-details-container": !isSmallDevice
                })}
            >
                <Button
                    className="p-ml-2 p-my-2 p-button-sm p-button-text"
                    style={{ display: isValue(params.menuName) ? undefined : "none" }}
                    icon={PrimeIcons.ARROW_LEFT}
                    label="Back to menu"
                    onClick={onNavigateBackToMenu}
                />

                <OverlayWaitDisplay
                    isWaiting={productDetailsLoadingState.isExecuting}
                >
                    <InventoryGridCard 
                        image={thumbnailImage}
                        inventory={inventory}
                        menuItems={menuItems}
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        primaryFont={primaryFont}
                        secondaryFont={secondaryFont}
                        userOwnsMenu={userOwnsMenu}
                        showDescription={true}
                    />
                    
                </OverlayWaitDisplay>
            </div>
        </div>
}