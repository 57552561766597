import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { OverlayWaitDisplay } from '../../../../components/waitDisplay';
import { useAppSelector } from '../../../../store/configureStore';
import { smallDeviceQuery } from '../../../responsiveconfiguration';
import { getIsLoading } from '../store/selectors/loading';
import { ListResultsView } from './listResultsView';
import './searchResults.css';
import { TableResultsView } from './TableResultsView';

export const SearchResults: React.FC = () => {

    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    const isLoading = useAppSelector(getIsLoading);

    return <OverlayWaitDisplay isWaiting={isLoading}>
        <div className={clsx({ "search-results-container-small": isSmallDevice, "search-results-container": !isSmallDevice })}>

            {
                isSmallDevice
                    ? <ListResultsView />
                    : <TableResultsView />
            }


        </div>
    </OverlayWaitDisplay >
}
