import { ColumnProps } from "primereact/column";

export interface FillRemainingSpaceOptions extends Omit<ColumnProps, "field" | "header" | "columnKey"> {
}

export function createFillRemainingSpaceColumnProps(props: FillRemainingSpaceOptions): ColumnProps {
    return {
        ...props,
        columnKey: "fill-remaining-space"
    };
}