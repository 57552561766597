import { ActionCreatorWithPayload, ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/types/types-external";
import { ActionReducerFactory, IActionReducerFactory } from "./action/actionReducerFactory";
import { IReducerFactory } from "./interfaces";
import { SagaActions } from "./sagaWithProgress/types";
import { ISagaWithProgressReducerFactory, SagaWithProgressReducerFactory } from "./sagaWithProgress/sagaWithProgressReducerFactory";

export class ReducerFactory<TSliceState> implements IReducerFactory<TSliceState> {

    private builder: ActionReducerMapBuilder<TSliceState>;

    private constructor(builder: ActionReducerMapBuilder<TSliceState>) {
        this.builder = builder;
    }

    public forSagaWithProgress<TArg, TResult>(sagaActions: SagaActions<TArg, TResult>): ISagaWithProgressReducerFactory<TSliceState, TArg, TResult> {
        return new SagaWithProgressReducerFactory<TSliceState, TArg, TResult>(this, this.builder, sagaActions);
    }

    public static forSlice<TSliceState>(builder: ActionReducerMapBuilder<TSliceState>): IReducerFactory<TSliceState> {
        return new ReducerFactory(builder);
    }

    public forAction = <TPayLoad>(action: ActionCreatorWithPayload<TPayLoad>): IActionReducerFactory<TSliceState, TPayLoad> => {
        return new ActionReducerFactory<TSliceState, TPayLoad>(this, this.builder, action);
    }

    public add = (delegate: (reducerFactory: IReducerFactory<TSliceState>) => IReducerFactory<TSliceState>): IReducerFactory<TSliceState> => {
        return delegate(this);
    }    

    public addRange = (delegates: ((reducerFactory: IReducerFactory<TSliceState>) => IReducerFactory<TSliceState>)[]): IReducerFactory<TSliceState> => {
        delegates.forEach(delegate => {
            delegate(this);
        });
        
        return this;
    }
}

export const createResetStateReducer = <TState,>(initialStateFactory: () => TState) => {
    return (state: WritableDraft<TState>, _: PayloadAction) => {
        const newInitialState = initialStateFactory();
        Object.assign(state, newInitialState);
    }
}