import { Tooltip } from 'primereact/tooltip';
import React from "react";
import { DefaultMaxImageSizeMB } from '../../utils/imageHelper';

export interface ImageUploadToolTipProps {
    maxFileSizeMB?: number;
    targetClassName?: string;
};

export const DefaultImageUploadToolTipTarget: string = "file-upload";

export const ImageUploadToolTip: React.FC<ImageUploadToolTipProps> = ({ 
    maxFileSizeMB = DefaultMaxImageSizeMB, 
    targetClassName = DefaultImageUploadToolTipTarget 
}) => {

    return <Tooltip target={`.${targetClassName}`} position="top">
        <div>Max file size is {maxFileSizeMB} MB</div>
    </Tooltip>
}