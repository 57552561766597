import { validStates } from '../../../../utils/usStates';
import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { TermsOfServiceAdminState } from './types';

export const createInitialState = (): TermsOfServiceAdminState => {
    return {
        loadingState: CreateActionStatusInitialState(),
        termsOfService: [],
        stateSearchResults: validStates,
        saveState: CreateActionStatusInitialState(),
        termsFilter: {
            inactiveFilterValues: []
        },
        editState: {
            saveState: CreateActionStatusInitialState(),
            stateAbbreviation: ""
        }
    };
};

export const initialState: TermsOfServiceAdminState = createInitialState();