import { useDispatch } from "react-redux";
import { FeatureContentPanel } from "../../../../components/containers/featureContentPanel";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { clearQualityReferenceData } from "../../../qualityAssessments/assessmentEditor/store/featureActions/clearQualityReferenceData";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { getLoadingState } from "../store/selectors/loadingState";
import { resetState } from "../store/slice";
import { AddQualityMetricKindDialog } from "./addQualityMetricKindDialog";
import { CommandBar } from "./commandBar";
import './index.css';
import { QualityMetricKindEditor } from "./metricKindEditor";
import { QualityMetricKindList } from "./metricKindList";

export const QualityMetricKindsDashboard: React.FC = () => {

    const dispatch = useDispatch();

    useMountEffect(() => {
        dispatch(loadSliceData());

        return () => {
            dispatch(resetState());
            dispatch(clearQualityReferenceData());
        }
    });

    return <FeatureContentPanel
        loadingStateSelector={getLoadingState}
        title="Quality Metrics"
    >
        <AddQualityMetricKindDialog />

        <FlexColumnContainer className="quality-metric-kind-dashboard">

            <CommandBar />

            <FlexRowContainer className="quality-metric-kind-content">
                <QualityMetricKindList />
                <QualityMetricKindEditor />
            </FlexRowContainer>

        </FlexColumnContainer>
        
    </FeatureContentPanel>
} 