import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { actionFactory, ProductManagementState } from "../../types";

export const beginBulkClearCategoryAssignment = actionFactory.createAppAction("beginBulkClearCategoryAssignment");

export function beginBulkClearCategoryAssignmentReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forAction(beginBulkClearCategoryAssignment)
        .addHandler((state, _) => {
            state.bulkClearCategoryAssignmentState.products = state.selectedProducts;
        })
        .build();
}