import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { createSalesOrderSagaFactory } from "../featureActions/createSalesOrder";
import { deleteCustomerContactSagaFactory } from "../featureActions/deleteCustomerContact";
import { deleteCustomerNoteSagaFactory } from "../featureActions/deleteCustomerNote";
import { editContactSagaFactory } from "../featureActions/editContact";
import { loadSliceDataSagaFactory } from "../featureActions/loadSliceData";
import { saveCustomerSagaFactory } from "../featureActions/saveCustomer";
import { upsertCustomerContactSagaFactory } from "../featureActions/upsertCustomerContact";
import { upsertCustomerNoteSagaFactory } from "../featureActions/upsertCustomerNote";
 
// Register this method in the root saga
export function createCustomerEditorSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        loadSliceDataSagaFactory(context),
        saveCustomerSagaFactory(context),
        upsertCustomerNoteSagaFactory(context),
        createSalesOrderSagaFactory(context),
        deleteCustomerNoteSagaFactory(context),
        upsertCustomerContactSagaFactory(context),
        deleteCustomerContactSagaFactory(context),
        editContactSagaFactory(context)
    ] 
}