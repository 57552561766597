import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { ImageDto } from "../../../commonModels/imageTypes";

export const sliceName: string = "company-profile";

export const actionFactory = new ActionFactory<CompanyProfileState>(sliceName);

export enum CompanyType {
    Supplier = "Supplier",
    Distributor = "Distributor",
    Retail = "Retail"
}

export const companyTypes: CompanyType[] = [
    CompanyType.Supplier,
    CompanyType.Distributor,
    CompanyType.Retail
];

export interface Company {
    id: number;
    tenantId: string | null;
    companyType: CompanyType | null;
    displayName: string | null;
    stateAbbreviation: string | null;    
}

export interface CompanyProfile extends Company {   
    urlName: string | null;
    subscriptionUrl: string | null;
    contactUrl: string | null;
    contactPhone: string | null;
    contactEmail: string | null;
}

export interface ImageState {
    saveState: ActionStatus;
    loadState: ActionStatus;
    newImagePreview?: string;
    currentImage: ImageDto | null;
}

export interface CompanyProfileState {
    profileLoadingState: ActionStatus;  
    profileSavingState: ActionStatus;  
    companyProfile: CompanyProfile; 
    stateSearchResults: string[];   
    logoState: ImageState;
    bannerState: ImageState; 
}