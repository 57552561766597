import { ConfirmDialog } from 'primereact/confirmdialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FeatureContentPanel } from "../../../../components/containers/featureContentPanel";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { clearQualityReferenceData } from "../../../qualityAssessments/assessmentEditor/store/featureActions/clearQualityReferenceData";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { getLoadingState } from "../store/selectors/loadingState";
import { resetState } from "../store/slice";
import { ChangeGradeKindDialog } from "./changeGradeKindDialog";
import { GeneralInfoEditor } from "./generalInfoEditor";
import './index.css';
import { SelectMetricsDialog } from "./selectMetricsDialog";
import { TemplateConstraintsEditor } from "./templateConstraintsEditor";
import { TemplateMetricsEditor } from "./templateMetricsEditor";
import { TestAssessmentEditor } from "./testAssessmentEditor";

export interface QualityTemplateEditorParams {
    id: string | undefined
};

export const QualityTemplateEditor: React.FC = () => {

    const dispatch = useDispatch();
    const routeParams = useParams<QualityTemplateEditorParams>();
    const templateId: number = routeParams.id === undefined ? -1 : Number.parseInt(routeParams.id);

    useMountEffect(() => {
        dispatch(loadSliceData(templateId));

        return () => {
            dispatch(resetState());
            dispatch(clearQualityReferenceData());
        }
    });

    return <FeatureContentPanel
        loadingStateSelector={getLoadingState}
        title="Quality Template Editor"
    >
        <SelectMetricsDialog />        
        <ChangeGradeKindDialog />
        <ConfirmDialog />
        
        
        <TabView renderActiveOnly={false} >
            <TabPanel key="general" header="General">
                <GeneralInfoEditor />
            </TabPanel>

            <TabPanel key="metrics" header="Metrics">
                <TemplateMetricsEditor />
            </TabPanel>

            <TabPanel key="constraints" header="Constraints">
                <TemplateConstraintsEditor />
            </TabPanel>

            <TabPanel key="test" header="Test">
                <TestAssessmentEditor />
            </TabPanel>
        </TabView>

    </FeatureContentPanel>
} 