import clsx from "clsx";
import { useFormikContext } from "formik";
import { sum } from "lodash";
import { CSSProperties } from "react";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { DropDownField } from "../../../../components/formik/dropDownField";
import { LiveSearchField } from "../../../../components/formik/liveSearchField";
import { NumberField } from "../../../../components/formik/numberField";
import { useAppSelector } from "../../../../store/configureStore";
import { setFormikNumberField, setFormikStringField } from "../../../../utils/formikHelper";
import { fourFractionalDigits } from "../../../../utils/numberHelper";
import { useEffectWithPartialDependencies, useMountEffect } from "../../../../utils/useEffectHelper";
import { isValue } from "../../../../utils/valueHelper";
import { SelectItem } from "../../../commonModels/selectItem";
import { Inventory } from "../../../inventorySetup/inventoryManagement/store/types";
import { SalesOrderItem } from "../../common/types";
import { clearSearchResults } from "../store/featureActions/clearSearchResults";
import { getOrderedQuantities } from "../store/featureActions/getOrderedQuantities";
import { searchInventory } from "../store/featureActions/searchInventory";
import { selectInventoryForOrderItem } from "../store/featureActions/selectInventoryForOrderItem";
import { getInventorySearchResults } from "../store/selectors/inventorySearch";
import { getOrderedQuantityLoadState, getOrderedQuantityResults } from "../store/selectors/orderedQuantities";
import { getSelectedInventory } from "../store/selectors/orderItemEditState";
import { getProductMap, getProducts } from "../store/selectors/product";
import { SalesOrderItemEditModel } from "../store/types";
import './orderItemEditor.css';

export const OrderItemEditor: React.FC = () => {

    const dispatch = useDispatch();
    const formikContext = useFormikContext<SalesOrderItemEditModel>();
    const products = useAppSelector(getProducts);
    const productIdMap = useAppSelector(getProductMap);
    const orderedQuantities = useAppSelector(getOrderedQuantityResults)
    const orderedQuantityLoadState = useAppSelector(getOrderedQuantityLoadState);
    const selectedInventory = useAppSelector(getSelectedInventory)


    const productOptions = products.map<SelectItem<number>>(product => ({
        label: product.metrcName,
        value: product.id
    }));

    useMountEffect(() => {
        if (isValue(formikContext.values.inventoryId)) {
            dispatch(getOrderedQuantities({ inventoryIds: [formikContext.values.inventoryId] }))
        }
    });

    useEffectWithPartialDependencies(() => {

        const quantityOnOtherOrders = sum(orderedQuantities
            .filter(oq => oq.salesOrderItemID !== formikContext.values.id)
            .map(oq => oq.quantity));

        const remainingQuantity = isValue(selectedInventory)
            ? (selectedInventory.quantity) - (selectedInventory.reservedQuantity ?? 0) - quantityOnOtherOrders
            : null;

        setFormikNumberField(formikContext, "remainingQuantity", remainingQuantity)

    }, [selectedInventory, orderedQuantities]);


    useEffectWithPartialDependencies(() => {

        if (isValue(formikContext.values.productId)) {
            const product = productIdMap[formikContext.values.productId];
            if (isValue(product)) {

                setFormikStringField<SalesOrderItemEditModel>(formikContext, "unitOfMeasure", product.unitOfMeasureName);
            }
        }
        else {
            setFormikStringField<SalesOrderItemEditModel>(formikContext, "unitOfMeasure", null);
        }

    }, [formikContext.values.productId]);

    const onSearchInventory = (searchText: string | null) => {
        dispatch(searchInventory({ packageLabel: searchText, productId: formikContext.values.productId }));
    }

    const onProductSelected = (value: number | null | undefined) => {
        const selectedInventoryId = formikContext.values.inventoryId;

        if (isValue(value) && isValue(selectedInventoryId)) {
            setFormikNumberField(formikContext, "inventoryId", null);
            setFormikStringField(formikContext, "inventoryName", null);
        }
    }

    const onInventorySearchCancel = () => {
        dispatch(clearSearchResults());
    }

    const onSelectedInventoryChanged = (value: Inventory | null) => {
        dispatch(selectInventoryForOrderItem(value));
        if (!isValue(value)) return;

        setFormikNumberField(formikContext, "productId", value.productId);
        dispatch(getOrderedQuantities({ inventoryIds: [value.id] }))
    }

    const labelStyle: CSSProperties = {
        display: "inline-block",
        width: "80px"
    }

    const inputStyle: CSSProperties = {
        width: "100%",
        maxWidth: "450px"
    }

    const containerStyle: CSSProperties = {
        width: "100%"
    }

    return <FlexColumnContainer gap="20px" marginTop="20px">

        <DropDownField<SalesOrderItem, number>
            fieldName="productId"
            defaultLabel="Product"
            availableValues={productOptions}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            panelStyle={{}}
            onSelectionChanged={onProductSelected}
            containerStyle={containerStyle}
        />

        <FlexColumnContainer>

            <LiveSearchField<SalesOrderItem, number, Inventory>
                label="Package"
                valueFieldName="inventoryId"
                labelFieldName="inventoryName"
                searchResultsSelector={getInventorySearchResults}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                getOptionLabel={inventory => inventory.metrcPackageLabel}
                getOptionValue={inventory => inventory.id}
                overlayClassName="order-item-inventory-search-overlay"
                onSearch={onSearchInventory}
                onCancelSelection={onInventorySearchCancel}
                onChange={onSelectedInventoryChanged}
            />

            {
                orderedQuantityLoadState.isExecuting &&
                <FlexRowContainer centerContent gap="10px">
                    <span className={clsx("pi pi-spin pi-spinner", "order-item-remaining-quantity-spinner")}></span>
                    <small>calculating available quantity....</small>
                </FlexRowContainer>
            }
            {
                !orderedQuantityLoadState.isExecuting &&
                isValue(selectedInventory) &&
                <small>
                    {`Available Quantity: ${fourFractionalDigits(formikContext.values.remainingQuantity)} ${formikContext.values.unitOfMeasure}`}
                </small>
            }

        </FlexColumnContainer>

        <FlexRowContainer centerContent gap="8px">
            <NumberField<SalesOrderItem>
                defaultLabel="Quantity"
                fieldName="quantity"
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                //containerStyle={containerStyle}
                minFractionDigits={2}
                maxFractionDigits={4}
                max={999999999999}
            />
            <div style={{ marginTop: "10px" }}>{formikContext.values.unitOfMeasure}</div>
        </FlexRowContainer>

        <NumberField<SalesOrderItem>
            defaultLabel="Price"
            fieldName="price"
            isCurrency
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            containerStyle={containerStyle}

        />

    </FlexColumnContainer>
} 