import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { FormikEditorClassStyles } from "../../../../components/formik/classNameAttributes";
import { DateField } from "../../../../components/formik/dateField";
import { FormikEmbeddedEditor } from "../../../../components/formik/embeddedEditor";
import { StringField } from "../../../../components/formik/stringField";
import { SwitchField } from "../../../../components/formik/switchField";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { QualityMetricKind } from "../../models/metricKind";
import { publishQualityMetricKind } from "../store/featureActions/publishQualityMetricKind";
import { upsertQualityMetricKind } from "../store/featureActions/upsertQualityMetricKind";
import { upsertQualityMetricValue } from "../store/featureActions/upsertQualityMetricValue";
import { getIsSelectedMetricKindEditable, getMetricValueSaveState, getSelectedMetricKind } from "../store/selectors/editing";
import { QualityMetricValueTable } from "./qualityMetricValueTable";

export const QualityMetricKindEditor: React.FC = () => {

    const dispatch = useDispatch();
    const selectedMetricKind = useAppSelector(getSelectedMetricKind);
    const saveState = useAppSelector(getMetricValueSaveState);
    const isEditable = useAppSelector(getIsSelectedMetricKindEditable);

    const onSaveMetricKind = (value: QualityMetricKind) => {
        dispatch(upsertQualityMetricKind(value));
    }

    const fieldClassNames: FormikEditorClassStyles = {
        containerClassName: "quality-metric-kind-field-container",
        inputClassName: "quality-metric-kind-field-input",
        labelClassName: "quality-metric-kind-field-label"
    }

    const onAddMetricValue = () => {
        if (!isValue(selectedMetricKind)) return;

        dispatch(upsertQualityMetricValue({
            metricKindId: selectedMetricKind.id,
            id: 0,
            name: "",
            sequence: 0
        }));
    }

    const onPublish = () => {
        if (!isValue(selectedMetricKind)) return;
        dispatch(publishQualityMetricKind(selectedMetricKind));
    }

    return !isValue(selectedMetricKind)
        ? <FlexColumnContainer className="quality-metric-kind-editor-no-selection" >No Selection</FlexColumnContainer>
        : <FlexColumnContainer className="quality-metric-kind-editor">

            <div className="quality-metric-kind-editor-title">Details</div>
            <FormikEmbeddedEditor<QualityMetricKind>
                initialValues={selectedMetricKind}
                onSave={onSaveMetricKind}
            >
                <FlexColumnContainer gap="20px">

                    <StringField<QualityMetricKind>
                        fieldName="name"
                        defaultLabel="Name"
                        submitOnBlur={true}
                        disabled={!isEditable}
                        {...fieldClassNames}
                    />

                    <SwitchField<QualityMetricKind>
                        defaultLabel="Active"
                        fieldName="inactive"
                        invertLogic={true}
                        submitOnChange={true}
                        disabled={saveState.isExecuting}
                        labelClassName={fieldClassNames.labelClassName}
                        containerClassName={fieldClassNames.containerClassName}
                    />

                    {
                        !isEditable
                            ? <DateField<QualityMetricKind>
                                defaultLabel="Published"
                                fieldName="publishedDate"
                                disabled={true}
                                labelClassName={fieldClassNames.labelClassName}
                            />
                            : <FlexRowContainer centerContent gap="10px">
                                <DateField<QualityMetricKind>
                                    defaultLabel="Published"
                                    fieldName="publishedDate"
                                    disabled={true}
                                    labelClassName={fieldClassNames.labelClassName}
                                />
                                <Button type="button" onClick={onPublish} >Publish Now</Button>
                            </FlexRowContainer>
                    }

                </FlexColumnContainer>
            </FormikEmbeddedEditor>

            <OverlayWaitDisplay
                isWaiting={saveState.isExecuting}
            >
                <FlexColumnContainer className="quality-metric-kind-editor-values-container">

                    <div className="quality-metric-kind-editor-title">Values</div>

                    <FlexRowContainer>
                        <Button onClick={onAddMetricValue} disabled={saveState.isExecuting || !isEditable}>Add Value</Button>
                    </FlexRowContainer>

                    <QualityMetricValueTable />

                </FlexColumnContainer>
            </OverlayWaitDisplay>

        </FlexColumnContainer>
}