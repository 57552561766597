import React from "react";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { RootState } from "../../../../store/rootReducer";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { EntityVideo } from "../../../commonModels/entityVideo";
import { entityVideoValidations } from "../../inventoryManagement/validations/entityVideoValidations";
import { AddVideoEditor } from "./addVideoEditor";

export interface AddVideoDialogProps {
    onSave: (value: EntityVideo) => void;
    onCancel: () => void;
    valueSelector: (state: RootState) => EntityVideo | null;
    visibleSelector: (state: RootState) => boolean;
    statusSelector: (state: RootState) => ActionStatus;
}

export const AddVideoDialog: React.FC<AddVideoDialogProps> = ({ onSave, onCancel, valueSelector, visibleSelector, statusSelector }) => {

    return <FormikModalEditor<EntityVideo>
        valueSelector={valueSelector}
        visibleSelector={visibleSelector}
        statusSelector={statusSelector}
        position="top"
        onSave={onSave}
        onCancel={onCancel}
        validationSchema={entityVideoValidations}        
        title="Add Video"
    >
        <AddVideoEditor />

    </FormikModalEditor>
}