import { useFormikContext } from "formik";
import { Message } from "primereact/message";
import React from "react";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { DropDownField } from "../../../../components/formik/dropDownField";
import { useAppSelector } from "../../../../store/configureStore";
import { getQualityAssessmentTemplates } from "../store/selectors/qualityGrade";
import { QualityAssessmentAssignment } from "../store/types";

export const CreateQualityAssessmentEditor: React.FC = () => {
    
    const qualityAssessmentTemplates = useAppSelector(getQualityAssessmentTemplates);
    const formikContext = useFormikContext<QualityAssessmentAssignment>();

    const message = `The assessment will apply to ${formikContext.values.inventoryIds.length} package${formikContext.values.inventoryIds.length > 1 ? "s" : ""}.`;

    return <FlexColumnContainer gap="20px">

            <DropDownField<QualityAssessmentAssignment, number>
                defaultLabel="Quality Template"
                fieldName="qualityAssessmentTemplateId"
                availableValues={qualityAssessmentTemplates.map(template => ({ label: template.name, value: template.id }))}
                containerClassName="create-inv-quality-assessment-dialog-field-container"
                labelClassName="create-inv-quality-assessment-dialog-field-label"
                inputClassName="create-inv-quality-assessment-dialog-field-input"
            />

            <Message severity="info" text={message}></Message>

        </FlexColumnContainer>
}