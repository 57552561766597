import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";

export const sliceName: string = "manage-profile";

export const actionFactory = new ActionFactory<ManageProfileState>(sliceName);

export interface MarketplaceProfile {
    id: number;
    username: string;
    email: string;
    tenantId: string;
    firstName: string | null;
    lastName: string | null;
    phoneNumber: string | null;    
}

export interface ManageProfileState {
    loadingState: ActionStatus;    
    savingState: ActionStatus;    
    MarketplaceProfile: MarketplaceProfile
}