import { FilterOperator } from "primereact/api";
import { DataTableFilterMetaData, DataTableOperatorFilterMetaData } from "primereact/datatable";
import { createIdentityMap } from "../../../../../utils/identity";
import { isValue } from "../../../../../utils/valueHelper";
import { DataTableViewState, TableState, ViewDefinition } from "../types";

export const updateSettingsFromTableDefinition = (tableState: TableState, viewDefinition: ViewDefinition) : TableState => {
    
    if (!isValue(tableState.additionalSettings)) return tableState;
    const additionalSettings: DataTableViewState = tableState.additionalSettings;
    const filters = additionalSettings.filters;

    const cleanedFilters: { [key: string]: DataTableFilterMetaData | DataTableOperatorFilterMetaData } = {};
    const columnMap = createIdentityMap(viewDefinition.columnState, col => col.columnId);

    // Remove filters for columns that no longer exist
    Object.keys(filters).forEach(key => {

        if (isValue(columnMap[key])) {
            cleanedFilters[key] = filters[key];
        }
    })

    tableState.columnState.forEach(col => {
        if (col.isVisible) {
            if (!isValue(cleanedFilters[col.columnId])) {
                // Add the filter

                const matchingColumn = columnMap[col.columnId];

                if (matchingColumn.filter) {
                    cleanedFilters[col.columnId] = {
                        operator: FilterOperator.AND,
                        constraints: [
                            {
                                matchMode: matchingColumn.filterMatchMode ?? "startsWith",
                                value: null
                            }
                        ]
                    };
                }
            }
        }
    });

    return {        
        columnState: tableState.columnState,
        additionalSettings: {
            ...tableState.additionalSettings,
            filters: cleanedFilters
        }
    };
}