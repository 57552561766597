import { Inventory } from "../../../../inventorySetup/inventoryManagement/store/types";
import { actionFactory } from "../types";

export const {

    selectInventoryForOrderItem,
    selectInventoryForOrderItemReducer

} = actionFactory
    .withMappedName("selectInventoryForOrderItem")
    .createAction<Inventory | null>((state, action) => {
        state.selectedInventory = action.payload;
    });