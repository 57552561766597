import React from 'react';
import { CenterContentResponsivePanel } from '../../components/responsiveContainer';
import { BuyerSignUpCard } from '../../components/responsiveCard/buyerSignUpCard';
import { ContactNodeCard } from '../../components/responsiveCard/contactNodeCard';
import { SellerSignUpCard } from '../../components/responsiveCard/sellerSignUpCard';

export const MarketingPanel: React.FC = () => {

    return <CenterContentResponsivePanel>

        <BuyerSignUpCard />

        <SellerSignUpCard />

        <ContactNodeCard />

    </CenterContentResponsivePanel>
}