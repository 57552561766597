import { createSelector } from "reselect";
import { RootState } from "../../../../store/rootReducer";
import { isValue } from "../../../../utils/valueHelper";

export const getUserName = createSelector(
    [
        (state: RootState) => state.authentication.CurrentUser,
        (state: RootState) => state.manageProfile.MarketplaceProfile
    ],
    (currentUser, profile) => {
        return profile !== null && isValue(profile.firstName) && profile.firstName.length > 0
                ? `${profile.firstName} ${profile.lastName}`
                : currentUser !== null 
                    ? currentUser.userSettings.profile.email
                    : "unknown";
    }
);