import { Vendor } from "../../../productDetails/store/types";
import { actionFactory } from "../types";

export const {

    loadVendor,
    loadVendorReducer,
    loadVendorSagaFactory

} = actionFactory
    .withMappedName("loadVendor")
    .createAsyncAction<string, Vendor>({
        actionStatusSelector: state => state.vendorLoadingState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.liveMenu.getVendor(arg);
        },
        onFulfilled: (state, action) => {
            state.vendor = action.payload.result;
        },
        debounceInMilliseconds: 250
    });