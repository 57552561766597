import { ErrorDisplayType } from "../../../../../../store/factory/sagaWithProgress/types";
import { ImageKind, ImageDto } from "../../../../../commonModels/imageTypes";
import { actionFactory } from "../../types";

export const {

    loadLogo,
    loadLogoReducer,
    loadLogoSagaFactory

} = actionFactory
    .withMappedName("loadLogo")
    .createAsyncAction<{ menuId: number }, ImageDto>({
        actionStatusSelector: state => state.logoState.loadState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.menuAdmin.getImage(arg.menuId, ImageKind.Logo)
        },
        onFulfilled: (state, action) => {
            state.logoState.currentImage = action.payload.result;   
        },
        errorDisplay: ErrorDisplayType.None
    });