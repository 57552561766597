import { ActionCreatorWithPayload, ActionReducerMapBuilder, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { IReducerFactory } from "../interfaces";

export interface IActionReducerFactory<TSliceState, TPayLoad> {
    build() : IReducerFactory<TSliceState>;
    addHandler(handler: CaseReducer<TSliceState, PayloadAction<TPayLoad>>) : IActionReducerFactory<TSliceState, TPayLoad>;
};

export class ActionReducerFactory<TSliceState, TPayLoad> implements IActionReducerFactory<TSliceState, TPayLoad> {

    private reducerFactory: IReducerFactory<TSliceState>;
    private builder: ActionReducerMapBuilder<TSliceState>;
    private actionCreator: ActionCreatorWithPayload<TPayLoad>;
    private caseReducers: CaseReducer<TSliceState, PayloadAction<TPayLoad>>[];

    constructor(
        reducerFactory: IReducerFactory<TSliceState>,
        builder: ActionReducerMapBuilder<TSliceState>,
        actionCreator: ActionCreatorWithPayload<TPayLoad>) {

        this.reducerFactory = reducerFactory;
        this.builder = builder;  
        this.actionCreator = actionCreator;  
        this.caseReducers = [];
    }

    public build = (): IReducerFactory<TSliceState> => {
        this.builder.addCase(this.actionCreator, (state, action) => {
            this.caseReducers.forEach(caseReducer => {
                caseReducer(state, action);
            });
        });        
        return this.reducerFactory;
    }

    public addHandler = (handler: CaseReducer<TSliceState, PayloadAction<TPayLoad>>) : ActionReducerFactory<TSliceState, TPayLoad> => {
        this.caseReducers.push(handler)        
        return this;
    };
}