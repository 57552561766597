import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { corporateEntityAdapter } from "../state";
import { actionFactory, CorporateEntity, CorporateEntityState, isSavableCorporateEntity } from "../types";

export const completeEdit = actionFactory.createAppAction<Partial<CorporateEntity>>("completeEdit");

const sagaActions = actionFactory.createSagaWithProgressActions<Partial<CorporateEntity>, CorporateEntity>(completeEdit.type);

export function completeEditReducers(reducerFactory: IReducerFactory<CorporateEntityState>): IReducerFactory<CorporateEntityState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.loadingState)
        .onFulfilled((state, action) => {
            corporateEntityAdapter.upsertOne(state.corporateEntities, action.payload.result);    
            state.entityEditDialogVisible = false;
            state.entityEditValue = null;
        })
        .build();
}

async function performAction(arg: Partial<CorporateEntity>, context: SagaAppContext): Promise<CorporateEntity> {
    const state = context.getState();
    const editValue = state.corporateEntityManagement.entityEditValue;
    if (editValue === null) throw new Error("Cannot save without a valid value");

    const updatedEntity = {...editValue, ...arg};

    if (!isSavableCorporateEntity(updatedEntity)) return Promise.reject("Corporate entity is not valid");
    return context.marketplaceApiClient.corporateEntity.saveCorporateEntity(updatedEntity);
}

export function createCompleteEditSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: completeEdit.type,
        debounceInMilliseconds: 250
    });
};