import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { isValue } from "../../../../../utils/valueHelper";
import { actionFactory, ProductManagementState } from "../types";

export const updateProductAssignmentMaps = actionFactory.createAppAction("updateProductAssignmentMaps");

export function updateInventoryAssignmentMapsReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forAction(updateProductAssignmentMaps)
        .addHandler((state, _) => {
            const productMenuMap: { [key: number]: boolean } = {};

            state.menuAssignments.forEach(menuAssignment => {
                menuAssignment.productIds.forEach(productId => {                    
                    productMenuMap[productId] = true;
                });
            });

            state.products.ids.forEach(productId => {
                const product = state.products.entities[productId];
                if (isValue(product)) {                    
                    product.onMenuDirectly =  !!productMenuMap[product.id];
                }
            });
          
        })
        .build();
}