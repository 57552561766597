import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { ColumnProps } from "primereact/column";
import React, { CSSProperties } from 'react';
import { BooleanOrNullKeyOf, getBooleanOrNullValue, PropertiesOfType } from "../typeHelpers";
import { BooleanFilterElement } from './filters/booleanFilterElement';
import { BooleanFilterOptions } from './filters/booleanFilterTypes';

interface CheckBoxTemplateProps<T> {
    getValueFn: (rowData: T) => boolean | null | undefined;
    onChange?: (value: T, checked: boolean) => void;
    disabledFn?: (value: T) => boolean;
}
const createCheckBoxTemplate = <T,>({ getValueFn, onChange, disabledFn }: React.PropsWithChildren<CheckBoxTemplateProps<T>>) => {
    return (rowData: T) => {
        const value = getValueFn(rowData);
        const disabled = !!disabledFn ? disabledFn(rowData) : false;

        const onChangeHandler = !!onChange
            ? (e: CheckboxChangeParams) => onChange(rowData, e.checked)
            : undefined;

        return value !== undefined && value !== null
            ? <Checkbox checked={value} onChange={onChangeHandler} disabled={disabled} />
            : <Checkbox checked={false} />
    }
};

export interface CreateBooleanColumnOptions<T extends {}> extends Omit<ColumnProps, "field" | "header" | "body" | "style"> {
    field: BooleanOrNullKeyOf<T> & string,
    header: string,
    disabledFn?: (value: T) => boolean,
    style?: CSSProperties;
    onChange?: (value: T, checked: boolean) => void,
    customTemplateFn?: (value: T) => React.ReactNode
}

// export function createBooleanColumn<T extends PropertiesOfType<T, boolean | null | undefined>>(
//     props: CreateBooleanColumnOptions<T>): React.ReactNode {

//     const getValueFn = (rowData: T) => getBooleanOrNullValue(rowData, props.field);
//     const bodyTemplate = props.customTemplateFn ?? createCheckBoxTemplate({ getValueFn: getValueFn, disabledFn: props.disabledFn });

//     return <Column key={props.columnKey ?? props.field} {...props} body={bodyTemplate} />;
// }

export interface CreateDerivedBooleanColumnOptions<T> extends Omit<ColumnProps, "body"> {
    getValueFn: (rowData: T) => boolean | null | undefined
    disabledFn?: (value: T) => boolean,
    customTemplateFn?: (value: T) => React.ReactNode
}

// export function createDerivedBooleanColumn<T>(props: CreateDerivedBooleanColumnOptions<T>): React.ReactNode {

//     const bodyTemplate = props.customTemplateFn ?? createCheckBoxTemplate({ getValueFn: props.getValueFn, disabledFn: props.disabledFn });
//     return <Column key={props.columnKey} {...props} body={bodyTemplate} />;
// }

export function createDerivedBooleanColumnProps<T>(props: CreateDerivedBooleanColumnOptions<T>): ColumnProps {
    const bodyTemplate = props.customTemplateFn ?? createCheckBoxTemplate({ getValueFn: props.getValueFn, disabledFn: props.disabledFn });

    return {
        columnKey: props.columnKey ?? props.field,
        ...props,
        body: bodyTemplate
    };
}

export function createBooleanColumnProps<T extends PropertiesOfType<T, boolean | null | undefined>>(
    props: CreateBooleanColumnOptions<T>): ColumnProps {

    const getValueFn = (rowData: T) => getBooleanOrNullValue(rowData, props.field);
    const bodyTemplate = props.customTemplateFn ?? createCheckBoxTemplate({ getValueFn: getValueFn, disabledFn: props.disabledFn, onChange: props.onChange });

    return { columnKey: props.columnKey ?? props.field, ...props, body: bodyTemplate };
}

export interface FilteredBooleanColumnOptions<T extends PropertiesOfType<T, boolean | null | undefined>> extends CreateBooleanColumnOptions<T>, BooleanFilterOptions { }

export function createFilteredBooleanColumnProps<T extends PropertiesOfType<T, boolean | null | undefined>>(props: FilteredBooleanColumnOptions<T>): ColumnProps {

    const getValueFn = (rowData: T) => getBooleanOrNullValue(rowData, props.field);
    const disabled = (_: T) => false;

    const bodyTemplate = props.customTemplateFn ?? createCheckBoxTemplate({ getValueFn: getValueFn, disabledFn: disabled, onChange: props.onChange });

    return {
        ...props,
        body: bodyTemplate,
        filter: true,
        filterType: "boolean",
        dataType: "boolean",
        showFilterMatchModes: false,
        showFilterMenuOptions: false,
        showFilterOperator: false,
        filterField: props.field,
        sortable: true,
        filterMatchMode: FilterMatchMode.IN,
        filterElement: (elementOptions) => <BooleanFilterElement
            trueLabel={props.trueLabel}
            falseLabel={props.falseLabel}
            elementOptions={elementOptions}
        />
    };
}

export const createInitialInactiveFilterState = () => {
    return {
        operator: FilterOperator.AND,
        constraints: [{
            value: ["noMatch", false],
            matchMode: FilterMatchMode.IN
        }]
    }
}

