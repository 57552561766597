import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { addQualityGradeKindBegin } from "../store/featureActions/addQualityGradeKind";

export const CommandBar: React.FC = () => {

    const dispatch = useDispatch();

    const onAddNewGradeKind = () => {
        dispatch(addQualityGradeKindBegin());
    }

    return <FlexRowContainer>
        <Button onClick={onAddNewGradeKind} >Add Grade Kind</Button>
    </FlexRowContainer>

} 