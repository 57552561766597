import { IntegerId } from "../../../../../commonModels/integerId";
import { productNotesAdapter } from "../../state";
import { actionFactory, ProductNote } from "../../types";

export const {

    loadProductNotes,
    loadProductNotesReducer,
    loadProductNotesSagaFactory

} = actionFactory
    .withMappedName("loadProductNotes")
    .createAsyncAction<IntegerId, ProductNote[]>({
        actionStatusSelector: state => state.productNoteLoadStatus,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.productManagement.getProductNotes(arg.id);
        },
        onFulfilled: (state, action) => {
            productNotesAdapter.setAll(state.editProductNotes, action.payload.result);            
        }
    });