import { actionFactory } from "../../types";

export const {

    setNewBanner,
    setNewBannerReducer

} = actionFactory
    .withMappedName("setNewBanner")
    .createAction<string>((state, action) => {
        state.bannerState.newImagePreview = action.payload;
    });
