import { DateTime } from "luxon";
import { all } from "./arrayHelpers";

export type PropertiesOfType<Model extends {}, Type> = {
    [property in keyof Model as Model[property] extends Type ? property : never]: Type
}

export type TypedKeyOf<TModel extends {}, TValue> = keyof PropertiesOfType<TModel, TValue> & keyof TModel;

export const getPropertyName = <T>(name: keyof T & string) : keyof T & string => {
    return name;
}

export type DateTimeOrNullKeyOf<T extends {}> = keyof PropertiesOfType<T, DateTime | null | undefined> & keyof T;
export type DateTimeKeyOf<T extends {}> = keyof PropertiesOfType<T, DateTime>;
export type StringOrNullKeyOf<T extends {}> = keyof PropertiesOfType<T, string | null | undefined>;
export type StringKeyOf<T extends {}> = keyof PropertiesOfType<T, string>;
export type NumberOrNullKeyOf<T extends {}> = keyof PropertiesOfType<T, number | null | undefined>;
export type NumberKeyOf<T extends {}> = keyof PropertiesOfType<T, number>;
export type BooleanKeyOf<T extends {}> = keyof PropertiesOfType<T, boolean>;
export type BooleanOrNullKeyOf<T extends {}> = keyof PropertiesOfType<T, boolean | null | undefined>;
export type NumberArrayKeyOf<T extends {}> = keyof PropertiesOfType<T, number[]>;

export const createPropertyGetterOfType = <PropertyType extends unknown>() =>
    <T extends PropertiesOfType<T, PropertyType>>(model: T, property: keyof PropertiesOfType<T, PropertyType>) =>
        model[property];

export const createPropertySetterOfType = <PropertyType extends unknown>() =>
    <T extends PropertiesOfType<T, PropertyType>>(model: T, property: keyof PropertiesOfType<T, PropertyType>, value: PropertyType): void => {
        model[property] = value as T[keyof PropertiesOfType<T, PropertyType>];
    };

export const getStringValue = createPropertyGetterOfType<string>();
export const getStringOrNullValue = createPropertyGetterOfType<string | null | undefined>();
export const getNumberValue = createPropertyGetterOfType<number>();
export const getNumberOrNullValue = createPropertyGetterOfType<number | null | undefined>();
export const getBooleanValue = createPropertyGetterOfType<boolean>();
export const getBooleanOrNullValue = createPropertyGetterOfType<boolean | null | undefined>();
export const getDateTimeValue = createPropertyGetterOfType<DateTime>();
export const getDateTimeOrNullValue = createPropertyGetterOfType<DateTime | null | undefined>();
export const getNumberArrayValue = createPropertyGetterOfType<number[]>();

export const setStringValue = createPropertySetterOfType<string>();
export const setStringOrNullValue = createPropertySetterOfType<string | null | undefined>();
export const setNumberValue = createPropertySetterOfType<number>();
export const setNumberOrNullValue = createPropertySetterOfType<number | null | undefined>();
export const setBooleanValue = createPropertySetterOfType<boolean>();
export const setDateTimeValue = createPropertySetterOfType<DateTime>();
export const setDateTimeOrNullValue = createPropertySetterOfType<DateTime | null | undefined>();

export function keysAreUndefined<T>(object: T | Partial<T>, keys: (keyof T)[]): boolean {
    return all(keys, key => object[key] === undefined);
}

export function keysAreUndefinedNullOrEmpty<T>(object: T | Partial<T>, keys: (keyof T)[]): boolean {
    return all(keys, key => {
        const value = object[key];
        return value === undefined ||
            value === null ||
            (typeof (value) === "string" && value.length === 0)
    });
}

export const getTypedKeys = <T extends {}>(object: T) => {
    return Object.keys(object) as (keyof T)[];
}
