import { FilterMatchMode } from 'primereact/api';
import { ColumnFilterElementTemplateOptions, ColumnProps } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

export interface MultiSelectFilterOption<T> {
    label: string;
    value: T;
}

export interface MultiSelectFilterModel<T> {
    selectedOptions: T[];
}

interface MultiSelectFilterElementProps<T> {
    elementOptions: ColumnFilterElementTemplateOptions;
    selectOptions: MultiSelectFilterOption<T>[];
}

function isMultiSelectFilterModel<T>(value: any): value is MultiSelectFilterModel<T> {
    return (value as MultiSelectFilterModel<T>)?.selectedOptions !== undefined;
}

export const MultiSelectFilterElement = <T,>({ elementOptions, selectOptions }: MultiSelectFilterElementProps<T>) => {
    
    const filterModel: MultiSelectFilterModel<T> = isMultiSelectFilterModel<T>(elementOptions.value)
        ? elementOptions.value
        : { selectedOptions: [] };

    return <MultiSelect
        value={filterModel.selectedOptions}
        options={selectOptions}
        maxSelectedLabels={2}
        onChange={(e) => {            
            const newFilterModel: MultiSelectFilterModel<T> = {
                ...filterModel,
                selectedOptions: e.value
            };
            elementOptions.filterCallback(newFilterModel);
        }}
    />;
};

export type FilterProps = Pick<ColumnProps, "filter" | "filterMatchMode" | "showFilterMatchModes" | "showFilterMenuOptions"
    | "showFilterOperator" | "showAddButton" | "filterFunction" | "filterElement">;

export interface MultiSelectFilterProps<T> {
    filterFunction: (value: T, filterModel: MultiSelectFilterModel<T>) => boolean,
    selectOptions: MultiSelectFilterOption<T>[]
}
export const createMultiSelectFilterProps = <T,>({ filterFunction, selectOptions }: MultiSelectFilterProps<T>): FilterProps => {

    return {
        filter: true,
        filterMatchMode: FilterMatchMode.CUSTOM,
        showFilterMatchModes: false,
        showFilterMenuOptions: false,
        showFilterOperator: false,
        showAddButton: false,
        filterFunction: filterFunction,
        filterElement: (options) => <MultiSelectFilterElement elementOptions={options} selectOptions={selectOptions} />
    }
}