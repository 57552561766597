import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { ErrorDisplayType } from "../../../../../store/factory/sagaWithProgress/types";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory, ServerHealth, ServerInformationState } from "../types";

export const forceToastErrorInSaga = actionFactory.createAppAction("forceToastErrorInSaga");

const sagaActions = actionFactory.createSagaWithProgressActions<void, ServerHealth>(forceToastErrorInSaga.type);

export function forceToastErrorInSagaReducers(reducerFactory: IReducerFactory<ServerInformationState>): IReducerFactory<ServerInformationState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)              
        .build();
}

async function performAction(_: void, context: SagaAppContext): Promise<ServerHealth> {
    return context.marketplaceApiClient.diagnostics.notFound();
}

export function createForceToastErrorInSagaSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions,
            errorDisplay: ErrorDisplayType.Toast,
            errorTitle: "Diagnostic Test Error"
        },
        actionPattern: forceToastErrorInSaga.type,
        debounceInMilliseconds: 250
    });
};