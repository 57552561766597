import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../../store/rootSaga";
import { CreateActionStatusInitialState } from "../../../../../actionStatus/actionStatus";
import { actionFactory, ProductManagementState } from "../../types";
import { loadMenuAssignments } from "../loadMenuAssignments";

export const completeCategoryAssignment = actionFactory.createAppAction<number[]>("completeCategoryAssignment");

const sagaActions = actionFactory.createSagaWithProgressActions<number[], Response>(completeCategoryAssignment.type);

export function completeCategoryAssignmentReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.categoryAssignmentState.savingState)
        .onFulfilled((state, _) => {
            state.categoryAssignmentState = {                 
                loadingState: CreateActionStatusInitialState(),
                savingState: CreateActionStatusInitialState()
            } 
        })
        .build();
}

async function performAction(categoryIds: number[], context: SagaAppContext) {      
    const state = context.getState();
    if (state.productManagement.categoryAssignmentState.parentModel === undefined) throw new Error("Cannot save product category assignments without a valid product selected");
    const product = state.productManagement.categoryAssignmentState.parentModel;
    return context.marketplaceApiClient.productManagement.setCategoryIdsForProduct(product.id, categoryIds);
}

export function createCompleteCategoryAssignmentSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions,
            successCallback: (_arg, _result, context) => context.dispatch(loadMenuAssignments())
        },
        actionPattern: completeCategoryAssignment.type,
        debounceInMilliseconds: 250        
    });
};