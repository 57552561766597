import clsx from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Galleria } from 'primereact/galleria';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { OverlayWaitDisplay } from '../../../../components/waitDisplay';
import { useAppSelector } from "../../../../store/configureStore";
import { createLocalImageUrlFromEntityImage } from '../../../../utils/imageHelper';
import { isEntityImage } from "../../../commonModels/entityImage";
import { MixedMedia } from '../../../commonModels/mixedMedia';
import { smallDeviceQuery } from '../../../responsiveconfiguration';
import { loadInventoryMedia } from '../store/featureActions/loadInventoryMedia';
import { getInventoryMedia, getMediaLoadingState, getShowInventory } from '../store/selectors';
import { resetState } from '../store/slice';
import './inventoryImageGallery.css';


export const InventoryImageGallery: React.FC = () => {

    const inventoryMedia = useAppSelector(getInventoryMedia);
    const imagesLoadingState = useAppSelector(getMediaLoadingState);
    const showInventory = useAppSelector(getShowInventory);
    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    const galleryRef = useRef<Galleria>(null);
    const dispatch = useDispatch();

    const itemTemplate = (item: MixedMedia) => {

        if (isEntityImage(item)) {

            return <img
                src={createLocalImageUrlFromEntityImage(item)}
                alt={item.imageTitle}

                style={{
                    maxHeight: "90vh",
                    maxWidth: "90vw",
                    objectFit: "contain",
                    display: 'block'
                }}
            />;
        }
        else {
            return <iframe
                    className='live-menu-gallery-video'
                    title={item.title}
                    src={item.embedUrl}
                    // width="640"
                    // height="360"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"                    
                />
        }
    }

    const onHideGallery = () => {
        dispatch(resetState());
    }

    useEffect(() => {
        if (showInventory && !!!imagesLoadingState.hasExecuted) {
            dispatch(loadInventoryMedia());
        }

    }, [dispatch, imagesLoadingState.hasExecuted, showInventory]);

    useEffect(() => {
        if (showInventory && imagesLoadingState.hasExecuted && inventoryMedia.length > 0) {
            setTimeout(() => {
                if (galleryRef.current !== null) {
                    galleryRef.current.show();
                }
            }, 650);
        }
    }, [inventoryMedia, imagesLoadingState.hasExecuted, showInventory]);

    return <div>
        <Dialog
            visible={showInventory && imagesLoadingState.hasExecuted && inventoryMedia.length === 0}
            onHide={onHideGallery}
            closable
            showHeader={false}
            dismissableMask={true}
        >
            <div>
                <div
                    className='p-d-flex p-jc-start p-ai-center'
                >
                    <i className={clsx(PrimeIcons.INFO_CIRCLE, "message-dialog-icon-info")} ></i>
                    <h3 className="p-ml-2">No images are available</h3>
                </div>

                <Divider />

                <div
                    className='p-d-flex p-jc-center p-ai-center p-mt-4 p-mb-2'
                >
                    <Button label="Ok" onClick={onHideGallery} />
                </div>

            </div>
        </Dialog>

        <OverlayWaitDisplay
            isWaiting={imagesLoadingState.isExecuting}
            fullScreen={true}
        />
        <Galleria
            ref={galleryRef}
            className={clsx({
                "product-page-image-gallery": !isSmallDevice,
                "product-page-image-gallery-small": isSmallDevice
            })}
            onHide={onHideGallery}
            value={inventoryMedia}
            circular
            fullScreen
            showItemNavigators
            indicatorsPosition="bottom"
            showThumbnails={false}
            item={itemTemplate}
        />
    </div>

}