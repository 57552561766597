import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { licenseAdapter } from "../state";
import { actionFactory, License, SyncAdminLicenseState } from "../types";

export const saveLicense = actionFactory.createAppAction<License>("saveLicense");

const sagaActions = actionFactory.createSagaWithProgressActions<License, License>(saveLicense.type);

export function saveLicenseReducers(reducerFactory: IReducerFactory<SyncAdminLicenseState>): IReducerFactory<SyncAdminLicenseState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .onPending((state, action) => {
            const saveState = state.licenseSaveState[action.payload.arg.id];
            saveState.isExecuting = true;
        })
        .onRejected((state, action) => {
            const saveState = state.licenseSaveState[action.payload.arg.id];
            saveState.errorContent = action.payload.errorResult;
            saveState.isExecuting = false;
        })
        .onFulfilled((state, action) => {
            licenseAdapter.upsertOne(state.licenses, action.payload.result);
            const saveState = state.licenseSaveState[action.payload.arg.id];
            saveState.isExecuting = false;
        })
        .build();
}

async function performAction(arg: License, context: SagaAppContext): Promise<License> {
    return context.marketplaceApiClient.syncAdmin.saveLicense(arg);
}

export function createSaveLicenseSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: saveLicense.type,
        debounceInMilliseconds: 250
    });
};