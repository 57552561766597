import { isValue } from "../../../../../utils/valueHelper";
import { updateSettingsFromTableDefinition } from "../logic/settingsUpdate";
import { viewDefinitionsAdapter, viewSettingsAdapter } from "../state";
import { actionFactory, TableState, ViewDefinition, ViewSettings } from "../types";

export const {

    updateTableState,
    updateTableStateReducer
 
} = actionFactory
    .withMappedName("updateTableState")
    .createAction<{ viewId: string, update: Partial<TableState>, flagSentinel: boolean}>((state, action) => {
        const viewId = action.payload.viewId;
        const settings = state.viewSettings.entities[viewId];

        const viewDefinition = state.viewDefinitions.entities[viewId];

        const additionalSettings = action.payload.update.additionalSettings;

        if (isValue(additionalSettings) && !isValue(viewDefinition?.tableState)) {
            // Table initialization. Record clean state to merge with existing settings
            const defaultDefinition: ViewDefinition = {
                columnState: action.payload.update.columnState ?? viewDefinition?.columnState ?? [],
                tableState: additionalSettings,
                id: viewId
            }

            viewDefinitionsAdapter.upsertOne(state.viewDefinitions, defaultDefinition)
        }

        if (!isValue(settings)) return;

        if (!isValue(viewDefinition)) return;

        const mergedSettings: ViewSettings = {
            id: settings.id,
            selectedUserTableSettings: settings.selectedUserTableSettings,
            currentState: {
                ...settings.currentState,
                ...action.payload.update
            },
            remountState: settings.remountState,
            restoreSentinel: action.payload.flagSentinel
        };

        const cleanedTableState = updateSettingsFromTableDefinition(mergedSettings.currentState, viewDefinition);

        const cleanedSettings: ViewSettings = {
            id: settings.id,
            selectedUserTableSettings: settings.selectedUserTableSettings,
            currentState: cleanedTableState,
            remountState: settings.remountState,
            restoreSentinel: action.payload.flagSentinel
        };

        viewSettingsAdapter.upsertOne(state.viewSettings, cleanedSettings);
    });