import clsx from 'clsx';
import { Tooltip } from 'primereact/tooltip';
import { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlexRowContainer } from '../../../../components/containers/flexRowContainer';
import { PopUpMenuButton } from '../../../../components/popMenuButton';
import { useAppSelector } from "../../../../store/configureStore";
import { formatToShortDateString } from '../../../../utils/datetimeHelper';
import { currencyFormatOptions, percentFormatOptions } from '../../../../utils/formatHelpers';
import { createLocalImageUrl } from '../../../../utils/imageHelper';
import { TypedMenuItem } from '../../../../utils/table/typedMenuItem';
import { isValue } from '../../../../utils/valueHelper';
import { showMediaForInventory } from '../../inventoryImageGallery/store/featureActions/showMediaForInventory';
import { getInventoryById, getInventoryImageById } from '../store/selectors/inventory';
import { getPrimaryColor, getSecondaryColor } from '../store/selectors/menuColors';
import { getSecondaryFont } from '../store/selectors/menuFonts';
import { getMenuUrlName, getVendorRouteName } from '../store/selectors/vendorMenu';
import { InventoryView } from '../store/types';
import { createMenuItems } from './inventoryCommands';
import './inventoryListCard.css';

export interface InventoryListCardProps {
    inventoryId: number;
}

export const InventoryListCard: React.FC<InventoryListCardProps> = ({ inventoryId }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const inventory = useAppSelector(state => getInventoryById(state, inventoryId));
    const image = useAppSelector(state => getInventoryImageById(state, inventoryId));
    const localImageUrl = isValue(image)
        ? createLocalImageUrl(image)
        : "";

    const vendorRouteName = useAppSelector(getVendorRouteName);
    const menuUrlName = useAppSelector(getMenuUrlName);
    const menuItems: TypedMenuItem<InventoryView>[] = createMenuItems(dispatch, history, vendorRouteName, menuUrlName);
    const primaryColor = useAppSelector(getPrimaryColor);
    const secondaryColor = useAppSelector(getSecondaryColor);
    const secondaryFont = useAppSelector(getSecondaryFont);

    const buttonStyle: CSSProperties = {
        background: primaryColor ?? undefined,
        color: secondaryColor ?? undefined
    }

    const onImageClick = () => {
        if (!isValue(inventory)) return;
        dispatch(showMediaForInventory(inventory.id));
    }

    return <FlexRowContainer centerContent width="100vw" gap="10px">

        {
            isValue(localImageUrl) && localImageUrl.length > 0
                // ? <Image src={localImageUrl} alt="coming soon!" width='120px' height='80px'  />
                ? <img
                    onClick={onImageClick}
                    src={localImageUrl}
                    alt="Coming soon!"
                    width={`120px`}
                    height={`80px`}
                />
                : <FlexRowContainer centerContent justifyContent="center" width='120px' height='80px'>Coming soon!</FlexRowContainer>
        }

        <div>
            <Tooltip target=".node-inv-list-card-title" position='top' />
            <div style={{ fontFamily: secondaryFont }} className='node-inv-list-card-title' data-pr-tooltip={inventory?.marketingName} >{inventory?.marketingName}</div>
        </div>

        <div style={{ width: "100px" }}>{`${inventory?.grade ?? ""}`}</div>

        <div
            style={{ width: "100px", fontFamily: secondaryFont }}
            className={clsx({
                "node-list-card-content-novalue": !isValue(inventory?.quantity),
                "node-list-card-content-value": isValue(inventory?.quantity)
            })}
        >{`${inventory?.quantity?.toLocaleString(undefined, { maximumFractionDigits: 4 }) ?? ""} ${inventory?.unitOfMeasureName}`}</div>

        <div
            style={{ width: "100px", fontFamily: secondaryFont }}
            className={clsx({
                "node-inv-card-split-content-novalue": !isValue(inventory?.unitPrice),
                "node-inv-card-split-content-value": isValue(inventory?.unitPrice)
            })}
        >{inventory?.unitPrice?.toLocaleString(undefined, currencyFormatOptions) ?? "Contact Vendor"}</div>

        <div
            style={{ width: "100px", fontFamily: secondaryFont }}
            className={clsx({
                "node-inv-card-split-content-novalue": !isValue(inventory?.thcPercentage),
                "node-inv-card-split-content-value": isValue(inventory?.thcPercentage)
            })}
        >{inventory?.thcPercentage?.toLocaleString(undefined, percentFormatOptions) ?? "Contact Vendor"}</div>

        <div
            style={{ width: "100px", fontFamily: secondaryFont }}
            className={clsx({
                "node-inv-card-split-content-novalue": !isValue(inventory?.cbdPercentage),
                "node-inv-card-split-content-value": isValue(inventory?.cbdPercentage)
            })}
        >{inventory?.cbdPercentage?.toLocaleString(undefined, percentFormatOptions) ?? "Contact Vendor"}</div>

        <div
            style={{ width: "100px", fontFamily: secondaryFont }}
            className={clsx({
                "node-inv-card-split-content-novalue": !isValue(inventory?.readyDate),
                "node-inv-card-split-content-value": isValue(inventory?.readyDate)
            })}
        >
            {
                isValue(inventory?.readyDate)
                    ? formatToShortDateString(inventory?.readyDate)
                    : "Contact Vendor"
            }
        </div>

        {
            isValue(inventory) &&
            <PopUpMenuButton<InventoryView>
                id="inventory-popup-menu"
                menuItems={menuItems}
                value={inventory}
                buttonStyle={buttonStyle}
            />
        }

    </FlexRowContainer >

}