import { useDispatch } from "react-redux";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { Button } from "primereact/button";
import { addCompanyBegin } from "../store/featureActions/addCompany";

export const CommandBar: React.FC = () => {

    const dispatch = useDispatch();

    const onAddCompany = () => {
        dispatch(addCompanyBegin());
    }

    return <FlexRowContainer>
        <Button onClick={onAddCompany}>Add Company</Button>
    </FlexRowContainer>

} 