import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { ServerInformationState } from './types';

export const createInitialState = (): ServerInformationState => {
    return {        
        loadingState: CreateActionStatusInitialState(),    
        serverHealth: null   
    };
};

export const initialState: ServerInformationState = createInitialState();