import { QualityAssessmentValue } from "../../../models/qualityAssessmentValue";
import { qualityAssessmentValueAdapter, saveValueStateAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    deleteAssessmentValue,
    deleteAssessmentValueReducer,
    deleteAssessmentValueSagaFactory

} = actionFactory
    .withMappedName("deleteAssessmentValue")
    .createAsyncAction<QualityAssessmentValue, Response>({
        actionStatusSelector: {
            adapter: saveValueStateAdapter,
            adapterStateSelector: state => state.saveValueState,
            getId: (_state, arg) => arg.id
        },
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.quality.deleteAssessmentValue(arg.id);
        },
        onFulfilled: (state, action) => {            
            qualityAssessmentValueAdapter.removeOne(state.assessmentValues, action.payload.arg.id);
        }
    });