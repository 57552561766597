import { Load2Result, parallelLoad } from "../../../../../utils/promiseHelpers";
import { isValue } from "../../../../../utils/valueHelper";
import { EntityImage } from "../../../../commonModels/entityImage";
import { EntityVideo } from "../../../../commonModels/entityVideo";
import { actionFactory } from "../types";

export const {

    loadInventoryMedia,
    loadInventoryMediaReducer,
    loadInventoryMediaSagaFactory

} = actionFactory
    .withMappedName("loadInventoryMedia")
    .createAsyncAction<void, Load2Result<EntityImage[], EntityVideo[]>>({
        actionStatusSelector: state => state.imageLoadState,
        asyncCall: (_, context) => {
            const inventoryId = context.getState().inventoryImages.inventoryId;            
            if (!isValue(inventoryId)) throw new Error("Cannot view inventory images without a valid inventory id");

            return parallelLoad(
                () => context.marketplaceApiClient.liveMenu.getInventoryImages(inventoryId),
                () => context.marketplaceApiClient.liveMenu.getInventoryVideo(inventoryId),
            ); 
        },
        onFulfilled: (state, action) => {
            state.images = action.payload.result.result1;
            state.videos = action.payload.result.result2;
        },
        debounceInMilliseconds: 10
    });