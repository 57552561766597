import { actionFactory } from "../types";

export const {

    viewOrdersForInventory,
    viewOrdersForInventoryReducer

} = actionFactory  
    .withMappedName("viewOrdersForInventory")
    .createAction<number | null>((state, action) => {
        state.viewOrdersForInventoryId = action.payload;
    });
    