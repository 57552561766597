import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { actionFactory, Inventory, InventoryManagementState } from "../../types";

export const beginEditInventoryImages = actionFactory.createAppAction<Inventory>("beginEditInventoryImages");

export function beginEditInventoryImagesReducers(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forAction(beginEditInventoryImages)
        .addHandler((state, action) => {          
            state.editMediaEntity = action.payload;
        })
        .build();
}