import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../store/rootReducer";
import { isNonEmpty, isValue } from "../../../../../../utils/valueHelper";
import { MixedMedia } from "../../../../../commonModels/mixedMedia";
import { inventoryVideoAdapter } from "../../state";

const videoSelectors = inventoryVideoAdapter.getSelectors((state: RootState) => state.inventoryManagement.editVideos);

export const getInventoryMedia = createSelector(
    [
        (state: RootState) => state.inventoryManagement.editImages,
        videoSelectors.selectAll
    ],
    (images, videos) => {
        const mixedMediaList: MixedMedia[] = [];

        mixedMediaList.push(...images.slice().sort((left, right) => left.id - right.id));
        mixedMediaList.push(...videos.slice().sort((left, right) => left.id - right.id));

        return mixedMediaList;
    }
)

export const getEditImagesInventory = (state: RootState) => state.inventoryManagement.editMediaEntity;

export const getEditMediaInventoryName = (state: RootState) => {
    const inventory = state.inventoryManagement.editMediaEntity;
    if (!isValue(inventory)) return "";

    const marketingName = inventory.marketingName;
    if (isNonEmpty(marketingName)) return marketingName

    const metrcName = inventory.metrcName;
    if (isNonEmpty(metrcName)) return metrcName
   
    return "";
}

export const getMenuThumbnailImageId = (state: RootState) => state.inventoryManagement.editMediaEntity?.menuThumbnailId;

export const getMediaLoadState = (state: RootState) => state.inventoryManagement.mediaLoadState;

export const getAddVideoState = (state: RootState) => state.inventoryManagement.videoSaveState;

export const getAddVideoVisible = (state: RootState) => state.inventoryManagement.addVideoState.isVisible;

export const getAddVideoModel = (state: RootState) => state.inventoryManagement.addVideoState.editedValue;

