import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { productVideoAdapter } from "../../state";
import { actionFactory, ProductManagementState } from "../../types";

export const completeEditProductImages = actionFactory.createAppAction("completeEditProductImages");

export function completeEditProductImagesReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forAction(completeEditProductImages)
        .addHandler((state, _) => {          
            state.editMediaEntity = undefined;
            state.editImages = [];
            productVideoAdapter.removeAll(state.editVideos);
        })
        .build();
}