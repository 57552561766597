import { userTableSettingsAdapter } from "../state";
import { actionFactory, UserTableSettings } from "../types";

export const {

    loadUserTableSettings,
    loadUserTableSettingsReducer,
    loadUserTableSettingsSagaFactory

} = actionFactory
    .withMappedName("loadUserTableSettings")
    .createAsyncAction<void, UserTableSettings[]>({
        actionStatusSelector: state => state.userTableSettingsLoadingState,
        asyncCall: async (_arg, context) => {
           return await context.marketplaceApiClient.userTableSettings.getUserTableSettings();
        },
        onFulfilled: (state, action) => {
            userTableSettingsAdapter.setAll(state.userTableSettings, action.payload.result);
        },
        predicate: (_arg, context) => {
            return !context.getState().userTableSettings.userTableSettingsLoadingState.hasExecuted ?? true;
        }
    });