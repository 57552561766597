import { CaseReducer, Draft } from "@reduxjs/toolkit";
import { ActionStatus, CreateActionStatusInitialState } from "../../../features/actionStatus/actionStatus";
import { SagaAppContext } from "../../rootSaga";
import { IActionFactory } from "../action/actionFactory";
import { ErrorDisplayType, FulfilledPayload } from "../sagaWithProgress/types";

export interface ModalEditState<T extends {}> extends ActionStatus {
    isVisible: boolean;
    editedValue: T | null;    
};

export function CreateInitialModalEditState<T extends {}>() : ModalEditState<T> {
    return {
        ...CreateActionStatusInitialState(),
        editedValue: null,        
        isVisible: false
    };
}

export function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export interface ModalEditOptions<TSliceState, TEditedValue extends {}, TBeginEditArg, TCompleteEditResult> {
    entityName: string;
    actionFactory: IActionFactory<TSliceState>;
    selector: (state: Draft<TSliceState>) => ModalEditState<TEditedValue>;
    modelFactory: (beginEditArg: TBeginEditArg, state: Draft<TSliceState>) => TEditedValue;
    onCompleteEdit: (editedValue: TEditedValue, context: SagaAppContext) => Promise<TCompleteEditResult>;
    onFulfilled: (state: Draft<TSliceState>, savedValue: FulfilledPayload<TEditedValue, TCompleteEditResult>) => void;
    successCallback?: (arg: TEditedValue, result: TCompleteEditResult, context: SagaAppContext) => void,    
    beforeBeginEdit?: CaseReducer<TSliceState, { payload: TBeginEditArg, type: string }>,
    errorDisplay?: ErrorDisplayType,
}