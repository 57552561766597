import { RootState } from "../../../../../store/rootReducer";
import { createLocalImageUrl } from "../../../../../utils/imageHelper";
import { isValue } from "../../../../../utils/valueHelper";

export const getDefaultThumbnail = (state: RootState) => {    
    const image = state.menuAdmin.thumbnailState.currentImage;
    if (!isValue(image)) return null;
    if (!isValue(image.imageData)) return null;
    return createLocalImageUrl(image);
}

export const getNewThumbnail = (state: RootState) => {
    return state.menuAdmin.thumbnailState.newImagePreview;
}

export const getLoadThumbnailState = (state: RootState) => {
    return state.menuAdmin.thumbnailState.loadState;
}

export const getSaveThumbnailState = (state: RootState) => {
    return state.menuAdmin.thumbnailState.saveState;
}