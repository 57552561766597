import TreeNode from "primereact/treenode";
import { isValue } from "./valueHelper";

export const nodeHasParentWith = <T, >(node: T, getParent: (node: T) => T | null | undefined, predicate: (node: T) => boolean) : boolean => {
    const parent = getParent(node);
    if (!isValue(parent)) return false;
    return predicate(parent) || nodeHasParentWith(parent, getParent, predicate);
} 

export const nodeHasChildWith = <T, >(node: T, getChildren: (node: T) => T[], predicate: (node: T) => boolean) : boolean => {
    const children = getChildren(node);
    if (children.length === 0) return false;
    return children
        .filter(node => predicate(node) || nodeHasChildWith(node, getChildren, predicate))
        .length > 0;
} 

export const getNodeParentChain = <T, >(node: T, getParent: (node: T) => T | null | undefined) : T[] => {
    const chain: T[] = [];

    const parent = getParent(node);    
    if (isValue(parent)) {
        chain.push(...getNodeParentChain(parent, getParent));
    }
    
    chain.push(node);

    return chain;
} 


export type TypedTreeNode<T> = Omit<TreeNode, "data" | "children"> & {
    data: T;
    children: TypedTreeNode<T>[] | undefined;
}

export interface TreeNodeMapOptions<T> {
    getKey: (item: T) => number | string;    
    className?: (item: T) => string | undefined;
    icon?: (item: T) => string | undefined;
    title?: (item: T) => string | undefined;
    itemFilter?: (item: T) => boolean;
    getChildren?: (item: T) => T[];
    selectable?: (item: T) => boolean;
}


export const mapToTreeNode = <T extends {}>(item: T, options: TreeNodeMapOptions<T>): TypedTreeNode<T> => {
    const { 
        getKey, 
        className, 
        icon, 
        title, 
        itemFilter = (_) => true, 
        getChildren,
        selectable
    } = options;

    const children = isValue(getChildren)
            ? getChildren(item)
                .filter(itemFilter)
                .map(childItem => mapToTreeNode(childItem, options))
            : [];

    return {
        key: getKey(item),
        label: isValue(title) ? title(item) : "",
        icon: isValue(icon) ? icon(item) : undefined,                
        className: isValue(className) ? className(item) : undefined,
        children: children,
        data: item,
        leaf: children.length === 0,
        selectable: isValue(selectable) ? selectable(item) : false 
    }
}