import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../../store/rootSaga";
import { SyncRequestDto } from "../../../../../commonModels/syncRequest";
import { actionFactory, InventoryManagementState } from "../../types";
import { loadLatestSyncRequest } from "./loadLatestSyncRequest";

export const requestInventorySync = actionFactory.createAppAction("requestInventorySync");

const sagaActions = actionFactory.createSagaWithProgressActions<void, SyncRequestDto>(requestInventorySync.type);

export function requestInventorySyncReducers(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)     
        .withActionStatus(state => state.requestSyncState)           
        .onFulfilled((state, action) => {
            state.latestSyncRequest = action.payload.result;            
        })        
        .build();
}

async function performAction(_: void, context: SagaAppContext): Promise<SyncRequestDto> {
    return context.marketplaceApiClient.inventoryManagement.requestPackageSync();    
}

export function createRequestInventorySyncSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions,
            rejectedCallback: (_arg, _result, context) => {                
                context.dispatch(loadLatestSyncRequest());
            }
        },
        actionPattern: requestInventorySync.type,
        debounceInMilliseconds: 250
    });
};