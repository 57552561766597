import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FeatureContentPanel } from "../../../../components/containers/featureContentPanel";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { loadVendorBanner } from "../store/featureActions/loadVendorBanner";
import { loadVendorLogo } from "../store/featureActions/loadVendorLogo";
import { getLoadingState } from "../store/selectors";
import { Banner } from "./banner";
import { CompanyInfoRow } from "./companyInfoRow";
import './index.css';
import { MenuList } from "./menuList";

export interface CompanyMarketplaceViewParams {
    id: string | undefined
};

export const CompanyMarketplaceView: React.FC = () => {

    const routeParams = useParams<CompanyMarketplaceViewParams>();
    const companyId: number = routeParams.id === undefined ? -1 : Number.parseInt(routeParams.id);
    const dispatch = useDispatch();

    useMountEffect(() => {
        dispatch(loadSliceData({ companyId: companyId }));
        dispatch(loadVendorLogo({ companyId: companyId }));
        dispatch(loadVendorBanner({ companyId: companyId }));
    });

    return <FeatureContentPanel
        loadingStateSelector={getLoadingState}
        title=''
    >
        <Banner />
        <CompanyInfoRow />
        <MenuList />

    </FeatureContentPanel>
} 