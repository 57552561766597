import { Load2Result, parallelLoad } from "../../../../../../utils/promiseHelpers";
import { EntityImage } from "../../../../../commonModels/entityImage";
import { EntityVideo } from "../../../../../commonModels/entityVideo";
import { productVideoAdapter } from "../../state";
import { actionFactory } from "../../types";

// export const loadProductImages = actionFactory.createAppAction<number>("loadProductImages");

// const sagaActions = actionFactory.createSagaWithProgressActions<number, EntityImage[]>(loadProductImages.type);

// export function loadProductImagesReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
//     return reducerFactory
//         .forSagaWithProgress(sagaActions)
//         .withActionStatus(state => state.mediaLoadState)
//         .onFulfilled((state, action) => {
//            state.editImages = action.payload.result;
//         })
//         .build();
// }

// async function performLoad(arg: number, context: SagaAppContext): Promise<EntityImage[]> {      
//     return context.marketplaceApiClient.productManagement.getProductImages(arg);    
// }

// export function createLoadProductImagesSaga(context: SagaAppContext) {

//     return createExecuteWithProgressSaga({
//         options: {
//             action: performLoad,
//             appContext: context,
//             notificationActions: sagaActions
//         },
//         actionPattern: loadProductImages.type,
//         debounceInMilliseconds: 250
//     });
// };


export const {

    loadProductMedia,
    loadProductMediaReducer,
    loadProductMediaSagaFactory

} = actionFactory
    .withMappedName("loadProductMedia")
    .createAsyncAction<number, Load2Result<EntityImage[], EntityVideo[]>>({
        actionStatusSelector: state => state.mediaLoadState,
        asyncCall: (arg, context) => {
            return parallelLoad(
                () => context.marketplaceApiClient.productManagement.getProductImages(arg),
                () => context.marketplaceApiClient.productManagement.getProductVideos(arg),
            );
        },
        onFulfilled: (state, action) => {
            state.editImages = action.payload.result.result1;
            productVideoAdapter.upsertMany(state.editVideos, action.payload.result.result2);
        }
    });