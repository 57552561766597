import React from "react";
import { useAppSelector } from "../../../../store/configureStore";
import { AlertsBar } from "../../../alerts/appAlerts/ui/alertsBar";
import { getIsAuthenticated } from "../../../authentication/store/selectors/isAuthenticated";
import UserSessionMenu from "./userSessionMenu";

export const LargeDeviceTopBarMenu: React.FC = () => {
    
    const isLoggedIn = useAppSelector(getIsAuthenticated);

    return <div className="p-d-flex p-flex-row">      
        {isLoggedIn && <AlertsBar />}
        <UserSessionMenu />
    </div>
}