import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { isValue } from "../../../../../utils/valueHelper";
import { AcceptTermsOfServiceState, actionFactory, TermsOfServiceUserAcceptance } from "../types";

export const saveTermsOfServiceAcceptance = actionFactory.createAppAction("saveTermsOfServiceAcceptance");

export const saveTermsOfServiceSagaActions = actionFactory.createSagaWithProgressActions<void, TermsOfServiceUserAcceptance>(saveTermsOfServiceAcceptance.type);

export function saveTermsOfServiceAcceptanceReducers(reducerFactory: IReducerFactory<AcceptTermsOfServiceState>): IReducerFactory<AcceptTermsOfServiceState> {
    return reducerFactory
        .forSagaWithProgress(saveTermsOfServiceSagaActions)
        .withActionStatus(state => state.savingState)
        .onFulfilled((state, action) => {
            state.termsOfServiceAcceptance = action.payload.result;
        })
        .build();
}

async function performAction(_arg: void, _context: SagaAppContext): Promise<TermsOfServiceUserAcceptance> {
    const state = _context.getState();
    const termsOfServiceId = state.acceptTermsOfService.termsOfServiceAcceptance?.termsOfServiceId;
    if (isValue(termsOfServiceId)) {
        return _context.marketplaceApiClient.termsOfService.agreeToTermsOfService(termsOfServiceId);
    }
    throw new Error("No Terms of Service are available.");

}

export function createSaveTermsOfServiceAcceptanceSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: saveTermsOfServiceSagaActions,
            errorTitle: "An error occured while accepting the terms of service"
        },
        actionPattern: saveTermsOfServiceAcceptance.type,
        debounceInMilliseconds: 250
    });
};