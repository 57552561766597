import { ColumnProps } from "primereact/column";
import { useDispatch } from "react-redux";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { TableSelectField } from "../../../../components/formik/tableSelectField";
import { useAppSelector } from "../../../../store/configureStore";
import { createTextColumnProps } from "../../../../utils/table/stringColumn";
import { QualityMetricKind } from "../../models/metricKind";
import { selectMetricsCancel, selectMetricsComplete } from "../store/featureActions/selectMetrics";
import { getMetricKindsForSelection, getSelectMetricsState, getSelectMetricsValue, getSelectMetricsVisible } from "../store/selectors/editing";
import { MetricKindSelection } from "../store/types";

export const SelectMetricsDialog: React.FC = () => {

    const dispatch = useDispatch();
    const availableMetrics = useAppSelector(getMetricKindsForSelection);

    const onSave = (values: MetricKindSelection): void => {
        dispatch(selectMetricsComplete(values));
    };

    const onCancel = (): void => {
        dispatch(selectMetricsCancel());
    }

    const columns: ColumnProps[] = [
        createTextColumnProps<QualityMetricKind>({
            header: "Metric",
            field: "name",
            style: { width: 280 }
        })
    ];

    return <FormikModalEditor<MetricKindSelection>
        statusSelector={getSelectMetricsState}
        visibleSelector={getSelectMetricsVisible}
        valueSelector={getSelectMetricsValue}
        onSave={onSave}
        onCancel={onCancel}        
        title="Select Quality Metrics"
    >
        <TableSelectField<MetricKindSelection, QualityMetricKind>
            availableValues={availableMetrics}
            fieldName="metricKinds"
            dataKey="id"
            columns={columns}
            size="small"
            containerClassName="select-quality-metrics-table-container"
        />

    </FormikModalEditor>
}