import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { createDismissAlertSaga } from "../featureActions/dismissUserAlert";
import { createLoadAlertsSaga } from "../featureActions/loadAlerts";


export function createAlertsSaga(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        createLoadAlertsSaga(context),
        createDismissAlertSaga(context)
    ]
}