import { isValue } from "../../../../../utils/valueHelper";
import { viewSettingsAdapter } from "../state";
import { actionFactory } from "../types";
import { completeTableRemount } from "./completeTableRemount";

export const {

    remountTable,
    remountTableReducer,    
    remountTableSagaFactory

} = actionFactory
    .withMappedName("remountTable")
    .createAsyncAction<{ viewId: string }, void>({
        actionStatusSelector: (_state) => ({ isExecuting: false }),
        asyncCall: (_arg, _context) => {
            return new Promise(resolve => setTimeout(resolve, 1))
        },
        onPending: (state, action) => {
            const viewSettings = state.viewSettings.entities[action.payload.arg.viewId];
            if (!isValue(viewSettings)) return;
    
            viewSettings.remountState = { 
                remountInProgress: true,
                showTable: false,
                tableRemountNeeded: true,
                needsStateReapplied: false
             };
            viewSettingsAdapter.upsertOne(state.viewSettings, viewSettings);        
        },
        onRejected: (state, action) => {
            const viewSettings = state.viewSettings.entities[action.payload.arg.viewId];
            if (!isValue(viewSettings)) return;
    
            viewSettings.remountState = { 
                remountInProgress: false,
                showTable: true,
                tableRemountNeeded: false,
                needsStateReapplied: false
             };
            viewSettingsAdapter.upsertOne(state.viewSettings, viewSettings);        
        },
        onFulfilled: (state, action) => {
            const viewSettings = state.viewSettings.entities[action.payload.arg.viewId];
            if (!isValue(viewSettings)) return;
    
            viewSettings.remountState = { 
                remountInProgress: true,
                showTable: true,
                tableRemountNeeded: false,
                needsStateReapplied: true
             };
            viewSettingsAdapter.upsertOne(state.viewSettings, viewSettings);        
        },
        successCallback: (arg, _result, context) => {
            context.dispatch(completeTableRemount({ viewId: arg.viewId }));
        }
    });