import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, InitialSetupWorkflowState, WorkflowStep } from "../types";

export const setWorkflowStep = actionFactory.createAppAction<{step: WorkflowStep}>("setWorkflowStep");

export function setWorkflowStepReducers(reducerFactory: IReducerFactory<InitialSetupWorkflowState>): IReducerFactory<InitialSetupWorkflowState> {
    return reducerFactory
        .forAction(setWorkflowStep)        
        .addHandler((state, action) => {
            state.userSelectedWorkflowStep = action.payload.step;
        })
        .build();
}