import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { addCategoryToSectionReducer } from './featureActions/addCategoryToSection';
import { addSectionToMenuReducer } from './featureActions/addSectionToMenu';
import { deleteCategoryReducer } from './featureActions/deleteCategory';
import { deleteMenuSectionReducer } from './featureActions/deleteMenuSection';
import { editCategoryNameReducers } from './featureActions/editCategoryName';
import { editMenuNameReducers } from './featureActions/editMenuName';
import { deleteBannerReducer } from './featureActions/images/deleteBanner';
import { deleteLogoReducer } from './featureActions/images/deleteLogo';
import { deleteNewBannerReducer } from './featureActions/images/deleteNewBanner';
import { deleteNewLogoReducer } from './featureActions/images/deleteNewLogo';
import { deleteNewThumbnailReducer } from './featureActions/images/deleteNewThumbnail';
import { deleteThumbnailReducer } from './featureActions/images/deleteThumbnail';
import { loadBannerReducer } from './featureActions/images/loadBanner';
import { loadLogoReducer } from './featureActions/images/loadLogo';
import { loadThumbnailReducer } from './featureActions/images/loadThumbnail';
import { saveBannerReducer } from './featureActions/images/saveBanner';
import { saveLogoReducer } from './featureActions/images/saveLogo';
import { saveThumbnailReducer } from './featureActions/images/saveThumbnail';
import { setNewBannerReducer } from './featureActions/images/setNewBanner';
import { setNewLogoReducer } from './featureActions/images/setNewLogo';
import { setNewThumbnailReducer } from './featureActions/images/setNewThumbnail';
import { loadCategoriesReducers } from './featureActions/loadCategories';
import { loadMenuSectionsReducer } from './featureActions/loadMenuSections';
import { loadSliceDataReducers } from './featureActions/loadSliceData';
import { removeCategoryFromSectionReducer } from './featureActions/removeCategoryFromSection';
import { resequenceMenuSectionReducer } from './featureActions/resequenceMenuSection';
import { setUseSmallMenuSectionViewReducers } from './featureActions/setUseSmallMenuSectionView';
import { updateMenuLocalStateReducer, updateMenuSectionReducer } from './featureActions/updateMenuSection';
import { upsertCategoryReducer } from './featureActions/upsertCategory';
import { upsertMenuReducers } from './featureActions/upsertMenu';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(loadSliceDataReducers)
            .add(upsertMenuReducers)
            .add(loadCategoriesReducers)
            .add(addSectionToMenuReducer)
            .add(updateMenuSectionReducer)
            .add(loadMenuSectionsReducer)
            .add(addCategoryToSectionReducer)
            .add(removeCategoryFromSectionReducer)
            .add(resequenceMenuSectionReducer)
            .add(setUseSmallMenuSectionViewReducers)
            .add(deleteMenuSectionReducer)
            .add(updateMenuLocalStateReducer)
            .add(upsertCategoryReducer)
            .add(editCategoryNameReducers)
            .add(deleteCategoryReducer)
            .add(editMenuNameReducers)            
            .add(deleteBannerReducer)
            .add(deleteLogoReducer)
            .add(deleteNewBannerReducer)
            .add(deleteNewLogoReducer)
            .add(loadBannerReducer)
            .add(loadLogoReducer)
            .add(saveBannerReducer)
            .add(saveLogoReducer)
            .add(setNewBannerReducer)
            .add(setNewLogoReducer)
            .add(deleteThumbnailReducer)
            .add(deleteNewThumbnailReducer)
            .add(loadThumbnailReducer)
            .add(saveThumbnailReducer)
            .add(setNewThumbnailReducer);
            // Create your feature actions in ./featureActions and then add them to the reducer factory here
            // with .Add(<your-action-reducer-name>);
            
    }
});

export default slice.reducer;

export const { resetState } = slice.actions;