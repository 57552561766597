import { EntityImage } from "../../../../commonModels/entityImage";
import { inventoryImageAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    loadInventoryThumbnail,
    loadInventoryThumbnailReducer,
    loadInventoryThumbnailSagaFactory

} = actionFactory
    .withMappedName("loadInventoryThumbnail")
    .createAsyncAction<number, EntityImage | null>({
        actionStatusSelector: state => state.inventoryImageLoadState,
        asyncCall: (arg, context) => context.marketplaceApiClient.liveMenu.getInventoryThumbnail(arg),
        onFulfilled: (state, action) => {
            if (action.payload.result === null) return;
            inventoryImageAdapter.upsertOne(state.inventoryImages, action.payload.result);
        }
    });