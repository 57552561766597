import { Sidebar } from 'primereact/sidebar';
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../store/configureStore";
import { getIsAuthenticated } from '../../authentication/store/selectors/isAuthenticated';
import { getUserIsMarketplaceUser } from '../../authentication/store/selectors/userRoles';
import MainAppMenu from "../../navigation/appMenu/menu";
import { toggleSidePanelVisible } from "../store/featureActions/toggleSidePanelVisible";
import { getSideMenuVisible, getUseOverlaySideMenu } from "../store/selectors/sideMenu";

const SideMenu: React.FC = () => {

  const dispatch = useDispatch();
  const sideMenuVisible = useAppSelector(getSideMenuVisible);
  const isLoggedIn = useAppSelector(getIsAuthenticated);
  const isMarketplaceUser = useAppSelector(getUserIsMarketplaceUser)
  const useOverlaySideMenu = useAppSelector(getUseOverlaySideMenu);

  const onToggleDrawerOpenState = () => {
    dispatch(toggleSidePanelVisible());
  };

  console.log("isLoggedIn", isLoggedIn);
  console.log("isMarketplaceUser", isMarketplaceUser);

  return <div>
    {
      isLoggedIn && isMarketplaceUser && 
      (
        useOverlaySideMenu
          ? <Sidebar
            dismissable
            showCloseIcon={false}
            className="ui-sidebar-sm overlay-menu-container"
            visible={sideMenuVisible}
            onHide={onToggleDrawerOpenState}
          >
            <MainAppMenu />
          </Sidebar>
          : <div className="docked-menu-container p-shadow-2">
            <MainAppMenu />
          </div>
      )
    }
  </div>
}

export default SideMenu;
