import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { TabPanel, TabView } from "primereact/tabview";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { isValue } from "../../../../utils/valueHelper";
import { customerEditorRoute } from "../../../navigation/routes";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { getOrder, getOrderLoadingState } from "../store/selectors/order";
import { OrderDetails } from "./orderDetails";
import { OrderItemDialog } from "./orderItemDialog";
import { OrderItems } from "./orderItems";

export interface OrderEditorParams {
    id: string | undefined
};

export const OrderEditor: React.FC = () => {

    const dispatch = useDispatch();
    const loadingState = useAppSelector(getOrderLoadingState);
    const order = useAppSelector(getOrder);
    const history = useHistory();

    const params = useParams<OrderEditorParams>();
    const orderId: number | undefined = isValue(params.id) ? parseInt(params.id) : undefined;

    useMountEffect(() => {
        if (!isValue(orderId)) return;
        dispatch(loadSliceData(orderId));
    });

    const onReturnToCustomer = () => {
        if (!isValue(order)) return;
        history.push(customerEditorRoute.createPath(order.customerId));
    }

    return <OverlayWaitDisplay
        isWaiting={loadingState.isExecuting}
    >
        <OrderItemDialog />
        <Card
            className="full-height-content-panel"
            title={`Order: ${order?.orderNumber ?? ""}`}
            subTitle={<Button className="p-button-link" label="Return to customer" onClick={onReturnToCustomer} />}
        >
            <TabView>
                <TabPanel key="order-details" header="General" >
                    <OrderDetails />
                </TabPanel>

                <TabPanel key="order-items" header="Line Items">
                    <OrderItems />
                </TabPanel>
            </TabView>
        </Card>

    </OverlayWaitDisplay>
}   