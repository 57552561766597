import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { StringField } from "../../../../components/formik/stringField";
import { EntityVideo } from "../../../commonModels/entityVideo";

export const AddVideoEditor: React.FC = () => {

    return <FlexColumnContainer className="add-video-editor" gap="20px">

        <StringField<EntityVideo>
            fieldName="title"
            defaultLabel="Title"            
            inputStyle={{ width: "100%" }}
        />

        <StringField<EntityVideo>
            fieldName="embedUrl"
            defaultLabel="Embed Url"            
            inputStyle={{ width: "100%" }}
        />

    </FlexColumnContainer>
}