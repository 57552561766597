import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import loginLogo from '../../assets/login.svg';
import { beginLogin } from "../../features/authentication/store/featureActions/beginLoginActions";
import { MarketingCard } from './marketingCard';

export const LoginCard: React.FC = () => {

    const dispatch = useDispatch();
    
    const [hoveredCard, setHoveredCard] = useState<string | null>(null);    

    const onMouseOver = (e: React.MouseEvent<HTMLDivElement>) => {
        setHoveredCard(e.currentTarget.id);
    }

    const onMouseLeave = (_: React.MouseEvent<HTMLDivElement>) => {
        setHoveredCard(null);
    }

    const onLogin = () => {
        dispatch(beginLogin());
    }

    return <div onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} id="LoginCard" >
            <MarketingCard
                image={loginLogo}
                title="Login"
                actionTitle="Login"
                isSelected={hoveredCard !== null && hoveredCard === "LoginCard"}
                onActionClick={onLogin}
            />
        </div>
}