import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { actionFactory, InventoryManagementState } from "../../types";

export const setQualityGradeToAssign = actionFactory.createAppAction<number | null>("setQualityGradeToAssign");

export function setQualityGradeToAssignReducer(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forAction(setQualityGradeToAssign)
        .addHandler((state, action) => {
            state.bulkQualityGradeAssignmentState.qualityGradeIdToAssign = action.payload;
        })
        .build();
}