import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { createCompleteEditTermsOfServiceSaga } from "../featureActions/completeEditTermsOfService";
import { createInactivateTermsOfServiceSaga } from "../featureActions/inactivateTermsOfService";
import { createLoadSliceDataSaga } from "../featureActions/loadSliceData";
import { createPublishTermsOfServiceSaga } from "../featureActions/publishTermsOfService";

// Register this method in the root saga
export function createTermsOfServiceAdminSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        createLoadSliceDataSaga(context),
        createCompleteEditTermsOfServiceSaga(context),
        createInactivateTermsOfServiceSaga(context),
        createPublishTermsOfServiceSaga(context)
    ]
}