import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { FileUpload, FileUploadSelectParams, FileUploadUploadParams } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import React, { useRef } from "react";
import { FlexRowContainer } from '../../../../components/containers/flexRowContainer';
import { DefaultImageUploadToolTipTarget, ImageUploadToolTip } from '../../../../components/fileUpload/imageUploadToolTip';
import { RootState } from '../../../../store/rootReducer';
import { MixedMedia } from '../../../commonModels/mixedMedia';
import { ShowGallery } from './showGallery';

export interface EntityMediaEditorHeaderProps {
    inventoryId: number;
    mediaSelector: (state: RootState) => MixedMedia[];
    uploadInventoryImage: (inventoryId: number, localFileUrl: string) => void;
    showAddVideoDialog: (inventoryId: number) => void
};

export const EntityMediaEditorHeader: React.FC<EntityMediaEditorHeaderProps> = ({ inventoryId, mediaSelector, uploadInventoryImage, showAddVideoDialog }) => {

    const fileUploadRef = useRef<FileUpload>(null);
    const toastRef = useRef<Toast>(null);

    const onUploadNewInventoryImage = (_: FileUploadUploadParams) => {
        if (toastRef.current === null) return;
        toastRef.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded Successfully' });
    }

    const onSelectNewImage = async (e: FileUploadSelectParams) => {
        if (e.files.length !== 1) return;

        const fileUrl = URL.createObjectURL(e.files[0]);
        uploadInventoryImage(inventoryId, fileUrl);        

        fileUploadRef.current?.clear();
    }    

    const onAddVideo = () => {
        showAddVideoDialog(inventoryId);
    }

    return <FlexRowContainer className='entity-media-editor-header'>
        <Toast ref={toastRef}></Toast>

        <div>
            <ImageUploadToolTip />
            <FileUpload
                ref={fileUploadRef}
                className={DefaultImageUploadToolTipTarget}
                mode="basic"
                multiple={false}
                url="./upload"
                name="newEntityImage"
                accept=".jpg,.jpeg,.bmp,.tga"
                maxFileSize={1000000}
                customUpload={true}
                onUpload={onUploadNewInventoryImage}
                onSelect={onSelectNewImage}
                auto
                chooseLabel="Upload New Image"
            />
        </div>

        <Button
            icon={PrimeIcons.PLUS}
            label='Add Video'            
            onClick={onAddVideo}
        />

        <ShowGallery mediaSelector={mediaSelector} />


    </FlexRowContainer>
}