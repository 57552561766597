import { createEntityAdapter } from '@reduxjs/toolkit';
import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { License, SyncAdminLicenseState } from './types';

export const licenseAdapter =  createEntityAdapter<License>();

export const createInitialState = (): SyncAdminLicenseState => {
    return {
        loadingState: CreateActionStatusInitialState(),
        licenses: licenseAdapter.getInitialState(),
        latestSyncLicenseRequest: null,
        requestLicenseSyncState: CreateActionStatusInitialState(),
        licenseSaveState: {}
    };
};

export const initialState: SyncAdminLicenseState = createInitialState();