import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { loadSliceDataSagaFactory } from "../featureActions/loadSliceData";
import { loadVendorBannerSagaFactory } from "../featureActions/loadVendorBanner";
import { loadVendorLogoSagaFactory } from "../featureActions/loadVendorLogo";

// Register this method in the root saga
export function createCompanyMarketplaceViewSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        loadSliceDataSagaFactory(context),
        loadVendorBannerSagaFactory(context),
        loadVendorLogoSagaFactory(context)
    ]
}