import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../../store/configureStore";
import { createDateColumnProps } from "../../../../utils/table/dateColumn";
import { createFillRemainingSpaceColumnProps } from "../../../../utils/table/fillRemainingSpaceColumn";
import { createMultiActionColumnProps } from "../../../../utils/table/multiActionColumn";
import { TypedCommandMenuItem } from "../../../../utils/table/typedMenuItem";
import { createFilteredTextColumnProps } from "../../../../utils/table/stringColumn";
import { priceModelEditorRoute } from "../../../navigation/routes";
import { PricingModel } from "../../sharedTypes";
import { getPricingModels } from "../store/selectors/priceModels";
import { useDispatch } from "react-redux";
import { deletePricingModel } from "../store/featureActions/deletePricingModel";

export const PricingModelTable: React.FC = () => {

    const tableRef = useRef<DataTable>(null);
    const pricingModels = useAppSelector(getPricingModels);
    const history = useHistory();
    const dispatch = useDispatch();

    const onEdit = (pricingModel: PricingModel) => {
        history.push(priceModelEditorRoute.createPath(pricingModel.id));
    };

    const onDeletePriceModel = (pricingModel: PricingModel) => {
        dispatch(deletePricingModel(pricingModel));
    }

    const rowMenuItems: TypedCommandMenuItem<PricingModel>[] = [
        {
            label: "Delete",
            command: onDeletePriceModel,
            promptBeforeInvoke: "Are you sure you want to delete this pricing model?"
        }
    ];

    const columns: ColumnProps[] = [
        createMultiActionColumnProps<PricingModel>({
            columnKey: "action-column",
            actionName: "Edit",
            actionHandler: onEdit,
            header: "Actions",
            menuItems: rowMenuItems,            
            style: { width: 150 },
            sortable: false
        }),
        createFilteredTextColumnProps<PricingModel>({ field: "name", header: "Name", style: { width: 300 }, sortable: true }),
        createDateColumnProps<PricingModel>({ field: "createdUtc", header: "Created", style: { width: 200 } }),
        createFillRemainingSpaceColumnProps({}),
    ];

    return <DataTable
        ref={tableRef}
        dataKey="id"
        value={pricingModels}
        scrollable
        scrollDirection='both'
        scrollHeight="flex"
        paginator
        rows={20}
        rowsPerPageOptions={[10, 20, 50]}
    >
        {columns.map(c => <Column key={c.columnKey ?? c.field} {...c} />)}
    </DataTable>
} 