import { useSelector } from "react-redux";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { isValue } from "../../../../utils/valueHelper";
import { getVendorImageSize } from "../../../responsiveconfiguration";
import { getCompanyProfile, getVendorLogo } from "../store/selectors";
import { InfoPanel } from "./infoPanel";


export const CompanyInfoRow: React.FC = () => {
    
    const dimension = getVendorImageSize(false);
    const profile = useSelector(getCompanyProfile);    
    const logo = useSelector(getVendorLogo);

    return <FlexRowContainer className="marketingview-info-row">            
            <div
                style={{
                    width: dimension,
                    height: dimension,
                    zIndex: 20
                }}
            >
                {isValue(logo) &&
                    <img
                        src={logo}
                        alt="vendor logo"
                        width={dimension}
                        height={dimension}
                        style={{
                            display: !isValue(logo) ? "none" : undefined,
                            zIndex: 20

                        }}
                    />
                }
            </div>
            <div className="vendor-title">
                {profile?.displayName ?? ""}
            </div>
           <InfoPanel title='State' value={profile?.stateAbbreviation}/>
           <InfoPanel title='Type' value={profile?.companyType}/>
           <InfoPanel title='Phone' value={profile?.contactPhone ?? '(---) --- ----'}/>
        </FlexRowContainer>
} 