import React, { useState } from 'react';
import sellerLogo from '../../assets/shop.svg';
import { openContactNodePage } from '../../utils/externalPageHelper';
import { MarketingCard } from './marketingCard';

export const SellerSignUpCard: React.FC = () => {

    const [hoveredCard, setHoveredCard] = useState<string | null>(null);

    const onMouseOver = (e: React.MouseEvent<HTMLDivElement>) => {
        setHoveredCard(e.currentTarget.id);
    }

    const onMouseLeave = (_: React.MouseEvent<HTMLDivElement>) => {
        setHoveredCard(null);
    }

    return <div onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} id="SellerCard" >
        <MarketingCard
            image={sellerLogo}
            title="Supplier Signup"
            actionTitle="Sign up"
            isSelected={hoveredCard !== null && hoveredCard === "SellerCard"}
            onActionClick={openContactNodePage}
        />
    </div>
}