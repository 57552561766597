import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, Category, CategoryManagementState } from "../types";

export const beginAssignProductsToCategory = actionFactory.createAppAction<Category>("beginAssignProductsToCategory");

export function beginAssignProductsToCategoryReducers(reducerFactory: IReducerFactory<CategoryManagementState>): IReducerFactory<CategoryManagementState> {
    return reducerFactory
        .forAction(beginAssignProductsToCategory)
        .addHandler((state, action) => {          
            state.categoryProductAssignment.category = action.payload;
        })
        .build();
}