import { DataTablePageParams } from "primereact/datatable";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { IntegerId } from "../../../commonModels/integerId";
import { Vendor } from "../../../menu/productDetails/store/types";
import { CompanyType } from "../../../company/profile/store/types";

export const sliceName: string = "search-dashboard";

export const actionFactory = new ActionFactory<SearchState>(sliceName);

export interface SearchRequest {
    searchText: string;
    pageSize: number;
    pageNumber: number;
}

export interface VendorSearchResult extends IntegerId {
    displayName: string;
    companyType: CompanyType;
    stateAbbreviation: string;
    urlName: string;
    contactUrl: string;
    contactPhone: string;
    contactEmail: string;
}

export interface InventorySearchResult extends IntegerId {
    name: string;
    strainName: string;
    vendorName: string;
    quantity: number;

    vendorUrlName: string | null;
    thcPercentage: number | null;
    unitOfMeasure: string | null;
}

export interface SearchState {
    loadingState: ActionStatus;  
    inventorySearchResults: InventorySearchResult[];      
    vendorSearchResults: VendorSearchResult[];
    searchRequest: SearchRequest;
    noMoreResults: boolean;
    viewPageState: DataTablePageParams;
    showVendorDetails: string | null;
    vendor: Vendor | null;
    vendorLoadingState: ActionStatus;
}