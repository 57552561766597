import { QualityMetricKind } from "../../../models/metricKind";
import { metricKindAdapter } from "../state";
import { actionFactory } from "../types";

export const {

   addQualityMetricKindBegin,
   addQualityMetricKindCancel,
   addQualityMetricKindComplete,
   addQualityMetricKindReducers,
   addQualityMetricKindSagaFactory

} = actionFactory
    .withMappedName("addQualityMetricKind")
    .createModal<QualityMetricKind, void, QualityMetricKind>({        
        selector: state => state.addMetricKindState,
        modelFactory: (_arg, _state) => {
            return { id: 0, inactive: false, name: "", publishedDate: null };
        },
        onCompleteEdit: (value, context) => {
            return context.marketplaceApiClient.quality.upsertQualityMetricKind(value);
        },
        onFulfilled: (state, action) => {
            metricKindAdapter.upsertOne(state.metricKinds, action.result);
        }
    });