import { isValue } from "../../../../../utils/valueHelper";
import { serializeTableState } from "../logic/settingsSerialization";
import { userTableSettingsAdapter } from "../state";
import { actionFactory, UpsertTableSettingsRequest, UserTableSettings } from "../types";

export const {

    saveUserTableSettings,
    saveUserTableSettingsReducer,
    saveUserTableSettingsSagaFactory

} = actionFactory
    .withMappedName("saveUserTableSettings")
    .createAsyncAction<{ viewId: string }, UserTableSettings>({
        actionStatusSelector: state => state.commandBarActionState,
        asyncCall: (arg, context) => {
            const sliceState = context.getState().userTableSettings;
            const currentTableSettings = sliceState.viewSettings.entities[arg.viewId];
            if (!isValue(currentTableSettings)) throw new Error("Save settings failed: current table settings were not available");

            const currentUserTableSettingsId = currentTableSettings.selectedUserTableSettings;
            if (!isValue(currentUserTableSettingsId))throw new Error("Save settings failed: no user settings were selected");

            const currentUserTableSettings = sliceState.userTableSettings.entities[currentUserTableSettingsId];
            if (!isValue(currentUserTableSettings))throw new Error("Save settings failed: selected user settings were no longer available");

            const newUserTableSettings: UpsertTableSettingsRequest = {
                id: currentUserTableSettings.id,
                name: currentUserTableSettings.name,
                rank: currentUserTableSettings.rank,
                schemaVersion: currentUserTableSettings.schemaVersion,
                viewId: currentUserTableSettings.viewId,
                settings: serializeTableState(currentTableSettings.currentState)
            }

            return context.marketplaceApiClient.userTableSettings.upsertUserTableSettings(newUserTableSettings);
        },
        onFulfilled: (state, action) => {
            userTableSettingsAdapter.upsertOne(state.userTableSettings, action.payload.result);                    
        }
    });