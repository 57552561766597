import { ColumnProps } from "primereact/column";
import React from 'react';
import { getStringOrNullValue, PropertiesOfType, StringOrNullKeyOf } from "../typeHelpers";

function createEmailTemplate<T>(
    getValueFn: (rowData: T) => string | null | undefined,
): (rowData: T, props: ColumnProps) => React.ReactNode {

    return (rowData: T, _props: ColumnProps) => {
        const value = getValueFn(rowData);
        return <a href={`mailto:${value}`}>{value}</a>

    };
}

export interface EmailColumnOptions<T extends PropertiesOfType<T, string | null | undefined>> extends Omit<ColumnProps, "field" | "header" | "body"> {
    field: StringOrNullKeyOf<T> & string;
    header: string;
}

export function createEmailColumnProps<T extends PropertiesOfType<T, string | null | undefined>>(props: EmailColumnOptions<T>): ColumnProps {

    const getValueFn = (rowData: T) => getStringOrNullValue(rowData, props.field);

    return {
        body: createEmailTemplate(getValueFn),
        filter: true,
        ...props
    };
}