import { actionFactory } from "../../types";

export const {
    deleteNewThumbnail,
    deleteNewThumbnailReducer
} = actionFactory
    .withMappedName("deleteNewThumbnail")
    .createAction<void>((state, _) => {
        state.thumbnailState.newImagePreview = undefined;
    });

