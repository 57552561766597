import ApiClient from "../../apiCommunication/apiClient";
import { deserializeSyncRequestDates, SyncRequestDto } from "../../commonModels/syncRequest";
import { License } from "../licenseManagement/store/types";


export default class SyncAdmin {

    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    getLicenses = (): Promise<License[]> => {
        return this
            .apiClient
            .executeGet<License[]>(`SyncAdmin/license`);
    };

    getLatestLicenseSyncRequest = (): Promise<SyncRequestDto | null> => {
        return this
            .apiClient
            .executeGet<SyncRequestDto | null>(`SyncAdmin/license/sync`)
            .then(result => {
                if (result !== null) {
                    return deserializeSyncRequestDates(result);
                }
                return result;
            });
    };

    requestLicenseSync = (): Promise<SyncRequestDto> => {
        return this
            .apiClient
            .executePostWithResult<SyncRequestDto>(`SyncAdmin/license/sync`)
            .then(deserializeSyncRequestDates);
    };

    saveLicense = (license: License): Promise<License> => {
        return this
            .apiClient
            .executePostWithResult<License>(`SyncAdmin/license`, license);
    };
}