import { createSelector } from "reselect";
import { RootState } from "../../../../store/rootReducer";
import { isValue } from "../../../../utils/valueHelper";
import { isTokenExpired } from "../../helper";

export const getIsAuthenticated = createSelector(
    [
        (state: RootState) => state.authentication
    ],
    (authState) => {
        if (!isValue(authState.CurrentUser)) return false;
        if (!isValue(authState.CurrentUser.accessTokenData)) return false;        
        return !isTokenExpired(authState.CurrentUser.accessTokenData)
    }
);