import clsx from 'clsx';
import { FormikProps } from 'formik';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { MenuItem } from 'primereact/menuitem';
import { Steps } from 'primereact/steps';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { FlexColumnContainer } from '../../../../components/containers/flexColumnContainer';
import { useAppSelector } from '../../../../store/configureStore';
import { useMountEffect } from '../../../../utils/useEffectHelper';
import { isValue } from '../../../../utils/valueHelper';
import { getIsAuthenticated } from '../../../authentication/store/selectors/isAuthenticated';
import { getUserIsMarketplaceAdmin, getUserIsMarketplaceUser } from '../../../authentication/store/selectors/userRoles';
import { getCompanyProfileSavingState } from '../../../company/profile/store/selectors/actionStatus';
import { CompanyProfile } from '../../../company/profile/store/types';
import { getUserProfileSavingState } from '../../../marketplaceUserProfile/manageProfile/store/selectors/actionStatus';
import { MarketplaceProfile } from '../../../marketplaceUserProfile/manageProfile/store/types';
import { smallDeviceQuery } from '../../../responsiveconfiguration';
import { saveTermsOfServiceAcceptance } from '../../acceptTermsOfService/store/featureActions/saveTermsOfServiceAcceptance';
import { getHasAcceptedTerms } from '../../acceptTermsOfService/store/selectors/termsOfService';
import { AcceptTermsOfService } from '../../acceptTermsOfService/ui';
import { loadUserSetupInfo } from '../store/featureActions/loadUserSetupData';
import { setWorkflowStep } from '../store/featureActions/workflowNavigation';
import { getIsLoading } from '../store/selectors/loadingState';
import { getUserSetupRequired } from '../store/selectors/userSetup';
import { getCanNavigateBack, getCanNavigateForward } from '../store/selectors/workflowNavigation';
import { getCurrentWorkflowStep } from '../store/selectors/workflowStep';
import { resetState } from '../store/slice';
import { WorkflowStep } from '../store/types';
import { CompanyProfileForm } from './companyProfileForm';
import './index.css';
import { UserProfileForm } from './userProfileForm';

export const InitialSetupWorkflow: React.FC = () => {

    const dispatch = useDispatch();

    const isLoggedIn = useAppSelector(getIsAuthenticated);
    const isMarketplaceUser = useAppSelector(getUserIsMarketplaceUser);
    const isMarketplaceAdmin = useAppSelector(getUserIsMarketplaceAdmin);
    const currentWorkflowStep = useAppSelector(getCurrentWorkflowStep);
    const canNavigateBack = useAppSelector(getCanNavigateBack);
    const canNavigateForward = useAppSelector(getCanNavigateForward);
    const isLoading = useAppSelector(getIsLoading);
    const userHasAcceptedTerms = useAppSelector(getHasAcceptedTerms);
    const userSetupInfo = useAppSelector(getUserSetupRequired);
    const isSavingCompanyProfile = useAppSelector(getCompanyProfileSavingState);
    const isSavingUserProfile = useAppSelector(getUserProfileSavingState);

    const companyInfoFormikRef = React.useRef<FormikProps<Partial<CompanyProfile>>>(null);
    const userProfileFormikRef = React.useRef<FormikProps<Partial<MarketplaceProfile>>>(null);

    const requiresSetup = !!userSetupInfo &&
        (userSetupInfo.companyProfileNeeded ||
            userSetupInfo.termsOfServiceNeeded ||
            userSetupInfo.userProfileNeeded);

    const isSmallDevice = useMediaQuery(smallDeviceQuery);

    const stepItems: MenuItem[] = [];
    const stepsToWorkflowIndex: {[key:number]: number} = {};

    if (isMarketplaceAdmin) {
        stepItems.push({ label: "Company Info" });
        stepsToWorkflowIndex[WorkflowStep.CompanyInfo] = 0;
        stepsToWorkflowIndex[WorkflowStep.UserInfo] = 1;
        stepsToWorkflowIndex[WorkflowStep.TermsOfService] = 2;
        stepsToWorkflowIndex[WorkflowStep.Complete] = 3;
    }    
    else {        
        stepsToWorkflowIndex[WorkflowStep.UserInfo] = 0;
        stepsToWorkflowIndex[WorkflowStep.TermsOfService] = 1;
        stepsToWorkflowIndex[WorkflowStep.Complete] = 2;
    }

    stepItems.push({ label: "User Info" });
    stepItems.push({ label: "Terms of Service" });

    useMountEffect(() => {
        return () => {
            dispatch(resetState());
        }
    });

    useEffect(() => {
        if (isLoggedIn && isMarketplaceUser && (!isValue(userSetupInfo) || (!!userHasAcceptedTerms && requiresSetup))) {
            dispatch(loadUserSetupInfo());
        }
    }, [isLoggedIn, isMarketplaceUser, dispatch, userSetupInfo, userHasAcceptedTerms, requiresSetup]);


    const onHideTermsOfServiceDialog = () => { };

    const onNavigateBack = () => {
        if (currentWorkflowStep <= 0) return;
        dispatch(setWorkflowStep({ step: currentWorkflowStep - 1 }));
    }

    const onSaveTermsOfServiceAcceptance = () => {
        dispatch(saveTermsOfServiceAcceptance());
    }


    const onSubmitCompanyProfile = () => {
        if (companyInfoFormikRef.current !== null) {
            companyInfoFormikRef.current.submitForm();
        }
    }

    const onSaveUserProfile = () => {
        if (userProfileFormikRef.current !== null) {
            userProfileFormikRef.current.submitForm();
        }
    }

    const initialSetupHeader = (<Steps
        className="initial-setup-step"
        model={stepItems}
        activeIndex={stepsToWorkflowIndex[currentWorkflowStep]}        
    />);
    
    return <Dialog
        header={initialSetupHeader}
        position="center"
        draggable={false}
        keepInViewport={true}
        closeOnEscape={false}
        closable={false}
        maximized={isSmallDevice}
        visible={isLoggedIn && requiresSetup}
        onHide={onHideTermsOfServiceDialog}
    >
        {
            !isMarketplaceAdmin && !!userSetupInfo && userSetupInfo.companyProfileNeeded 
            ?
                <FlexColumnContainer centerContent justifyContent='center' height="50vh" fontSize="20px" fontWeight="bold" >
                    <div className='p-mb-4'>Your company profile is missing.</div>
                    <div className='p-p-4'>Please have your Marketplace Administrator configure the company profile before proceeding.</div>
                </FlexColumnContainer>
            : <div >
                <div className={clsx({ "initial-setup-content-large-device": !isSmallDevice, "initial-setup-content-small-device": isSmallDevice })}>
                    {
                        currentWorkflowStep === WorkflowStep.CompanyInfo &&
                        <CompanyProfileForm formikRef={companyInfoFormikRef} />
                    }
                    {
                        currentWorkflowStep === WorkflowStep.UserInfo &&
                        <UserProfileForm formikRef={userProfileFormikRef} />
                    }
                    {
                        currentWorkflowStep === WorkflowStep.TermsOfService &&
                        <div
                            className={clsx({ "initial-setup-content-large-device": !isSmallDevice, "initial-setup-content-small-device": isSmallDevice })}
                            style={{ marginTop: "18px" }}
                        >
                            <AcceptTermsOfService />
                        </div>
                    }
                </div>

                <div className="initial-setup-controlbar">
                    <Divider />
                    <Button label="Back" loading={isLoading} disabled={!canNavigateBack} icon={PrimeIcons.ARROW_CIRCLE_LEFT} onClick={onNavigateBack} />
                    {
                        currentWorkflowStep === WorkflowStep.CompanyInfo &&
                        <Button
                            label="Next"
                            loading={isSavingCompanyProfile.isExecuting}
                            disabled={!canNavigateForward}
                            style={{ float: 'right' }}
                            icon={PrimeIcons.ARROW_CIRCLE_RIGHT}
                            iconPos="right"
                            onClick={onSubmitCompanyProfile}
                        />
                    }
                    {
                        currentWorkflowStep === WorkflowStep.UserInfo &&
                        <Button
                            label="Next"
                            loading={isSavingUserProfile.isExecuting}
                            disabled={!canNavigateForward}
                            style={{ float: 'right' }}
                            icon={PrimeIcons.ARROW_CIRCLE_RIGHT}
                            iconPos="right"
                            onClick={onSaveUserProfile}
                        />
                    }
                    {
                        currentWorkflowStep === WorkflowStep.TermsOfService &&
                        <Button
                            label="Finish"
                            loading={isLoading}
                            disabled={!canNavigateForward}
                            style={{ float: 'right' }}
                            icon={PrimeIcons.ARROW_CIRCLE_RIGHT}
                            iconPos="right"
                            onClick={onSaveTermsOfServiceAcceptance}
                        />
                    }
                </div>
            </div>
        }
    </Dialog>
}