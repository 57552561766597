import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { addToFavoritesSagaFactory } from "../featureActions/addToFavorites";
import { loadFavoriteMenusSagaFactory } from "../featureActions/loadFavoriteMenus";
import { loadMenuInvitesSagaFactory } from "../featureActions/loadMenuInvites";
import { removeFromFavoritesSagaFactory } from "../featureActions/removeFromFavorites";

// Register this method in the root saga
export function createFavoriteMenusSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        loadFavoriteMenusSagaFactory(context),
        removeFromFavoritesSagaFactory(context),
        loadMenuInvitesSagaFactory(context),
        addToFavoritesSagaFactory(context)
    ]
}