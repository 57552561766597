import { ErrorDisplayType } from "../../../../../../store/factory/sagaWithProgress/types";
import { ImageKind, ImageDto } from "../../../../../commonModels/imageTypes";
import { actionFactory } from "../../types";

export const {

    loadLogo,
    loadLogoReducer,
    loadLogoSagaFactory

} = actionFactory
    .withMappedName("loadLogo")
    .createAsyncAction<{ companyId: number }, ImageDto>({
        actionStatusSelector: state => state.logoState.loadState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.company.getImage(arg.companyId, ImageKind.Logo)
        },
        onFulfilled: (state, action) => {
            state.logoState.currentImage = action.payload.result;   
        },
        errorDisplay: ErrorDisplayType.None
    });