import { Card } from "primereact/card"
import { useDispatch } from "react-redux"
import { useMountEffect } from "../../../../utils/useEffectHelper"
import { loadSliceData } from "../store/featureActions/loadSliceData"
import { OrdersTable } from "./ordersTable"

export const BuyerOrdersDashboard: React.FC = () => {

    const dispatch = useDispatch();

    useMountEffect(() => {
        dispatch(loadSliceData());
    });

    return <Card className="full-height-content-panel" title="Hold Requests">
        <OrdersTable />
    </Card>
} 