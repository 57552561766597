import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { createFetchNextPageSaga } from "../featureActions/fetchNextPage";
import { performSearchSagaFactory } from "../featureActions/performSearch";

// Register this method in the root saga
export function createSearchSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        performSearchSagaFactory(context),
        createFetchNextPageSaga(context),        
    ]
}