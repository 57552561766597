import { Contact } from "../../../common/types";
import { contactAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    deleteCustomerContact,
    deleteCustomerContactReducer,
    deleteCustomerContactSagaFactory

} = actionFactory
    .withMappedName("deleteCustomerContact")
    .createAsyncAction<Contact, Response>({    
    actionStatusSelector: state => state.loadingState,
    asyncCall: (arg, context) => {
       return context.marketplaceApiClient.customer.deleteCustomerContact(arg);
    },
    onFulfilled: (state, action) => {
       contactAdapter.removeOne(state.customerContacts, action.payload.arg.id);
    }
});