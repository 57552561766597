import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { isValue } from "../../../../../utils/valueHelper";
import { getInventoryMap } from "./inventory";

export const getSelectedInventoryIds = (state: RootState) => state.inventoryManagement.selectedInventoryIds;

export const getSelectedInventory = createSelector(
    [
        getSelectedInventoryIds,
        getInventoryMap
    ],
    (selectedIds, inventoryMap) => {
        return selectedIds
            .map(id => inventoryMap[id])
            .filter(isValue);
    }
);