import { ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { currencyFormatOptions } from "../../../../utils/formatHelpers";
import { createCalculatedValueColumnProps } from "../../../../utils/table/calculatedValueColumn";
import { mapColumns } from "../../../../utils/table/mapPropsToColumns";
import { createNumberColumnProps } from "../../../../utils/table/numberColumn";
import { createTextColumnProps } from "../../../../utils/table/stringColumn";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { loadSectionInventory } from "../store/featureActions/loadSectionInventory";
import { getInventoryForSection, getInventoryLoadingStateForSection, getSectionName } from "../store/selectors/vendorMenu";
import { InventoryView } from "../store/types";

export interface PrintableMenuSectionProps { sectionId: number };

export const PrintableMenuSection: React.FC<PrintableMenuSectionProps> = ({ sectionId }) => {

    const dispatch = useDispatch();
    const sectionName = useAppSelector(state => getSectionName(state, sectionId));
    const inventory = useAppSelector(state => getInventoryForSection(state, sectionId));
    const inventoryLoadState = useAppSelector(state => getInventoryLoadingStateForSection(state, sectionId));

    useMountEffect(() => {
        dispatch(loadSectionInventory({ sectionId: sectionId }))
    });

    const getPurcharseQuantity = () => "       ";

    const columns: ColumnProps[] = [

        createTextColumnProps<InventoryView>({ field: "marketingName", header: "Name", sortable: true }),
        createNumberColumnProps<InventoryView>({ field: "quantity", header: "Quantity", formatOptions: { maximumFractionDigits: 4 }, sortable: true }),
        createTextColumnProps<InventoryView>({ field: "unitOfMeasureName", header: "UOM", sortable: true }),
        createNumberColumnProps<InventoryView>({ field: "unitPrice", header: "Unit Price", formatOptions: currencyFormatOptions, sortable: true }),
        createTextColumnProps<InventoryView>({ field: "marketingStrainName", header: "Strain", sortable: true }),
        createCalculatedValueColumnProps<InventoryView>({
            columnKey: "purchaseQuantity",
            header: "Purchase Quantity",
            getValueFn: getPurcharseQuantity,
            style: { textAlign: "right", minWidth: "180px" }
        })
    ]

    return <DataTable
        header={<div>{sectionName}</div>}
        className="p-datatable-sm"
        autoLayout={true}
        value={inventory}
        style={{
            display: !inventoryLoadState.isExecuting && inventory.length === 0 ? "none" : undefined,
            maxWidth: "1200px"
        }}
        loading={inventoryLoadState.isExecuting}
    >
        {mapColumns(columns)}
    </DataTable>
}