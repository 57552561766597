import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { isValue } from "../../../../../utils/valueHelper";

export const getOrderedQuantities = (state: RootState) => state.inventoryManagement.orderedQuantities;

export const getViewOrderedInventoryId = (state: RootState) => state.inventoryManagement.viewOrdersForInventoryId;

export const getOrdersForViewedInventory = createSelector([
    getViewOrderedInventoryId,
    getOrderedQuantities
], (invId, orderedQuantities) => {
    if (!isValue(invId)) return [];
    const orderItems = orderedQuantities[invId];
    if (!isValue(orderItems)) return [];
    return orderItems;
})