import { CreateInitialModalEditState } from "../../../../store/factory/modalEdit/modalEdit";
import { CreateActionStatusInitialState } from "../../../actionStatus/actionStatus";
import { QualitityTemplateDashboardState } from './types';

export const createInitialState = (): QualitityTemplateDashboardState => {
    return {
        loadingState: CreateActionStatusInitialState(),
        templates: [],
        gradeKinds: [],
        addTemplateState: CreateInitialModalEditState()
    };
};

export const initialState: QualitityTemplateDashboardState = createInitialState();