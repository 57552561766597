import { Update } from "@reduxjs/toolkit";
import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../../store/rootSaga";
import { inventoryAdapter } from "../../state";
import { actionFactory, BulkExcludeAction, Inventory, InventoryManagementState } from "../../types";

export const completeBulkExclude = actionFactory.createAppAction("completeBulkExclude");

export const completeBulkExcludeSagaActions = actionFactory.createSagaWithProgressActions<void, Response>(completeBulkExclude.type);

export function completeBulkExcludeReducers(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forSagaWithProgress(completeBulkExcludeSagaActions)
        .withActionStatus(state => state.bulkExcludeState.savingState)
        .onFulfilled((state, _) => {
            state.bulkExcludeState.showConfirmation = false;
            
            inventoryAdapter.updateMany(state.inventory,
                state.selectedInventoryIds.map<Update<Inventory>>(id => ({
                    id: id,
                    changes: {
                        excludeFromMarket: state.bulkExcludeState.action === BulkExcludeAction.Exclude
                    }
                }))
            );
        })
        .build();
}

async function performLoad(_: void, context: SagaAppContext) {
    const state = context.getState();

    const entityIds = state.inventoryManagement.selectedInventoryIds;

    if (state.inventoryManagement.bulkExcludeState.action === BulkExcludeAction.Exclude) {
        return context.marketplaceApiClient.inventoryManagement.bulkExcludeFromMarketplace(entityIds);
    }
    else if (state.inventoryManagement.bulkExcludeState.action === BulkExcludeAction.RemoveExclusion) {
        return context.marketplaceApiClient.inventoryManagement.bulkRemoveExclusion(entityIds);
    }
    else {
        throw new Error("BulkExcludeAction not supported");
    }
}

export function createCompleteBulkExcludeSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: completeBulkExcludeSagaActions
        },
        actionPattern: completeBulkExclude.type,
        debounceInMilliseconds: 250
    });
};