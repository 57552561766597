import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from 'connected-react-router'
import appComponentReducer from '../features/appLayout/store/slice';
import authenticationReducer from '../features/authentication/store/slice';
import diagnosticReducer from '../features/diagnostics/serverInformation/store/slice';
import manageProfileReducer from '../features/marketplaceUserProfile/manageProfile/store/slice';
import syncAdminLicenseReducer from '../features/admin/licenseManagement/store/slice';
import syncAdminCorporateEntityReducer from '../features/admin/corporateEntities/store/slice';
import alertsReducer from '../features/alerts/appAlerts/store/slice';
import identityVerificaitonReducer from '../features/identityVerification/buyerVerification/store/slice';
import companyProfileReducer from '../features/company/profile/store/slice';
import productManagementReducer from '../features/inventorySetup/productManagement/store/slice';
import acceptTermsOfServiceReducer from '../features/initialSetup/acceptTermsOfService/store/slice';
import initialSetupReducer from '../features/initialSetup/workflow/store/slice';
import termsOfServiceAdminReducer from '../features/nodeAdmin/termsOfService/store/slice';
import categoryManagementReducer from '../features/menu/categoryManagement/store/slice';
import inventoryManagementReducer from '../features/inventorySetup/inventoryManagement/store/slice';
import menuAdminReducer from '../features/menu/editor/store/slice';
import liveMenuReducer from '../features/menu/endUser/store/slice';
import productDetailsReducer from '../features/menu/productDetails/store/slice';
import searchReducer from '../features/search/dashboard/store/slice';
import ageConfirmationReducer from '../features/ageConfirmation/dialog/store/slice';
import contactVendorReducer from '../features/dialogs/vendor/store/slice';
import messageDialogReducer from '../features/dialogs/message/store/slice';
import errorQueueReducer from '../features/dialogs/error/store/slice';
import inventoryImagesReducer from '../features/menu/inventoryImageGallery/store/slice';
import menuDashboardReducer from '../features/menu/dashboard/store/slice';
import categoryAssignmentReducer from '../features/inventorySetup/categoryAssignment/store/slice';
import vendorTopPanelReducer from '../features/menu/vendorTopPanel/store/slice';
import favoriteMenusReducer from '../features/marketplaceUserProfile/favoriteMenus/store/slice';
import notAuthorizedReducer from '../features/authorization/notAuthorized/store/slice';
import buyerHoldRequestReducer from '../features/orders/buyerOrders/store/slice';
import pricingDashboardReducer from '../features/pricing/priceModelDashboard/store/slice';
import pricingEditorReducer from '../features/pricing/priceModelEditor/store/slice';
import userTableSettingsReducer from '../features/userAppSettings/userTableSettings/store/slice';
import customerDashboardReducer from '../features/customer/dashboard/store/slice';
import customerEditorReducer from '../features/customer/customerEditor/store/slice';
import orderEditorReducer from '../features/customer/orderEditor/store/slice';
import galleryTestingReducer from '../features/diagnostics/galleryTests/store/slice';
import qualityMetricKindsReducer from '../features/qualityGradeTemplates/metricKinds/store/slice';
import qualityGradeKindsReducer from '../features/qualityGradeTemplates/gradeKinds/store/slice';
import qualityTemplateDashboardReducer from '../features/qualityGradeTemplates/templateDashboard/store/slice';
import qualityTemplateEditorReducer from '../features/qualityGradeTemplates/templateEditor/store/slice';
import qualityAssessmentEditorReducer from '../features/qualityAssessments/assessmentEditor/store/slice';
import nodeAdminProvisioningReducer from '../features/nodeAdmin/provisioning/store/slice';
import companyMarketplaceViewReducer from '../features/company/marketplaceView/store/slice';

import { appHistory } from "./historyInstance";

const rootReducer = combineReducers({
    router: connectRouter(appHistory),
    appComponent: appComponentReducer,
    authentication: authenticationReducer,
    diagnostics: diagnosticReducer,
    manageProfile: manageProfileReducer,
    licenseManagement: syncAdminLicenseReducer,
    corporateEntityManagement: syncAdminCorporateEntityReducer,    
    alerts: alertsReducer,
    identityVerificaiton: identityVerificaitonReducer,
    companyProfile: companyProfileReducer,
    productManagement: productManagementReducer,
    acceptTermsOfService: acceptTermsOfServiceReducer,
    initialSetup: initialSetupReducer,
    termsOfServiceAdmin: termsOfServiceAdminReducer,
    categoryManagement: categoryManagementReducer,
    inventoryManagement: inventoryManagementReducer,
    menuAdmin: menuAdminReducer,
    liveMenu: liveMenuReducer,
    productDetails: productDetailsReducer,
    search: searchReducer,
    ageConfirmation: ageConfirmationReducer,
    contactVendor: contactVendorReducer,
    messageDialog: messageDialogReducer,
    errorQueue: errorQueueReducer,
    inventoryImages: inventoryImagesReducer,    
    menuDashboard: menuDashboardReducer,
    categoryAssignment: categoryAssignmentReducer,
    vendorTopPanel: vendorTopPanelReducer,
    favoriteMenus: favoriteMenusReducer,
    notAuthorized: notAuthorizedReducer,
    buyerHoldRequest: buyerHoldRequestReducer,
    pricingDashboard: pricingDashboardReducer,
    pricingEditor: pricingEditorReducer,    
    userTableSettings: userTableSettingsReducer,
    customerDashboard: customerDashboardReducer,
    customerEditor: customerEditorReducer,
    orderEditor: orderEditorReducer,
    galleryTesting: galleryTestingReducer,
    qualityMetricKinds: qualityMetricKindsReducer,
    qualityGradeKinds: qualityGradeKindsReducer,
    qualityTemplateDashboard: qualityTemplateDashboardReducer,
    qualityTemplateEditor: qualityTemplateEditorReducer,
    qualityAssessmentEditor: qualityAssessmentEditorReducer,
    nodeAdminProvisioning: nodeAdminProvisioningReducer,
    companyMarketplaceView: companyMarketplaceViewReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;