import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../../store/rootSaga";
import { actionFactory, InventoryManagementState } from "../../types";
import { loadMenuAssignments } from "../loadMenuAssignments";

export const completeInventoryCategoryAssignment = actionFactory.createAppAction<number[]>("completeInventoryCategoryAssignment");

const sagaActions = actionFactory.createSagaWithProgressActions<number[], Response>(completeInventoryCategoryAssignment.type);

export function completeCategoryAssignmentReducers(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.categoryAssignmentState.savingState)
        .onFulfilled((state, _) => {
            state.categoryAssignmentState.parentModel = undefined;
            
        })
        .build();
}

async function performLoad(categoryIds: number[], context: SagaAppContext) {
    const state = context.getState();
    if (state.inventoryManagement.categoryAssignmentState.parentModel === undefined) throw new Error("Cannot save inventory category assignments without a valid package selected");

    const inventory = state.inventoryManagement.categoryAssignmentState.parentModel;
    return context.marketplaceApiClient.inventoryManagement.setCategoryIdsForInventory(inventory.id, categoryIds);
    
}

export function createCompleteCategoryAssignmentSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions,
            successCallback: (_arg, _result, context) => context.dispatch(loadMenuAssignments())
        },
        actionPattern: completeInventoryCategoryAssignment.type,
        debounceInMilliseconds: 250
    });
};