import { isValue } from "../../utils/valueHelper";
import { isFailedApiResponse, isProblemDetail, isValidationFailure, isFailureMessage, ErrorContent } from "./types";

export const GenericNetworkErrorMessage = "A problem was encountered connecting to Node's web services.";

export const messageFromError = (result: any): string => {
    
    if (isFailedApiResponse(result)) {
        return result.message;
    }

    if (isProblemDetail(result) && result.title !== null) {        
        return result.title;
    }
    
    if (isValidationFailure(result)) {
        return result.errorMessage;
    }

    if (isFailureMessage(result)){
        
        if (result.message === "Failed to fetch") {
            return GenericNetworkErrorMessage;
        }
        
        return result.message;
    }

    if (result instanceof Response) {        
        if (result.status === 500) {
            return "Server failure";
        }
        else if (result.status === 401) {
            return "Not authorized";
        }
        else {
            return `Failure: http status = ${result.status}`;
        }
    }

    if (typeof (result) === "string") {
        if (result === "Network Error") {
            return GenericNetworkErrorMessage;
        }
        return result;
    }
    
    if (result.error !== undefined && result.error.name !== undefined && result.error.message !== undefined && result.error.name !== undefined) {
        if (result.error.name === "TypeError" && result.error.message === "Failed to fetch") {
            return GenericNetworkErrorMessage;
        }
        else if (result.error.message !== undefined){
            return `${result.error.message}`;
        }
    }
    return "Unknown failure";
}


export function getErrorResult(arg: any) : ErrorContent {
    if (isFailureMessage(arg)) return arg;
    if (isValidationFailure(arg)) return arg;
    if (isProblemDetail(arg)) return arg;
    if (isFailedApiResponse(arg)) return arg;

    if (arg instanceof Response) {        
        if (arg.status === 500) {
            return "Server failure";
        }
        else if (arg.status === 401) {
            return "Not authorized";
        }
        else {
            return `Failure: http status = ${arg.status}`;
        }
    }

    if (typeof (arg) === "string") {
        if (arg === "Network Error") {
            return GenericNetworkErrorMessage;
        }
        return arg;
    }

    if (arg.error !== undefined && arg.error.name !== undefined && arg.error.message !== undefined && arg.error.name !== undefined) {
        if (arg.error.name === "TypeError" && arg.error.message === "Failed to fetch") {
            return GenericNetworkErrorMessage;
        }
        else if (arg.error.message !== undefined){
            return `${arg.error.message}`;
        }
    }

    return "As unknown failure has occured. Please refresh your browser";
}

export const messageFromErrorContent = (content: ErrorContent) : string | null => {    

    if (isFailedApiResponse(content)) {
        return content.message;
    }

    if (isProblemDetail(content) && content.title !== null) {        
        return content.detail;
    }
    
    if (isValidationFailure(content)) {
        return content.errorMessage;
    }

    if (isFailureMessage(content)){
        
        if (content.message === "Failed to fetch") {
            return GenericNetworkErrorMessage;
        }
        
        return content.message;
    }

    if (isValue(content) && typeof(content) === "string") {
        return content;
    }

    return null;
}