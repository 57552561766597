import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, ContactVendorState } from "../types";

export const hideVendorContactDetails = actionFactory.createAppAction("hideVendorContactDetails");

export function hideVendorContactDetailsReducer(reducerFactory: IReducerFactory<ContactVendorState>): IReducerFactory<ContactVendorState> {
    return reducerFactory
        .forAction(hideVendorContactDetails)
        .addHandler((state, _) => {
            state.showVendor = false;
            state.vendor = null;
        })
        .build();
}