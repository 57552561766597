import { actionFactory, InventoryLicense } from "../../types";

export const {

    loadInventoryLicenses,
    loadInventoryLicensesReducer,
    loadInventoryLicensesSagaFactory

} = actionFactory
    .withMappedName("loadInventoryLicenses")
    .createAsyncAction<void, InventoryLicense[]>({
        actionStatusSelector: state => state.inventoryLicensesLoadState,
        asyncCall: (_, context) => {
            return context.marketplaceApiClient.inventoryManagement.getInventoryLicenses();
        },
        onFulfilled: (state, action) => {
            state.inventoryLicenses = action.payload.result;  
        }
    });