import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { createLoadAgeConfirmationSaga } from "../featureActions/loadAgeConfirmation";
import { createSaveAgeConfirmationSaga } from "../featureActions/saveAgeConfirmation";

// Register this method in the root saga
export function createAgeConfirmationSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        createLoadAgeConfirmationSaga(context),
        createSaveAgeConfirmationSaga(context)
    ]
}