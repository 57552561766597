import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../../store/rootSaga";
import { SyncRequestDto } from "../../../../../commonModels/syncRequest";
import { actionFactory, ProductManagementState } from "../../types";

export const requestProductSync = actionFactory.createAppAction("requestProductSync");

const sagaActions = actionFactory.createSagaWithProgressActions<void, SyncRequestDto>(requestProductSync.type);

export function requestProductSyncReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)     
        .withActionStatus(state => state.requestProductSyncState)           
        .onFulfilled((state, action) => {
            state.latestSyncRequest = action.payload.result;            
        })
        .build();
}

async function performAction(_: void, context: SagaAppContext): Promise<SyncRequestDto> {
    return context.marketplaceApiClient.productManagement.requestProductSync();    
}

export function createRequestProductSyncSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: requestProductSync.type,
        debounceInMilliseconds: 250
    });
};