import clsx from "clsx";
import { Field, FieldProps, useFormikContext } from "formik";
import { AutoComplete, AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { searchStates } from "../store/featureActions/searchStates";
import { getStateSearchResults } from "../store/selectors/states";
import { CompanyProfile } from "../store/types";

export interface CompanyInfoProps {    
    allowStateChange: boolean;
}
export const CompanyInfo: React.FC<CompanyInfoProps> = ({ allowStateChange }) => {


    const dispatch = useDispatch();
    const formik = useFormikContext<Partial<CompanyProfile>>();
    
    const stateSearchResults = useAppSelector(getStateSearchResults);
    

    const onSearchStates = (searchText: AutoCompleteCompleteMethodParams) => {
        dispatch(searchStates(searchText.query));
    }
   
    return <div>     

        <div style={{ maxWidth: "1000px" }}>

            <div className="p-fluid">

                <div className="p-field">
                    <label>Company Name</label>
                    <Field
                        type="text"
                        name="displayName"
                        className={clsx({ "p-invalid": !!formik.errors.displayName })}
                        onBlur={() => formik.submitForm()}
                        as={InputText}                                                
                    >
                    </Field>
                    <small>{formik.errors.displayName}</small>
                </div>

                <div className="p-field">
                    <label>State</label>
                    <Field
                        type="text"
                        name="stateAbbreviation"
                    >
                        {
                            (props: FieldProps<CompanyProfile>) =>
                                <AutoComplete
                                    dropdown
                                    disabled={!allowStateChange}
                                    forceSelection
                                    name="state"
                                    className={clsx({ "p-invalid": !!formik.errors.stateAbbreviation })}
                                    value={formik.values.stateAbbreviation}
                                    suggestions={stateSearchResults}
                                    completeMethod={onSearchStates}
                                    onChange={(event) => {
                                        formik.setFieldValue(props.field.name, event.target.value);
                                    }}
                                    onBlur={() => formik.submitForm()}
                                />
                        }

                    </Field>
                    <small>{formik.errors.stateAbbreviation}</small>
                </div>
            </div>
        </div>
    </div>
}