import { createIntegerIdMap } from "../../../../../../utils/identity";
import { PricingModel } from "../../../../../pricing/sharedTypes";
import { actionFactory } from "../../types";

export const {

    loadPriceModels,
    loadPriceModelsReducer,
    loadPriceModelsSagaFactory

} = actionFactory
    .withMappedName("loadPriceModels")
    .createAsyncAction<void, PricingModel[]>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (_arg, context) => {
            return context.marketplaceApiClient.pricing.getPricingModels();
        },
        onFulfilled: (state, action) => {
            state.pricingModelMap = createIntegerIdMap(action.payload.result);
            state.pricingModels = action.payload.result.sort((left, right) => left.name.toLowerCase().localeCompare(right.name.toLowerCase()));
        }
    });