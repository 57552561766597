import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FormikEditorClassStyles } from "../../../../components/formik/classNameAttributes";
import { DropDownField } from "../../../../components/formik/dropDownField";
import { StringField } from "../../../../components/formik/stringField";
import { validStates } from "../../../../utils/usStates";
import { Company, CompanyType, companyTypes } from "../../../company/profile/store/types";


export const AddCompanyEditor: React.FC = () => {

    const editorStyles: FormikEditorClassStyles = {
        inputClassName: "company-field-input",
        containerClassName: "company-field-container",
        labelClassName: "company-field-label"
    }

    return <FlexColumnContainer className="company-editor">

        <StringField<Company>
            fieldName="displayName"
            defaultLabel="Name"
            {...editorStyles}
        />

        <StringField<Company>
            fieldName="tenantId"
            defaultLabel="Tenant"
            {...editorStyles}
        />

        <DropDownField<Company, string>
            defaultLabel="State"
            fieldName="stateAbbreviation"
            availableValues={validStates.map(state => ({ label: state, value: state }))}
            {...editorStyles}
        />

        <DropDownField<Company, CompanyType>
            defaultLabel="Type"
            fieldName="companyType"
            availableValues={companyTypes.map(companyType => ({ label: companyType, value: companyType }))}
            {...editorStyles}
        />

    </FlexColumnContainer>

}
