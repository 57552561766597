import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { actionFactory, CategoryAssignmentState } from "../../types";

export const assignCategories = actionFactory.createAppAction<number[]>("assignItems");

export const assignCategoriesReducers = (reducerFactory: IReducerFactory<CategoryAssignmentState>): IReducerFactory<CategoryAssignmentState> => {
        return reducerFactory
            .forAction(assignCategories)
            .addHandler((state, action) => {                               
                state.selected = action.payload;
            })
            .build();
    }