import ApiClient from "../../apiCommunication/apiClient";
import { createUtcDateSerializationFunction } from "../../apiCommunication/serialization";
import { ServerHealth } from "../serverInformation/store/types";

const deserializeServerHealthDates = createUtcDateSerializationFunction<ServerHealth>({ properties: ["currentServerTime"] });

export default class Diagnostics {

    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    getServerHealth = (): Promise<ServerHealth> => {
        return this
            .apiClient
            .executeGet<ServerHealth>(`Diagnostic`)
            .then(deserializeServerHealthDates);
    };

    notFound = (): Promise<ServerHealth> => {
        return this
            .apiClient
            .executeGet<ServerHealth>(`Diagnostic/NotFound`);
    };

}