import { actionFactory, CompanyProfile } from "../types";
import { loadBanner } from "./images/loadBanner";
import { loadLogo } from "./images/loadLogo";

export const {
    loadCustomerProfile,
    loadCustomerProfileReducer,
    loadCustomerProfileSagaFactory
} = actionFactory
    .withMappedName("loadCustomerProfile")
    .createAsyncAction<void, CompanyProfile>({
        actionStatusSelector: state => state.profileLoadingState,
        asyncCall: (_arg, context) => {
            return context.marketplaceApiClient.company.getCompanyProfile();
        },
        onFulfilled: (state, action) => {
            state.companyProfile = action.payload.result;
        },
        successCallback: (_arg, result, context) => {
            context.dispatch(loadLogo({ companyId: result.id }))
            context.dispatch(loadBanner({ companyId: result.id }))
        }
    });