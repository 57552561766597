import { AssessmentTemplate } from "../../../models/assessmentTemplate";
import { actionFactory } from "../types";

export const {

    saveAssessmentTemplate,
    saveAssessmentTemplateReducer,
    saveAssessmentTemplateSagaFactory

} = actionFactory
    .withMappedName("saveAssessmentTemplate")
    .createAsyncAction<AssessmentTemplate, AssessmentTemplate>({
        actionStatusSelector: state => state.templateSaveState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.quality.upsertAssessmentTemplate(arg);
        },
        onFulfilled: (state, action) => {
            state.template = action.payload.result;           
        }
    });