import { EntityState } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { ColumnFilterMatchModeType } from "primereact/column";
import { DataTableFilterMeta, DataTablePageParams, DataTableSortParams } from "primereact/datatable";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { IntegerId } from "../../../commonModels/integerId";

export const sliceName: string = "user-table-settings";

export const actionFactory = new ActionFactory<UserTableSettingsState>(sliceName);

export interface UserTableSettings extends IntegerId {
    user: string;
    name: string;
    viewId: string;
    rank: number;
    schemaVersion: number;
    settings: string;
    created: DateTime;
    updated: DateTime;
}

export interface UpsertTableSettingsRequest extends IntegerId {
    name: string;
    viewId: string;
    rank: number;
    schemaVersion: number;
    settings: string;
}

export interface ColumnState {
    columnId: string;
    header: string;
    width: number | string | undefined;
    isVisible: boolean;  
    filter?: boolean;
    filterMatchMode?: ColumnFilterMatchModeType;    
}

export interface DataTableViewState extends DataTablePageParams, DataTableSortParams {
    filters: DataTableFilterMeta;
    columnWidths: string;
    columnOrder: string[];
    selection: any[];
    tableWidth: string | number
}

// export interface TransitionTableState {
//     columnState: ColumnState[];
//     additionalSettings: string | null | DataTableViewState;
// }

export interface TableState {
    columnState: ColumnState[];
    additionalSettings: DataTableViewState | null;
}

export interface RemountState {
    tableRemountNeeded: boolean;
    remountInProgress: boolean;
    showTable: boolean;
    needsStateReapplied: boolean; // deprecated
}

export interface ViewSettings {
    id: string;
    selectedUserTableSettings: number | null;    
    currentState: TableState;   
    remountState: RemountState;   
    restoreSentinel: boolean;
}

export interface SaveAsModel {
    newName: string;
    viewId: string;
}
export interface SaveAsState extends SaveAsModel {
    visible: boolean;
    savingState: ActionStatus;
}

export interface ViewDefinition {
    id: string;
    columnState: ColumnState[];
    tableState?: DataTableViewState;
}

export interface UserTableSettingsState {
    userTableSettingsLoadingState: ActionStatus;
    userTableSettingsSaveState: ActionStatus;

    // The settings organized by view id
    viewSettings: EntityState<ViewSettings>;

    // The saved settings for the current user
    userTableSettings: EntityState<UserTableSettings>;

    // The column definitions as defined by the ui initializing the view (the available columns with which the table was initialized)
    // Initialized the first time a view is rendered so the stored user settings can be merged with the current definition
    viewDefinitions: EntityState<ViewDefinition>;

    saveAsState: SaveAsState;  
    commandBarActionState: ActionStatus;  
}