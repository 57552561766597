import { JwtPayload } from "jwt-decode";
import { UserSettings } from 'oidc-client';
import { ActionFactory } from '../../../store/factory/action/actionFactory';
import { ErrorContent } from "../../errorHandling/types";
import { ActionStatus } from "../../actionStatus/actionStatus";

export const postLoginRedirectUriStorageKey = 'redirectUri';

export const sliceName: string = "auth";

export const actionFactory = new ActionFactory<UserAuthState>(sliceName);

export enum AuthenticationAction {
    LoggingIn,
    LoggingOut
}

export interface AccessTokenData extends JwtPayload {
    TenantId: string,
    role: string[] | string | undefined,
};

export interface CurrentUserState {    
    userSettings: UserSettings,
    accessTokenData: AccessTokenData
}

export interface UserAuthState {
    CompleteLoginState: ActionStatus,
    LoadAuthState: ActionStatus,
    CurrentUser: CurrentUserState | null,    
    AuthenticationAction: AuthenticationAction | null,
    ErrorMessage: ErrorContent,
    signInCompleted: boolean
}