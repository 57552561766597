export type ErrorResults<T> = Partial<{ [Property in keyof T]: string }>;

// from OWASP: https://owasp.org/www-community/OWASP_Validation_Regex_Repository

export const emailRegex = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;

export const safeTextRegex = /^[a-zA-Z0-9 .-]+$/;

export const validNameRegex = /^[a-zA-Z0-9' .-]+$/;

export const hasUpperCaseRegex = /[A-Z]+/;

export const hasLowerCaseRegex = /[a-z]+/;

export const hasNonAlphaNumeric = /\W/;


export const validUSStatesRegex = /^(AE|AL|AK|AP|AS|AZ|AR|CA|CO|CT|DE|DC|FM|FL|GA|GU|HI|ID|IL|IN|IA|KS|KY|LA|ME|MH|MD|MA|MI|MN|MS|MO|MP|MT|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PW|PA|PR|RI|SC|SD|TN|TX|UT|VT|VI|VA|WA|WV|WI|WY)$/;

export const passwordMinLength: number = 8;
export const passwordMaxLength: number = 128;

export const simplePassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,128}$/;

export function emailIsValid(value: string) : boolean {
    return emailRegex.test(value);
}

export const urlRegex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i