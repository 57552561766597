import { Button } from "primereact/button";
import React from "react";
import { useDispatch } from "react-redux";
import { AssignmentPickList } from "../../../../components/assignment/assignmentPickList";
import { LoadingContainer } from "../../../../components/loadingContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { createTextColumn } from "../../../../utils/table/stringColumn";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { Product } from "../../../inventorySetup/productManagement/store/types";
import { assignProducts } from "../store/featureActions/assignProducts";
import { cancelAssignProductsToCategory } from "../store/featureActions/cancelAssignProductsToCategory";
import { loadProductsAndAssignments } from "../store/featureActions/loadProductsAndAssignments";
import { removeProductAssignments } from "../store/featureActions/removeProductAssignments";
import { saveProductAssignments } from "../store/featureActions/saveProductAssignments";
import { setSelectedAssignedProducts } from "../store/featureActions/setSelectedAssignedProducts";
import { setSelectedAvailableProducts } from "../store/featureActions/setSelectedAvailableProducts";
import { getAssignedProducts, getAvailableProducts, getCategoryForProductAssignment, getProductAssignmentLoadState, getSelectedAssignedProducts, getSelectedAvailableProducts } from "../store/selectors/productAssignment";
import './categoryProductAssignment.css';

export const CategoryProductAssignment: React.FC = () => {

    const dispatch = useDispatch();
    const category = useAppSelector(getCategoryForProductAssignment);

    useMountEffect(() => {
        if (category === undefined) return;
        dispatch(loadProductsAndAssignments(category));
    });

    const onSave = () => {
        dispatch(saveProductAssignments());
    }
 
    const onCancel = () => {
        dispatch(cancelAssignProductsToCategory());
    }

    const productTableColumns = [
        createTextColumn<Product>({ columnKey: "licenseNumber", field: "licenseNumber", header: "License", filter: true, filterMatchMode: "contains", style: { width: "150px" } }),
        createTextColumn<Product>({ columnKey: "metrcName",field: "metrcName", header: "Metrc Name", filter: true, filterMatchMode: "contains", style: { width: "200px" } })
    ];

    return <div className="product-assignment-container">

        <LoadingContainer actionStatusSelector={getProductAssignmentLoadState}>

            <AssignmentPickList<Product>
                availableListProps={{
                    allItemsSelector: getAvailableProducts,
                    selectedItemsSelector: getSelectedAvailableProducts,
                    setSelectedItemAction: setSelectedAvailableProducts
                }}
                assignedListProps={{
                    allItemsSelector: getAssignedProducts,
                    selectedItemsSelector: getSelectedAssignedProducts,
                    setSelectedItemAction: setSelectedAssignedProducts
                }}
                assignAction={assignProducts}
                unassignAction={removeProductAssignments}
            >
                {productTableColumns}
            </AssignmentPickList>
          
            <div>
                <Button className="p-ml-2" label="Save" style={{ float: "right" }} onClick={onSave} />
                <Button label="Cancel" style={{ float: "right" }} onClick={onCancel} />
            </div>

        </LoadingContainer>

    </div>

}