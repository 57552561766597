import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, MessageDialogState } from "../types";

export const hideDialog = actionFactory.createAppAction("hideDialog");

export function hideDialogReducers(reducerFactory: IReducerFactory<MessageDialogState>): IReducerFactory<MessageDialogState> {
    return reducerFactory
        .forAction(hideDialog)
        .addHandler((state, _) => {            
            state.messageInfo = undefined;
        })
        .build();
}