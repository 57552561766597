import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { priceModelEditorRoute } from "../../../navigation/routes";
import { upsertPricingModel } from "../store/featureActions/upsertPricingModel";
import { getAddPricingModelState, getNavigateToNewModelId } from "../store/selectors/priceModels";

export const TopCommandBar: React.FC = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const addPricingModelState = useAppSelector(getAddPricingModelState);
    const navigateToNewModelId = useAppSelector(getNavigateToNewModelId);

    const onAddMenu = () => {
        dispatch(upsertPricingModel({
            name: "Pricing Model"
        }));
    }

    useEffect(() => {
        if (isValue(navigateToNewModelId)) {
            history.push(priceModelEditorRoute.createPath(navigateToNewModelId));
        }
    }, [navigateToNewModelId, history]);


    return <FlexRowContainer>
        <Button
            label="Add Pricing Model"
            icon={PrimeIcons.PLUS}
            onClick={onAddMenu}
            loading={addPricingModelState.isExecuting}
        />
    </FlexRowContainer>

}
