import clsx from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import logo_node from '../../assets/marketplace-logo.svg';
import { createEnterKeyHandler } from '../../utils/keyboardEvents';
import { searchRoute } from '../navigation/routes';
import { smallDeviceQuery } from '../responsiveconfiguration';
import { setSearchText } from '../search/dashboard/store/featureActions/setSearchText';
import './index.css';
import { MarketingPanel } from './marketingPanel';
import { useAppSelector } from '../../store/configureStore';
import { getIsAuthenticated } from '../authentication/store/selectors/isAuthenticated';

export const Home: React.FC = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    const [searchValue, setSearchValue] = useState<string>("");
    const isAuthenticated = useAppSelector(getIsAuthenticated)

    const onSearch = () => {
        dispatch(setSearchText(searchValue));
        history.push(searchRoute.path);
    }

    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    }

    const onSearchInputKeyUp = createEnterKeyHandler(onSearch);

    return <div>
        {
            !isSmallDevice &&
            <div className="p-d-flex p-jc-center p-ai-center banner-container">
                <img src={logo_node} alt="" height="140px" width="100%" ></img>
            </div>
        }
        <div className="p-d-flex p-jc-center p-ai-center search-label-container">

            <div className={clsx({
                "text-search-label": !isSmallDevice,
                "text-search-label-small": isSmallDevice
            })}>Wholesale Cannabis Simplified</div>

        </div>
        {
            isAuthenticated &&
            <div className={clsx("p-d-flex p-jc-center p-ai-center search-container", {
                "p-mb-4": !isSmallDevice,
            })}>

                <InputText
                    className={clsx("p-p-3", {
                        "text-search-small": isSmallDevice,
                        "text-search-large": !isSmallDevice
                    })}
                    placeholder="Search vendors..."
                    onChange={onSearchInputChange}
                    onKeyUp={onSearchInputKeyUp}
                />
                <Button
                    className={clsx("p-ml-2", {
                        "p-button-lg": !isSmallDevice,
                        "p-button-sm": isSmallDevice
                    })}
                    icon={PrimeIcons.SEARCH}
                    onClick={onSearch}
                />

            </div>
        }

        <Divider />

        <MarketingPanel />
    </div>
}