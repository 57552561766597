import { createSelector } from "@reduxjs/toolkit";
import { getCompanyProfileLoadingState } from "../../../../company/profile/store/selectors/actionStatus";
import { getUserProfileLoadingState } from "../../../../marketplaceUserProfile/manageProfile/store/selectors/actionStatus";
import { getLoadingState as getTOSLoadingState } from "../../../acceptTermsOfService/store/selectors/actionStatus";

export const getIsLoading = createSelector(
    [
        getCompanyProfileLoadingState,
        getUserProfileLoadingState,
        getTOSLoadingState
    ], 
    (companyProfileLoading, userProfileLoading, termsOfServiceLoading) => {
        return companyProfileLoading.isExecuting || userProfileLoading.isExecuting ||termsOfServiceLoading.isExecuting;
    }
);