import * as yup from 'yup';
import { SaveAsModel } from '../store/types';


export const saveAsValidationSchema: yup.SchemaOf<SaveAsModel> = yup.object().shape({
    viewId: yup.string()        
        .required("Name is required"),
    newName: yup.string()
        .min(1, "Name must be at least 1 character")
        .max(255, "Name must be at most 64 characters")
        .required("Name is required"),
});