import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory, ConfirmAgeState } from "../types";

const localStorageKey = "age-confirmation";
export const loadAgeConfirmation = actionFactory.createAppAction("loadAgeConfirmation");

const sagaActions = actionFactory.createSagaWithProgressActions<void, boolean>(loadAgeConfirmation.type);

export function loadAgeConfirmationReducers(reducerFactory: IReducerFactory<ConfirmAgeState>): IReducerFactory<ConfirmAgeState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .onFulfilled((state, action) => {
            state.hasConfirmedAge = action.payload.result;     
            state.hasLoaded = true;       
        })
        .build();
}

async function performLoad(_arg: void, _context: SagaAppContext): Promise<boolean> {   
   const localStorageValue = localStorage.getItem(localStorageKey);
   return localStorageValue === "true";
}

export function createLoadAgeConfirmationSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: loadAgeConfirmation.type,
        debounceInMilliseconds: 250
    });
};