import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { addQualityGradeKindReducers } from './featureActions/addQualityGradeKind';
import { deleteQualityGradeValueReducer } from './featureActions/deleteQualityGradeValue';
import { loadSliceDataReducer } from './featureActions/loadSliceData';
import { publishQualityGradeKindReducer } from './featureActions/publishQualityGradeKind';
import { resequenceGradeValueReducer } from './featureActions/resequenceGradeValue';
import { selectQualityGradeKindReducer } from './featureActions/selectQualityGradeKind';
import { upsertQualityGradeKindReducer } from './featureActions/upsertQualityGradeKind';
import { upsertQualityGradeValueReducer } from './featureActions/upsertQualityGradeValue';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(loadSliceDataReducer)
            .addRange(addQualityGradeKindReducers)
            .add(upsertQualityGradeKindReducer)
            .add(selectQualityGradeKindReducer)
            .add(upsertQualityGradeValueReducer)
            .add(resequenceGradeValueReducer)
            .add(deleteQualityGradeValueReducer)
            .add(publishQualityGradeKindReducer);
            // Create your feature actions in ./featureActions and then add them to the reducer factory here
            // with .Add(<your-action-reducer-name>);
            
    }
});

export default slice.reducer;

export const { resetState } = slice.actions;