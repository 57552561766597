import { createAction } from "@reduxjs/toolkit";
import { IReducerFactory } from "../../../../store/factory/interfaces";
import { SagaAppContext } from "../../../../store/rootSaga";
import { createExecuteWithProgressSaga } from "../../../../store/factory/sagaWithProgress/executeWithProgress";
import { actionFactory, AuthenticationAction, UserAuthState } from "../types";

const beginLogoutActionName = actionFactory.createActionName("beginLogout");
export const beginLogout = createAction(beginLogoutActionName);

const sagaActions = actionFactory.createSagaWithProgressActions<void, void>(beginLogoutActionName);
export function beginLogoutReducers(reducerFactory: IReducerFactory<UserAuthState>): IReducerFactory<UserAuthState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .onPending((state, _) => {
            state.AuthenticationAction = AuthenticationAction.LoggingOut;
        })
        .onRejected((state, action) => {
            state.AuthenticationAction = null;
            state.ErrorMessage = action.payload.errorResult;
        })
        .onFulfilled((state, _) => {
            state.AuthenticationAction = null;
            state.ErrorMessage = null;
            state.CurrentUser = null;
        })
        .build();
}

async function logout(_: void, context: SagaAppContext): Promise<void> {
    return context.authService.newLogout()
}

export function createBeginLogoutSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: logout,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: beginLogoutActionName,
        debounceInMilliseconds: 250
    });
};