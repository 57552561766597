import * as yup from 'yup';
import { Contact } from '../common/types';

export const contactValidationSchema: yup.SchemaOf<Partial<Contact>> = yup.object().shape({

    name: yup.string()
        .min(1, "Name must be at least 1 character")
        .max(255, "Name must be at most 255 characters")
        .required("Name is required"),

    email: yup.string()
        .max(128, "Account number must be at most 128 characters")
        .optional()
        .nullable(),

    phoneNumber: yup.string()
        .max(32, "Phone number must be at most 32 characters")
        .optional()
        .nullable(),

    position: yup.string()
        .max(64, "Position must be at most 64 characters")
        .optional()
        .nullable(),

    customerId: yup.number().required("Customer is required"),
    id: yup.number().required("Id is required")
});