import { RootState } from "../../../../../store/rootReducer";
import { gradeKindAdapter, gradeValueAdapter } from "../state";

const gradeKindSelectors = gradeKindAdapter.getSelectors((state: RootState) => state.qualityGradeKinds.gradeKinds)
export const getGradeKindEntities = gradeKindSelectors.selectEntities;
export const getGradeKinds = gradeKindSelectors.selectAll;

const gradeValueSelectors = gradeValueAdapter.getSelectors((state: RootState) => state.qualityGradeKinds.gradeValues)
export const getGradeValues = gradeValueSelectors.selectAll;



