import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { useEffect, useState } from 'react';
import { ActionStatus } from '../../features/actionStatus/actionStatus';
import { Note } from '../../features/commonModels/note';
import { useAppSelector } from '../../store/configureStore';
import { RootState } from '../../store/rootReducer';
import { isValue } from '../../utils/valueHelper';
import { FlexColumnContainer } from '../containers/flexColumnContainer';
import { OverlayWaitDisplay } from '../waitDisplay';
import { NoteEditor } from './note';
import './notesList.css';

export interface NotesListProps<T extends Note> {
    actionStatusSelector: (state: RootState) => ActionStatus;
    noteIdsSelector: (state: RootState) => number[];
    getByIdSelector: (state: RootState, id: number) => T | undefined;
    onUpdateNote: (note: T) => void;
    onSaveNewNote: (notes: string) => void;
    onDeleteNote: (note: T) => void;
    newNoteInputClassName?: string;
    listClassName?: string;
}

export const NotesList = <T extends Note>({
    actionStatusSelector,
    noteIdsSelector,
    onSaveNewNote,
    getByIdSelector,
    onDeleteNote,
    onUpdateNote,
    newNoteInputClassName,
    listClassName
}: NotesListProps<T>) => {

    const noteIds = useAppSelector(noteIdsSelector);
    const status = useAppSelector(actionStatusSelector);

    const [newNote, setNewNote] = useState<string>("");

    useEffect(() => {
        if (!!status.hasExecuted) {
            setNewNote("");
        }
    }, [status.hasExecuted]);

    const saveNewNote = () => {
        onSaveNewNote(newNote);
    }

    return <OverlayWaitDisplay
        isWaiting={status.isExecuting}
    ><FlexColumnContainer
        className='notes-list-container'
    >

            <InputTextarea
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                autoResize={true}
                className={newNoteInputClassName}
            />

            <Button
                type='button'
                label='Add Note'
                disabled={(!isValue(newNote) || newNote.length <= 0)} onClick={saveNewNote}
                style={{ width: "120px" }}
            />

            <FlexColumnContainer
                className={listClassName ?? 'notes-list'}
            >
                {
                    noteIds.map(noteId => <NoteEditor<T>
                        key={noteId}
                        id={noteId} 
                        dataSelector={getByIdSelector}
                        onDeleteNote={onDeleteNote}
                        onUpdateNote={onUpdateNote}
                    />
                    
                    // <FlexColumnContainer
                    //     key={note.id}
                    //     gap="5px"
                    //     padding="5px"
                    // >
                    //     <FlexRowContainer gap="5px" fontSize="10px">
                    //         <div>{note.createdBy}</div>
                    //         <div>{formatToDateAndTimeString(note.createdUtc)}</div>
                    //         {
                    //             !note.createdUtc.equals(note.modifiedUtc) && <div>
                    //                 {`(modified)`}
                    //             </div>
                    //         }
                    //     </FlexRowContainer>
                    //     <FlexRowContainer gap="5px" width="100%">
                    //         <InputTextarea
                                
                    //             onChange={() => {

                    //             }}
                    //             className='notes-editor'
                    //             defaultValue={note.notes}
                    //             onBlur={(e) => updateNote(note, e.target.value)}
                    //         />
                    //         <Button type='button' icon={PrimeIcons.TIMES} style={{ height: "30px" }} onClick={(e) => deleteNote(e, note)} />
                    //     </FlexRowContainer>
                    // </FlexColumnContainer>
                )
                }
            </FlexColumnContainer>

        </FlexColumnContainer>
    </OverlayWaitDisplay>
}   