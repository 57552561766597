import { Form, Formik, FormikHelpers } from "formik";
import { Card } from "primereact/card";
import { TabPanel, TabView } from 'primereact/tabview';
import { useDispatch } from "react-redux";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { loadCustomerProfile } from "../store/featureActions/loadCustomerProfile";
import { saveCompanyProfile } from "../store/featureActions/saveCustomerProfile";
import { getCompanyProfileLoadingState } from "../store/selectors/actionStatus";
import { getCompanyProfile } from "../store/selectors/companyProfile";
import { resetState } from "../store/slice";
import { CompanyProfile } from "../store/types";
import { companyProfileValidationSchema } from "../validations";
import { CompanyInfo } from "./companyInfo";
import { ContactDetails } from "./contactDetails";
import './index.css';
import { LogoSetup } from "./logoSetup";
import { BannerSetup } from "./bannerSetup";

export const CompanyProfileDashboard: React.FC = () => {

    const dispatch = useDispatch();
    const companyProfile: Partial<CompanyProfile> = useAppSelector(getCompanyProfile);
    const loadingState = useAppSelector(getCompanyProfileLoadingState);

    useMountEffect(() => {
        dispatch(loadCustomerProfile());

        return () => {
            dispatch(resetState());
        }
    });

    const onSubmit = (values: Partial<CompanyProfile>, formikHelpers: FormikHelpers<Partial<CompanyProfile>>): void | Promise<any> => {
        dispatch(saveCompanyProfile(values));
        formikHelpers.setSubmitting(false);
    };

    return <OverlayWaitDisplay
        isWaiting={loadingState.isExecuting}
    >
        <div>
            <Card className="main-content-panel" title="Customer Profile">

                <Formik<Partial<CompanyProfile>>
                    initialValues={companyProfile}
                    enableReinitialize={true}
                    validationSchema={companyProfileValidationSchema}
                    onSubmit={onSubmit}
                >
                    <Form autoComplete="off">

                        <TabView
                            renderActiveOnly={false}
                        >
                            <TabPanel header="General" >
                                <CompanyInfo allowStateChange={false} />
                            </TabPanel>

                            <TabPanel header="Contact Details">
                                <ContactDetails />
                            </TabPanel>

                            <TabPanel
                                header="Logo"
                            >
                                <LogoSetup />
                            </TabPanel>

                            <TabPanel
                                header="Banner"
                            >
                                <BannerSetup />
                            </TabPanel>

                        </TabView>

                    </Form>

                </Formik>

            </Card>

        </div>
    </OverlayWaitDisplay>
}