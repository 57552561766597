import { AnyAction, Dispatch } from 'redux';
import { all, call, spawn, StrictEffect } from 'redux-saga/effects';
import { createCorporateEntitySagas } from '../features/admin/corporateEntities/store/sagas';
import { createSyncAdminLicenseSagas } from '../features/admin/licenseManagement/store/sagas';
import { createAgeConfirmationSagas } from '../features/ageConfirmation/dialog/store/sagas';
import { createAlertsSaga } from '../features/alerts/appAlerts/store/sagas';
import { IMarketplaceApiClient } from '../features/apiCommunication/marketplaceApiClient';
import { createAppLevelSagas } from '../features/appLayout/store/sagas';
import { IAuthService } from '../features/authentication/authService';
import { createAuthenticationSagas } from '../features/authentication/store/sagas';
import { createContactVendorSagas } from '../features/dialogs/vendor/store/sagas';
import { createCustomerSagas } from '../features/company/profile/store/sagas';
import { createDiagnosticSagas } from '../features/diagnostics/serverInformation/store/sagas';
import { createVerificationSagas } from '../features/identityVerification/buyerVerification/store/sagas';
import { createAcceptTermsOfServiceSagas } from '../features/initialSetup/acceptTermsOfService/store/sagas';
import { createInitialSetupSagas } from '../features/initialSetup/workflow/store/sagas';
import { createCategoryManagementSagas } from '../features/menu/categoryManagement/store/sagas';
import { createInventoryManagementSagas } from '../features/inventorySetup/inventoryManagement/store/sagas';
import { createProductManagementSagas } from '../features/inventorySetup/productManagement/store/sagas';
import { createMarketplaceProfileSagas } from '../features/marketplaceUserProfile/manageProfile/store/sagas';
import { createMenuAdminSagas } from '../features/menu/editor/store/sagas';
import { createLiveMenuSagas } from '../features/menu/endUser/store/sagas';
import { createProductDetailsSagas } from '../features/menu/productDetails/store/sagas';
import { createTermsOfServiceAdminSagas } from '../features/nodeAdmin/termsOfService/store/sagas';
import { createSearchSagas } from '../features/search/dashboard/store/sagas';
import { RootState } from './rootReducer';
import { createInventoryImageGallerySagas } from '../features/menu/inventoryImageGallery/store/sagas';
import { createMenuAdminDashboardSagas } from '../features/menu/dashboard/store/sagas';
import { createCategoryAssignmentSagas } from '../features/inventorySetup/categoryAssignment/store/sagas';
import { createFavoriteMenusSagas } from '../features/marketplaceUserProfile/favoriteMenus/store/sagas';
import { createBuyerHoldRequestSagas } from '../features/orders/buyerOrders/store/sagas';
import { createPricingDashboardSagas } from '../features/pricing/priceModelDashboard/store/sagas';
import { createPricingEditorSagas } from '../features/pricing/priceModelEditor/store/sagas';
import { createUserTableSettingsSagas } from '../features/userAppSettings/userTableSettings/store/sagas';
import { createCustomerDashboardSagas } from '../features/customer/dashboard/store/sagas';
import { createCustomerEditorSagas } from '../features/customer/customerEditor/store/sagas';
import { createOrderEditorSagas } from '../features/customer/orderEditor/store/sagas';
import { createGalleryTestingSagas } from '../features/diagnostics/galleryTests/store/sagas';
import { createQualityMetricKindSagas } from '../features/qualityGradeTemplates/metricKinds/store/sagas';
import { createQualityGradeKindSagas } from '../features/qualityGradeTemplates/gradeKinds/store/sagas';
import { createQualityTemplateDashboardSagas } from '../features/qualityGradeTemplates/templateDashboard/store/sagas';
import { createQualityTemplateEditorSagas } from '../features/qualityGradeTemplates/templateEditor/store/sagas';
import { createQualityAssessmentSagas } from '../features/qualityAssessments/assessmentEditor/store/sagas';
import { createNodeAdminProvisioningSagas } from '../features/nodeAdmin/provisioning/store/sagas';
import { createCompanyMarketplaceViewSagas } from '../features/company/marketplaceView/store/sagas';


export interface SagaAppContext {
    marketplaceApiClient: IMarketplaceApiClient;        
    authService: IAuthService;
    getState: () => RootState;
    dispatch: Dispatch<AnyAction>;
}

export function createRootSaga(context: SagaAppContext) {

    return function* rootSaga() {

        const restartableSagas: (() => Generator<StrictEffect<any, any>, any, any>)[] = [
            ...createAppLevelSagas(context),
            ...createAuthenticationSagas(context),
            ...createDiagnosticSagas(context),
            ...createMarketplaceProfileSagas(context),
            ...createSyncAdminLicenseSagas(context),            
            ...createAlertsSaga(context),
            ...createVerificationSagas(context),
            ...createCustomerSagas(context),
            ...createCorporateEntitySagas(context),
            ...createProductManagementSagas(context),
            ...createAcceptTermsOfServiceSagas(context),
            ...createTermsOfServiceAdminSagas(context),
            ...createInitialSetupSagas(context),
            ...createCategoryManagementSagas(context),
            ...createInventoryManagementSagas(context),
            ...createMenuAdminSagas(context),
            ...createLiveMenuSagas(context),
            ...createProductDetailsSagas(context),
            ...createSearchSagas(context),
            ...createAgeConfirmationSagas(context),
            ...createContactVendorSagas(context),
            ...createInventoryImageGallerySagas(context),            
            ...createMenuAdminDashboardSagas(context),
            ...createCategoryAssignmentSagas(context),
            ...createFavoriteMenusSagas(context),
            ...createBuyerHoldRequestSagas(context),
            ...createPricingDashboardSagas(context),
            ...createPricingEditorSagas(context),            
            ...createUserTableSettingsSagas(context),
            ...createCustomerDashboardSagas(context),
            ...createCustomerEditorSagas(context),
            ...createOrderEditorSagas(context),
            ...createGalleryTestingSagas(context),
            ...createQualityMetricKindSagas(context),
            ...createQualityGradeKindSagas(context),
            ...createQualityTemplateDashboardSagas(context),
            ...createQualityTemplateEditorSagas(context),
            ...createQualityAssessmentSagas(context),
            ...createNodeAdminProvisioningSagas(context),
            ...createCompanyMarketplaceViewSagas(context)
        ];

        yield all(restartableSagas.map(saga =>
            // for the sagas that should restart on error,
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga)
                        break
                    } catch (e) {
                        console.log(e)
                    }
                }
            }))
        );
    }
}
