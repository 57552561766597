import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { actionFactory, InventoryManagementState } from "../../types";


export const cancelInventoryCategoryAssignment = actionFactory.createAppAction("cancelInventoryCategoryAssignment");

export function cancelInventoryCategoryAssignmentReducers(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forAction(cancelInventoryCategoryAssignment)        
        .addHandler((state, _) => {
            state.categoryAssignmentState.parentModel = undefined;
        })
        .build();
}