import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { addTemplateReducers } from './featureActions/addTemplate';
import { copyTemplateReducer } from './featureActions/copyTemplate';
import { deleteTemplateReducer } from './featureActions/deleteTemplate';
import { loadSliceDataReducer } from './featureActions/loadSliceData';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(loadSliceDataReducer)
            .addRange(addTemplateReducers)
            .add(copyTemplateReducer)
            .add(deleteTemplateReducer);
        // Create your feature actions in ./featureActions and then add them to the reducer factory here
        // with .Add(<your-action-reducer-name>);

    }
});

export default slice.reducer;

export const { resetState } = slice.actions;