import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { actionFactory, ProductManagementState } from "../../types";

export const beginBulkCategoryAssignment = actionFactory.createAppAction("beginBulkCategoryAssignment");

export function beginBulkCategoryAssignmentReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forAction(beginBulkCategoryAssignment)
        .addHandler((state, _) => {
            state.bulkCategoryAssignmentState.products = state.selectedProducts;
        })
        .build();
}