import { isValue } from "../../../../../../utils/valueHelper";
import { BulkAssignmentKind } from "../../../../../commonModels/bulkAssignmentState";
import { actionFactory } from "../../types";
import { loadProducts } from "../loadProducts";

export const {

    completeBulkPricingModelAssignment,
    completeBulkPricingModelAssignmentReducer,
    completeBulkPricingModelAssignmentSagaFactory

} = actionFactory
    .withMappedName("completeBulkPricingModelAssignment")
    .createAsyncAction<void, Response>({
        actionStatusSelector: state => state.bulkPricingModelAssignmentState.savingState,
        asyncCall: (_arg, context) => {
            const bulkAssignmentState = context.getState().productManagement.bulkPricingModelAssignmentState;
            if (bulkAssignmentState.bulkAssignmentKind === BulkAssignmentKind.Assign && !isValue(bulkAssignmentState.pricingModelIdToAssign)) throw new Error("A pricing model must be selected!");

            return context.marketplaceApiClient.productManagement.assignPricingModelToProducts({
                pricingModelId: bulkAssignmentState.pricingModelIdToAssign,
                entityIds: bulkAssignmentState.products.map(product => product.id)
            });
        },
        onFulfilled: (state, _action) => {
            state.bulkPricingModelAssignmentState.products = [];
            state.bulkPricingModelAssignmentState.pricingModelIdToAssign = null;
        },
        successCallback: (_arg, _result, context) => {
            context.dispatch(loadProducts())
        }
    });