import { createAction } from "@reduxjs/toolkit";
import { CreateActionReturnType } from "../../../utils/reduxTypeHelper";
import { isValue } from "../../../utils/valueHelper";
import { SagaAppContext } from "../../rootSaga";
import { IReducerFactory } from "../interfaces";
import { createExecuteWithProgressSaga } from "../sagaWithProgress/executeWithProgress";
import { FulfilledPayload, PendingPayload, RejectedPayload, SagaActions } from "../sagaWithProgress/types";
import { CreateAsyncActionResult, isEntityActionStatusOption, NamedAsyncActionOptions } from "./asyncActionOptions";
import { IMappedNameFactory, MappedNameFactory } from "./mappedNameFactory";

export interface IActionFactory<TSliceState> {
    createActionName: (actionName: string) => string;
    createAppAction: <TArg = void>(actionName: string) => CreateActionReturnType<TArg>;
    createSagaWithProgressActions: <TArg = void, TResult = void>(actionName: string) => SagaActions<TArg, TResult>;
    withMappedName: <TActionName extends string>(actionName: TActionName) => IMappedNameFactory<TSliceState, TActionName>;
    createAsyncAction: <TArg, TApiResult>(options: NamedAsyncActionOptions<TSliceState, TArg, TApiResult>) => CreateAsyncActionResult<TSliceState, TArg, TApiResult>;
}

export class ActionFactory<TSliceState> implements IActionFactory<TSliceState> {

    private sliceName: string;

    constructor(sliceName: string) {
        this.sliceName = sliceName;
    }

    public withMappedName = <TActionName extends string>(actionName: TActionName): IMappedNameFactory<TSliceState, TActionName> => {
        return new MappedNameFactory(actionName, this);
    };

    public createActionName = (actionName: string) => {
        return `${this.sliceName}/${actionName}`;
    };

    public createAppAction = <P = void>(actionName: string) => {
        return createAction<P>(this.createActionName(actionName));
    };

    public createSagaWithProgressActions = <TArg, TResult>(actionName: string): SagaActions<TArg, TResult> => {
        return {
            pending: createAction<PendingPayload<TArg>>(`${actionName}/pending`),
            rejected: createAction<RejectedPayload<TArg>>(`${actionName}/rejected`),
            fulfilled: createAction<FulfilledPayload<TArg, TResult>>(`${actionName}/fulfilled`)
        };
    };

    public createAsyncAction = <TArg, TApiResult>({
        actionName,
        asyncCall,
        actionStatusSelector,
        onFulfilled,
        onPending,
        onRejected,
        debounceInMilliseconds,
        successCallback,
        rejectedCallback,
        predicate
    }: NamedAsyncActionOptions<TSliceState, TArg, TApiResult>): CreateAsyncActionResult<TSliceState, TArg, TApiResult> => {
        const action = this.createAppAction<TArg>(actionName);

        const sagaActions = this.createSagaWithProgressActions<TArg, TApiResult>(action.type);

        const reducerFactory = (reducerFactory: IReducerFactory<TSliceState>): IReducerFactory<TSliceState> => {

            const factory = isEntityActionStatusOption(actionStatusSelector)
                ? reducerFactory
                    .forSagaWithProgress(sagaActions)
                    .withEntityActionStatus(actionStatusSelector)
                : reducerFactory
                    .forSagaWithProgress(sagaActions)
                    .withActionStatus(actionStatusSelector);

            if (isValue(onPending)) {
                factory.onPending(onPending);
            }

            if (isValue(onRejected)) {
                factory.onRejected(onRejected);
            }

            return factory
                .onFulfilled(onFulfilled)
                .build();
        }

        const sagaFactory = (context: SagaAppContext) => {

            return createExecuteWithProgressSaga({
                options: {
                    action: asyncCall,
                    appContext: context,
                    notificationActions: sagaActions,
                    successCallback: successCallback,
                    rejectedCallback: rejectedCallback,
                    predicate: predicate,
                },
                actionPattern: action.type,
                debounceInMilliseconds: debounceInMilliseconds
            });
        };

        return {
            action,
            reducerFactory,
            sagaFactory,
            sagaActions
        }
    }
}
