import { QualityGradeKind } from "../../../models/gradeKind";
import { gradeKindAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    publishQualityGradeKind,
    publishQualityGradeKindReducer,
    publishQualityGradeKindSagaFactory

} = actionFactory
    .withMappedName("publishQualityGradeKind")
    .createAsyncAction<QualityGradeKind, QualityGradeKind>({        
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {            
            return context.marketplaceApiClient.quality.publishQualityGradeKind(arg);            
        },
        onFulfilled: (state, action) => {
            gradeKindAdapter.upsertOne(state.gradeKinds, action.payload.result);
        }
    });