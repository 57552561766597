import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { addTemplateBegin } from "../store/featureActions/addTemplate";

export const CommandBar: React.FC = () => {

    const dispatch = useDispatch();

    const onAddTemplate = () => {
        dispatch(addTemplateBegin());
    }

    return <FlexRowContainer>
        <Button icon={PrimeIcons.PLUS} onClick={onAddTemplate} label="Add Template" />
    </FlexRowContainer>
} 