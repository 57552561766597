import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../../store/rootSaga";
import { SyncRequestDto } from "../../../../../commonModels/syncRequest";
import { actionFactory, ProductManagementState } from "../../types";

export const loadLatestSyncRequest = actionFactory.createAppAction("loadLatestSyncRequest");

const sagaActions = actionFactory.createSagaWithProgressActions<void, SyncRequestDto | null>(loadLatestSyncRequest.type);

export function loadLatestSyncRequestReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.loadingState)
        .onFulfilled((state, action) => {            
            state.latestSyncRequest = action.payload.result;
        })
        .build();
}

async function performLoad(_arg: void, context: SagaAppContext): Promise<SyncRequestDto | null> {      
    return context.marketplaceApiClient.productManagement.getLatestProductSyncRequest();
}

export function createLoadLatestSyncRequestSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: loadLatestSyncRequest.type,
        debounceInMilliseconds: 250
    });
};