import { isValue } from "../../../../../utils/valueHelper";
import { viewSettingsAdapter } from "../state";
import { actionFactory, ViewSettings } from "../types";

export const {

    clearRestoreSentinel,
    clearRestoreSentinelReducer

} = actionFactory
    .withMappedName("clearRestoreSentinel")
    .createAction<{ viewId: string }>((state, action) => {
        const viewId = action.payload.viewId;
        const settings = state.viewSettings.entities[viewId];
        if (!isValue(settings)) return;

        const updatedSettings: ViewSettings = {
            ...settings,
            restoreSentinel: false
        };

        viewSettingsAdapter.upsertOne(state.viewSettings, updatedSettings);
    }); 