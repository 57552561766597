import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { actionFactory, InventoryManagementState } from "../../types";

export const cancelBulkClearCategoryAssignment = actionFactory.createAppAction("cancelBulkClearCategoryAssignment");

export function cancelBulkClearCategoryAssignmentReducers(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forAction(cancelBulkClearCategoryAssignment)
        .addHandler((state, _) => {
            state.bulkClearCategoryAssignmentState.parentModels = [];
        })
        .build();
}