import { createAction } from "@reduxjs/toolkit";
import { IReducerFactory } from "../../../../store/factory/interfaces";
import { actionFactory, AppComponentState } from "../types";

export const hideSidePanel = createAction(actionFactory.createActionName("hideSidePanel"));

export function hideSidePanelReducer(reducerFactory: IReducerFactory<AppComponentState>): IReducerFactory<AppComponentState> {
    return reducerFactory
        .forAction(hideSidePanel)
        .addHandler((state, _) => {
            state.sidePanelVisible = false;
        })
        .build();
}