import { RootState } from "../../../../../store/rootReducer";
import { createLocalImageUrl } from "../../../../../utils/imageHelper";
import { isValue } from "../../../../../utils/valueHelper";

export const getCustomerBanner = (state: RootState) => {
    const bannerImageDto = state.companyProfile.bannerState.currentImage;
    if (!isValue(bannerImageDto)) return null;  
    if (!isValue(bannerImageDto.imageData)) return null;
    return createLocalImageUrl(bannerImageDto);
}

export const getNewBanner = (state: RootState) => {
    return state.companyProfile.bannerState.newImagePreview;
}

export const getLoadBannerState = (state: RootState) => {
    return state.companyProfile.bannerState.loadState;
}

export const getSaveBannerState = (state: RootState) => {
    return state.companyProfile.bannerState.saveState;
}