import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { addQualityMetricKindReducers } from './featureActions/addQualityMetricKind';
import { deleteQualityMetricValueReducer } from './featureActions/deleteQualityMetricValue';
import { loadSliceDataReducer } from './featureActions/loadSliceData';
import { publishQualityMetricKindReducer } from './featureActions/publishQualityMetricKind';
import { resequenceMetricValueReducer } from './featureActions/resequenceMetricValue';
import { selectQualityMetricKindReducer } from './featureActions/selectQualityMetricKind';
import { upsertQualityMetricKindReducer } from './featureActions/upsertQualityMetricKind';
import { upsertQualityMetricValueReducer } from './featureActions/upsertQualityMetricValue';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(loadSliceDataReducer)
            .addRange(addQualityMetricKindReducers)
            .add(upsertQualityMetricKindReducer)
            .add(selectQualityMetricKindReducer)
            .add(upsertQualityMetricValueReducer)
            .add(resequenceMetricValueReducer)
            .add(deleteQualityMetricValueReducer)
            .add(publishQualityMetricKindReducer);
            // Create your feature actions in ./featureActions and then add them to the reducer factory here
            // with .Add(<your-action-reducer-name>);
            
    }
});

export default slice.reducer;

export const { resetState } = slice.actions;