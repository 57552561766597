import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { beginBulkCategoryAssignmentReducers } from './featureActions/bulkCategoryAssignment/beginBulkCategoryAssignment';
import { beginCategoryAssignmentReducers } from './featureActions/categoryAssignment/beginCategoryAssignment';
import { beginEditProductImagesReducers } from './featureActions/productImages/beginEditProductImages';
import { cancelBulkCategoryAssignmentReducers } from './featureActions/bulkCategoryAssignment/cancelBulkCategoryAssignment';
import { cancelCategoryAssignmentReducers } from './featureActions/categoryAssignment/cancelCategoryAssignment';
import { completeBulkCategoryAssignmentReducers } from './featureActions/bulkCategoryAssignment/completeBulkCategoryAssignment';
import { completeCategoryAssignmentReducers } from './featureActions/categoryAssignment/completeCategoryAssignment';
import { completeEditProductImagesReducers } from './featureActions/productImages/completeEditProductImages';
import { deleteProductImageReducers } from './featureActions/productImages/deleteProductImage';
import { loadLatestSyncRequestReducers } from './featureActions/productSync/loadLatestSyncRequest';
import { loadProductMediaReducer } from './featureActions/productImages/loadProductMedia';
import { loadProductsReducer } from './featureActions/loadProducts';
import { requestProductSyncReducers } from './featureActions/productSync/requestProductSync';
import { setSelectedProductsReducers } from './featureActions/setSelectedProducts';
import { updateImageDetailsReducers } from './featureActions/productImages/updateImageDetails';
import { uploadNewProductImageReducers } from './featureActions/productImages/uploadNewProductImage';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';
import { beginBulkSyncKindAssignmentReducers } from './featureActions/bulkSyncKindAssignment/beginBulkSyncKindAssignment';
import { updateInventoryAssignmentMapsReducers } from './featureActions/updateInventoryAssignmentMaps';
import { loadMenuAssignmentsReducer } from './featureActions/loadMenuAssignments';
import { beginBulkClearCategoryAssignmentReducers } from './featureActions/bulkClearCategoryAssignment/beginBulkClearCategoryAssignment';
import { cancelBulkClearCategoryAssignmentReducers } from './featureActions/bulkClearCategoryAssignment/cancelBulkClearCategoryAssignment';
import { completeBulkClearCategoryAssignmentReducers } from './featureActions/bulkClearCategoryAssignment/completeBulkClearCategoryAssignment';
import { loadPriceModelsReducer } from './featureActions/priceModels/loadPriceModels';
import { cancelBulkPricingModelAssignmentReducer } from './featureActions/priceModels/cancelBulkPricingModelAssignment';
import { beginBulkPricingModelAssignmentReducers } from './featureActions/priceModels/beginBulkPricingModelAssignment';
import { completeBulkPricingModelAssignmentReducer } from './featureActions/priceModels/completeBulkPricingModelAssignment';
import { setPricingModelToAssignReducer } from './featureActions/priceModels/setPricingModelToAssign';
import { addVideoReducers } from './featureActions/productImages/addVideo';
import { upsertProductVideoReducer } from './featureActions/productImages/upsertProductVideo';
import { deleteProductVideoReducer } from './featureActions/productImages/deleteProductVideo';
import { editProductReducers } from './featureActions/productDetails/editProduct';
import { loadProductNotesReducer } from './featureActions/notes/loadProductNotes';
import { upsertProductNoteReducer } from './featureActions/notes/upsertProductNote';
import { deleteProductNoteReducer } from './featureActions/notes/deleteProductNote';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(loadProductsReducer)
            .add(requestProductSyncReducers)            
            .add(setSelectedProductsReducers)
            .addRange(editProductReducers)            
            .add(beginEditProductImagesReducers)
            .add(uploadNewProductImageReducers)
            .add(loadProductMediaReducer)
            .add(updateImageDetailsReducers)
            .add(completeEditProductImagesReducers)
            .add(deleteProductImageReducers)            
            .add(loadLatestSyncRequestReducers)
            .add(beginBulkCategoryAssignmentReducers)
            .add(cancelBulkCategoryAssignmentReducers)
            .add(completeBulkCategoryAssignmentReducers)            
            .add(beginCategoryAssignmentReducers)
            .add(cancelCategoryAssignmentReducers)
            .add(completeCategoryAssignmentReducers)
            .add(beginBulkSyncKindAssignmentReducers)
            .add(loadMenuAssignmentsReducer)
            .add(updateInventoryAssignmentMapsReducers)
            .add(beginBulkClearCategoryAssignmentReducers)
            .add(cancelBulkClearCategoryAssignmentReducers)
            .add(completeBulkClearCategoryAssignmentReducers)
            .add(loadPriceModelsReducer)            
            .add(beginBulkPricingModelAssignmentReducers)
            .add(cancelBulkPricingModelAssignmentReducer)
            .add(completeBulkPricingModelAssignmentReducer)
            .add(setPricingModelToAssignReducer)
            .addRange(addVideoReducers)
            .add(upsertProductVideoReducer)
            .add(deleteProductVideoReducer)
            .add(loadProductNotesReducer)
            .add(upsertProductNoteReducer)
            .add(deleteProductNoteReducer);
            // Create your feature actions in ./featureActions and then add them to the reducer factory here
            // with .Add(<your-action-reducer-name>);
    }
});

export default slice.reducer;

export const { resetState } = slice.actions;