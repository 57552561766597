import { isValue } from "../../../../../utils/valueHelper";
import { OrderStatus, SalesOrder } from "../../../common/types";
import { actionFactory } from "../types";

export const {

    updateSalesOrder,
    updateSalesOrderReducer,
    updateSalesOrderSagaFactory

} = actionFactory
    .withMappedName("updateSalesOrder")
    .createAsyncAction<{ status: OrderStatus}, SalesOrder>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {       
            const orderId = context.getState().orderEditor.salesOrder?.id;     
            if (!isValue(orderId)) throw new Error("Cannot update order without a valid order instance");
            return context.marketplaceApiClient.customer.updateOrder({ id: orderId, status: arg.status });
        },
        onFulfilled: (state, action) => {
            state.salesOrder = action.payload.result;
        }
    });