import { useEffect, useRef } from "react";
import { useAppSelector } from "../../../../store/configureStore";
import { getAccessToken } from "../../../authentication/store/selectors/accessToken";
import { useMarketplaceHub } from "../hubContext";
import { HubMethodNames, SyncRequestComplete } from "../store/types";
import { Toast } from 'primereact/toast';
import { getIsAuthenticated } from "../../../authentication/store/selectors/isAuthenticated";

const rootUrl = process.env.REACT_APP_MARKETPLACE_API_URL;
 
export const SignalRHubConnection: React.FC = () => {

    const accessToken = useAppSelector(getAccessToken);
    const isAuthenticated = useAppSelector(getIsAuthenticated);
    const marketplaceHub = useMarketplaceHub();
    const toast = useRef<Toast>(null);
    
    const onSyncComplete = (message: SyncRequestComplete) => {
        if (toast.current !== null) {
            toast.current.show({ 
                closable: true,
                severity: message.success ? "success" : "error",
                summary: message.success ? "Request Complete" : "Request Failed",
                detail: message.userMessage,
                life: 5000
            });
        }
    }

    useEffect(() => {
        
        if (isAuthenticated && accessToken !== null && rootUrl !== undefined) {

            marketplaceHub.connect(`${rootUrl}marketplace-notification-hub`, accessToken);

            marketplaceHub.registerCallback(HubMethodNames.SyncComplete, onSyncComplete);
        }
        else if (!isAuthenticated) {
            marketplaceHub.disconnect();
        }

        return () => {
            marketplaceHub.disconnect();
        };

    }, [accessToken, marketplaceHub, isAuthenticated]);

    return <div id="signalR-hub-connection">
        <Toast ref={toast} />
    </div>;
}  