import { DateTime } from "luxon";
import { SyncRequestDto } from "../../../../commonModels/syncRequest";
import { actionFactory, SyncInventoryRequest } from "../types";

export const {

    syncInventoryBegin,
    syncInventoryCancel,
    syncInventoryComplete,
    syncInventoryReducers,
    syncInventorySagaFactory

} = actionFactory
    .withMappedName("syncInventory")
    .createModal<SyncInventoryRequest, void, SyncRequestDto>({
        selector: state => state.syncInventoryRequestState,
        modelFactory: (_arg, _state) => ({
            changesSince: DateTime.utc()
        }),
        onCompleteEdit: (value, context) => {
            return context.marketplaceApiClient.inventoryManagement.requestInventorySync(value);
        },
        onFulfilled: (_state, _action) => {            
        },
        successCallback: (_arg, _result, _context) => { }
    }); 