import { Duration } from "luxon";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { getUserIsNodeAdmin } from "../../../authentication/store/selectors/userRoles";
import { setOptionsDialogVisibility } from "../store/featureActions/setOptionsDialogVisibility";
import { setRefreshInterval } from "../store/featureActions/setRefreshInterval";
import { getOptionsDialogVisible, getRefreshInterval } from "../store/selectors/optionsDialog";

export const MenuOptionsDialog: React.FC = () => {

    const optionsDialogVisible = useAppSelector(getOptionsDialogVisible);
    const dispatch = useDispatch();
    const refreshInterval = useAppSelector(getRefreshInterval);
    const isNodeAdmin = useAppSelector(getUserIsNodeAdmin);

    const refreshOptions = [
        { label: "No Refresh", value: 0 },
        { label: "10 Minutes", value: Duration.fromObject({ minutes: 10 }).toMillis() },
        { label: "15 Minutes", value: Duration.fromObject({ minutes: 15 }).toMillis() },
        { label: "20 Minutes", value: Duration.fromObject({ minutes: 20 }).toMillis() },
        { label: "25 Minutes", value: Duration.fromObject({ minutes: 20 }).toMillis() },
        { label: "30 Minutes", value: Duration.fromObject({ minutes: 20 }).toMillis() }
    ];

    if (isNodeAdmin) {
        refreshOptions.push({ label: "10 Seconds", value: Duration.fromObject({ seconds: 10 }).toMillis() });
    }


    const onDialogHide = () => {
        dispatch(setOptionsDialogVisibility(false));
    };

    const onRefreshIntervalChanged = (e: DropdownChangeParams) => {
        dispatch(setRefreshInterval(e.value));
    }

    const footer = <FlexRowContainer centerContent justifyContent="end">
        <Button
            label="Ok"
            style={{ width: "80px" }}
            onClick={onDialogHide}
        />
    </FlexRowContainer>;

    const header = <FlexRowContainer centerContent justifyContent="start">
        <div>Menu Options</div>
    </FlexRowContainer>;

    return <Dialog
        onHide={onDialogHide}
        visible={optionsDialogVisible}
        closable={false}
        position="center"
        footer={footer}
        header={header}
        contentStyle={{ padding: "25px" }}
    >

        <FlexColumnContainer>

            <FlexRowContainer centerContent gap="20px">
                <div>Refresh Interval</div>
                <Dropdown
                    onChange={onRefreshIntervalChanged}
                    options={refreshOptions}
                    value={refreshInterval}
                    style={{ width: "200px" }}
                />
            </FlexRowContainer>

        </FlexColumnContainer>

    </Dialog>
}