import ApiClient from "../../apiCommunication/apiClient";
import { ImageDto, ImageKind } from "../../commonModels/imageTypes";
import { InventoryMenuAssignment } from "../../inventorySetup/general/models/menuAssignment";
import { MenuCategoryView } from "../../inventorySetup/general/models/menuCategoryView";
import { Category, NewCategory } from "../categoryManagement/store/types";
import { Menu, MenuSection, NewMenuRequest } from "../editor/store/types";

export default class MenuAdmin {

    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    getMenu = (menuId: number): Promise<Menu> => {
        return this
            .apiClient
            .executeGet<Menu>(`menuadmin/${menuId}`);
    };

    deleteMenu = (menuId: number): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`menuadmin/${menuId}`);
    };

    getMenus = (): Promise<Menu[]> => {
        return this
            .apiClient
            .executeGet<Menu[]>(`menuadmin`);
    };

    upsertMenu = (menu: Menu | NewMenuRequest): Promise<Menu> => {
        return this
            .apiClient
            .executePostWithResult<Menu>(`menuadmin`, menu);
    };

    getMenuSections = (menuId: number): Promise<MenuSection[]> => {
        return this
            .apiClient
            .executeGet<MenuSection[]>(`menuadmin/${menuId}/sections`);
    };

    upsertMenuSection = (menuSection: MenuSection): Promise<MenuSection> => {
        return this
            .apiClient
            .executePostWithResult<MenuSection>(`menuadmin/sections`, menuSection);
    };

    deleteMenuSection = (menuSection: MenuSection): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`menuadmin/sections/${menuSection.id}`);
    };

    resequenceMenuSection = (menuSection: MenuSection): Promise<MenuSection[]> => {
        return this
            .apiClient
            .executePostWithResult<MenuSection[]>(`menuadmin/section/resequence`, menuSection);
    };

    setCategoryIdsForMenuSection = (menuSectionId: number, categoryIds: number[]): Promise<Response> => {
        return this
            .apiClient
            .executePost(`menuadmin/section/${menuSectionId}/categoryIds`, categoryIds);
    };

    getCategories = (menuId: number): Promise<Category[]> => {
        return this
            .apiClient
            .executeGet<Category[]>(`menuadmin/${menuId}/categories`);
    };

    getMenuCategoryViews = (): Promise<MenuCategoryView[]> => {
        return this
            .apiClient
            .executeGet<MenuCategoryView[]>(`menuadmin/menuviews`);
    };

    getProductIdsForCategory = (categoryId: number): Promise<number[]> => {
        return this
            .apiClient
            .executeGet<number[]>(`category/${categoryId}/productIds`);
    };

    setProductIdsForCategory = (categoryId: number, productIds: number[]): Promise<Response> => {
        return this
            .apiClient
            .executePost(`category/${categoryId}/productIds`, productIds);
    };

    upsertCategory = (menuId: number, category: Category | NewCategory): Promise<Category> => {
        return this
            .apiClient
            .executePostWithResult<Category>(`menuadmin/${menuId}/categories`, category);
    };

    deleteCategory = (categoryId: number): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`menuadmin/categories/${categoryId}`);
    };    

    getMenuInventoryAssignments = (): Promise<InventoryMenuAssignment[]> => {
        return this
            .apiClient
            .executeGet<InventoryMenuAssignment[]>(`menuadmin/assignments/inventory`);                  
    };

    getMenuProductAssignments = (): Promise<InventoryMenuAssignment[]> => {
        return this
            .apiClient
            .executeGet<InventoryMenuAssignment[]>(`menuadmin/assignments/product`);                  
    };
    
    getImage = (menuId: number, kind: ImageKind): Promise<ImageDto> => {        
        return this
            .apiClient
            .executeGet(`menuadmin/${menuId}/${kind}`);
    };

    deleteImage = (menuId: number, kind: ImageKind): Promise<Response> => {        
        return this
            .apiClient
            .executeDelete(`menuadmin/${menuId}/${kind}`);
    };

    uploadImage = (menuId: number, file: Blob | string | File, kind: ImageKind): Promise<Response> => {
        const formData = new FormData();
        formData.append("file", file);        
        return this
            .apiClient
            .executeFormDataPost(`menuadmin/${menuId}/${kind}`, formData);
    };
}