import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore"
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { getLoadingState, getServerHealth } from "../store/selectors/serverVersion";
import { resetState } from "../store/slice";
import { Card } from 'primereact/card';
import { fullDateDisplay } from "../../../../utils/datetimeHelper";
import { DateTime } from 'luxon';
import React, { ReactNode } from "react";
import './index.css';
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { Button } from "primereact/button";
import { showMessageInDialog } from "../../../dialogs/message/store/featureActions/showMessageInDialog";
import { enqueueError } from "../../../dialogs/error/store/featureActions/enqueueErrorAction";
import { forceDialogErrorInSaga } from "../store/featureActions/forceDialogErrorInSaga";
import { ErrorDisplayType } from "../../../../store/factory/sagaWithProgress/types";
import { forceToastErrorInSaga } from "../store/featureActions/forceToastErrorInSaga";
import { isValue } from "../../../../utils/valueHelper";

export const DiagnosticDashboard: React.FC = () => {

    const serverHealth = useAppSelector(getServerHealth);
    const loadingState = useAppSelector(getLoadingState);

    const dispatch = useDispatch();

    useMountEffect(() => {
        dispatch(loadSliceData());
        return () => {
            dispatch(resetState());
        }
    });

    const clientDateTime: DateTime = DateTime.now();

    const serverHealthGood: ReactNode = <i className="pi pi-check diagnostic"></i>;
    const serverHealthBad: ReactNode = <i className="pi pi-exclamation-circle diagnostic"></i>;

    const showTestDialog = () => {
        dispatch(showMessageInDialog({
            message: "This is a test message",
            title: "Diagnostic Test",
            actionTitle: "Dismiss"
        }));
    }

    const queueError = () => {
        dispatch(enqueueError({
            error: "This is a test error message",
            title: "Diagnostic Test",
            errorDisplayType: ErrorDisplayType.Dialog
        }));
    }


    const forceAsyncFailureWithDialogDisplay = () => {
        dispatch(forceDialogErrorInSaga());
    }

    const forceAsyncFailureWithToastDisplay = () => {
        dispatch(forceToastErrorInSaga());
    }

    return <OverlayWaitDisplay
        isWaiting={loadingState.isExecuting}
        overlayOpacity={1}
    >
        <div className="p-p-2">
            <div className="p-grid">
                <div className="p-col-4">
                    <Card title="Server Version" >
                        <div className="inline-result">
                            {serverHealth?.version}
                        </div>
                        <div className="inline-result-icon">
                            {!!serverHealth?.version ? serverHealthGood : serverHealthBad}
                        </div>
                    </Card>
                </div>
                <div className="p-col-4">
                    <Card title="Server Time" >
                        <div className="inline-result">
                            {serverHealth?.currentServerTime?.toLocaleString(fullDateDisplay)}
                        </div>
                        <div className="inline-result-icon">
                            {!!serverHealth?.currentServerTime ? serverHealthGood : serverHealthBad}
                        </div>
                    </Card>
                </div>
                <div className="p-col-4">
                    <Card title="Client Time" >
                        <div className="inline-result">
                            {clientDateTime.toLocaleString(fullDateDisplay)}
                        </div>
                        <div className="inline-result-icon">
                            {serverHealthGood}
                        </div>
                    </Card>
                </div>
            </div>
            <div className="p-grid">
                <div className="p-col-4">
                    <Card title="Database Connectivity" >
                        <div className="inline-result">
                            {serverHealth?.databaseConnectivity ? "Success" : "Failure"}
                        </div>
                        <div className="inline-result-icon">
                            {!!serverHealth?.databaseConnectivity ? serverHealthGood : serverHealthBad}
                        </div>
                    </Card>
                </div>
                <div className="p-col-4">
                    <Card title="Customer Count" >
                        <div className="inline-result">
                            {serverHealth?.companyCount.toLocaleString()}
                        </div>
                        <div className="inline-result-icon">
                            {isValue(serverHealth?.companyCount) ? serverHealthGood : serverHealthBad}
                        </div>
                    </Card>
                </div>
            </div>
            <div className="p-grid">
                <div className="p-col-4">
                    <Card title="Message Dialog Testing" >
                        <Button label="Create Test Dialog" onClick={showTestDialog} />
                    </Card>
                </div>
                <div className="p-col-4">
                    <Card title="Error Queue Testing" >
                        <Button label="Queue Error Message" onClick={queueError} />
                    </Card>
                </div>
                <div className="p-col-4">
                    <Card title="Async Error Testing" >
                        <Button className="p-ml-4" label="Async Dialog Failure" onClick={forceAsyncFailureWithDialogDisplay} />
                        <Button className="p-ml-4" label="Async Toast Failure" onClick={forceAsyncFailureWithToastDisplay} />
                    </Card>
                </div>
            </div>
        </div>

    </OverlayWaitDisplay>

}