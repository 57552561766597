import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { Button } from "primereact/button";
import { Dialog, DialogPositionType } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Message } from "primereact/message";
import { CSSProperties } from "react";
import { ActionStatus } from "../../features/actionStatus/actionStatus";
import { messageFromError } from "../../features/errorHandling/errorMessageHelper";
import { useAppSelector } from "../../store/configureStore";
import { RootState } from "../../store/rootReducer";
import { isValue } from "../../utils/valueHelper";
import { FlexColumnContainer } from "../containers/flexColumnContainer";
import { FlexRowContainer } from "../containers/flexRowContainer";

export interface DialogButtonProps {
    buttonStyle?: CSSProperties;
    buttonClassname?: string;

}

export interface FormikModalEditorProps<T extends FormikValues> {
    title?: string;
    valueSelector: (state: RootState) => T | null;
    visibleSelector: (state: RootState) => boolean;
    statusSelector: (state: RootState) => ActionStatus;
    validationSchema?: any | (() => any);
    onSave: (value: T) => void;
    onCancel: () => void;
    style?: CSSProperties;
    okButtonStyle?: CSSProperties;
    okButtonClassname?: string;
    okButtonLabel?: string;
    cancelButtonStyle?: CSSProperties;
    cancelButtonClassname?: string;
    cancelButtonLabel?: string;
    position?: DialogPositionType;
    maskClassName?: string;
    headerClassName?: string;
    contentClassName?: string;
    className?: string;
    closable?: boolean;
}

export const FormikModalEditor = <T extends FormikValues,>({
    title,
    valueSelector,
    visibleSelector,
    statusSelector,
    validationSchema,
    onSave,
    onCancel,
    style,
    children,
    okButtonStyle,
    okButtonClassname,
    okButtonLabel,
    cancelButtonStyle,
    cancelButtonClassname,
    cancelButtonLabel,
    position,
    maskClassName,
    headerClassName,
    contentClassName,
    className,
    closable=false
}: React.PropsWithChildren<FormikModalEditorProps<T>>) => {

    const value = useAppSelector(valueSelector);
    const visible = useAppSelector(visibleSelector);
    const status = useAppSelector(statusSelector);

    const onSubmit = (value: T, formikHelpers: FormikHelpers<T>): void | Promise<any> => {
        onSave(value);
        formikHelpers.setSubmitting(false);
    };

    const onHide = () => { 
        if (closable){
            onCancel();
        }
    }
 
    return <Dialog
        header={title}
        position={position}
        visible={visible}
        onHide={onHide}
        closable={closable}
        closeOnEscape={false}
        dismissableMask={false}
        style={style}
        maskClassName={maskClassName}
        headerClassName={headerClassName}
        contentClassName={contentClassName}
        className={className}
    >
        {
            isValue(value) &&
            <Formik<T>
                initialValues={value}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {
                    (_formikProps) => <Form autoComplete="off">

                        {children} 

                        {
                            isValue(status.errorContent) && <FlexColumnContainer marginTop="10px">
                                <Message severity="error" text={messageFromError(status.errorContent)}></Message>
                            </FlexColumnContainer>
                        }

                        {/* <div>{JSON.stringify(_formikProps.errors)}</div> */}

                        <Divider />

                        <FlexRowContainer
                            gap="10px"
                            justifyContent="end"
                            centerContent
                        >
                            <Button
                                type="button"
                                className={cancelButtonClassname}
                                label={ cancelButtonLabel ?? "Cancel"}
                                style={cancelButtonStyle ?? { width: "100px" }}
                                onClick={onCancel}
                                disabled={status.isExecuting}
                            />
                            <Button
                                type="submit"
                                className={okButtonClassname}
                                label={okButtonLabel ?? "Save"}
                                style={okButtonStyle ?? { width: "100px" }}
                                loading={status.isExecuting}
                            />
                        </FlexRowContainer>


                    </Form>
                }

            </Formik>
        }

    </Dialog>
}