import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { CustomerDashboardState } from './types';

export const createInitialState = (): CustomerDashboardState => {
    return {        
        loadingState: CreateActionStatusInitialState(),      
        data: [],
    };
};

export const initialState: CustomerDashboardState = createInitialState();