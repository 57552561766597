import clsx from "clsx";
import { debounce } from "lodash";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { DataViewLayoutType } from "primereact/dataview";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { PopUpMenuButton } from "../../../../components/popMenuButton";
import { useAppSelector } from "../../../../store/configureStore";
import { TypedMenuItem } from "../../../../utils/table/typedMenuItem";
import { isValue } from "../../../../utils/valueHelper";
import { getIsAuthenticated } from "../../../authentication/store/selectors/isAuthenticated";
import { getUserIsBuyer, getUserIsSeller } from "../../../authentication/store/selectors/userRoles";
import { showVendorContactDetails } from "../../../dialogs/vendor/store/featureActions/loadVendor";
import { printLiveMenuRoute } from "../../../navigation/routes";
import { smallDeviceQuery } from "../../../responsiveconfiguration";
import { addToFavorites } from "../store/featureActions/addToFavorites";
import { removeFromFavorites } from "../store/featureActions/removeFromFavorites";
import { setOptionsDialogVisibility } from "../store/featureActions/setOptionsDialogVisibility";
import { setSearchText } from "../store/featureActions/setSearchText";
import { setSectionLayout } from "../store/featureActions/setSectionLayout";
import { getSearchText } from "../store/selectors/search";
import { getMenu, getMenuIsFavorite, getSectionLayout, getVendorRouteName } from "../store/selectors/vendorMenu";
import './searchBar.css';
import { SortPopOver } from "./sortPopover";

export interface MenuSearchBarProps {
    vendorName: string | undefined,
    menuName: string | undefined
}
export const MenuSearchBar: React.FC<MenuSearchBarProps> = ({ vendorName, menuName }) => {

    const dispatch = useDispatch();
    const searchText = useAppSelector(getSearchText);
    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    const menu = useAppSelector(getMenu);
    const menuIsFavorite = useAppSelector(getMenuIsFavorite);
    const isLoggedIn = useAppSelector(getIsAuthenticated);
    const isBuyer = useAppSelector(getUserIsBuyer);
    const isSeller = useAppSelector(getUserIsSeller);
    const sectionLayout = useAppSelector(getSectionLayout);
    const vendorRouteName = useAppSelector(getVendorRouteName);

    const onPrint = () => {
        if (!isValue(vendorName) || !isValue(menuName)) return;
        window.open(printLiveMenuRoute.createFullUrl(vendorName, menuName));
    }

    const dispatchSearchText = debounce((searchText: string) => {
        dispatch(setSearchText(searchText));
    }, 200);

    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatchSearchText(e.currentTarget.value);
    }

    const onToggleFavorite = () => {
        if (!isValue(menu)) return;
        if (!isLoggedIn) return;

        if (menuIsFavorite) {
            dispatch(removeFromFavorites({ menuId: menu.id }));
        }
        else {
            dispatch(addToFavorites({ menuId: menu.id }));
        }
    }

    const onViewOptionsDialog = () => {
        dispatch(setOptionsDialogVisibility(true));
    }

    const onSetLayout = (value: DataViewLayoutType) => {
        dispatch(setSectionLayout(value));
    }

    const onViewVendorContactDetails = () => {
        dispatch(showVendorContactDetails(vendorRouteName));
    }

    const viewSelectionMenuItems: TypedMenuItem<string>[] = [
        { label: "Card Layout", command: () => onSetLayout("grid") },
        { label: "Table Layout", command: () => onSetLayout("list") }
    ];

    return <div className="search-bar-and-favorites-container">

        <FlexRowContainer centerContent justifyContent="center" width="100vw" gap="5px">

            {
                isBuyer && isLoggedIn &&
                <Button
                    className={clsx("p-button-rounded p-button-sm", {
                        "p-button-outlined": !menuIsFavorite
                    })}
                    icon={PrimeIcons.HEART}
                    tooltip={menuIsFavorite ? "Remove From Favorites" : "Add To Favorites"}
                    onClick={onToggleFavorite}
                    style={{ justifySelf: "start", flexShrink: 0 }}
                />

            }

            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    className={clsx({
                        "menu-search-bar-input-small": isSmallDevice,
                        "menu-search-bar-input-large": !isSmallDevice
                    })}
                    onChange={onSearchInputChange}
                    defaultValue={searchText}
                    placeholder="Search"
                />
            </span>
            <SortPopOver />
            {!isSmallDevice && isSeller && <Button
                icon={PrimeIcons.COG}
                style={{
                    background: 'white',
                    color: 'black',
                    fontSize: "x-large"
                }}
                onClick={onViewOptionsDialog}
            />}
            {!isSmallDevice && isSeller &&
                <Button
                    icon={PrimeIcons.PRINT}
                    style={{
                        background: 'white',
                        color: 'black',
                        fontSize: "x-large"
                    }}
                    onClick={onPrint}
                />
            }

            {
                !isSmallDevice &&
                <FlexRowContainer flexShrink={0}>

                    <Button
                        icon={PrimeIcons.ENVELOPE}
                        onClick={() => onViewVendorContactDetails()}
                        style={{
                            fontSize: isSmallDevice ? "large" : "x-large",
                            flexShrink: 0,
                            background: 'white',
                            color: 'black',
                            marginRight: "4px"
                        }}
                    />


                    <Button
                        icon={PrimeIcons.TABLE}
                        className={clsx("menu-layout-button", {
                            "menu-layout-button-selected": sectionLayout === "table"
                        })}
                        onClick={() => onSetLayout("table")}
                        style={{ fontSize: isSmallDevice ? "large" : "x-large", flexShrink: 0 }}
                    />
                    <Button
                        icon={PrimeIcons.BARS}
                        className={clsx("menu-layout-button", {
                            "menu-layout-button-selected": sectionLayout === "list"
                        })}
                        onClick={() => onSetLayout("list")}
                        style={{ fontSize: isSmallDevice ? "large" : "x-large", flexShrink: 0 }}
                    />
                    <Button
                        icon={PrimeIcons.TH_LARGE}
                        className={clsx("menu-layout-button", {
                            "menu-layout-button-selected": sectionLayout === "grid"
                        })}
                        onClick={() => onSetLayout("grid")}
                        style={{ fontSize: isSmallDevice ? "large" : "x-large", flexShrink: 0 }}
                    />
                </FlexRowContainer>
            }

            {
                isSmallDevice &&
                <FlexRowContainer gap="4px">

                    <Button
                        icon={PrimeIcons.ENVELOPE}
                        onClick={() => onViewVendorContactDetails()}
                        style={{
                            fontSize: isSmallDevice ? "large" : "x-large",
                            flexShrink: 0,
                            background: 'white',
                            color: 'black'
                        }}
                    />

                    <PopUpMenuButton
                        id="sort-popup-menu"
                        menuItems={viewSelectionMenuItems}
                        value={''}
                        menuStyle={{ width: "250px" }}
                        buttonStyle={{ fontSize: isSmallDevice ? "large" : "x-large", flexShrink: 0 }}
                        icon={PrimeIcons.TH_LARGE}
                    />

                </FlexRowContainer>
                // <FlexRowContainer flexShrink={0}>


                //     <Button
                //         icon={PrimeIcons.BARS}
                //         className={clsx("menu-layout-button", {
                //             "menu-layout-button-selected": sectionLayout === "list"
                //         })}
                //         onClick={() => onSetLayout("list")}
                //         style={{ fontSize: isSmallDevice ? "large" : "x-large", flexShrink: 0 }}
                //     />
                //     <Button
                //         icon={PrimeIcons.TH_LARGE}
                //         className={clsx("menu-layout-button", {
                //             "menu-layout-button-selected": sectionLayout === "grid"
                //         })}
                //         onClick={() => onSetLayout("grid")}
                //         style={{ fontSize: isSmallDevice ? "large" : "x-large", flexShrink: 0 }}
                //     />
                // </FlexRowContainer>
            }



        </FlexRowContainer>
    </div>
}