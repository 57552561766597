import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { beginBulkCategoryAssignmentReducers } from './featureActions/bulkCategoryAssignment/beginBulkCategoryAssignment';
import { cancelBulkCategoryAssignmentReducers } from './featureActions/bulkCategoryAssignment/cancelBulkCategoryAssignment';
import { completeBulkCategoryAssignmentReducers } from './featureActions/bulkCategoryAssignment/completeBulkCategoryAssignment';
import { beginBulkClearCategoryAssignmentReducers } from './featureActions/bulkClearCategoryAssignment/beginBulkClearCategoryAssignment';
import { cancelBulkClearCategoryAssignmentReducers } from './featureActions/bulkClearCategoryAssignment/cancelBulkClearCategoryAssignment';
import { completeBulkClearCategoryAssignmentReducers } from './featureActions/bulkClearCategoryAssignment/completeBulkClearCategoryAssignment';
import { beginBulkExcludeReducers } from './featureActions/bulkExcludeFromMarketplace/beginBulkExclude';
import { cancelBulkExcludeReducers } from './featureActions/bulkExcludeFromMarketplace/cancelBulkExclude';
import { completeBulkExcludeReducers } from './featureActions/bulkExcludeFromMarketplace/completeBulkExclude';
import { bulkSetReadyDateReducers } from './featureActions/bulkSetReadyDate';
import { beginInventoryCategoryAssignmentReducers } from './featureActions/categoryAssignment/beginInventoryCategoryAssignment';
import { cancelInventoryCategoryAssignmentReducers } from './featureActions/categoryAssignment/cancelInventoryCategoryAssignment';
import { completeCategoryAssignmentReducers } from './featureActions/categoryAssignment/completeInventoryCategoryAssignment';
import { editInventoryReducers } from './featureActions/InventoryDetails/editInventory';
import { addInventoryReducers } from './featureActions/InventoryDetails/addInventory';
import { addVideoReducers } from './featureActions/inventoryImages/addVideo';
import { beginEditInventoryImagesReducers } from './featureActions/inventoryImages/beginEditInventoryImages';
import { completeEditInventoryImagesReducers } from './featureActions/inventoryImages/completeEditInventoryImages';
import { deleteInventoryImageReducers } from './featureActions/inventoryImages/deleteInventoryImage';
import { deleteInventoryVideoReducer } from './featureActions/inventoryImages/deleteInventoryVideo';
import { loadInventoryMediaReducer } from './featureActions/inventoryImages/loadInventoryMedia';
import { updateImageDetailsReducers } from './featureActions/inventoryImages/updateImageDetails';
import { uploadNewInventoryImageReducers } from './featureActions/inventoryImages/uploadNewInventoryImage';
import { upsertInventoryVideoReducer } from './featureActions/inventoryImages/upsertInventoryVideo';
import { loadInventoryReducer } from './featureActions/loadInventory';
import { loadInventoryByIdsReducer } from './featureActions/loadInventoryByIds';
import { loadMenuAssignmentsReducer } from './featureActions/loadMenuAssignments';
import { loadSliceDataReducer } from './featureActions/loadSliceData';
import { deleteInventoryNoteReducer } from './featureActions/notes/deleteInventoryNote';
import { deleteInventoryReducer } from './featureActions/InventoryDetails/deleteInventory';
import { loadInventoryNotesReducer } from './featureActions/notes/loadInventoryNotes';
import { loadInventoryLicensesReducer } from './featureActions/licenses/loadInventoryLicenses';
import { loadUnitOfMeasuresReducer } from './featureActions/unitOfMeasures/loadUnitOfMeasures';
import { upsertInventoryNoteReducer } from './featureActions/notes/upsertInventoryNote';
import { beginBulkQualityGradeAssignmentReducers } from './featureActions/qualityGrades/beginBulkQualityGradeAssignment';
import { cancelBulkQualityGradeAssignmentReducer } from './featureActions/qualityGrades/cancelBulkQualityGradeAssignment';
import { completeBulkQualityGradeAssignmentReducer } from './featureActions/qualityGrades/completeBulkQualityGradeAssignment';
import { createQualityAssessmentReducers } from './featureActions/qualityGrades/createQualityAssessment';
import { removeQualityAssessmentReducers } from './featureActions/qualityGrades/removeQualityAssessment';
import { setQualityGradeToAssignReducer } from './featureActions/qualityGrades/setQualityGradeToAssign';
import { setSelectedInventoryReducers } from './featureActions/setSelectedInventory';
import { loadLatestSyncRequestReducers } from './featureActions/sync/loadLatestSyncRequest';
import { requestInventorySyncReducers } from './featureActions/sync/requestInventorySync';
import { syncInventoryReducers } from './featureActions/syncInventory';
import { updateInventoryAssignmentMapsReducers } from './featureActions/updateInventoryAssignmentMaps';
import { viewOrdersForInventoryReducer } from './featureActions/viewOrdersForInventory';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(loadSliceDataReducer)
            .add(loadInventoryReducer)
            .add(loadLatestSyncRequestReducers)
            .add(requestInventorySyncReducers)
            .add(setSelectedInventoryReducers)
            .addRange(editInventoryReducers)
            .addRange(addInventoryReducers)
            .add(beginInventoryCategoryAssignmentReducers)
            .add(completeCategoryAssignmentReducers)
            .add(beginBulkCategoryAssignmentReducers)
            .add(completeBulkCategoryAssignmentReducers)
            .add(beginEditInventoryImagesReducers)
            .add(completeEditInventoryImagesReducers)
            .add(deleteInventoryImageReducers)
            .add(loadInventoryMediaReducer)
            .add(updateImageDetailsReducers)
            .add(uploadNewInventoryImageReducers)
            .add(beginBulkExcludeReducers)
            .add(cancelBulkExcludeReducers)
            .add(completeBulkExcludeReducers)
            .add(cancelInventoryCategoryAssignmentReducers)
            .add(cancelBulkCategoryAssignmentReducers)
            .add(loadMenuAssignmentsReducer)
            .add(updateInventoryAssignmentMapsReducers)
            .add(beginBulkClearCategoryAssignmentReducers)
            .add(cancelBulkClearCategoryAssignmentReducers)
            .add(completeBulkClearCategoryAssignmentReducers)
            .add(beginBulkQualityGradeAssignmentReducers)
            .add(cancelBulkQualityGradeAssignmentReducer)
            .add(completeBulkQualityGradeAssignmentReducer)
            .add(setQualityGradeToAssignReducer)
            .add(viewOrdersForInventoryReducer)
            .add(upsertInventoryVideoReducer)
            .add(deleteInventoryVideoReducer)
            .addRange(addVideoReducers)
            .addRange(createQualityAssessmentReducers)
            .addRange(removeQualityAssessmentReducers)
            .add(loadInventoryByIdsReducer)
            .addRange(syncInventoryReducers)
            .addRange(bulkSetReadyDateReducers)
            .add(upsertInventoryNoteReducer)
            .add(deleteInventoryNoteReducer)
            .add(deleteInventoryReducer)
            .add(loadInventoryNotesReducer)
            .add(loadInventoryLicensesReducer)
            .add(loadUnitOfMeasuresReducer)
        // Create your feature actions in ./featureActions and then add them to the reducer factory here
        // with .Add(<your-action-reducer-name>);

    }
});

export default slice.reducer;

export const { resetState } = slice.actions;