import { useDispatch } from "react-redux";
import { deleteBanner } from "../store/featureActions/images/deleteBanner";
import { deleteNewBanner } from "../store/featureActions/images/deleteNewBanner";
import { getCustomerBanner, getLoadBannerState, getNewBanner, getSaveBannerState } from "../store/selectors/banner";
import { ImageSelector } from "./imageSelector";
import { setNewBanner } from "../store/featureActions/images/setNewBanner";
import { saveBanner } from "../store/featureActions/images/saveBanner";

export const BannerSetup: React.FC = () => {

    const dispatch = useDispatch();

    const onSelectBanner = (fileUrl: string) => {
        dispatch(setNewBanner(fileUrl));
    }

    const onReplaceBanner = () => {
        dispatch(saveBanner());
    }

    const onDeleteBanner = () => {
        dispatch(deleteBanner());
    }

    const onDeleteNewBanner = () => {
        dispatch(deleteNewBanner());
    }

    return <div className="p-mt-2">       
        <ImageSelector
            currentImageSelector={getCustomerBanner}
            newImageSelector={getNewBanner}
            saveStateSelector={getSaveBannerState}
            loadStateSelector={getLoadBannerState}
            title={"Banner"}
            onNewImageSet={onSelectBanner}
            onReplaceImage={onReplaceBanner}
            onDeleteCurrentImage={onDeleteBanner}
            onDeleteNewImage={onDeleteNewBanner}
            imageSize={{ width: "720px", height: "120px" }}
            imageContainerSize={{ width: "740px", height: "220px" }}
        />

    </div>
}