import partialHasChanges from "../../../../../utils/changeDetection";
import { isValue } from "../../../../../utils/valueHelper";
import { PricingModelGrade } from "../../../sharedTypes";
import { pricingModelGradeAdapter, pricingModelSaveStateAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    upsertPricingModelGrade,
    upsertPricingModelGradeReducer,
    upsertPricingModelGradeSagaFactory

} = actionFactory
    .withMappedName("upsertPricingModelGrade")
    .createAsyncAction<PricingModelGrade, PricingModelGrade>({
        actionStatusSelector: {
            adapter: pricingModelSaveStateAdapter,
            adapterStateSelector: (state) => state.pricingModelSaveStates,
            getId: (_state, arg) => arg.id
        },
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.pricing.upsertPricingModelGrade(arg);
        },
        onFulfilled: (state, action) => {
            pricingModelGradeAdapter.upsertOne(state.pricingModelGrades, action.payload.result);
        },
        predicate: (arg, context) => {
            if (!isValue(arg.id)) return true;
            if (arg.id <= 0) return true;

            const originalValues = context.getState().pricingEditor.pricingModelGrades.entities[arg.id];
            if (!isValue(originalValues)) return true;

            return partialHasChanges(originalValues, arg);
        }
    });