import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { createAdvanceWorkflowOnCompanyProfileSaveSaga, createAdvanceWorkflowOnUserProfileSaveSaga } from "../featureActions/advanceWorkflowOnSave";
import { createLoadUserSetupInfoSaga } from "../featureActions/loadUserSetupData";

// Register this method in the root saga
export function createInitialSetupSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [
        createLoadUserSetupInfoSaga(context),   
        createAdvanceWorkflowOnCompanyProfileSaveSaga(context),
        createAdvanceWorkflowOnUserProfileSaveSaga(context)
    ]
}