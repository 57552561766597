import { isValue } from "./valueHelper";

export function TransformResult<T>(transform: (_: T) => void, result: T): T {
    transform(result);
    return result;
}

export function TransformResults<T>(transform: (_: T) => void) {
    return (results: T[]) => {
        results.forEach(result => transform(result));
        return results;
    }
}

export interface Load2Result<T1, T2> { result1: T1, result2: T2 };
export interface Load3Result<T1, T2, T3> { result1: T1, result2: T2, result3: T3 };
export interface Load4Result<T1, T2, T3, T4> { result1: T1, result2: T2, result3: T3, result4: T4 };
export interface Load5Result<T1, T2, T3, T4, T5> { result1: T1, result2: T2, result3: T3, result4: T4, result5: T5 };
export interface Load6Result<T1, T2, T3, T4, T5, T6> { result1: T1, result2: T2, result3: T3, result4: T4, result5: T5, result6: T6 };
export interface Load7Result<T1, T2, T3, T4, T5, T6, T7> { result1: T1, result2: T2, result3: T3, result4: T4, result5: T5, result6: T6, result7: T7 };
export interface Load8Result<T1, T2, T3, T4, T5, T6, T7, T8> { result1: T1, result2: T2, result3: T3, result4: T4, result5: T5, result6: T6, result7: T7, result8: T8 };
export interface Load9Result<T1, T2, T3, T4, T5, T6, T7, T8, T9> { result1: T1, result2: T2, result3: T3, result4: T4, result5: T5, result6: T6, result7: T7, result8: T8, result9: T9 };
export interface Load10Result<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10> { result1: T1, result2: T2, result3: T3, result4: T4, result5: T5, result6: T6, result7: T7, result8: T8, result9: T9, result10: T10 };

export function parallelLoad<T1, T2>(
    load1: () => Promise<T1>,
    load2: () => Promise<T2>): Promise<Load2Result<T1, T2>>;
export function parallelLoad<T1, T2, T3>(
    load1: () => Promise<T1>,
    load2: () => Promise<T2>,
    load3: () => Promise<T3>): Promise<Load3Result<T1, T2, T3>>;
export function parallelLoad<T1, T2, T3, T4>(
    load1: () => Promise<T1>,
    load2: () => Promise<T2>,
    load3: () => Promise<T3>,
    load4: () => Promise<T4>): Promise<Load4Result<T1, T2, T3, T4>>;
export function parallelLoad<T1, T2, T3, T4, T5>(
    load1: () => Promise<T1>,
    load2: () => Promise<T2>,
    load3: () => Promise<T3>,
    load4: () => Promise<T4>,
    load5: () => Promise<T5>): Promise<Load5Result<T1, T2, T3, T4, T5>>;
export function parallelLoad<T1, T2, T3, T4, T5, T6>(
    load1: () => Promise<T1>,
    load2: () => Promise<T2>,
    load3: () => Promise<T3>,
    load4: () => Promise<T4>,
    load5: () => Promise<T5>,
    load6: () => Promise<T6>): Promise<Load6Result<T1, T2, T3, T4, T5, T6>>;
export function parallelLoad<T1, T2, T3, T4, T5, T6, T7>(
    load1: () => Promise<T1>,
    load2: () => Promise<T2>,
    load3: () => Promise<T3>,
    load4: () => Promise<T4>,
    load5: () => Promise<T5>,
    load6: () => Promise<T6>,
    load7: () => Promise<T7>): Promise<Load7Result<T1, T2, T3, T4, T5, T6, T7>>;
export function parallelLoad<T1, T2, T3, T4, T5, T6, T7, T8>(
    load1: () => Promise<T1>,
    load2: () => Promise<T2>,
    load3: () => Promise<T3>,
    load4: () => Promise<T4>,
    load5: () => Promise<T5>,
    load6: () => Promise<T6>,
    load7: () => Promise<T7>,
    load8: () => Promise<T8>): Promise<Load8Result<T1, T2, T3, T4, T5, T6, T7, T8>>;
export function parallelLoad<T1, T2, T3, T4, T5, T6, T7, T8, T9>(
    load1: () => Promise<T1>,
    load2: () => Promise<T2>,
    load3: () => Promise<T3>,
    load4: () => Promise<T4>,
    load5: () => Promise<T5>,
    load6: () => Promise<T6>,
    load7: () => Promise<T7>,
    load8: () => Promise<T8>,
    load9: () => Promise<T9>): Promise<Load9Result<T1, T2, T3, T4, T5, T6, T7, T8, T9>>;
export function parallelLoad<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10>(
    load1: () => Promise<T1>,
    load2: () => Promise<T2>,
    load3: () => Promise<T3>,
    load4: () => Promise<T4>,
    load5: () => Promise<T5>,
    load6: () => Promise<T6>,
    load7: () => Promise<T7>,
    load8: () => Promise<T8>,
    load9: () => Promise<T9>,
    load10: () => Promise<T10>): Promise<Load10Result<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10>>;

export function parallelLoad<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10>(
    load1: () => Promise<T1>,
    load2: () => Promise<T2>,
    load3?: () => Promise<T3>,
    load4?: () => Promise<T4>,
    load5?: () => Promise<T5>,
    load6?: () => Promise<T6>,
    load7?: () => Promise<T7>,
    load8?: () => Promise<T8>,
    load9?: () => Promise<T9>,
    load10?: () => Promise<T10>
) {
    if (isValue(load3) && isValue(load4) && isValue(load5) && isValue(load6) && isValue(load7) && isValue(load8) && isValue(load9) && isValue(load10)) {
        const promise1: Promise<T1> = load1();
        const promise2: Promise<T2> = load2();
        const promise3: Promise<T3> = load3();
        const promise4: Promise<T4> = load4();
        const promise5: Promise<T5> = load5();
        const promise6: Promise<T6> = load6();
        const promise7: Promise<T7> = load7();
        const promise8: Promise<T8> = load8();
        const promise9: Promise<T9> = load9();
        const promise10: Promise<T10> = load10();
        return Promise
            .all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9, promise10])
            .then(([result1, result2, result3, result4, result5, result6, result7, result8, result9, result10]) =>
                ({ result1, result2, result3, result4, result5, result6, result7, result8, result9, result10 }));
    }
    if (isValue(load3) && isValue(load4) && isValue(load5) && isValue(load6) && isValue(load7) && isValue(load8) && isValue(load9)) {
        const promise1: Promise<T1> = load1();
        const promise2: Promise<T2> = load2();
        const promise3: Promise<T3> = load3();
        const promise4: Promise<T4> = load4();
        const promise5: Promise<T5> = load5();
        const promise6: Promise<T6> = load6();
        const promise7: Promise<T7> = load7();
        const promise8: Promise<T8> = load8();
        const promise9: Promise<T9> = load9();
        return Promise
            .all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9])
            .then(([result1, result2, result3, result4, result5, result6, result7, result8, result9]) => ({ result1, result2, result3, result4, result5, result6, result7, result8, result9 }));
    }
    if (isValue(load3) && isValue(load4) && isValue(load5) && isValue(load6) && isValue(load7) && isValue(load8)) {
        const promise1: Promise<T1> = load1();
        const promise2: Promise<T2> = load2();
        const promise3: Promise<T3> = load3();
        const promise4: Promise<T4> = load4();
        const promise5: Promise<T5> = load5();
        const promise6: Promise<T6> = load6();
        const promise7: Promise<T7> = load7();
        const promise8: Promise<T8> = load8();
        return Promise
            .all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8])
            .then(([result1, result2, result3, result4, result5, result6, result7, result8]) => ({ result1, result2, result3, result4, result5, result6, result7, result8 }));
    }
    if (isValue(load3) && isValue(load4) && isValue(load5) && isValue(load6) && isValue(load7)) {
        const promise1: Promise<T1> = load1();
        const promise2: Promise<T2> = load2();
        const promise3: Promise<T3> = load3();
        const promise4: Promise<T4> = load4();
        const promise5: Promise<T5> = load5();
        const promise6: Promise<T6> = load6();
        const promise7: Promise<T7> = load7();
        return Promise
            .all([promise1, promise2, promise3, promise4, promise5, promise6, promise7])
            .then(([result1, result2, result3, result4, result5, result6, result7]) => ({ result1, result2, result3, result4, result5, result6, result7 }));
    }
    if (isValue(load3) && isValue(load4) && isValue(load5) && isValue(load6)) {
        const promise1: Promise<T1> = load1();
        const promise2: Promise<T2> = load2();
        const promise3: Promise<T3> = load3();
        const promise4: Promise<T4> = load4();
        const promise5: Promise<T5> = load5();
        const promise6: Promise<T6> = load6();
        return Promise
            .all([promise1, promise2, promise3, promise4, promise5, promise6])
            .then(([result1, result2, result3, result4, result5, result6]) => ({ result1, result2, result3, result4, result5, result6 }));
    }
    if (isValue(load3) && isValue(load4) && isValue(load5)) {
        const promise1: Promise<T1> = load1();
        const promise2: Promise<T2> = load2();
        const promise3: Promise<T3> = load3();
        const promise4: Promise<T4> = load4();
        const promise5: Promise<T5> = load5();
        return Promise
            .all([promise1, promise2, promise3, promise4, promise5])
            .then(([result1, result2, result3, result4, result5]) => ({ result1, result2, result3, result4, result5 }));
    }
    if (isValue(load3) && isValue(load4)) {
        const promise1: Promise<T1> = load1();
        const promise2: Promise<T2> = load2();
        const promise3: Promise<T3> = load3();
        const promise4: Promise<T4> = load4();
        return Promise
            .all([promise1, promise2, promise3, promise4])
            .then(([result1, result2, result3, result4]) => ({ result1, result2, result3, result4 }));
    }
    else if (isValue(load3)) {
        const promise1: Promise<T1> = load1();
        const promise2: Promise<T2> = load2();
        const promise3: Promise<T3> = load3();
        return Promise
            .all([promise1, promise2, promise3])
            .then(([result1, result2, result3]) => ({ result1, result2, result3 }));
    }
    else {
        const promise1: Promise<T1> = load1();
        const promise2: Promise<T2> = load2();
        return Promise
            .all([promise1, promise2])
            .then(([result1, result2]) => ({ result1, result2 }));
    }
}