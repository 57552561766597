import { Menu } from "../../../../menu/editor/store/types";
import { actionFactory } from "../types";

export const {

    loadMenuInvites,
    loadMenuInvitesReducer,
    loadMenuInvitesSagaFactory

} = actionFactory
    .withMappedName("loadMenuInvites")
    .createAsyncAction<void, Menu[]>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (_arg, context) => {
            return context.marketplaceApiClient.userProfile.getMenuInvites();
        },
        onFulfilled: (state, action) => {
            state.invites = action.payload.result;
        },
        successCallback: (_arg, _result, _context) => { },
        debounceInMilliseconds: 50
    });