import clsx from 'clsx';
import { CSSProperties } from 'react';
import { useAppSelector } from '../../../../store/configureStore';
import { VendorTopPanelProps } from './props';
import { VendorBanner } from "./vendorBanner";
import { VendorLogo } from "./vendorLogo";
import './vendorTopPanel.css';

export const VendorTopPanel: React.FC<VendorTopPanelProps> = ({ vendorUrlName, menuUrlName, primaryColorSelector, secondardColorSelector, companyDisplayNameSelector }) => {

    const primaryColor = useAppSelector(primaryColorSelector);

    const panelStyle: CSSProperties = {
        background: primaryColor ?? undefined
    }

    return <div className="p-d-flex p-flex-column">
        <div
            className={clsx("p-d-flex p-jc-start p-ai-start")}
            style={panelStyle}
        >
            <VendorLogo vendorUrlName={vendorUrlName} menuUrlName={menuUrlName} />

            <div
                className="p-d-flex p-jc-center p-ai-center"
                style={{
                    width: "100vw",
                    background: primaryColor ?? undefined,
                    position: "absolute",
                    top: 0,
                    left: 0
                }}
            >
                <VendorBanner
                    vendorUrlName={vendorUrlName}
                    menuUrlName={menuUrlName}
                    primaryColorSelector={primaryColorSelector}
                    companyDisplayNameSelector={companyDisplayNameSelector}
                    secondardColorSelector={secondardColorSelector}

                />
            </div>
        </div>
    </div>
}
