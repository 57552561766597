import { createResponseWithPayload, CreateWatchAndRespondSaga } from "../../../../../store/factory/watchAndRespond";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { isValue } from "../../../../../utils/valueHelper";
import { saveCompanyProfileSagaActions } from "../../../../company/profile/store/featureActions/saveCustomerProfile";
import { saveUserProfileSagaActions } from "../../../../marketplaceUserProfile/manageProfile/store/featureActions/updateProfile";
import { WorkflowStep } from "../types";
import { setWorkflowStep } from "./workflowNavigation";


const responsePredicate = (context: SagaAppContext) : boolean => {

    const state = context.getState();
    return isValue(state.initialSetup.userSetupRequiredState.userSetupInfo) && state.initialSetup.userSetupRequiredState.userSetupInfo.termsOfServiceNeeded;

}

export function createAdvanceWorkflowOnCompanyProfileSaveSaga(context: SagaAppContext) {

    return CreateWatchAndRespondSaga<{ step: WorkflowStep }>({
        context: context,
        watchActionPattern: saveCompanyProfileSagaActions.fulfilled,
        response: createResponseWithPayload(setWorkflowStep, { step: WorkflowStep.UserInfo }),
        respondPredicate: responsePredicate
    });

};

export function createAdvanceWorkflowOnUserProfileSaveSaga(context: SagaAppContext) {

    return CreateWatchAndRespondSaga<{ step: WorkflowStep }>({
        context: context,
        watchActionPattern: saveUserProfileSagaActions.fulfilled,
        response: createResponseWithPayload(setWorkflowStep, { step: WorkflowStep.TermsOfService }),
        respondPredicate: responsePredicate
    });

};