import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { clearAssessmentState } from "../store/featureActions/clearAssessmentState";
import { loadReferenceData } from "../store/featureActions/loadReferenceData";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { getIsLoading, getIsSavingAssessment } from "../store/selectors/actionStatus";
import { AssessmentCommandBar } from "./assessmentCommandBar";
import { AssessmentValueTable } from "./assessmentValueTable";
import './index.css';

export interface QualityAssessmentEditorProps {
    assessmentId: number
}

export const QualityAssessmentEditor: React.FC<QualityAssessmentEditorProps> = ({ assessmentId }) => {

    const dispatch = useDispatch();
    const isLoading = useAppSelector(state => getIsLoading(state, assessmentId));
    const isSavingAssessment = useAppSelector(state => getIsSavingAssessment(state, assessmentId));

    useMountEffect(() => {
        dispatch(loadReferenceData());
        dispatch(loadSliceData({ assessmentId: assessmentId }));

        return () => {
            dispatch(clearAssessmentState({ assessmentId: assessmentId }));
        }
    });

    return <OverlayWaitDisplay
        isWaiting={isLoading || isSavingAssessment}
    >
        <FlexColumnContainer className="quality-assessment-editor">
            <AssessmentCommandBar assessmentId={assessmentId}/>
            <AssessmentValueTable assessmentId={assessmentId}  />
            {/* <AssessmentValueList assessmentId={assessmentId} /> */}
        </FlexColumnContainer>
    </OverlayWaitDisplay>
} 