import { Column, ColumnProps } from "primereact/column";
import { DataTable, DataTableSelectionChangeParams } from "primereact/datatable";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { createFilteredBooleanColumnProps, createInitialInactiveFilterState } from "../../../../utils/table/booleanColumn";
import { useInitialFilterState } from "../../../../utils/table/filters/initialFilterStateFunctions";
import { createDateColumnProps } from "../../../../utils/table/dateColumn";
import { createTextColumnProps } from "../../../../utils/table/stringColumn";
import { isValue } from "../../../../utils/valueHelper";
import { QualityGradeKind } from "../../models/gradeKind";
import { selectQualityGradeKind } from "../store/featureActions/selectQualityGradeKind";
import { getGradeKinds } from "../store/selectors/data";
import { getSelectedGradeKind } from "../store/selectors/editing";

export const QualityGradeKindList: React.FC = () => {

    const dispatch = useDispatch(); 
    const gradeKinds = useAppSelector(getGradeKinds);
    const selectedGradeKind = useAppSelector(getSelectedGradeKind);
    const filterState = useInitialFilterState({
        filterMeta: {
            'inactive': createInitialInactiveFilterState()
        }}
    );

    const columns: ColumnProps[] = [
        createTextColumnProps<QualityGradeKind>({ header: "Name", field: "name", style: { minWidth: 150 }, sortable: true }),
        createDateColumnProps<QualityGradeKind>({ header: "Published", field: "publishedDate", style: { width: 120, maxWidth: 120 } }),
        createFilteredBooleanColumnProps<QualityGradeKind>({
            header: "Inactive",
            field: "inactive",
            disabledFn: () => true,
            style: { width: 140, maxWidth: 140 },
            falseLabel: "Active",
            trueLabel: "Inactive"
        })
    ];

    const onSelectionChange = (e: DataTableSelectionChangeParams) => {
        const selectedEntities: QualityGradeKind | null = e.value;

        if (isValue(selectedEntities)) {
            dispatch(selectQualityGradeKind(selectedEntities));
        }
        else {
            dispatch(selectQualityGradeKind(null));
        }
    }


    return <FlexColumnContainer className="quality-grade-kind-list">

        <DataTable
            {...filterState}
            size="small"
            dataKey="id"
            value={gradeKinds}
            scrollable
            scrollHeight='flex'
            paginator
            rows={20}
            rowsPerPageOptions={[10, 20, 50]}
            alwaysShowPaginator={false}
            selectionMode="single"
            onSelectionChange={onSelectionChange}
            selection={selectedGradeKind}
        >
            {columns.map(c => <Column key={c.columnKey ?? c.field} {...c} />)}
        </DataTable>

    </FlexColumnContainer>
} 