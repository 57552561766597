import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { actionFactory, InventoryManagementState } from "../../types";

export const cancelBulkQualityGradeAssignment = actionFactory.createAppAction("cancelBulkQualityGradeAssignment");

export function cancelBulkQualityGradeAssignmentReducer(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forAction(cancelBulkQualityGradeAssignment)
        .addHandler((state, _) => {
            state.bulkQualityGradeAssignmentState.inventory = [];
        })
        .build();
}