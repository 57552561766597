import { Contact, isContact } from "../../../common/types";
import { contactAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    editContactBegin,
    editContactCancel,
    editContactComplete,
    editContactReducers,
    editContactSagaFactory

} = actionFactory
    .withMappedName("editContact")
    .createModal<Partial<Contact>, Partial<Contact>, Contact>({
        modelFactory: (arg, state) => {

            if (isContact(arg)) return arg;

            return {
                id: 0,
                name: "",
                customerId: state.customer?.id
            }
        },
        selector: (state) => state.editContactState,
        onCompleteEdit: (value, context) => {
            if (!isContact(value)) throw new Error("The contact is not valid for saving.");
            return context.marketplaceApiClient.customer.upsertCustomerContact(value);
        },
        onFulfilled: (state, action) => {
            contactAdapter.upsertOne(state.customerContacts, action.result);
        }
    });