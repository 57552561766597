import clsx from 'clsx';
import { Field, FieldProps, FormikContextType, useFormikContext } from "formik";
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { CSSProperties } from 'react';
import { FlexRowContainer } from '../containers/flexRowContainer';

export interface SingleSelectFieldProps<TModel, TValue> {
    defaultLabel: string;
    fieldName: string & keyof TModel;
    getValue: (model: TModel) => TValue | undefined | null;
    optionValue: string & keyof TValue;
    optionLabel: string & keyof TValue;
    availableValues: TValue[];
    containerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    placeHolder?: string;
    allowClear?: boolean;
    submitOnBlur?: boolean;
    disabled?: boolean;
}


export const SingleSelectField = <TModel, TValue>({
    defaultLabel,
    fieldName,
    availableValues,
    optionValue,
    optionLabel,
    containerStyle,
    labelStyle,
    inputStyle,
    placeHolder,
    submitOnBlur = false,
    disabled
}: SingleSelectFieldProps<TModel, TValue>) => {

    const formikProps: FormikContextType<TModel> = useFormikContext<TModel>();
    const labelText = defaultLabel;

    return <div style={containerStyle}>

        <label style={labelStyle} htmlFor={fieldName} >{labelText}</label>
        <Field
            name={fieldName}
        >
            {
                (props: FieldProps<TModel>) =>
                    <FlexRowContainer centerContent>
                        <Dropdown
                            options={availableValues}
                            optionValue={optionValue}
                            optionLabel={optionLabel}
                            placeholder={placeHolder}
                            style={inputStyle}
                            className={clsx({ "p-invalid": !!formikProps.errors[fieldName] })}
                            onChange={(e: DropdownChangeParams) => {
                                formikProps.setFieldValue(props.field.name, e.value);
                            }}
                            onBlur={(_event) => {
                                if (submitOnBlur && formikProps.dirty) {
                                    formikProps.submitForm();
                                }
                            }}
                            value={formikProps.values[fieldName]}
                            filter
                            disabled={disabled}
                        />
                        <Button
                            type="button"
                            icon={PrimeIcons.TIMES}
                            className="p-button-outlined p-button-rounded p-ml-2 clearable-input-number--button"
                            onClick={() => formikProps.setFieldValue(props.field.name, null)}
                        />
                    </FlexRowContainer>
            }
        </Field>
        <div>
            <small>{formikProps.errors[fieldName]}</small>
        </div>

    </div>
}