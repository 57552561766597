import ApiClient from "../../apiCommunication/apiClient";
import { ImageDto, ImageKind } from "../../commonModels/imageTypes";
import { SearchVendorDto } from "../../customer/dashboard/store/types";
import { Company, CompanyProfile } from "../profile/store/types";

export default class CompanyApi {

    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    getCompanyProfile = (): Promise<CompanyProfile> => {
        return this
            .apiClient
            .executeGet<CompanyProfile>(`Company`);
    };

    getCompanyMarketingView = (companyId: number): Promise<CompanyProfile> => {
        return this
            .apiClient
            .executeGet<CompanyProfile>(`Company/${companyId}`);
    };

    updateCompanyProfile = (companyProfile: CompanyProfile): Promise<CompanyProfile> => {
        return this
            .apiClient
            .executePutWithResult<CompanyProfile>(`Company`, companyProfile);
    };
    
    getCompanyList = (): Promise<CompanyProfile[]> => {
        return this
            .apiClient
            .executeGet<CompanyProfile[]>(`Company/list`);
    };

    createNewCompany = (company: Company): Promise<Company> => {
        return this
            .apiClient
            .executePostWithResult<Company>(`Company`, company);
    };

    getImage = (companyId: number, kind: ImageKind): Promise<ImageDto> => {        
        return this
            .apiClient
            .executeGet(`Company/${companyId}/${kind}`);
    };

    deleteImage = (companyId: number, kind: ImageKind): Promise<Response> => {        
        return this
            .apiClient
            .executeDelete(`Company/${companyId}/${kind}`);
    };

    uploadImage = (companyId: number, file: Blob | string | File, kind: ImageKind): Promise<Response> => {
        const formData = new FormData();
        formData.append("file", file);        
        return this
            .apiClient
            .executeFormDataPost(`Company/${companyId}/${kind}`, formData);
    };

    getSupplierList = (searchFilter: SearchVendorDto): Promise<CompanyProfile[]> => {
        return this
            .apiClient
            .executePostWithResult<CompanyProfile[]>(`Company/suppliers`, searchFilter);
    };

    getDistributorList = (searchFilter: SearchVendorDto): Promise<CompanyProfile[]> => {
        return this
            .apiClient
            .executePostWithResult<CompanyProfile[]>(`Company/distributors`, searchFilter);
    };

    getRetailList = (searchFilter: SearchVendorDto): Promise<CompanyProfile[]> => {
        return this
            .apiClient
            .executePostWithResult<CompanyProfile[]>(`Company/retailers`, searchFilter);
    };
}