import { createAction } from "@reduxjs/toolkit";
import { IReducerFactory } from "../../../../store/factory/interfaces";
import { actionFactory, UserAuthState } from "../types";

export const clearSession = createAction(actionFactory.createActionName("clearSession"));

export function clearSessionReducer(reducerFactory: IReducerFactory<UserAuthState>): IReducerFactory<UserAuthState> {
    return reducerFactory
        .forAction(clearSession)
        .addHandler((state, _) => {

            state.CurrentUser = null;
            state.signInCompleted = false;
            state.ErrorMessage = null;
            state.AuthenticationAction = null;

        })
        .build();
}