import { ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { CSSProperties } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { currencyFormatOptions, percentFormatOptions } from "../../../../utils/formatHelpers";
import { createDateColumnProps, ShortDateFormatOptions } from "../../../../utils/table/dateColumn";
import { mapColumns } from "../../../../utils/table/mapPropsToColumns";
import { createNumberColumnProps } from "../../../../utils/table/numberColumn";
import { createOverlayMenuColumnProps } from "../../../../utils/table/overlayMenuColumn";
import { createTextColumnProps } from "../../../../utils/table/stringColumn";
import { TypedMenuItem } from "../../../../utils/table/typedMenuItem";
import { smallDeviceQuery } from "../../../responsiveconfiguration";
import { getPrimaryColor, getSecondaryColor } from "../store/selectors/menuColors";
import { getPrimaryFont, getSecondaryFont } from "../store/selectors/menuFonts";
import { getInventoryForSection, getInventoryLoadingStateForSection, getMenuUrlName, getVendorRouteName } from "../store/selectors/vendorMenu";
import { InventoryView } from "../store/types";
import { createMenuItems } from "./inventoryCommands";

export interface MenuSectionTableViewProps {
    sectionId: number;
}

export const MenuSectionTableView: React.FC<MenuSectionTableViewProps> = ({ sectionId }) => {

    const dispatch = useDispatch();
    const inventory = useAppSelector(state => getInventoryForSection(state, sectionId));
    const inventoryLoadState = useAppSelector(state => getInventoryLoadingStateForSection(state, sectionId));
    const vendorRouteName = useAppSelector(getVendorRouteName);
    const history = useHistory();
    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    const menuUrlName = useAppSelector(getMenuUrlName);

    const primaryColor = useAppSelector(getPrimaryColor);
    const secondaryColor = useAppSelector(getSecondaryColor);
    const primaryFont = useAppSelector(getPrimaryFont);
    const secondaryFont = useAppSelector(getSecondaryFont);
    

    const buttonStyle: CSSProperties = {
        background: primaryColor ?? undefined,
        color: secondaryColor ?? undefined
    }

    const menuItems: TypedMenuItem<InventoryView>[] = createMenuItems(dispatch, history, vendorRouteName, menuUrlName);

    const columns: ColumnProps[] = [
        createOverlayMenuColumnProps<InventoryView>({
            field: "id",
            header: <div style={{ fontFamily: primaryFont }}>Actions</div>,
            menuTitle: "...",
            menuItems: menuItems,
            columnKey: "id",
            buttonStyle: buttonStyle,
            style: { width: "50px" },
            sortable: false
        }), 
        createTextColumnProps<InventoryView>({ field: "marketingName", header: <div style={{ fontFamily: primaryFont }}>Name</div>, style: { width: "250px", fontFamily: secondaryFont }, sortable: false }),
        createNumberColumnProps<InventoryView>({ field: "quantity", header: <div style={{ fontFamily: primaryFont }}>Quantity</div>, formatOptions: { maximumFractionDigits: 4 }, style: { width: "150px", fontFamily: secondaryFont }, sortable: false }),
        createTextColumnProps<InventoryView>({ field: "unitOfMeasureName", header: <div style={{ fontFamily: primaryFont }}>UOM</div>, style: { width: "100px", fontFamily: secondaryFont }, sortable: false }),
        createNumberColumnProps<InventoryView>({ field: "unitPrice", header: <div style={{ fontFamily: primaryFont }}>Unit Price</div>, formatOptions: currencyFormatOptions, style: { width: "150px", fontFamily: secondaryFont }, sortable: false }),
        createNumberColumnProps<InventoryView>({ field: "thcPercentage", header: <div style={{ fontFamily: primaryFont }}>THC %</div>, formatOptions: percentFormatOptions, style: { width: "100px", fontFamily: secondaryFont }, sortable: false }),
        createNumberColumnProps<InventoryView>({ field: "cbdPercentage", header: <div style={{ fontFamily: primaryFont }}>CBD %</div>, formatOptions: percentFormatOptions, style: { width: "100px", fontFamily: secondaryFont }, sortable: false }),
        createDateColumnProps<InventoryView>({ 
            field: "readyDate", 
            header: <div style={{ fontFamily: primaryFont }}>Ready</div>,  
            style: { width: "100px", fontFamily: secondaryFont }, 
            sortable: false,
            formatOptions: ShortDateFormatOptions
        }),
        createTextColumnProps<InventoryView>({ field: "marketingStrainName", header: <div style={{ fontFamily: primaryFont }}>Strain</div>, sortable: false, style: { fontFamily: secondaryFont } }),        
    ]

    return <div style={{ width: "100%" }}>

        <OverlayWaitDisplay
            isWaiting={inventoryLoadState.isExecuting}
        >
            <DataTable
                className="p-datatable-sm"
                autoLayout={true}
                value={inventory}
                paginator
                alwaysShowPaginator={false}
                rows={100}
                paginatorTemplate={isSmallDevice ? "PrevPageLink PageLinks NextPageLink" : "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"}
                paginatorPosition="top"
                pageLinkSize={3}
                rowsPerPageOptions={[10, 20, 50, 100]}                
            >
                {mapColumns(columns)}
            </DataTable>
        </OverlayWaitDisplay>

    </div>
}