import { useDispatch } from "react-redux";
import { useMountEffect } from "../../../../utils/useEffectHelper"
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { resetState } from "../store/slice";
import { FeatureContentPanel } from "../../../../components/containers/featureContentPanel";
import { getLoadingState } from "../store/selectors";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { CompanyTable } from "./companyTable";
import { CommandBar } from "./commandBar";
import { AddCompanyDialog } from "./addCompanyDialog";
import './index.css'
export const CompanyProvisioningDashboard: React.FC = () => {

    const dispatch = useDispatch();

    useMountEffect(() => {
        dispatch(loadSliceData());

        return () => {
            dispatch(resetState());
        }
    });

    return <FeatureContentPanel
        loadingStateSelector={getLoadingState}
        title="Company Provisioning"
    >
        <AddCompanyDialog />
        <FlexColumnContainer className="quality-metric-kind-dashboard">
            
            <CommandBar />

            <CompanyTable />            

        </FlexColumnContainer>

    </FeatureContentPanel>
} 