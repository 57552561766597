import { DataTableFilterMeta, DataTablePFSEvent } from 'primereact/datatable';
import { useState } from 'react';


export interface InitialFilterState {
    filterMeta: DataTableFilterMeta;
}

export const useInitialFilterState = (initialState: InitialFilterState) => {

    const [filters, setFilter] = useState<DataTableFilterMeta>(initialState.filterMeta);

    const onFilter = (e: DataTablePFSEvent) => {
        setFilter(e.filters);
    };

    return {
        filters,
        onFilter
    };
};
