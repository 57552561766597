import clsx from "clsx";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Divider } from "primereact/divider";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { hideDialog } from "../store/featureActions/hideDialog";
import { getMessageInfo, getVisible } from "../store/selectors";
import './index.css';

export const MessageDialog: React.FC = () => {

    const dispatch = useDispatch();
    const visible = useAppSelector(getVisible);
    const messageInfo = useAppSelector(getMessageInfo);

    const onDismiss = () => {
        dispatch(hideDialog());
    }

    return <Dialog
        visible={visible}
        onHide={() => { }}
        closeOnEscape={false}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
    >
        <div
            className={clsx("p-shadow-8 p-p-4")}
        >
            <div
                className='p-d-flex p-jc-start p-ai-center'
            >
                <i className={clsx(PrimeIcons.INFO_CIRCLE, "message-dialog-icon-info")} ></i>
                <h3 className="p-ml-2">{messageInfo?.title ?? "Information"}</h3>
            </div>

            <Divider />

            <div
                className='p-d-flex p-jc-center p-ai-center'
            >
                <div>{messageInfo?.message}</div>
            </div>

            <Divider />

            <div
                className='p-d-flex p-jc-center p-ai-center p-mt-4 p-mb-2'
            >
                <Button label={messageInfo?.actionTitle ?? "Ok"} onClick={onDismiss} />
            </div>

        </div>
    </Dialog>
}