import { CustomerNote } from "../../../common/types";
import { customerNoteAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    upsertCustomerNote,
    upsertCustomerNoteReducer,
    upsertCustomerNoteSagaFactory

} = actionFactory
    .withMappedName("upsertCustomerNote")
    .createAsyncAction<CustomerNote, CustomerNote>({    
    actionStatusSelector: state => state.loadingState,
    asyncCall: (arg, context) => {
       return context.marketplaceApiClient.customer.upsertCustomerNote(arg);
    },
    onFulfilled: (state, action) => {
       customerNoteAdapter.upsertOne(state.customerNotes, action.payload.result);
    }
});