import { isValue } from "../../../../../utils/valueHelper";
import { viewSettingsAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    requireTableRemount,
    requireTableRemountReducer

} = actionFactory
    .withMappedName("requireTableRemount")
    .createAction<{ viewId: string }>((state, action) => {

        const viewSettings = state.viewSettings.entities[action.payload.viewId];
        if (!isValue(viewSettings)) return;

        viewSettings.remountState.tableRemountNeeded = true;
        viewSettingsAdapter.upsertOne(state.viewSettings, viewSettings);
    });