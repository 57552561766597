import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../../store/rootSaga";
import { MenuCategoryView } from "../../../../general/models/menuCategoryView";
import { actionFactory, CategoryAssignmentState } from "../../types";

export const loadMenuViews = actionFactory.createAppAction("loadMenuViews");

const sagaActions = actionFactory.createSagaWithProgressActions<void, MenuCategoryView[]>(loadMenuViews.type);

export function loadMenuViewsReducers(reducerFactory: IReducerFactory<CategoryAssignmentState>): IReducerFactory<CategoryAssignmentState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.loadingState)
        .onFulfilled((state, action) => {
            state.available = action.payload.result;
        })
        .build();
}

async function performLoad(_: void, context: SagaAppContext): Promise<MenuCategoryView[]> {
    return context.marketplaceApiClient.menuAdmin.getMenuCategoryViews();
}

export function createLoadMenuViewsSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: loadMenuViews.type,
        debounceInMilliseconds: 250
    });
};