import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { deletePricingModelSagaFactory } from "../featureActions/deletePricingModel";
import { loadSliceDataSagaFactory } from "../featureActions/loadSliceData";
import { upsertPricingModelSagaFactory } from "../featureActions/upsertPricingModel";

// Register this method in the root saga
export function createPricingDashboardSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        loadSliceDataSagaFactory(context),
        upsertPricingModelSagaFactory(context),
        deletePricingModelSagaFactory(context)
    ]
}