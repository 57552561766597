import { sum } from "lodash";
import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { isValue } from "../../../../../utils/valueHelper";
import { actionFactory, InventoryManagementState } from "../types";

export const updateInventoryAssignmentMaps = actionFactory.createAppAction("updateInventoryAssignmentMaps");

export function updateInventoryAssignmentMapsReducers(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forAction(updateInventoryAssignmentMaps)
        .addHandler((state, _) => {

            // Dictionary of inventory ids that are on menus directly
            const inventoryIdMap: { [key: number]: boolean } = {};
            state.menuAssignments.forEach(menuAssignment => {
                menuAssignment.inventoryIds.forEach(inventoryId => {
                    inventoryIdMap[inventoryId] = true;
                });
            });

            // Dictionary of products ids that are on menus directly
            const productIdMap: { [key: number]: boolean } = {};
            state.menuAssignments
                .flatMap(assignment => assignment.productIds)
                .forEach(productId => {
                    productIdMap[productId] = true;
                });

            // Assign each inventory record's menu assignments
            state.inventory.ids.forEach(inventoryId => {
                const inventory = state.inventory.entities[inventoryId];

                if (isValue(inventory)) {

                    inventory.onMenuViaProduct = inventory.productId ? 
                                    !!productIdMap[inventory.productId] && !inventory.excludeFromMarket : false;
                    inventory.onMenuDirectly = !!inventoryIdMap[inventory.id] && !inventory.excludeFromMarket;

                    inventory.calulatedQualityGradeValueId = inventory.qualityGradeId ?? inventory.qualityAssessmentGradeValueId;

                    const details = state.orderedQuantities[inventoryId];
                    if (isValue(details)) {
                        inventory.orderedQuantity = sum(details.map(detail => detail.quantity));
                    }
                    else {
                        inventory.orderedQuantity = 0;
                    }
                }
            });

        })
        .build();
}