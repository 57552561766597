import { PrimeIcons } from "primereact/api";
import { Column, ColumnProps } from "primereact/column";
import { DataTable, DataTableRowReorderParams } from "primereact/datatable";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { createActionColumnProps } from "../../../../utils/table/actionColumn";
import { createEditableTextColumnProps } from "../../../../utils/table/editableTextColumn";
import { createNumberColumnProps } from "../../../../utils/table/numberColumn";
import { createRowReorderColumnProps } from "../../../../utils/table/rowReorderColumn";
import { QualityMetricValue } from "../../models/metricValue";
import { deleteQualityMetricValue } from "../store/featureActions/deleteQualityMetricValue";
import { resequenceMetricValue } from "../store/featureActions/resequenceMetricValue";
import { upsertQualityMetricValue } from "../store/featureActions/upsertQualityMetricValue";

import { getIsSelectedMetricKindEditable, getMetricValueSaveState, getValuesForSelectedMetric } from "../store/selectors/editing";

export const QualityMetricValueTable: React.FC = () => {

    const dispatch = useDispatch();
    const metricValues = useAppSelector(getValuesForSelectedMetric);
    const saveState = useAppSelector(getMetricValueSaveState);
    const isEditable = useAppSelector(getIsSelectedMetricKindEditable);

    const onUpdateMetricValue = (model: QualityMetricValue) => {
        dispatch(upsertQualityMetricValue(model));
    }

    const columns: ColumnProps[] = [
        createRowReorderColumnProps({ header: "", style: { width: 30, maxWidth: 30 }, rowReorderIcon: PrimeIcons.ELLIPSIS_V }),        
        createEditableTextColumnProps<QualityMetricValue>({ header: "Name", field: "name", style: { minWidth: 180 }, sortable: false, disabled: !isEditable, onSubmit: onUpdateMetricValue }),
        createNumberColumnProps<QualityMetricValue>({ header: "Sequence", field: "sequence", style: { width: 100, maxWidth: 100 }, sortable: false }),
        createActionColumnProps<QualityMetricValue>({
            header: "",
            columnKey: "actions",
            style: { width: 50, maxWidth: 50 },
            actionHandler: (value) => {
                dispatch(deleteQualityMetricValue(value));
            },
            actionName: "",
            buttonIcon: PrimeIcons.TIMES,
            buttonClassName: "quality-metric-value-table-button",
            disabled: !isEditable
        }),
    ];

    const onRowReorder = (e: DataTableRowReorderParams) => {

        // Odd behavior from prime react DataTable. When dragging down, the index appears to be one based.
        // but when dragging up, the index appears to be zero based. -\o/-
        const dropIndex: number = e.dropIndex > e.dragIndex ? e.dropIndex - 1 : e.dropIndex;

        const dragTarget = metricValues[e.dragIndex];
        const dropTarget = metricValues[dropIndex];

        dispatch(resequenceMetricValue({
            newSequenceValue: dropTarget.sequence,
            entityId: dragTarget.id
        }));
    }

    return <DataTable
        size="small"
        disabled={!isEditable || saveState.isExecuting}
        dataKey="id"
        value={metricValues}
        scrollable        
        scrollHeight='flex'
        paginator
        rows={20}
        rowsPerPageOptions={[10, 20, 50]}
        alwaysShowPaginator={false} 

        onRowReorder={onRowReorder}
    >
        {columns.map(c => <Column key={c.columnKey ?? c.field} {...c} />)}
    </DataTable>

}