import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { FullyCentered } from "../../../components/containers/fullyCentered";
import { useAppSelector } from "../../../store/configureStore";
import { useMountEffect } from "../../../utils/useEffectHelper";
import { signOutCallbackRoute } from "../routes/authRoutes";
import { beginLogout } from "../store/featureActions/beginLogout";
import { getAuthenticationState } from "../store/selectors/authState";

export const BeginSignOut = () => {

    const dispatch = useDispatch();
    const authState = useAppSelector(getAuthenticationState);

    useMountEffect(() => {
        dispatch(beginLogout());
    });

    return authState.CurrentUser !== null 
    ? <FullyCentered inlineStyle={{ fontSize: "28px", height: "calc(90vh - 60px)" }}>
        Logging out...
    </FullyCentered>
    : <Redirect to={signOutCallbackRoute.path}/>
}