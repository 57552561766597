import { Load2Result, parallelLoad } from "../../../../../utils/promiseHelpers";
import { AssessmentTemplate } from "../../../models/assessmentTemplate";
import { QualityGradeKind } from "../../../models/gradeKind";
import { actionFactory } from "../types";

export const {

    loadSliceData,
    loadSliceDataReducer,
    loadSliceDataSagaFactory

} = actionFactory
    .withMappedName("loadSliceData")
    .createAsyncAction<void, Load2Result<AssessmentTemplate[], QualityGradeKind[]>>({        
        actionStatusSelector: state => state.loadingState,
        asyncCall: (_arg, context) => {            
            return parallelLoad(
                () => context.marketplaceApiClient.quality.getAssessmentTemplates(),
                () => context.marketplaceApiClient.quality.getQualityGradeKinds()
            );
        },
        onFulfilled: (state, action) => {
            state.templates = action.payload.result.result1;
            state.gradeKinds = action.payload.result.result2;
        }
    });