import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { isValue } from "../../../../../utils/valueHelper";
import { QualityGradeValue } from "../../../models/gradeValue";
import { getGradeKindEntities, getGradeValues } from "./data";

export const getAddQualityGradeKindStatus = (state: RootState) => state.qualityGradeKinds.addGradeKindState;
export const getAddQualityGradeKindVisible = (state: RootState) => state.qualityGradeKinds.addGradeKindState.isVisible;
export const getAddQualityGradeKindValue = (state: RootState) => state.qualityGradeKinds.addGradeKindState.editedValue;

export const getSelectedGradeKindId = (state: RootState) => state.qualityGradeKinds.selectedGradeKindId;

export const getSelectedGradeKind = createSelector(
    [
        getSelectedGradeKindId,
        getGradeKindEntities
    ],
    (selectedGradeKindId, allGradeKinds) => {
        if (!isValue(selectedGradeKindId)) return null;
        return allGradeKinds[selectedGradeKindId] ?? null;
    }
);

export const getGradeValueSaveState = (state: RootState) => state.qualityGradeKinds.gradeValueSaveState;

export const getIsSelectedGradeKindEditable = createSelector(
    [
        getSelectedGradeKindId,
        getGradeKindEntities
    ],
    (selectedGradeKindId, allGradeKinds) => {
        if (!isValue(selectedGradeKindId)) return false;
        const selectedGradeKind = allGradeKinds[selectedGradeKindId];
        return isValue(selectedGradeKind) && !isValue(selectedGradeKind.publishedDate);
    }
);

export const getValuesForSelectedGrade = createSelector(
    [
        getGradeValues,
        getSelectedGradeKindId
    ],
    (allValues, selectedGradeKindId) => {
        const emptySet: QualityGradeValue[] = [];
        if (!isValue(selectedGradeKindId)) return emptySet;
        return allValues
            .filter(value => value.gradeKindId === selectedGradeKindId)
            .sort((left, right) => left.sequence - right.sequence);
    }
);