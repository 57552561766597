import { createAction } from "@reduxjs/toolkit";
import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory, ManageProfileState, MarketplaceProfile } from "../types";

export const updateProfile = createAction<Partial<MarketplaceProfile>>(actionFactory.createActionName("updateProfile")); 

export const saveUserProfileSagaActions = actionFactory.createSagaWithProgressActions<Partial<MarketplaceProfile>, MarketplaceProfile>(updateProfile.type);

export function updateProfileReducers(reducerFactory: IReducerFactory<ManageProfileState>): IReducerFactory<ManageProfileState> {
    return reducerFactory       
        .forAction(updateProfile) 
        .addHandler((state, action) => {
            if (state.MarketplaceProfile === null) return;
            state.MarketplaceProfile = { ...state.MarketplaceProfile, ...action.payload };
        })
        .build()
        .forSagaWithProgress(saveUserProfileSagaActions)     
        .withActionStatus(state => state.savingState)           
        .onFulfilled((state, action) => {
            state.MarketplaceProfile = action.payload.result;            
        })
        .build();
}

async function saveUserProfile(_: Partial<MarketplaceProfile>, context: SagaAppContext): Promise<MarketplaceProfile> {
    
    const state = context.getState();
    if (state.manageProfile.MarketplaceProfile === null) throw new Error("Attempt to save marketplace profile without a profile loaded");

    return context.marketplaceApiClient.userProfile.saveMarketplaceProfile(state.manageProfile.MarketplaceProfile);
}

export function createSaveMarketplaceProfileSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: saveUserProfile,
            appContext: context,
            notificationActions: saveUserProfileSagaActions,
            errorTitle: "An error occured while saving the user profile"
        },
        actionPattern: updateProfile.type,
        debounceInMilliseconds: 750
    });
};