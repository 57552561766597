import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, CategoryManagementState } from "../types";

export const assignProducts = actionFactory.createAppAction("assignProducts");

export function assignSelectedAvailableProductsReducers(reducerFactory: IReducerFactory<CategoryManagementState>): IReducerFactory<CategoryManagementState> {
    return reducerFactory        
        .forAction(assignProducts)
        .addHandler((state, _) => {
            const selectedAvailableProductIds = state.categoryProductAssignment.selectedAvailableProducts.map(p => p.id);            
            selectedAvailableProductIds.forEach(id => {
                state.categoryProductAssignment.assignedProductMap[id] = id;
            });

            state.categoryProductAssignment.selectedAvailableProducts = [];
        })
        .build();
}