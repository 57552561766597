import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, CorporateEntityState } from "../types";

export const cancelEdit = actionFactory.createAppAction("cancelEdit");

export function cancelEditReducers(reducerFactory: IReducerFactory<CorporateEntityState>): IReducerFactory<CorporateEntityState> {
    return reducerFactory
        .forAction(cancelEdit)
        .addHandler((state, _) => {
            state.entityEditDialogVisible = false;
            state.entityEditValue = null;
        })
        .build();
}