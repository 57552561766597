import { isValue } from "./valueHelper";

export const currencyFormatOptions: Intl.NumberFormatOptions = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
};

export const percentFormatOptions: Intl.NumberFormatOptions = {
    style: "percent",    
    minimumFractionDigits: 2
};

export const formatAsCurrency = (value: number | null | undefined) => {
    if (!isValue(value)) return "";
    return value.toLocaleString(undefined, currencyFormatOptions);
}