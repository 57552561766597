import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { beginSaveAsReducer } from './featureActions/beginSaveAs';
import { cancelSaveAsReducer } from './featureActions/cancelSaveAs';
import { deleteUserTableSettingsReducer } from './featureActions/deleteUserTableSettings';
import { initializeViewReducer } from './featureActions/initializeView';
import { loadUserTableSettingsReducer } from './featureActions/loadUserTableSettings';
import { resetViewReducer } from './featureActions/resetView';
import { saveNewUserTableSettingsReducer } from './featureActions/saveNewUserTableSettings';
import { saveUserTableSettingsReducer } from './featureActions/saveUserTableSettings';
import { selectFirstTableSettingsReducer } from './featureActions/selectFirstTableSettings';
import { selectTableSettingsReducer } from './featureActions/selectTableSettings';
import { remountTableReducer } from './featureActions/remountTable';
import { updateTableStateReducer } from './featureActions/updateTableState';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';
import { requireTableRemountReducer } from './featureActions/requireTableRemount';
import { completeTableRemountReducer } from './featureActions/completeTableRemount';
import { clearRestoreSentinelReducer } from './featureActions/clearRestoreSentinel';

const slice = createSlice({
    name: sliceName, 
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(loadUserTableSettingsReducer)
            .add(initializeViewReducer)
            .add(updateTableStateReducer)
            .add(saveNewUserTableSettingsReducer)
            .add(selectTableSettingsReducer)
            .add(beginSaveAsReducer)
            .add(cancelSaveAsReducer)
            .add(deleteUserTableSettingsReducer)
            .add(selectFirstTableSettingsReducer)
            .add(saveUserTableSettingsReducer)
            .add(resetViewReducer)
            .add(remountTableReducer)
            .add(requireTableRemountReducer)
            .add(completeTableRemountReducer)
            .add(clearRestoreSentinelReducer);
            // Create your feature actions in ./featureActions and then add them to the reducer factory here
            // with .Add(<your-action-reducer-name>);
            
    }
});

export default slice.reducer;

export const { resetState } = slice.actions;