import { push } from "connected-react-router";
import { isValue } from "../../../../../utils/valueHelper";
import { orderEditorRoute } from "../../../../navigation/routes";
import { SalesOrder } from "../../../common/types";
import { salesOrderAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    createSalesOrder,
    createSalesOrderReducer,
    createSalesOrderSagaFactory

} = actionFactory
    .withMappedName("createSalesOrder")
    .createAsyncAction<void, SalesOrder>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (_arg, context) => {
            const customerId = context.getState().customerEditor.customer?.id;
            if (!isValue(customerId)) throw new Error("Cannot create a new order without a valid customer");
            return context.marketplaceApiClient.customer.createCustomerOrder(customerId);
        },
        onFulfilled: (state, action) => {
            salesOrderAdapter.upsertOne(state.customerOrders, action.payload.result);
        },
        successCallback: (_arg, result, context) => {
            context.dispatch(push(orderEditorRoute.createPath(result.id)))
        }
    });