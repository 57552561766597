import { useDispatch } from "react-redux";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { editQualityAssessmentCancel, editQualityAssessmentComplete } from "../store/featureActions/editQualityAssessment";
import { getQualityAssessmentModalEditState, getQualityAssessmentModalEditValue, getQualityAssessmentModalEditVisible } from "../store/selectors/editing";
import './index.css';
import { ModalAssessmentEditor } from "./modalAssessmentEditor";

export const QualityAssessmentDialog: React.FC = () => {

    const dispatch = useDispatch();

    const cancelEditing = () => {
        dispatch(editQualityAssessmentCancel());
    }
    
    const completeEditing = (value: { qualityAssessmentId: number }) => {
        dispatch(editQualityAssessmentComplete(value));
    }
    
    return <FormikModalEditor<{ qualityAssessmentId: number }>
        statusSelector={getQualityAssessmentModalEditState}
        visibleSelector={getQualityAssessmentModalEditVisible}
        valueSelector={getQualityAssessmentModalEditValue}
        onCancel={cancelEditing}
        onSave={completeEditing}
        closable={false}
        title="Edit Quality Assessment"
        className="quality-assessment-modal-dialog"
        contentClassName="quality-assessment-modal-dialog-content"
        cancelButtonClassname="quality-assessment-modal-dialog-cancel"
        okButtonClassname="quality-assessment-modal-dialog-confirm"
        okButtonLabel="Ok"
    >
        <ModalAssessmentEditor />
    </FormikModalEditor> 
}