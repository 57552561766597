import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { customerNoteAdapter, salesOrderAdapter } from "../state";

export const getCustomer = (state: RootState) => state.customerEditor.customer;

export const getCustomerLoadingState = (state: RootState) => state.customerEditor.loadingState;
export const getSaveCustomerNoteStatus = (state: RootState) => state.customerEditor.saveCustomerNoteStatus;

const customerNoteSelectors = customerNoteAdapter.getSelectors((state: RootState) => state.customerEditor.customerNotes);
export const getCustomerNoteById = customerNoteSelectors.selectById;
export const getCustomerNoteIds = createSelector(
    [
        customerNoteSelectors.selectAll
    ],
    (allNotes) => {
        return allNotes
            .slice()
            .sort((left, right) => right.createdUtc.toMillis() - left.createdUtc.toMillis())
            .map(note => note.id)
    }
);

const customerOrderSelectors = salesOrderAdapter.getSelectors((state: RootState) => state.customerEditor.customerOrders);
export const getCustomerOrders = createSelector(
    [
        customerOrderSelectors.selectAll
    ],
    (allNotes) => {
        return allNotes
            .slice()
            .sort((left, right) => right.createdUtc.toMillis() - left.createdUtc.toMillis())
    }
);


