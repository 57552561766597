import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { completeAssessmentSagaFactory } from "../featureActions/completeAssessment";
import { deleteAssessmentValueSagaFactory } from "../featureActions/deleteAssessmentValue";
import { editQualityAssessmentSagaFactory } from "../featureActions/editQualityAssessment";
import { loadReferenceDataSagaFactory } from "../featureActions/loadReferenceData";
import { loadSliceDataSagaFactory } from "../featureActions/loadSliceData";
import { reopenAssessmentSagaFactory } from "../featureActions/reopenAssessment";
import { saveAssessmentValueSagaFactory } from "../featureActions/saveAssessmentValue";

// Register this method in the root saga
export function createQualityAssessmentSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        loadSliceDataSagaFactory(context),
        loadReferenceDataSagaFactory(context),
        saveAssessmentValueSagaFactory(context),
        deleteAssessmentValueSagaFactory(context),
        completeAssessmentSagaFactory(context),
        reopenAssessmentSagaFactory(context),
        editQualityAssessmentSagaFactory(context)
    ]
}