import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { createCustomerUrl } from "../../../../utils/safeUrlHelper";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { isValue } from "../../../../utils/valueHelper";
import { loadCustomerProfile } from "../../../company/profile/store/featureActions/loadCustomerProfile";
import { getCompanyProfile } from "../../../company/profile/store/selectors/companyProfile";
import { openMenuInNewWindow } from "../../../navigation/helper";
import { getMenuForEditing } from "../store/selectors/menu";

export const MenuUrlHelper: React.FC = () => {

    const dispatch = useDispatch();
    const companyProfile = useAppSelector(getCompanyProfile);
    const menu = useAppSelector(getMenuForEditing);

    useMountEffect(() => {
        dispatch(loadCustomerProfile());
    });

    const isValid = isValue(companyProfile.urlName);

    const menuUrl: string = isValid
        ? createCustomerUrl(menu)
        : "";

    const onViewmenu = () => {
        openMenuInNewWindow(menu);
    }
    return <div className="p-d-flex p-ai-center p-py-2">
        <label className="p-mr-5">Menu Url</label>
        <Button label={menuUrl} className="p-button-link" onClick={ onViewmenu } disabled={!isValid} />
        
    </div>
}