import { Divider } from "primereact/divider"
import { useMediaQuery } from "react-responsive";
import { smallDeviceQuery } from "../../../responsiveconfiguration";
import { SearchBar } from "./searchBar"
import { SearchResults } from "./searchResults"

export const SearchDashboard: React.FC = () => {
    const isSmallDevice = useMediaQuery(smallDeviceQuery);

    return <div className="main-content-panel" >
        <SearchBar />
        {!isSmallDevice && <Divider />}
        <SearchResults />
    </div>
}