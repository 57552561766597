import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { isNonEmpty, isValue } from "../../../../../utils/valueHelper";
import { MixedMedia } from "../../../../commonModels/mixedMedia";
import { productVideoAdapter } from "../state";

const videoSelectors = productVideoAdapter.getSelectors((state: RootState) => state.productManagement.editVideos);

export const getProductMedia = createSelector(
    [
        (state: RootState) => state.productManagement.editImages,
        videoSelectors.selectAll
    ],
    (images, videos) => {
        const mixedMediaList: MixedMedia[] = [];

        mixedMediaList.push(...images.slice().sort((left, right) => left.id - right.id));
        mixedMediaList.push(...videos.slice().sort((left, right) => left.id - right.id));

        return mixedMediaList;
    }
)

export const getEditImagesProduct = (state: RootState) => state.productManagement.editMediaEntity;

export const getEditMediaProductName = (state: RootState) => {
    const product = state.productManagement.editMediaEntity;
    if (!isValue(product)) return "";

    const marketingName = product.marketingName;
    if (isNonEmpty(marketingName)) return marketingName

    const metrcName = product.metrcName;
    if (isNonEmpty(metrcName)) return metrcName
   
    return "";
}

export const getMenuThumbnailImageId = (state: RootState) => state.productManagement.editMediaEntity?.menuThumbnailId;

export const getMediaLoadState = (state: RootState) => state.productManagement.mediaLoadState;

export const getAddVideoState = (state: RootState) => state.productManagement.videoSaveState;

export const getAddVideoVisible = (state: RootState) => state.productManagement.addVideoState.isVisible;

export const getAddVideoModel = (state: RootState) => state.productManagement.addVideoState.editedValue;

