import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { actionFactory, ProductManagementState } from "../../types";

export const setPricingModelToAssign = actionFactory.createAppAction<number | null>("setPricingModelToAssign");

export function setPricingModelToAssignReducer(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forAction(setPricingModelToAssign)
        .addHandler((state, action) => {
            state.bulkPricingModelAssignmentState.pricingModelIdToAssign = action.payload;
        })
        .build();
}