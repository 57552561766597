import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { isNumber } from "../../../../../utils/valueHelper";
import { createCategoryProductAssignmentInitialState } from "../state";
import { actionFactory, CategoryManagementState } from "../types";

export const saveProductAssignments = actionFactory.createAppAction("saveProductAssignments");

const sagaActions = actionFactory.createSagaWithProgressActions<void, Response>(saveProductAssignments.type);

export function saveProductAssignmentsReducers(reducerFactory: IReducerFactory<CategoryManagementState>): IReducerFactory<CategoryManagementState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.categoryProductAssignment.loadingState)
        .onFulfilled((state, _) => {
            state.categoryProductAssignment = createCategoryProductAssignmentInitialState();
        })
        .build();
}

async function performAction(_arg: void, context: SagaAppContext) {
    const state = context.getState();

    if (state.categoryManagement.categoryProductAssignment.category === undefined) throw new Error("Cannot save product assignments without a valid category selected");
    const categoryId = state.categoryManagement.categoryProductAssignment.category.id;
    const assignedProductMap = state.categoryManagement.categoryProductAssignment.assignedProductMap;

    const assignedProductIds = Object
        .values(assignedProductMap)
        .filter(isNumber)
    
    return context.marketplaceApiClient.menuAdmin.setProductIdsForCategory(categoryId, assignedProductIds);
}

export function createSaveProductAssignmentsSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: saveProductAssignments.type,
        debounceInMilliseconds: 250
    });
};