import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { createFilteredDateColumnProps } from "../../../../utils/table/filteredDateColumn";
import { mapColumns } from "../../../../utils/table/mapPropsToColumns";
import { createMultiActionColumnProps } from "../../../../utils/table/multiActionColumn";
import { createTextColumnProps } from "../../../../utils/table/stringColumn";
import { TypedCommandMenuItem } from "../../../../utils/table/typedMenuItem";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { hasValue } from "../../../../utils/valueHelper";
import { editTermsOfService } from "../store/featureActions/editTermsOfService";
import { inactivateTermsOfService } from "../store/featureActions/inactivateTermsOfService";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { publishTermsOfService } from "../store/featureActions/publishTermsOfService";
import { getEditedTermsOfServiceId, getTermsOfServiceList } from "../store/selectors/termsOfService";
import { TermsOfService } from "../store/types";
import { TermsOfServiceEditor } from "./termsEditor";

export const TermsOfServiceAdminDashboard: React.FC = () => {

    const dispatch = useDispatch();
    const termsOfService = useAppSelector(getTermsOfServiceList);
    const editedTermsOfServiceId = useAppSelector(getEditedTermsOfServiceId);    
    const tableRef = useRef<DataTable>(null);

    useMountEffect(() => {
        dispatch(loadSliceData());
    });

    const onEditClicked = (value: TermsOfService) => {
        dispatch(editTermsOfService(value.id));
    }

    const createTermsOfService = () => {
        dispatch(editTermsOfService(null));
    };

    const onHideTermsEditor = () => { };

    const actionColumnItems: TypedCommandMenuItem<TermsOfService>[] = [
        {
            label: "Publish",
            command: (value) => {
                dispatch(publishTermsOfService(value))
            },
            disabled: (value) => hasValue(value.inactivatedUtc) || hasValue(value.publishedUtc)
        },
        {
            label: "Inactivate",
            command: (value) => {
                dispatch(inactivateTermsOfService(value))
            },
            disabled: (value) => hasValue(value.inactivatedUtc)
        }
    ];
    
    const columns: ColumnProps[] = [
        createMultiActionColumnProps<TermsOfService>({ columnKey: "action-column", header: "Actions", actionName: "Edit", actionHandler: onEditClicked, icon: PrimeIcons.PENCIL, menuItems: actionColumnItems, style: { width: "140px"}  }),
        createTextColumnProps<TermsOfService>({ field: "stateAbbreviation", header: "State", filter: true, sortable: true, style: { width: "120px"}}),        
        createFilteredDateColumnProps<TermsOfService>({ field: "publishedUtc", header: "Published" }),
        createFilteredDateColumnProps<TermsOfService>({ field: "inactivatedUtc", header: "Inactivated" })
    ];

    return <div>
        <Dialog
            header={"Edit Terms Of Service"}
            position="center"
            draggable={false}
            keepInViewport={true}
            closeOnEscape={false}
            closable={false}
            visible={editedTermsOfServiceId !== undefined}
            onHide={onHideTermsEditor}
        >
            {editedTermsOfServiceId !== undefined && <TermsOfServiceEditor termsOfServiceId={editedTermsOfServiceId} />}
        </Dialog>
        <Card className="full-height-content-panel" title="Manage Terms of Service">

            <Button label="Create New Terms" className="p-my-2" onClick={createTermsOfService} />

            <DataTable
                ref={tableRef}
                value={termsOfService}
                className="p-datatable-sm"
                paginator
                sortField="stateAbbreviation"
                scrollable
                scrollDirection="both"
                scrollHeight='calc(100vh - 600px)'
                sortOrder={1}
                rows={20}
                rowsPerPageOptions={[10, 20, 50]}
            >
                {mapColumns(columns)}
            </DataTable>


        </Card>
    </div>
}