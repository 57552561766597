import { push } from "connected-react-router";
import { QualityTemplateEditorRoute } from "../../../../navigation/routes";
import { AssessmentTemplate } from "../../../models/assessmentTemplate";
import { actionFactory } from "../types";

export const {

    copyTemplate,
    copyTemplateReducer,
    copyTemplateSagaFactory

} = actionFactory
    .withMappedName("copyTemplate")
    .createAsyncAction<number, AssessmentTemplate>({        
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {            
            return context.marketplaceApiClient.quality.copyAssessmentTemplate(arg);
        },
        onFulfilled: (_state, _action) => {
        },
        successCallback: (_arg, result, context) => {
            context.dispatch(push(QualityTemplateEditorRoute.createPath(result.id)));
        }
    });