import { DateTime } from "luxon";
import { ErrorDisplayType } from "../../../../../store/factory/sagaWithProgress/types";
import { isValue } from "../../../../../utils/valueHelper";
import { isSalesOrderItem, SalesOrderItem } from "../../../common/types";
import { salesOrderItemAdapter } from "../state";
import { actionFactory, SalesOrderItemEditModel } from "../types";

export const {

    editOrderItemBegin,
    editOrderItemCancel,
    editOrderItemComplete,
    editOrderItemReducers,
    editOrderItemSagaFactory

} = actionFactory
    .withMappedName("editOrderItem")
    .createModal<SalesOrderItemEditModel, SalesOrderItem | null, SalesOrderItem>({
        beforeBeginEdit: (state, action) => {

            const inventoryId = action.payload?.inventoryId;
            state.selectedInventory = isValue(inventoryId)
                ? state.orderInventory.entities[inventoryId] ?? null
                : null;

            state.orderedQuantities = [];            

        },
        modelFactory: (arg, state) => {
            if (arg !== null) return { ...arg, remainingQuantity: null };
            return {
                id: 0,
                salesOrderId: state.salesOrder?.id,
                createdUtc: DateTime.now(),
                modifiedUtc: DateTime.now(),
                price: null,
                remainingQuantity: null,
                inventoryId: null,
                inventoryName: null
            };
        },
        selector: (state) => state.orderItemEditState,
        onCompleteEdit: (value, context) => {

            const { remainingQuantity, ...rest } = value;

            if (!isSalesOrderItem(rest)) throw new Error("Cannot save order item without all fields defined");
            return context.marketplaceApiClient.customer.upsertOrderItem(rest);
        },
        onFulfilled: (state, action) => {
            salesOrderItemAdapter.upsertOne(state.salesOrderItems, action.result);
        },
        errorDisplay: ErrorDisplayType.None
    });