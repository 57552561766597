import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/rootReducer";
import { IsBuyer, IsNodeAdmin, IsSeller, IsWholesaleAdmin, IsWholesaleUser, isSecurityRole } from "../../../authorization/roles";

export const getUserRoles = createSelector(
    [
        (state: RootState) => {
            return state.authentication.CurrentUser?.accessTokenData.role;
        }
    ],
    (roles) => {
        if (roles === undefined) return [];

        if (typeof (roles) === "string") {
            return [roles].filter(isSecurityRole);            
        }
        
        return roles.filter(isSecurityRole);
    })

export const getUserIsSeller = createSelector(
    [
        getUserRoles
    ],
    (roles) => {
        return IsSeller(roles);
    })

export const getUserIsBuyer = createSelector(
    [
        getUserRoles
    ],
    (roles) => {
        return IsBuyer(roles);
    })

export const getUserIsNodeAdmin = createSelector(
    [
        getUserRoles
    ],
    (roles) => {
        return IsNodeAdmin(roles);
    })

export const getUserIsMarketplaceUser = createSelector(
    [
        getUserRoles
    ],
    (roles) => {
        console.log("getUserIsMarketplaceUser", roles);
        return IsWholesaleUser(roles);
    })

export const getUserIsMarketplaceAdmin = createSelector(
    [
        getUserRoles
    ],
    (roles) => {
        return IsWholesaleAdmin(roles);
    })


