import clsx from 'clsx';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { smallDeviceQuery } from '../../features/responsiveconfiguration';

export const CenterContentResponsivePanel: React.FC = ({children}) => {

    const isSmallDevice = useMediaQuery(smallDeviceQuery);

    return <div className={clsx("p-d-flex p-jc-center", {
        "p-flex-column": isSmallDevice,
        "p-ai-top": !isSmallDevice,
        "p-ai-middle": isSmallDevice
    })}>
        {children}
    </div>
}