import { Button } from "primereact/button";
import { MouseEvent } from "react";
import { useDispatch } from "react-redux";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { ConstraintNodeKind } from "../../models/constraintNode";
import { upsertTemplateConstraintNode } from "../store/featureActions/upsertTemplateConstraintNode";
import { getGradeValueById, getIsLowestRankGrade, getRootConstraintNodeForGradeValue } from "../store/selectors/data";
import { getIsEditable } from "../store/selectors/editing";

export interface ConstraintListHeaderProps {
    gradeValueId: number;
}

export const ConstraintListHeader: React.FC<ConstraintListHeaderProps> = ({ gradeValueId }) => {

    const dispatch = useDispatch();
    const isEditable = useAppSelector(getIsEditable);
    const gradeValue = useAppSelector(state => getGradeValueById(state, gradeValueId));
    const rootConstraint = useAppSelector(state => getRootConstraintNodeForGradeValue(state)(gradeValueId))
    const isLowestRankGrade = useAppSelector(state => getIsLowestRankGrade(state)(gradeValueId))
    
    const onAddConstraint = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        dispatch(upsertTemplateConstraintNode({
            gradeValueId: gradeValueId,
            node: {
                comparison: null,
                kind: ConstraintNodeKind.And,
                id: 0,
                metricKindId: null,
                metricValueIds: [],
                parentNodeId: null,
                rootNodeId: null,
                children: []
            }
        }));
    }

    return <FlexRowContainer className="template-grade-constraint-header">

        <div className="template-grade-constraint-header-title">{`Grade ${gradeValue?.name}`}</div>

        {
            (!isValue(rootConstraint) && !isLowestRankGrade) &&
            <Button
                disabled={!isEditable}
                onClick={onAddConstraint}
                label="Add Logic Block"
                className="template-grade-constraint-header-extra"
            />
        }

    </FlexRowContainer>
}