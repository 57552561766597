import clsx from 'clsx';
import { PrimeIcons } from 'primereact/api';
import './noApplicationAccess.css';

export const NoApplicationAccess: React.FC = () => {

    return <div className="p-d-flex p-flex-column p-ai-center p-jc-center p-mt-6">

        <i className={clsx(PrimeIcons.LOCK, "p-mb-4")} style={{ 'fontSize': '3em' }}></i>
        
        <div className="no-access-title p-mb-4">
            Your Node account is valid, but you do not have permission to access this application.
        </div>

        <div className="no-access-title">
            Contact your Node account administrator to gain access to this application.
        </div>
    </div>
}