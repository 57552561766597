import { TabPanel, TabView } from 'primereact/tabview';
import { useAppSelector } from "../../../../store/configureStore";
import { getVendorMenuSections } from "../store/selectors/vendorMenu";
import { MenuSection } from "./menuSection";
import './sectionList.css';

export const SectionList: React.FC = () => {

    const menuSections = useAppSelector(getVendorMenuSections);

    // const itemTemplate = (section: VendorMenuSection, _: DataViewLayoutType): React.ReactNode => {
    //     return <MenuSection key={section.id} sectionId={section.id} />
    // }



    return <TabView className='node-menu-tab'>
        {
            menuSections.map(section => <TabPanel
                key={section.id}
                header={section.name}
                headerClassName="node-menu-tab"
            >
                <MenuSection key={section.id} sectionId={section.id} />
            </TabPanel>)
        }
    </TabView>

}