import { menuSectionAdapter } from "../state";
import { actionFactory, MenuSection } from "../types";

export const {

    loadMenuSections,
    loadMenuSectionsReducer,
    loadMenuSectionsSagaFactory

} = actionFactory
.withMappedName("loadMenuSections")
.createAsyncAction<{menuId: number}, MenuSection[]>({    
    actionStatusSelector: state => state.menuSectionLoadState,
    asyncCall: (arg, context) => {
        return context.marketplaceApiClient.menuAdmin.getMenuSections(arg.menuId);
    },
    onFulfilled: (state, action) => {
        menuSectionAdapter.setAll(state.menuSections, action.payload.result);        
    },
    debounceInMilliseconds: 250
});