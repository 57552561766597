import { isValue } from "../../../../../utils/valueHelper";
import { categoryAdapter, menuSectionAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    deleteCategory,
    deleteCategoryReducer,
    deleteCategorySagaFactory

} = actionFactory
    .withMappedName("deleteCategory")
    .createAsyncAction<{ categoryId: number }, Response>({
        actionStatusSelector: state => state.addCategorySaveState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.menuAdmin.deleteCategory(arg.categoryId);
        },
        onFulfilled: (state, action) => {
            categoryAdapter.removeOne(state.categories, action.payload.arg.categoryId);

            state.menuSections.ids.forEach(id => {
                const menuSection = state.menuSections.entities[id];
                if (!isValue(menuSection)) return;

                menuSectionAdapter.updateOne(state.menuSections, {
                    id: id,
                    changes: {
                        categoryIds: menuSection.categoryIds.filter(id => id !== action.payload.arg.categoryId)
                    }
                });
            });
        },
        debounceInMilliseconds: 100
    });