import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { NotAuthorized } from "../../../../components/notAuthorized";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { getIsAuthenticated } from "../../../authentication/store/selectors/isAuthenticated";
import { smallDeviceQuery } from "../../../responsiveconfiguration";
import { hideVendorContactDetails } from "../store/featureActions/hideVendorContactDetails";
import { getIsLoading } from "../store/selectors/loading";
import { getShowVendor, getVendor } from "../store/selectors/vendorDetails";
import { resetState } from "../store/slice";
import './index.css';

export const ContactSellerDetails: React.FC = () => {

    const dispatch = useDispatch();
    const showVendor = useAppSelector(getShowVendor);
    const vendor = useAppSelector(getVendor)
    const loadingState = useAppSelector(getIsLoading);
    const isLoggedIn = useAppSelector(getIsAuthenticated);
    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    
    useMountEffect(() => {
        return () => { dispatch(resetState()); }
    });

    const onHideVendorDetails = () => {
        dispatch(hideVendorContactDetails());
    };

    return <Dialog
        onHide={onHideVendorDetails}
        visible={showVendor}
        showHeader={false}
        closable={true}
        dismissableMask={true}   
        className="contact-vendor-dialog"     
        contentClassName="contact-vendor-dialog-content"
    >
        <OverlayWaitDisplay
            isWaiting={loadingState.isExecuting}
        >
            {
                isLoggedIn
                    ? <Card
                        title={vendor?.companyName}
                        className="contact-vendor-card"                        
                    >
                        <Divider />
                        <h3>Email</h3>
                        <div>{vendor?.contactEmail}</div>

                        <Divider />
                        <h3>Phone</h3>
                        <div>{vendor?.contactPhone}</div>

                        <Divider />
                        <h3>Website</h3>
                        <a href={vendor?.contactUrl} target="_blank" rel="noreferrer">{vendor?.companyName}</a>

                        <Divider />
                        <h3>Mailing List</h3>
                        <a href={vendor?.subscriptionUrl} target="_blank" rel="noreferrer">Subscribe Now</a>

                        <Divider />
                        <div className="p-d-flex p-jc-end">
                            <Button label="Ok" onClick={onHideVendorDetails} />
                        </div>

                    </Card>
                    : <div>
                        <Divider />                        

                        <div style={{ height: isSmallDevice ? undefined : "440px"}}>
                            <NotAuthorized 
                                loginRequiredTitle="You must be logged in to view vendor contact information."
                            />
                        </div>

                        <Divider />

                        <div className="p-d-flex p-jc-end p-pr-2">
                            <Button label="Ok" onClick={onHideVendorDetails} />
                        </div>

                    </div>
            }
        </OverlayWaitDisplay>
    </Dialog>

}