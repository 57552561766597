import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { categoryAdapter } from "../state";
import { actionFactory, MenuAdminState } from "../types";

export const editCategoryName = actionFactory.createAppAction<{id: number, name: string}>("editCategoryName");

export function editCategoryNameReducers(reducerFactory: IReducerFactory<MenuAdminState>): IReducerFactory<MenuAdminState> {
    return reducerFactory        
        .forAction(editCategoryName)
        .addHandler((state, action) => {
            categoryAdapter.updateOne(state.categories, {
                id: action.payload.id,
                changes: {
                    name: action.payload.name
                }
            });
        })
        .build();
}