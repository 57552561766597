import { PrimeIcons } from "primereact/api";
import { CSSProperties } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { PopUpMenuButton } from "../../../../components/popMenuButton";
import { useAppSelector } from "../../../../store/configureStore";
import { TypedMenuItem } from "../../../../utils/table/typedMenuItem";
import { smallDeviceQuery } from "../../../responsiveconfiguration";
import { setSort } from "../store/featureActions/setSort";
import { getSortSetting } from "../store/selectors/sort";
import { SortDirection, SortField } from "../../common/types";

export const SortPopOver: React.FC = () => {

    const dispatch = useDispatch();
    const currentSortSetting = useAppSelector(getSortSetting);
    const isSmallDevice = useMediaQuery(smallDeviceQuery);

    const buttonStyle: CSSProperties = {
        background: 'white',
        color: 'black',
        fontSize: isSmallDevice ? "large" : "x-large"
    }

    const selectedMenuStyle: CSSProperties = { backgroundColor: "lightgrey" }
    const notSelectedMenuStyle: CSSProperties = {}

    enum SortDescriptionType {
        Alphabetical,
        Numeric
    }
    const createSortMenuItems = (sortField: SortField, description: SortDescriptionType): TypedMenuItem<string>[] => {
        return [
            {
                label: `${sortField} (${description === SortDescriptionType.Alphabetical ? "A to Z" : "Low to High"})`,
                icon: PrimeIcons.SORT_AMOUNT_UP,
                command: _ => { dispatch(setSort({ sortField: sortField, direction: SortDirection.Ascending })) },
                style: currentSortSetting.sortField === sortField && currentSortSetting.direction === SortDirection.Ascending ? selectedMenuStyle : notSelectedMenuStyle
            },
            {
                label: `${sortField} (${description === SortDescriptionType.Alphabetical ? "Z to A" : "High to Low"})`,
                icon: PrimeIcons.SORT_AMOUNT_DOWN,
                command: _ => { dispatch(setSort({ sortField: sortField, direction: SortDirection.Descending })) },
                style: currentSortSetting.sortField === sortField && currentSortSetting.direction === SortDirection.Descending ? selectedMenuStyle : notSelectedMenuStyle
            }
        ]
    }

    const menuItems: TypedMenuItem<string>[] = [
        ...createSortMenuItems(SortField.Name, SortDescriptionType.Alphabetical),
        ...createSortMenuItems(SortField.Strain, SortDescriptionType.Alphabetical),
        ...createSortMenuItems(SortField.Quantity, SortDescriptionType.Numeric),
        ...createSortMenuItems(SortField.Price, SortDescriptionType.Numeric),
        ...createSortMenuItems(SortField.THC, SortDescriptionType.Numeric)
    ];

    return <PopUpMenuButton    
        id="sort-popup-menu"
        menuItems={menuItems}
        value={''}
        menuStyle={{ width: "250px" } }
        buttonStyle={buttonStyle}
        icon={PrimeIcons.SORT_ALT}
    />
}