import { isValue } from "../../../../../utils/valueHelper";
import { mergeViewDefintionWithTableState } from "../logic/columnHelper";
import { deserializeTableState } from "../logic/settingsSerialization";
import { viewSettingsAdapter } from "../state";
import { actionFactory, ViewSettings, TableState } from "../types";

export const {

    selectTableSettings,
    selectTableSettingsReducer

} = actionFactory
    .withMappedName("selectTableSettings")
    .createAction<{ viewId: string, id: number }>((state, action) => {
        const settings = state.viewSettings.entities[action.payload.viewId];
        if (!isValue(settings)) return;

        const userSettings = state.userTableSettings.entities[action.payload.id];
        if (!isValue(userSettings)) return;

        const deserializedTableState: TableState = deserializeTableState(userSettings.settings);

        const viewColumnDefinitions = state.viewDefinitions.entities[action.payload.viewId];        
        if (isValue(viewColumnDefinitions)) {
            mergeViewDefintionWithTableState(viewColumnDefinitions, deserializedTableState);
        }

        const mergedTableSettings: ViewSettings = { 
            id: settings.id, 
            selectedUserTableSettings: userSettings.id,            
            currentState: deserializedTableState,
            remountState: settings.remountState,
            restoreSentinel: false
        };
        
        viewSettingsAdapter.upsertOne(state.viewSettings, mergedTableSettings)
    });