import { isValue } from "../../../../../utils/valueHelper";
import { EntityImage } from "../../../../commonModels/entityImage";
import { inventoryImageAdapter } from "../state";
import { actionFactory } from "../types";

interface ThumbnailLoadResult {
    remainingInventoryIds: number[];
    thumbnails: EntityImage[];
}

interface LoadSectionThumbnailsRequest {
    sectionId: number;
    inventoryIds?: number[];
}

export const {

    loadSectionThumbnails,
    loadSectionThumbnailsReducer,
    loadSectionThumbnailsSagaFactory

} = actionFactory
    .withMappedName("loadSectionThumbnails")
    .createAsyncAction<LoadSectionThumbnailsRequest, ThumbnailLoadResult>({
        actionStatusSelector: state => state.inventoryImageLoadState,
        asyncCall: (arg, context) => {
            const liveMenuState = context.getState().liveMenu;
            const inventoryList = liveMenuState.sectionInventoryLists.entities[arg.sectionId];
            if (!isValue(inventoryList)) throw new Error("Attempt to load inventory thumbnails for menu section that was not availaible");

            const neededIds = !isValue(arg.inventoryIds)
                ? inventoryList
                    .inventory
                    .filter(inv => liveMenuState.inventoryImages.ids[inv.id] === undefined)
                    .map(inv => inv.id)
                : arg
                    .inventoryIds
                    .filter(id => liveMenuState.inventoryImages.ids[id] === undefined);                    

            const idsForThisBatch = neededIds.slice(0, 200);
            const remainingIds = neededIds.slice(200);
            const thumbnailPromise = context.marketplaceApiClient.liveMenu.getInventoryThumbnails({ inventoryIds: idsForThisBatch });

            return thumbnailPromise.then(thumbnails => ({
                remainingInventoryIds: remainingIds,
                thumbnails: thumbnails
            }));
        },
        onFulfilled: (state, action) => {
            inventoryImageAdapter.upsertMany(state.inventoryImages, action.payload.result.thumbnails);
        },
        successCallback: (arg, result, context) => {
            if (result.remainingInventoryIds.length > 0) {
                context.dispatch(loadSectionThumbnails({ sectionId: arg.sectionId, inventoryIds: result.remainingInventoryIds }));
            }
        }
    });