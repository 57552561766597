import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { isValue } from "../../../../../../utils/valueHelper";
import { actionFactory, InventoryManagementState } from "../../types";

export const beginBulkCategoryAssignment = actionFactory.createAppAction("beginBulkCategoryAssignment");

export function beginBulkCategoryAssignmentReducers(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forAction(beginBulkCategoryAssignment)
        .addHandler((state, _) => {
            state.bulkCategoryAssignmentState.parentModels = state
                .selectedInventoryIds
                .map(id => state.inventory.entities[id])
                .filter(isValue);
        })
        .build();
}