import { Message } from "primereact/message";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FormikEditorClassStyles } from "../../../../components/formik/classNameAttributes";
import { DropDownField } from "../../../../components/formik/dropDownField";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { useAppSelector } from "../../../../store/configureStore";
import { AssessmentTemplate } from "../../models/assessmentTemplate";
import { assessmentTemplateValidationSchema } from "../../validations/assessmentTemplateValidations";
import { changeGradeKindCancel, changeGradeKindComplete } from "../store/featureActions/changeGradeKind";
import { getQualityGradeKinds } from "../store/selectors/data";
import { getChangeGradeKindState, getChangeGradeKindValue, getChangeGradeKindVisible } from "../store/selectors/editing";

export const ChangeGradeKindDialog: React.FC = () => {

    const dispatch = useDispatch();
    const gradeKinds = useAppSelector(getQualityGradeKinds);

    const onSave = (values: AssessmentTemplate): void => {
        dispatch(changeGradeKindComplete(values));
    };

    const onCancel = (): void => {
        dispatch(changeGradeKindCancel());
    }

    const fieldClassNames: FormikEditorClassStyles = {
        containerClassName: "edit-template-field-container",
        inputClassName: "edit-template-field-input",
        labelClassName: "edit-template-field-label"
    }

    return <FormikModalEditor<AssessmentTemplate>
        statusSelector={getChangeGradeKindState}
        valueSelector={getChangeGradeKindValue}
        visibleSelector={getChangeGradeKindVisible}
        onSave={onSave}
        onCancel={onCancel}        
        title="Change Grade Kind"
        validationSchema={assessmentTemplateValidationSchema}
    >
        <FlexColumnContainer gap="20px">

            <Message severity="warn" text="Changing grade kind will remove all current constraints."></Message>

            <DropDownField<AssessmentTemplate, number>
                availableValues={gradeKinds.map(gradeKind => ({ label: gradeKind.name, value: gradeKind.id }))}
                fieldName="gradeKindId"
                defaultLabel="Grade Kind"                
                allowClear={false}
                {...fieldClassNames}
            />

        </FlexColumnContainer>

    </FormikModalEditor>
}