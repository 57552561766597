import { Component } from "react";
import './classPrintWrapper.css';

export class HiddenClassPrintWrapper extends Component {
	render() {
		return <div className="hidden-print-container">
			{this.props.children}
		</div>;
	}
}

export class ClassPrintWrapper extends Component {
	render() {
		return <div>
			{this.props.children}
		</div>;
	}
}