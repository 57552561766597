import { actionFactory } from "../../types";

export const {
    deleteNewBanner,
    deleteNewBannerReducer
} = actionFactory
    .withMappedName("deleteNewBanner")
    .createAction<void>((state, _) => {
        state.bannerState.newImagePreview = undefined;
    });
