import { productNotesAdapter } from "../../state";
import { actionFactory, ProductNote } from "../../types";

export const {

    upsertProductNote,
    upsertProductNoteReducer,
    upsertProductNoteSagaFactory

} = actionFactory
    .withMappedName("upsertProductNote")
    .createAsyncAction<ProductNote, ProductNote>({
        actionStatusSelector: state => state.productNoteSaveStatus,
        onPending: (state, _action) => {
            state.productNoteSaveStatus.hasExecuted = false;
        },
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.productManagement.upsertProductNote(arg);
        },
        onFulfilled: (state, action) => {
            productNotesAdapter.upsertOne(state.editProductNotes, action.payload.result);            
        }
    });