import { NotAuthorizedAction } from "../../../../../store/factory/sagaWithProgress/types";
import { actionFactory } from "../types";

export const {

    loadLicenses,
    loadLicensesReducer,
    loadLicensesSagaFactory

} = actionFactory
    .withMappedName("loadLicenses")
    .createAsyncAction<string, string[]>({
        actionStatusSelector: state => state.vendorMenuLoadingState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.liveMenu.getVendorLicenses(arg);
        },
        onFulfilled: (state, action) => {
            state.licenses = action.payload.result;
        },
        notAuthorizedAction: NotAuthorizedAction.NotAuthorizedEvent
    });