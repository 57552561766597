import { EntityState } from "@reduxjs/toolkit";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ModalEditState } from "../../../../store/factory/modalEdit/modalEdit";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { Contact, Customer, CustomerNote, SalesOrder } from "../../common/types";

export const sliceName: string = "customer-editor";

export const actionFactory = new ActionFactory<CustomerEditorState>(sliceName);

export interface CustomerEditorState {
    loadingState: ActionStatus;
    saveCustomerNoteStatus: ActionStatus;
    customer: Customer | null;
    customerNotes: EntityState<CustomerNote>;
    customerOrders: EntityState<SalesOrder>;    
    customerContacts: EntityState<Contact>;
    editContactState: ModalEditState<Contact>;
}