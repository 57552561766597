import UserProfile from "../marketplaceUserProfile/api";
import { UserAuthState } from "../authentication/store/types";
import Diagnostics from "../diagnostics/api";
import ApiClient from "./apiClient";
import SyncAdmin from "../admin/api";
import Alerts from "../alerts/api";
import IdentityVerification from "../identityVerification/api";
import CompanyApi from "../company/api";
import ProductManagement from "../inventorySetup/api/product";
import { TermsOfServiceAdmin } from "../nodeAdmin/termsOfService/api";
import { AcceptTermsOfService } from "../initialSetup/acceptTermsOfService/api";
import InventoryManagement from "../inventorySetup/api/inventory";
import MenuAdmin from "../menu/api/menuAdmin";
import LiveMenu from "../menu/api/liveMenu";
import { Search } from "../search/dashboard/api";
import Orders from "../orders/api/orders";
import Pricing from "../pricing/api/pricing";
import UserTableSettingsApi from "../userAppSettings/userTableSettings/api";
import CustomerApi from "../customer/api/customerApi";
import QualityApiClient from "../qualityGradeTemplates/apiClient/qualityApiClient";
import CorporateEntityAdmin from "../admin/corporateEntities/api/corporateEntity";

export interface IMarketplaceApiClient {
    readonly diagnostics: Diagnostics;
    readonly userProfile: UserProfile;
    readonly syncAdmin: SyncAdmin;
    readonly alerts: Alerts;
    readonly identityVerification: IdentityVerification;
    readonly company: CompanyApi;
    readonly productManagement: ProductManagement;
    readonly termsOfService: AcceptTermsOfService;
    readonly termsOfServiceAdmin: TermsOfServiceAdmin;
    readonly inventoryManagement: InventoryManagement;
    readonly menuAdmin: MenuAdmin;
    readonly liveMenu: LiveMenu;
    readonly search: Search;    
    readonly orders: Orders;
    readonly pricing: Pricing;
    readonly userTableSettings: UserTableSettingsApi;
    readonly customer: CustomerApi;
    readonly quality: QualityApiClient;
    readonly corporateEntity: CorporateEntityAdmin;
}

export class MarketplaceApiClient implements IMarketplaceApiClient {

    private apiClient: ApiClient;
    readonly diagnostics: Diagnostics;
    readonly userProfile: UserProfile;
    readonly syncAdmin: SyncAdmin;
    readonly alerts: Alerts;
    readonly identityVerification: IdentityVerification;
    readonly company: CompanyApi;
    readonly productManagement: ProductManagement;
    readonly termsOfService: AcceptTermsOfService;
    readonly termsOfServiceAdmin: TermsOfServiceAdmin;
    readonly inventoryManagement: InventoryManagement;
    readonly menuAdmin: MenuAdmin;
    readonly liveMenu: LiveMenu;
    readonly search: Search;    
    readonly orders: Orders;
    readonly pricing: Pricing;
    readonly userTableSettings: UserTableSettingsApi;
    readonly customer: CustomerApi;
    readonly quality: QualityApiClient;
    readonly corporateEntity: CorporateEntityAdmin;

    constructor() {
        if (process.env.REACT_APP_MARKETPLACE_API_URL === undefined) { throw new Error("REACT_APP_MARKETPLACE_DATA_URL is not set"); }
        this.apiClient = new ApiClient(process.env.REACT_APP_MARKETPLACE_API_URL);

        this.diagnostics = new Diagnostics(this.apiClient);
        this.userProfile = new UserProfile(this.apiClient);
        this.syncAdmin = new SyncAdmin(this.apiClient);
        this.alerts = new Alerts(this.apiClient);
        this.identityVerification = new IdentityVerification(this.apiClient);
        this.company = new CompanyApi(this.apiClient);
        this.productManagement = new ProductManagement(this.apiClient);
        this.termsOfService = new AcceptTermsOfService(this.apiClient);
        this.termsOfServiceAdmin = new TermsOfServiceAdmin(this.apiClient);
        this.inventoryManagement = new InventoryManagement(this.apiClient);
        this.menuAdmin = new MenuAdmin(this.apiClient);
        this.liveMenu = new LiveMenu(this.apiClient);
        this.search = new Search(this.apiClient);        
        this.orders = new Orders(this.apiClient);
        this.pricing = new Pricing(this.apiClient);
        this.userTableSettings = new UserTableSettingsApi(this.apiClient);
        this.customer = new CustomerApi(this.apiClient);
        this.quality = new QualityApiClient(this.apiClient);
        this.corporateEntity= new CorporateEntityAdmin(this.apiClient);
    }

    addGetUserAuth = (getUserAuth: () => UserAuthState) => {
        this.apiClient.getUserAuth = getUserAuth;
    }

    addOnUnauthorized = (onUnauthorized: () => void) => {
        this.apiClient.onUnauthorized = onUnauthorized;
    }
}
