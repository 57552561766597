import { DateTime } from "luxon";
import { inventoryAdapter } from "../state";
import { actionFactory, Inventory, ReadyDateAssignment } from "../types";
import { updateInventoryAssignmentMaps } from "./updateInventoryAssignmentMaps";

export const {

    bulkSetReadyDateBegin,
    bulkSetReadyDateCancel,
    bulkSetReadyDateComplete,
    bulkSetReadyDateReducers,
    bulkSetReadyDateSagaFactory

} = actionFactory
    .withMappedName("bulkSetReadyDate")
    .createModal<ReadyDateAssignment, void, Inventory[]>({
        selector: state => state.readyDateAssignmentState,
        modelFactory: (_arg, state) => ({
            readyDate: DateTime.utc(),
            inventoryIds: state.selectedInventoryIds
        }),
        onCompleteEdit: (value, context) => {
            return context.marketplaceApiClient.inventoryManagement.assignReadyDate(value);
        },
        onFulfilled: (state, action) => {     
            inventoryAdapter.upsertMany(state.inventory, action.result.map(inv => ({ ...inv, orderedQuantity: 0 })));       
        },
        successCallback: (_arg, _result, context) => { 
            context.dispatch(updateInventoryAssignmentMaps());
        }
    }); 