import { Field, FieldProps, FormikContextType, useFormikContext } from "formik";
import { Column, ColumnProps } from "primereact/column";
import { DataTable, DataTableSelectionChangeParams, DataTableSizeType } from "primereact/datatable";
import { isValue } from '../../utils/valueHelper';

export interface TableSelectFieldProps<TModel, TValue extends object> {
    fieldName: string & keyof TModel;
    dataKey: string;
    availableValues: TValue[];
    columns: ColumnProps[];    
    showErrors?: boolean;
    size?: DataTableSizeType;
    containerClassName?: string;
}


export const TableSelectField = <TModel, TValue extends object>({
    fieldName,
    dataKey,
    availableValues,
    columns,    
    showErrors = true,    
    size,
    containerClassName
}: TableSelectFieldProps<TModel, TValue>) => {

    const formikProps: FormikContextType<TModel> = useFormikContext<TModel>();    

    return <div className={containerClassName}>
        <Field
            name={fieldName}
        >
            {
                (props: FieldProps<TModel>) => {

                    const currentValue = (props.field.value as unknown as TValue[]);
                    const currentSelectedRows = isValue(currentValue) ? currentValue : [];

                    const onSelectionChange = (e: DataTableSelectionChangeParams) => {                        
                        formikProps.setFieldValue(props.field.name, e.value);
                    }

                    return <DataTable
                        size={size}
                        dataKey={dataKey}
                        value={availableValues}
                        scrollable
                        scrollDirection="both"
                        scrollHeight='flex'
                        paginator
                        rows={50}
                        rowsPerPageOptions={[10, 20, 50]}
                        alwaysShowPaginator={false}
                        selectionMode="multiple"
                        metaKeySelection={false}
                        onSelectionChange={onSelectionChange}
                        selection={currentSelectedRows}
                    >
                        {columns.map(c => <Column key={c.columnKey ?? c.field} {...c} />)}
                    </DataTable>

                }
            }
        </Field>
        {
            showErrors &&
            <div>
                <small>{formikProps.errors[fieldName]}</small>
            </div>
        }

    </div>
}