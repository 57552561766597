import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { OverlayPanel } from 'primereact/overlaypanel';
import { MouseEvent, useRef } from "react";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { updateTableState } from "../store/featureActions/updateTableState";
import { getSettingsForView } from "../store/selectors";
import { ColumnState } from "../store/types";
import './index.css';

export interface ColumnPickerProps {
    viewId: string;    
}

export const ColumnPicker: React.FC<ColumnPickerProps> = ({ viewId }) => {

    const dispatch = useDispatch();
    const overlayPanelRef = useRef<OverlayPanel>(null);
    const tableColumnState = useAppSelector(state => getSettingsForView(state, viewId));

    const onViewColumnPicker = (e: MouseEvent<HTMLButtonElement>) => {
        if (!isValue(overlayPanelRef)) return;
        if (!isValue(overlayPanelRef.current)) return;
        overlayPanelRef.current.toggle(e);
    }

    const onColumnCheckChange = (col: ColumnState, checked: boolean) => {
        if (!isValue(tableColumnState)) return;

        dispatch(updateTableState({
            viewId: viewId,
            flagSentinel: true,            
            update: {
                columnState: tableColumnState.currentState.columnState.map(columnState => columnState.columnId === col.columnId
                    ? {
                        ...columnState,
                        isVisible: checked
                    }
                    : columnState)
            }
        }));        
    };

    return <FlexRowContainer>
        <OverlayPanel
            ref={overlayPanelRef}
        >
            {
                tableColumnState &&
                <FlexColumnContainer>
                    {
                        tableColumnState.currentState.columnState.map(col => <FlexRowContainer
                            key={col.columnId}
                            gap="10px"
                            padding="5px"
                        >
                            <Checkbox
                                key={col.columnId}
                                checked={col.isVisible}
                                onChange={(e) => onColumnCheckChange(col, e.checked)}
                            />
                            <div>{col.header}</div>
                        </FlexRowContainer>)
                    }
                </FlexColumnContainer>
            }
        </OverlayPanel>
        <Button
            label=""
            onClick={onViewColumnPicker}
            icon={PrimeIcons.TABLE}
            tooltip="Choose columns"
            tooltipOptions={{ position: "top" }}
        />
    </FlexRowContainer>
} 