import { ImageContent } from "./imageTypes";

export interface EntityImage extends ImageContent {
    id: number;
    tenantId: string;
    owningEntityId: number;
    imageData: string;
    mimeType: string;
    imageTitle: string;
}

export const isEntityImage = (value: any) : value is EntityImage  => {
    return (value as EntityImage)?.id !== undefined &&
    (value as EntityImage)?.tenantId !== undefined &&
    (value as EntityImage)?.owningEntityId !== undefined &&
    (value as EntityImage)?.imageData !== undefined &&
    (value as EntityImage)?.mimeType !== undefined &&
    (value as EntityImage)?.imageTitle !== undefined &&    
    (value as EntityImage)?.imageData !== undefined;
}