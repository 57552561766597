import { TemplateMetric } from "../../../models/templateMetric";
import { templateMetricAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    updateTemplateMetric,
    updateTemplateMetricReducer,
    updateTemplateMetricSagaFactory

} = actionFactory
    .withMappedName("updateTemplateMetric")
    .createAsyncAction<TemplateMetric, TemplateMetric>({
        actionStatusSelector: state => state.templateMetricSaveState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.quality.updateTemplateMetric(arg);
        },
        onFulfilled: (state, action) => {
           templateMetricAdapter.upsertOne(state.templateMetrics, action.payload.result);           
        }
    });