import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { actionFactory, InventorySyncKind, ProductManagementState } from "../../types";

export const beginBulkSyncKindAssignment = actionFactory.createAppAction("beginBulkSyncKindAssignment");

export function beginBulkSyncKindAssignmentReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forAction(beginBulkSyncKindAssignment)
        .addHandler((state, _) => {
            state.bulkSyncKindAssignmentState.parentModels = state.selectedProducts;
            state.bulkSyncKindAssignmentState.available = [
                InventorySyncKind.Automated,
                InventorySyncKind.Exclude,
                InventorySyncKind.Manual
            ]
            state.bulkSyncKindAssignmentState.selected = InventorySyncKind.Manual;
        })
        .build();
}