import { useDispatch } from "react-redux";
import { FullyCentered } from "../../../components/containers/fullyCentered";
import { useMountEffect } from "../../../utils/useEffectHelper";
import { clearSession } from "../store/featureActions/clearSession";

export const SessionExpired = () => {

    const dispatch = useDispatch();

    useMountEffect(() => {
        dispatch(clearSession());
    });

    return <FullyCentered inlineStyle={{ fontSize: "36px", height: "calc(90vh - 60px)" }}>
        Your session has expired. Please login again to continue.
    </FullyCentered>
}