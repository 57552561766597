import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { Menu } from "primereact/menu";
import React, { CSSProperties, useRef } from 'react';
import { isValue } from "../valueHelper";
import { mapToMenuItem, TypedMenuItem } from "./typedMenuItem";

export interface OverlayMenuColumnOptions<T> extends Omit<ColumnProps, "field" | "body"> {    
    field: (keyof T) & string,    
    icon?: string,
    disabled?: (value: T) => boolean,
    menuTitle: string,
    menuItems?:  TypedMenuItem<T>[],
    buttonStyle?: CSSProperties
}

interface PopUpMenuProps<T> extends OverlayMenuColumnOptions<T> {
    value: T;
}

const PopUpMenu = <T,>(props: React.PropsWithChildren<PopUpMenuProps<T>>) => {

    const buttonId: string = `${props.field}-${props.menuTitle}`;
    const overlayMenuRef = useRef<Menu>(null);
    const isDisabled = props.disabled !== undefined ? props.disabled(props.value) : false;

    return <div>
        <Menu 
            popup 
            ref={overlayMenuRef} 
            model={props
                .menuItems?.filter(menuItem => !isValue(menuItem.visible) || menuItem.visible(props.value))
                .map(menuItem => mapToMenuItem(menuItem, props.value, buttonId))} />
        <Button
            id={buttonId}
            icon="pi pi-bars"
            onClick={(event) => overlayMenuRef.current?.toggle(event)}
            disabled={isDisabled}
            style={props.buttonStyle}
        />
    </div>
}


function createOverlayMenuTemplate<T>(options: OverlayMenuColumnOptions<T>): (rowData: T) => React.ReactNode {
    return (value: T) => <PopUpMenu {...options} value={value} />
}


export function createOverlayMenuColumn<T>(
    props: OverlayMenuColumnOptions<T>
): React.ReactNode {
    return <Column key={props.columnKey ?? props.field} {...props} body={createOverlayMenuTemplate(props)} />;
}


export function createOverlayMenuColumnProps<T>(
    props: OverlayMenuColumnOptions<T>
): ColumnProps {
    return {
        body: createOverlayMenuTemplate(props),
        ...props 
    }
}