import { CorporateEntityManagement } from "../../admin/corporateEntities/ui";
import { SyncAdminLicense } from "../../admin/licenseManagement/ui";
import { ManageAlerts } from "../../alerts/appAlerts/ui/manageAlerts";
import { authRoutes } from "../../authentication/routes/authRoutes";
import { NoApplicationAccess } from "../../authentication/ui/noApplicationAccess";
import { IsBuyer, IsBuyerAdmin, IsNodeAdmin, IsSeller, IsSellerAdmin, IsTenantAdmin, IsWholesaleAdmin, IsWholesaleUser, SecurityRole } from "../../authorization/roles";
import { CompanyMarketplaceView } from "../../company/marketplaceView/ui";
import { CompanyProfileDashboard } from "../../company/profile/ui";
import { CustomerEditor } from "../../customer/customerEditor/ui";
import { CompanyList } from "../../customer/dashboard/ui";
import { OrderEditor } from "../../customer/orderEditor/ui";
import { GalleryTestingDashboard } from "../../diagnostics/galleryTests/ui";
import { DataViewScrollTests } from "../../diagnostics/scrollTests/dataview/ui";
import { DiagnosticDashboard } from "../../diagnostics/serverInformation/ui";
import { Home } from "../../home";
import { ApiKeyVerificationDashboard } from "../../identityVerification/buyerVerification/ui";
import { AcceptTermsOfService } from "../../initialSetup/acceptTermsOfService/ui";
import { InventoryManagementDashboard } from "../../inventorySetup/inventoryManagement/ui";
import { ProductManagementDashboard } from "../../inventorySetup/productManagement/ui";
import { FavoriteMenus } from "../../marketplaceUserProfile/favoriteMenus/ui";
import { ManageProfile } from "../../marketplaceUserProfile/manageProfile/ui";
import { MenuDashboard } from "../../menu/dashboard/ui";
import { MenuEditor } from "../../menu/editor/ui";
import { LiveMenuDashboard } from "../../menu/endUser/ui";
import { PrintMenuDashboard } from "../../menu/endUser/ui/printMenuDashboard";
import { ProductDetailsPage } from "../../menu/productDetails/ui";
import { CompanyProvisioningDashboard } from "../../nodeAdmin/provisioning/ui";
import { TermsOfServiceAdminDashboard } from "../../nodeAdmin/termsOfService/ui";
import { BuyerOrdersDashboard } from "../../orders/buyerOrders/ui";
import { PricingDashboard } from "../../pricing/priceModelDashboard/ui";
import { PricingModelEditor } from "../../pricing/priceModelEditor/ui";
import { QualityGradeKindsDashboard } from "../../qualityGradeTemplates/gradeKinds/ui";
import { QualityMetricKindsDashboard } from "../../qualityGradeTemplates/metricKinds/ui";
import { QualityAssessmentTemplateDashboard } from "../../qualityGradeTemplates/templateDashboard/ui";
import { QualityTemplateEditor } from "../../qualityGradeTemplates/templateEditor/ui";
import { SearchDashboard } from "../../search/dashboard/ui";
import { SignalRHubConnection } from "../../signalR/hubConnection/ui";
import { TestTable } from "../../userAppSettings/userTableSettings/ui/testTable";
import { NonSecuredRoute, ParameterizedRoute, RouteModel, SimpleRoute } from "./types";

if (process.env.REACT_APP_CLIENT_URL === undefined) { throw new Error("REACT_APP_CLIENT_URL is not set"); }
const clientUrl = process.env.REACT_APP_CLIENT_URL;

export const homeRoute: NonSecuredRoute = {
  kind: "NonSecured",
  path: "/home",
  exact: true,
  view: Home,
}

export const searchRoute: SimpleRoute = {
  kind: "Secured",
  path: "/search",
  exact: true,
  view: SearchDashboard,
  role: IsWholesaleUser
}

export const noApplicationAccessRoute: NonSecuredRoute = {
  kind: "NonSecured",
  path: "/noapplicationaccess",
  exact: true,
  view: NoApplicationAccess
}

export const menuDashboardRoute: SimpleRoute = {
  kind: "Secured",
  path: "/menuDashboard",
  exact: true,
  view: MenuDashboard,
  role: IsSeller
}

export const menuEditorRoute: ParameterizedRoute = {
  kind: "Parameterized",
  path: "/menu/:id",
  createPath: (params) => `/menu/${params}`,
  createFullUrl: (params) => `${clientUrl}menu/${params}`,
  exact: false,
  view: MenuEditor,
  role: IsSeller
}

export const diagnosticsRoute: SimpleRoute = {
  kind: "Secured",
  path: '/diagnostics',
  exact: true,
  view: DiagnosticDashboard,
  role: SecurityRole.NodeAdmin
};

export const signalRTestRoute: SimpleRoute = {
  kind: "Secured",
  path: '/signalRTest',
  exact: true,
  view: SignalRHubConnection,
  role: SecurityRole.NodeAdmin
};

export const customTableTestRoute: SimpleRoute = {
  kind: "Secured",
  path: '/customTableTest',
  exact: true,
  view: TestTable,
  role: SecurityRole.NodeAdmin
};

export const manageProfileRoute: SimpleRoute = {
  kind: "Secured",
  path: '/userProfile',
  exact: true,
  view: ManageProfile,
  role: IsWholesaleUser
};

export const syncAdminLicenseRoute: SimpleRoute = {
  kind: "Secured",
  path: '/syncAdminLicense',
  exact: true,
  role: IsSellerAdmin,
  view: SyncAdminLicense
};

export const manageAlertsRoute: SimpleRoute = {
  kind: "Secured",
  path: '/alerts',
  exact: true,
  role: IsWholesaleUser,
  view: ManageAlerts
};

export const apiKeyVerificationRoute: SimpleRoute = {
  kind: "Secured",
  path: '/license/verification',
  exact: true,
  role: IsBuyerAdmin,
  view: ApiKeyVerificationDashboard
};

export const companyProfileRoute: SimpleRoute = {
  kind: "Secured",
  path: '/company/profile',
  exact: true,
  role: IsWholesaleAdmin,
  view: CompanyProfileDashboard
};

export const corporateEntityManagementRoute: SimpleRoute = {
  kind: "Secured",
  path: '/corporateEntity',
  exact: true,
  role: IsTenantAdmin,
  view: CorporateEntityManagement
};

export const productManagementDashboardRoute: SimpleRoute = {
  kind: "Secured",
  path: '/productManagement',
  exact: true,
  role: IsSeller,
  view: ProductManagementDashboard
};

export const acceptTermsOfServiceRoute: SimpleRoute = {
  kind: "Secured",
  path: '/AcceptTermsOfService',
  exact: true,
  role: IsWholesaleUser,
  view: AcceptTermsOfService
};

export const termsOfServiceAdminDashboardRoute: SimpleRoute = {
  kind: "Secured",
  path: '/termsOfServiceAdmin',
  exact: true,
  role: SecurityRole.NodeAdmin,
  view: TermsOfServiceAdminDashboard
};

export const favoriteMenusRoute: SimpleRoute = {
  kind: "Secured",
  path: '/favoriteMenus',
  exact: true,
  role: IsBuyer,
  view: FavoriteMenus
};

export const priceModelsDashboardRoute: SimpleRoute = {
  kind: "Secured",
  path: '/pricing/models',
  exact: true,
  role: IsSeller,
  view: PricingDashboard
};

export const priceModelEditorRoute: ParameterizedRoute = {
  kind: "Parameterized",
  path: "/pricing/models/:id",
  createPath: (params) => `/pricing/models/${params}`,
  createFullUrl: (params) => `${clientUrl}pricing/models/${params}`,
  exact: false,
  role: IsSeller,
  view: PricingModelEditor
};

export const buyerOrdersRoute: SimpleRoute = {
  kind: "Secured",
  path: '/buyer/holdRequests',
  exact: true,
  role: IsNodeAdmin,
  view: BuyerOrdersDashboard
};

export const inventoryManagementDashboardRoute: SimpleRoute = {
  kind: "Secured",
  path: '/inventoryManagement',
  exact: true,
  role: IsSeller,
  view: InventoryManagementDashboard
};

export const companyListRoute: ParameterizedRoute = {
  kind: "Parameterized",
  path: '/customer/:companyType',
  createPath: (companyId) => `/customer/${companyId}`,
  createFullUrl: (companyId) => `${clientUrl}customer/${companyId}`,
  exact: true,
  role: IsWholesaleUser,
  view: CompanyList
};

export const customerEditorRoute: ParameterizedRoute = {
  kind: "Parameterized",
  path: '/customer/:id/editor',
  createPath: (id) => `/customer/${id}/editor`,
  createFullUrl: (id) => `${clientUrl}customer/${id}/editor`,
  exact: false,
  view: CustomerEditor,
  role: IsSeller
}

export const orderEditorRoute: ParameterizedRoute = {
  kind: "Parameterized",
  path: '/order/:id/editor',
  createPath: (id) => `/order/${id}/editor`,
  createFullUrl: (id) => `${clientUrl}order/${id}/editor`,
  exact: false,
  view: OrderEditor,
  role: IsSeller
}


export const liveMenuRoute: ParameterizedRoute = {
  kind: "Parameterized",
  path: '/vendor/:vendorName/menu/:menuName',
  createPath: (vendorName, menuName) => `/vendor/${vendorName}/menu/${menuName}`,
  createFullUrl: (vendorName, menuName) => `${clientUrl}vendor/${vendorName}/menu/${menuName}`,
  exact: false,
  view: LiveMenuDashboard,
  role: IsWholesaleUser
}

export const printLiveMenuRoute: ParameterizedRoute = {
  kind: "Parameterized",
  path: '/vendor/:vendorName/menu/:menuName/print',
  createPath: (vendorName, menuName) => `/vendor/${vendorName}/menu/${menuName}/print`,
  createFullUrl: (vendorName, menuName) => `${clientUrl}vendor/${vendorName}/menu/${menuName}/print`,
  exact: false,
  view: PrintMenuDashboard,
  role: IsSeller
}

export const productDetailsRoute: ParameterizedRoute = {
  kind: "Parameterized",
  path: '/vendor/:companyName/menu/:menuName/inventory/:inventoryId',
  createPath: (companyName, menuName, inventoryId) => `/vendor/${companyName}/menu/${menuName}/inventory/${inventoryId}`,
  createFullUrl: (companyName, menuName, inventoryId) => `${clientUrl}/vendor/${companyName}/menu/${menuName}/inventory/${inventoryId}`,
  exact: false,
  view: ProductDetailsPage,
  role: IsWholesaleUser
}

export const dataViewScrollTestsRoute: SimpleRoute = {
  kind: "Secured",
  path: '/dataViewScrollTest',
  exact: true,
  role: SecurityRole.NodeAdmin,
  view: DataViewScrollTests
};

export const galleryTestingDashboardRoute: SimpleRoute = {
  kind: "Secured",
  path: '/galleryTesting',
  exact: true,
  role: SecurityRole.NodeAdmin,
  view: GalleryTestingDashboard
};

const defaultRoute: NonSecuredRoute = {
  kind: "NonSecured",
  path: '*',
  exact: false,
  view: Home
};

export const QualityMetricKindsRoute : SimpleRoute = {
  kind: "Secured",
  path: '/quality/metrics',  
  exact: true,
  view: QualityMetricKindsDashboard,
  role: IsSeller
};

export const QualityGradeKindsRoute : SimpleRoute = {
  kind: "Secured",
  path: '/quality/grades',  
  exact: true,
  view: QualityGradeKindsDashboard,
  role: IsSeller
};

export const QualityTemplateDashboardRoute : SimpleRoute = {
  kind: "Secured",
  path: '/quality/templates',  
  exact: true,
  view: QualityAssessmentTemplateDashboard,
  role: IsSeller
};

export const QualityTemplateEditorRoute : ParameterizedRoute = {
  kind: "Parameterized",
  path: '/quality/templates/:id',
  createPath: (params) => `/quality/templates/${params}`,
  createFullUrl: (params) => `${clientUrl}quality/templates/${params}`,
  exact: true,
  view: QualityTemplateEditor,
  role: IsSeller
};

export const CompanyProvisioningDashboardRoute : SimpleRoute = {
  kind: "Secured",
  path: '/company/manage/dashboard',  
  exact: true,
  view: CompanyProvisioningDashboard,
  role: IsNodeAdmin
};

export const CompanyMarketplaceViewRoute : ParameterizedRoute = {
  kind: "Parameterized",
  path: '/company/:id',
  createPath: (params) => `/company/${params}`,
  createFullUrl: (params) => `${clientUrl}company/${params}`,
  exact: true,
  view: CompanyMarketplaceView,
  role: IsWholesaleUser
};



export const appRoutes: RouteModel[] = [
  noApplicationAccessRoute,
  ...authRoutes,
  acceptTermsOfServiceRoute,
  homeRoute,
  searchRoute,
  menuEditorRoute,
  menuDashboardRoute,
  diagnosticsRoute,
  customTableTestRoute,
  manageProfileRoute,
  syncAdminLicenseRoute,
  corporateEntityManagementRoute,  
  manageAlertsRoute,
  apiKeyVerificationRoute,
  companyProfileRoute,
  favoriteMenusRoute,
  productManagementDashboardRoute,
  termsOfServiceAdminDashboardRoute,
  inventoryManagementDashboardRoute,
  productDetailsRoute,  
  printLiveMenuRoute,
  liveMenuRoute,
  buyerOrdersRoute,
  priceModelsDashboardRoute,
  priceModelEditorRoute,
  companyListRoute,
  customerEditorRoute,
  orderEditorRoute,
  dataViewScrollTestsRoute,
  signalRTestRoute,
  galleryTestingDashboardRoute,
  QualityMetricKindsRoute,
  QualityGradeKindsRoute,
  QualityTemplateDashboardRoute,
  QualityTemplateEditorRoute,
  CompanyProvisioningDashboardRoute,
  CompanyMarketplaceViewRoute,
  defaultRoute,
];