import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { menuSectionAdapter } from "../state";
import { actionFactory, MenuAdminState, MenuSection } from "../types";

export const {

    updateMenuSection,
    updateMenuSectionReducer,
    updateMenuSectionSagaFactory

} = actionFactory
    .withMappedName("updateMenuSection")
    .createAsyncAction<MenuSection, MenuSection>({
        actionStatusSelector: state => state.addSectionSaveState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.menuAdmin.upsertMenuSection(arg);
        },
        onFulfilled: (state, action) => {
            if (action.payload.arg.id !== action.payload.result.id) {
                menuSectionAdapter.upsertOne(state.menuSections, action.payload.result);
            }

        },
        debounceInMilliseconds: 250
    });

export function updateMenuLocalStateReducer(reducerFactory: IReducerFactory<MenuAdminState>): IReducerFactory<MenuAdminState> {
    return reducerFactory
        .forAction(updateMenuSection)
        .addHandler((state, action) => {
            if (action.payload.id > 0) {
                menuSectionAdapter.upsertOne(state.menuSections, action.payload);
            }
        })
        .build();
}
