import { RootState } from "../../../../../store/rootReducer";
//import { isValue } from "../../../../../utils/valueHelper";

export const getHasAcceptedTerms = (state: RootState) => state.acceptTermsOfService.termsOfServiceAcceptance !== null &&
    state.acceptTermsOfService.termsOfServiceAcceptance.acceptedUtc !== null;

export const getTermsOfService = (state: RootState) => {
    return `data:application/pdf;base64,${state.acceptTermsOfService.termsOfServiceAcceptance?.termsOfServiceDocument ?? ""}`;   
}

export const getUserAgreesToNewTerms = (state: RootState) => state.acceptTermsOfService.userAgreesToNewTerms;