import { createEntityAdapter } from '@reduxjs/toolkit';
import { CreateActionStatusInitialState, EntityActionStatus } from '../../../actionStatus/actionStatus';
import { QualityGradeKind } from '../../../qualityGradeTemplates/models/gradeKind';
import { QualityGradeValue } from '../../../qualityGradeTemplates/models/gradeValue';
import { PricingModelGrade } from '../../sharedTypes';
import { PriceModelEditorState } from './types';

export const pricingModelGradeAdapter = createEntityAdapter<PricingModelGrade>();
export const pricingModelSaveStateAdapter = createEntityAdapter<EntityActionStatus>();
export const gradeValueAdapter = createEntityAdapter<QualityGradeValue>();
export const gradeKindAdapter = createEntityAdapter<QualityGradeKind>();
export const createInitialState = (): PriceModelEditorState => {
    return {        
        loadingState: CreateActionStatusInitialState(),      
        priceModel: null,
        pricingModelGrades: pricingModelGradeAdapter.getInitialState(),        
        pricingModelSaveStates: pricingModelSaveStateAdapter.getInitialState(),
        gradeValues: gradeValueAdapter.getInitialState(),
        gradeKinds: gradeKindAdapter.getInitialState(),        
    };
};

export const initialState: PriceModelEditorState = createInitialState();