import * as yup from 'yup';
import { Category } from '../../categoryManagement/store/types';
import { SortDirection, SortField } from '../../common/types';
import { Menu, MenuSection } from "../store/types";

export const menuValidations: yup.SchemaOf<Partial<Menu>> = yup.object().shape({
    id: yup.number().optional(),
    companyId: yup.number().optional(),
    inactive: yup.bool().optional(),
    name: yup.string().required(),
    urlName: yup.string().optional(),
    primaryColor: yup.string().optional().nullable(),
    secondaryColor: yup.string().optional().nullable(),    
    tenantId: yup.string().optional(),
    vendorName: yup.string().optional().nullable(),
    vendorUrlName: yup.string().optional().nullable(),
    secondaryFont: yup.string().optional().nullable(),
    primaryFont: yup.string().optional().nullable(),
    defaultSortDirection: yup.mixed<SortDirection>().required(),
    defaultSortField: yup.mixed<SortField>().required(),
});

export const categoryValidations: yup.SchemaOf<Category> = yup.object().shape({
    id: yup.number().required(),
    companyId: yup.string().required(),
    tenantId: yup.string().required(),
    name: yup.string().required("Name is requried"),  
});

export const menuSectionValidations: yup.SchemaOf<MenuSection> = yup.object().shape({
    id: yup.number().required(),
    // companyId: yup.string().required(),
    tenantId: yup.string().required(),
    name: yup.string().required("Name is requried"),  
    categoryIds: yup.array().required(),
    description: yup.string().nullable().min(0),  
    menuId: yup.number().required(),
    sequence: yup.number().required()
});