import { Message } from "primereact/message";
import { CSSProperties } from "react";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { getLicenses } from "../store/selectors/vendorMenu";

export const LicenseDisplay: React.FC = () => {

    const licenses = useAppSelector(getLicenses);

    const messageStyle: CSSProperties = {
        backgroundColor: "lightgrey"
    }

    return <FlexRowContainer gap="15px" padding="10px" maxWidth="95vw">
        <Message
            severity="info"            
            style={messageStyle}
            content={<FlexRowContainer>                
                <div>{`Licenses: ${licenses.join(", ")}`}</div>
            </FlexRowContainer>
            }
        />
    </FlexRowContainer>;
}