import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { getUserRoles } from "../../../../authentication/store/selectors/userRoles";
import { IsWholesaleAdmin } from "../../../../authorization/roles";
import { AlertBarState, CompanyAlert, UserAlert, actionFactory } from "../types";

export const loadAlerts = actionFactory.createAppAction("loadAlerts");

interface LoadAlertsResult {
    userAlerts: UserAlert[],
    companyAlerts: CompanyAlert[]
}
const sagaActions = actionFactory.createSagaWithProgressActions<void, LoadAlertsResult>(loadAlerts.type);

export function loadloadAlertsReducers(reducerFactory: IReducerFactory<AlertBarState>): IReducerFactory<AlertBarState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.loadingState)
        .onFulfilled((state, action) => {
            state.userAlerts = action.payload.result.userAlerts;
            state.companyAlerts = action.payload.result.companyAlerts;
        })
        .build();
}

async function performLoad(_: void, context: SagaAppContext): Promise<LoadAlertsResult> {

    const state = context.getState();
    const userRoles = getUserRoles(state);

    const companyAlertsPromise: Promise<CompanyAlert[]> = IsWholesaleAdmin(userRoles)
        ? context.marketplaceApiClient.alerts.getCompanyAlerts()
        : Promise.resolve<CompanyAlert[]>([]);

    const userAlertsPromise: Promise<UserAlert[]> = context.marketplaceApiClient.alerts.getUserAlerts();

    return Promise
        .all([userAlertsPromise, companyAlertsPromise])
        .then(values => {
            return {
                userAlerts: values[0],
                companyAlerts: values[1]
            }
        });
}

export function createLoadAlertsSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: loadAlerts.type,
        debounceInMilliseconds: 250
    });
};