import { ColumnProps } from "primereact/column";
import { getFilteredEntities } from "../../../../../utils/entityStateHelpers";
import { isValue } from "../../../../../utils/valueHelper";
import { getColumnHeaderText, getColumnId, getColumnWidth, mergeViewDefintionWithTableState } from "../logic/columnHelper";
import { deserializeTableState } from "../logic/settingsSerialization";
import { viewDefinitionsAdapter, viewSettingsAdapter } from "../state";
import { actionFactory, ColumnState, ViewDefinition } from "../types";

export const {

    initializeView,
    initializeViewReducer

} = actionFactory
    .withMappedName("initializeView")
    .createAction<{ viewId: string, columns: ColumnProps[] }>((state, action) => {

        const columnState = action.payload.columns.map<ColumnState>(col => ({
            columnId: getColumnId(col),
            width: getColumnWidth(col),
            header: getColumnHeaderText(col),
            isVisible: true, 
            filter: col.filter,
            filterMatchMode: col.filterMatchMode
        }));

        const viewDefinition: ViewDefinition = {
            ...state.viewDefinitions.entities[action.payload.viewId],
            id: action.payload.viewId,
            columnState: columnState            
        };
        viewDefinitionsAdapter.upsertOne(state.viewDefinitions, viewDefinition);

        const userTableSettingsForView = getFilteredEntities(
            state.userTableSettings,
            userTableSettings => userTableSettings.viewId === action.payload.viewId);

        const defaultUserSettings = userTableSettingsForView.length > 0
            ? userTableSettingsForView[0]
            : null;

        const settings = state.viewSettings.entities[action.payload.viewId];

        if (!isValue(settings)) {
            if (isValue(defaultUserSettings)) {
                const tableState = deserializeTableState(defaultUserSettings.settings);

                mergeViewDefintionWithTableState(viewDefinition, tableState);

                viewSettingsAdapter.upsertOne(state.viewSettings, {
                    id: action.payload.viewId,
                    selectedUserTableSettings: defaultUserSettings.id,
                    currentState: tableState,
                    remountState: {
                        remountInProgress: false,
                        showTable: true,
                        tableRemountNeeded: false,
                        needsStateReapplied: false
                    },
                    restoreSentinel: false
                })
            }
            else {
                viewSettingsAdapter.upsertOne(state.viewSettings, {
                    id: action.payload.viewId,
                    selectedUserTableSettings: null,
                    currentState: {
                        additionalSettings: null,
                        columnState: columnState
                    },
                    remountState: {
                        remountInProgress: false,
                        showTable: true,
                        tableRemountNeeded: false,
                        needsStateReapplied: false
                    },
                    restoreSentinel: false
                })
            }

        }
    });