import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { AssessmentTemplate } from "../../models/assessmentTemplate";
import { QualityGradeKind } from "../../models/gradeKind";
import { ModalEditState } from "../../../../store/factory/modalEdit/modalEdit";

export const sliceName: string = "quality-template-dashboard";

export const actionFactory = new ActionFactory<QualitityTemplateDashboardState>(sliceName);

export interface QualitityTemplateDashboardState {
    loadingState: ActionStatus;  
    templates: AssessmentTemplate[];
    gradeKinds: QualityGradeKind[];    
    addTemplateState: ModalEditState<Partial<AssessmentTemplate>>;
}