import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Menu } from 'primereact/menu';
import { MenuItem } from "primereact/menuitem";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import packageJson from '../../../../../package.json';
import { beginLogin } from "../../../authentication/store/featureActions/beginLoginActions";

const WithoutSessionMenu: React.FC = () => {
    
    const withoutSessionMenuRef = useRef<Menu>(null);    
    const dispatch = useDispatch();    
    const currentVersion = packageJson.version;
  
    const onLoginClick = () => {
        dispatch(beginLogin());
    }

    const onToggleWithoutSessionMenu = (event: React.MouseEvent) => {
        if (withoutSessionMenuRef.current === null) return;
        withoutSessionMenuRef.current.toggle(event);
    }

    
    const withoutSessionMenuItems: MenuItem[] = [];

    withoutSessionMenuItems.push({
        label: "Login",
        icon: PrimeIcons.SIGN_IN,
        command: onLoginClick
    });
    
    withoutSessionMenuItems.push({
       label: `v ${currentVersion}`,
       disabled: true
    });

    return <div>           
            <Button
                iconPos="right"
                icon={PrimeIcons.CHEVRON_DOWN}
                className="p-button-text"
                onClick={onToggleWithoutSessionMenu}
                label="Login"
            />       
        </div>
}

export default WithoutSessionMenu;
