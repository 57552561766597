import ApiClient from "../../../apiCommunication/apiClient";
import { TermsOfServiceUserAcceptance } from "../store/types";


export class AcceptTermsOfService {

    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    agreeToTermsOfService = (termsOfServiceId: number): Promise<TermsOfServiceUserAcceptance> => {
        return this
            .apiClient
            .executePostWithResult<TermsOfServiceUserAcceptance>(`termsofservice/accept/${termsOfServiceId}`);
    };

    getTermsOfServiceAcceptance = (): Promise<TermsOfServiceUserAcceptance> => {
        return this
            .apiClient
            .executeGet<TermsOfServiceUserAcceptance>(`termsofservice/accept`);
    };

}
