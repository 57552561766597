import { PanelMenu } from 'primereact/panelmenu';
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../store/configureStore";
import { useMountEffect } from '../../../utils/useEffectHelper';
import { isValue } from "../../../utils/valueHelper";
import { hideSidePanel } from "../../appLayout/store/featureActions/hideSidePanel";
import { getUserRoles } from "../../authentication/store/selectors/userRoles";
import { mapMenuModelToMenu } from "./helper";
import { menuItems } from "./logicalMenu";
import './menu.css';

const MainAppMenu: React.FC = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const userRoles = useAppSelector(getUserRoles);

    const menuItemClick = (navigateTo: string) => {
        dispatch(hideSidePanel());
        history.push(navigateTo);
    }

    const appMenuItems = menuItems
        .map(model => mapMenuModelToMenu(model, userRoles, menuItemClick))
        .filter(isValue);

    useMountEffect(() => {

        setTimeout(() => {
            document.querySelectorAll(".node-parentmenu .p-panelmenu-header > a").forEach((element => {                
                (element as any).click();
            }));
        }, 750); // <-- stupid hack until PrimeReact allows for default expanded states of panel menu header

    });

    return <PanelMenu
        className="app-side-menu"
        multiple={true}
        model={appMenuItems}
        style={{ width: '100%' }}
    />
}

export default MainAppMenu;
