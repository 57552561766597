import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { actionFactory, ProductManagementState } from "../../types";

export const cancelBulkClearCategoryAssignment = actionFactory.createAppAction("cancelBulkClearCategoryAssignment");

export function cancelBulkClearCategoryAssignmentReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forAction(cancelBulkClearCategoryAssignment)
        .addHandler((state, _) => {
            state.bulkClearCategoryAssignmentState.products = [];
        })
        .build();
}