import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { Product } from "../../../../inventorySetup/productManagement/store/types";
import { actionFactory, Category, CategoryManagementState } from "../types";

export const loadProductsAndAssignments = actionFactory.createAppAction<Category>("loadProductsAndAssignments");

interface LoadResult {
    allProducts: Product[];
    assignedProductIds: number[];
}

const sagaActions = actionFactory.createSagaWithProgressActions<Category, LoadResult>(loadProductsAndAssignments.type);

export function loadProductsAndAssignmentsReducers(reducerFactory: IReducerFactory<CategoryManagementState>): IReducerFactory<CategoryManagementState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.categoryProductAssignment.loadingState)
        .onFulfilled((state, action) => {
            state.categoryProductAssignment.allProducts = action.payload.result.allProducts;

            action.payload.result.assignedProductIds.forEach(id => {
                state.categoryProductAssignment.assignedProductMap[id] = id;
            });

        })
        .build();
}

async function performLoad(arg: Category, context: SagaAppContext): Promise<LoadResult> {
    const productsPromise = context.marketplaceApiClient.productManagement.getProducts();
    const assignedProductIdsPromise = context.marketplaceApiClient.menuAdmin.getProductIdsForCategory(arg.id);

    return Promise.all([productsPromise, assignedProductIdsPromise]).then(values => {
        return {
            allProducts: values[0],
            assignedProductIds: values[1]
        }
    });
}

export function createLoadProductsAndAssignmentsSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: loadProductsAndAssignments.type,
        debounceInMilliseconds: 250
    });
};