import { Load4Result, parallelLoad } from "../../../../../utils/promiseHelpers";
import { Contact, Customer, CustomerNote, SalesOrderWithDetail } from "../../../common/types";
import { contactAdapter, customerNoteAdapter, salesOrderAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    loadSliceData,
    loadSliceDataReducer,
    loadSliceDataSagaFactory

} = actionFactory
    .withMappedName("loadSliceData")
    .createAsyncAction<number, Load4Result<Customer, CustomerNote[], SalesOrderWithDetail[], Contact[]>>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {
            return parallelLoad(
                () => context.marketplaceApiClient.customer.getCustomerById(arg),
                () => context.marketplaceApiClient.customer.getCustomerNotes(arg),
                () => context.marketplaceApiClient.customer.getCustomerOrders(arg),
                () => context.marketplaceApiClient.customer.getCustomerContacts(arg)
            );
        },
        onFulfilled: (state, action) => {
            state.customer = action.payload.result.result1;
            customerNoteAdapter.setAll(state.customerNotes, action.payload.result.result2);
            salesOrderAdapter.setAll(state.customerOrders, action.payload.result.result3.map(dto => dto.salesOrder));
            contactAdapter.setAll(state.customerContacts, action.payload.result.result4);
        }
    });