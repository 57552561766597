import { createEntityAdapter } from "@reduxjs/toolkit";

import { CreateInitialModalEditState } from "../../../../store/factory/modalEdit/modalEdit";
import { CreateActionStatusInitialState } from "../../../actionStatus/actionStatus";
import { QualityGradeKind } from "../../models/gradeKind";
import { QualityGradeValue } from "../../models/gradeValue";
import { QualityGradeKindsState } from './types';

export const gradeKindAdapter = createEntityAdapter<QualityGradeKind>();
export const gradeValueAdapter = createEntityAdapter<QualityGradeValue>();

export const createInitialState = (): QualityGradeKindsState => {
    return {        
        loadingState: CreateActionStatusInitialState(),      
        gradeKinds: gradeKindAdapter.getInitialState(),
        addGradeKindState: CreateInitialModalEditState(),
        selectedGradeKindId: null,
        gradeValueSaveState: CreateActionStatusInitialState(),
        gradeValues: gradeValueAdapter.getInitialState()
    };
};

export const initialState: QualityGradeKindsState = createInitialState();