import clsx from "clsx";
import { PrimeIcons } from "primereact/api";
import { ColumnProps } from "primereact/column";
import React from 'react';
import { isValue } from "../valueHelper";

export interface IconColumnOptions<T> extends Omit<ColumnProps, "field" | "header" | "columnKey"> {
    columnKey: string;
    header: string;
    getIconClassName: (rowData: T) => string | null | undefined;
}

export function createIconTemplate<T>(getIconClassName: (rowData: T) => string | null | undefined): (rowData: T) => React.ReactNode {
    return (rowData: T) => {
        const value = getIconClassName(rowData);
        if (isValue(value)) {
            return <i className={value} ></i>;
        }
        else {
            return null;
        }
    };
}

export function createCircleCheckIconTemplate<T>(getValueFn: ((model: T) => boolean)): (rowData: T) => React.ReactNode {
    return (rowData: T) => {
        const value = getValueFn(rowData);
        if (value) {
            return <i className={clsx(PrimeIcons.CHECK_CIRCLE, "table-icon-circle-check")} ></i>;
        }
        else {
            return null;
        }
    };
}


function createSaveIconTemplate<T>(getValueFn: ((model: T) => boolean)): (rowData: T) => React.ReactNode {
    return (rowData: T) => {
        const value = getValueFn(rowData);
        if (value) {
            return <i className={clsx(PrimeIcons.SPINNER, "pi-spin")} ></i>;
        }
        else {
            return null;
        }
    };
}

export interface SaveIconColumnOptions<T> extends Omit<ColumnProps, "field" | "header" | "columnKey"> {
    columnKey: string;
    header: string;
    getIsSaving: (rowData: T) => boolean;
}

export function createSaveIconColumnProps<T>({ getIsSaving, columnKey, header, ...rest }: SaveIconColumnOptions<T>): ColumnProps{
    return { 
        ...rest, 
        columnKey: columnKey, 
        style: { textAlign: "center" },        
        body: createSaveIconTemplate(getIsSaving) 
    };
}

export function createIconColumnProps<T>({ getIconClassName, columnKey, header, ...rest }: IconColumnOptions<T>): ColumnProps{
    return { 
        ...rest, 
        columnKey: columnKey, 
        style: { textAlign: "center" },        
        body: createIconTemplate(getIconClassName) 
    };
}
