import { EntityVideo } from "../../../../../commonModels/entityVideo";
import { inventoryVideoAdapter } from "../../state";
import { actionFactory } from "../../types";

export const {

    upsertInventoryVideo,
    upsertInventoryVideoReducer,
    upsertInventoryVideoSagaFactory

} = actionFactory
    .withMappedName("upsertInventoryVideo")
    .createAsyncAction<EntityVideo, EntityVideo>({
        actionStatusSelector: state => state.videoSaveState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.inventoryManagement.upsertInventoryVideo(arg);
        },
        onFulfilled: (state, action) => {
            inventoryVideoAdapter.upsertOne(state.editVideos, action.payload.result);
        }
    });