import { createAction } from "@reduxjs/toolkit";
import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory, ServerHealth, ServerInformationState } from "../types";

const loadSliceDataActionName = actionFactory.createActionName("loadSliceData");
export const loadSliceData = createAction<void>(loadSliceDataActionName);

const sagaActions = actionFactory.createSagaWithProgressActions<void, ServerHealth>(loadSliceDataActionName);

export function loadSliceDataReducers(reducerFactory: IReducerFactory<ServerInformationState>): IReducerFactory<ServerInformationState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.loadingState)
        .onFulfilled((state, action) => {
            state.serverHealth = action.payload.result;
        })
        .build();
}

async function onLoadSliceData(_: void, context: SagaAppContext): Promise<ServerHealth> {
    return context.marketplaceApiClient.diagnostics.getServerHealth();
}

export function createLoadSliceDataSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: onLoadSliceData,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: loadSliceDataActionName,
        debounceInMilliseconds: 250
    });
};