import { MenuItem } from "primereact/menuitem";
import { SplitButton } from "primereact/splitbutton";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { BulkAssignmentKind } from "../../../commonModels/bulkAssignmentState";
import { beginBulkCategoryAssignment } from "../store/featureActions/bulkCategoryAssignment/beginBulkCategoryAssignment";
import { beginBulkClearCategoryAssignment } from "../store/featureActions/bulkClearCategoryAssignment/beginBulkClearCategoryAssignment";
import { beginBulkPricingModelAssignment } from "../store/featureActions/priceModels/beginBulkPricingModelAssignment";
import { getSelectedProducts } from "../store/selectors/selectedProducts";

export const BulkActionToolbar: React.FC = () => {

    const dispatch = useDispatch();
    const selectedProducts = useAppSelector(getSelectedProducts);

    const onBulkEditCategoryAssignment = () => {
        dispatch(beginBulkCategoryAssignment());
    }

    const onBulkClearCategoryAssignment = () => {
        dispatch(beginBulkClearCategoryAssignment());
    }

    const onBulkAssignPricingModel = () => {
        dispatch(beginBulkPricingModelAssignment(BulkAssignmentKind.Assign));
    }

    const onBulkClearPricingModel = () => {
        dispatch(beginBulkPricingModelAssignment(BulkAssignmentKind.Clear));
    }

    const bulkActionMenuItems: MenuItem[] = [      
        {
            label: "Assign Categories",
            command: onBulkEditCategoryAssignment
        },
        {
            label: "Clear Categories",
            command: onBulkClearCategoryAssignment
        },
        {
            label: "Assign Pricing Model",
            command: onBulkAssignPricingModel
        },
        {
            label: "Clear Pricing Model",
            command: onBulkClearPricingModel
        }
    ];

    return <div>
        <SplitButton label="Bulk Actions" model={bulkActionMenuItems} disabled={selectedProducts.length === 0} />
    </div>
}