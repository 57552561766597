import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { firstOrNull } from "../../../../../utils/arrayHelpers";
import { actionFactory, AlertBarState } from "../types";

export interface DismissAlertArg {
    alertId: number,
    isUser: boolean
}
export const dismissAlert = actionFactory.createAppAction<DismissAlertArg>("dismissAlert");

const sagaActions = actionFactory.createSagaWithProgressActions<DismissAlertArg, Response>(dismissAlert.type);

export function dismissAlertReducers(reducerFactory: IReducerFactory<AlertBarState>): IReducerFactory<AlertBarState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.loadingState)
        .onFulfilled((state, action) => {
            let dismissTarget = null;
            if (action.payload.arg.isUser) {
                dismissTarget = firstOrNull(state.userAlerts, alert => alert.id === action.payload.arg.alertId);
            }
            else {
                dismissTarget = firstOrNull(state.companyAlerts, alert => alert.id === action.payload.arg.alertId);
            }

            if (dismissTarget !== null) {
                dismissTarget.isDismissed = true;
            }
        })
        .build();
}

async function performAction(arg: DismissAlertArg, context: SagaAppContext): Promise<Response> {
    if (arg.isUser) {
        return context.marketplaceApiClient.alerts.dismissUserAlert(arg.alertId);
    }
    else {
        return context.marketplaceApiClient.alerts.dismissCompanyAlert(arg.alertId);        
    }
}

export function createDismissAlertSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: dismissAlert.type,
        debounceInMilliseconds: 250
    });
};