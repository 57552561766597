import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { isValue } from "../../../../../utils/valueHelper";
import { Category } from "../../../categoryManagement/store/types";
import { categoryAdapter } from "../state";
import { getMenuSectionById } from "./menuSections";

const categorySelectors = categoryAdapter.getSelectors((state: RootState) => state.menuAdmin.categories);

export const getAvailableCategories = categorySelectors.selectAll;

export const getAvailableCategoryIds = categorySelectors.selectIds;

export const getCategoryById = categorySelectors.selectById;

export const getCategoriesForMenuSection = createSelector(
    [
        getAvailableCategories,
        getMenuSectionById
    ],
    (allCategories, menuSection) => {

        const categories: Category[] = isValue(menuSection)
            ? allCategories.filter(c => menuSection.categoryIds.includes(c.id))
            : [];

        return categories;
    }
);

export const getCategoriesLoadState = (state: RootState) => state.menuAdmin.categoriesLoadState;