import { sum } from "lodash";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { currencyFormatOptions, formatAsCurrency } from "../../../../utils/formatHelpers";
import { createCalculatedValueColumnProps } from "../../../../utils/table/calculatedValueColumn";
import { createFillRemainingSpaceColumnProps } from "../../../../utils/table/fillRemainingSpaceColumn";
import { createFilteredDateColumnProps } from "../../../../utils/table/filteredDateColumn";
import { createMultiActionColumnProps } from "../../../../utils/table/multiActionColumn";
import { createNumberColumnProps } from "../../../../utils/table/numberColumn";
import { createFilteredTextColumnProps } from "../../../../utils/table/stringColumn";
import { TypedMenuItem } from "../../../../utils/table/typedMenuItem";
import { smallDeviceQuery, smallDeviceTableBreakpoint } from "../../../responsiveconfiguration";
import { isTerminalOrderStatus, SalesOrderItem } from "../../common/types";
import { deleteOrderItem } from "../store/featureActions/deleteOrderItem";
import { editOrderItemBegin } from "../store/featureActions/editOrderItem";
import { getOrderStatus } from "../store/selectors/order";
import { getOrderItems } from "../store/selectors/orderItem";
import { getProductMap } from "../store/selectors/product";

export const OrderItems: React.FC = () => {

    const dispatch = useDispatch();
    const orderItems = useAppSelector(getOrderItems);
    const productIdMap = useAppSelector(getProductMap);
    const orderStatus = useAppSelector(getOrderStatus);
    const isSmallDevice = useMediaQuery(smallDeviceQuery);

    const onAddNewOrderItem = (_: React.MouseEvent) => {
        dispatch(editOrderItemBegin(null));
    }

    const onEdit = (orderItem: SalesOrderItem) => {
        dispatch(editOrderItemBegin(orderItem));
    }

    const orderItemActions: TypedMenuItem<SalesOrderItem>[] = [
        {
            label: "Delete",
            icon: PrimeIcons.TIMES,
            promptBeforeInvoke: "Are you sure you want to delete this record?",
            command: (orderItem) => {
                dispatch(deleteOrderItem(orderItem));
            }
        }
    ];

    const columns: ColumnProps[] = [
        createMultiActionColumnProps<SalesOrderItem>({
            columnKey: "action-column",
            actionName: "Edit",
            actionHandler: onEdit,
            header: "Actions",            
            style: { width: 100 },
            sortable: false,
            menuItems: orderItemActions,
            disabled: (_) => isTerminalOrderStatus(orderStatus)
        }),
        createFilteredDateColumnProps<SalesOrderItem>({ field: "createdUtc", header: "Created", style: { width: 150 } }),
        createCalculatedValueColumnProps<SalesOrderItem>({
            columnKey: "productName",
            getValueFn: (orderItem) => {
                return productIdMap[orderItem.productId]?.metrcName;
            },
            header: "Product",
            style: { width: 300 },
            sortable: true,
            filter: true
        }),
        createFilteredTextColumnProps<SalesOrderItem>({ field: "inventoryName", header: "Package", style: { width: 300 }, sortable: true }),
        createNumberColumnProps<SalesOrderItem>({
            field: "quantity",
            header: "Quantity",
            style: { width: 150 },
            sortable: true,
            filter: true,
            formatOptions: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
            align: "right"
        }),
        createFilteredTextColumnProps<SalesOrderItem>({ field: "unitOfMeasure", header: "Units", style: { width: 100 }, sortable: true }),
        createNumberColumnProps<SalesOrderItem>({
            field: "price",
            header: "Price",
            style: { width: 150 },
            sortable: true,
            filter: true,
            formatOptions: currencyFormatOptions,
            footer: formatAsCurrency(sum(orderItems.map(item => item.price)))
        }),
        createFillRemainingSpaceColumnProps({}),
    ];

    return <FlexColumnContainer
        gap="20px"
    >
        <FlexRowContainer>
            <Button 
                label="Add Item" 
                onClick={onAddNewOrderItem} 
                disabled={isTerminalOrderStatus(orderStatus)}
            />
        </FlexRowContainer>
        <DataTable
            size="small"
            dataKey="id"
            value={orderItems}
            breakpoint={smallDeviceTableBreakpoint}
            scrollable={isSmallDevice ? false : true}
            scrollDirection={isSmallDevice ? "vertical" : 'both'}
            scrollHeight={isSmallDevice ? "flex" : "flex"}
            responsiveLayout={isSmallDevice ? "stack" : undefined}
            paginator
            alwaysShowPaginator={false}
            paginatorPosition="top"
            rows={20}
            rowsPerPageOptions={[10, 20, 50]}
        >
            {columns.map(c => <Column key={c.columnKey ?? c.field} {...c} />)}
        </DataTable>
    </FlexColumnContainer>
} 