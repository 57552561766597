import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createSelectorHook } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { IMarketplaceApiClient, MarketplaceApiClient } from '../features/apiCommunication/marketplaceApiClient';
import AuthService from '../features/authentication/authService';
import { appHistory } from './historyInstance';
import rootReducer, { RootState } from './rootReducer';
import { createRootSaga } from './rootSaga';

const configureAppStore = () => {

    const marketplaceApiClient: MarketplaceApiClient = new MarketplaceApiClient();    
    const sagaMiddleware = createSagaMiddleware();

    const store = configureStore({
        reducer: rootReducer,        
        middleware: (getDefaultMiddleWare) => {
            return getDefaultMiddleWare({ 
                thunk: { 
                    extraArgument: {
                        marketplaceApiClient: marketplaceApiClient    
                    }
                },
                serializableCheck: false,
                immutableCheck: true              
            })
            .concat(sagaMiddleware)
            .concat(routerMiddleware(appHistory))
        },
        devTools: true
    });
    
    marketplaceApiClient.addGetUserAuth(() => store.getState().authentication);    
  
    const authService = new AuthService(store.dispatch);

    sagaMiddleware.run(createRootSaga({ 
        marketplaceApiClient: marketplaceApiClient,
        authService: authService, 
        getState: store.getState, 
        dispatch: store.dispatch 
    }));

    return store;
};

const store = configureAppStore();

export default store;

export type AppStore = typeof store;

export type AppThunkExtraArg = {
    apiClient: IMarketplaceApiClient
};

export const useAppSelector = createSelectorHook<RootState>();