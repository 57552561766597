import partialHasChanges from "../../../../../utils/changeDetection";
import { isValue } from "../../../../../utils/valueHelper";
import { QualityMetricValue } from "../../../models/metricValue";
import { metricValueAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    upsertQualityMetricValue,
    upsertQualityMetricValueReducer,
    upsertQualityMetricValueSagaFactory

} = actionFactory
    .withMappedName("upsertQualityMetricValue")
    .createAsyncAction<QualityMetricValue, QualityMetricValue>({
        actionStatusSelector: state => state.metricValueSaveState,
        asyncCall: (arg, context) => {            
            return context.marketplaceApiClient.quality.upsertQualityMetricValue(arg);
        },
        onFulfilled: (state, action) => {
            metricValueAdapter.upsertOne(state.metricValues, action.payload.result);
        },
        predicate: (arg, context) => {
            if (arg.id > 0) {
                const sliceState = context.getState().qualityMetricKinds;
                const target = sliceState.metricValues.entities[arg.id];
                if (isValue(target)) {
                    return partialHasChanges(target, arg);
                }
            }
            return true;
        }
    });