import { isValue } from "./valueHelper";

export const nullableNumberCompare = (left: number | null | undefined, right: number | null | undefined): number => {
    if (isValue(left) && isValue(right)) {
        return left - right
    }
    else if (!isValue(left) && isValue(right)) {
        return -1;
    }
    else if (isValue(left) && !isValue(right)) {
        return 1;
    }
    else {
        return 0;
    }
}

export const isValueWithinBounds = (value: number, lowerBound: number, upperBound: number, precisionDigits: number = 2, epsilon?: number): boolean => {

    const multiplier = Math.pow(10, precisionDigits + 1);
    const epsilonValue = epsilon ?? (1 / multiplier);

    const scaledValue = value * multiplier;
    const scaledLowerBound = (lowerBound - epsilonValue) * multiplier;
    const scaledUpperBound = (upperBound + epsilonValue) * multiplier;

    const roundedValue = Math.round(scaledValue);
    const roundedLowerBound = Math.round(scaledLowerBound);
    const roundedUpperBound = Math.round(scaledUpperBound);

    return roundedLowerBound <= roundedValue && roundedValue <= roundedUpperBound;
}

export const parseInteger = (value: string | null | undefined): number | undefined => {
    if (!isValue(value)) {
        return undefined;
    }
    else {
        const integer = parseInt(value);
        return !isNaN(integer)
            ? integer
            : undefined;
    }
}

export const formatAsInteger: (value: number | null | undefined) => string = (value) => {
    if (!isValue(value))
        return "";

    return value.toLocaleString(undefined, { maximumFractionDigits: 0 });
};

export const twoFractionalDigits: (value: number | null | undefined) => string = (value) => {
    if (!isValue(value))
        return "";

    return value.toLocaleString(undefined, { maximumFractionDigits: 2 });
};

export const fourFractionalDigits: (value: number | null | undefined) => string = (value) => {
    if (!isValue(value))
        return "";

    return value.toLocaleString(undefined, { maximumFractionDigits: 4 });
};

export const isNumberType = (value: any): value is number => {
    return typeof (value) === "number";
}