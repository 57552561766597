import { Load8Result, parallelLoad } from "../../../../../utils/promiseHelpers";
import { AssessmentTemplate } from "../../../models/assessmentTemplate";
import { assignChildrenNodes, TemplateConstraintNode } from "../../../models/constraintNode";
import { QualityGradeKind } from "../../../models/gradeKind";
import { QualityGradeValue } from "../../../models/gradeValue";
import { QualityMetricKind } from "../../../models/metricKind";
import { QualityMetricValue } from "../../../models/metricValue";
import { TemplateConstraint } from "../../../models/templateConstraint";
import { TemplateMetric } from "../../../models/templateMetric";
import { getInitialExpansionStateForGradeKind } from "../logic/expandedTreeNodes";
import { expandedTreeNodeAdapter, gradeValueAdapter, metricKindAdapter, templateConstraintAdapter, templateConstraintNodeAdapter, templateMetricAdapter } from "../state";
import { actionFactory, TreeNodeExpandedKeys } from "../types";

type LoadResult = Load8Result<
    AssessmentTemplate,
    TemplateConstraint[],
    TemplateMetric[],
    QualityGradeKind[],
    QualityMetricKind[],
    QualityGradeValue[],
    QualityMetricValue[],
    TemplateConstraintNode[]
>;

export const {

    loadSliceData,
    loadSliceDataReducer,
    loadSliceDataSagaFactory

} = actionFactory
    .withMappedName("loadSliceData")
    .createAsyncAction<number, LoadResult>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {
            return parallelLoad(
                () => context.marketplaceApiClient.quality.getAssessmentTemplateById(arg),
                () => context.marketplaceApiClient.quality.getTemplateConstraints(arg),
                () => context.marketplaceApiClient.quality.getTemplateMetrics(arg),
                () => context.marketplaceApiClient.quality.getQualityGradeKinds(),
                () => context.marketplaceApiClient.quality.getQualityMetricKinds(),
                () => context.marketplaceApiClient.quality.getQualityGradeValues(),
                () => context.marketplaceApiClient.quality.getQualityMetricValues(),
                () => context.marketplaceApiClient.quality.getTemplateConstraintNodes(arg),
            )
        },
        onFulfilled: (state, action) => {
            state.template = action.payload.result.result1;

            const templateConstraints = action.payload.result.result2;
            templateConstraintAdapter.setAll(state.templateConstraints, templateConstraints);

            templateMetricAdapter.setAll(state.templateMetrics, action.payload.result.result3);

            const gradeKinds = action.payload.result.result4;
            state.gradeKinds = gradeKinds;

            metricKindAdapter.setAll(state.metricKinds, action.payload.result.result5);

            const gradeValues = action.payload.result.result6;
            gradeValueAdapter.setAll(state.gradeValues, gradeValues);

            state.metricValues = action.payload.result.result7;

            const constraintNodes = action.payload.result.result8;
            assignChildrenNodes(constraintNodes);
            templateConstraintNodeAdapter.setAll(state.constraintNodes, constraintNodes);

            const expandedKeys: TreeNodeExpandedKeys[] = getInitialExpansionStateForGradeKind(
                templateConstraints,
                constraintNodes
            );
                
            expandedTreeNodeAdapter.setAll(state.constraintTreeExpandedKeys, expandedKeys)
        }
    });