import { EntityState } from "@reduxjs/toolkit";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ModalEditState } from "../../../../store/factory/modalEdit/modalEdit";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { QualityGradeKind } from "../../models/gradeKind";
import { QualityGradeValue } from "../../models/gradeValue";

export const sliceName: string = "quality-grade-kinds";

export const actionFactory = new ActionFactory<QualityGradeKindsState>(sliceName);

export interface QualityGradeKindsState {
    loadingState: ActionStatus;
    addGradeKindState: ModalEditState<QualityGradeKind>;
    selectedGradeKindId: number | null;
    gradeValueSaveState: ActionStatus;
    gradeKinds: EntityState<QualityGradeKind>;
    gradeValues: EntityState<QualityGradeValue>;
}