import { createEntityAdapter } from '@reduxjs/toolkit';
import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { CompanyMarketplaceViewState } from './types';
import { Menu } from '../../../menu/editor/store/types';
import { EntityImage } from '../../../commonModels/entityImage';

export const menuAdapter = createEntityAdapter<Menu>();
export const menuImageAdapter = createEntityAdapter<EntityImage>();

export const createInitialState = (): CompanyMarketplaceViewState => {
    return {
        loadingState: CreateActionStatusInitialState(),
        menus: menuAdapter.getInitialState(),
        menuImages: menuImageAdapter.getInitialState(),
        companyProfile: null,
        vendorBanner: null,
        vendorBannerLoadingState: CreateActionStatusInitialState(),
        vendorLogo: null,
        vendorLogoLoadingState: CreateActionStatusInitialState()
    };
};

export const initialState: CompanyMarketplaceViewState = createInitialState();