import { Column, ColumnProps } from "primereact/column";
import React from 'react';

export interface RowReorderColumnOptions extends Omit<ColumnProps, "field" | "rowReorder"> {}

export function createRowReorderColumn(props: RowReorderColumnOptions): React.ReactNode {
    return <Column key={props.columnKey ?? "row-reorder"} {...props} rowReorder />;
}

export function createRowReorderColumnProps(props: RowReorderColumnOptions): ColumnProps {
    return {
        ...props,
        columnKey: "row-reorder",
        rowReorder: true
    };
}
