import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { isValue } from "../../../../../utils/valueHelper";
import { viewSettingsAdapter, userTableSettingsAdapter } from "../state";
import { RemountState, SaveAsModel } from "../types";

export const getUserTableSettingsLoaded = (state: RootState) => state.userTableSettings.userTableSettingsLoadingState.hasExecuted ?? false;

const viewSettingsSelectors = viewSettingsAdapter.getSelectors((state: RootState) => state.userTableSettings.viewSettings);
export const getSettingsForView = viewSettingsSelectors.selectById;

const userTableSettingsSelectors = userTableSettingsAdapter.getSelectors((state: RootState) => state.userTableSettings.userTableSettings);


export const getUserTableSettingsForView = createSelector([
    userTableSettingsSelectors.selectAll,
    (_state: RootState, viewId: string) => viewId,
], (userTableSettings, viewId) => {
    return userTableSettings.filter(settings => settings.viewId === viewId);
});

export const getColumnStateForView = createSelector([
    getSettingsForView
], (viewSettings) => {
    return viewSettings?.currentState.columnState ?? [];
});

export const getRemountStateForView = createSelector([
    getSettingsForView
], (viewSettings) => {
    const remountState: RemountState = viewSettings?.remountState ?? {
        remountInProgress: false,
        showTable: true,
        tableRemountNeeded: false,
        needsStateReapplied: false
    };
    return remountState;
});

export const getSelectedUserSettingsIdForView = createSelector([
    getSettingsForView
], (viewSettings) => {
    return viewSettings?.selectedUserTableSettings;
});

export const getRestoreTableStateForView = createSelector([    
    getSettingsForView
], (viewSettings) => {
    if (!isValue(viewSettings)) return undefined;
    return viewSettings.currentState.additionalSettings;    
});

export const getSaveAsState = (state: RootState) => state.userTableSettings.saveAsState;

export const getSaveAsEditModel = (state: RootState): SaveAsModel => state.userTableSettings.saveAsState;

export const getSaveAsStatus = (state: RootState) => state.userTableSettings.saveAsState.savingState;

export const getSaveAsVisible = (state: RootState) => state.userTableSettings.saveAsState.visible;