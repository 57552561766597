import { DateTimeOrNullKeyOf } from "../../../utils/typeHelpers";
import ApiClient from "../../apiCommunication/apiClient";
import { createDeserializeUtcToLocalDateFns } from "../../apiCommunication/serialization";
import { PurchaseOrder } from "../sharedTypes";

const orderDateProperties: (DateTimeOrNullKeyOf<PurchaseOrder>)[] = [];
export const { single: processOrder, set: processOrders } = createDeserializeUtcToLocalDateFns<PurchaseOrder>(orderDateProperties);

export default class Orders {

    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    getBuyerOrders = (): Promise<PurchaseOrder[]> => {
        return this
            .apiClient
            .executeGet<PurchaseOrder[]>(`order/buyer`)
            .then(processOrders);
    };

}