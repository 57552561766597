import { actionFactory, Menu } from "../types";

export const {

    loadSliceData,
    loadSliceDataReducer: loadSliceDataReducers,
    loadSliceDataSagaFactory: createLoadSliceDataSaga

} = actionFactory
    .withMappedName("loadSliceData")
    .createAsyncAction<{ menuId: number }, Menu>({
        actionStatusSelector: state => state.logoState.loadState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.menuAdmin.getMenu(arg.menuId);
        },
        onFulfilled: (state, action) => {
            state.menu = action.payload.result;
        }
    });    