import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { BulkAssignmentKind } from "../../../../../commonModels/bulkAssignmentState";
import { actionFactory, ProductManagementState } from "../../types";

export const beginBulkPricingModelAssignment = actionFactory.createAppAction<BulkAssignmentKind>("beginBulkPricingModelAssignment");

export function beginBulkPricingModelAssignmentReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forAction(beginBulkPricingModelAssignment)
        .addHandler((state, action) => {
            state.bulkPricingModelAssignmentState.products = state.selectedProducts;
            state.bulkPricingModelAssignmentState.bulkAssignmentKind = action.payload;
        })
        .build();
}