import { createEntityAdapter } from '@reduxjs/toolkit';
import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { ViewSettings, UserTableSettings, UserTableSettingsState, ViewDefinition } from './types';

export const viewSettingsAdapter = createEntityAdapter<ViewSettings>();
export const userTableSettingsAdapter = createEntityAdapter<UserTableSettings>();
export const viewDefinitionsAdapter = createEntityAdapter<ViewDefinition>();

export const createInitialState = (): UserTableSettingsState => {
    return {
        userTableSettingsLoadingState: CreateActionStatusInitialState(),
        userTableSettingsSaveState: CreateActionStatusInitialState(),
        viewSettings: viewSettingsAdapter.getInitialState(),
        userTableSettings: userTableSettingsAdapter.getInitialState(),
        saveAsState: {
            newName: "",
            viewId: "",
            visible: false,
            savingState: CreateActionStatusInitialState()
        },
        commandBarActionState: CreateActionStatusInitialState(),
        viewDefinitions: viewDefinitionsAdapter.getInitialState()
    };
};

export const initialState: UserTableSettingsState = createInitialState();