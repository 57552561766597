import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { loadSliceDataSagaFactory } from "../featureActions/loadSliceData";
import { addCompanySagaFactory } from "../featureActions/addCompany";

// Register this method in the root saga
export function createNodeAdminProvisioningSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        loadSliceDataSagaFactory(context),
        addCompanySagaFactory(context)
    ]
}