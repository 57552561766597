import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { EntityState } from "@reduxjs/toolkit";
import { QualityAssessment } from "../../models/qualityAssessment";
import { QualityMetricKind } from "../../../qualityGradeTemplates/models/metricKind";
import { QualityMetricValue } from "../../../qualityGradeTemplates/models/metricValue";
import { QualityGradeKind } from "../../../qualityGradeTemplates/models/gradeKind";
import { QualityGradeValue } from "../../../qualityGradeTemplates/models/gradeValue";
import { QualityAssessmentValue } from "../../models/qualityAssessmentValue";
import { TemplateMetric } from "../../../qualityGradeTemplates/models/templateMetric";
import { AssessmentTemplate } from "../../../qualityGradeTemplates/models/assessmentTemplate";
import { ActionStatus, EntityActionStatus } from "../../../actionStatus/actionStatus";
import { ModalEditState } from "../../../../store/factory/modalEdit/modalEdit";

export const sliceName: string = "quality-assessment-editor";

export const actionFactory = new ActionFactory<QualityAssessmentEditorState>(sliceName);

export interface ModalAssessmentEditArgs {
    qualityAssessmentId: number,
    loadInventoryOnComplete?: boolean,
    loadInventoryIds?: number[];
}

export interface QualityAssessmentEditorState {
    loadAssessmentState: EntityState<EntityActionStatus>;
    loadReferenceDataState: ActionStatus;
    saveAssessmentState: EntityState<EntityActionStatus>;
    saveValueState: EntityState<EntityActionStatus>;

    // instance data
    assessments: EntityState<QualityAssessment>;
    assessmentValues: EntityState<QualityAssessmentValue>;

    // reference data
    metricKinds: EntityState<QualityMetricKind>;
    metricValues: EntityState<QualityMetricValue>;
    gradeKinds: EntityState<QualityGradeKind>;
    gradeValues: EntityState<QualityGradeValue>;
    templateMetrics: EntityState<TemplateMetric>;
    templates: EntityState<AssessmentTemplate>;

    modalEditState: ModalEditState<ModalAssessmentEditArgs>;
}