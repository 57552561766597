import clsx from 'clsx';
import { Field, FieldProps, FormikContextType, useFormikContext } from "formik";
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeParams, DropdownFilterParams } from 'primereact/dropdown';
import { CSSProperties } from 'react';
import { SelectItem } from '../../features/commonModels/selectItem';
import { isValue } from '../../utils/valueHelper';
import { FlexRowContainer } from '../containers/flexRowContainer';
import { FormikEditorClassStyles } from './classNameAttributes';

export interface DropDownFieldProps<TModel, TValue> extends FormikEditorClassStyles {
    defaultLabel: string;
    fieldName: string & keyof TModel;
    availableValues: SelectItem<TValue>[];
    containerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    panelStyle?: CSSProperties;
    placeHolder?: string;
    allowClear?: boolean;
    submitOnBlur?: boolean;
    submitOnChange?: boolean;
    disabled?: boolean;
    onFilter?: (searchText: string | null) => void;
    onSelectionChanged?: (value: TValue) => void;
    showErrors?: boolean;
}


export const DropDownField = <TModel, TValue>({
    defaultLabel,
    fieldName,
    availableValues,
    containerStyle,
    containerClassName,
    labelClassName,
    inputClassName,
    labelStyle,
    inputStyle,
    panelStyle,
    placeHolder,
    disabled,
    submitOnChange = false,
    allowClear = false,
    submitOnBlur = false,
    onFilter,
    onSelectionChanged,
    showErrors = false
}: DropDownFieldProps<TModel, TValue>) => {

    const formikProps: FormikContextType<TModel> = useFormikContext<TModel>();
    const labelText = defaultLabel;

    const onDropDownFilter = (e: DropdownFilterParams) => {
        if (isValue(onFilter)) {
            onFilter(e.filter);
        }
    }

    return <div style={containerStyle} className={containerClassName}>

        <label className={labelClassName} style={labelStyle} htmlFor={fieldName} >{labelText}</label>
        <Field
            name={fieldName}
        >
            {


                (props: FieldProps<TModel>) =>
                    <FlexRowContainer centerContent>
                        <Dropdown
                            id={fieldName}                            
                            name={fieldName}
                            options={availableValues}
                            placeholder={placeHolder}
                            style={inputStyle}
                            panelStyle={panelStyle ?? inputStyle}
                            className={clsx(inputClassName, { "p-invalid": !!formikProps.errors[fieldName] })}
                            onChange={(e: DropdownChangeParams) => {
                                formikProps.setFieldValue(props.field.name, e.value);
                                if (submitOnChange) {
                                    //formikProps.submitForm();
                                    formikProps
                                        .validateForm()
                                        .then(_errors => {
                                            formikProps.submitForm();
                                        }) 
                                }
                                if (isValue(onSelectionChanged)) {
                                    onSelectionChanged(e.value);
                                }
                            }}
                            onBlur={(_event) => {
                                if (submitOnBlur && formikProps.dirty) {
                                    formikProps.submitForm();
                                }
                            }}
                            value={formikProps.values[fieldName]}
                            filter
                            onFilter={onDropDownFilter}
                            resetFilterOnHide={false}
                            showFilterClear={true}
                            disabled={disabled}
                        />
                        {
                            allowClear &&
                            <Button
                                type="button"
                                icon={PrimeIcons.TIMES}
                                className="p-button-outlined p-button-rounded p-ml-2 clearable-input-number--button"
                                onClick={() => formikProps.setFieldValue(props.field.name, null)}
                            />
                        }
                    </FlexRowContainer>
            }
        </Field>
        {
            showErrors &&
            <div>
                <small>{formikProps.errors[fieldName]}</small>
            </div>
        }

    </div>
}