import { DateTime, DateTimeFormatOptions, LocaleOptions } from "luxon";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { ColumnFilterParams, ColumnProps } from "primereact/column";
import React from 'react';
import { DateTimeOrNullKeyOf, getDateTimeOrNullValue, PropertiesOfType } from "../typeHelpers";
import { isValue } from "../valueHelper";
import { DateFilterElement, DateFilterModel } from "./filters/dateFilterElement";

// DateTime

export interface CreateFilteredDateColumnOptions<T> extends Omit<ColumnProps, "field" | "header" | "body"> {
    field: DateTimeOrNullKeyOf<T> & string;
    header: string;
    formatOptions?: LocaleOptions & DateTimeFormatOptions;
}

export const ShortDateFormatOptions: LocaleOptions & DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"    
};

function createLuxonDateTemplate<T>(
    getDateFn: (rowData: T) => DateTime | null | undefined,
    formatOptions: LocaleOptions & DateTimeFormatOptions): (rowData: T) => React.ReactNode {
    return (value: T) => {
        const dateValue = getDateFn(value);
        return <div>
            {!!dateValue
                ? dateValue.toLocaleString(formatOptions)
                : ""}
        </div>;
    };
}

const filterFunction = (value: DateTime | null | undefined, filter: DateFilterModel, _filterLocale: string, _params: ColumnFilterParams): boolean => {

    if (!isValue(filter)) return true;
    if (!isValue(value)) return false;
    
    const afterMatches = (!isValue(filter.after) && filter.filterMatchMode === FilterOperator.AND) || 
        (isValue(filter.after) && value > filter.after);

    const beforeMatches = (!isValue(filter.before)  && filter.filterMatchMode === FilterOperator.AND) || 
        (isValue(filter.before) && value < filter.before);

    const onMatches = (!isValue(filter.on) && filter.filterMatchMode === FilterOperator.AND) || 
        (isValue(filter.on) && value.year === filter.on.year && value.month === filter.on.month && value.day === filter.on.day);

    return filter.filterMatchMode === FilterOperator.AND
        ? afterMatches && beforeMatches && onMatches
        : afterMatches || beforeMatches || onMatches;
}

export function createFilteredDateColumnProps<T extends PropertiesOfType<T, DateTime | null | undefined>>(
    props: CreateFilteredDateColumnOptions<T>
): ColumnProps {

    const getValueFn = (rowData: T) => getDateTimeOrNullValue(rowData, props.field);
    const formatOptions = props.formatOptions ?? ShortDateFormatOptions;

    return {
        sortable: true,
        ...props,
        body: createLuxonDateTemplate(getValueFn, formatOptions),
        filter: true,
        filterType: "date",
        dataType: "date",
        showFilterMatchModes: false,
        showFilterMenuOptions: false,
        showFilterOperator: false,        
        showAddButton: false,
        filterField: props.field,
        filterMatchMode: FilterMatchMode.CUSTOM,
        filterElement: (elementOptions) => <DateFilterElement elementOptions={elementOptions} />,
        filterFunction: filterFunction
    }    
}