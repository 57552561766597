import { createEntityAdapter } from '@reduxjs/toolkit';
import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { CorporateEntity, CorporateEntityState } from './types';

export const corporateEntityAdapter = createEntityAdapter<CorporateEntity>();

export const createInitialState = (): CorporateEntityState => {
    return {        
        loadingState: CreateActionStatusInitialState(),      
        corporateEntities: corporateEntityAdapter.getInitialState(),
        entityEditDialogVisible: false,
        entityEditValue: null,
        corporateEntityFilter: {
            inactiveValues: [false]
        }
    };
};

export const initialState: CorporateEntityState = createInitialState();