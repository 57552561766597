import { isValue } from "../../../../../../utils/valueHelper";
import { ImageKind } from "../../../../../commonModels/imageTypes";
import { actionFactory } from "../../types";
import { loadLogo } from "./loadLogo";

export const {

    saveLogo,
    saveLogoReducer,
    saveLogoSagaFactory

} = actionFactory
    .withMappedName("saveLogo")
    .createAsyncAction<void, Response>({
        actionStatusSelector: state => state.logoState.saveState,
        asyncCall: async (_arg, context) => {
            const state = context.getState();

            const menuId = state.menuAdmin.menu?.id;
            if (!isValue(menuId)) throw new Error("Cannot load image without valid menu instance");

            if (state.menuAdmin.logoState.newImagePreview !== undefined) {
                const blob = await fetch(state.menuAdmin.logoState.newImagePreview).then(localFile => localFile.blob());
                return context.marketplaceApiClient.menuAdmin.uploadImage(menuId, blob, ImageKind.Logo);
            }

            throw new Error("Attempt to save logo without new image specified")
        },
        onFulfilled: (state, _action) => {
            state.logoState.newImagePreview = undefined;
        },
        successCallback: (_arg, _result, context) => {
            const menuId = context.getState().menuAdmin.menu?.id;
            if (!isValue(menuId)) throw new Error("Menu instance was not valid");
            context.dispatch(loadLogo({ menuId: menuId }));
        }
    });