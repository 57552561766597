import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { isValue } from "../../../../../utils/valueHelper";
import { WorkflowStep } from "../types";
import { getCompanyProfileIsValid } from "./companyProfile";

export const getCurrentWorkflowStep = createSelector(
    [
        (state: RootState) => state.initialSetup.userSelectedWorkflowStep,        
        getCompanyProfileIsValid
    ], 
    (userSelectedStep, companyProfileIsValid) => {
        if (isValue(userSelectedStep)){
            return userSelectedStep;
        }       
        else if (!companyProfileIsValid){
            return WorkflowStep.CompanyInfo;
        }
        else {
            return WorkflowStep.UserInfo;
        }
    }
);