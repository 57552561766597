import { DateTime } from "luxon";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { QueueStatus } from "../../../commonModels/queueStatus";

export const sliceName: string = "buyer-verify";

export const actionFactory = new ActionFactory<BuyerVerificationState>(sliceName);

export interface VerifyRequest {    
    apiKey: string;
    license: string;        
}

export interface ApiKeyVerification {
    id: number;
    tenantId: string;
    license: string;
    companyId: number;
    createdById: number;
    createdUtc: DateTime;
    verifiedDateUtc: DateTime | null;
    status: QueueStatus;
    errorMessage: string;
}

export interface BuyerVerificationState {
    loadingState: ActionStatus;  
    submitState: ActionStatus;
    verifications: ApiKeyVerification[];      
}