import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { isValue } from "../../../../../utils/valueHelper";
import { actionFactory, MenuAdminState } from "../types";

export const editMenuName = actionFactory.createAppAction<string>("editMenuName");

export function editMenuNameReducers(reducerFactory: IReducerFactory<MenuAdminState>): IReducerFactory<MenuAdminState> {
    return reducerFactory        
        .forAction(editMenuName)
        .addHandler((state, action) => {
            if (!isValue(state.menu)) return;
            
            state.menu.name = action.payload;
        })
        .build();
}