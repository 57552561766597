import clsx from "clsx";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Menu } from 'primereact/menu';
import { MenuItem } from "primereact/menuitem";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import packageJson from '../../../../../package.json';
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { AlertsBar } from "../../../alerts/appAlerts/ui/alertsBar";
import { beginLogout } from "../../../authentication/store/featureActions/beginLogout";
import { getIsAuthenticated } from "../../../authentication/store/selectors/isAuthenticated";
import { getUserName } from "../../../authentication/store/selectors/userName";
import { getUserIsMarketplaceUser, getUserRoles } from "../../../authentication/store/selectors/userRoles";
import { SecurityRole } from "../../../authorization/roles";
import { loadMarketplaceProfile } from "../../../marketplaceUserProfile/manageProfile/store/featureActions/loadProfile";
import { getUserProfile } from "../../../marketplaceUserProfile/manageProfile/store/selectors/userProfile";
import { manageProfileRoute } from "../../../navigation/routes";
import { smallDeviceQuery } from "../../../responsiveconfiguration";

const WithSessionMenu: React.FC = () => {
    const withSessionMenuRef = useRef<Menu>(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const userProfile = useAppSelector(getUserProfile);
    const userName = useAppSelector(getUserName);
    const isLoggedIn = useAppSelector(getIsAuthenticated);
    const isMarketplaceUser = useAppSelector(getUserIsMarketplaceUser);
    const roles = useAppSelector(getUserRoles);
    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    const currentVersion = packageJson.version;

    useEffect(() => {
        if (isLoggedIn && !isValue(userProfile)) {
            dispatch(loadMarketplaceProfile());
        }
    }, [dispatch, isLoggedIn, userProfile]);

    const onToggleWithSessionMenu = (event: React.MouseEvent) => {
        if (withSessionMenuRef.current === null) return;
        withSessionMenuRef.current.toggle(event);
    }

    const onLogoutClick = () => {
        dispatch(beginLogout());
    }

    const onAdminManageUsers = () => {
        window.open(`${process.env.REACT_APP_IDENTITY_URL}Identity/Account/ManageTenantUsers`, '_blank');
    }

    const onManageAccount = () => {
        window.open(`${process.env.REACT_APP_IDENTITY_URL}Identity/Account/Manage`, '_blank');
    }

    const onManageMarketplaceProfile = () => {
        history.push(manageProfileRoute.path);
    }

    const withSessionMenuItems: MenuItem[] = [];

    if (roles.includes(SecurityRole.TenantAdmin)) {
        withSessionMenuItems.push({
            label: "User Admin",
            icon: PrimeIcons.USERS,
            command: onAdminManageUsers
        });
    }

    withSessionMenuItems.push({
        label: "Account",
        icon: PrimeIcons.USER_EDIT,
        command: onManageAccount
    });

    if (isMarketplaceUser) {
        withSessionMenuItems.push({
            label: "User Profile",
            icon: PrimeIcons.USER_PLUS,
            command: onManageMarketplaceProfile
        });
    }

    withSessionMenuItems.push({
        label: "Logout",
        icon: PrimeIcons.POWER_OFF,
        command: onLogoutClick
    });

    withSessionMenuItems.push({
        label: `v ${currentVersion}`,
        disabled: true
    });

    return <div className="p-d-flex p-flex-row p-jc-end">
        <AlertsBar />
        <Menu
            ref={withSessionMenuRef}
            popup
            model={withSessionMenuItems}
        />
        <Button
            icon={isSmallDevice ? PrimeIcons.USER : PrimeIcons.ANGLE_DOWN}
            iconPos="right"
            className={clsx({
                "p-button-rounded": isSmallDevice,
                "p-button-outlined": isSmallDevice,
                "p-button-text": !isSmallDevice
            })}
            onClick={onToggleWithSessionMenu}
            label={isSmallDevice ? undefined : userName}
        />

    </div>
}

export default WithSessionMenu;
