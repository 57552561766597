import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { cancelBulkClearCategoryAssignment } from "../store/featureActions/bulkClearCategoryAssignment/cancelBulkClearCategoryAssignment";
import { completeBulkClearCategoryAssignment } from "../store/featureActions/bulkClearCategoryAssignment/completeBulkClearCategoryAssignment";
import { getBulkClearCategoriesDialogVisible, getBulkClearCategoriesSavingState, getBulkClearCategoriesTitle } from "../store/selectors/bulkClearCategories";

export const BulkClearCategoriesDialog: React.FC = () => {

    const bulkClearCategoryDialogVisible = useAppSelector(getBulkClearCategoriesDialogVisible);
    const bulkClearSavingState = useAppSelector(getBulkClearCategoriesSavingState)    
    const title = useAppSelector(getBulkClearCategoriesTitle);

    const dispatch = useDispatch();

    const onHideDialog = () => { };

    const onSave = () => {
        dispatch(completeBulkClearCategoryAssignment());
    }

    const onCancel = () => {
        dispatch(cancelBulkClearCategoryAssignment());
    }

    return <Dialog
        onHide={onHideDialog}
        visible={bulkClearCategoryDialogVisible}
        closable={false}
        draggable={false}
        header={title}
    >
        <div>
            <Button className="p-ml-2" label="Save" style={{ float: "right" }} onClick={onSave} loading={bulkClearSavingState.isExecuting} />
            <Button label="Cancel" style={{ float: "right" }} onClick={onCancel} disabled={bulkClearSavingState.isExecuting} />
        </div>
    </Dialog>
}