import ApiClient from "../../../apiCommunication/apiClient";
import { InventorySearchResult, SearchRequest, VendorSearchResult } from "../store/types";

export class Search {

    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    searchInventory = (searchRequest: SearchRequest): Promise<InventorySearchResult[]> => {
        return this
            .apiClient
            .executePostWithResult<InventorySearchResult[]>(`search/inventory`, searchRequest);
    };

    searchVendor = (searchRequest: SearchRequest): Promise<VendorSearchResult[]> => {
        return this
            .apiClient
            .executePostWithResult<VendorSearchResult[]>(`search/vendor`, searchRequest);
    };
}