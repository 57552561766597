import { RootState } from "../../../../../store/rootReducer";
import { BulkAssignmentKind } from "../../../../commonModels/bulkAssignmentState";

export const getBulkQualityGradeSavingState = (state: RootState) => state.inventoryManagement.bulkQualityGradeAssignmentState.savingState;

export const getInventoryForBulkQualityGradeAssignment = (state: RootState) => state.inventoryManagement.bulkQualityGradeAssignmentState.inventory;

export const getQualityGradeToBulkAssign = (state: RootState) => state.inventoryManagement.bulkQualityGradeAssignmentState.qualityGradeIdToAssign;

export const getQualityGradeAssignmentKind = (state: RootState) => state.inventoryManagement.bulkQualityGradeAssignmentState.bulkAssignmentKind;

export const getBulkQualityGradeAssignmentDialogTitle = 
    (state: RootState) => 
        state.inventoryManagement.bulkQualityGradeAssignmentState.bulkAssignmentKind === BulkAssignmentKind.Assign
            ? `Assign Quality Grade: ${state.inventoryManagement.bulkQualityGradeAssignmentState.inventory.length} packages will be updated`
            : `Clear Quality Grade: ${state.inventoryManagement.bulkQualityGradeAssignmentState.inventory.length} packages will be updated`;