import React, { CSSProperties } from "react"

export interface FlexColumnContainerProps extends Omit<CSSProperties, "display" | "flexDirection"> {
    centerContent?: boolean;
    className?: string;
}

export const FlexColumnContainer: React.FC<React.PropsWithChildren<FlexColumnContainerProps>> = (props) => {
    return <div
        className={props.className}
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: props.centerContent ? "center" : undefined,
            alignContent: props.centerContent ? "center" : undefined,
            ...props
        }}
    >
        {props.children}
    </div>
}