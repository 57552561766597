import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { actionFactory, ProductManagementState } from "../../types";

export const cancelCategoryAssignment = actionFactory.createAppAction("cancelCategoryAssignment");

export function cancelCategoryAssignmentReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forAction(cancelCategoryAssignment)
        .addHandler((state, _) => {
            state.categoryAssignmentState.parentModel = undefined;
        })
        .build();
}