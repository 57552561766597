import { createEntityAdapter } from '@reduxjs/toolkit';
import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { EntityImage } from '../../../commonModels/entityImage';
import { InventoryView, LiveMenuState, SectionInventoryList, SectionProductList } from './types';
import { SortDirection, SortField } from "../../common/types";

export const sectionProductListAdapter = createEntityAdapter<SectionProductList>();
export const sectionInventoryListAdapter = createEntityAdapter<SectionInventoryList>();
export const inventoryAdapter = createEntityAdapter<InventoryView>();
export const inventoryImageAdapter = createEntityAdapter<EntityImage>({ selectId: (entityImage) => entityImage.owningEntityId });

export const createInitialState = (): LiveMenuState => {
    return {
        companyRouteName: null,
        vendorMenuLoadingState: CreateActionStatusInitialState(),
        vendor: null,
        vendorLoadingState: CreateActionStatusInitialState(),
        licenses: [],
        vendorMenu: null,
        menuSections: [],
        sectionLayout: "grid",
        sectionInventoryLists: sectionInventoryListAdapter.getInitialState(),
        searchText: "",
        isCurrentUserFavoriteActionState: CreateActionStatusInitialState(),
        sortSetting: {
            direction: SortDirection.Ascending,
            sortField: SortField.Name
        },
        refreshOptions: { refreshIntervalMilliseconds: 0 },
        optionDialogVisible: false,
        inventoryLookup: inventoryAdapter.getInitialState(),
        inventoryImages: inventoryImageAdapter.getInitialState(),
        inventoryImageLoadState: CreateActionStatusInitialState(),
        defaultThumbnail: null
    };
};

export const initialState: LiveMenuState = createInitialState();