import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../store/rootSaga";
import { createBeginLoginSaga } from "../featureActions/beginLogin";
import { createBeginLogoutSaga } from "../featureActions/beginLogout";
import { completeLoginSagaFactory } from "../featureActions/completeLogin";
import { createCompleteLogoutSaga } from "../featureActions/completeLogout";
import { createSilentRenewSaga } from "../featureActions/silentRenew";
import { loadExistingAuthSagaFactory } from "../featureActions/loadExistingAuth";

export function createAuthenticationSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [
        createBeginLoginSaga(context),
        completeLoginSagaFactory(context),
        createBeginLogoutSaga(context),
        createCompleteLogoutSaga(context),
        createSilentRenewSaga(context),
        loadExistingAuthSagaFactory(context)    
    ]
}