import { Route } from 'react-router-dom';
import { NotAuthorized } from '../../../components/notAuthorized';
import { SecurityRole } from '../../authorization/roles';
import * as AuthRoute from "./types";
import { RouteModel } from './types';

const userHasAccessToRouteModel = (userRoles: string[], routeModel: RouteModel): boolean => {
  if (AuthRoute.isSecuredRoute(routeModel)) {
    if (AuthRoute.isSecurityRolePredicate(routeModel.role)) {
      return routeModel.role(userRoles)
    }
    else {
      return routeModel.role === SecurityRole.Anonymous || userRoles.includes(routeModel.role);
    }
  } 
  else {
    return true;
  }
}

export const mapRouteModelToReactRoute = (userRoles: string[]) => {
  return (routeModel: RouteModel, index: number) => {
    return mapProtectedRouteModelToReactRoute(userRoles, routeModel, index)
  };
};

const mapProtectedRouteModelToReactRoute = (userRoles: string[], routeModel: RouteModel, index: number): JSX.Element => {
  return (
    <Route
      key={index}
      path={routeModel.path}
      exact={routeModel.exact}
      children={
        userHasAccessToRouteModel(userRoles, routeModel)
          ? <routeModel.view />
          : <NotAuthorized />
      } />
  )
}