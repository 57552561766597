import { TemplateConstraint } from "../../../models/templateConstraint";
import { templateConstraintAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    updateTemplateConstraint,
    updateTemplateConstraintReducer,
    updateTemplateConstraintSagaFactory

} = actionFactory
    .withMappedName("updateTemplateConstraint")
    .createAsyncAction<TemplateConstraint, TemplateConstraint>({
        actionStatusSelector: state => state.templateMetricSaveState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.quality.upsertTemplateConstraint(arg);
        },
        onFulfilled: (state, action) => {
           templateConstraintAdapter.upsertOne(state.templateConstraints, action.payload.result);           
        }
    });