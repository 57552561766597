import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { ErrorDisplayType } from "../../../../../store/factory/sagaWithProgress/types";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory, ServerHealth, ServerInformationState } from "../types";

export const forceDialogErrorInSaga = actionFactory.createAppAction("forceDialogErrorInSaga");

const sagaActions = actionFactory.createSagaWithProgressActions<void, ServerHealth>(forceDialogErrorInSaga.type);

export function forceDialogErrorInSagaReducers(reducerFactory: IReducerFactory<ServerInformationState>): IReducerFactory<ServerInformationState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)                
        .build();
}

async function performAction(_: void, context: SagaAppContext): Promise<ServerHealth> {
    return context.marketplaceApiClient.diagnostics.notFound();
}

export function createForceDialogErrorInSagaSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions,
            errorDisplay: ErrorDisplayType.Dialog,
            errorTitle: "Diagnostic Test Error"
        },
        actionPattern: forceDialogErrorInSaga.type,
        debounceInMilliseconds: 250
    });
};