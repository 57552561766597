
export enum SortField {
    Name = "Name",
    Quantity = "Quantity",
    Price = "Price",
    Strain = "Strain",
    THC = "THC"
}

export enum SortDirection {
    Ascending = "Ascending",
    Descending = "Descending"
}
