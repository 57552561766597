import { ResequenceRequest } from "../../../../pricing/sharedTypes";
import { TemplateMetric } from "../../../models/templateMetric";
import { templateMetricAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    resequenceTemplateMetric,
    resequenceTemplateMetricReducer,
    resequenceTemplateMetricSagaFactory

} = actionFactory
    .withMappedName("resequenceTemplateMetric")
    .createAsyncAction<ResequenceRequest, TemplateMetric[]>({
        actionStatusSelector: (state) => state.templateMetricSaveState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.quality.resequenceTemplateMetric(arg.entityId, arg.newSequenceValue);
        },       
        onFulfilled: (state, action) => {
            templateMetricAdapter.upsertMany(state.templateMetrics, action.payload.result);
        }
    });