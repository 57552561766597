import { CSSProperties } from "react";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { StringField } from "../../../../components/formik/stringField";
import { Contact } from "../../common/types";


export const ContactEditor: React.FC = () => {

    const labelStyle: CSSProperties = {
        display: "inline-block",
        width: "80px"
    }

    const inputStyle: CSSProperties = {        
        width: "100%"
    }

    return <FlexColumnContainer gap="20px">

        <StringField<Contact>
            fieldName="name"
            defaultLabel="Name"
            labelStyle={labelStyle}
            inputStyle={inputStyle}
        />

        <StringField<Contact>
            fieldName="email"
            defaultLabel="Email"
            labelStyle={labelStyle}
            inputStyle={inputStyle}
        />

        <StringField<Contact>
            fieldName="phoneNumber"
            defaultLabel="Phone"
            labelStyle={labelStyle}
            inputStyle={inputStyle}
        />

        <StringField<Contact>
            fieldName="position"
            defaultLabel="Position"
            labelStyle={labelStyle}
            inputStyle={inputStyle}
        />

    </FlexColumnContainer>
} 