import { Card } from "primereact/card";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { PrintContents } from "../../../../components/printing/printContents";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { isValue } from "../../../../utils/valueHelper";
import { loadLiveMenu } from "../store/featureActions/loadLiveMenu";
import { LiveMenuParams } from "./menuRouteParams";
import { PrintableSectionList } from "./printableSectionList";


export const PrintMenuDashboard: React.FC = () => {

    const dispatch = useDispatch();

    const liveMenuParams = useParams<LiveMenuParams>();

    useMountEffect(() => {
        if (isValue(liveMenuParams.vendorName) && isValue(liveMenuParams.menuName)) {
            dispatch(loadLiveMenu({ vendorName: liveMenuParams.vendorName, menuName: liveMenuParams.menuName }));
        }
    });

    return <Card className="full-height-content-panel" title="Print Menu">
        <PrintContents
            buttonStyle={{
                background: 'white',
                color: 'black',
                marginLeft: "5px",
                fontSize: "x-large"
            }}
            hidePrintedContents={false}            
        >
            <PrintableSectionList />
        </PrintContents>
    </Card>
}