import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { createSalesOrderReducer } from './featureActions/createSalesOrder';
import { deleteCustomerContactReducer } from './featureActions/deleteCustomerContact';
import { deleteCustomerNoteReducer } from './featureActions/deleteCustomerNote';
import { editContactReducers } from './featureActions/editContact';
import { loadSliceDataReducer } from './featureActions/loadSliceData';
import { saveCustomerReducer } from './featureActions/saveCustomer';
import { upsertCustomerContactReducer } from './featureActions/upsertCustomerContact';
import { upsertCustomerNoteReducer } from './featureActions/upsertCustomerNote';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(loadSliceDataReducer)
            .add(saveCustomerReducer)
            .add(upsertCustomerNoteReducer)
            .add(createSalesOrderReducer)
            .add(deleteCustomerNoteReducer)
            .add(upsertCustomerContactReducer)
            .add(deleteCustomerContactReducer)
            .addRange(editContactReducers);
            // Create your feature actions in ./featureActions and then add them to the reducer factory here
            // with .Add(<your-action-reducer-name>);
            
    } 
});

export default slice.reducer;

export const { resetState } = slice.actions;