import { ColumnProps } from "primereact/column";
import { InputNumber, InputNumberProps } from "primereact/inputnumber";
import React, { useState } from 'react';
import { getNumberOrNullValue, NumberOrNullKeyOf, PropertiesOfType } from "../typeHelpers";
import { isValue } from "../valueHelper";

export interface EditableNumberColumnOptions<T extends PropertiesOfType<T, number | null | undefined>>
    extends Omit<ColumnProps, "field" | "header" | "body"> {

    field: NumberOrNullKeyOf<T> & string;
    header: string;
    onSubmit: (rowData: T, newValue: number | null | undefined) => void;
    inputOptions: Omit<InputNumberProps, "onBlur" | "value" | "onChange">;
    allowNoValue: boolean;
}

interface NumberInputBodyProps<T> {
    rowData: T,
    value: number | null | undefined,
    onSubmit: (rowData: T, newValue: number | null | undefined) => void,
    inputOptions: InputNumberProps,
    allowNoValue: boolean
}

const NumberInputBody = <T,>({ rowData, value, onSubmit, inputOptions, allowNoValue }: NumberInputBodyProps<T>) => {

    const [numberValue, setNumberValue] = useState<number | null | undefined>(value);

    return <InputNumber
        {...inputOptions}
        value={numberValue}
        onChange={(e) => {
            if (isValue(e.value) || allowNoValue) {
                setNumberValue(e.value);
            }
            else {
                setNumberValue(0);
            }
        }}
        onBlur={(_) => {
            // The onBlur event submits the value as a string with formatting.
            // Therefore, to get just the value as a number, capture it on change 
            // and submit that value onBlur.
            if (isValue(numberValue) || allowNoValue) {
                onSubmit(rowData, numberValue);
            }
        }}
    />
}

function createEditableNumberTemplate<T>(
    getNumberFn: (rowData: T) => number | null | undefined,
    onSubmit: (rowData: T, newValue: number | null | undefined) => void,
    inputOptions: InputNumberProps,
    allowNoValue: boolean): (rowData: T) => React.ReactNode {

    return (value: T) => {

        const numberValue = getNumberFn(value);

        return <NumberInputBody<T>
            rowData={value}
            value={numberValue}
            onSubmit={onSubmit}
            inputOptions={inputOptions}
            allowNoValue={allowNoValue}
        />
    };
}

export function createEditableNumberColumnProps<T extends PropertiesOfType<T, number | null | undefined>>(props: EditableNumberColumnOptions<T>): ColumnProps {

    const getValueFn = (rowData: T) => getNumberOrNullValue(rowData, props.field);

    return {
        columnKey: props.columnKey ?? props.field,
        body: createEditableNumberTemplate<T>(getValueFn, props.onSubmit, props.inputOptions, props.allowNoValue),
        ...props
    }
}