import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, NotAuthorizedState } from "../types";


export const clearNotAuthorized = actionFactory.createAppAction("clearNotAuthorized");

export function clearNotAuthorizedReducers(reducerFactory: IReducerFactory<NotAuthorizedState>): IReducerFactory<NotAuthorizedState> {
    return reducerFactory        
        .forAction(clearNotAuthorized)
        .addHandler((state, _) => {
            state.isNotAuthorized = false;
            state.route = undefined;
        })
        .build();
}

