import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { firstOrNull } from "../../../../../utils/arrayHelpers";
import { isValue } from "../../../../../utils/valueHelper";
import { ActionStatus, CreateActionStatusInitialState } from "../../../../actionStatus/actionStatus";
import { getTenantId } from "../../../../authentication/store/selectors/tenant";
import { sectionInventoryListAdapter } from "../state";
import { InventoryView, VendorMenuSection } from "../types";
import { getSearchText } from "./search";
import { getSortFunction } from "./sort";

export const getCustomerId = (state: RootState) => state.liveMenu.vendorMenu?.companyId ?? null;

export const getMenuLoadingState = (state: RootState) => state.liveMenu.vendorMenuLoadingState;

export const getMenu = (state: RootState) => state.liveMenu.vendorMenu;

export const getLicenses = (state: RootState) => state.liveMenu.licenses;

export const getMenuIsFavorite = (state: RootState) => state.liveMenu.isCurrentUserFavorite;

export const getCustomerDisplayName = (state: RootState) => state.liveMenu.vendorMenu?.companyDisplayName ?? "";

export const getVendorMenuSections = (state: RootState): VendorMenuSection[] => state.liveMenu.menuSections;

export const getSectionLayout = (state: RootState) => state.liveMenu.sectionLayout;

export const getVendorRouteName = (state: RootState) => state.liveMenu.companyRouteName ?? "";

export const getMenuUrlName = (state: RootState) => state.liveMenu.vendorMenu?.urlName ?? "";

export const getUserOwnsMenu = createSelector([
    getMenu,
    getTenantId,

], (menu, tenant) => {
    return isValue(menu) && isValue(tenant) && menu.tenantId === tenant;
})

export const getSectionName = (state: RootState, sectionId: number) => {
    const sections = getVendorMenuSections(state);
    const section = firstOrNull(sections, section => section.id === sectionId);
    return section?.name ?? "";
};

const sectionInventorySelectors = sectionInventoryListAdapter.getSelectors((state: RootState) => state.liveMenu.sectionInventoryLists);

export const getInventoryForSection = createSelector(
    [
        sectionInventorySelectors.selectById,
        getSearchText,
        getSortFunction
    ],
    (inventorySection, searchText, sortFunction): InventoryView[] => {

        const inventoryForSection = inventorySection?.inventory ?? [];

        if (searchText.length > 0) {
            return inventoryForSection
                .filter(inv =>
                    (isValue(inv.marketingName) && inv.marketingName.toLowerCase().includes(searchText.toLowerCase())) ||
                    (isValue(inv.marketingStrainName) && inv.marketingStrainName.toLowerCase().includes(searchText.toLowerCase())) ||
                    (isValue(inv.unitOfMeasureName) && inv.unitOfMeasureName.toLowerCase().includes(searchText.toLowerCase())) ||
                    (inv.quantity.toString().toLowerCase().includes(searchText.toLowerCase())))
                .sort(sortFunction);
        }
        else {
            return inventoryForSection.slice().sort(sortFunction);
        }

    }
);

const notLoading = CreateActionStatusInitialState();

export const getInventoryLoadingStateForSection = createSelector(
    [
        sectionInventorySelectors.selectById,
    ],
    (inventorySection): ActionStatus => {

        return inventorySection?.loadingState ?? notLoading;

    }
);