import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';
import { loadProductsAndAssignmentsReducers } from './featureActions/loadProductsAndAssignments';
import { beginAssignProductsToCategoryReducers } from './featureActions/beginAssignProductsToCategory';
import { setSelectedAvailableProductsReducers } from './featureActions/setSelectedAvailableProducts';
import { assignSelectedAvailableProductsReducers } from './featureActions/assignProducts';
import { removeProductAssignmentsReducers } from './featureActions/removeProductAssignments';
import { setSelectedAssignedProductsReducers } from './featureActions/setSelectedAssignedProducts';
import { saveProductAssignmentsReducers } from './featureActions/saveProductAssignments';
import { cancelAssignProductsToCategoryReducers } from './featureActions/cancelAssignProductsToCategory';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)            
            .add(beginAssignProductsToCategoryReducers)
            .add(loadProductsAndAssignmentsReducers)
            .add(setSelectedAvailableProductsReducers)
            .add(assignSelectedAvailableProductsReducers)
            .add(removeProductAssignmentsReducers)
            .add(setSelectedAssignedProductsReducers)
            .add(saveProductAssignmentsReducers)
            .add(cancelAssignProductsToCategoryReducers);
            // Create your feature actions in ./featureActions and then add them to the reducer factory here
            // with .Add(<your-action-reducer-name>);
            
    }
});

export default slice.reducer;

export const { resetState } = slice.actions;