import { useDispatch } from "react-redux";
import { FormikEmbeddedEditor } from "../../../../components/formik/embeddedEditor";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { isTemplateConstraintNode, TemplateConstraintNode } from "../../models/constraintNode";
import { constraintNodeValidationsSchema } from "../../validations/constraintNodeValidations";
import { upsertTemplateConstraintNode } from "../store/featureActions/upsertTemplateConstraintNode";
import { getConstraintNodeById } from "../store/selectors/data";
import { ConstraintEditor } from "./constraintEditor";

export interface ConstraintFormProps {
    constraintNodeId: number;
    gradeValueId: number;
}

export const ConstraintForm: React.FC<ConstraintFormProps> = ({ constraintNodeId, gradeValueId }) => {

    const dispatch = useDispatch();
    const constraintToEdit = useAppSelector(state => getConstraintNodeById(state, constraintNodeId));

    const onSaveTemplate = (value: TemplateConstraintNode) => {
        console.log("ConstraintForm.onSaveTemplate", value);
        if (!isTemplateConstraintNode(value)) return;
        dispatch(upsertTemplateConstraintNode({ gradeValueId: gradeValueId, node: value }));
    }

    return !isValue(constraintToEdit)
        ? <div>Constraint Not Found</div>
        : <FormikEmbeddedEditor<TemplateConstraintNode>
            initialValues={constraintToEdit}
            onSave={onSaveTemplate}
            validationSchema={constraintNodeValidationsSchema}            
        >

            <ConstraintEditor />
        </FormikEmbeddedEditor>
} 