import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../../store/rootSaga";
import { isValue } from "../../../../../../utils/valueHelper";
import { EntityImage } from "../../../../../commonModels/entityImage";
import { actionFactory, InventoryManagementState } from "../../types";

export interface InventoryImageUploadArgs {
    localFileUrl: string;
    inventoryId: number;
}
export const uploadNewInventoryImage = actionFactory.createAppAction<InventoryImageUploadArgs>("uploadNewInventoryImage");

const sagaActions = actionFactory.createSagaWithProgressActions<InventoryImageUploadArgs, EntityImage>(uploadNewInventoryImage.type);

export function uploadNewInventoryImageReducers(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.editImageSaveState)
        .onFulfilled((state, action) => {
            state.editImages.push(action.payload.result);

            if (state.editImages.length === 1 && isValue(state.editMediaEntity)) {            
                state.editMediaEntity.menuThumbnailId = action.payload.result.id;
                const mainInv = state.inventory.entities[state.editMediaEntity.id];
                if (isValue(mainInv)) {
                    mainInv.menuThumbnailId = action.payload.result.id;
                }
            }

        })
        .build();
}

async function performLoad(arg: InventoryImageUploadArgs, context: SagaAppContext): Promise<EntityImage> {      
    const blob = await fetch(arg.localFileUrl).then(localFile => localFile.blob());
    return context.marketplaceApiClient.inventoryManagement.uploadInventoryImage(arg.inventoryId, blob);
}

export function createUploadNewInventoryImageSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: uploadNewInventoryImage.type,
        debounceInMilliseconds: 250
    });
};