import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { firstOrNull } from "../../../../../utils/arrayHelpers";
import { isValue } from "../../../../../utils/valueHelper";
import { QualityGradeValue } from "../../../models/gradeValue";
import { expandedTreeNodeAdapter, gradeValueAdapter, metricKindAdapter, templateConstraintAdapter, templateConstraintNodeAdapter, templateMetricAdapter } from "../state";

export const getAssessmentTemplate = (state: RootState) => state.qualityTemplateEditor.template;

export const getQualityGradeKinds = (state: RootState) => state.qualityTemplateEditor.gradeKinds;

export const getActiveQualityGradeKinds = (state: RootState) => state.qualityTemplateEditor.gradeKinds.filter(kind => !kind.inactive);

const qualityGradeValueSelector = gradeValueAdapter.getSelectors((state: RootState) => state.qualityTemplateEditor.gradeValues);
export const getGradeValues = qualityGradeValueSelector.selectAll;
export const getGradeValueById = qualityGradeValueSelector.selectById;

export const getGradeValuesForTemplate = createSelector(
    [
        getAssessmentTemplate,
        getGradeValues
    ],
    (template, allGradeValues) => {
        const emptySet: QualityGradeValue[] = [];
        if (!isValue(template)) return emptySet;
        return allGradeValues
            .filter(gradeValue => gradeValue.gradeKindId === template.gradeKindId)
            .sort((left, right) => left.sequence - right.sequence);
    }
);

const metricKindSelectors = metricKindAdapter.getSelectors((state: RootState) => state.qualityTemplateEditor.metricKinds);
export const getQualityMetricKindMap = metricKindSelectors.selectEntities;
export const getQualityMetricKinds = metricKindSelectors.selectAll;
export const getQualityMetricKindById = metricKindSelectors.selectById;

export const getQualityMetricValues = (state: RootState) => state.qualityTemplateEditor.metricValues;

export const getValuesForQualityMetricKind = createSelector(
    [
        getQualityMetricValues
    ],
    (allMetricValues) => {
        return (metricKindId: number) => allMetricValues
            .filter(metricValue => metricValue.metricKindId === metricKindId)
            .sort((left, right) => left.sequence - right.sequence);
    }
);

const templateMetricSelectors = templateMetricAdapter.getSelectors((state: RootState) => state.qualityTemplateEditor.templateMetrics);
export const getTemplateMetrics = createSelector(
    [
        templateMetricSelectors.selectAll
    ],
    (allTemplateMetrics) => {
        return allTemplateMetrics.slice().sort((left, right) => left.sequence - right.sequence);
    }
);

const templateConstraintSelectors = templateConstraintAdapter.getSelectors((state: RootState) => state.qualityTemplateEditor.templateConstraints);
export const getAllTemplateConstraints = templateConstraintSelectors.selectAll;
export const getTemplateConstraintById = templateConstraintSelectors.selectById;

export const getTemplateConstraintsForGradeValue = createSelector(
    [
        getAllTemplateConstraints
    ],
    (allTemplateConstraints) => {
        return (gradeValueId: number) => firstOrNull(allTemplateConstraints, constraint => constraint.gradeValueId === gradeValueId);
    }
);


const constraintNodeSelectors = templateConstraintNodeAdapter.getSelectors((state: RootState) => state.qualityTemplateEditor.constraintNodes);
export const getConstraintNodeMap = constraintNodeSelectors.selectEntities;
export const getConstraintNodes = constraintNodeSelectors.selectAll;
export const getConstraintNodeById = constraintNodeSelectors.selectById;

export const getRootConstraintNodeForGradeValue = createSelector(
    [
        getConstraintNodeMap,
        getTemplateConstraintsForGradeValue
    ],
    (nodeMap, getConstraint) => {        
        return (gradeValueId: number) => {
            const constraint = getConstraint(gradeValueId);
            const rootNodeId = constraint?.rootConstraintNodeId;
            if (!isValue(rootNodeId)) return null;
            return nodeMap[rootNodeId] ?? null;            
        }
    }
);

export const getIsLowestRankGrade = createSelector(
    [
        getGradeValuesForTemplate
    ],
    (values) => {
        return (gradeValueId: number) => {
            if (values.length === 0) return false;
            const lowestRank = values[values.length - 1];
            return lowestRank.id === gradeValueId;
        }
    }
);

export const getConstraintChildCount = createSelector(
    [
        getConstraintNodes
    ],
    (allNodes) => {        
        return (constraintNodeId: number) => {            
            return allNodes.filter(node => node.parentNodeId === constraintNodeId).length;      
        }
    }
);


const expandedTreeNodeSelectors = expandedTreeNodeAdapter.getSelectors((state: RootState) => state.qualityTemplateEditor.constraintTreeExpandedKeys);
export const getAllExpandedTreeNodes = expandedTreeNodeSelectors.selectEntities;
export const getExpandedTreeNodesForGradeValue = expandedTreeNodeSelectors.selectById;