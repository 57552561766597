import { Contact } from "../../../common/types";
import { contactAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    upsertCustomerContact,
    upsertCustomerContactReducer,
    upsertCustomerContactSagaFactory

} = actionFactory
    .withMappedName("upsertCustomerContact")
    .createAsyncAction<Contact, Contact>({    
    actionStatusSelector: state => state.loadingState,
    asyncCall: (arg, context) => {
       return context.marketplaceApiClient.customer.upsertCustomerContact(arg);
    },
    onFulfilled: (state, action) => {
       contactAdapter.upsertOne(state.customerContacts, action.payload.result);
    }
});