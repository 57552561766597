import React, { useState } from 'react';
import buyerlogo from '../../assets/order.svg';
import { openContactNodePage } from '../../utils/externalPageHelper';
import { MarketingCard } from './marketingCard';

export interface BuyerSignUpCardProps {    
}

export const BuyerSignUpCard: React.FC<BuyerSignUpCardProps> = () => {
    
    const [hoveredCard, setHoveredCard] = useState<string | null>(null);

    const onMouseOver = (e: React.MouseEvent<HTMLDivElement>) => {
        setHoveredCard(e.currentTarget.id);
    }

    const onMouseLeave = (_: React.MouseEvent<HTMLDivElement>) => {
        setHoveredCard(null);
    }

    return <div onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} id="BuyerCard" >
        <MarketingCard
            image={buyerlogo}
            title="Operator Signup"
            actionTitle="Sign up"
            isSelected={hoveredCard !== null && hoveredCard === "BuyerCard"}
            onActionClick={openContactNodePage}
        />
    </div>
}