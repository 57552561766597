import { CSSProperties } from "react";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FormikEmbeddedEditor } from "../../../../components/formik/embeddedEditor";
import { StringField } from "../../../../components/formik/stringField";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { Customer } from "../../common/types";
import { saveCustomer } from "../store/featureActions/saveCustomer";
import { getCustomer } from "../store/selectors/customer";

export const CustomerDetails: React.FC = () => {

    const dispatch = useDispatch();
    const customer = useAppSelector(getCustomer);

    const onSaveCustomer = (customer: Customer) => {
        dispatch(saveCustomer(customer));
    }
 
    const labelStyle: CSSProperties = { display: "inline-block", width: "100px" };
    const inputStyle: CSSProperties = { width: "400px", maxWidth: "80vw" };

    return isValue(customer)
        ? <FormikEmbeddedEditor
            initialValues={customer}
            onSave={onSaveCustomer}
        >
            <FlexColumnContainer
                gap="20px"
            >
                <StringField<Customer>
                    fieldName="name"
                    defaultLabel="Name"
                    submitOnBlur
                    labelStyle={labelStyle}
                    inputStyle={inputStyle}
                />

                <StringField<Customer>
                    fieldName="accountNumber"
                    defaultLabel="Account #"
                    submitOnBlur
                    labelStyle={labelStyle}
                    inputStyle={inputStyle}
                />
            </FlexColumnContainer>
        </FormikEmbeddedEditor>
        : null
}   