import { MenuItem } from "primereact/menuitem";
import { SplitButton } from "primereact/splitbutton";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { BulkAssignmentKind } from "../../../commonModels/bulkAssignmentState";
import { beginBulkCategoryAssignment } from "../store/featureActions/bulkCategoryAssignment/beginBulkCategoryAssignment";
import { beginBulkClearCategoryAssignment } from "../store/featureActions/bulkClearCategoryAssignment/beginBulkClearCategoryAssignment";
import { beginBulkExclude } from "../store/featureActions/bulkExcludeFromMarketplace/beginBulkExclude";
import { bulkSetReadyDateBegin } from "../store/featureActions/bulkSetReadyDate";
import { beginBulkQualityGradeAssignment } from "../store/featureActions/qualityGrades/beginBulkQualityGradeAssignment";
import { createQualityAssessmentBegin } from "../store/featureActions/qualityGrades/createQualityAssessment";
import { removeQualityAssessmentBegin } from "../store/featureActions/qualityGrades/removeQualityAssessment";
import { getSelectedInventory } from "../store/selectors/bulkEdit";
import { BulkExcludeAction } from "../store/types";
import { MetrcPackageType } from "../../../commonModels/metrcPackageType";
import './bulkActionToolbar.css';

export const BulkActionToolbar: React.FC = () => {

    const dispatch = useDispatch();
    const selectedInventory = useAppSelector(getSelectedInventory);

    const onBulkEditCategoryAssignment = () => {
        dispatch(beginBulkCategoryAssignment());
    }

    const onBulkExclude = () => {
        dispatch(beginBulkExclude(BulkExcludeAction.Exclude));
    }

    const onBulkRemoveExclude = () => {
        dispatch(beginBulkExclude(BulkExcludeAction.RemoveExclusion));
    }

    const onClearCategoryAssignment = () => {
        dispatch(beginBulkClearCategoryAssignment());
    }

    const onBulkAssignQualityGrade = () => {
        dispatch(beginBulkQualityGradeAssignment(BulkAssignmentKind.Assign));
    }

    const onBulkClearQualityGrade = () => {
        dispatch(beginBulkQualityGradeAssignment(BulkAssignmentKind.Clear));
    }

    const onLogQualityAssessment = () => {
        dispatch(createQualityAssessmentBegin());
    }

    const onRemoveQualityAssessment = () => {
        dispatch(removeQualityAssessmentBegin());
    }

    const onAssignReadyDate = () => {
        dispatch(bulkSetReadyDateBegin());
    }

    const bulkActionMenuItems: MenuItem[] = [
        {
            label: "Assign Categories",
            command: onBulkEditCategoryAssignment
        },
        {
            label: "Clear Categories",
            command: onClearCategoryAssignment
        },
        {
            label: "Exclude From Menu",
            command: onBulkExclude
        },
        {
            label: "Remove Menu Exclusion",
            command: onBulkRemoveExclude
        },
        {
            label: "Assign Quality Grade",
            command: onBulkAssignQualityGrade
        },
        {
            label: "Clear Quality Grade",
            command: onBulkClearQualityGrade
        },
        {
            label: "Log Quality Assessment",
            command: onLogQualityAssessment,
            disabled: selectedInventory.some(inv => isValue(inv.qualityAssessmentId))
        },
        {
            label: "Remove Quality Assessment",
            command: onRemoveQualityAssessment,
            disabled: selectedInventory.filter(inv => isValue(inv.qualityAssessmentId)).length === 0
        },
        {
            label: "Assign / Clear Ready Date",
            command: onAssignReadyDate,
            disabled: selectedInventory.some(inv => inv.metrcPackageType !== MetrcPackageType.ImmaturePlant)
        }
    ];


    return <div>
        <SplitButton menuClassName="inventory-bulk-actions-menu-item" label="Bulk Actions" model={bulkActionMenuItems} disabled={selectedInventory.length === 0} />
    </div>
}