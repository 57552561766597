import { Dropdown, DropdownChangeParams, DropdownProps } from 'primereact/dropdown';
import { CSSProperties } from 'react';
import { isValue } from '../../utils/valueHelper';

export interface FontPickerProps {
    value?: string | null;
    style?: CSSProperties;
    className?: string;
    onChange?: (value: string | null) => void;
    availableFontFamilies: string[];
    disabled?: boolean;
}

export const FontPicker: React.FC<FontPickerProps> = ({ value, style, className, onChange, availableFontFamilies, disabled }) => {

    const options: { label: string, value: string | null }[] = [
        {
            label: "Default",
            value: null
        },
        ...availableFontFamilies
            .map(fontFamily => ({
                label: fontFamily.replace("-Regular", ""),
                value: fontFamily
            }))
    ]

    const itemTemplate = (option: { label: string, value: string }): React.ReactNode => {
        return <div style={{ fontFamily: option.value ?? undefined }}>{option.label}</div>
    }
    const valueTemplate = (option: { label: string, value: string }, _props: DropdownProps): React.ReactNode => {
        if (!isValue(option)) return "Default";
        return <div style={{ fontFamily: option.value ?? undefined }}>{option.label}</div>
    }

    const onSelectedFontChanged = (e: DropdownChangeParams) => {
        if (!isValue(onChange)) return;
        onChange(e.value as string | null);
    }

    return <Dropdown
        className={className}
        disabled={disabled}
        options={options}
        value={value ?? undefined}
        itemTemplate={itemTemplate}
        valueTemplate={valueTemplate}
        onChange={onSelectedFontChanged}
        placeholder="Default"
        style={style}
    />

}

