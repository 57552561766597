import { PrimeIcons } from "primereact/api";
import { Column, ColumnProps } from "primereact/column";
import { DataTable, DataTableRowReorderParams } from "primereact/datatable";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { createActionColumnProps } from "../../../../utils/table/actionColumn";
import { createEditableTextColumnProps } from "../../../../utils/table/editableTextColumn";
import { createNumberColumnProps } from "../../../../utils/table/numberColumn";
import { createRowReorderColumnProps } from "../../../../utils/table/rowReorderColumn";
import { QualityGradeValue } from "../../models/gradeValue";
import { deleteQualityGradeValue } from "../store/featureActions/deleteQualityGradeValue";
import { resequenceGradeValue } from "../store/featureActions/resequenceGradeValue";
import { upsertQualityGradeValue } from "../store/featureActions/upsertQualityGradeValue";

import { getGradeValueSaveState, getIsSelectedGradeKindEditable, getValuesForSelectedGrade } from "../store/selectors/editing";

export const QualityGradeValueTable: React.FC = () => {

    const dispatch = useDispatch();
    const gradeValues = useAppSelector(getValuesForSelectedGrade);
    const saveState = useAppSelector(getGradeValueSaveState);
    const isEditable = useAppSelector(getIsSelectedGradeKindEditable);

    const onUpdateGradeValue = (model: QualityGradeValue) => {
        dispatch(upsertQualityGradeValue(model));
    }

    const columns: ColumnProps[] = [
        createRowReorderColumnProps({ header: "", style: { width: 30, maxWidth: 30 }, rowReorderIcon: PrimeIcons.ELLIPSIS_V }),        
        createEditableTextColumnProps<QualityGradeValue>({ header: "Name", field: "name", style: { width: 180 }, sortable: false, disabled: !isEditable, onSubmit: onUpdateGradeValue }),
        createNumberColumnProps<QualityGradeValue>({ header: "Sequence", field: "sequence", style: { width: 100, maxWidth: 100 }, sortable: false }),
        createActionColumnProps<QualityGradeValue>({
            columnKey: "delete-action",
            header: "",
            style: { width: 50, maxWidth: 50},
            actionHandler: (value) => {
                dispatch(deleteQualityGradeValue(value));
            },
            actionName: "",
            buttonIcon: PrimeIcons.TIMES,
            buttonClassName: "quality-grade-value-table-button",
            disabled: !isEditable
        }),
    ];

    const onRowReorder = (e: DataTableRowReorderParams) => {

        // Odd behavior from prime react DataTable. When dragging down, the index appears to be one based.
        // but when dragging up, the index appears to be zero based. -\o/-
        const dropIndex: number = e.dropIndex > e.dragIndex ? e.dropIndex - 1 : e.dropIndex;

        const dragTarget = gradeValues[e.dragIndex];
        const dropTarget = gradeValues[dropIndex];

        dispatch(resequenceGradeValue({
            newSequenceValue: dropTarget.sequence,
            entityId: dragTarget.id
        }));
    }

    return <DataTable
        size="small"
        disabled={!isEditable || saveState.isExecuting}
        dataKey="id"
        value={gradeValues}
        scrollable        
        scrollHeight='flex'
        paginator
        alwaysShowPaginator={false}
        rows={20}
        rowsPerPageOptions={[10, 20, 50]}
        onRowReorder={onRowReorder}
    >
        {columns.map(c => <Column key={c.columnKey ?? c.field} {...c} />)}
    </DataTable>
}