import { QualityGradeKind } from "../../../models/gradeKind";
import { actionFactory } from "../types";

export const {

    selectQualityGradeKind,
    selectQualityGradeKindReducer

} = actionFactory
    .withMappedName("selectQualityGradeKind")
    .createAction<QualityGradeKind | null>((state, action) => {
        state.selectedGradeKindId = action.payload?.id ?? null;
    })