import { actionFactory } from "../types";
import { loadFavoriteMenus } from "./loadFavoriteMenus";
import { loadMenuInvites } from "./loadMenuInvites";

export const {

    removeFromFavorites,
    removeFromFavoritesReducer,
    removeFromFavoritesSagaFactory

} = actionFactory
    .withMappedName("removeFromFavorites")
    .createAsyncAction<{ menuId: number }, Response>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.userProfile.removeMenuFromFavorites(arg.menuId);
        },
        onFulfilled: (_state, _action) => {
        },
        successCallback: (_arg, _result, context) => {
            context.dispatch(loadMenuInvites());
            context.dispatch(loadFavoriteMenus())
        },
        debounceInMilliseconds: 50
    });