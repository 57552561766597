import clsx from "clsx";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { getSpinnerMargin, getSpinnerSize, smallDeviceQuery } from "../../features/responsiveconfiguration";
import { isValue } from "../../utils/valueHelper";
import './index.css';

export interface WaitDisplayProps {
    height?: string | number
};

export const WaitDisplay: React.FC<WaitDisplayProps> = (props) => {

    return <div className="p-grid p-align-center p-justify-center" style={{ height: props.height ?? "75vh" }}>
        <i className="pi pi-spin pi-spinner" style={{ 'fontSize': '3em', color: "#009b99" }}></i>
    </div>
}

export const WaitDisplaySmall: React.FC<WaitDisplayProps> = (props) => {

    return <div className="p-grid p-align-center p-justify-center" style={{ width: "100%", height: props.height ?? "40px" }}>
        <span className="pi pi-spin pi-spinner" style={{ 'fontSize': '2em', color: "#009b99" }}></span>
    </div>
}

export interface StyledWaitDisplayProps {
    containerClassName?: string;
    spinnerClassName?: string;
};

export const StyledWaitDisplay: React.FC<StyledWaitDisplayProps> = (props) => {

    return <div className={clsx("p-grid", "p-align-center", "p-justify-center", props.containerClassName)}>
        <span className={clsx("pi", "pi-spin", "pi-spinner", props.spinnerClassName ?? "default-wait-display-spinner")} ></span>
    </div>
}

export interface OverlayWaitDisplayProps {
    isWaiting: boolean;
    overlayOpacity?: number;
    fullScreen?: boolean;
    spinnerStyle?: CSSProperties;
};

export const OverlayWaitDisplay: React.FC<OverlayWaitDisplayProps> = ({ isWaiting, children, overlayOpacity = .75, fullScreen, spinnerStyle }) => {

    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    const spinnerSize = getSpinnerSize(isSmallDevice);
    const spinnerMargin = getSpinnerMargin(isSmallDevice);
    const [internalIsWaiting, setInternalIsWaiting] = useState<boolean>(false) ;

    const defaultStyle = { width: spinnerSize, height: spinnerSize, marginTop: spinnerMargin, marginLeft: spinnerMargin };

    const spinnerStyleToUse: CSSProperties = isValue(spinnerStyle)
        ? { ...defaultStyle, ...spinnerStyle }
        : defaultStyle;

    useEffect(() => {
        if (!!isWaiting) {
            setInternalIsWaiting(isWaiting);
        }
        else {
            const timeoutId = setTimeout(() => {
                setInternalIsWaiting(isWaiting);
            }, 500);

            return () => clearTimeout(timeoutId);
        }        
    }, [isWaiting, setInternalIsWaiting]);

    return <BlockUI
        blocked={internalIsWaiting}
        fullScreen={fullScreen}
        style={internalIsWaiting ? { backgroundColor: "white", opacity: overlayOpacity } : undefined}        
        template={
            <div
                className='p-d-flex p-jc-center p-ai-center'
                style={internalIsWaiting ? { opacity: 1 } : undefined}
            >
                <ProgressSpinner 
                    className="custom-progress-spinner" 
                    style={spinnerStyleToUse} 
                    strokeWidth="5"
                />
            </div>
        }
    >
        {children}
    </BlockUI>
}