import { createAction } from "@reduxjs/toolkit";
import { IReducerFactory } from "../../../../store/factory/interfaces";
import { actionFactory, UserAuthState } from "../types";

export const acknowledgeLoginError = createAction(actionFactory.createActionName("acknowledgeLoginError"));

export function acknowledgeLoginErrorReducer(reducerFactory: IReducerFactory<UserAuthState>): IReducerFactory<UserAuthState> {
    return reducerFactory
    .forAction(acknowledgeLoginError)
    .addHandler((state, _) => {
        state.ErrorMessage = null;
    })
    .build();
}