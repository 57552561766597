import { isValue } from "../../../../../utils/valueHelper";
import { actionFactory, CategorySectionCommand } from "../types";

export const {

    addCategoryToSection,
    addCategoryToSectionReducer,
    addCategoryToSectionSagaFactory

} = actionFactory
    .withMappedName("addCategoryToSection")
    .createAsyncAction<CategorySectionCommand, Response>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {

            const state = context.getState();
            const menuSection = state.menuAdmin.menuSections.entities[arg.menuSectionId];
            if (!isValue(menuSection)) throw new Error("Attempted to save category to menu section without a valid menu section reference");

            return context.marketplaceApiClient.menuAdmin.setCategoryIdsForMenuSection(menuSection.id, [...menuSection.categoryIds, arg.categoryId]);
        },
        onFulfilled: (state, action) => {
            const menuSection = state.menuSections.entities[action.payload.arg.menuSectionId];
            if (!isValue(menuSection)) throw new Error("Attempted to save category to menu section without a valid menu section reference");
            menuSection.categoryIds.push(action.payload.arg.categoryId);
        },
        debounceInMilliseconds: 250
    });