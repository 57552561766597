import { ConfirmPopup } from "primereact/confirmpopup"
import { ConfirmAgeDialog } from "../ageConfirmation/dialog/ui"
import { QualityAssessmentDialog } from "../qualityAssessments/assessmentEditor/ui/qualityAssessmentDialog"
import { GlobalErrorQueue } from "./error/ui"
import { MessageDialog } from "./message/ui"
import { ContactSellerDetails } from "./vendor/ui"

export const AppDialogs: React.FC = () => {

    return <div>
        <ConfirmAgeDialog />
        <MessageDialog />
        <GlobalErrorQueue />
        <ContactSellerDetails />
        <ConfirmPopup />
        <QualityAssessmentDialog />
    </div>

}