import partialHasChanges from "../../../../../utils/changeDetection";
import { isValue } from "../../../../../utils/valueHelper";
import { QualityGradeValue } from "../../../models/gradeValue";
import { gradeValueAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    upsertQualityGradeValue,
    upsertQualityGradeValueReducer,
    upsertQualityGradeValueSagaFactory

} = actionFactory
    .withMappedName("upsertQualityGradeValue")
    .createAsyncAction<QualityGradeValue, QualityGradeValue>({
        actionStatusSelector: state => state.gradeValueSaveState,
        asyncCall: (arg, context) => {            
            return context.marketplaceApiClient.quality.upsertQualityGradeValue(arg);
        },
        onFulfilled: (state, action) => {
            gradeValueAdapter.upsertOne(state.gradeValues, action.payload.result);
        },
        predicate: (arg, context) => {
            if (arg.id > 0) {
                const sliceState = context.getState().qualityGradeKinds;
                const target = sliceState.gradeValues.entities[arg.id];
                if (isValue(target)) {
                    return partialHasChanges(target, arg);
                }
            }
            return true;
        }
    });