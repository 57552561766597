import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory, ConfirmAgeState } from "../types";

const localStorageKey = "age-confirmation";
export const saveAgeConfirmation = actionFactory.createAppAction("saveAgeConfirmation");

const sagaActions = actionFactory.createSagaWithProgressActions<void, void>(saveAgeConfirmation.type);

export function saveAgeConfirmationReducers(reducerFactory: IReducerFactory<ConfirmAgeState>): IReducerFactory<ConfirmAgeState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .onFulfilled((state, _) => {
            state.hasConfirmedAge = true;
        })
        .build();
}

async function performAction(_arg: void, _context: SagaAppContext): Promise<void> {
    localStorage.setItem(localStorageKey, "true");
}

export function createSaveAgeConfirmationSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: saveAgeConfirmation.type,
        debounceInMilliseconds: 250
    });
};