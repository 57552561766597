import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { createLoadSliceDataSaga } from "../featureActions/loadSliceData";
import { createSaveTermsOfServiceAcceptanceSaga } from "../featureActions/saveTermsOfServiceAcceptance";

// Register this method in the root saga
export function createAcceptTermsOfServiceSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        createLoadSliceDataSaga(context),
        createSaveTermsOfServiceAcceptanceSaga(context)
    ]
}