import { confirmPopup } from 'primereact/confirmpopup';
import { MenuItem, MenuItemCommandParams } from "primereact/menuitem";
import { isValue } from "../valueHelper";

export interface TypedParentMenuItem<T> extends Pick<MenuItem, "label" | "icon" | "separator" | "style" | "className"> {
    disabled?: (value: T) => boolean;
    visible?: (value: T) => boolean;
    items: (TypedMenuItem<T> | TypedParentMenuItem<T>)[];
}

export interface TypedCommandMenuItem<T> extends Pick<MenuItem, "label" | "icon" | "separator" | "style" | "className"> {
    command: (value: T, originalEvent: React.SyntheticEvent) => void;
    disabled?: (value: T) => boolean;
    visible?: (value: T) => boolean;
    promptBeforeInvoke?: string;
    promptAcceptLabel?: string;
    promptRejectLabel?: string;
}

export type TypedMenuItem<T> = TypedParentMenuItem<T> | TypedCommandMenuItem<T>;


export function isParentMenuItem<T>(value: TypedMenuItem<T>): value is TypedParentMenuItem<T> {
    return (value as TypedParentMenuItem<T>).items !== undefined;
}

export function isCommandMenuItem<T>(value: TypedMenuItem<T>): value is TypedCommandMenuItem<T> {
    return (value as TypedCommandMenuItem<T>).command !== undefined;
}
export const mapToMenuItem = <T,>(menuItem: TypedMenuItem<T>, value: T, elementId: string): MenuItem => {
    const disabled = menuItem.disabled !== undefined ? menuItem.disabled(value) : false;

    return {
        ...menuItem,
        disabled: disabled,        
        items: isParentMenuItem(menuItem) ? menuItem.items.map(item => mapToMenuItem(item, value, elementId)) : undefined,
        command: isCommandMenuItem(menuItem)
            ? (event: MenuItemCommandParams) => {

                if (isValue(menuItem.promptBeforeInvoke)) {

                    confirmPopup({
                        target: isValue(elementId) ? document.getElementById(elementId) ?? undefined : undefined,
                        message: menuItem.promptBeforeInvoke,
                        acceptLabel: menuItem.promptAcceptLabel,
                        rejectLabel: menuItem.promptRejectLabel,
                        icon: 'pi pi-exclamation-triangle',
                        accept: () => {
                            menuItem.command(value, event.originalEvent);
                        },
                        reject: () => { }
                    });                     
                    
                }
                else {
                    menuItem.command(value, event.originalEvent);
                }
            }
            : undefined
    };
};
