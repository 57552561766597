import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, LiveMenuState, SortSetting } from "../types";


export const setSort = actionFactory.createAppAction<SortSetting>("setSort");

export function setSortReducers(reducerFactory: IReducerFactory<LiveMenuState>): IReducerFactory<LiveMenuState> {
    return reducerFactory
        .forAction(setSort)
        .addHandler((state, action) => {
            state.sortSetting = action.payload;
        })
        .build();
}