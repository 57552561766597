import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { confirmDialog } from 'primereact/confirmdialog';
import { Message } from 'primereact/message';
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { FormikEditorClassStyles } from "../../../../components/formik/classNameAttributes";
import { DropDownField } from '../../../../components/formik/dropDownField';
import { FormikEmbeddedEditor } from "../../../../components/formik/embeddedEditor";
import { StringField } from "../../../../components/formik/stringField";
import { SwitchField } from "../../../../components/formik/switchField";
import { OverlayWaitDisplay } from '../../../../components/waitDisplay';
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { AssessmentTemplate } from "../../models/assessmentTemplate";
import { changeGradeKindBegin } from "../store/featureActions/changeGradeKind";
import { publishAssessmentTemplate } from "../store/featureActions/publishAssessmentTemplate";
import { saveAssessmentTemplate } from "../store/featureActions/saveAssessmentTemplate";
import { getAssessmentTemplate, getQualityGradeKinds } from "../store/selectors/data";
import { getIsEditable } from "../store/selectors/editing";
import { getTemplateSaveState } from "../store/selectors/loadingState";

export const GeneralInfoEditor: React.FC = () => {

    const dispatch = useDispatch();
    const template = useAppSelector(getAssessmentTemplate);
    const templateSaveState = useAppSelector(getTemplateSaveState);

    const gradeKinds = useAppSelector(getQualityGradeKinds);
    const isEditable = useAppSelector(getIsEditable);

    const onSaveTemplate = (value: AssessmentTemplate) => {
        dispatch(saveAssessmentTemplate(value));
    }

    const fieldClassNames: FormikEditorClassStyles = {
        containerClassName: "edit-template-field-container",
        inputClassName: "edit-template-field-input",
        labelClassName: "edit-template-field-label"
    }

    const onChangeGradeKind = () => {
        dispatch(changeGradeKindBegin());
    }

    const onPublish = () => {
        confirmDialog({
            className: "publish-template-confirm-dialog",
            rejectLabel: "Do not publish",
            acceptLabel: "Publish this template",
            header: "Publish Quality Template",            
            message: <FlexColumnContainer gap="20px" padding="10px">
                <Message severity="warn" text="Once a template is published, it can no longer be modified."></Message>
                <div>Are you sure you want to publish this template?</div>
            </FlexColumnContainer>,
            accept: () => {
                dispatch(publishAssessmentTemplate());
            }
        });
    }

    return !isValue(template)
        ? <div></div>
        : <FormikEmbeddedEditor<AssessmentTemplate>
            initialValues={template}
            onSave={onSaveTemplate}
        >
            <OverlayWaitDisplay
                isWaiting={templateSaveState.isExecuting}
            >

                <FlexColumnContainer className="general-info-editor">

                    <StringField<AssessmentTemplate>
                        fieldName="name"
                        defaultLabel="Name"
                        disabled={!isEditable}
                        submitOnBlur
                        {...fieldClassNames}
                    />

                    <FlexRowContainer centerContent gap="20px">
                        <DropDownField<AssessmentTemplate, number>
                            availableValues={gradeKinds.map(gradeKind => ({ label: gradeKind.name, value: gradeKind.id }))}
                            fieldName="gradeKindId"
                            defaultLabel="Grade Kind"
                            allowClear={false}
                            disabled={true}
                            {...fieldClassNames}
                        />
                        {
                            !isValue(template.publishedDate) &&
                            <Button                                
                                onClick={onChangeGradeKind}
                                disabled={!isEditable}
                            >Change Grade Kind</Button>
                        }
                    </FlexRowContainer>

                    <SwitchField<AssessmentTemplate>
                        defaultLabel="Active"
                        fieldName="inactive"
                        invertLogic={true}                        
                        submitOnChange={true}
                        labelClassName={fieldClassNames.labelClassName}
                        containerClassName={fieldClassNames.containerClassName}
                    />

                    <FlexRowContainer centerContent>
                        <div className={fieldClassNames.labelClassName}>Published</div>
                        <Calendar
                            value={template.publishedDate?.toJSDate()}                            
                            readOnlyInput={true}
                            disabled={true}
                            placeholder=""

                        />
                        {
                            isEditable &&
                            <Button
                                onClick={onPublish}                                
                                style={{ marginLeft: "10px" }}
                                type='button'
                            >Publish Now</Button>
                        }
                    </FlexRowContainer>

                </FlexColumnContainer>

            </OverlayWaitDisplay>

        </FormikEmbeddedEditor>
} 