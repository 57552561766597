import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { createCompleteBulkCategoryAssignmentSaga } from "../featureActions/bulkCategoryAssignment/completeBulkCategoryAssignment";
import { createCompleteCategoryAssignmentSaga } from "../featureActions/categoryAssignment/completeCategoryAssignment";
import { createDeleteProductImageSaga } from "../featureActions/productImages/deleteProductImage";
import { createLoadLatestSyncRequestSaga } from "../featureActions/productSync/loadLatestSyncRequest";
import { loadProductMediaSagaFactory } from "../featureActions/productImages/loadProductMedia";
import { loadProductsSagaFactory } from "../featureActions/loadProducts";
import { createRequestProductSyncSaga } from "../featureActions/productSync/requestProductSync";
import { createUpdateImageDetailsSaga } from "../featureActions/productImages/updateImageDetails";
import { createUploadNewProductImageSaga } from "../featureActions/productImages/uploadNewProductImage";
import { createBeginCategoryAssignmentSaga } from "../featureActions/categoryAssignment/beginCategoryAssignment";
import { loadMenuAssignmentsSagaFactory } from "../featureActions/loadMenuAssignments";
import { createCompleteBulkClearCategoryAssignmentSaga } from "../featureActions/bulkClearCategoryAssignment/completeBulkClearCategoryAssignment";
import { loadPriceModelsSagaFactory } from "../featureActions/priceModels/loadPriceModels";
import { completeBulkPricingModelAssignmentSagaFactory } from "../featureActions/priceModels/completeBulkPricingModelAssignment";
import { addVideoSagaFactory } from "../featureActions/productImages/addVideo";
import { upsertProductVideoSagaFactory } from "../featureActions/productImages/upsertProductVideo";
import { deleteProductVideoSagaFactory } from "../featureActions/productImages/deleteProductVideo";
import { editProductSagaFactory } from "../featureActions/productDetails/editProduct";
import { loadProductNotesSagaFactory } from "../featureActions/notes/loadProductNotes";
import { upsertProductNoteSagaFactory } from "../featureActions/notes/upsertProductNote";
import { deleteProductNoteSagaFactory } from "../featureActions/notes/deleteProductNote";

// Register this method in the root saga
export function createProductManagementSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        loadProductsSagaFactory(context),
        createRequestProductSyncSaga(context),        
        editProductSagaFactory(context),
        createUploadNewProductImageSaga(context),
        loadProductMediaSagaFactory(context),
        createUpdateImageDetailsSaga(context),
        createDeleteProductImageSaga(context),        
        createLoadLatestSyncRequestSaga(context),
        createCompleteBulkCategoryAssignmentSaga(context),        
        createCompleteCategoryAssignmentSaga(context),
        createBeginCategoryAssignmentSaga(context),
        loadMenuAssignmentsSagaFactory(context),
        createCompleteBulkClearCategoryAssignmentSaga(context),
        loadPriceModelsSagaFactory(context),        
        completeBulkPricingModelAssignmentSagaFactory(context),
        addVideoSagaFactory(context),
        upsertProductVideoSagaFactory(context),
        deleteProductVideoSagaFactory(context),
        loadProductNotesSagaFactory(context),
        upsertProductNoteSagaFactory(context),
        deleteProductNoteSagaFactory(context)
    ]
}