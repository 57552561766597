import { QualityGradeKind } from "../../../models/gradeKind";
import { gradeKindAdapter } from "../state";
import { actionFactory } from "../types";

export const {

   addQualityGradeKindBegin,
   addQualityGradeKindCancel,
   addQualityGradeKindComplete,
   addQualityGradeKindReducers,
   addQualityGradeKindSagaFactory

} = actionFactory
    .withMappedName("addQualityGradeKind")
    .createModal<QualityGradeKind, void, QualityGradeKind>({        
        selector: state => state.addGradeKindState,
        modelFactory: (_arg, _state) => {
            return { id: 0, inactive: false, name: "", publishedDate: null };
        },
        onCompleteEdit: (value, context) => {
            return context.marketplaceApiClient.quality.upsertQualityGradeKind(value);
        },
        onFulfilled: (state, action) => {
            gradeKindAdapter.upsertOne(state.gradeKinds, action.result);
            state.selectedGradeKindId = action.result.id;            
        }
    });