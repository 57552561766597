import { push } from "connected-react-router";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { TabPanel, TabView } from "primereact/tabview";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { NotFound } from "../../../../components/notFound";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { isValue } from "../../../../utils/valueHelper";
import { QualityGradeKindsRoute } from "../../../navigation/routes";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { getLoadingState } from "../store/selectors/pricingModel";
import { getQualityGradeKinds } from "../store/selectors/pricingModelGrade";
import { resetState } from "../store/slice";
import { PricingModelForm } from "./pricingModelForm";
import { PricingModelGradeTable } from "./pricingModelGradeTable";
import './index.css';

export interface PricingModelEditorParams {
    id: string | undefined
};

export const PricingModelEditor: React.FC = () => {

    const dispatch = useDispatch();
    const params = useParams<PricingModelEditorParams>();
    const pricingModelId: number | undefined = isValue(params.id) ? parseInt(params.id) : undefined;
    const loadingState = useAppSelector(getLoadingState);
    const qualityGradeKinds = useAppSelector(getQualityGradeKinds);

    useMountEffect(() => {
        if (isValue(pricingModelId)) {
            dispatch(loadSliceData({ id: pricingModelId }));
        }

        return () => {
            dispatch(resetState());
        }
    });

    const onConfigureQualityGrades = () => {
        dispatch(push(QualityGradeKindsRoute.path));
    }

    return <div>
        {
            !isValue(pricingModelId)
                ? <NotFound />
                : <OverlayWaitDisplay
                    isWaiting={loadingState.isExecuting}
                >
                    <Card className="full-height-content-panel" title="Edit Pricing Model">

                        <FlexColumnContainer gap="15px">

                            <PricingModelForm />

                            {
                                qualityGradeKinds.length === 0 &&
                                <FlexColumnContainer className="quality-grade-required-warning-container">
                                    <div className="quality-grade-required-warning-title">Quality grades must be configured before pricing can be entered.</div>
                                    <Button label="Configure Quality Grades" onClick={onConfigureQualityGrades} />
                                </FlexColumnContainer>
                            }
                            {
                                qualityGradeKinds.length > 0 &&
                                <TabView>
                                    {
                                        qualityGradeKinds.map(gradeKind => <TabPanel
                                            key={gradeKind.id}
                                            header={gradeKind.name}
                                        >
                                            <PricingModelGradeTable gradeKindId={gradeKind.id} />
                                        </TabPanel>)
                                    }
                                </TabView>
                            }

                        </FlexColumnContainer>

                    </Card>
                </OverlayWaitDisplay>
        }
    </div>
} 