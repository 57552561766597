import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { isValue } from "../../../../../utils/valueHelper";
import { QualityMetricValue } from "../../../models/metricValue";
import { getMetricKindEntities, getMetricValues } from "./data";

export const getAddQualityMetricKindState = (state: RootState) => state.qualityMetricKinds.addMetricKindState;
export const getAddQualityMetricKindValue = (state: RootState) => state.qualityMetricKinds.addMetricKindState.editedValue;
export const getAddQualityMetricKindVisible = (state: RootState) => state.qualityMetricKinds.addMetricKindState.isVisible;


export const getSelectedMetricKindId = (state: RootState) => state.qualityMetricKinds.selectedMetricKindId;

export const getSelectedMetricKind = createSelector(
    [
        getSelectedMetricKindId,
        getMetricKindEntities
    ],
    (selectedMetricKindId, allMetricKinds) => {
        if (!isValue(selectedMetricKindId)) return null;
        return allMetricKinds[selectedMetricKindId] ?? null;
    }
);

export const getMetricValueSaveState = (state: RootState) => state.qualityMetricKinds.metricValueSaveState;

export const getIsSelectedMetricKindEditable = createSelector(
    [
        getSelectedMetricKindId,
        getMetricKindEntities
    ],
    (selectedMetricKindId, allMetricKinds) => {
        if (!isValue(selectedMetricKindId)) return false;
        const selectedMetricKind = allMetricKinds[selectedMetricKindId];
        return isValue(selectedMetricKind) && !isValue(selectedMetricKind.publishedDate);
    }
);

export const getValuesForSelectedMetric = createSelector(
    [
        getMetricValues,
        getSelectedMetricKindId
    ],
    (allValues, selectedMetricKindId) => {
        const emptySet: QualityMetricValue[] = [];
        if (!isValue(selectedMetricKindId)) return emptySet;
        return allValues
            .filter(value => value.metricKindId === selectedMetricKindId)
            .sort((left, right) => left.sequence - right.sequence);
    }
);