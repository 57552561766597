import { ErrorDisplayType } from "../../../../../store/factory/sagaWithProgress/types";
import { ImageDto, ImageKind } from "../../../../commonModels/imageTypes";
import { actionFactory } from "../types";

export const {

    loadVendorBanner,
    loadVendorBannerReducer,
    loadVendorBannerSagaFactory

} = actionFactory
    .withMappedName("loadVendorBanner")
    .createAsyncAction<{ companyId: number }, ImageDto>({
        actionStatusSelector: state => state.vendorBannerLoadingState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.company.getImage(arg.companyId, ImageKind.Banner);
        },
        onFulfilled: (state, action) => {
            state.vendorBanner = action.payload.result;
        },
        errorDisplay: ErrorDisplayType.None
    });