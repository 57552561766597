import { DataView } from 'primereact/dataview';
import React from "react";
import { useMediaQuery } from "react-responsive";
import { FlexColumnContainer } from '../../../../components/containers/flexColumnContainer';
import { useAppSelector } from '../../../../store/configureStore';
import { RootState } from '../../../../store/rootReducer';
import { useMountEffect } from '../../../../utils/useEffectHelper';
import { ActionStatus } from '../../../actionStatus/actionStatus';
import { isEntityImage } from '../../../commonModels/entityImage';
import { MixedMedia } from '../../../commonModels/mixedMedia';
import { smallDeviceQuery } from '../../../responsiveconfiguration';
import { ImageActions, ImageListDisplay } from "./imageListDisplay";
import { EntityMediaEditorHeader } from './entityMediaEditorHeader';
import { VideoActions, VideoListDisplay } from './videoListDisplay';

export interface EntityMediaEditorProps {
    entityId: number;
    imageActions: ImageActions;
    videoActions: VideoActions;
    thumbnailSelector: (state: RootState) => number | null | undefined;
    mediaSelector: (state: RootState) => MixedMedia[];
    mediaLoadingSelector: (state: RootState) => ActionStatus;
    uploadImage: (entityId: number, localFileUrl: string) => void;
    showAddVideoDialog: (entityId: number) => void;
    loadMedia: (entityId: number) => void;
};

export const EntityMediaEditor: React.FC<EntityMediaEditorProps> = ({
    entityId,
    imageActions,
    videoActions,
    thumbnailSelector,
    mediaSelector,
    mediaLoadingSelector,
    uploadImage,
    showAddVideoDialog,
    loadMedia
}) => {
    
    const menuThumbnailImageId = useAppSelector(thumbnailSelector);
    const isSmallDevice = useMediaQuery(smallDeviceQuery);

    const entityMedia = useAppSelector(mediaSelector);
    const mediaLoadingState = useAppSelector(mediaLoadingSelector);

    useMountEffect(() => {
        loadMedia(entityId);
    });

    const itemTemplate = (item: MixedMedia, _layout: string) => {
        if (!item) return;

        if (isEntityImage(item)) {
            return <ImageListDisplay
                data={item}
                isMenuThumbnail={menuThumbnailImageId === item.id}
                onSave={imageActions.onSave}
                onDelete={imageActions.onDelete}
            />
        }
        else {
            return <VideoListDisplay
                data={item}
                onSave={videoActions.onSave}
                onDelete={videoActions.onDelete}
            />;
        }
    }

    return <FlexColumnContainer
        width={isSmallDevice ? "90vw" : "60vw"}
        className="entity-media-editor"
        minHeight="80vh"
    >
        <EntityMediaEditorHeader
            inventoryId={entityId}
            mediaSelector={mediaSelector}
            uploadInventoryImage={uploadImage}
            showAddVideoDialog={showAddVideoDialog}
        />

        <DataView
            layout="list"
            itemTemplate={itemTemplate}
            value={entityMedia}
            loading={mediaLoadingState.isExecuting}
            className="entity-media-list"
        />
    </FlexColumnContainer>
}