import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { isValue } from "../../../../../../utils/valueHelper";
import { BulkAssignmentKind } from "../../../../../commonModels/bulkAssignmentState";
import { actionFactory, InventoryManagementState } from "../../types";

export const beginBulkQualityGradeAssignment = actionFactory.createAppAction<BulkAssignmentKind>("beginBulkQualityGradeAssignment");

export function beginBulkQualityGradeAssignmentReducers(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forAction(beginBulkQualityGradeAssignment)
        .addHandler((state, action) => {
            state.bulkQualityGradeAssignmentState.inventory = state
                .selectedInventoryIds
                .map(id => state.inventory.entities[id])
                .filter(isValue);
            state.bulkQualityGradeAssignmentState.bulkAssignmentKind = action.payload;
        })
        .build();
}