import _ from "lodash";
import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory, CompanyProfile, CompanyProfileState } from "../types";

export const saveCompanyProfile = actionFactory.createAppAction<Partial<CompanyProfile>>("saveCompanyProfile");

export const saveCompanyProfileSagaActions = actionFactory.createSagaWithProgressActions<Partial<CompanyProfile>, CompanyProfile>(saveCompanyProfile.type);

export function saveCompanyProfileReducers(reducerFactory: IReducerFactory<CompanyProfileState>): IReducerFactory<CompanyProfileState> {
    return reducerFactory
        .forSagaWithProgress(saveCompanyProfileSagaActions)
        .withActionStatus(state => state.profileSavingState)
        .onFulfilled((state, action) => {
            state.companyProfile = action.payload.result;
        })
        .build();
}

async function performAction(arg: Partial<CompanyProfile>, context: SagaAppContext): Promise<CompanyProfile> {
    const rootState = context.getState();

    const currentValue = rootState.companyProfile.companyProfile;

    if (_.matches(arg)(currentValue)) return currentValue;

    const mergedProfile: CompanyProfile = { ...currentValue, ...arg };

    return context.marketplaceApiClient.company.updateCompanyProfile(mergedProfile);
}

export function createSaveCompanyProfileSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: saveCompanyProfileSagaActions,
            errorTitle: "An error occured while saving the company profile"
        },
        actionPattern: saveCompanyProfile.type,
        debounceInMilliseconds: 500
    });
};