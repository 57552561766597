import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { FileUpload, FileUploadSelectParams, FileUploadUploadParams } from 'primereact/fileupload';
import { Message } from 'primereact/message';
import { CSSProperties, useRef } from "react";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { DefaultImageUploadToolTipTarget, ImageUploadToolTip } from "../../../../components/fileUpload/imageUploadToolTip";
import { WaitDisplaySmall } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { RootState } from "../../../../store/rootReducer";
import { AcceptedImageFileExtensions } from "../../../../utils/imageHelper";
import { isValue } from "../../../../utils/valueHelper";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { TitleWithDelete } from "./titleWithDelete";

export interface ImageSelectorProps {
    currentImageSelector: (state: RootState) => string | null | undefined;
    newImageSelector: (state: RootState) => string | null | undefined;
    saveStateSelector: (state: RootState) => ActionStatus;
    loadStateSelector: (state: RootState) => ActionStatus;
    title: string;
    onNewImageSet: (fileUrl: string) => void;
    onReplaceImage: () => void;
    onDeleteCurrentImage: () => void;
    onDeleteNewImage: () => void;
    imageSize: { width: string | number | undefined, height: string | number | undefined }
    imageContainerSize: { width: string | number | undefined, height: string | number | undefined }
}

export const ImageSelector: React.FC<ImageSelectorProps> = (
    {
        currentImageSelector,
        newImageSelector,
        saveStateSelector,
        loadStateSelector,
        title,
        onNewImageSet,
        onReplaceImage,
        onDeleteCurrentImage,
        onDeleteNewImage,
        imageSize,
        imageContainerSize
    }
) => {

    const imageFileUploadRef = useRef<FileUpload>(null);

    const currentImage = useAppSelector(currentImageSelector);
    const newImageObjectUrl = useAppSelector(newImageSelector);

    const saveImageState = useAppSelector(saveStateSelector);
    const loadImageState = useAppSelector(loadStateSelector);

    const onTemplateUpload = (_: FileUploadUploadParams) => {
    }

    const onSelectImage = async (e: FileUploadSelectParams) => {
        if (isValue(newImageObjectUrl)) {
            URL.revokeObjectURL(newImageObjectUrl);
        }
        
        const fileUrl = URL.createObjectURL(e.files[0]);        
        onNewImageSet(fileUrl);
        imageFileUploadRef.current?.clear();
    }

    const onReplaceImageClick = () => onReplaceImage();
    const onDeleteImageClick = () => onDeleteCurrentImage();
    const onDeleteNewImageClick = () => onDeleteNewImage();

    const titleBarStyle: CSSProperties = {
        fontSize: "22px",
        fontWeight: "bold"
    };
  
    return <div className="p-mt-2">
        <Divider style={titleBarStyle}>{title}</Divider>

        <Message
            severity="info"
            text={`Optimum image size is ${imageSize.width} x ${imageSize.height} pixels or smaller. Larger images may stretch or clip.`}
            className="p-ml-3"
        />

        <div className="p-grid p-mt-2" >
            <div className="p-shadow-1 p-ml-4" style={{ ...imageContainerSize }} >

                <TitleWithDelete
                    title="Current"
                    onDelete={onDeleteImageClick}
                    deleteVisible={!!currentImage}
                />

                <FlexRowContainer centerContent justifyContent="center" width={imageContainerSize.width} height={imageSize.height} >
                    {
                        !loadImageState.isExecuting
                            ? !!currentImage
                                ? <img src={currentImage} alt="" {...imageSize} style={{ objectFit: "contain" }} ></img>
                                : <div>{`No ${title}`}</div>
                            : <WaitDisplaySmall height="60px" />
                    }
                </FlexRowContainer>

            </div>

        </div>

        <div className="p-grid p-mt-2" >
            <div className="p-shadow-1 p-ml-4" style={{ ...imageContainerSize }}>

                <TitleWithDelete
                    title={`New ${title}`}
                    onDelete={onDeleteNewImageClick}
                    deleteVisible={!!newImageObjectUrl}
                />

                <FlexRowContainer centerContent justifyContent="center" width={imageContainerSize.width} height={imageSize.height} >
                    {
                        !!newImageObjectUrl
                            ? <img src={newImageObjectUrl} alt="" {...imageSize} style={{ objectFit: "contain" }} ></img>
                            : <div style={imageSize}></div>
                    }
                </FlexRowContainer>

            </div>
            <div style={{ width: "120px", marginLeft: "20px" }}>
                <ImageUploadToolTip />
                <FileUpload
                    ref={imageFileUploadRef}
                    style={{ width: "120px" }}
                    className={DefaultImageUploadToolTipTarget}
                    name="image-fileupload"
                    url="./upload"
                    mode={"basic"}
                    multiple={false}
                    accept={AcceptedImageFileExtensions}
                    maxFileSize={1000000}
                    customUpload={true}
                    onUpload={onTemplateUpload}
                    onSelect={onSelectImage}
                />
                <Button
                    className="logo-upload-save p-mt-2"
                    icon={PrimeIcons.UPLOAD}
                    label="Save"
                    loading={saveImageState.isExecuting}
                    disabled={newImageObjectUrl === undefined}
                    onClick={onReplaceImageClick}
                ></Button>
            </div>
        </div>
    </div>
}