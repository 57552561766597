import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { getRefreshInterval } from "../store/selectors/optionsDialog";

export const MenuRefresher: React.FC = () => {

    const dispatch = useDispatch();
    const refreshInterval = useAppSelector(getRefreshInterval);

    useEffect(() => {
        if (refreshInterval > 0) {

        }
        else {
            
        }
    }, [refreshInterval, dispatch]);

    return null;
}