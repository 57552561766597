import { isDate } from 'lodash';
import { DateTime } from 'luxon';
import { FilterOperator } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { FlexColumnContainer } from '../../../components/containers/flexColumnContainer';
import { FlexRowContainer } from '../../../components/containers/flexRowContainer';

export interface DateFilterModel {
    filterMatchMode: FilterOperator
    before: DateTime | null;
    after: DateTime | null;
    on: DateTime | null;
}

export interface DateFilterElementProps {
    elementOptions: ColumnFilterElementTemplateOptions;
}

export const DateFilterElement: React.FC<DateFilterElementProps> = ({ elementOptions }) => {

    const dateFilter: DateFilterModel = elementOptions.value ?? { before: null, after: null, on: null, filterMatchMode: FilterOperator.AND };
    const labelWidth = "60px";

    const operatorOptions = [{ label: "Match All", value: FilterOperator.AND }, { label: "Match Any", value: FilterOperator.OR }];

    return <FlexColumnContainer gap="20px">

        <FlexRowContainer gap="10px" centerContent >

            <Dropdown
                value={dateFilter.filterMatchMode}
                options={operatorOptions}
                onChange={(e) => {                    
                    const newFilterModel: DateFilterModel = {
                        ...dateFilter,
                        filterMatchMode: e.value
                    };

                    elementOptions.filterCallback(newFilterModel);
                }}
                style={{ width: "100%" }}
            />

        </FlexRowContainer>

        <FlexRowContainer gap="10px" centerContent >
            <div style={{ width: labelWidth }} >On</div>
            <Calendar
                value={dateFilter.on?.toJSDate()}
                onChange={(e) => {                    

                    const newFilterModel: DateFilterModel = {
                        ...dateFilter,
                        on: isDate(e.value) ? DateTime.fromJSDate(e.value) : null
                    };

                    elementOptions.filterCallback(newFilterModel);
                }}
            />

        </FlexRowContainer>

        <FlexRowContainer gap="10px" centerContent >
            <div style={{ width: labelWidth }} >Before</div>
            <Calendar
                value={dateFilter.before?.toJSDate()}
                onChange={(e) => {

                    const newFilterModel: DateFilterModel = {
                        ...dateFilter,
                        before: isDate(e.value) ? DateTime.fromJSDate(e.value) : null
                    };

                    elementOptions.filterCallback(newFilterModel);
                }}
            />

        </FlexRowContainer>

        <FlexRowContainer gap="10px" centerContent >
            <div style={{ width: labelWidth }} >After</div>
            <Calendar
                value={dateFilter.after?.toJSDate()}
                onChange={(e) => {                    

                    const newFilterModel: DateFilterModel = {
                        ...dateFilter,
                        after: isDate(e.value) ? DateTime.fromJSDate(e.value) : null
                    };

                    elementOptions.filterCallback(newFilterModel);
                }}
            />
        </FlexRowContainer>

    </FlexColumnContainer>
};