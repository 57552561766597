import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { Product } from "../../../../inventorySetup/productManagement/store/types";
import { actionFactory, CategoryManagementState } from "../types";

export const setSelectedAssignedProducts = actionFactory.createAppAction<Product[]>("setSelectedAssignedProducts");

export function setSelectedAssignedProductsReducers(reducerFactory: IReducerFactory<CategoryManagementState>): IReducerFactory<CategoryManagementState> {
    return reducerFactory        
        .forAction(setSelectedAssignedProducts)
        .addHandler((state, action) => {
            state.categoryProductAssignment.selectedAssignedProducts = action.payload;
        })
        .build();
}