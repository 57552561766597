import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ValidationResponder } from "../../../../components/formikExtensions/validationResponderProps";
import { OverlayWaitDisplay } from '../../../../components/waitDisplay';
import { useAppSelector } from '../../../../store/configureStore';
import { useMountEffect } from '../../../../utils/useEffectHelper';
import { loadCustomerProfile } from '../../../company/profile/store/featureActions/loadCustomerProfile';
import { saveCompanyProfile } from '../../../company/profile/store/featureActions/saveCustomerProfile';
import { getCompanyProfileLoadingState, getCompanyProfileSavingState } from '../../../company/profile/store/selectors/actionStatus';
import { getCompanyProfile } from '../../../company/profile/store/selectors/companyProfile';
import { CompanyProfile } from '../../../company/profile/store/types';
import { CompanyInfo } from '../../../company/profile/ui/companyInfo';
import { companyProfileValidationSchema } from '../../../company/profile/validations';
import { setCompanyProfileValid } from '../store/featureActions/setCompanyProfileValid';
import './index.css';

export interface CompanyProfileFormProps {
    formikRef: React.Ref<FormikProps<Partial<CompanyProfile>>>;
}

export const CompanyProfileForm: React.FC<CompanyProfileFormProps> = ({ formikRef }) => {

    const dispatch = useDispatch();

    const companyProfile: Partial<CompanyProfile> = useAppSelector(getCompanyProfile);
    const loadingState = useAppSelector(getCompanyProfileLoadingState);
    const savingState = useAppSelector(getCompanyProfileSavingState);

    useMountEffect(() => {
        dispatch(loadCustomerProfile());
    });

    const onSaveCompanyProfileSubmit = (values: Partial<CompanyProfile>, formikHelpers: FormikHelpers<Partial<CompanyProfile>>): void | Promise<any> => {
        dispatch(saveCompanyProfile(values));
        formikHelpers.setSubmitting(false);
    };

    const onChange = (isValid: boolean) => {
        dispatch(setCompanyProfileValid(isValid));
    }

    return <OverlayWaitDisplay
        isWaiting={loadingState.isExecuting || savingState.isExecuting}
    >
        <Formik
            innerRef={formikRef}
            initialValues={companyProfile}
            enableReinitialize={true}
            validationSchema={companyProfileValidationSchema}
            onSubmit={onSaveCompanyProfileSubmit}
        >
            <Form autoComplete="off">
                <ValidationResponder handleValidityChanged={onChange} />
                <CompanyInfo                    
                    allowStateChange={true}
                />
            </Form>
        </Formik>
    </OverlayWaitDisplay>
}