import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../../store/rootSaga";
import { actionFactory, ProductManagementState } from "../../types";
import { EntityImage } from "../../../../../commonModels/entityImage";
import { isValue } from "../../../../../../utils/valueHelper";

export interface ProductImageUploadArgs {
    localFileUrl: string;
    productId: number;
}
export const uploadNewProductImage = actionFactory.createAppAction<ProductImageUploadArgs>("uploadNewProductImage");

const sagaActions = actionFactory.createSagaWithProgressActions<ProductImageUploadArgs, EntityImage>(uploadNewProductImage.type);

export function uploadNewProductImageReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.editImageSaveState)
        .onFulfilled((state, action) => {
            state.editImages.push(action.payload.result);
            if (state.editImages.length === 1 && isValue(state.editMediaEntity)) {            
                state.editMediaEntity.menuThumbnailId = action.payload.result.id;
                const mainInv = state.products.entities[state.editMediaEntity.id];
                if (isValue(mainInv)) {
                    mainInv.menuThumbnailId = action.payload.result.id;
                }
            }
        })
        .build();
}

async function performLoad(arg: ProductImageUploadArgs, context: SagaAppContext): Promise<EntityImage> {      
    const blob = await fetch(arg.localFileUrl).then(localFile => localFile.blob());
    return context.marketplaceApiClient.productManagement.uploadProductImage(arg.productId, blob);
}

export function createUploadNewProductImageSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: uploadNewProductImage.type,
        debounceInMilliseconds: 250
    });
};