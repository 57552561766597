import { createEntityAdapter } from "@reduxjs/toolkit";
import { CreateInitialModalEditState } from "../../../../store/factory/modalEdit/modalEdit";
import { CreateActionStatusInitialState } from "../../../actionStatus/actionStatus";
import { QualityMetricKind } from "../../models/metricKind";
import { QualityMetricValue } from "../../models/metricValue";
import { QualityMetricKindsState } from './types';

export const metricKindAdapter = createEntityAdapter<QualityMetricKind>();
export const metricValueAdapter = createEntityAdapter<QualityMetricValue>();

export const createInitialState = (): QualityMetricKindsState => {
    return {        
        loadingState: CreateActionStatusInitialState(),      
        metricKinds: metricKindAdapter.getInitialState(),
        addMetricKindState: CreateInitialModalEditState(),
        selectedMetricKindId: null,
        metricValueSaveState: CreateActionStatusInitialState(),
        metricValues: metricValueAdapter.getInitialState()
    };
};

export const initialState: QualityMetricKindsState = createInitialState();