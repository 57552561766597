import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createPollingSaga, FulfilledPayload, RejectedPayload } from "../../../../../store/factory/polling/pollingSaga";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { isValue } from "../../../../../utils/valueHelper";
import { actionFactory, LiveMenuState } from "../types";
import { loadLiveMenu } from "./loadLiveMenu";
import { loadSectionInventory } from "./loadSectionInventory";

export const beginRefreshInventory = actionFactory.createAppAction("beginRefreshInventory");
export const cancelRefreshInventory = actionFactory.createAppAction("cancelPollingSyncRequest");

const handlePollingError = actionFactory.createAppAction<RejectedPayload<void>>("handlePollingError");
const handlePollingSuccess = actionFactory.createAppAction<FulfilledPayload<void, void>>("handlePollingSuccess");

export function refreshInventoryReducers(reducerFactory: IReducerFactory<LiveMenuState>): IReducerFactory<LiveMenuState> {
    return reducerFactory
        .forAction(handlePollingError)
        .addHandler((_state, _action) => {
        })
        .build()
        .forAction(handlePollingSuccess)
        .addHandler((_state, _action) => {
        })
        .build();
}

const refreshInventory = async (_arg: void, context: SagaAppContext): Promise<void> => {
    const { vendorMenu, vendor } = context.getState().liveMenu;
    if (!isValue(vendorMenu) || !isValue(vendor)) return;

    await context.dispatch(loadLiveMenu({ menuName: vendorMenu.urlName, vendorName: vendor.vendorUrlName }));

    const sections = context.getState().liveMenu.vendorMenu?.sections;
    if (!isValue(sections)) return;

    sections.forEach(section => {        
        context.dispatch(loadSectionInventory({ sectionId: section.id }));
    });

    return;
}

const pollResultPredicate = (_arg: void, _result: void): Promise<boolean> => {
    return Promise.resolve(true);
}

const getPollingInterval = async (_arg: void, context: SagaAppContext): Promise<number> => {
    return context.getState().liveMenu.refreshOptions.refreshIntervalMilliseconds;
}

export const createRefreshInventorySaga = (context: SagaAppContext) => {
    return createPollingSaga<void, void>({
        action: refreshInventory,
        appContext: context,
        pollResultPredicate: pollResultPredicate,
        sagaActions: {
            beginPollingAction: beginRefreshInventory,
            cancelPollingAction: cancelRefreshInventory,
            onPollFailureAction: handlePollingError,
            onPollSucessAction: handlePollingSuccess,            
        },
        pollIntervalMs: getPollingInterval
    });
}