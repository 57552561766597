import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, CategoryManagementState } from "../types";

export const removeProductAssignments = actionFactory.createAppAction("removeProductAssignments");

export function removeProductAssignmentsReducers(reducerFactory: IReducerFactory<CategoryManagementState>): IReducerFactory<CategoryManagementState> {
    return reducerFactory        
        .forAction(removeProductAssignments)
        .addHandler((state, _) => {
            const selectedAssignedProductIds = state.categoryProductAssignment.selectedAssignedProducts.map(p => p.id);
            selectedAssignedProductIds.forEach(id => {
                state.categoryProductAssignment.assignedProductMap[id] = undefined;
            });

            state.categoryProductAssignment.selectedAssignedProducts = [];
        })
        .build();
}