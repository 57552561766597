import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { inventoryVideoAdapter } from "../../state";
import { actionFactory, InventoryManagementState } from "../../types";

export const completeEditInventoryImages = actionFactory.createAppAction("completeEditInventoryImages");

export function completeEditInventoryImagesReducers(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forAction(completeEditInventoryImages)
        .addHandler((state, _) => {
            state.editMediaEntity = undefined;
            state.editImages = [];
            inventoryVideoAdapter.removeAll(state.editVideos);
        })
        .build();
}