import { TransformResult } from "../../../utils/promiseHelpers";
import ApiClient from "../../apiCommunication/apiClient";
import { createUtcDateSerializationFunction } from "../../apiCommunication/serialization";
import { ApiKeyVerification, VerifyRequest } from "../buyerVerification/store/types";

const deserializeApiKeyVerificationDates = createUtcDateSerializationFunction<ApiKeyVerification>({ properties:["createdUtc", "verifiedDateUtc"] });

export default class IdentityVerification {

    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    getCurrentApiKeyVerifications = (): Promise<ApiKeyVerification[]> => {
        return this
            .apiClient
            .executeGet<ApiKeyVerification[]>(`MetrcVerification/buyer`)
            .then(results => {
                results.forEach(result => TransformResult(deserializeApiKeyVerificationDates, result));                
                return results;
            });            
    };
    
    submitVerificationRequest = (request: VerifyRequest): Promise<ApiKeyVerification> => {
        return this
            .apiClient
            .executePostWithResult<ApiKeyVerification>(`MetrcVerification/buyer`, request)
            .then(deserializeApiKeyVerificationDates);            
    };
}