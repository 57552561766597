import React from 'react';
import ReactDOM from 'react-dom';
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './index.css';
import App from './features/appLayout';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/configureStore';
import { ConnectedRouter } from 'connected-react-router'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CacheBuster } from './components/cacheBuster';
import { appHistory } from './store/historyInstance';
import './assets/fonts/index.css';

ReactDOM.render(
  <React.StrictMode>
    <CacheBuster>

      <Provider store={store}>
        <ConnectedRouter history={appHistory}>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </ConnectedRouter>
      </Provider>

    </CacheBuster>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
