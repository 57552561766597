import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../../store/rootSaga";
import { actionFactory, ProductManagementState } from "../../types";
import { EntityImage } from "../../../../../commonModels/entityImage";

export const deleteProductImage = actionFactory.createAppAction<EntityImage>("deleteProductImage");

const sagaActions = actionFactory.createSagaWithProgressActions<EntityImage, Response>(deleteProductImage.type);

export function deleteProductImageReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.editImageSaveState)
        .onFulfilled((state, action) => {
            state.editImages = state.editImages.filter(image => image.id !== action.payload.arg.id);
        })
        .build();
}

async function performAction(arg: EntityImage, context: SagaAppContext) {          
    return context.marketplaceApiClient.productManagement.deleteProductImage(arg.id);
}

export function createDeleteProductImageSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: deleteProductImage.type,
        debounceInMilliseconds: 400
    });
};