import { Load2Result, parallelLoad } from "../../../../../utils/promiseHelpers";
import { QualityMetricKind } from "../../../models/metricKind";
import { QualityMetricValue } from "../../../models/metricValue";
import { metricKindAdapter, metricValueAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    loadSliceData,
    loadSliceDataReducer,
    loadSliceDataSagaFactory

} = actionFactory
    .withMappedName("loadSliceData")
    .createAsyncAction<void, Load2Result<QualityMetricKind[], QualityMetricValue[]>>({        
        actionStatusSelector: state => state.loadingState,
        asyncCall: (_arg, context) => {            
            return parallelLoad(
                () => context.marketplaceApiClient.quality.getQualityMetricKinds(),
                () => context.marketplaceApiClient.quality.getQualityMetricValues()
            );            
        },
        onFulfilled: (state, action) => {
            metricKindAdapter.upsertMany(state.metricKinds, action.payload.result.result1);
            metricValueAdapter.upsertMany(state.metricValues, action.payload.result.result2);

            if (action.payload.result.result1.length > 0) {
                state.selectedMetricKindId = action.payload.result.result1
                    .sort((left, right) => left.name.localeCompare(right.name))
                    [0]?.id ?? null;
            }
        }
    });