import { menuSectionAdapter } from "../state";
import { actionFactory, MenuSection } from "../types";

export const {

    addSectionToMenu,
    addSectionToMenuReducer,
    addSectionToMenuSagaFactory

} = actionFactory
    .withMappedName("addSectionToMenu")
    .createAsyncAction<void, MenuSection>({        
        actionStatusSelector: state => state.addSectionSaveState,
        asyncCall: (_arg, context) => {

            const state = context.getState();
            const menu = state.menuAdmin.menu;
            if (menu === null) throw new Error("Attempted to save section to menu without a valid menu reference");

            return context.marketplaceApiClient.menuAdmin.upsertMenuSection({
                categoryIds: [],
                description: "",
                menuId: menu.id,
                id: 0,
                name: "New Section",
                sequence: 0,
                tenantId: ""
            });
        },
        onFulfilled: (state, action) => {
            menuSectionAdapter.upsertOne(state.menuSections, action.payload.result);
        },
        debounceInMilliseconds: 250
    });