import { isValue } from "../../../../../../utils/valueHelper";
import { ImageKind } from "../../../../../commonModels/imageTypes";
import { actionFactory } from "../../types";

export const {

    deleteThumbnail,
    deleteThumbnailReducer,
    deleteThumbnailSagaFactory
} = actionFactory
    .withMappedName("deleteThumbnail")
    .createAsyncAction<void, Response>({        
        actionStatusSelector: state => state.thumbnailState.saveState,
        asyncCall: (_, context) => {
            const menuId = context.getState().menuAdmin.menu?.id;
            if (!isValue(menuId)) throw new Error("Cannot delete image without valid menu instance");
            return context.marketplaceApiClient.menuAdmin.deleteImage(menuId, ImageKind.ImageUnavailable);
        },
        onFulfilled: (state, _) => {
            state.logoState.currentImage = null;
        },
        debounceInMilliseconds: 100
    });