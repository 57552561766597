
export function hasValue(value: any): boolean {
    return value !== undefined && value !== null;
}

export function isValue<T>(value: T | null | undefined): value is T {
    return hasValue(value);
}

export function valueOrException<T>(value: T | null | undefined, errorMessage?: string): T {
    if (isValue(value)) {
        return value;
    }
    else{
        throw new Error(errorMessage ?? "Value was null or undefined");
    }    
}

export function isNumber (value: any) : value is number {
    return isValue<number>(value);
}


export function isString (value: any) : value is string {
    return isValue(value) && typeof(value) === "string";
}

export function isNonEmpty (value: string | null | undefined) : value is string {
    return isValue(value) && value.length > 0;
}

export function isNullOrEmpty (value: string | null | undefined) : boolean {
    return !isValue(value) || value.length === 0;
}

export function stringEquals (left: string | null | undefined, right: string | null | undefined) : boolean {
    return isValue(left) && isValue(right) && left.localeCompare(right) === 0;
}