import { fullDateDisplay } from "../../../utils/datetimeHelper";
import { isValue } from "../../../utils/valueHelper";
import { isQueueStatusComplete } from "../../../features/commonModels/queueStatus";
import { SyncRequestDto } from "../../../features/commonModels/syncRequest";


export const getSyncStatusDisplay = (syncRequest: SyncRequestDto | null): string => {
    const lastSyncTime = syncRequest !== null && syncRequest.statusTimeUtc !== null ? syncRequest.statusTimeUtc.toLocal().toLocaleString(fullDateDisplay) : null;
    const syncInProgress = isSyncInProgress(syncRequest);
    

    if (!isValue(syncRequest)) {
        return `Synchronization had not occured`;
    }
    else if (syncRequest.errorDetail) {
        return `The last sync attempt failed. Please try again`;
    }
    else if (syncInProgress) {
        return 'The sync is in progress...';
    }
    else {
        return `Synchronized as of : ${lastSyncTime}`;
    }
};

export const isSyncInProgress = (syncRequest: SyncRequestDto | null): boolean => {
    return syncRequest !== null && !isQueueStatusComplete(syncRequest.status);
};

export const getEntityName = (syncRequest: SyncRequestDto | null): string => {
    if (syncRequest?.kind === "Products") {
        return "Items";
    }
    else if (syncRequest?.kind === "Inventory") {
        return "Inventory";
    }
    else if (syncRequest?.kind === "Licenses") {
        return "Licenses";
    }
    else {
        return "";
    }
};
