import { DateTime } from 'luxon';
import * as yup from 'yup';
import { isValue } from '../../../../utils/valueHelper';
import { InventoryView, InventoryType } from "../store/types";
import { MetrcPackageType } from "../../../commonModels/metrcPackageType";

export const inventoryValidations: yup.SchemaOf<Partial<InventoryView>> = yup.object().shape({
    id: yup.number().optional(),
    tenantId: yup.string().optional(),
    productId: yup.number().optional(),
    licenseId: yup.number().optional(),
    menuThumbnailId: yup.number().optional().nullable(),
    licenseNumber: yup.string().optional(),
    metrcName: yup.string().optional(),
    metrcStrainName: yup.string().optional(),
    metrcPackageId: yup.number().optional(),
    metrcPackageLabel: yup.string().optional(),
    metrcPackageType: yup.mixed<MetrcPackageType>().optional(),
    quantity: yup.number().optional(),
    unitOfMeasureName: yup.string().optional(),
    marketingName: yup.string().optional().nullable()
        .max(128, "Must have less than 128 characters"), 
    marketingStrainName: yup.string().optional().nullable()
        .max(128, "Must have less than 128 characters"),
    marketingDescription: yup.string().optional().nullable()
        .max(255, "Must have less than 512 characters"),
    thcPercentage: yup.number().optional().nullable(),
    cbdPercentage: yup.number().optional().nullable(),
    unitPrice: yup.number().optional().nullable(),
    packagedDate: yup.mixed<DateTime>().optional().nullable(),
    inactiveDate: yup.mixed<DateTime>().optional().nullable(),
    readyDate: yup.mixed<DateTime>().optional().nullable(),
    excludeFromMarket: yup.boolean().optional(),
    reservedQuantity: yup.number()
        .optional()
        .nullable()
        .test(
            "reservedQuantityAndOrderedQuantityDoNotExceedQuantity",
            "Reserved quantity + ordered quantity must be less than or equal to available quantity",
            function (_value, context) {
                const model = context.parent as InventoryView;
                return isValue(model.reservedQuantity)
                    ? model.quantity >= (model.reservedQuantity + model.orderedQuantity)
                    : true
            }
        ),
    onMenuDirectly: yup.boolean().optional(),
    onMenuViaProduct: yup.boolean().optional(),
    qualityGradeId: yup.number().nullable(),
    orderedQuantity: yup.number().optional(),
    qualityAssessmentId: yup.number().optional().nullable(),
    qualityAssessmentGradeValueId: yup.number().optional().nullable(),
    calulatedQualityGradeValueId: yup.number().optional().nullable(),
    inventoryType: yup.mixed<InventoryType>().required(),
});
