import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { NotesList } from '../../../../components/notes/notesList';
import { useAppSelector } from "../../../../store/configureStore";
import { CustomerNote } from '../../common/types';
import { deleteCustomerNote } from '../store/featureActions/deleteCustomerNote';
import { upsertCustomerNote } from '../store/featureActions/upsertCustomerNote';
import { getCustomer, getCustomerNoteById, getCustomerNoteIds, getSaveCustomerNoteStatus } from "../store/selectors/customer";

export const CustomerNotes: React.FC = () => {

    const customer = useAppSelector(getCustomer);
    const dispatch = useDispatch();

    const saveNewNote = (notes: string) => {
        const newNote: CustomerNote = {
            createdBy: "",
            createdUtc: DateTime.utc(),
            modifiedUtc: DateTime.utc(),
            id: 0,
            notes: notes,
            customerId: customer?.id ?? -1
        };

        dispatch(upsertCustomerNote(newNote));
    }

    const updateNote = (note: CustomerNote) => {
        dispatch(upsertCustomerNote(note));
    }

    const deleteNote = (note: CustomerNote) => {
        dispatch(deleteCustomerNote(note));
    }

    return <NotesList<CustomerNote>
        actionStatusSelector={getSaveCustomerNoteStatus}
        noteIdsSelector={getCustomerNoteIds}
        getByIdSelector={getCustomerNoteById}
        onDeleteNote={deleteNote}
        onSaveNewNote={saveNewNote}
        onUpdateNote={updateNote}
        listClassName="customer-note-list"
    />    
}   