import { DateTime } from "luxon";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { NotesList } from "../../../../components/notes/notesList";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { getTenantId } from "../../../authentication/store/selectors/tenant";
import { deleteProductNote } from "../store/featureActions/notes/deleteProductNote";
import { loadProductNotes } from "../store/featureActions/notes/loadProductNotes";
import { upsertProductNote } from "../store/featureActions/notes/upsertProductNote";
import { editProductCancel, editProductComplete } from "../store/featureActions/productDetails/editProduct";
import { getEditedProductNoteIds, getEditProductState, getEditProductValue, getEditProductVisible, getProductNoteById, getProductNoteSaveStatus } from "../store/selectors/editedProduct";
import { Product, ProductNote } from "../store/types";
import { EditProduct } from "./editProduct";

export const EditProductDialog: React.FC = () => {

    const dispatch = useDispatch();
    const editedProduct = useAppSelector(getEditProductValue);
    
    const onCancel = () => { dispatch(editProductCancel()); };
    const onSave = (value: Product) => { dispatch(editProductComplete(value)); };
    const tenantId = useAppSelector(getTenantId);

    const editedProductId = editedProduct?.id ?? -1;

    useEffect(() => {        
        if (editedProductId <= 0) return;
        dispatch(loadProductNotes({ id: editedProductId }));

    }, [editedProductId, dispatch]);

    const onSaveNote = (note: ProductNote) => {
        dispatch(upsertProductNote(note));
    }

    const onSaveNewNote = (notes: string) => {
        const newNote: ProductNote = {
            createdBy: "",
            createdUtc: DateTime.utc(),
            modifiedUtc: DateTime.utc(),
            id: 0,
            notes: notes,
            productId: editedProduct?.id ?? -1,
            tenantId: tenantId ?? ""
        };

        dispatch(upsertProductNote(newNote));
    }
    const onDeleteNote = (note: ProductNote) => {
        dispatch(deleteProductNote(note));
    }

    return <FormikModalEditor<Product>
        title={`Edit Item: ${editedProduct?.metrcName}`}
        valueSelector={getEditProductValue}
        visibleSelector={getEditProductVisible}
        statusSelector={getEditProductState}
        onCancel={onCancel}
        onSave={onSave}
        position="top"
    >
        <TabView>

            <TabPanel header="Details">
                {isValue(editedProduct) && <EditProduct product={editedProduct} />}
            </TabPanel>

            <TabPanel header="Notes">

                <NotesList<ProductNote>
                    onDeleteNote={onDeleteNote}
                    onSaveNewNote={onSaveNewNote}
                    onUpdateNote={onSaveNote}
                    noteIdsSelector={getEditedProductNoteIds}
                    getByIdSelector={getProductNoteById}
                    actionStatusSelector={getProductNoteSaveStatus}
                    newNoteInputClassName="edit-product-notes-input"
                />

            </TabPanel>

        </TabView>

    </FormikModalEditor>
}