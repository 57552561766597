import { clone } from "lodash";
import { firstOrNull } from "../../../../../utils/arrayHelpers";
import { getEntities } from "../../../../../utils/entityStateHelpers";
import { isValue } from "../../../../../utils/valueHelper";
import { assignChildrenNodes, TemplateConstraintNode } from "../../../models/constraintNode";
import { expandedTreeNodeAdapter, templateConstraintAdapter, templateConstraintNodeAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    upsertTemplateConstraintNode,
    upsertTemplateConstraintNodeReducer,
    upsertTemplateConstraintNodeSagaFactory

} = actionFactory
    .withMappedName("upsertTemplateConstraintNode")
    .createAsyncAction<
        {
            gradeValueId: number,
            node: TemplateConstraintNode
        },
        TemplateConstraintNode
    >({
        actionStatusSelector: state => state.templateMetricSaveState,
        debounceInMilliseconds: 1000,
        asyncCall: (arg, context) => {
            const sliceState = context.getState().qualityTemplateEditor;
            const templateId = sliceState.template?.id;
            if (!isValue(templateId)) throw new Error("Cannot add template constraint node without a valid template instance");
            return context.marketplaceApiClient.quality.upsertTemplateConstraintNode(
                templateId,
                arg.gradeValueId,
                {
                    ...arg.node,
                    id: arg.node.id > 0 ? arg.node.id : 0
                });
        },
        onFulfilled: (state, action) => {

            if (action.payload.arg.node.id <= 0) {
                templateConstraintNodeAdapter.removeOne(state.constraintNodes, action.payload.arg.node.id);
            }

            templateConstraintNodeAdapter.upsertOne(state.constraintNodes, action.payload.result);

            const allNodes = getEntities(state.constraintNodes);
            assignChildrenNodes(allNodes);
            templateConstraintNodeAdapter.upsertMany(state.constraintNodes, allNodes);

            if (action.payload.arg.node.rootNodeId === null) {
                const gradeConstraint = firstOrNull(
                    getEntities(state.templateConstraints),
                    constraint => constraint.gradeValueId === action.payload.arg.gradeValueId
                );

                if (isValue(gradeConstraint)) {
                    templateConstraintAdapter.upsertOne(
                        state.templateConstraints,
                        {
                            ...gradeConstraint,
                            rootConstraintNodeId: action.payload.result.id
                        });
                }
            }

            // If a node was added to a parent, ensure the parent node is expanded
            if (isValue(action.payload.result.parentNodeId)) {
                const currentExpandedKeys = state.constraintTreeExpandedKeys.entities[action.payload.arg.gradeValueId];
                if (isValue(currentExpandedKeys)) {

                    const copy = clone(currentExpandedKeys.expandedKeys);
                    copy[action.payload.result.parentNodeId] = true;

                    expandedTreeNodeAdapter.upsertOne(state.constraintTreeExpandedKeys, {
                        gradeValueId: action.payload.arg.gradeValueId,
                        expandedKeys: copy
                    });
                }
            }
        }
    });