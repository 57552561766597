import { actionFactory } from "../../types";

export const {

    setNewLogo,
    setNewLogoReducer
    
} = actionFactory
    .withMappedName("setNewLogo")
    .createAction<string>((state, action) => {
        state.logoState.newImagePreview = action.payload;
    });