import { OrderedInventoryDetails, OrderedQuantityFilter } from "../../../../inventorySetup/inventoryManagement/store/types";
import { actionFactory } from "../types";

export const {

    getOrderedQuantities,
    getOrderedQuantitiesReducer,
    getOrderedQuantitiesSagaFactory

} = actionFactory
    .withMappedName("getOrderedQuantities")
    .createAsyncAction<OrderedQuantityFilter, OrderedInventoryDetails[]>({
        actionStatusSelector: state => state.orderedQuantitiesLoadState,
        asyncCall: (arg, context) => {                  
            return context.marketplaceApiClient.inventoryManagement.getOrderedQuantityDetails(arg);
        },
        onFulfilled: (state, action) => {
            state.orderedQuantities = action.payload.result;
        }
    });