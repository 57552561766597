import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, ErrorQueueState } from "../types";

export const dismissFirstError = actionFactory.createAppAction("dismissFirstError");

export function dismissFirstErrorReducers(reducerFactory: IReducerFactory<ErrorQueueState>): IReducerFactory<ErrorQueueState> {
    return reducerFactory        
        .forAction(dismissFirstError)
        .addHandler((state, _) => {
            state.errorQueue.shift();
        })
        .build();
}
