import clsx from 'clsx';
import { Field, FormikContextType, FieldProps, useFormikContext } from "formik";
import { FocusEvent, ChangeEvent, CSSProperties } from 'react';
import { getStringOrNullValue, PropertiesOfType, StringOrNullKeyOf } from '../../utils/typeHelpers';
import { isValue } from '../../utils/valueHelper';
import { InputTextarea } from 'primereact/inputtextarea';
import { FormikEditorClassStyles } from './classNameAttributes';

export interface TextAreaFieldProps<T extends PropertiesOfType<T, string | null | undefined>> extends FormikEditorClassStyles {
    defaultLabel: string;
    fieldName: keyof T & string & StringOrNullKeyOf<T>;    
    containerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;    
    placeHolder?: string;
    rows?: number;
    allowNoValue? : boolean;
    submitOnBlur? : boolean;
    autoResize?: boolean;

}

export const TextAreaField = <T extends PropertiesOfType<T, string | null | undefined>,>({
    defaultLabel,    
    fieldName,
    containerStyle,
    labelStyle,
    inputStyle,     
    placeHolder,
    rows,
    allowNoValue = true,
    submitOnBlur = false,
    autoResize = false,
    containerClassName,
    inputClassName,
    labelClassName
}: TextAreaFieldProps<T>) => {

    const formikProps: FormikContextType<T> = useFormikContext<T>();    
    const labelText = defaultLabel;    
    
    const onChange = (event: ChangeEvent<HTMLTextAreaElement> | FocusEvent<HTMLTextAreaElement>, props: FieldProps<T>) => {
        
        if (isValue(event.target.value) || allowNoValue) {
            formikProps.setFieldValue(props.field.name, event.target.value);
        }
    } 
    
    return <div style={containerStyle} className={containerClassName}>
        
        <label style={labelStyle} htmlFor={fieldName} className={labelClassName} >{labelText}</label>
        <Field
            name={fieldName}
        >
            {
                (props: FieldProps<T>) =>
                    <InputTextarea
                        rows={rows}
                        placeholder={placeHolder}
                        style={inputStyle}
                        id={fieldName}
                        autoResize={autoResize}                        
                        name={fieldName}                        
                        className={clsx(inputClassName, { "p-invalid": !!formikProps.errors[fieldName] })}
                        value={getStringOrNullValue(formikProps.values, fieldName) ?? undefined}
                        onChange={(event) => onChange(event, props)}
                        //defaultValue={getStringOrNullValue(formikProps.values, fieldName) ?? undefined}
                        onBlur={(_) => {
                             //onChange(event, props);
                             if (submitOnBlur && formikProps.dirty) {
                                formikProps.submitForm();
                             }
                        }}
                    />
            }
        </Field>
        <div>
            <small>{formikProps.errors[fieldName]}</small>
        </div>

    </div>


}