import { createSelector } from "@reduxjs/toolkit";
import { getUserIsMarketplaceAdmin } from "../../../../authentication/store/selectors/userRoles";
import { getHasAcceptedTerms, getUserAgreesToNewTerms } from "../../../acceptTermsOfService/store/selectors/termsOfService";
import { WorkflowStep } from "../types";
import { getCompanyProfileIsValid } from "./companyProfile";
import { getUserProfileIsValid } from "./userProfile";
import { getCurrentWorkflowStep } from "./workflowStep";


export const getCanNavigateBack = createSelector(
    [  
        getCurrentWorkflowStep,
        getUserIsMarketplaceAdmin    
    ], 
    (currentWorkflowStep, isMarketplaceAdmin) => {
        if (currentWorkflowStep === WorkflowStep.UserInfo && !isMarketplaceAdmin) return false;
        return currentWorkflowStep !== WorkflowStep.CompanyInfo;
    }
);

export const getCanNavigateForward = createSelector(
    [        
        getHasAcceptedTerms,
        getUserAgreesToNewTerms,
        getCompanyProfileIsValid,
        getUserProfileIsValid,
        getCurrentWorkflowStep
    ], 
    (hasAcceptedTerms, agreesToNewTerms, companyProfileIsValid, userProfileIsValid, currentWorkflowStep) => {
        if (currentWorkflowStep === WorkflowStep.CompanyInfo) {
            return companyProfileIsValid;
        }
        else if (currentWorkflowStep === WorkflowStep.UserInfo) {
            return userProfileIsValid;
        }
        else if (currentWorkflowStep === WorkflowStep.TermsOfService) {
            return hasAcceptedTerms || agreesToNewTerms;
        }
        else {
            return false;
        }
    }
);