import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { createActionColumnProps } from "../../../../utils/table/actionColumn";
import { createDateColumnProps } from "../../../../utils/table/dateColumn";
import { createFillRemainingSpaceColumnProps } from "../../../../utils/table/fillRemainingSpaceColumn";
import { createFilteredTextColumnProps } from "../../../../utils/table/stringColumn";
import { orderEditorRoute } from "../../../navigation/routes";
import { smallDeviceQuery, smallDeviceTableBreakpoint } from "../../../responsiveconfiguration";
import { SalesOrder } from "../../common/types";
import { createSalesOrder } from "../store/featureActions/createSalesOrder";
import { getCustomerOrders } from "../store/selectors/customer";

export const CustomerOrders: React.FC = () => {


    const orders = useAppSelector(getCustomerOrders);
    const dispatch = useDispatch();
    const history = useHistory();
    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    
    const onEdit = (order: SalesOrder) => {
        history.push(orderEditorRoute.createPath(order.id));
    }

    const onNewOrder = () => {
        dispatch(createSalesOrder());
    }

    const columns: ColumnProps[] = [
        createActionColumnProps<SalesOrder>({
            columnKey: "id",
            actionName: "Edit",
            actionHandler: onEdit,
            header: "Actions",            
            style: { width: 150 },
            sortable: false
        }),
        createDateColumnProps<SalesOrder>({ field: "createdUtc", header: "Created", style: { width: 200 } }),
        createFilteredTextColumnProps<SalesOrder>({ field: "orderNumber", header: "Order#", style: { width: 300 }, sortable: true }),
        createFilteredTextColumnProps<SalesOrder>({ field: "status", header: "Status", style: { width: 300 }, sortable: true }),
        createFillRemainingSpaceColumnProps({}),
    ];

    return <FlexColumnContainer
        gap="20px"
    >
        <FlexRowContainer
            gap="20px"
        >
            <Button label="New Order" onClick={onNewOrder} />
        </FlexRowContainer>
        <DataTable
            dataKey="id"
            size="small"
            value={orders}
            breakpoint={smallDeviceTableBreakpoint}
            scrollable={isSmallDevice ? false : true}
            scrollDirection={isSmallDevice ? "vertical": 'both'}
            scrollHeight={isSmallDevice ? "flex" : "flex"}
            responsiveLayout={isSmallDevice ? "stack" : undefined}
            paginator
            rows={20}
            rowsPerPageOptions={[10, 20, 50]}            
        >
            {columns.map(c => <Column key={c.columnKey ?? c.field} {...c} />)}
        </DataTable>
    </FlexColumnContainer>
} 