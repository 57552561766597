import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, TermsOfServiceAdminState } from "../types";

export const editTermsOfService = actionFactory.createAppAction<number | null | undefined>("editTermsOfService");

export function editTermsOfServiceReducers(reducerFactory: IReducerFactory<TermsOfServiceAdminState>): IReducerFactory<TermsOfServiceAdminState> {
    return reducerFactory
        .forAction(editTermsOfService)
        .addHandler((state, action) => {
            state.editedTermsOfServiceId = action.payload;
        })
        .build();
}