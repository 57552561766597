import { ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { createEditableCurrencyColumnProps } from "../../../../utils/table/editableCurrencyColumn";
import { createFillRemainingSpaceColumnProps } from "../../../../utils/table/fillRemainingSpaceColumn";
import { createSaveIconColumnProps } from "../../../../utils/table/iconColumn";
import { mapColumns } from "../../../../utils/table/mapPropsToColumns";
import { createFilteredTextColumnProps } from "../../../../utils/table/stringColumn";
import { PricingModelGrade } from "../../sharedTypes";
import { upsertPricingModelGrade } from "../store/featureActions/upsertPricingModelGrade";
import {  getPricingModelGradesForGradeKind, getSaveStateMap } from "../store/selectors/pricingModelGrade";

export interface PricingModelGradeTableProps {
    gradeKindId: number;
}

export const PricingModelGradeTable: React.FC<PricingModelGradeTableProps> = ({ gradeKindId }) => {

    const dispatch = useDispatch();
    const pricingModelGrades = useAppSelector(state => getPricingModelGradesForGradeKind(state)(gradeKindId));
    const saveStateMap = useAppSelector(getSaveStateMap);  

    const onSaveUnitPrice = (model: PricingModelGrade, newValue: number | null | undefined): void => {
        dispatch(upsertPricingModelGrade({ ...model, unitPrice: newValue ?? 0 }));
    }

    const getIsSaving = (pricingModelGrade: PricingModelGrade): boolean => {
        return saveStateMap[pricingModelGrade.id]?.isExecuting ?? false;
    }

    const columns: ColumnProps[] = [
        createFilteredTextColumnProps<PricingModelGrade>({ field: "gradeValueName", header: "Quality Grade", style: { minWidth: 200 } }),
        createEditableCurrencyColumnProps<PricingModelGrade>({ field: "unitPrice", header: "Unit Price", style: { minWidth: 240 }, onSubmit: onSaveUnitPrice, allowNoValue: false }),
        createSaveIconColumnProps<PricingModelGrade>({ columnKey: "saveStatus", header: "", getIsSaving: getIsSaving, style: { minWidth: 100 } }),
        createFillRemainingSpaceColumnProps({}),
    ];

    return <DataTable
        size="small"
        dataKey="id"
        value={pricingModelGrades}
        scrollable
        scrollDirection='both'
        scrollHeight="flex"
        paginator
        rows={20}
        rowsPerPageOptions={[10, 20, 50]}
    >
        {mapColumns(columns)}
    </DataTable>
} 