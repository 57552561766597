import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { AutoSubmit } from "../../../../components/formikExtensions/autoSubmit";
import { useAppSelector } from "../../../../store/configureStore";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { marketPlaceProfileValidationSchema } from "../../validations";
import { loadMarketplaceProfile } from "../store/featureActions/loadProfile";
import { updateProfile } from "../store/featureActions/updateProfile";
import { getUserProfile } from "../store/selectors/userProfile";
import { MarketplaceProfile } from "../store/types";
import { UserProfileEditor } from "./userProfileEditor";

export const ManageProfile: React.FC = () => {

    const dispatch = useDispatch();
    const userProfile: Partial<MarketplaceProfile> = useAppSelector(getUserProfile);    

    useMountEffect(() => {
        dispatch(loadMarketplaceProfile());
    });

    const onSubmit = (values: Partial<MarketplaceProfile>, formikHelpers: FormikHelpers<Partial<MarketplaceProfile>>): void | Promise<any> => {
        dispatch(updateProfile(values));
        formikHelpers.setSubmitting(false);
    };

    return <div>

        <div className="p-mt-1 p-p-1">
            <div className="p-fluid p-m-1 p-p-2">
                <Formik<Partial<MarketplaceProfile>>
                    initialValues={userProfile}
                    enableReinitialize={true}
                    validationSchema={marketPlaceProfileValidationSchema}
                    onSubmit={onSubmit}                    
                >
                    <Form autoComplete="off">
                        <AutoSubmit />
                        <UserProfileEditor />
                    </Form>
                </Formik>
            </div>
        </div>
    </div>
}