import { productsAdapter } from "../state";
import { actionFactory, Product } from "../types";
import { updateProductAssignmentMaps } from "./updateInventoryAssignmentMaps";

export const {

    loadProducts,
    loadProductsReducer,
    loadProductsSagaFactory

} = actionFactory.withMappedName("loadProducts")
    .createAsyncAction<void, Product[]>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (_, context) => {
            return context.marketplaceApiClient.productManagement.getProducts();
        },
        onFulfilled: (state, action) => {
            productsAdapter.setAll(state.products, action.payload.result);
        },
        successCallback: (_arg, _result, context) => { context.dispatch(updateProductAssignmentMaps()); },
        debounceInMilliseconds: 50
    });