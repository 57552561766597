import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { ErrorDisplayType } from "../../../../../store/factory/sagaWithProgress/types";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory, ManageProfileState, MarketplaceProfile } from "../types";



export const loadMarketplaceProfile = actionFactory.createAppAction("loadProfile");

const sagaActions = actionFactory.createSagaWithProgressActions<void,MarketplaceProfile>(loadMarketplaceProfile.type);

export function loadMarketplaceProfileReducers(reducerFactory: IReducerFactory<ManageProfileState>): IReducerFactory<ManageProfileState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)     
        .withActionStatus(state => state.loadingState)           
        .onFulfilled((state, action) => {
            state.MarketplaceProfile = action.payload.result;            
        })
        .build();
}

async function performLoad(_: void, context: SagaAppContext): Promise<MarketplaceProfile> {
    return context.marketplaceApiClient.userProfile.getMarketplaceProfile();
}

export function createLoadMarketplaceProfileSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions,
            errorDisplay: ErrorDisplayType.None
        },
        actionPattern: [loadMarketplaceProfile.type],
        debounceInMilliseconds: 250
    });
};