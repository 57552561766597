import { push } from "connected-react-router";
import { QualityTemplateEditorRoute } from "../../../../navigation/routes";
import { AssessmentTemplate, isAssessmentTemplate } from "../../../models/assessmentTemplate";
import { actionFactory } from "../types";

export const {

    addTemplateBegin,
    addTemplateCancel,
    addTemplateComplete,
    addTemplateReducers,
    addTemplateSagaFactory
    
} = actionFactory
    .withMappedName("addTemplate")
    .createModal<Partial<AssessmentTemplate>, void, AssessmentTemplate>({
        selector: state => state.addTemplateState,
        modelFactory: (_arg, _state) => {
            return {                
                id: 0,
                inactive: false,
                name: "",
                publishedDate: null
            }
        },
        onCompleteEdit: (value, context) => {
            if (!isAssessmentTemplate(value)) throw new Error("Attempt to create a template without a valid template instance");
            return context.marketplaceApiClient.quality.upsertAssessmentTemplate(value);
        },
        onFulfilled: (_state, _action) => {
        },
        successCallback: (_arg, result, context) => {
            context.dispatch(push(QualityTemplateEditorRoute.createPath(result.id)));
        }
    });