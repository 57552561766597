import { isValue } from "../../../../../utils/valueHelper";
import { PricingModel } from "../../../sharedTypes";
import { pricingModelAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    upsertPricingModel,
    upsertPricingModelReducer,
    upsertPricingModelSagaFactory

} = actionFactory
    .withMappedName("upsertPricingModel")
    .createAsyncAction<PricingModel | Partial<PricingModel>, PricingModel>({        
        actionStatusSelector: state => state.addPricingModelState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.pricing.upsertPricingModels(arg);
        },
        onFulfilled: (state, action) => {
            pricingModelAdapter.upsertOne(state.pricingModels, action.payload.result);
            if (!isValue(action.payload.arg.id)) {
                state.navigateToNewModelId = action.payload.result.id;
            }
        }
    });