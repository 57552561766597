import { RootState } from "../../../../../store/rootReducer";
import { pricingModelAdapter } from "../state";


const pricingModelSelectors = pricingModelAdapter.getSelectors((state: RootState) => state.pricingDashboard.pricingModels);

export const getPricingModels = pricingModelSelectors.selectAll;

export const getLoadingState = (state: RootState) => state.pricingDashboard.loadingState;

export const getAddPricingModelState = (state: RootState) => state.pricingDashboard.addPricingModelState;

export const getNavigateToNewModelId = (state: RootState) => state.pricingDashboard.navigateToNewModelId;