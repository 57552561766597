import { useFormikContext } from "formik";
import { useEffect } from "react";


export interface ValidationResponderProps {
  handleValidityChanged: (isValid: boolean) => void;
}

export const ValidationResponder: React.FC<ValidationResponderProps> = ({ handleValidityChanged }) => {

  const { isValid } = useFormikContext();

  useEffect(() => {
    handleValidityChanged(isValid);
  }, [isValid, handleValidityChanged]);

  return null;
};
