import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { QualityMetricKind } from "../../models/metricKind";
import { ModalEditState } from "../../../../store/factory/modalEdit/modalEdit";
import { EntityState } from "@reduxjs/toolkit";
import { QualityMetricValue } from "../../models/metricValue";

export const sliceName: string = "quality-metric-kinds";

export const actionFactory = new ActionFactory<QualityMetricKindsState>(sliceName);

export interface QualityMetricKindsState {
    loadingState: ActionStatus;  
    metricKinds: EntityState<QualityMetricKind>;      
    addMetricKindState: ModalEditState<QualityMetricKind>;
    selectedMetricKindId: number | null;
    metricValueSaveState: ActionStatus;
    metricValues: EntityState<QualityMetricValue>;
}