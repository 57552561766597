import { RootState } from "../../../../../store/rootReducer";
import { firstOrException } from "../../../../../utils/arrayHelpers";
import { TermsOfServiceUpsert } from "../types";

export const getTermsOfServiceList = (state: RootState) => state.termsOfServiceAdmin.termsOfService;

export const getTermsOfServiceById = (state: RootState, id: number | null): TermsOfServiceUpsert => {
    if (id === null) {
        return {
            stateAbbreviation: "",
            termsAndConditions: ""
        }
    }
    else {
        const termsOfService = firstOrException(state.termsOfServiceAdmin.termsOfService, terms => terms.id === id);
        return {
            stateAbbreviation: termsOfService.stateAbbreviation,
            termsAndConditions: termsOfService.termsAndConditions
        };
    }

};

export const getEditedTermsOfServiceId = (state: RootState) => state.termsOfServiceAdmin.editedTermsOfServiceId;