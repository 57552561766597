import { isValue } from "../../utils/valueHelper"

export enum QueueStatus {
    Queued = "Queued",
    InProgress = "InProgress",
    Success = "Success",
    Failure = "Failure"
}

export const isQueueStatusComplete = (status: QueueStatus | null | undefined) : boolean => {
    return isValue(status) && (status === QueueStatus.Failure || status === QueueStatus.Success);
}