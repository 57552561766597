import clsx from 'clsx';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from '../../../../store/configureStore';
import { cancelEdit } from '../store/featureActions/cancelEdit';
import { completeEdit } from '../store/featureActions/completeEdit';

import { PasswordField } from '../../../../components/formik/passwordField';
import { getEditEntityDialogVisible, getEditEntityValue } from '../store/selectors/editEntity';
import { CorporateEntity } from '../store/types';
import { corporateEntityValidationSchema } from '../validations';

export const NewCorporateEntityDialog: React.FC = () => {

    const dispatch = useDispatch();
    const editEntityDialogVisible = useAppSelector(getEditEntityDialogVisible);
    const editEntityvalue = useAppSelector(getEditEntityValue);

    const onCancel = () => {
        dispatch(cancelEdit());
    };


    const onSubmit = (values: Partial<CorporateEntity>, formikHelpers: FormikHelpers<Partial<CorporateEntity>>): void | Promise<any> => {
        dispatch(completeEdit(values));
        formikHelpers.setSubmitting(false);
    };

    const initialValues: Partial<CorporateEntity> = { ...editEntityvalue };

    return editEntityvalue === null
        ? <div></div>
        : <Dialog
            header="Edit Corporate Entity"
            closable={false}
            visible={editEntityDialogVisible}
            style={{ width: '50vw' }}
            onHide={onCancel}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={corporateEntityValidationSchema}
                onSubmit={onSubmit}
            >
                {
                    (actions) => <Form autoComplete="off">

                        <div className="p-fluid">

                            <div className="p-field">
                                <label>Name</label>
                                <Field
                                    type="text"
                                    name="name"
                                    autoComplete="new-password"
                                    //disabled={isRegistering}
                                    className={clsx({ "p-invalid": !!actions.errors.name })}
                                    as={InputText}>
                                </Field>
                                <small>{actions.errors.name}</small>
                            </div>

                            <PasswordField<CorporateEntity>
                                fieldName='metrcApiKey'
                            />
                            {/* <div className="p-field">
                                <label>Api Key</label>
                                <Field
                                    type="text"
                                    name="metrcApiKey"
                                    feedback={false}
                                    autoComplete="new-password"
                                    className={clsx({ "p-invalid": !!actions.errors.metrcApiKey })}
                                    as={Password}
                                >
                                </Field>
                                <small>{actions.errors.metrcApiKey}</small>
                            </div> */}

                            <div className="p-field">
                                <label>Inactive</label>
                                <Field
                                    type="text"
                                    name="inactive"
                                    className={clsx({ "p-invalid": !!actions.errors.inactive })}
                                >
                                    {
                                        (props: FieldProps<Partial<CorporateEntity>>) =>
                                            <Checkbox
                                                name="inactive"
                                                style={{ display: "inline" }}
                                                checked={actions.values.inactive}
                                                onChange={(event) => {
                                                    actions.setFieldValue(props.field.name, event.target.checked);
                                                }}
                                            />
                                    }
                                </Field>
                                <small>{actions.errors.inactive}</small>
                            </div>

                        </div>

                        <div >
                            <Button className="p-mr-1" type="submit" label="Save" icon="pi pi-check" />
                            <Button className="p-ml-1" label="Cancel" icon="pi pi-times" onClick={onCancel} />
                        </div>

                    </Form>
                }

            </Formik>
        </Dialog>

}