import { DataTableViewState, TableState } from "../types";

export const serializeTableState = (tableState: TableState) => {
    return JSON.stringify(tableState);
}

export const deserializeTableState = (tableState: string) : TableState => {
    return JSON.parse(tableState);
}

export const deserializeDataTableViewState = (tableState: string) : DataTableViewState => {
    return JSON.parse(tableState);
}



