import { createEntityAdapter } from '@reduxjs/toolkit';
import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { TenantProvisioning } from './types';
import { Company } from '../../../company/profile/store/types';
import { CreateInitialModalEditState } from '../../../../store/factory/modalEdit/modalEdit';

export const companyAdapter = createEntityAdapter<Company>();

export const createInitialState = (): TenantProvisioning => {
    return {        
        loadingState: CreateActionStatusInitialState(),      
        data: companyAdapter.getInitialState() ,
        addCompanyState: CreateInitialModalEditState()
    };
};

export const initialState: TenantProvisioning = createInitialState();