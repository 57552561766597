import { TransformResult } from "../../../../utils/promiseHelpers";
import ApiClient from "../../../apiCommunication/apiClient";
import { createUtcDateSerializationFunction } from "../../../apiCommunication/serialization";
import { TermsOfService } from "../store/types";

const deserializeDates = createUtcDateSerializationFunction<TermsOfService>( { properties: ["publishedUtc", "inactivatedUtc"] });

export class TermsOfServiceAdmin {

    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    getTermsOfService = (): Promise<TermsOfService[]> => {
        return this
            .apiClient
            .executeGet<TermsOfService[]>(`termsofservice/admin`)
            .then(results => {
                results.forEach(result => TransformResult(deserializeDates, result));
                return results;
            });
    };

    upsertTermsOfService = (file: Blob | string | File, state: string): Promise<TermsOfService> => {
        const formData = new FormData();
        formData.append("file", file);      

        return this
            .apiClient
            .executeFormDataPostWithResult<TermsOfService>(`termsofservice/admin/${state}`, formData)
            .then(deserializeDates);
    }

    inactivateTermsOfService = (id: number): Promise<TermsOfService> => {
        return this
            .apiClient
            .executePostWithResult<TermsOfService>(`TermsOfService/admin/${id}/inactivate`)
            .then(deserializeDates);
    }

    publishTermsOfService = (id: number): Promise<TermsOfService> => {
        return this
            .apiClient
            .executePostWithResult<TermsOfService>(`TermsOfService/admin/${id}/publish`)
            .then(deserializeDates);
    }

}