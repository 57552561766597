import { PayloadAction } from '@reduxjs/toolkit';
import { ActionPattern, call, debounce, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import { beginLogin } from "../../../features/authentication/store/featureActions/beginLoginActions";
import { notAuthorizedEvent } from "../../../features/authorization/notAuthorized/store/featureActions/notAuthorizedEventAction";
import { enqueueError } from "../../../features/dialogs/error/store/featureActions/enqueueErrorAction";
import { getErrorResult } from '../../../features/errorHandling/errorMessageHelper';
import { isResponse, isUauthorizedApiResponse } from '../../../features/errorHandling/types';
import { isValue } from '../../../utils/valueHelper';
import { appHistory } from '../../historyInstance';
import { SagaAppContext } from '../../rootSaga';
import { ErrorDisplayType, NotAuthorizedAction, RejectedPayload, SagaActions } from './types';

export interface SagaOptions<TArg, TResult> {
    appContext: SagaAppContext,
    notificationActions: SagaActions<TArg, TResult>,
    errorTitle?: string,
    errorDisplay?: ErrorDisplayType,
    logMetric?: boolean | ((action: PayloadAction<TArg>, result: TResult) => boolean),
    getMetricValue?: (action: PayloadAction<TArg>) => number,
    getMetricDetail?: (action: PayloadAction<TArg>, result: TResult) => string | undefined,
    action: (arg: TArg, context: SagaAppContext) => Promise<TResult>,
    successCallback?: (arg: TArg, result: TResult, context: SagaAppContext) => void,
    rejectedCallback?: (arg: TArg, result: RejectedPayload<TArg>, context: SagaAppContext) => void,
    notAuthorizedAction?: NotAuthorizedAction,
    predicate?: (arg: TArg, context: SagaAppContext) => boolean
};

type CallFnType<TArg, TResult> = (arg: SagaOptions<TArg, TResult>, action: PayloadAction<TArg>) => any;

function* executeWithProgress<TArg, TResult>(payload: SagaOptions<TArg, TResult>, action: PayloadAction<TArg>): Generator<StrictEffect, void, TResult> {
    try {
        if (isValue(payload.predicate) && !payload.predicate(action.payload, payload.appContext)) return;

        yield put(payload.notificationActions.pending({ arg: action.payload }));

        const result = yield call(payload.action, action.payload, payload.appContext);

        yield put(payload.notificationActions.fulfilled({ arg: action.payload, result: result }));

        if (isValue(payload.successCallback)) {
            yield call(payload.successCallback, action.payload, result, payload.appContext)
        }
    }
    catch (error) {
        const errorResult = getErrorResult(error);
        const errorHandling = payload.errorDisplay ?? ErrorDisplayType.Dialog;

        yield put(payload.notificationActions.rejected({ arg: action.payload, errorResult: errorResult }));

        if (isValue(payload.rejectedCallback)) {
            yield call(payload.rejectedCallback, action.payload, { arg: action.payload, errorResult: errorResult }, payload.appContext)
        }

        const notAuthorizedAction: NotAuthorizedAction = payload.notAuthorizedAction ?? NotAuthorizedAction.NotAuthorizedEvent;

        if (isUauthorizedApiResponse(error)) {
            if (notAuthorizedAction === NotAuthorizedAction.BeginLogin && payload.appContext.getState().authentication.CurrentUser === null) {
                yield put(beginLogin());
            }
            else if (notAuthorizedAction === NotAuthorizedAction.NotAuthorizedEvent) {

                yield (put(notAuthorizedEvent({ route: appHistory.location.pathname })))
            }
            else {
                yield (put(notAuthorizedEvent({ route: appHistory.location.pathname })))
            }
        }

        if (errorHandling !== ErrorDisplayType.None) {
            if (isResponse(error) && error.status === 401) return; // auth failures are handled separately

            yield put(enqueueError({ error: errorResult, title: payload.errorTitle ?? "Error", errorDisplayType: errorHandling }));
        }
    }
};

export function createExecuteWithProgressSaga<TArg = void, TResult = void>({ options, actionPattern, debounceInMilliseconds }: {
    options: SagaOptions<TArg, TResult>,
    actionPattern: ActionPattern<PayloadAction<TArg>>,
    debounceInMilliseconds?: number
}) {
    return function* watchRequests(): Generator<StrictEffect, void, void> {
        if (isValue(debounceInMilliseconds)) {
            yield debounce<PayloadAction<TArg>, CallFnType<TArg, TResult>>(debounceInMilliseconds, actionPattern, executeWithProgress, options);
        }
        else {
            yield takeEvery<PayloadAction<TArg>, CallFnType<TArg, TResult>>(actionPattern, executeWithProgress, options);
        }
    }
};