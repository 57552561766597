import { createAction } from "@reduxjs/toolkit";
import { IReducerFactory } from "../../../../store/factory/interfaces";
import { SagaAppContext } from "../../../../store/rootSaga";
import { createExecuteWithProgressSaga } from "../../../../store/factory/sagaWithProgress/executeWithProgress";
import { actionFactory, AuthenticationAction, UserAuthState } from "../types";

const completeLogoutActionName = actionFactory.createActionName("completeLogout");
export const completeLogout = createAction(completeLogoutActionName);

const sagaActions = actionFactory.createSagaWithProgressActions<void, void>(completeLogoutActionName);
export function completeLogoutReducers(reducerFactory: IReducerFactory<UserAuthState>): IReducerFactory<UserAuthState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)        
        .onPending((state, _) => {
            state.AuthenticationAction = AuthenticationAction.LoggingOut;
        })
        .onRejected((state, action) => {
            state.AuthenticationAction = null;
            state.ErrorMessage = action.payload.errorResult;
        })
        .onFulfilled((state, _) => {

            state.AuthenticationAction = null;
            state.ErrorMessage = null;
            state.CurrentUser = null;                
        })
        .build();
}

async function onCompleteLogout(_: void, context: SagaAppContext): Promise<void> {    
    return context
            .authService
            .completeLogout();
}

export function createCompleteLogoutSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga<void, void>({
        options: {
            action: onCompleteLogout,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: completeLogoutActionName,
        debounceInMilliseconds: 250
    });
};