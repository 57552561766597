import { viewSettingsAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    resetView,
    resetViewReducer

} = actionFactory
    .withMappedName("resetView")
    .createAction<{ viewId: string }>((state, action) => {        
        viewSettingsAdapter.removeOne(state.viewSettings, action.payload.viewId);
    });