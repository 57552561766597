import { QualityMetricValue } from "../../../models/metricValue";
import { metricValueAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    deleteQualityMetricValue,
    deleteQualityMetricValueReducer,
    deleteQualityMetricValueSagaFactory

} = actionFactory
    .withMappedName("deleteQualityMetricValue")
    .createAsyncAction<QualityMetricValue, QualityMetricValue[]>({
        actionStatusSelector: state => state.metricValueSaveState,
        asyncCall: (arg, context) => {            
            return context.marketplaceApiClient.quality.deleteQualityMetricValue(arg.id);
        },
        onFulfilled: (state, action) => {
            metricValueAdapter.removeOne(state.metricValues, action.payload.arg.id);
            metricValueAdapter.upsertMany(state.metricValues, action.payload.result);
        }
    });