import { IntegerId } from "../../../../../commonModels/integerId";
import { inventoryAdapter } from "../../state";
import { actionFactory } from "../../types";

export const {

    deleteInventory,
    deleteInventoryReducer,
    deleteInventorySagaFactory

} = actionFactory
    .withMappedName("deleteInventory")
    .createAsyncAction<IntegerId, Response>({
        actionStatusSelector: state => state.inventoryDeleteStatus,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.inventoryManagement.deleteInventory(arg.id);
        },
        onFulfilled: (state, action) => {
            inventoryAdapter.removeOne(state.inventory, action.payload.arg.id);            
        }
    });