import clsx from 'clsx';
import { Field, FieldProps, FormikContextType, useFormikContext } from "formik";
import { InputNumber, InputNumberChangeParams } from 'primereact/inputnumber';
import { CSSProperties } from 'react';
import { getNumberOrNullValue, NumberOrNullKeyOf, PropertiesOfType } from '../../utils/typeHelpers';
import { isValue } from '../../utils/valueHelper';

export interface NumberFieldProps<T extends PropertiesOfType<T, number | null | undefined>> {

    defaultLabel: string;
    fieldName: keyof T & string & NumberOrNullKeyOf<T>;
    containerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    placeHolder?: string;
    disabled?: boolean;
    min?: number;
    max?: number;
    step?: number;
    submitOnBlur?: boolean;
    isCurrency?: boolean;
    minFractionDigits?: number;
    maxFractionDigits?: number;
    mode?: string;
    suffix?: string;
    locale?: string;
    isPercent?: boolean;
}


export const NumberField = <T extends PropertiesOfType<T, number | null | undefined>,>({

    defaultLabel,
    fieldName,
    labelStyle,
    containerStyle,
    inputStyle,
    placeHolder,
    disabled,
    min,
    max,
    step = 1,
    submitOnBlur = false,
    isCurrency = false,
    minFractionDigits,
    maxFractionDigits,
    mode,
    suffix,
    locale = 'en-US',
    isPercent = false
}: NumberFieldProps<T>) => {

    const formikProps: FormikContextType<T> = useFormikContext<T>();
    const labelText = defaultLabel;


    const rawValue: number | undefined = getNumberOrNullValue(formikProps.values, fieldName) ?? undefined;

    const transformedValue =  isValue(rawValue) && isPercent
        ? rawValue * 100
        : rawValue;

    return <div style={containerStyle}>

        <label style={labelStyle} htmlFor={fieldName} >{labelText}</label>
        <Field
            name={fieldName}
        >
            {
                (props: FieldProps<T>) =>
                    <InputNumber
                        locale={locale}
                        suffix={suffix}
                        mode={isCurrency ? "currency" : mode}
                        currency={isCurrency ? "USD" : undefined}
                        placeholder={placeHolder}
                        style={inputStyle}
                        disabled={disabled}
                        minFractionDigits={minFractionDigits}
                        maxFractionDigits={maxFractionDigits}
                        step={step}
                        min={min}
                        max={max}
                        id={fieldName}
                        name={fieldName}
                        className={clsx({ "p-invalid": !!formikProps.errors[fieldName] })}
                        value={transformedValue}
                        onChange={(e: InputNumberChangeParams) => {
                            if (e.value === null || isNaN(e.value)) {
                                formikProps.setFieldValue(props.field.name, null);
                            }
                            else {
                                const value = isPercent
                                    ? e.value / 100
                                    : e.value;
                                formikProps.setFieldValue(props.field.name, value);
                            }
                        }}
                        onBlur={(_event) => {
                            if (submitOnBlur && formikProps.dirty) {
                                formikProps.submitForm();
                            }
                        }}
                    />
            }
        </Field>
        <div>
            <small>{formikProps.errors[fieldName]}</small>
        </div>
    </div>
}
