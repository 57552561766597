import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ColumnProps } from "primereact/column";
import { confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method
import { DataTable } from "primereact/datatable";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { mapColumns } from "../../../../utils/table/mapPropsToColumns";
import { createMultiActionColumnProps } from "../../../../utils/table/multiActionColumn";
import { createTextColumnProps } from "../../../../utils/table/stringColumn";
import { TypedCommandMenuItem } from "../../../../utils/table/typedMenuItem";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { menuEditorRoute } from "../../../navigation/routes";
import { Menu } from "../../editor/store/types";
import { addMenu } from "../store/featureActions/addMenu";
import { deleteMenu } from "../store/featureActions/deleteMenu";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { getAddMenuState, getLoadingState } from "../store/selectors/actionStatus";
import { getMenus } from "../store/selectors/menu";
import { resetState } from "../store/slice";

export const MenuDashboard: React.FC = () => {

    const dispatch = useDispatch();
    const menus = useAppSelector(getMenus);
    const isLoading = useAppSelector(getLoadingState);
    const addMenuState = useAppSelector(getAddMenuState);

    const history = useHistory();

    useMountEffect(() => {
        dispatch(loadSliceData());

        return () => {
            dispatch(resetState());
        }
    });

    const onAddMenu = () => {
        dispatch(addMenu());
    }

    const onEditMenu = (target: Menu) => {
        history.push(menuEditorRoute.createPath(target.id));
    }

    const onDeleteMenu = (target: Menu) => {
        confirmDialog({
            message: 'Are you sure you want to delete this menu?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Delete",
            rejectLabel: "Cancel",
            accept: () => { dispatch(deleteMenu(target.id)) },
            reject: () => { }
        });
    }

    const menuItems: TypedCommandMenuItem<Menu>[] = [
        {
            label: "Delete",
            command: onDeleteMenu
        }
    ];
 
    const columns: ColumnProps[] = [
        createMultiActionColumnProps<Menu>({
            columnKey: "action-column",
            header: "Edit",
            actionName: "Edit",
            actionHandler: onEditMenu,
            style: { width: "150px" },
            menuItems: menuItems
        }),
        createTextColumnProps<Menu>({ field: "name", header: "Name", filter: menus.length > 0, filterMatchMode: "contains" })
    ];

    return <Card className="full-height-content-panel" title="Manage Menus" >

        <div className="p-d-flex p-mb-2">
            <div className="p-mr-2">
                <Button label="Add Menu" icon={PrimeIcons.PLUS} onClick={onAddMenu} loading={addMenuState.isExecuting} />
            </div>
        </div>
        <OverlayWaitDisplay isWaiting={isLoading.isExecuting}>
            <DataTable
                dataKey="id"
                value={menus}
                scrollable
                scrollDirection="both"
                editMode="cell"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 50]}
            >
                {mapColumns(columns)}
            </DataTable>
        </OverlayWaitDisplay>

    </Card>

}