import { Load2Result } from "../../../../../utils/promiseHelpers";
import { isValue } from "../../../../../utils/valueHelper";
import { AssessmentTemplate } from "../../../models/assessmentTemplate";
import { TemplateConstraint } from "../../../models/templateConstraint";
import { getInitialExpansionStateForGradeKind } from "../logic/expandedTreeNodes";
import { expandedTreeNodeAdapter, templateConstraintAdapter } from "../state";
import { actionFactory, TreeNodeExpandedKeys } from "../types";

export const {

    changeGradeKindBegin,
    changeGradeKindCancel,
    changeGradeKindComplete,
    changeGradeKindReducers,
    changeGradeKindSagaFactory    

} = actionFactory
    .withMappedName("changeGradeKind")
    .createModal<AssessmentTemplate, void, Load2Result<AssessmentTemplate, TemplateConstraint[]>>({
        selector: state => state.changeGradeKindState,
        modelFactory: (_arg, state) => {

            const template = state.template;
            if (!isValue(template)) throw new Error("Cannot change grade kind without a valid template");

            return template;
        },
        onCompleteEdit: async (value, context) => {            
            const template = await context.marketplaceApiClient.quality.upsertAssessmentTemplate(value);
            const constraints = await context.marketplaceApiClient.quality.getTemplateConstraints(value.id);

            return Promise.resolve({ result1: template, result2: constraints });
        },
        onFulfilled: (state, action) => {
            state.template = action.result.result1;
            templateConstraintAdapter.setAll(state.templateConstraints, action.result.result2);

            const expandedKeys: TreeNodeExpandedKeys[] = getInitialExpansionStateForGradeKind(
                action.result.result2,
                []
            );
                
            expandedTreeNodeAdapter.setAll(state.constraintTreeExpandedKeys, expandedKeys)
        }
    });