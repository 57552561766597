import { EntityState } from "@reduxjs/toolkit";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { ImageDto } from "../../../commonModels/imageTypes";
import { Menu } from "../../../menu/editor/store/types";
import { CompanyProfile } from "../../profile/store/types";
import { EntityImage } from "../../../commonModels/entityImage";

export const sliceName: string = "company-mp-view";

export const actionFactory = new ActionFactory<CompanyMarketplaceViewState>(sliceName);

export interface CompanyMarketplaceViewState {
    loadingState: ActionStatus;
    companyProfile: CompanyProfile | null;
    menus: EntityState<Menu>;
    menuImages: EntityState<EntityImage>;
    vendorLogoLoadingState: ActionStatus;   
    vendorLogo: ImageDto | null;
    vendorBannerLoadingState: ActionStatus;   
    vendorBanner: ImageDto | null;
}