import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, MessageDialogState, MessageInfo } from "../types";

export const showMessageInDialog = actionFactory.createAppAction<MessageInfo>("showMessageInDialog");

export function showMessageInDialogReducers(reducerFactory: IReducerFactory<MessageDialogState>): IReducerFactory<MessageDialogState> {
    return reducerFactory
        .forAction(showMessageInDialog)
        .addHandler((state, action) => {            
            state.messageInfo = action.payload;
        })
        .build();
}