import { createEntityAdapter } from '@reduxjs/toolkit';
import { CreateInitialModalEditState } from '../../../../store/factory/modalEdit/modalEdit';
import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { Inventory } from '../../../inventorySetup/inventoryManagement/store/types';
import { SalesOrderItem } from '../../common/types';
import { OrderEditorState } from './types';

export const salesOrderItemAdapter = createEntityAdapter<SalesOrderItem>();
export const orderInventoryAdapter = createEntityAdapter<Inventory>();

export const createInitialState = (): OrderEditorState => {
    return {
        loadingState: CreateActionStatusInitialState(),
        customer: null,
        salesOrder: null,
        salesOrderItems: salesOrderItemAdapter.getInitialState(),
        orderInventory: orderInventoryAdapter.getInitialState(),
        orderItemEditState: CreateInitialModalEditState(),
        products: [],
        productMap: {},
        inventorySearchResults: [],
        inventorySearchState: CreateActionStatusInitialState(),
        orderedQuantities: [],
        orderedQuantitiesLoadState: CreateActionStatusInitialState(),
        selectedInventory: null
    };
};

export const initialState: OrderEditorState = createInitialState();