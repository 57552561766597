import { RootState } from "../../../../../store/rootReducer";
import { SortDirection, SortField } from "../../../common/types";
import { Menu } from "../types";

export const getMenu = (state: RootState) => state.menuAdmin.menu;

export const getMenuForEditing = (state: RootState) : Menu => state.menuAdmin.menu ?? {
    companyId: 0,
    id: 0,
    inactive: false,    
    name: "",
    urlName: "",
    tenantId: "",
    primaryColor: null,
    secondaryColor: null,
    primaryFont: null,
    secondaryFont: null,
    defaultSortDirection: SortDirection.Ascending,
    defaultSortField: SortField.Name
};