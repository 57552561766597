import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";

export const getCategoryForProductAssignment = (state: RootState) => state.categoryManagement.categoryProductAssignment.category;

export const getProductAssignmentLoadState = (state: RootState) => state.categoryManagement.categoryProductAssignment.loadingState;

export const getSelectedAvailableProducts = (state: RootState) => state.categoryManagement.categoryProductAssignment.selectedAvailableProducts;

export const getSelectedAssignedProducts = (state: RootState) => state.categoryManagement.categoryProductAssignment.selectedAssignedProducts;

export const getAvailableProducts = createSelector(
    [
        (state: RootState) => state.categoryManagement.categoryProductAssignment.allProducts,
        (state: RootState) => state.categoryManagement.categoryProductAssignment.assignedProductMap
    ],
    (allProducts, assignedProductMap) => {
        return allProducts.filter(product => assignedProductMap[product.id] === undefined);
    }
) 

export const getAssignedProducts = createSelector(
    [
        (state: RootState) => state.categoryManagement.categoryProductAssignment.allProducts,
        (state: RootState) => state.categoryManagement.categoryProductAssignment.assignedProductMap
    ],
    (allProducts, assignedProductMap) => {
        return allProducts.filter(product => assignedProductMap[product.id] !== undefined);
    }
) 