import clsx from "clsx";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../../store/configureStore";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { getUserIsMarketplaceUser } from "../../../authentication/store/selectors/userRoles";
import { manageAlertsRoute } from "../../../navigation/routes";
import { smallDeviceQuery } from "../../../responsiveconfiguration";
import { loadAlerts } from "../store/featureActions/loadAlerts";
import { getActiveAlertCount } from "../store/selectors/alerts";

export const AlertsBar: React.FC = () => {

    const activeAlertCount = useAppSelector(getActiveAlertCount);

    const dispatch = useDispatch();
    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    const isMarketplaceUser = useAppSelector(getUserIsMarketplaceUser);
    const history = useHistory();

    useMountEffect(() => {
        if (isMarketplaceUser) {
            dispatch(loadAlerts());
        }
    });

    const onViewAlerts = () => {
        history.push(manageAlertsRoute.path);
    };

    return isMarketplaceUser
        ? <Button
            onClick={onViewAlerts}
            type="button"
            label={isSmallDevice ? undefined : "Alerts"}
            icon="pi pi-bell"
            className={clsx("p-button-outlined alerts-bar", {
                "p-button-rounded": isSmallDevice,
                "p-button-info": !isSmallDevice || activeAlertCount === 0,
                "p-button-danger": isSmallDevice && activeAlertCount > 0
            })}
            badge={isSmallDevice
                ? undefined
                : activeAlertCount === 0
                    ? undefined
                    : activeAlertCount.toString()}
            badgeClassName={activeAlertCount > 0 ? "p-badge-danger" : "p-badge-info"}
        />
        : null

}