import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { getTemplateMetricSaveState } from "../store/selectors/loadingState";
import { TemplateMetricCommandBar } from "./templateMetricCommandBar";
import { TemplateMetricTable } from "./templateMetricTable";

export const TemplateMetricsEditor: React.FC = () => {

    const saveState = useAppSelector(getTemplateMetricSaveState);

    return <OverlayWaitDisplay
        isWaiting={saveState.isExecuting}
    >
        <FlexColumnContainer className="template-metrics-editor">

            <TemplateMetricCommandBar />

            <TemplateMetricTable />

        </FlexColumnContainer>
        
    </OverlayWaitDisplay>
}