import { ErrorDisplayType } from "../../../../../../store/factory/sagaWithProgress/types";
import { ImageKind, ImageDto } from "../../../../../commonModels/imageTypes";
import { actionFactory } from "../../types";

export const {

    loadBanner,
    loadBannerReducer,
    loadBannerSagaFactory

} = actionFactory
    .withMappedName("loadBanner")
    .createAsyncAction<{ menuId: number }, ImageDto>({
        actionStatusSelector: state => state.bannerState.loadState,
        asyncCall: (arg, context) => {            
            return context.marketplaceApiClient.menuAdmin.getImage(arg.menuId, ImageKind.Banner)
        },
        onFulfilled: (state, action) => {
            state.bannerState.currentImage = action.payload.result;   
        },
        errorDisplay: ErrorDisplayType.None
    });