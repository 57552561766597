import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from 'primereact/toolbar';
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { InfoPanel } from "../../../../components/infoPanel";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { createFilteredBooleanColumnProps } from "../../../../utils/table/booleanColumn";
import { createFillRemainingSpaceColumnProps } from "../../../../utils/table/fillRemainingSpaceColumn";
import { mapColumns } from "../../../../utils/table/mapPropsToColumns";
import { createMultiActionColumnProps } from "../../../../utils/table/multiActionColumn";
import { createTextColumnProps } from "../../../../utils/table/stringColumn";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { beginEdit } from "../store/featureActions/beginEdit";
import { deleteCorporateEntity } from "../store/featureActions/deleteCorporateEntity";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { getCorporateEntities } from "../store/selectors/corporateEntities";
import { getLoadingState } from "../store/selectors/loadingState";
import { resetState } from "../store/slice";
import { CorporateEntity } from "../store/types";
import './index.css';
import { NewCorporateEntityDialog } from "./newCorporateEntityDialog";

export const CorporateEntityManagement: React.FC = () => {

    const dispatch = useDispatch();
    const corporateEntities = useAppSelector(getCorporateEntities);
    
    const loadingState = useAppSelector(getLoadingState);

    const tableRef = useRef<DataTable>(null);

    useMountEffect(() => {
        dispatch(loadSliceData());

        return () => {
            dispatch(resetState());
        }
    });
    
    const onEditEntity = (entity: CorporateEntity) => {
        dispatch(beginEdit(entity));
    };

    const onNewEntity = () => {
        dispatch(beginEdit({
            name: "",
            metrcApiKey: "",
            inactive: false
        }));
    }

    const leftToolbarContents = (
        <React.Fragment>
            <Button label="New" icon="pi pi-plus" className="p-mr-2" onClick={onNewEntity} />
        </React.Fragment>
    );


    const onDelete = (value: CorporateEntity) => {
        dispatch(deleteCorporateEntity(value.id));
    }
 
    const columns: ColumnProps[] = [
        createMultiActionColumnProps<CorporateEntity>({
            columnKey: "action-column",
            icon: PrimeIcons.PENCIL,
            actionName: "Edit",
            header: "Actions",
            className: "left-aligned-action-column",
            actionHandler: onEditEntity,
            style: { width: "160px" },
            menuClassName: "left-aligned-action-column",
            menuItems: [
                {
                    icon: "pi pi-trash",
                    label: 'Delete',
                    command: onDelete,
                }
            ]
        }),
        createTextColumnProps<CorporateEntity>({ field: "name", header: "Name", filter: true, style: { width: "400px" }, }),
        createFilteredBooleanColumnProps<CorporateEntity>({
            field: "inactive",
            header: "Inactive",
            falseLabel: "Active",
            trueLabel: "Inactive",
            style: { width: "140px" },
            disabledFn: () => true
        }),
        createFillRemainingSpaceColumnProps({})
    ];

    return <OverlayWaitDisplay
        isWaiting={loadingState.isExecuting}
    >
        <div>
            <NewCorporateEntityDialog />
            <Card className="main-content-panel" title="Corporate Entity Management">
                <div className="corporate-entity-dashboard">
                    <InfoPanel textContent="Corporate entities represent the organizational units that own a METRC api key. The api key is required to access and sync your METRC inventory." />

                    <Toolbar className="p-mb-2" left={leftToolbarContents} />
                    <DataTable value={corporateEntities} ref={tableRef} className="p-datatable-sm">
                        {mapColumns(columns)}
                    </DataTable>
                </div>
            </Card>

        </div>
    </OverlayWaitDisplay>
}