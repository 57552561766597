import { EntityVideo } from "../../../../../commonModels/entityVideo";
import { productVideoAdapter } from "../../state";
import { actionFactory } from "../../types";

export const {

    upsertProductVideo,
    upsertProductVideoReducer,
    upsertProductVideoSagaFactory

} = actionFactory
    .withMappedName("upsertProductVideo")
    .createAsyncAction<EntityVideo, EntityVideo>({
        actionStatusSelector: state => state.videoSaveState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.productManagement.upsertProductVideo(arg);
        },
        onFulfilled: (state, action) => {
            productVideoAdapter.upsertOne(state.editVideos, action.payload.result);
        }
    });