import clsx from 'clsx';
import { Field, FieldProps, FormikContextType, useFormikContext } from "formik";
import { DateTime } from 'luxon';
import { CSSProperties } from 'react';
import { DateTimeOrNullKeyOf, getDateTimeOrNullValue, PropertiesOfType } from '../../utils/typeHelpers';
import { Calendar, CalendarChangeParams } from 'primereact/calendar';
import { isValue } from '../../utils/valueHelper';
import { FormikEditorClassStyles } from './classNameAttributes';

export interface DateFieldProps<T extends PropertiesOfType<T, DateTime | null | undefined>> extends FormikEditorClassStyles {

    defaultLabel: string;
    fieldName: keyof T & string & DateTimeOrNullKeyOf<T>;
    containerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    disabled?: boolean;
    submitOnBlur?: boolean;
    showButtonBar?: boolean;
}


export const DateField = <T extends PropertiesOfType<T, DateTime | null | undefined>,>({

    defaultLabel,
    fieldName,
    labelStyle,
    containerStyle,
    inputStyle,
    disabled,
    submitOnBlur = false,
    containerClassName,
    inputClassName,
    labelClassName,
    showButtonBar = false
}: DateFieldProps<T>) => {

    const formikProps: FormikContextType<T> = useFormikContext<T>();
    const labelText = defaultLabel;


    return <div style={containerStyle} className={containerClassName}>

        <label className={labelClassName} style={labelStyle} htmlFor={fieldName} >{labelText}</label>
        <Field
            name={fieldName}
        >
            {
                (props: FieldProps<T>) => {
                    const currentValue = getDateTimeOrNullValue(formikProps.values, fieldName);
                    const dateValue: Date | undefined = isValue(currentValue) && DateTime.isDateTime(currentValue)
                        ? currentValue.toJSDate()
                        : undefined;

                    return <Calendar
                        style={inputStyle}
                        disabled={disabled}
                        name={fieldName}                        
                        className={clsx(inputClassName, { "p-invalid": !!formikProps.errors[fieldName] })}
                        onChange={(e: CalendarChangeParams) => {
                            const value = e.value;
                            if (!isValue(value)) {
                                formikProps.setFieldValue(props.field.name, value);
                            }
                            else if(Array.isArray(value)) {
                                throw new Error("DateField expects only a single date");
                            }
                            else {
                                formikProps.setFieldValue(props.field.name, DateTime.fromJSDate(value));
                            }
                        }}
                        value={dateValue}
                        onBlur={(_event) => {
                            if (submitOnBlur && formikProps.dirty) {
                                formikProps.submitForm();
                            }
                        }}
                        showButtonBar={showButtonBar}
                    />
                }
            }
        </Field>
        <div>
            <small>{formikProps.errors[fieldName]}</small>
        </div>
    </div>
}
