import { EntityState } from "@reduxjs/toolkit";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ModalEditState } from "../../../../store/factory/modalEdit/modalEdit";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { Company } from "../../../company/profile/store/types";

export const sliceName: string = "provisioning";

export const actionFactory = new ActionFactory<TenantProvisioning>(sliceName);

export interface TenantProvisioning {
    loadingState: ActionStatus;  
    data: EntityState<Company>;      
    addCompanyState: ModalEditState<Company>;
}