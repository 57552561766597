import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { InitialSetupWorkflowState } from './types';

export const createInitialState = (): InitialSetupWorkflowState => {
    return {
        userSetupRequiredState: {
            loadingState: CreateActionStatusInitialState(),
            userSetupInfo: null
        },
        companyProfileIsValid: false,
        userProfileIsValid: false
    };
};

export const initialState: InitialSetupWorkflowState = createInitialState();