import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { createEmailColumnProps } from "../../../../utils/table/emailColumn";
import { createFillRemainingSpaceColumnProps } from "../../../../utils/table/fillRemainingSpaceColumn";
import { createMultiActionColumnProps } from "../../../../utils/table/multiActionColumn";
import { createFilteredTextColumnProps } from "../../../../utils/table/stringColumn";
import { TypedMenuItem } from "../../../../utils/table/typedMenuItem";
import { smallDeviceQuery, smallDeviceTableBreakpoint } from "../../../responsiveconfiguration";
import { Contact } from "../../common/types";
import { deleteCustomerContact } from "../store/featureActions/deleteCustomerContact";
import { editContactBegin } from "../store/featureActions/editContact";
import { getCustomerContacts } from "../store/selectors/contact";

export const CustomerContacts: React.FC = () => {

    const contacts = useAppSelector(getCustomerContacts);
    const dispatch = useDispatch();
    const isSmallDevice = useMediaQuery(smallDeviceQuery);

    const onEdit = (value: Contact) => {
        dispatch(editContactBegin(value));
    }

    const onNewContactClick = () => {
        dispatch(editContactBegin({}));
    }

    const additionalActions: TypedMenuItem<Contact>[] = [
        {
            label: "Delete",
            icon: PrimeIcons.TIMES,
            command: (contact) => dispatch(deleteCustomerContact(contact)),
            promptBeforeInvoke: "Are you sure you want to delete this contact?",
            promptAcceptLabel: "Delete Contact",
            promptRejectLabel: "Keep Contact"
        }
    ];

    const columns: ColumnProps[] = [
        createMultiActionColumnProps<Contact>({
            columnKey: "action-column",
            actionName: "Edit",
            actionHandler: onEdit,
            header: "Actions",            
            style: { width: 150 },
            sortable: false,
            menuItems: additionalActions
        }),
        createFilteredTextColumnProps<Contact>({ field: "name", header: "Name", style: { width: 300 }, sortable: true }),
        createEmailColumnProps<Contact>({ field: "email", header: "Email", style: { width: 300 }, sortable: true }),
        createFilteredTextColumnProps<Contact>({ field: "phoneNumber", header: "Phone", style: { width: 300 }, sortable: true }),
        createFilteredTextColumnProps<Contact>({ field: "position", header: "Position", style: { width: 300 }, sortable: true }),
        createFillRemainingSpaceColumnProps({}),
    ];

    return <FlexColumnContainer
        gap="20px"
    >
        <FlexRowContainer
            gap="20px"
        >
            <Button label="New Contact" onClick={onNewContactClick} />
        </FlexRowContainer>
        <DataTable
            dataKey="id"
            size="small"
            value={contacts}
            breakpoint={smallDeviceTableBreakpoint}
            scrollable={isSmallDevice ? false : true}
            scrollDirection={isSmallDevice ? "vertical": 'both'}
            scrollHeight={isSmallDevice ? "flex" : "flex"}
            responsiveLayout={isSmallDevice ? "stack" : undefined}
            paginator
            rows={20}
            rowsPerPageOptions={[10, 20, 50]}
        >
            {columns.map(c => <Column key={c.columnKey ?? c.field} {...c} />)}
        </DataTable>
    </FlexColumnContainer>
} 