import { ColumnProps } from "primereact/column";
import { PropertiesOfType } from "../typeHelpers";
import { createEditableNumberColumnProps, EditableNumberColumnOptions } from "./editableNumberColumn";

export interface EditableCurrencyColumnOptions<T extends PropertiesOfType<T, number | null | undefined>>
    extends Omit<EditableNumberColumnOptions<T>, "inputOptions"> {
}

export function createEditableCurrencyColumnProps<T extends PropertiesOfType<T, number | null | undefined>>(props: EditableCurrencyColumnOptions<T>): ColumnProps {

    return createEditableNumberColumnProps<T>({
        ...props,
        inputOptions: {           
            mode: "currency",
            currency: "USD",
            locale: "en-US"
        }
    });
}