import { actionFactory } from "../../types";

export const {
    deleteNewLogo,
    deleteNewLogoReducer
} = actionFactory
    .withMappedName("deleteNewLogo")
    .createAction<void>((state, _) => {
        state.logoState.newImagePreview = undefined;
    });

