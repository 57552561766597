import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { EntityImage } from "../../../commonModels/entityImage";
import { EntityVideo } from "../../../commonModels/entityVideo";
import { EntityMediaDialog } from "../../general/entityMediaEditing/entityMediaDialog";
import { addVideoBegin, addVideoCancel, addVideoComplete } from "../store/featureActions/inventoryImages/addVideo";
import { completeEditInventoryImages } from "../store/featureActions/inventoryImages/completeEditInventoryImages";
import { deleteInventoryImage } from "../store/featureActions/inventoryImages/deleteInventoryImage";
import { deleteInventoryVideo } from "../store/featureActions/inventoryImages/deleteInventoryVideo";
import { loadInventoryMedia } from "../store/featureActions/inventoryImages/loadInventoryMedia";
import { updateImageDetails } from "../store/featureActions/inventoryImages/updateImageDetails";
import { uploadNewInventoryImage } from "../store/featureActions/inventoryImages/uploadNewInventoryImage";
import { upsertInventoryVideo } from "../store/featureActions/inventoryImages/upsertInventoryVideo";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { getAddVideoModel, getAddVideoState, getAddVideoVisible, getEditImagesInventory, getEditMediaInventoryName, getInventoryMedia, getMediaLoadState, getMenuThumbnailImageId } from "../store/selectors/mediaEditing";
import { resetState } from "../store/slice";
import { ImageDetailsUpdate } from "../store/types";
import { AssignCategoriesDialog } from "./assignCategoriesDialog";
import { BulkAssignCategoriesDialog } from "./bulkAssignCategoriesDialog";
import { BulkAssignQualityGradeDialog } from "./bulkAssignQualityGradeDialog";
import { BulkClearCategoriesDialog } from "./bulkClearCategoriesDialog";
import { BulkExcludeDialog } from "./bulkExcludeDialog";
import { CreateQualityAssessmentDialog } from "./createQualityAssessmentDialog";
import { EditInventoryDialog } from "./editInventoryDialog";
import { AddInventoryDialog } from "./addInventoryDialog";
import { InventorySyncStatus } from "./inventorySyncStatus";
import { InventoryTable } from "./inventoryTable";
import { ViewOrdersDialog } from "./viewOrdersDialog";
import './index.css';
import { RemoveQualityAssessmentDialog } from "./removeQualityAssessmentDialog";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { getIsBusy } from "../store/selectors/actionState";
import { useAppSelector } from "../../../../store/configureStore";
import { getUserIsNodeAdmin } from "../../../authentication/store/selectors/userRoles";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { syncInventoryBegin } from "../store/featureActions/syncInventory";
import { SyncInventoryDialog } from "./syncInventoryDialog";
import { AssignReadyDateDialog } from "./assignReadyDateDialog";

export const InventoryManagementDashboard: React.FC = () => {

    const dispatch = useDispatch();
    const isBusy = useAppSelector(getIsBusy)
    const isNodeAdmin = useAppSelector(getUserIsNodeAdmin);

    useMountEffect(() => {
        dispatch(loadSliceData());

        return () => {
            dispatch(resetState());
        }
    });

    const onSaveImage = (update: ImageDetailsUpdate) => {
        dispatch(updateImageDetails(update));
    }

    const onDeleteImage = (image: EntityImage) => {
        dispatch(deleteInventoryImage(image));
    }

    const onUploadImage = (inventoryId: number, localFileUrl: string) => {
        dispatch(uploadNewInventoryImage({ inventoryId: inventoryId, localFileUrl: localFileUrl }));
    }

    const onAddVideoComplete = (value: EntityVideo) => {
        dispatch(addVideoComplete(value));
    }

    const onAddVideoCancel = () => {
        dispatch(addVideoCancel());
    }

    const onSaveVideo = (value: EntityVideo) => {
        dispatch(upsertInventoryVideo(value));
    };

    const onDeleteVideo = (value: EntityVideo) => {
        dispatch(deleteInventoryVideo(value));
    }

    const showAddVideoDialog = (inventoryId: number) => {
        dispatch(addVideoBegin({
            embedUrl: "https://",
            id: 0,
            owningEntityId: inventoryId,
            tenantId: "",
            title: "New Video"
        }));
    }

    const loadMedia = (entityId: number) => {
        dispatch(loadInventoryMedia(entityId));
    }

    const onHideMediaEditor = () => {
        dispatch(completeEditInventoryImages());
    };

    const onAdminSync = () => {
        dispatch(syncInventoryBegin());
    }


    return <OverlayWaitDisplay isWaiting={isBusy}>
        <ViewOrdersDialog /> 

        <SyncInventoryDialog />

        <AssignReadyDateDialog />

        <AssignCategoriesDialog />

        <BulkAssignCategoriesDialog />

        <BulkClearCategoriesDialog />

        <BulkAssignQualityGradeDialog />

        <CreateQualityAssessmentDialog />

        <RemoveQualityAssessmentDialog />

        <EntityMediaDialog
            addVideoProps={{
                onSave: onAddVideoComplete,
                onCancel: onAddVideoCancel,
                statusSelector: getAddVideoState,
                valueSelector: getAddVideoModel,
                visibleSelector: getAddVideoVisible
            }}
            imageActions={{
                onSave: onSaveImage,
                onDelete: onDeleteImage
            }}
            videoActions={{
                onSave: onSaveVideo,
                onDelete: onDeleteVideo
            }}
            thumbnailSelector={getMenuThumbnailImageId}
            mediaSelector={getInventoryMedia}
            mediaLoadingSelector={getMediaLoadState}
            uploadImage={onUploadImage}
            showAddVideoDialog={showAddVideoDialog}
            loadMedia={loadMedia}
            getEntity={getEditImagesInventory}
            getEntityName={getEditMediaInventoryName}
            onHide={onHideMediaEditor}
        />

        <BulkExcludeDialog />

        <EditInventoryDialog />
        <AddInventoryDialog />

        <FlexColumnContainer className="full-height-content-panel" gap="10px">
            <FlexRowContainer centerContent height="40px" padding="0px 20px" gap="10px" margin="10px">
                <div style={{
                    fontSize: "1.5rem",
                    fontWeight: "bolder",
                    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'"
                }}>Package Management</div>
                <InventorySyncStatus />
                {
                    isNodeAdmin &&
                    <Button
                       icon={PrimeIcons.COG}
                       onClick={onAdminSync}
                    />
                }
            </FlexRowContainer>

            <InventoryTable />

        </FlexColumnContainer>

    </OverlayWaitDisplay>
}