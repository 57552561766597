import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, InventoryManagementState } from "../types";

export const setSelectedInventory = actionFactory.createAppAction<number[]>("setSelectedInventory");

export function setSelectedInventoryReducers(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forAction(setSelectedInventory)        
        .addHandler((state, action) => {            
            state.selectedInventoryIds = action.payload;
        })
        .build();
}