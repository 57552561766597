import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../../store/configureStore";
import { createActionColumnProps } from "../../../../utils/table/actionColumn";
import { createFillRemainingSpaceColumnProps } from "../../../../utils/table/fillRemainingSpaceColumn";
import { createFilteredTextColumnProps } from "../../../../utils/table/stringColumn";
import { CompanyProfile } from "../../../company/profile/store/types";
import { customerEditorRoute } from "../../../navigation/routes";
import { smallDeviceQuery, smallDeviceTableBreakpoint } from "../../../responsiveconfiguration";
import { getCustomerList } from "../store/selectors/customers";


export const CustomerTable: React.FC = () => {

    const customers = useAppSelector(getCustomerList);
    const history = useHistory();
    const isSmallDevice = useMediaQuery(smallDeviceQuery);

    const onEdit = (customer: CompanyProfile) => {
        history.push(customerEditorRoute.createPath(customer.id));
    }

    const columns: ColumnProps[] = [
        createActionColumnProps<CompanyProfile>({
            columnKey: "id",
            actionName: "Edit",
            actionHandler: onEdit,
            header: "Actions",
            style: { width: 150 },
            sortable: false
        }),
        createFilteredTextColumnProps<CompanyProfile>({ field: "displayName", header: "Name", style: { width: 300 }, sortable: true }),
        createFilteredTextColumnProps<CompanyProfile>({ field: "stateAbbreviation", header: "State", style: { width: 300 }, sortable: true }),
        createFilteredTextColumnProps<CompanyProfile>({ field: "contactPhone", header: "Phone #", style: { width: 300 }, sortable: true }),
        createFilteredTextColumnProps<CompanyProfile>({ field: "contactEmail", header: "Email", style: { width: 300 }, sortable: true }),
        createFillRemainingSpaceColumnProps({}),
    ];

    return <DataTable
        dataKey="id"
        value={customers}
        size="small"
        breakpoint={smallDeviceTableBreakpoint}
        scrollable={isSmallDevice ? false : true}
        scrollDirection={isSmallDevice ? "vertical" : 'both'}
        scrollHeight={isSmallDevice ? "flex" : "flex"}
        responsiveLayout={isSmallDevice ? "stack" : undefined}
        paginator
        rows={20}
        rowsPerPageOptions={[10, 20, 50]}
    >
        {columns.map(c => <Column key={c.columnKey ?? c.field} {...c} />)}
    </DataTable>
} 