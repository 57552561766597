import { firstOrNull } from "../../../../../utils/arrayHelpers";
import { AssessmentEntry } from "../../../models/assessmentEvaluationRequest";
import { actionFactory } from "../types";

export const {

    setTestAssessmentValue,
    setTestAssessmentValueReducer

} = actionFactory
    .withMappedName("setTestAssessmentValue")
    .createAction<AssessmentEntry>((state, action) => {

        const matchingEntry = firstOrNull(
            state.testAssessmentEntries, 
            entry => entry.qualityMetricKindId === action.payload.qualityMetricKindId);

        if (matchingEntry == null) {
            state.testAssessmentEntries.push(action.payload);
        }
        else {
            matchingEntry.qualityMetricValueId = action.payload.qualityMetricValueId;
        }
    });