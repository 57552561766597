import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useAppSelector } from "../../../../store/configureStore";
import { createTextColumnProps } from "../../../../utils/table/stringColumn";
import { Company } from "../../../company/profile/store/types";
import { getCompanies } from "../store/selectors";
import { createFillRemainingSpaceColumnProps } from "../../../../utils/table/fillRemainingSpaceColumn";

export const CompanyTable: React.FC = () => {

    //const dispatch = useDispatch();
    const metricKinds = useAppSelector(getCompanies);

    const columns: ColumnProps[] = [
        createTextColumnProps<Company>({ header: "Tenant", field: "tenantId", sortable: true, style: { width: "150px", maxWidth: "200px" } }),
        createTextColumnProps<Company>({ header: "Type", field: "companyType", sortable: true, style: { width: "80px", maxWidth: "120px" } }),
        createTextColumnProps<Company>({ header: "State", field: "stateAbbreviation", sortable: true, style: { width: "80px", maxWidth: "100px" } }),
        createTextColumnProps<Company>({ header: "Name", field: "displayName", sortable: true, style: { minWidth: "150px" } }),
        createFillRemainingSpaceColumnProps({})
    ];

    return <DataTable
            size="small"
            dataKey="id"
            value={metricKinds}
            scrollable            
            scrollHeight='flex'
            paginator
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            alwaysShowPaginator={false}
            // selectionMode="single"
            // onSelectionChange={onSelectionChange}
            // selection={selectedMetricKind}
        >
            {columns.map(c => <Column key={c.columnKey ?? c.field} {...c} />)}
        </DataTable>
} 