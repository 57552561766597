import { EntityState } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { DataViewLayoutType } from "primereact/dataview";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { EntityImage } from "../../../commonModels/entityImage";
import { ImageDto } from "../../../commonModels/imageTypes";
import { IntegerId } from "../../../commonModels/integerId";
import { Vendor } from "../../productDetails/store/types";
import { SortField, SortDirection } from "../../common/types";
import { MetrcPackageType } from "../../../commonModels/metrcPackageType";

export const sliceName: string = "live-menu";

export const actionFactory = new ActionFactory<LiveMenuState>(sliceName);

export interface VendorMenuSection extends IntegerId {
    name: string;
    sequence: number;
}

export interface ProductView extends IntegerId {
    sectionId: number;
    marketingName: string;
    marketingStrainName: string;
    marketingDescription: string;
}

export interface LoadThumbnailsRequest {
    inventoryIds: number[];
}

export interface InventoryView extends IntegerId {
    productId: number;
    marketingName: string;
    marketingDescription: string;
    marketingStrainName: string;
    quantity: number;
    unitOfMeasureName: string;
    thcPercentage: number | null;
    cbdPercentage: number | null;
    unitPrice: number | null;
    packagedDate: DateTime;
    packageLabel: string;
    grade: string | null;
    packageType: MetrcPackageType | null;
    readyDate: DateTime | null;
}

export interface SectionProductList extends IntegerId {
    products: ProductView[];
    loadingState: ActionStatus;
}
export interface SectionInventoryList extends IntegerId {
    inventory: InventoryView[];
    loadingState: ActionStatus;
}

export interface VendorMenu extends IntegerId {
    tenantId: string;
    urlName: string;
    companyId: number;
    companyDisplayName: string;
    sections: VendorMenuSection[];
    primaryColor: string;
    secondaryColor: string;
    primaryFont: string | null;
    secondaryFont: string | null;
    defaultSortField: SortField;
    defaultSortDirection: SortDirection;
}

export interface SortSetting {
    sortField: SortField;
    direction: SortDirection;
}

export interface MenuOptions {
    refreshIntervalMilliseconds: number
}

export interface LiveMenuState {

    companyRouteName: string | null;
    vendorMenuLoadingState: ActionStatus;

    vendor: Vendor | null;
    vendorLoadingState: ActionStatus;
    licenses: string[];

    vendorMenu: VendorMenu | null;
    menuSections: VendorMenuSection[];
    sectionLayout: DataViewLayoutType | "table";

    isCurrentUserFavoriteActionState: ActionStatus;
    isCurrentUserFavorite?: boolean | null;

    sectionInventoryLists: EntityState<SectionInventoryList>;
    inventoryLookup: EntityState<InventoryView>;
    inventoryImages: EntityState<EntityImage>;
    inventoryImageLoadState: ActionStatus;

    searchText: string;
    sortSetting: SortSetting;

    optionDialogVisible: boolean;
    refreshOptions: MenuOptions;

    defaultThumbnail: ImageDto | null;
}