import { Button } from "primereact/button";

export interface TitleWithDeleteProps {
    title: string;
    onDelete: () => void;
    deleteVisible: boolean;
}

export const TitleWithDelete: React.FC<TitleWithDeleteProps> = ({ title, onDelete, deleteVisible }) => {

    const onDeleteClick = () => {
        onDelete();
    }

    return <div className="p-grid p-p-2"  >
        <div className="p-col-6" >
            <div className="p-d-flex p-jc-start p-ai-center">
                {title}
            </div>
        </div>
        <div className="p-col-6" >
            <div className="p-d-flex p-jc-end p-ai-center">
                <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-outlined p-button-danger p-button-sm"
                    style={{ display: deleteVisible ? undefined : "none" }}
                    onClick={onDeleteClick}
                />
            </div>
        </div>
    </div>
}

