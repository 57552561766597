import { Column } from "primereact/column";
import TreeNode from "primereact/treenode";
import { TreeTable, TreeTableExpandedKeysType, TreeTableSelectionKeys, TreeTableSelectionParams, TreeTableToggleParams } from 'primereact/treetable';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { assignCategories } from "../store/featureActions/categoryAssignment/assignCategories";
import { loadMenuViews } from "../store/featureActions/categoryAssignment/loadMenuViews";
import { getAssignedCategories, getCategoryAssignmentLoadState, getMenuViews } from "../store/selectors/categoryAssignment";
import { resetState } from "../store/slice";
import './categoryAssignment.css';
import { createMenuKey, getIdFromKey, getSelectedKeys, isCategoryKey, isTreeTableSelectionValue, mapMenuViewTreeNode, TreeTableSelectionValue } from "./logic";

export const CategoryAssignment: React.FC = () => {

    const dispatch = useDispatch();
    const menuViews = useAppSelector(getMenuViews);
    const selectedCategories = useAppSelector(getAssignedCategories);
    const loadingState = useAppSelector(getCategoryAssignmentLoadState)

    const treeNodes: TreeNode[] = menuViews.map(mapMenuViewTreeNode);

    const selectedKeys: { [key: number]: TreeTableSelectionValue } = getSelectedKeys(menuViews, selectedCategories);

    const [expandedKeys, setExpandedKeys] = useState<TreeTableExpandedKeysType>({})

    useMountEffect(() => {
        dispatch(loadMenuViews());

        return () => {
            dispatch(resetState());
        };
    });

    useEffect(() => {
        const initialExpandedKeys: TreeTableExpandedKeysType = {};
        menuViews.forEach(menuView => {
            initialExpandedKeys[createMenuKey(menuView)] = true
        });
        setExpandedKeys(initialExpandedKeys);
    }, [menuViews, setExpandedKeys]);


    const onSelectionChange = (e: TreeTableSelectionParams) => {

        const selectedCategoryIds = Object
            .keys(e.value)
            .filter(isCategoryKey)
            .filter(key => {
                const value = e.value[key];
                return isTreeTableSelectionValue(value) && value.checked
            })
            .map(key => getIdFromKey(key));

        dispatch(assignCategories(selectedCategoryIds))
    }

    const onToggle = (e: TreeTableToggleParams) => {
        setExpandedKeys(e.value);
    }

    const calculateTreeTableRowClass = (node: TreeNode) => {
        return {
            "menu-tree-table-row": node.data.kind === "Menu",
            "category-tree-table-row": node.data.kind === "Category"
        };
    }

    return <div className="category-assignment-container">

        <OverlayWaitDisplay isWaiting={loadingState.isExecuting} >

            <TreeTable
                rowClassName={calculateTreeTableRowClass}
                selectionMode="checkbox"
                selectionKeys={selectedKeys as unknown as TreeTableSelectionKeys}
                onSelectionChange={onSelectionChange}
                value={treeNodes}
                className="category-assignment-tree-table"
                expandedKeys={expandedKeys}
                onToggle={onToggle}
                scrollable
                scrollHeight="65vh"                
            >
                <Column field="name" expander={true} />
            </TreeTable>

        </OverlayWaitDisplay>
    </div>

}