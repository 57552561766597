import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { MultiSelectFilterOption } from "../../../../../utils/table/filters/multiSelectFilterElement";
import { qualityGradeAdapter, qualityGradeKindAdapter } from "../state";

const qualityGradeSelectors = qualityGradeAdapter.getSelectors((state: RootState) => state.inventoryManagement.qualityGrades);

export const getQualityGradeValues = createSelector(
    [
        qualityGradeSelectors.selectAll
    ],
    (allGradeValues) => {
        return allGradeValues
            .slice()
            .sort((left, right) => {
                if (left.gradeKindId === right.gradeKindId) return left.sequence - right.sequence;
                return left.gradeKindId - right.gradeKindId;
            })
    }
);


export const getQualityGradeValueMap = qualityGradeSelectors.selectEntities;

const qualityGradeKindSelectors = qualityGradeKindAdapter.getSelectors((state: RootState) => state.inventoryManagement.qualityGradeKinds);

export const getQualityGradeKinds = qualityGradeKindSelectors.selectAll;
export const getQualityGradeKindMap = qualityGradeKindSelectors.selectEntities;

export const getQualityAssessmentTemplates = (state: RootState) => state.inventoryManagement.qualityAssessmentTemplates

export const getCreateQualityAssessmentState = (state: RootState) => state.inventoryManagement.assignQualityAssessmentState;
export const getCreateQualityAssessmentVisible = (state: RootState) => state.inventoryManagement.assignQualityAssessmentState.isVisible;
export const getCreateQualityAssessmentValue = (state: RootState) => state.inventoryManagement.assignQualityAssessmentState.editedValue;

export const getRemoveQualityAssessmentState = (state: RootState) => state.inventoryManagement.removeQualityAssessmentState;
export const getRemoveQualityAssessmentVisible = (state: RootState) => state.inventoryManagement.removeQualityAssessmentState.isVisible;
export const getRemoveQualityAssessmentValue = (state: RootState) => state.inventoryManagement.removeQualityAssessmentState.editedValue;


export const getQualityGradeFilterOptions = createSelector(
    [
        getQualityGradeValues,
        getQualityGradeKindMap
    ],
    (allGradeValues, qualityGradeKindMap) => {
       return [{ label: "Not Set", value: -1 }]
       .concat(allGradeValues.map<MultiSelectFilterOption<number>>(gradeValue => {

           const gradeKind = qualityGradeKindMap[gradeValue.gradeKindId];

           return { 
               label: `${gradeKind?.name} - ${gradeValue.name}`, 
               value: gradeValue.id 
           };
       }));
    }
);