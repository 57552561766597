import { useDispatch, useSelector } from "react-redux";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";

import { EntityId } from "@reduxjs/toolkit";
import { getCompanyProfile, getMenuById } from "../store/selectors";
import { RootState } from "../../../../store/rootReducer";
import { Button } from "primereact/button";
import { push } from "connected-react-router";
import { liveMenuRoute } from "../../../navigation/routes";
import { isValue } from "../../../../utils/valueHelper";

export interface MenuListItemProps {
    menuId: EntityId;
}
export const MenuListItem: React.FC<MenuListItemProps> = ({ menuId }) => {

    const dispatch = useDispatch();
    const company = useSelector(getCompanyProfile);
    const menu = useSelector((state: RootState) => getMenuById(state, menuId));

    const onNavigateToMenu = () => {
        if (isValue(company) && isValue(menu) && isValue(company.urlName) && isValue(menu.urlName)) {
            dispatch(push(liveMenuRoute.createPath(company.urlName, menu.urlName)));
        }
    }

    return <FlexRowContainer className="menu-list-item-row">
        <Button className="p-button-link menu-list-item-link" onClick={onNavigateToMenu}>{menu?.name}</Button>
    </FlexRowContainer>
} 