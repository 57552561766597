import { DateTime } from 'luxon';
import * as yup from 'yup';
import { isValue } from '../../../utils/valueHelper';
import { SalesOrderItemEditModel } from '../orderEditor/store/types';

export const orderItemValidationSchema: yup.SchemaOf<SalesOrderItemEditModel> = yup.object().shape({
    createdUtc: yup.mixed<DateTime>().optional(),
    modifiedUtc: yup.mixed<DateTime>().optional(),
    id: yup.number().optional(),
    price: yup.number().nullable(),
    productId: yup.number().required("Product is required"),
    productName: yup.string().optional(),
    quantity: yup.number()
        .required("Quantity is required")
        .typeError("Quantity is required")
        .test(
            "QuantityLessThanOrEqualToRemainingQuantity", 
            "Quantity must be less than or equal to available quantity",
            function (_value, context) {
                const model = context.parent as SalesOrderItemEditModel;
                return isValue(model.remainingQuantity)  && isValue(model.quantity)
                    ? model.quantity <= model.remainingQuantity
                    : true
            }),
    salesOrderId: yup.number().required("Sales order is required"),
    unitOfMeasure: yup.string().optional(),
    inventoryId: yup.number().nullable(),
    inventoryName: yup.string().nullable(),
    remainingQuantity: yup.number().defined().nullable(),
});