import { ErrorDisplayType } from "../../../../../store/factory/sagaWithProgress/types";
import { ImageDto } from "../../../../commonModels/imageTypes";
import { actionFactory } from "../types";

export const {

    loadVendorBanner,
    loadVendorBannerReducer,
    loadVendorBannerSagaFactory

} = actionFactory
    .withMappedName("loadVendorBanner")
    .createAsyncAction<{ vendorUrlName: string, menuUrlName: string }, ImageDto>({
        actionStatusSelector: state => state.vendorBannerLoadingState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.liveMenu.getVendorBanner(arg.vendorUrlName, arg.menuUrlName);
        },
        onFulfilled: (state, action) => {
            state.vendorBanner = action.payload.result;
        },
        debounceInMilliseconds: 250,
        errorDisplay: ErrorDisplayType.None
    });