import clsx from "clsx";
import { Field, FieldProps, useFormikContext } from "formik";
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import React from "react";
import { MarketplaceProfile } from "../store/types";

export const UserProfileEditor: React.FC = () => {
    
    const formik = useFormikContext<Partial<MarketplaceProfile>>();

    return <div>     

        <div className="p-mt-1 p-p-1">
            <div className="p-fluid p-m-1 p-p-2">

                <div className="p-fluid">

                    <div className="p-field">
                        <label>First name</label>
                        <Field
                            type="text"
                            name="firstName"
                            className={clsx({ "p-invalid": !!formik.errors.firstName })}
                            as={InputText}>
                        </Field>
                        <small>{formik.errors.firstName}</small>
                    </div>

                    <div className="p-field">
                        <label>Last name</label>
                        <Field
                            type="text"
                            name="lastName"                            
                            className={clsx({ "p-invalid": !!formik.errors.lastName })}
                            as={InputText}>
                        </Field>
                        <small>{formik.errors.lastName}</small>
                    </div>

                    <div className="p-field">
                        <label>Phone Number</label>
                        <Field
                            type="text"
                            name="phoneNumber"
                        >
                            {
                                (props: FieldProps<Partial<MarketplaceProfile>>) =>
                                    <InputMask
                                        mask="(999) 999-9999"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        value={formik.values.phoneNumber ?? undefined}
                                        onChange={(event) => {
                                            formik.setFieldValue(props.field.name, event.target.value ?? "");
                                        }}
                                        onBlur={props.field.onBlur} />
                            }
                        </Field>
                        <small>{formik.errors.phoneNumber}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
}