import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { getAssignedCategories } from "../../categoryAssignment/store/selectors/categoryAssignment";
import { CategoryAssignment } from "../../categoryAssignment/ui/categoryAssignment";
import { cancelInventoryCategoryAssignment } from "../store/featureActions/categoryAssignment/cancelInventoryCategoryAssignment";
import { completeInventoryCategoryAssignment } from "../store/featureActions/categoryAssignment/completeInventoryCategoryAssignment";
import { getCategoryAssignmentSaveState, getInventoryForCategoryAssignment } from "../store/selectors/categoryAssignment";

export const AssignCategoriesDialog: React.FC = () => {

    const parentModel = useAppSelector(getInventoryForCategoryAssignment);
    const categoryAssignmentSaveState = useAppSelector(getCategoryAssignmentSaveState)
    const assignedCategoryIds = useAppSelector(getAssignedCategories);

    const dispatch = useDispatch();

    const onHideDialog = () => { };

    const onSave = () => {
        dispatch(completeInventoryCategoryAssignment(assignedCategoryIds));
    }

    const onCancel = () => {
        dispatch(cancelInventoryCategoryAssignment());
    }

    return <Dialog
        onHide={onHideDialog}
        visible={isValue(parentModel)}
        closable={false}
        draggable={false}
        header={`Assign categories to package ${parentModel?.metrcPackageLabel}`}
    >
        <CategoryAssignment />
        <div>
            <Button className="p-ml-2" label="Save" style={{ float: "right" }} onClick={onSave} loading={categoryAssignmentSaveState.isExecuting} />
            <Button label="Cancel" style={{ float: "right" }} onClick={onCancel} disabled={categoryAssignmentSaveState.isExecuting} />
        </div>
    </Dialog>
}