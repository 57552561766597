import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { getIsLowestRankGrade, getRootConstraintNodeForGradeValue } from "../store/selectors/data";
import { ConstraintNodeTree } from "./constraintNodeTree";

export interface ConstraintListProps {
    gradeValueId: number;
}

export const GradeConstraintList: React.FC<ConstraintListProps> = ({ gradeValueId }) => {

    const rootConstraint = useAppSelector(state => getRootConstraintNodeForGradeValue(state)(gradeValueId))    
    const isLowestRankGrade = useAppSelector(state => getIsLowestRankGrade(state)(gradeValueId))

    return <FlexColumnContainer className="template-constraints-list">
        {
            isValue(rootConstraint)
                ? <ConstraintNodeTree 
                    key={rootConstraint.id} 
                    constraintNodeId={rootConstraint.id} 
                    gradeValueId={gradeValueId} 
                />
                : isLowestRankGrade 
                    ? <div className="template-constraint-list-default">Default grade when no rules are met.</div>
                    : <div className="template-constraint-list-empty">Click 'Add Logic Block' to add rules for this grade.</div>
        }
    </FlexColumnContainer>
}