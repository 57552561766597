import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { BulkAssignmentKind } from "../../../commonModels/bulkAssignmentState";
import { QualityGradeValue } from "../../../qualityGradeTemplates/models/gradeValue";
import { cancelBulkQualityGradeAssignment } from "../store/featureActions/qualityGrades/cancelBulkQualityGradeAssignment";
import { completeBulkQualityGradeAssignment } from "../store/featureActions/qualityGrades/completeBulkQualityGradeAssignment";
import { setQualityGradeToAssign } from "../store/featureActions/qualityGrades/setQualityGradeToAssign";
import { getBulkQualityGradeAssignmentDialogTitle, getBulkQualityGradeSavingState, getInventoryForBulkQualityGradeAssignment, getQualityGradeAssignmentKind, getQualityGradeToBulkAssign } from "../store/selectors/bulkQualityGradeAssignment";
import { getQualityGradeValues } from "../store/selectors/qualityGrade";


export const BulkAssignQualityGradeDialog: React.FC = () => {

    const qualityGrades: QualityGradeValue[] = useAppSelector(getQualityGradeValues);
    const inventoryForBulkAssign = useAppSelector(getInventoryForBulkQualityGradeAssignment);
    const qualityGradeIdToAssign = useAppSelector(getQualityGradeToBulkAssign);
    const title = useAppSelector(getBulkQualityGradeAssignmentDialogTitle);
    const bulkAssignmentKind = useAppSelector(getQualityGradeAssignmentKind);
    const saveState = useAppSelector(getBulkQualityGradeSavingState);
    const dispatch = useDispatch(); 

    const onHideDialog = () => { };

    const onSave = () => {
        dispatch(completeBulkQualityGradeAssignment());
    }

    const onCancel = () => {
        dispatch(cancelBulkQualityGradeAssignment());
    }

    const onSelectedQualityGradeChanged = (e: DropdownChangeParams) => {
        if (bulkAssignmentKind === BulkAssignmentKind.Assign && typeof (e.value) === "number") {
            dispatch(setQualityGradeToAssign(e.value));
        }
    }

    return <Dialog
        onHide={onHideDialog}
        visible={inventoryForBulkAssign.length > 0}
        closable={false}
        draggable={false}
        header={title}
    >
        <FlexColumnContainer gap="20px" paddingTop="20px">

            {
                bulkAssignmentKind === BulkAssignmentKind.Assign &&
                <FlexRowContainer gap="10px" centerContent>
                    <label style={{ display: "inline-block", width: "150px" }}>Quality Grade</label>
                    <Dropdown
                        value={qualityGradeIdToAssign}
                        style={{ width: "100%" }}
                        options={qualityGrades.map(qualityGrade => ({ label: qualityGrade.name, value: qualityGrade.id }))}
                        onChange={onSelectedQualityGradeChanged}
                    />
                </FlexRowContainer>
            }
            <div>
                <Button className="p-ml-2" label="Save" style={{ float: "right" }} onClick={onSave} loading={saveState.isExecuting} />
                <Button label="Cancel" style={{ float: "right" }} onClick={onCancel} disabled={saveState.isExecuting} />
            </div>
        </FlexColumnContainer>
    </Dialog>
}