import { createAction } from "@reduxjs/toolkit";
import { IReducerFactory } from "../../../../store/factory/interfaces";
import { actionFactory, AppComponentState } from "../types";

export const toggleSidePanelVisible = createAction(actionFactory.createActionName("toggleSidePanelVisible"));

export function toggleSidePanelVisibleReducer(reducerFactory: IReducerFactory<AppComponentState>): IReducerFactory<AppComponentState> {
    return reducerFactory
        .forAction(toggleSidePanelVisible)
        .addHandler((state, _) => {
            state.sidePanelVisible = !state.sidePanelVisible;
        })
        .build();
}