import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { AcceptTermsOfServiceState } from './types';

export const createInitialState = (): AcceptTermsOfServiceState => {
    return {        
        loadingState: CreateActionStatusInitialState(),      
        savingState: CreateActionStatusInitialState(),      
        termsOfServiceAcceptance: null,
        userAgreesToNewTerms: false
    };
};

export const initialState: AcceptTermsOfServiceState = createInitialState();