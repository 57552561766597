export interface FailureMessage {
    message: string
}

export function isFailureMessage(arg: any): arg is FailureMessage {
    if (arg === null) return false;
    if (arg === undefined) return false;
    return ((arg as FailureMessage).message !== undefined)
};

export interface FailedApiResponse extends FailureMessage{    
    status: number,
    statusText: string,
    url: string
}

export interface ProblemDetail {
    type: string | null,
    title: string | null, 
    status: number | null, 
    detail: string | null,
    instance: string | null, 
    traceId: string | null
};

export interface ValidationFailure {
    errorMessage: string,
    memberNames: string[]
};

export function isValidationFailure(arg: any): arg is ValidationFailure {
    if (arg === null) return false;
    if (arg === undefined) return false;
    return ((arg as ValidationFailure).errorMessage !== undefined) && 
    ((arg as ValidationFailure).memberNames !== undefined)
};

export function isProblemDetail(arg: any): arg is ProblemDetail {
    if (arg === null) return false;
    if (arg === undefined) return false;
    return ((arg as ProblemDetail).type !== undefined) &&
    ((arg as ProblemDetail).title !== undefined) &&
    ((arg as ProblemDetail).status !== undefined) &&    
    ((arg as ProblemDetail).traceId !== undefined);
};

export function isResponse(arg: any): arg is Response {
    if (arg === null) return false;
    if (arg === undefined) return false;
    return ((arg as Response).status !== undefined) &&
    ((arg as Response).statusText !== undefined) &&    
    ((arg as Response).url !== undefined);
};

export function isFailedApiResponse(arg: any): arg is FailedApiResponse {
    if (arg === null) return false;
    if (arg === undefined) return false;
    return ((arg as FailedApiResponse).message !== undefined) &&
    ((arg as FailedApiResponse).status !== undefined) &&
    ((arg as FailedApiResponse).statusText !== undefined) &&
    ((arg as FailedApiResponse).url !== undefined);
};

export function isUauthorizedApiResponse(arg: any): arg is FailedApiResponse {
    if (arg === null) return false;
    if (arg === undefined) return false;
    return ((arg as FailedApiResponse).status !== undefined) &&
    ((arg as FailedApiResponse).status === 401);
};

export type ErrorContent = FailedApiResponse | ProblemDetail | ValidationFailure | FailureMessage | string | undefined | null;