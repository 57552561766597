import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../store/configureStore";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { isValue } from "../../../../utils/valueHelper";
import { smallDeviceQuery } from "../../../responsiveconfiguration";
import { InventoryImageGallery } from "../../inventoryImageGallery/ui/inventoryImageGallery";
import { getVendorBanner } from "../../vendorTopPanel/store/selectors";
import { VendorTopPanel } from "../../vendorTopPanel/ui/vendorTopPanel";
import { loadDefaultThumbnail } from "../store/featureActions/loadDefaultThumbnail";
import { loadIsFavorite } from "../store/featureActions/loadIsFavorite";
import { loadLicenses } from "../store/featureActions/loadLicenses";
import { loadLiveMenu } from "../store/featureActions/loadLiveMenu";
import { loadVendor } from "../store/featureActions/loadVendor";
import { setCustomerRouteName } from "../store/featureActions/setCustomerRouteName";
import { getPrimaryColor, getSecondaryColor } from "../store/selectors/menuColors";
import { getCustomerDisplayName } from "../store/selectors/vendorMenu";
import { resetState } from "../store/slice";
import './index.css';
import { LicenseDisplay } from "./licenseDisplay";
import { MenuOptionsDialog } from "./menuOptionsDialog";
import { MenuRefresher } from "./menuRefresher";
import { LiveMenuParams } from "./menuRouteParams";
import { Prop65Warning } from "./prop65Warning";
import { MenuSearchBar } from "./searchBar";
import { SectionList } from "./sectionList";

export const LiveMenuDashboard: React.FC = () => {

    const liveMenuParams = useParams<LiveMenuParams>();


    const dispatch = useDispatch();
    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    const banner = useAppSelector(getVendorBanner);
    const hasBanner = isValue(banner);    


    useMountEffect(() => {
        if (isValue(liveMenuParams.vendorName) && isValue(liveMenuParams.menuName)) {
            dispatch(loadLiveMenu({ vendorName: liveMenuParams.vendorName, menuName: liveMenuParams.menuName }));
            dispatch(loadIsFavorite({ vendorName: liveMenuParams.vendorName, menuName: liveMenuParams.menuName }));
            dispatch(loadVendor(liveMenuParams.vendorName));
            dispatch(setCustomerRouteName(liveMenuParams.vendorName));
            dispatch(loadLicenses(liveMenuParams.vendorName));
            dispatch(loadDefaultThumbnail({ vendorUrlName: liveMenuParams.vendorName, menuUrlName: liveMenuParams.menuName }));
        }

        return () => {
            dispatch(resetState());
        }
    });

    return <div className="p-d-flex p-flex-column">

        <div className="menu-vendor-search-container p-d-flex p-flex-column">
            <VendorTopPanel
                vendorUrlName={liveMenuParams.vendorName}
                menuUrlName={liveMenuParams.menuName}
                companyDisplayNameSelector={getCustomerDisplayName}
                primaryColorSelector={getPrimaryColor}
                secondardColorSelector={getSecondaryColor}
            />
            <MenuSearchBar vendorName={liveMenuParams.vendorName} menuName={liveMenuParams.menuName} />
        </div>

        <div id="menu-section-list" className={clsx({
            "section-list-small": isSmallDevice && hasBanner,
            "section-list-large": !isSmallDevice && hasBanner,
            "section-list-no-banner-small": isSmallDevice && !hasBanner,
            "section-list-no-banner-large": !isSmallDevice && !hasBanner,
        })}
        >           
            <SectionList />
            <LicenseDisplay />
            <Prop65Warning />
        </div>

        <MenuOptionsDialog />
        <InventoryImageGallery />
        <MenuRefresher />
    </div>
}