import { NotAuthorizedAction } from "../../../../../store/factory/sagaWithProgress/types";
import { isValue } from "../../../../../utils/valueHelper";
import { actionFactory, VendorMenu } from "../types";

export interface LoadLiveMenuParams {
    vendorName: string;
    menuName: string;
}

export const {

    loadLiveMenu,
    loadLiveMenuReducer,
    loadLiveMenuSagaFactory

} = actionFactory
    .withMappedName("loadLiveMenu")
    .createAsyncAction<LoadLiveMenuParams, VendorMenu>({
        actionStatusSelector: state => state.vendorMenuLoadingState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.liveMenu.getLiveMenu(arg.vendorName, arg.menuName);
        },
        onFulfilled: (state, action) => {

            // To preserve user modifications to the sort field and direction, 
            // only set the sort values on the first load.
            if (!isValue(state.vendorMenu)) {
                state.sortSetting = {
                    direction: action.payload.result.defaultSortDirection,
                    sortField: action.payload.result.defaultSortField
                }
            }

            state.vendorMenu = action.payload.result;
            state.menuSections = action.payload.result.sections
                .sort((left, right) => left.sequence - right.sequence);

        },
        notAuthorizedAction: NotAuthorizedAction.NotAuthorizedEvent,
        debounceInMilliseconds: 250
    });