import * as yup from 'yup';
import { urlRegex, validUSStatesRegex } from "../../../../utils/validationHelper";
import { CompanyProfile, CompanyType } from "../store/types";

export const companyProfileValidationSchema: yup.SchemaOf<Partial<CompanyProfile>> = yup.object().shape({

    displayName: yup.string()
        .min(1, "Company name must be at least 1 character")
        .max(255, "Company name must be at most 255 characters")
        .required("Company name is required"),

    stateAbbreviation: yup.string()
        .matches(validUSStatesRegex, "Please enter a valid state")        
        .required("State is required"),
 
    id: yup.number().optional(),
    tenantId: yup.string().optional(),
    urlName: yup.string().optional(),
    contactEmail: yup.string().optional().nullable(),
    contactUrl: yup.string().optional().nullable().matches(urlRegex, "url must be correctly formed, such as https://www.example.com. You must include http or https in the beginning."),
    subscriptionUrl: yup.string().optional().nullable().matches(urlRegex, "url must be correctly formed, such as https://www.example.com. You must include http or https in the beginning."),
    contactPhone: yup.string().optional().nullable(),
    companyType:  yup.mixed<CompanyType>().nullable(),
});