import { EntityState } from "@reduxjs/toolkit";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { isValue } from "../../../../utils/valueHelper";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { IntegerId } from "../../../commonModels/integerId";
import { ImageState } from "../../../company/profile/store/types";
import { Category } from "../../categoryManagement/store/types";
import { SortDirection, SortField } from "../../common/types";
 
export const sliceName: string = "menu-editor";

export const actionFactory = new ActionFactory<MenuAdminState>(sliceName);

export interface NewMenuRequest {
    name: string;   
    inactive: boolean;
}

export interface Menu extends IntegerId {
    name: string;
    vendorName?: string | null;
    vendorUrlName?: string | null;
    urlName: string;
    tenantId: string;
    companyId: number;
    inactive: boolean;
    primaryColor: string | null;
    secondaryColor: string | null;
    primaryFont: string | null;
    secondaryFont: string | null;
    defaultSortField: SortField;
    defaultSortDirection: SortDirection;
}

export interface CategorySectionCommand {
    menuSectionId: number,
    categoryId: number
}

export const isSaveableMenu = (value: Menu | Partial<Menu> | null | undefined): value is Menu => {
    if (!isValue(value)) return false;

    if (isValue(value.id) && value.id > 0) {
        return isValue(value.name) && isValue(value.companyId) && isValue(value.companyId);
    }
    else {
        return isValue(value.name);
    }

}

export interface MenuSection extends IntegerId {
    tenantId: string;
    menuId: number;
    name: string;
    description?: string | null;
    sequence: number;
    categoryIds: number[];   
}


export const isMenuSection = (value: MenuSection | any): value is MenuSection => {
    if (!isValue(value)) return false;

    return isValue((value as MenuSection).tenantId) &&
            isValue((value as MenuSection).menuId) &&
            isValue((value as MenuSection).name) &&
            isValue((value as MenuSection).description) &&
            isValue((value as MenuSection).sequence) &&
            isValue((value as MenuSection).categoryIds);
}

export interface MenuAdminState {
    loadingState: ActionStatus;
    menu: Menu | null;
    menuSections: EntityState<MenuSection>;
    menuSectionLoadState: ActionStatus;
    addSectionSaveState: ActionStatus;
    categories: EntityState<Category>;
    categoriesLoadState: ActionStatus;
    useSmallMenuSectionView: boolean;
    addCategorySaveState: ActionStatus;        
    logoState: ImageState;
    bannerState: ImageState;
    thumbnailState: ImageState;
}