import { createEntityAdapter } from "@reduxjs/toolkit";
import { CreateInitialModalEditState } from "../../../../store/factory/modalEdit/modalEdit";
import { CreateActionStatusInitialState, EntityActionStatus } from "../../../actionStatus/actionStatus";
import { AssessmentTemplate } from "../../../qualityGradeTemplates/models/assessmentTemplate";
import { QualityGradeKind } from "../../../qualityGradeTemplates/models/gradeKind";
import { QualityGradeValue } from "../../../qualityGradeTemplates/models/gradeValue";
import { QualityMetricKind } from "../../../qualityGradeTemplates/models/metricKind";
import { QualityMetricValue } from "../../../qualityGradeTemplates/models/metricValue";
import { TemplateMetric } from "../../../qualityGradeTemplates/models/templateMetric";
import { QualityAssessment } from "../../models/qualityAssessment";
import { QualityAssessmentValue } from "../../models/qualityAssessmentValue";
import { QualityAssessmentEditorState } from './types';

export const qualityAssessmentAdapter = createEntityAdapter<QualityAssessment>();
export const qualityAssessmentValueAdapter = createEntityAdapter<QualityAssessmentValue>();

export const qualityMetricKindAdapter = createEntityAdapter<QualityMetricKind>();
export const qualityMetricValueAdapter = createEntityAdapter<QualityMetricValue>();
export const qualityGradeKindAdapter = createEntityAdapter<QualityGradeKind>();
export const qualityGradeValueAdapter = createEntityAdapter<QualityGradeValue>();
export const templateMetricAdapter = createEntityAdapter<TemplateMetric>();
export const templateAdapter = createEntityAdapter<AssessmentTemplate>();
export const loadAssesmentStateAdapter = createEntityAdapter<EntityActionStatus>();
export const saveAssesmentStateAdapter = createEntityAdapter<EntityActionStatus>();
export const saveValueStateAdapter = createEntityAdapter<EntityActionStatus>();


export const createInitialState = (): QualityAssessmentEditorState => {
    return {
        loadAssessmentState: loadAssesmentStateAdapter.getInitialState(),
        loadReferenceDataState: CreateActionStatusInitialState(),
        assessments: qualityAssessmentAdapter.getInitialState(),
        metricKinds: qualityMetricKindAdapter.getInitialState(),
        metricValues: qualityMetricValueAdapter.getInitialState(),
        gradeKinds: qualityGradeKindAdapter.getInitialState(),
        gradeValues: qualityGradeValueAdapter.getInitialState(),
        assessmentValues: qualityAssessmentValueAdapter.getInitialState(),
        templateMetrics: templateMetricAdapter.getInitialState(),
        templates: templateAdapter.getInitialState(),
        saveAssessmentState: saveAssesmentStateAdapter.getInitialState(),
        saveValueState: saveValueStateAdapter.getInitialState(),
        modalEditState: CreateInitialModalEditState()
    };
};

export const initialState: QualityAssessmentEditorState = createInitialState();