import { actionFactory } from "../types";

export const {

    beginSaveAs,
    beginSaveAsReducer

} = actionFactory
    .withMappedName("beginSaveAs")
    .createAction<{ viewId: string }>((state, action) => {
        state.saveAsState.visible = true;
        state.saveAsState.viewId = action.payload.viewId;
    });