import { FormikContextType, FormikHelpers } from "formik";
import { DateTime } from "luxon";
import { DateTimeOrNullKeyOf, NumberArrayKeyOf, NumberOrNullKeyOf, PropertiesOfType, StringOrNullKeyOf } from "./typeHelpers";

export const setFormikStringField = <T extends PropertiesOfType<T, string | null>>(context: FormikContextType<T>, field:  StringOrNullKeyOf<T> & string, value: string | null) : void => {
    context.setFieldValue(field, value);
}

export const setFormikNumberField = <T extends PropertiesOfType<T, number | null | undefined>>(context: FormikContextType<T> | FormikHelpers<T>, field:  NumberOrNullKeyOf<T> & string, value: number | null | undefined) : void => {
    context.setFieldValue(field, value);
}

export const setFormikDateField = <T extends PropertiesOfType<T, DateTime | null>>(context: FormikContextType<T>, field:  DateTimeOrNullKeyOf<T> & string, value: DateTime | null) : void => {
    context.setFieldValue(field, value);
}

export const setFormikField = <T>(context: FormikContextType<T> | FormikHelpers<T>, field:  keyof T & string, value: any) : void => {
    context.setFieldValue(field, value);
}

export const setFormikNumberArrayField = <T extends PropertiesOfType<T, number[]>>(context: FormikContextType<T> | FormikHelpers<T>, field:  NumberArrayKeyOf<T> & string, value: number[]) : void => {
    context.setFieldValue(field, value);
}