import { Dialog } from "primereact/dialog";
import { useAppSelector } from "../../../../store/configureStore";
import { RootState } from "../../../../store/rootReducer";
import { isValue } from "../../../../utils/valueHelper";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { IntegerId } from "../../../commonModels/integerId";
import { MixedMedia } from "../../../commonModels/mixedMedia";
import { AddVideoDialog, AddVideoDialogProps } from "./addVideoDialog";
import { EntityMediaEditor } from "./entityMediaEditor";
import { ImageActions } from "./imageListDisplay";
import { VideoActions } from "./videoListDisplay";
import './index.css';

export interface EntityMediaDialogProps<T extends IntegerId> {
    addVideoProps: AddVideoDialogProps;
    imageActions: ImageActions;
    videoActions: VideoActions;
    thumbnailSelector: (state: RootState) => number | null | undefined;
    mediaSelector: (state: RootState) => MixedMedia[];
    mediaLoadingSelector: (state: RootState) => ActionStatus;
    uploadImage: (inventoryId: number, localFileUrl: string) => void;
    showAddVideoDialog: (inventoryId: number) => void;
    loadMedia: (entityId: number) => void;
    getEntityName: (state: RootState) => string;
    getEntity: (state: RootState) => T | undefined;
    onHide: () => void;
}

export const EntityMediaDialog  = <T extends IntegerId>({
    addVideoProps,
    imageActions,
    videoActions,
    thumbnailSelector,
    mediaSelector,
    mediaLoadingSelector,
    uploadImage,
    showAddVideoDialog,
    loadMedia,
    getEntityName,
    getEntity,
    onHide
}: EntityMediaDialogProps<T>) => {
    
    const entity = useAppSelector(getEntity);
    const displayName = useAppSelector(getEntityName);
    
    return <Dialog
        onHide={onHide}
        visible={isValue(entity)}
        closable={true}
        draggable={false}
        contentClassName="entity-media-dialog"
        header={`Edit Media: ${displayName}`}
    >
        {
            isValue(entity) &&
            <div>
                <AddVideoDialog
                    {...addVideoProps}
                />
                <EntityMediaEditor
                    entityId={entity.id}
                    thumbnailSelector={thumbnailSelector}
                    imageActions={imageActions}
                    videoActions={videoActions}
                    mediaSelector={mediaSelector}
                    uploadImage={uploadImage}
                    showAddVideoDialog={showAddVideoDialog}
                    loadMedia={loadMedia}
                    mediaLoadingSelector={mediaLoadingSelector}
                />
            </div>

        }
    </Dialog>
}