import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { isValue } from "../../../../utils/valueHelper";
import { smallDeviceQuery } from "../../../responsiveconfiguration";
import { loadVendorBanner } from "../store/featureActions/loadVendorBanner";
import './vendorBanner.css';
import { getVendorBanner, getVendorBannerLoadingState } from "../store/selectors";
import { RootState } from "../../../../store/rootReducer";



export interface VendorBannerProps {
    vendorUrlName: string | undefined,
    menuUrlName: string | undefined,
    primaryColorSelector: (state: RootState) => string,
    secondardColorSelector: (state: RootState) => string,
    companyDisplayNameSelector: (state: RootState) => string
}
 
export const VendorBanner: React.FC<VendorBannerProps> = ({ vendorUrlName, menuUrlName, primaryColorSelector, secondardColorSelector, companyDisplayNameSelector }) => {

    const dispatch = useDispatch();
    const vendorBanner = useAppSelector(getVendorBanner);
    const vendorBannerLoadingState = useAppSelector(getVendorBannerLoadingState);
    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    const companyDisplayName = useAppSelector(companyDisplayNameSelector);
    const primaryColor = useAppSelector(primaryColorSelector);
    const secondaryColor = useAppSelector(secondardColorSelector);

    useMountEffect(() => {
        if (isValue(vendorUrlName) && isValue(menuUrlName)) {
            dispatch(loadVendorBanner({ vendorUrlName: vendorUrlName, menuUrlName: menuUrlName }));
        }
    });

    return <OverlayWaitDisplay
        isWaiting={vendorBannerLoadingState.isExecuting || !!!vendorBannerLoadingState.hasExecuted}
        overlayOpacity={1}
    >
        <div className={clsx({
            "vendor-image-banner-container-small": isSmallDevice,
            "vendor-image-banner-container-large": !isSmallDevice
        })}>{
                isValue(vendorBanner) &&
                <img
                    className="vendor-image-banner"
                    src={vendorBanner}
                    alt="vendor logo"
                    style={{
                        display: !isValue(vendorBanner) ? "none" : undefined
                    }}
                />
            }

            {
                !isValue(vendorBanner) &&
                <div
                    className="p-d-flex p-ai-center vendor-banner-company-name"
                    style={{
                        height: "100%",
                        backgroundColor: primaryColor,
                        color: secondaryColor,
                        fontSize: isSmallDevice ? "26px" : "50px"
                    }}
                >
                    {companyDisplayName}
                </div>
            }

        </div>
    </OverlayWaitDisplay>
}