import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";

export interface InfoPanelProps {
    title?: string | null;
    value?: string | null;
}

export const InfoPanel: React.FC<InfoPanelProps> = ({ title, value }) => {

    return <FlexColumnContainer className="marketing-view-info-panel">
        <div className="marketing-view-info-panel-title">
            {title}
        </div>
        <div className="marketing-view-info-panel-value">
            {value}
        </div>
    </FlexColumnContainer>
} 