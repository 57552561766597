import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { PurchaseOrder } from "../../sharedTypes";

export const sliceName: string = "buyer-orders";

export const actionFactory = new ActionFactory<BuyerOrdersState>(sliceName);

export interface BuyerOrdersState {
    loadingState: ActionStatus;  
    holdRequests: PurchaseOrder[];      
}