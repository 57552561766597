import clsx from "clsx";
import { Field, FieldProps, FormikContextType, useFormikContext } from "formik";
import { ColorPicker } from "primereact/colorpicker";
import { CSSProperties } from 'react';
import { getStringOrNullValue, PropertiesOfType, StringOrNullKeyOf } from '../../utils/typeHelpers';

export interface ColorFieldProps<T extends PropertiesOfType<T, string | null | undefined>> {
    defaultLabel: string;
    fieldName: keyof T & string & StringOrNullKeyOf<T>;
    containerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    disabled?: boolean;
    submitOnChange?: boolean;
    defaultColor?: string;
}

export const ColorField = <T extends PropertiesOfType<T, string | null | undefined>,>({

    defaultLabel,
    fieldName,
    labelStyle,
    containerStyle,
    inputStyle,
    disabled,
    defaultColor,
    submitOnChange = false
}: ColorFieldProps<T>) => {

    const formikProps: FormikContextType<T> = useFormikContext<T>();
    const labelText = defaultLabel;

    return <div style={containerStyle}>

        <label style={labelStyle} htmlFor={fieldName} >{labelText}</label>
        <Field
            name={fieldName}
        >
            {
                (props: FieldProps<T>) =>
                    <ColorPicker
                        disabled={disabled}
                        style={inputStyle}
                        name={fieldName}
                        className={clsx({ "p-invalid": !!formikProps.errors[fieldName] })}
                        value={getStringOrNullValue(formikProps.values, fieldName) ?? defaultColor ?? "#FFFFFF"}
                        onChange={(event) => {
                            if (typeof(event.value) === "string") {
                                formikProps.setFieldValue(props.field.name, event.value);
                                if (submitOnChange) {
                                    formikProps.submitForm();
                                }                            
                            }
                            else {
                                throw new Error("Color selector failed to convert selected value to string");
                            }                            
                        }}
                    />
            }
        </Field>
        <div>
            <small>{formikProps.errors[fieldName]}</small>
        </div>
    </div>
}
