import { confirmPopup } from "primereact/confirmpopup";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { CSSProperties } from "react";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { formatToDateAndTimeString } from "../../../../utils/datetimeHelper";
import { isTerminalOrderStatus, OrderStatus, OrderStatusOptions } from "../../common/types";
import { updateSalesOrder } from "../store/featureActions/updateSalesOrder";
import { getCustomerName } from "../store/selectors/customer";
import { getOrder } from "../store/selectors/order";

export const OrderDetails: React.FC = () => {

    const dispatch = useDispatch();
    const order = useAppSelector(getOrder);
    const customerName = useAppSelector(getCustomerName);

    const labelStyle: CSSProperties = {
        // display: "inline-block",
        width: "120px"
    }

    const valueStyle: CSSProperties = {
        border: "1px solid lightgrey",
        borderRadius: "5px",
        padding: "10px",
        maxWidth: "90vw",
        width: "400px"
    }

    const onStatusChanged = (e: DropdownChangeParams) => {
        const selectedOption: OrderStatus = e.value;

        if (isTerminalOrderStatus(selectedOption)) {

            confirmPopup({
                target: document.getElementById("order-status-selector") ?? undefined,
                message: <FlexColumnContainer gap="5px" padding="5px">
                    <div>{`Changing an order to the "${selectedOption}" status is permanent.`}</div>
                    <div>Are you sure you want to proceed?</div>
                </FlexColumnContainer>,
                acceptLabel: `Change order status to ${selectedOption}`,
                rejectLabel: `Leave order status as ${order?.status}`,
                icon: 'pi pi-exclamation-triangle',
                accept: () => dispatch(updateSalesOrder({ status: e.value }))
            });
        }
        else {
            dispatch(updateSalesOrder({ status: e.value }));
        }
    }

    return <FlexColumnContainer gap="20px">
        <FlexRowContainer centerContent flexWrap="wrap" gap="5px">

            <div style={labelStyle}>Customer:</div>
            <div style={valueStyle}>{customerName}</div>

        </FlexRowContainer>

        <FlexRowContainer centerContent flexWrap="wrap" gap="5px">

            <div style={labelStyle}>Order Entered:</div>
            <div style={valueStyle}>{formatToDateAndTimeString(order?.createdUtc)}</div>

        </FlexRowContainer>

        <FlexRowContainer centerContent flexWrap="wrap" gap="5px">

            <div style={labelStyle}>Status:</div>
            <Dropdown
                id="order-status-selector"
                value={order?.status}
                options={OrderStatusOptions}
                onChange={onStatusChanged}
                style={{ width: "300px" }}
                disabled={isTerminalOrderStatus(order?.status)}
            />

        </FlexRowContainer>

        <FlexRowContainer centerContent flexWrap="wrap" gap="5px">

            <div style={labelStyle}>Status Date:</div>
            <div style={valueStyle}>{formatToDateAndTimeString(order?.modifiedUtc)}</div>

        </FlexRowContainer>
    </FlexColumnContainer>
}   