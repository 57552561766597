import { useDispatch } from "react-redux";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { isValue } from "../../../../utils/valueHelper";
import { loadSliceData } from "../store/featureActions/loadSliceData";
import { resetState } from "../store/slice";
import { CategoryList } from "./categoryList";
import { MenuSectionList } from "./menuSectionList";

export interface MenuLayoutProps {
    menuId: number | undefined
};

export const MenuLayout: React.FC<MenuLayoutProps> = ({ menuId }) => {

    const dispatch = useDispatch();

    useMountEffect(() => {
        if (isValue(menuId)) {
            dispatch(loadSliceData({ menuId: menuId }));
        }
        return () => {
            dispatch(resetState());
        }
    });

    return <FlexRowContainer>
        <MenuSectionList menuId={menuId} />
        <CategoryList menuId={menuId} />
    </FlexRowContainer>
}