import { IntegerId } from "../features/commonModels/integerId";

export type IdentityMap<TValue> = {
    [key: string]: TValue;
    [key: number]: TValue;
}

export const createIdentityMap = <TValue>(values: TValue[], identityFn: (value: TValue) => string | number) : IdentityMap<TValue> => {
    const idMap: IdentityMap<TValue> = {};
    values.forEach(value => {        
        idMap[identityFn(value)] = value;
    });    
    return idMap;
}


export const createIntegerIdMap = <TValue extends IntegerId>(values: TValue[]) : { [key: number]: TValue } => {
    const idMap: { [key: number]: TValue } = {};
    values.forEach(value => {        
        idMap[value.id] = value;
    });    
    return idMap;
}

export const createValueMap = <TEntity, TValue>(
    values: TEntity[], 
    identityFn: (value: TEntity, index: number) => string | number,
    valueFn: (value: TEntity, index: number) => TValue) : IdentityMap<TValue> => {

    const idMap: IdentityMap<TValue> = {};

    values.forEach((entity, index) => {        
        idMap[identityFn(entity, index)] = valueFn(entity, index);
    });    

    return idMap;
}

export const identityFn = <T>(value: T) : T => value;

export const mapToId = <T extends IntegerId>(entity: T) : number => entity.id;