import { Card } from "primereact/card";
import { FavoritesTable } from "./favoritesTable";

export const LargeDeviceFavoritesView: React.FC = () => {

    return <Card
            className="favorite-menus-card"
            title="My Menus"            
        >
            <FavoritesTable />

        </Card>    
}