import { Column, ColumnProps } from "primereact/column";
import { DataTable, DataTableSelectionChangeParams } from "primereact/datatable";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { createFilteredBooleanColumnProps, createInitialInactiveFilterState } from "../../../../utils/table/booleanColumn";
import { useInitialFilterState } from "../../../../utils/table/filters/initialFilterStateFunctions";
import { createDateColumnProps } from "../../../../utils/table/dateColumn";
import { createTextColumnProps } from "../../../../utils/table/stringColumn";
import { isValue } from "../../../../utils/valueHelper";
import { QualityMetricKind } from "../../models/metricKind";
import { selectQualityMetricKind } from "../store/featureActions/selectQualityMetricKind";
import { getMetricKinds } from "../store/selectors/data";
import { getSelectedMetricKind } from "../store/selectors/editing";

export const QualityMetricKindList: React.FC = () => {

    const dispatch = useDispatch();
    const metricKinds = useAppSelector(getMetricKinds);
    const selectedMetricKind = useAppSelector(getSelectedMetricKind);
    const filterState = useInitialFilterState({
        filterMeta: {
            'inactive': createInitialInactiveFilterState()
        }}
    );

    const columns: ColumnProps[] = [
        createTextColumnProps<QualityMetricKind>({ header: "Name", field: "name", sortable: true, style: { minWidth: 150 } }),
        createDateColumnProps<QualityMetricKind>({ header: "Published", field: "publishedDate", style: { width: 120, maxWidth: 120 } }),
        createFilteredBooleanColumnProps<QualityMetricKind>({
            header: "Inactive",
            field: "inactive",
            disabledFn: () => true,
            style: { width: 140, maxWidth: 140 },
            falseLabel: "Active",
            trueLabel: "Inactive"
        })
    ];

    const onSelectionChange = (e: DataTableSelectionChangeParams) => {
        const selectedEntities: QualityMetricKind | null = e.value;

        if (isValue(selectedEntities)) {
            dispatch(selectQualityMetricKind(selectedEntities));
        }
        else {
            dispatch(selectQualityMetricKind(null));
        }
    }

     

    return <FlexColumnContainer className="quality-metric-kind-list">
        <DataTable
            size="small"
            dataKey="id"
            value={metricKinds}
            {...filterState}            
            scrollable
            //scrollDirection="both"
            scrollHeight='flex'

            paginator
            rows={20}
            rowsPerPageOptions={[10, 20, 50]}
            alwaysShowPaginator={false}

            selectionMode="single"
            onSelectionChange={onSelectionChange}
            selection={selectedMetricKind}
        >
            {columns.map(c => <Column key={c.columnKey ?? c.field} {...c} />)}
        </DataTable>
    </FlexColumnContainer>
} 