import { actionFactory } from "../types";

export const {

    addToFavorites,
    addToFavoritesReducer,
    addToFavoritesSagaFactory

} = actionFactory
    .withMappedName("addToFavorites")
    .createAsyncAction<{ menuId: number }, Response>({
        actionStatusSelector: state => state.isCurrentUserFavoriteActionState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.userProfile.addMenuToFavorites(arg.menuId);
        },
        onFulfilled: (state, _action) => {
            state.isCurrentUserFavorite = true;
        },
        debounceInMilliseconds: 50
    });