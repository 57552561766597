import { Card } from "primereact/card";
import { useDispatch } from "react-redux";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { loadAlerts } from "../store/featureActions/loadAlerts";
import { getActiveCustomerAlertIds, getActiveUserAlertIds, getLoadingState } from "../store/selectors/alerts";
import { AlertCard } from "./alertCard";

export const ManageAlerts: React.FC = () => {


    const dispatch = useDispatch();
    const userAlertIds = useAppSelector(getActiveUserAlertIds);
    const companyAlertIds = useAppSelector(getActiveCustomerAlertIds);
    const loadingState = useAppSelector(getLoadingState);

    useMountEffect(() => {
        dispatch(loadAlerts());
    });

    return <OverlayWaitDisplay
        isWaiting={loadingState.isExecuting}
    >
        <Card className="full-height-content-panel" title="Alerts">
            {userAlertIds.map(userAlertId => <AlertCard key={`useralert-${userAlertId}`} alertId={userAlertId} isUser={true} />)}
            {companyAlertIds.map(companyAlertId => <AlertCard key={`companyalert-${companyAlertId}`} alertId={companyAlertId} isUser={false} />)}
        </Card>
    </OverlayWaitDisplay>
}