import { ErrorDisplayType } from "../../../../../store/factory/sagaWithProgress/types";
import { ImageDto } from "../../../../commonModels/imageTypes";
import { actionFactory } from "../types";

export const {

    loadDefaultThumbnail,
    loadDefaultThumbnailReducer,
    loadDefaultThumbnailSagaFactory

} = actionFactory
    .withMappedName("loadDefaultThumbnail")
    .createAsyncAction<{ vendorUrlName: string, menuUrlName: string }, ImageDto>({
        actionStatusSelector: state => state.inventoryImageLoadState,
        asyncCall: (arg, context) => context.marketplaceApiClient.liveMenu.getDefaultThumbnail(arg.vendorUrlName, arg.menuUrlName),
        onFulfilled: (state, action) => {
            state.defaultThumbnail = action.payload.result;
        },
        errorDisplay: ErrorDisplayType.None
    });