import ApiClient from "../../apiCommunication/apiClient";
import { CompanyAlert, UserAlert } from "../appAlerts/store/types";

export default class Alerts {

    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    getUserAlerts = (): Promise<UserAlert[]> => {
        return this
            .apiClient
            .executeGet<UserAlert[]>(`Alerts/user`);
    };

    dismissUserAlert = (id: number): Promise<Response> => {
        return this
            .apiClient
            .executePost(`Alerts/user/dismiss/${id}`);
    };

    getCompanyAlerts = (): Promise<CompanyAlert[]> => {
        return this
            .apiClient
            .executeGet<CompanyAlert[]>(`Alerts/company`);
    };

    dismissCompanyAlert = (id: number): Promise<Response> => {
        return this
            .apiClient
            .executePost(`Alerts/company/dismiss/${id}`);
    };
    
    
}