import { VendorMenu } from "../../../endUser/store/types";
import { actionFactory } from "../types";

export interface LoadLiveMenuParams {
    vendorName: string;
    menuName: string;
}

export const {

    loadLiveMenu,
    loadLiveMenuReducer,
    loadLiveMenuSagaFactory

} = actionFactory
    .withMappedName("loadLiveMenu")
    .createAsyncAction<LoadLiveMenuParams, VendorMenu>({
        actionStatusSelector: state => state.vendorMenuLoadingState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.liveMenu.getLiveMenu(arg.vendorName, arg.menuName);
        },
        onFulfilled: (state, action) => {
            state.vendorMenu = action.payload.result;
        },
        debounceInMilliseconds: 250
    });