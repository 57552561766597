import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { addQualityGradeKindSagaFactory } from "../featureActions/addQualityGradeKind";
import { deleteQualityGradeValueSagaFactory } from "../featureActions/deleteQualityGradeValue";
import { loadSliceDataSagaFactory } from "../featureActions/loadSliceData";
import { publishQualityGradeKindSagaFactory } from "../featureActions/publishQualityGradeKind";
import { resequenceGradeValueSagaFactory } from "../featureActions/resequenceGradeValue";
import { upsertQualityGradeKindSagaFactory } from "../featureActions/upsertQualityGradeKind";
import { upsertQualityGradeValueSagaFactory } from "../featureActions/upsertQualityGradeValue";

// Register this method in the root saga
export function createQualityGradeKindSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        loadSliceDataSagaFactory(context),
        addQualityGradeKindSagaFactory(context),
        upsertQualityGradeKindSagaFactory(context),
        upsertQualityGradeValueSagaFactory(context),
        resequenceGradeValueSagaFactory(context),
        deleteQualityGradeValueSagaFactory(context),
        publishQualityGradeKindSagaFactory(context)
    ]
}