import { RootState } from "../../../../../store/rootReducer";
import { companyAdapter } from "../state";

export const getLoadingState = (state: RootState) => state.nodeAdminProvisioning.loadingState;

const companySelectors = companyAdapter.getSelectors((state: RootState) => state.nodeAdminProvisioning.data);

export const getCompanies = companySelectors.selectAll;

export const getCompanyEditState = (state: RootState) => state.nodeAdminProvisioning.addCompanyState;
export const getCompanyEditValue = (state: RootState) => state.nodeAdminProvisioning.addCompanyState.editedValue;
export const getCompanyEditVisible = (state: RootState) => state.nodeAdminProvisioning.addCompanyState.isVisible;