import React, { useState } from 'react';
import contactUsLogo from '../../assets/contact.svg';
import { openContactNodePage } from '../../utils/externalPageHelper';
import { MarketingCard } from './marketingCard';

export const ContactNodeCard: React.FC = () => {

    const [hoveredCard, setHoveredCard] = useState<string | null>(null);    

    const onMouseOver = (e: React.MouseEvent<HTMLDivElement>) => {
        setHoveredCard(e.currentTarget.id);
    }

    const onMouseLeave = (_: React.MouseEvent<HTMLDivElement>) => {
        setHoveredCard(null);
    }

    return <div onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} id="ContactCard" >
            <MarketingCard
                image={contactUsLogo}
                title="Distributor Signup"
                actionTitle="Sign up"
                isSelected={hoveredCard !== null && hoveredCard === "ContactCard"}
                onActionClick={openContactNodePage}
            />
        </div>
}