import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { addQualityMetricKindBegin } from "../store/featureActions/addQualityMetricKind";

export const CommandBar: React.FC = () => {

    const dispatch = useDispatch();

    const onAddNewMetricKind = () => {
        dispatch(addQualityMetricKindBegin());
    }

    return <FlexRowContainer>
        <Button onClick={onAddNewMetricKind}>Add Metric Kind</Button>
    </FlexRowContainer>

} 