import { actionFactory } from "../types";

export interface LoadIsFavoriteParams {
    vendorName: string;
    menuName: string;
}

export const {

    loadIsFavorite,
    loadIsFavoriteReducer,
    loadIsFavoriteSagaFactory

} = actionFactory
    .withMappedName("loadIsFavorite")
    .createAsyncAction<LoadIsFavoriteParams, boolean>({
        actionStatusSelector: state => state.vendorMenuLoadingState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.liveMenu.getLiveMenuIsFavorite(arg.vendorName, arg.menuName);
        },
        onFulfilled: (state, action) => {
            state.isCurrentUserFavorite = action.payload.result;
        },
        debounceInMilliseconds: 250
    });