import { EntityState } from "@reduxjs/toolkit";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";

export const sliceName: string = "corporate-entity";

export const actionFactory = new ActionFactory<CorporateEntityState>(sliceName);

export interface NewCorporateEntityRequest {        
    name: string;
    metrcApiKey: string;
    inactive: boolean;
}

export interface CorporateEntity {
    id: number;
    name: string;
    metrcApiKey: string;
    inactive: boolean;
    currentLicenseCount: number;
}

export type EditCorporateEntityRequest = {
    id?: number;
    metrcApiKey?: string;
    name: string;
    inactive: boolean;    
};

export function isSavableCorporateEntity(value: CorporateEntity | EditCorporateEntityRequest) : value is CorporateEntity {
    return (
        (value as CorporateEntity).name !== undefined &&
        (value as CorporateEntity).inactive !== undefined &&
        ((value as CorporateEntity).metrcApiKey !== undefined || (value as CorporateEntity).id === undefined)
    );
}

export function isPersistedCorporateEntity(value: CorporateEntity | NewCorporateEntityRequest) : value is CorporateEntity {
    return (value as CorporateEntity).id !== undefined;
}

export interface BooleanFilter {
    name: string,
    value: boolean
}

export interface CorporateEntityFilter {
    inactiveValues: boolean[]
}

export interface CorporateEntityState {
    loadingState: ActionStatus;  
    corporateEntities: EntityState<CorporateEntity>;   
    entityEditDialogVisible: boolean;   
    entityEditValue: EditCorporateEntityRequest | null;    
    corporateEntityFilter: CorporateEntityFilter;

}