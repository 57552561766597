import { isValue } from "../../../../../../utils/valueHelper";
import { editQualityAssessmentBegin } from "../../../../../qualityAssessments/assessmentEditor/store/featureActions/editQualityAssessment";
import { inventoryAdapter } from "../../state";
import { actionFactory, Inventory, QualityAssessmentAssignment } from "../../types";
import { updateInventoryAssignmentMaps } from "../updateInventoryAssignmentMaps";

type InventoryIdParam = { inventoryId: number };
type CreateQualityAssessmentArg = void | InventoryIdParam;

const isCreateQualityAssessmentArg = (value: CreateQualityAssessmentArg): value is InventoryIdParam => {
    return isValue((value as { inventoryId: number })?.inventoryId)
}

export const {

    createQualityAssessmentBegin,
    createQualityAssessmentCancel,
    createQualityAssessmentComplete,
    createQualityAssessmentReducers,
    createQualityAssessmentSagaFactory

} = actionFactory
    .withMappedName("createQualityAssessment")
    .createModal<QualityAssessmentAssignment, CreateQualityAssessmentArg, Inventory[]>({
        modelFactory: (arg, state) => {
            if (state.qualityAssessmentTemplates.length === 0) throw new Error("No quality templates were found.");

            if (isCreateQualityAssessmentArg(arg)) {
                return {
                    inventoryIds: [arg.inventoryId],
                    qualityAssessmentTemplateId: state.qualityAssessmentTemplates[0].id
                };
            }
            else {
                if (state.selectedInventoryIds.length === 0) throw new Error("At least inventory record must be selected.");
                return {
                    inventoryIds: state.selectedInventoryIds,
                    qualityAssessmentTemplateId: state.qualityAssessmentTemplates[0].id
                };
            }
        },
        selector: state => state.assignQualityAssessmentState,
        onCompleteEdit: (value, context) => {
            return context.marketplaceApiClient.inventoryManagement.createQualityAssessment(value);
        },
        onFulfilled: (state, action) => {
            inventoryAdapter.updateMany(
                state.inventory,
                action.result.map(inv => ({
                    id: inv.id,
                    changes: {
                        qualityAssessmentId: action.arg.qualityAssessmentTemplateId
                    }
                })));
        },
        successCallback: (_arg, result, context) => {
            context.dispatch(updateInventoryAssignmentMaps());

            if (result.length < 1) throw new Error("The assessment was not created. Refresh and try again.");
            const firstInv = result[0];
            if (!isValue(firstInv.qualityAssessmentId)) throw new Error("The assessment was not created. Refresh and try again.");

            context.dispatch(editQualityAssessmentBegin({ 
                qualityAssessmentId: firstInv.qualityAssessmentId,
                loadInventoryOnComplete: true,
                loadInventoryIds: result.map(inv => inv.id)
            }));
        }
    });