import clsx from "clsx";
import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { createIntegerIdMap } from "../../../../utils/identity";
import { createCalculatedValueColumnProps } from "../../../../utils/table/calculatedValueColumn";
import { createDateColumnProps } from "../../../../utils/table/dateColumn";
import { createDropDownColumnProps } from "../../../../utils/table/dropDownColumn";
import { createTextColumnProps } from "../../../../utils/table/stringColumn";
import { isValue } from "../../../../utils/valueHelper";
import { QualityAssessmentValue } from "../../models/qualityAssessmentValue";
import { deleteAssessmentValue } from "../store/featureActions/deleteAssessmentValue";
import { saveAssessmentValue } from "../store/featureActions/saveAssessmentValue";
import { getQualityMetricValuesByKind } from "../store/selectors/data";
import { getAssessmentValuesForAssessment, getIsAssessmentComplete, getKindToTemplateMetricMapForAssesment, getMetricKindsForAssessment } from "../store/selectors/editing";

export interface AssessmentValueTableProps {
    assessmentId: number
}

export const AssessmentValueTable: React.FC<AssessmentValueTableProps> = ({ assessmentId }) => {

    const dispatch = useDispatch();
    const metricKinds = useAppSelector(state => getMetricKindsForAssessment(state, assessmentId));
    const metricKindMap = createIntegerIdMap(metricKinds);
    const kindToValuesMap = useAppSelector(getQualityMetricValuesByKind);
    const assessmentValues = useAppSelector(state => getAssessmentValuesForAssessment(state, assessmentId));
    const isComplete = useAppSelector(state => getIsAssessmentComplete(state, assessmentId));
    const kindToTemplateMetricMap = useAppSelector(state => getKindToTemplateMetricMapForAssesment(state, assessmentId));

    const onValueAssigned = (entryValue: QualityAssessmentValue, metricValueId: number | null) => {
        if (entryValue.id <= 0) {
            if (!isValue(metricValueId)) throw new Error("Cannot clear assessment value when no value exists");

            dispatch(saveAssessmentValue({
                ...entryValue,
                id: 0,
                metricValueId: metricValueId
            }));
        }
        else {
            if (isValue(metricValueId)) {
                dispatch(saveAssessmentValue({
                    ...entryValue,
                    metricValueId: metricValueId
                }));
            }
            else {
                dispatch(deleteAssessmentValue(entryValue));
            }
        }
    }

    const columns: ColumnProps[] = [
        createCalculatedValueColumnProps<QualityAssessmentValue>({
            header: "Metric",
            columnKey: "metric-kind-name",
            style: { minWidth: 180, maxWidth: 300 },
            sortable: false,
            getValueFn: (value => {
                return metricKindMap[value.metricKindId]?.name ?? "Unknown";
            })
        }),
        createDropDownColumnProps<QualityAssessmentValue, number>({
            style: { minWidth: 180, maxWidth: 300 },
            availableValues: (value) => {
                const kinds = kindToValuesMap[value.metricKindId] ?? [];
                return kinds.map(value => ({ label: value.name, value: value.id }))
            },
            field: "metricValueId",
            header: "Value",
            getValue: value => value.metricValueId,
            inputClassName: value => clsx("quality-assessment-editor-input", {
                "p-invalid": value.id <= 0 && kindToTemplateMetricMap[value.metricKindId]?.isRequired
            }),
            inputOptions: {
                disabled: isComplete,
                showClear: true
            },
            onSubmit: onValueAssigned
        }),
        createTextColumnProps<QualityAssessmentValue>({
            field: "measuredBy",
            header: "Logged By",
            style: { width: 240, maxWidth: 240 }
        }),
        createDateColumnProps<QualityAssessmentValue>({
            field: "measuredOn",
            header: "Logged",
            style: { width: 140, maxWidth: 140 }
        })
    ];

    return <FlexColumnContainer className="quality-assessment-editor-entry-list">
        <DataTable
            size="small"
            dataKey="id"
            value={assessmentValues}
            scrollable
            scrollHeight='flex'
            paginator
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            alwaysShowPaginator={false}
        >
            {columns.map(c => <Column key={c.columnKey ?? c.field} {...c} />)}
        </DataTable>

    </FlexColumnContainer>
}