import { DateTime } from "luxon";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { NotesList } from "../../../../components/notes/notesList";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { getTenantId } from "../../../authentication/store/selectors/tenant";
import { EditMarketingDetails } from "../../general/ui/editMarketingDetails";
import { editInventoryCancel, editInventoryComplete } from "../store/featureActions/InventoryDetails/editInventory";
import { deleteInventoryNote } from "../store/featureActions/notes/deleteInventoryNote";
import { loadInventoryNotes } from "../store/featureActions/notes/loadInventoryNotes";
import { upsertInventoryNote } from "../store/featureActions/notes/upsertInventoryNote";
import { getEditedInventoryNoteIds, getEditInventoryState, getEditInventoryValue, getEditInventoryVisible, getInventoryNoteById, getInventoryNoteSaveStatus } from "../store/selectors/editedInventory";
import { getQualityGradeKinds, getQualityGradeValues } from "../store/selectors/qualityGrade";
import { Inventory, InventoryNote, InventoryType } from "../store/types";
import { getInventoryLicenses } from "../store/selectors/inventoryLicenses";
import { getUnitOfMeasures } from "../store/selectors/unitOfMeasures";
import { inventoryValidations } from "../validations/inventoryValidations";

export const EditInventoryDialog: React.FC = () => {

    const dispatch = useDispatch();
    const editedInventory = useAppSelector(getEditInventoryValue);
    const qualityGrades = useAppSelector(getQualityGradeValues);
    const qualityGradeKinds = useAppSelector(getQualityGradeKinds);
    const tenantId = useAppSelector(getTenantId);
    const editedInventoryId = editedInventory?.id ?? -1;
    const licenses = useAppSelector(getInventoryLicenses);
    const unitOfMeasures = useAppSelector(getUnitOfMeasures);

    useEffect(() => {
        if (editedInventoryId <= 0) return;
        dispatch(loadInventoryNotes({ id: editedInventoryId }));

    }, [editedInventoryId, dispatch]);

    const onCancel = () => { dispatch(editInventoryCancel()); };
    const onSave = (value: Inventory) => { dispatch(editInventoryComplete(value)); };

    const onSaveNote = (note: InventoryNote) => {
        dispatch(upsertInventoryNote(note));
    }

    const onSaveNewNote = (notes: string) => {
        const newNote: InventoryNote = {
            createdBy: "",
            createdUtc: DateTime.utc(),
            modifiedUtc: DateTime.utc(),
            id: 0,
            notes: notes,
            inventoryId: editedInventoryId,
            tenantId: tenantId ?? ""
        };

        dispatch(upsertInventoryNote(newNote));
    }
    const onDeleteNote = (note: InventoryNote) => {
        dispatch(deleteInventoryNote(note));
    }

    return <FormikModalEditor<Inventory>
        onCancel={onCancel}
        onSave={onSave}
        statusSelector={getEditInventoryState}
        valueSelector={getEditInventoryValue}
        visibleSelector={getEditInventoryVisible}
        position="top"
        title={editedInventory?.inventoryType === InventoryType.Custom ? `Edit Details for package: ${editedInventory?.metrcPackageLabel}` : `Edit Details for: ${editedInventory?.marketingName}`}
    >



        <TabView>

            <TabPanel header="Details">
                {
                    isValue(editedInventory) &&
                    <EditMarketingDetails
                        marketingDetails={editedInventory}
                        subTitle="Editing these values will override item configuration"
                        showPrice={true}
                        qualityGrades={qualityGrades}
                        qualityGradeKinds={qualityGradeKinds}
                        validationSchema={inventoryValidations}
                        licenses={licenses}
                        unitOfMeasures={unitOfMeasures}
                        showMetricData={editedInventory ? editedInventory.inventoryType === InventoryType.Metric : false}
                    />
                }
            </TabPanel>

            <TabPanel header="Notes">

                <NotesList<InventoryNote>
                    onDeleteNote={onDeleteNote}
                    onSaveNewNote={onSaveNewNote}
                    onUpdateNote={onSaveNote}
                    noteIdsSelector={getEditedInventoryNoteIds}
                    getByIdSelector={getInventoryNoteById}
                    actionStatusSelector={getInventoryNoteSaveStatus}
                    newNoteInputClassName="edit-product-notes-input"
                />

            </TabPanel>

        </TabView>
    </FormikModalEditor>
}