import { ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../../store/configureStore";
import { createActionColumnProps } from "../../../../utils/table/actionColumn";
import { mapColumns } from "../../../../utils/table/mapPropsToColumns";
import { createNumberColumnProps } from "../../../../utils/table/numberColumn";
import { createTextColumnProps } from "../../../../utils/table/stringColumn";
import { isValue } from "../../../../utils/valueHelper";
import { orderEditorRoute } from "../../../navigation/routes";
import { viewOrdersForInventory } from "../store/featureActions/viewOrdersForInventory";
import { getOrdersForViewedInventory, getViewOrderedInventoryId } from "../store/selectors/orderedQuantities";
import { OrderedInventoryDetails } from "../store/types";

export const ViewOrdersDialog: React.FC = () => {

    const dispatch = useDispatch();
    const inventoryId = useAppSelector(getViewOrderedInventoryId);
    const orderItems = useAppSelector(getOrdersForViewedInventory);
    const history = useHistory();


    const onHideDialog = () => {
        dispatch(viewOrdersForInventory(null));
    };

    const navigateToOrder = (orderDetails: OrderedInventoryDetails) => {
        history.push(orderEditorRoute.createPath(orderDetails.salesOrderId));
    }

    const columns: ColumnProps[] = [
        createTextColumnProps<OrderedInventoryDetails>({ field: "customerName", header: "Customer", style: { width: "250px" }, filter: true }),
        createActionColumnProps<OrderedInventoryDetails>({ 
            columnKey: "orderNumber", 
            header: "Order #", 
            style: { width: "200px" }, 
            filter: true,  
            buttonClassName: "p-button-text",
            actionName: (orderDetails) => orderDetails.orderNumber,
            actionHandler: navigateToOrder
        }),
        createNumberColumnProps<OrderedInventoryDetails>({ field: "quantity", header: "Quantity", style: { width: "120px" }, filter: true, dataType: "numeric", filterType: "numeric" }),
        createTextColumnProps<OrderedInventoryDetails>({ field: "unitOfMeasure", header: "UOM", style: { width: "200px" }, filter: true }),
    ];

    return <Dialog
        onHide={onHideDialog}
        visible={isValue(inventoryId)}
        closable={true}
        draggable={false}
        header={"Order Information"}
        contentStyle={{ padding: "20px" }}
    >
        <DataTable
            dataKey="id"
            value={orderItems}
            size="small"
            scrollHeight='calc(100vh - 330px)'
            paginator
            alwaysShowPaginator={false}
            rows={20}
            rowsPerPageOptions={[10, 20, 50, 100]}            
        >
            {mapColumns(columns)}
        </DataTable>

    </Dialog>
}