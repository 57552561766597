import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FormikEditorClassStyles } from "../../../../components/formik/classNameAttributes";
import { DropDownField } from "../../../../components/formik/dropDownField";
import { StringField } from "../../../../components/formik/stringField";
import { useAppSelector } from "../../../../store/configureStore";
import { AssessmentTemplate } from "../../models/assessmentTemplate";
import { getPublishedQualityGradeKinds } from "../store/selectors/data";

export const AddTemplateEditor: React.FC = () => {

    const gradeKinds = useAppSelector(getPublishedQualityGradeKinds);

    const fieldClassNames: FormikEditorClassStyles = {
        containerClassName: "add-template-field-container",
        inputClassName: "add-template-field-input",
        labelClassName: "add-template-field-label"
    }

    return <FlexColumnContainer className="add-quality-template-dialog">

        <StringField<AssessmentTemplate>
            fieldName="name"
            defaultLabel="Name"            
            {...fieldClassNames}
        />

        <DropDownField<AssessmentTemplate, number>
            availableValues={gradeKinds.map(gradeKind => ({ label: gradeKind.name, value: gradeKind.id }))}
            fieldName="gradeKindId"
            defaultLabel="Grade Kind"
            allowClear={false}
            {...fieldClassNames}
        />

    </FlexColumnContainer>

}