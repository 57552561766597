import { DateTime } from "luxon";
import { IntegerId } from "../../commonModels/integerId";

export interface AssessmentTemplate extends IntegerId {
    name: string;
    publishedDate: DateTime | null;
    inactive: boolean;
    gradeKindId: number;
}

export const isAssessmentTemplate = (value: AssessmentTemplate | Partial<AssessmentTemplate>) : value is AssessmentTemplate => {
    return value?.name !== undefined &&
    value?.publishedDate !== undefined &&
    value?.inactive !== undefined &&
    value?.gradeKindId !== undefined;
} 