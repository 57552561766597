import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, TermsOfServiceAdminState } from "../types";

export const cancelEditTermsOfService = actionFactory.createAppAction("cancelEditTermsOfService");

export function cancelEditTermsOfServiceReducers(reducerFactory: IReducerFactory<TermsOfServiceAdminState>): IReducerFactory<TermsOfServiceAdminState> {
    return reducerFactory
        .forAction(cancelEditTermsOfService)
        .addHandler((state, _) => {
            state.editedTermsOfServiceId = undefined;
        })
        .build();
}