import { Tooltip } from 'primereact/tooltip';
import clsx from "clsx";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { confirmPopup } from "primereact/confirmpopup";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import React from "react";
import { useDispatch } from "react-redux";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { PopUpMenuButton } from "../../../../components/popMenuButton";
import { useAppSelector } from "../../../../store/configureStore";
import { TypedMenuItem } from "../../../../utils/table/typedMenuItem";
import { isValue } from "../../../../utils/valueHelper";
import { ConstraintNodeKind } from "../../models/constraintNode";
import { addNewConstraint } from "../store/featureActions/addNewConstraint";
import { deleteTemplateConstraintNode } from "../store/featureActions/deleteTemplateConstraintNode";
import { upsertTemplateConstraintNode } from "../store/featureActions/upsertTemplateConstraintNode";
import { getConstraintChildCount, getConstraintNodeById, getRootConstraintNodeForGradeValue } from "../store/selectors/data";
import { getIsEditable } from "../store/selectors/editing";

export interface ConstraintFormProps {
    constraintNodeId: number;
    gradeValueId: number;
}

export const LogicBlockEditor: React.FC<ConstraintFormProps> = ({ constraintNodeId, gradeValueId }) => {

    const dispatch = useDispatch();
    const constraint = useAppSelector(state => getConstraintNodeById(state, constraintNodeId));
    const rootConstraint = useAppSelector(state => getRootConstraintNodeForGradeValue(state)(gradeValueId));
    const isEditable = useAppSelector(getIsEditable);
    const childCount = useAppSelector(state => getConstraintChildCount(state)(constraintNodeId));

    const logicOperatorOptions = [
        {
            label: "All conditions must be satisfied",
            value: ConstraintNodeKind.And
        },
        {
            label: "Any condition can be satisfied",
            value: ConstraintNodeKind.Or
        }
    ];

    const changeConstraintKind = (e: DropdownChangeParams) => {
        if (!isValue(constraint)) return;

        dispatch(upsertTemplateConstraintNode({ gradeValueId: gradeValueId, node: { ...constraint, kind: e.value } }));
    }

    const onAddLogicBlock = (nodeKind: ConstraintNodeKind) => {
        if (!isValue(constraint)) return;
        if (!isValue(rootConstraint)) return;

        dispatch(upsertTemplateConstraintNode({
            gradeValueId: gradeValueId,
            node: {
                comparison: null,
                kind: nodeKind,
                id: 0,
                metricKindId: null,
                metricValueIds: [],
                parentNodeId: constraint.id,
                rootNodeId: rootConstraint.id,
                children: []
            }
        }));
    }

    const onAddConstraint = () => {
        if (!isValue(constraint)) return;
        if (!isValue(rootConstraint)) return;
      
        dispatch(addNewConstraint({
            parentNodeId: constraint.id,
            rootNodeId: rootConstraint.id,
            gradeValueId: gradeValueId
        }));
    }

    const onDeleteConstraint = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!isValue(constraint)) return;

        confirmPopup({        
            target: e.currentTarget,
            message: 'Are you sure you want to delete this block?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => dispatch(deleteTemplateConstraintNode(constraint))            
        });
        
    }


    const menuItems: TypedMenuItem<{}>[] = [
        {
            command: onAddConstraint,
            label: "Add Rule"
        },
        {
            command: () => onAddLogicBlock(ConstraintNodeKind.And),
            label: "Add All Block"
        },
        {
            command: () => onAddLogicBlock(ConstraintNodeKind.Or),
            label: "Add Any Block"
        }
    ];


    return !isValue(constraint)
        ? <div>Logic Block Unavailable</div>
        : <FlexRowContainer className="logic-block-editor">

            <div className={clsx({
                "logic-block-icon-and": constraint.kind === ConstraintNodeKind.And,
                "logic-block-icon-or": constraint.kind === ConstraintNodeKind.Or
            })}></div>
       
            <Dropdown
                options={logicOperatorOptions}
                className="logic-block-editor-constraint-kind-selector"
                onChange={changeConstraintKind}
                value={constraint.kind}
                disabled={!isEditable}
            />

            <PopUpMenuButton<{}>
                id="logic-block-menu"
                value={{}}
                menuItems={menuItems}
                disabled={() => !isEditable}
            />

            <Button
                icon={PrimeIcons.TRASH}
                disabled={!isEditable}
                onClick={onDeleteConstraint}
            >
            </Button>

            {
                childCount === 0 &&
                <div>
                    <Tooltip 
                        target=".logic-block-error-icon" 
                        content="Logic blocks must contain at least one rule"
                        position='top'
                    />
                    <span className={clsx(PrimeIcons.EXCLAMATION_CIRCLE, "logic-block-error-icon")}></span>
                </div>           
            }

        </FlexRowContainer>
}