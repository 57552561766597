import clsx from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useAppSelector } from '../../../../store/configureStore';
import { createEnterKeyHandler } from '../../../../utils/keyboardEvents';
import { useMountEffect } from '../../../../utils/useEffectHelper';
import { isValue } from '../../../../utils/valueHelper';
import { smallDeviceQuery } from '../../../responsiveconfiguration';
import { performSearch } from '../store/featureActions/performSearch';
import { setSearchText } from '../store/featureActions/setSearchText';
import { getIsLoading } from '../store/selectors/loading';
import { getSearchRequest } from '../store/selectors/searchRequest';
import { resetState } from '../store/slice';
import './searchBar.css';

export const SearchBar: React.FC = () => {

    const dispatch = useDispatch();
    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    const searchRequest = useAppSelector(getSearchRequest);
    const isLoading = useAppSelector(getIsLoading);

    useMountEffect(() => {
        if (isValue(searchRequest)) {
            dispatch(performSearch());    
        }
        return () => {
            dispatch(resetState())
        }
    });
 
    const onSearch = () => {
        dispatch(performSearch());
    }

    const onSearchTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setSearchText(e.target.value));
    }

    const onSearchInputKeyUp = createEnterKeyHandler(onSearch);

    return <div className={clsx({ "search-bar-small": isSmallDevice, "search-bar": !isSmallDevice })}>
        <InputText 
            className={clsx({ "search-input-small": isSmallDevice, "search-input": !isSmallDevice })} 
            onChange={onSearchTextChanged}
            onKeyUp={onSearchInputKeyUp}
            value={searchRequest.searchText}
        />
        <Button icon={PrimeIcons.SEARCH} onClick={onSearch} disabled={!isValue(searchRequest.searchText) || isLoading} />
    </div> 
 

}