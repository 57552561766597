import { createEntityAdapter } from "@reduxjs/toolkit";
import { CreateInitialModalEditState } from "../../../../store/factory/modalEdit/modalEdit";
import { CreateActionStatusInitialState } from "../../../actionStatus/actionStatus";
import { TemplateConstraintNode } from "../../models/constraintNode";
import { QualityGradeValue } from "../../models/gradeValue";
import { QualityMetricKind } from "../../models/metricKind";
import { TemplateConstraint } from "../../models/templateConstraint";
import { TemplateMetric } from "../../models/templateMetric";
import { QualityTemplateEditorState, TreeNodeExpandedKeys } from './types';

export const templateConstraintAdapter = createEntityAdapter<TemplateConstraint>();
export const templateConstraintNodeAdapter = createEntityAdapter<TemplateConstraintNode>();
export const templateMetricAdapter = createEntityAdapter<TemplateMetric>();
export const metricKindAdapter = createEntityAdapter<QualityMetricKind>();
export const gradeValueAdapter = createEntityAdapter<QualityGradeValue>();
export const expandedTreeNodeAdapter = createEntityAdapter<TreeNodeExpandedKeys>({ selectId: item => item.gradeValueId });

export const createInitialState = (): QualityTemplateEditorState => {
    return {        
        loadingState: CreateActionStatusInitialState(),      
        template: null,
        gradeKinds: [],
        gradeValues: gradeValueAdapter.getInitialState(),
        metricKinds: metricKindAdapter.getInitialState(),
        metricValues: [],
        templateConstraints: templateConstraintAdapter.getInitialState(),
        constraintNodes: templateConstraintNodeAdapter.getInitialState(),        
        nextConstraintNodeId: -1,
        templateMetrics: templateMetricAdapter.getInitialState(),
        templateSaveState: CreateActionStatusInitialState(),
        templateMetricSaveState: CreateActionStatusInitialState(),
        selectMetricsState: CreateInitialModalEditState(),
        addTemplateConstraintState: CreateInitialModalEditState(),
        changeGradeKindState: CreateInitialModalEditState(),
        testAssessmentEntries: [],
        testResult: null,
        constraintTreeExpandedKeys: expandedTreeNodeAdapter.getInitialState()
    };
};

export const initialState: QualityTemplateEditorState = createInitialState();