import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { deleteOrderItemSagaFactory } from "../featureActions/deleteOrderItem";
import { editOrderItemSagaFactory } from "../featureActions/editOrderItem";
import { getOrderedQuantitiesSagaFactory } from "../featureActions/getOrderedQuantities";
import { loadSliceDataSagaFactory } from "../featureActions/loadSliceData";
import { searchInventorySagaFactory } from "../featureActions/searchInventory";
import { updateSalesOrderSagaFactory } from "../featureActions/updateSalesOrder";

// Register this method in the root saga
export function createOrderEditorSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [
        loadSliceDataSagaFactory(context),
        editOrderItemSagaFactory(context),
        updateSalesOrderSagaFactory(context),
        deleteOrderItemSagaFactory(context),
        searchInventorySagaFactory(context),
        getOrderedQuantitiesSagaFactory(context)
    ]
}