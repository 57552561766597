import { ErrorDisplayType } from "../../../../../store/factory/sagaWithProgress/types";
import { userTableSettingsAdapter } from "../state";
import { actionFactory } from "../types";
import { selectFirstTableSettings } from "./selectFirstTableSettings";

export const {

    deleteUserTableSettings,
    deleteUserTableSettingsReducer,
    deleteUserTableSettingsSagaFactory

} = actionFactory
    .withMappedName("deleteUserTableSettings")
    .createAsyncAction<{ viewId: string, id: number }, Response>({
        actionStatusSelector: state => state.commandBarActionState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.userTableSettings.deleteUserTableSettings(arg.id);
        },
        onFulfilled: (state, action) => {
            userTableSettingsAdapter.removeOne(state.userTableSettings, action.payload.arg.id);
        },
        successCallback: (arg, _result, context) => {
            context.dispatch(selectFirstTableSettings({ viewId: arg.viewId }));
        },
        errorDisplay: ErrorDisplayType.Toast
    });