import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { AcceptTermsOfServiceState, actionFactory } from "../types";

export const setAgreeToTerms = actionFactory.createAppAction<boolean>("setAgreeToTerms");

export function setAgreeToTermsReducers(reducerFactory: IReducerFactory<AcceptTermsOfServiceState>): IReducerFactory<AcceptTermsOfServiceState> {
    return reducerFactory
        .forAction(setAgreeToTerms)
        .addHandler((state, action) => {
            state.userAgreesToNewTerms = action.payload;
        })                
        .build();
}