import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../../store/rootSaga";
import { isValue } from "../../../../../../utils/valueHelper";
import { EntityImage } from "../../../../../commonModels/entityImage";
import { actionFactory, ImageDetailsUpdate, InventoryManagementState } from "../../types";


export const updateImageDetails = actionFactory.createAppAction<ImageDetailsUpdate>("updateImageDetails");

const sagaActions = actionFactory.createSagaWithProgressActions<ImageDetailsUpdate, EntityImage>(updateImageDetails.type);

export function updateImageDetailsReducers(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.editImageSaveState)
        .onFulfilled((state, action) => {
            if (action.payload.arg.id <= 0) {
                state.editImages = state.editImages.filter(image => image.id !== action.payload.result.id).concat(action.payload.result);
            } 
            if (action.payload.arg.useAsMenuThumbnail && isValue(state.editMediaEntity)) {
                state.editMediaEntity.menuThumbnailId = action.payload.result.id;
                const mainInv = state.inventory.entities[state.editMediaEntity.id];
                if (isValue(mainInv)) {
                    mainInv.menuThumbnailId = action.payload.result.id;
                }
            }
        })
        .build();
}

async function performLoad(arg: ImageDetailsUpdate, context: SagaAppContext): Promise<EntityImage> {
    return context.marketplaceApiClient.inventoryManagement.updateImageDetails(arg);
}

export function createUpdateImageDetailsSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: updateImageDetails.type,
        debounceInMilliseconds: 400
    });
};