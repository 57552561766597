import { useDispatch } from "react-redux"
import { silentRenew } from "../store/featureActions/silentRenew";

export const SilentRenew = () => {
    
    const dispatch = useDispatch();

    dispatch(silentRenew());

    return <div className="flex flex-column justify-content-center align-items-center" style={{ height: "100vh", width: "100vw", fontSize: "36px" }}>
        Silent Renew...
    </div>
}