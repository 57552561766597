import { Company, CompanyType } from "../../../../company/profile/store/types";
import { companyAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    addCompanyBegin,
    addCompanyCancel,
    addCompanyComplete,
    addCompanyReducers,
    addCompanySagaFactory

} = actionFactory
    .withMappedName("addCompany")
    .createModal<Company, void, Company>({        
        selector: state => state.addCompanyState,
        modelFactory: (_arg, _state) => {
            return { 
                companyType: CompanyType.Distributor,
                displayName: "",
                id: 0,
                stateAbbreviation: "MN",
                tenantId: ""
             };
        },
        onCompleteEdit: (value, context) => {
            return context.marketplaceApiClient.company.createNewCompany(value);
        },
        onFulfilled: (state, action) => {
            companyAdapter.upsertOne(state.data, action.result);
        }
    });