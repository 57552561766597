import { IntegerId } from "../../../../../commonModels/integerId";
import { inventoryNotesAdapter } from "../../state";
import { actionFactory } from "../../types";

export const {

    deleteInventoryNote,
    deleteInventoryNoteReducer,
    deleteInventoryNoteSagaFactory

} = actionFactory
    .withMappedName("deleteInventoryNote")
    .createAsyncAction<IntegerId, Response>({
        actionStatusSelector: state => state.inventoryNoteSaveStatus,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.inventoryManagement.deleteInventoryNote(arg.id);
        },
        onFulfilled: (state, action) => {
            inventoryNotesAdapter.removeOne(state.editInventoryNotes, action.payload.arg.id);            
        }
    });