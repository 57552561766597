import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { smallDeviceQuery } from "../../../responsiveconfiguration";
import { resetState } from "../store/slice";
import './index.css';
import { LargeDeviceFavoritesView } from "./largeDeviceView";
import { SmallDeviceFavoritesView } from "./smallDeviceView";

export const FavoriteMenus: React.FC = () => {

    const dispatch = useDispatch();
    const isSmallDevice = useMediaQuery(smallDeviceQuery);

    useMountEffect(() => {
        return () => {
            dispatch(resetState());
        }
    });

    return isSmallDevice
        ? <SmallDeviceFavoritesView />
        : <LargeDeviceFavoritesView />

}