import { RootState } from "../../../../../store/rootReducer";
import { createLocalImageUrl } from "../../../../../utils/imageHelper";
import { isValue } from "../../../../../utils/valueHelper";
import { menuAdapter } from "../state";

export const getLoadingState = (state: RootState) => state.companyMarketplaceView.loadingState;

export const getCompanyProfile = (state: RootState) => state.companyMarketplaceView.companyProfile;

const menuSelectors = menuAdapter.getSelectors((state: RootState) => state.companyMarketplaceView.menus);

export const getMenuIds = menuSelectors.selectIds;

export const getMenuById = menuSelectors.selectById;


export const getVendorLogo = (state: RootState) => {
    if (!isValue(state.companyMarketplaceView.vendorLogo)) return null;
    return createLocalImageUrl(state.companyMarketplaceView.vendorLogo);
}

export const getVendorLogoLoadingState = (state: RootState) => state.companyMarketplaceView.vendorLogoLoadingState;

export const getVendorBanner = (state: RootState) => {
    if (!isValue(state.companyMarketplaceView.vendorBanner)) return null;
    return createLocalImageUrl(state.companyMarketplaceView.vendorBanner);
}

export const getVendorBannerLoadingState = (state: RootState) => state.companyMarketplaceView.vendorBannerLoadingState;