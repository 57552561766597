import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { AlertBarState } from './types';

export const createInitialState = (): AlertBarState => {
    return {        
        loadingState: CreateActionStatusInitialState(),       
        userAlerts: [],
        companyAlerts: [] 
    };
};

export const initialState: AlertBarState = createInitialState();