import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { BuyerOrdersState } from './types';

export const createInitialState = (): BuyerOrdersState => {
    return {        
        loadingState: CreateActionStatusInitialState(),      
        holdRequests: [] 
    };
};

export const initialState: BuyerOrdersState = createInitialState();