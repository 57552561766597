import { ErrorDisplayType } from "../../../../../../store/factory/sagaWithProgress/types";
import { ImageKind, ImageDto } from "../../../../../commonModels/imageTypes";
import { actionFactory } from "../../types";

export const {

    loadThumbnail,
    loadThumbnailReducer,
    loadThumbnailSagaFactory

} = actionFactory
    .withMappedName("loadThumbnail")
    .createAsyncAction<{ menuId: number }, ImageDto>({
        actionStatusSelector: state => state.thumbnailState.loadState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.menuAdmin.getImage(arg.menuId, ImageKind.ImageUnavailable)
        },
        onFulfilled: (state, action) => {
            state.thumbnailState.currentImage = action.payload.result;   
        },
        errorDisplay: ErrorDisplayType.None
    });