import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory, TermsOfService, TermsOfServiceAdminState } from "../types";

export const publishTermsOfService = actionFactory.createAppAction<TermsOfService>("publishTermsOfService");

const sagaActions = actionFactory.createSagaWithProgressActions<TermsOfService, TermsOfService>(publishTermsOfService.type);

export function publishTermsOfServiceReducers(reducerFactory: IReducerFactory<TermsOfServiceAdminState>): IReducerFactory<TermsOfServiceAdminState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.saveState)
        .onFulfilled((state, action) => {
            state.termsOfService = state
                .termsOfService
                .filter(terms => terms.id !== action.payload.result.id)
                .concat(action.payload.result);
        })
        .build();
}

async function performAction(arg: TermsOfService, context: SagaAppContext): Promise<TermsOfService> {
    return context.marketplaceApiClient.termsOfServiceAdmin.publishTermsOfService(arg.id);
}

export function createPublishTermsOfServiceSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: publishTermsOfService.type,
        debounceInMilliseconds: 250
    });
};