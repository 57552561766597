import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../../store/rootSaga";
import { actionFactory, InventoryManagementState } from "../../types";
import { BulkCategoryAssignmentRequest } from "../../../../general/models/bulkCategoryRequests";
import { CreateActionStatusInitialState } from "../../../../../actionStatus/actionStatus";
import { loadMenuAssignments } from "../loadMenuAssignments";

export const completeBulkCategoryAssignment = actionFactory.createAppAction<number[]>("completeBulkCategoryAssignment");

const sagaActions = actionFactory.createSagaWithProgressActions<number[], Response>(completeBulkCategoryAssignment.type);

export function completeBulkCategoryAssignmentReducers(reducerFactory: IReducerFactory<InventoryManagementState>): IReducerFactory<InventoryManagementState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.bulkCategoryAssignmentState.savingState)
        .onFulfilled((state, _) => {
            state.bulkCategoryAssignmentState = {
                savingState: CreateActionStatusInitialState(),
                parentModels: []
            };
        })
        .build();
}

async function performLoad(categoryIds: number[], context: SagaAppContext) {
    const state = context.getState();
    const assignmentCommand: BulkCategoryAssignmentRequest = {
        entityIds: state.inventoryManagement.bulkCategoryAssignmentState.parentModels.map(p => p.id),
        categoryIds: categoryIds,
    };
    return context.marketplaceApiClient.inventoryManagement.assignCategoriesToInventory(assignmentCommand);

}

export function createCompleteBulkCategoryAssignmentSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions,
            successCallback: (_arg, _result, context) => context.dispatch(loadMenuAssignments())
        },
        actionPattern: completeBulkCategoryAssignment.type,
        debounceInMilliseconds: 250
    });
};