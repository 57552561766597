import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { IntegerId } from "../../../commonModels/integerId";
import { NumberMap } from "../../../commonModels/numberMap";
import { Product } from "../../../inventorySetup/productManagement/store/types";

export const sliceName: string = "category-management";

export const actionFactory = new ActionFactory<CategoryManagementState>(sliceName);

export interface NewCategory {
    name: string;    
}

export interface Category extends IntegerId, NewCategory {       
    tenantId: string;
    companyId: string;
}

export interface CategoryProductAssignmentState {    
    category?: Category;
    allProducts: Product[];    
    assignedProductMap: NumberMap;
    loadingState: ActionStatus;
    selectedAvailableProducts: Product[];
    selectedAssignedProducts: Product[];
}

export interface CategoryManagementState {    
    categoryProductAssignment: CategoryProductAssignmentState;    
}