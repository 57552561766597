import { DateTimeOrNullKeyOf } from "../../../utils/typeHelpers";
import ApiClient from "../../apiCommunication/apiClient";
import { createDeserializeUtcToLocalDateFns } from "../../apiCommunication/serialization";
import { PricingModel, PricingModelGrade } from "../sharedTypes";

const priceModelDateProperties: (DateTimeOrNullKeyOf<PricingModel>)[] = ["createdUtc", "modifiedUtc"];
export const { single: processPricingModel, set: processPricingModels } = createDeserializeUtcToLocalDateFns<PricingModel>(priceModelDateProperties);

export default class Pricing {

    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    getPricingModels = (): Promise<PricingModel[]> => {
        return this
            .apiClient
            .executeGet<PricingModel[]>(`price`)
            .then(processPricingModels);
    };

    getPricingModel = (id: number): Promise<PricingModel> => {
        return this
            .apiClient
            .executeGet<PricingModel>(`price/${id}`)
            .then(processPricingModel);
    };

    getPricingModelGrades = (id: number): Promise<PricingModelGrade[]> => {
        return this
            .apiClient
            .executeGet<PricingModelGrade[]>(`price/${id}/grades`);
    };
    
    addPricingModels = (model: PricingModel): Promise<PricingModel> => {
        return this
            .apiClient
            .executePostWithResult<PricingModel>(`price`, model)
            .then(processPricingModel);
    };

    upsertPricingModels = (model: PricingModel | Partial<PricingModel>): Promise<PricingModel> => {
        return this
            .apiClient
            .executePostWithResult<PricingModel>(`price`, model)
            .then(processPricingModel);
    };

    deletePricingModel = (model: PricingModel): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`price/${model.id}`);
    };

    // getQualityGrades = (): Promise<QualityGrade[]> => {
    //     return this
    //         .apiClient
    //         .executeGet<QualityGrade[]>(`price/qualityGrades`);
    // };

    // upsertQualityGrade = (model: QualityGrade | Partial<QualityGrade>): Promise<QualityGrade> => {
    //     return this
    //         .apiClient
    //         .executePostWithResult<QualityGrade>(`price/qualityGrades`, model);
    // };

    // deleteQualityGrade = (model: QualityGrade): Promise<Response> => {
    //     return this
    //         .apiClient
    //         .executeDelete(`price/qualityGrades/${model.id}`);
    // };


    // resequenceQualityGrade = (request: ResequenceRequest): Promise<QualityGrade[]> => {
    //     return this
    //         .apiClient
    //         .executePostWithResult<QualityGrade[]>(`price/qualityGrades/resequence`, request);
    // };

    upsertPricingModelGrade = (model: PricingModelGrade | Partial<PricingModelGrade>): Promise<PricingModelGrade> => {
        return this
            .apiClient
            .executePostWithResult<PricingModelGrade>(`price/grades`, model);
    };

}