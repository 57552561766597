import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { Menu } from "../../../editor/store/types";
import { actionFactory, MenuDashboardState } from "../types";

export const loadSliceData = actionFactory.createAppAction("loadSliceData");

const sagaActions = actionFactory.createSagaWithProgressActions<void, Menu[]>(loadSliceData.type);

export function loadSliceDataReducers(reducerFactory: IReducerFactory<MenuDashboardState>): IReducerFactory<MenuDashboardState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.loadingState)
        .onFulfilled((state, action) => {
            state.data = action.payload.result;            
        })
        .build();
}

async function performLoad(_arg: void, _context: SagaAppContext): Promise<Menu[]> {   
    return _context.marketplaceApiClient.menuAdmin.getMenus();
}

export function createLoadSliceDataSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: loadSliceData.type,
        debounceInMilliseconds: 250
    });
};