import { createSelector } from "reselect";
import { RootState } from "../../../../../store/rootReducer";
import { isQueueStatusComplete } from "../../../../commonModels/queueStatus";

export const getLatestSyncRequest = (state: RootState) => state.inventoryManagement.latestSyncRequest;

export const getInventorySyncState = (state: RootState) => state.inventoryManagement.requestSyncState;

export const getInventorySyncInProgress = createSelector(
    [
        (state: RootState) => state.inventoryManagement.latestSyncRequest
    ],
    (latestRequest) => latestRequest !== null && !isQueueStatusComplete(latestRequest.status)
);

export const getAdminSyncState = (state: RootState) => state.inventoryManagement.syncInventoryRequestState;
export const getAdminSyncVisible = (state: RootState) => state.inventoryManagement.syncInventoryRequestState.isVisible;
export const getAdminSyncValue = (state: RootState) => state.inventoryManagement.syncInventoryRequestState.editedValue;