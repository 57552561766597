import { Image } from "primereact/image";
import { Message } from "primereact/message";
import { CSSProperties } from "react";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import warningImage from '../../../../assets/warning.png';
import { Button } from "primereact/button";

export const Prop65Warning: React.FC = () => {

    const deltaSymbol: string = "\u0394";

    const messageStyle: CSSProperties = {
        backgroundColor: "lightgrey"
    }

    const onNavigateToClownWorld = () => {
        window.open("http://www.P65Warnings.ca.gov");
    }

    return <FlexRowContainer gap="15px" padding="10px" maxWidth="95vw">
        <Message
            severity="info"
            style={messageStyle}
            content={<FlexRowContainer gap="5px">
                <Image src={warningImage} width="14px" height="14px" ></Image>
                
                <div>{
                    `The products sold here can expose you to chemicals, including cannabis (marijuana) smoke and ${deltaSymbol}9-tetrahydrocannabinol (${deltaSymbol}9-THC). which are known to the State of California to cause cancer and birth defects, or other reproductive harm. For more information, go to`
                }
                    <Button label="www.P65Warnings.ca.gov" className="p-button-link prop-65-warning-link" onClick={onNavigateToClownWorld} />
                </div>
            </FlexRowContainer>
            }
        />
    </FlexRowContainer>;
}