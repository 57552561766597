import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { addCategoryToSectionSagaFactory } from "../featureActions/addCategoryToSection";
import { addSectionToMenuSagaFactory } from "../featureActions/addSectionToMenu";
import { deleteCategorySagaFactory } from "../featureActions/deleteCategory";
import { deleteMenuSectionSagaFactory } from "../featureActions/deleteMenuSection";
import { deleteBannerSagaFactory } from "../featureActions/images/deleteBanner";
import { deleteLogoSagaFactory } from "../featureActions/images/deleteLogo";
import { deleteThumbnailSagaFactory } from "../featureActions/images/deleteThumbnail";
import { loadBannerSagaFactory } from "../featureActions/images/loadBanner";
import { loadLogoSagaFactory } from "../featureActions/images/loadLogo";
import { loadThumbnailSagaFactory } from "../featureActions/images/loadThumbnail";
import { saveBannerSagaFactory } from "../featureActions/images/saveBanner";
import { saveLogoSagaFactory } from "../featureActions/images/saveLogo";
import { saveThumbnailSagaFactory } from "../featureActions/images/saveThumbnail";
import { createLoadCategoriesSaga } from "../featureActions/loadCategories";
import { loadMenuSectionsSagaFactory } from "../featureActions/loadMenuSections";
import { createLoadSliceDataSaga } from "../featureActions/loadSliceData";
import { removeCategoryFromSectionSagaFactory } from "../featureActions/removeCategoryFromSection";
import { resequenceMenuSectionSagaFactory } from "../featureActions/resequenceMenuSection";
import { updateMenuSectionSagaFactory } from "../featureActions/updateMenuSection";
import { upsertCategorySagaFactory } from "../featureActions/upsertCategory";
import { createUpsertMenuSaga } from "../featureActions/upsertMenu";

// Register this method in the root saga
export function createMenuAdminSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        addCategoryToSectionSagaFactory(context),        
        addSectionToMenuSagaFactory(context),
        deleteCategorySagaFactory(context),
        deleteMenuSectionSagaFactory(context),
        createLoadCategoriesSaga(context),
        loadMenuSectionsSagaFactory(context),
        createLoadSliceDataSaga(context),
        removeCategoryFromSectionSagaFactory(context),        
        resequenceMenuSectionSagaFactory(context),        
        updateMenuSectionSagaFactory(context),
        upsertCategorySagaFactory(context),
        createUpsertMenuSaga(context),
        loadLogoSagaFactory(context),
        saveLogoSagaFactory(context),        
        loadBannerSagaFactory(context),
        saveBannerSagaFactory(context),        
        deleteLogoSagaFactory(context),
        deleteBannerSagaFactory(context),
        deleteThumbnailSagaFactory(context),
        loadThumbnailSagaFactory(context),
        saveThumbnailSagaFactory(context)
    ]
}