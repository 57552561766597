import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { isValue } from "../../../../../utils/valueHelper";
import { menuSectionAdapter } from "../state";

const menuSectionSelectors = menuSectionAdapter.getSelectors((state: RootState) => state.menuAdmin.menuSections);

export const getMenuSections = menuSectionSelectors.selectAll;

export const getMenuSectionIds =createSelector(
    [
        getMenuSections
    ],
    (menuSections) => {
       return menuSections.sort((left, right) => left.sequence - right.sequence).map(section => section.id);
    });

export const getMenuSectionById = createSelector(
    [
        menuSectionSelectors.selectById
    ],
    (menuSection) => {
        if (!isValue(menuSection)) throw new Error(`Menu Section was not found`);
        return menuSection;
    });

export const getMenuSectionLoadState = (state: RootState) => state.menuAdmin.menuSectionLoadState;

export const getCategoriesForMenuSection = createSelector(
    [
        getMenuSectionById
    ],
    (menuSection) => {
        return menuSection?.categoryIds ?? [];
    }
);


export const getUseSmallMenuSectionView = (state: RootState) => state.menuAdmin.useSmallMenuSectionView;