import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { actionFactory, ProductManagementState } from "../../types";

export const cancelBulkPricingModelAssignment = actionFactory.createAppAction("cancelBulkPricingModelAssignment");

export function cancelBulkPricingModelAssignmentReducer(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forAction(cancelBulkPricingModelAssignment)
        .addHandler((state, _) => {
            state.bulkPricingModelAssignmentState.products = [];
        })
        .build();
}