import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { productNotesAdapter } from "../state";

export const getEditProductState = (state: RootState) => state.productManagement.editProductState;
export const getEditProductValue = (state: RootState) => state.productManagement.editProductState.editedValue;
export const getEditProductVisible = (state: RootState) => state.productManagement.editProductState.isVisible;

const productNotesSelectors = productNotesAdapter.getSelectors((state: RootState) => state.productManagement.editProductNotes);
export const getProductNoteById = productNotesSelectors.selectById;
export const getEditedProductNoteIds = createSelector(
    [
        productNotesSelectors.selectAll
    ],
    (allNotes) => {
        return allNotes
            .slice()
            .sort((left, right) => right.id - left.id)
            .map(note => note.id)
    }
);

export const getProductNoteLoadStatus = (state: RootState) => state.productManagement.productNoteLoadStatus;
export const getProductNoteSaveStatus = (state: RootState) => state.productManagement.productNoteSaveStatus;