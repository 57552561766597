import { Menu } from "../features/menu/editor/store/types";
import { isValue } from "./valueHelper";

const safeUrlRegex = /[^a-z0-9-]+/gi;

export function createSafeUrlName(value: string | null | undefined): string {        
    if (!isValue(value)) return "";
    return value.replaceAll(safeUrlRegex, "");
}

export function createCustomerUrl(menu: Menu): string {    
    return `${process.env.REACT_APP_CLIENT_URL}vendor/${createSafeUrlName(menu.vendorUrlName)}/menu/${createSafeUrlName(menu.urlName)}`;
}