import { expandedTreeNodeAdapter } from "../state";
import { actionFactory, TreeNodeExpandedKeys } from "../types";

export const {

    setExpandedTreeNodes,
    setExpandedTreeNodesReducer

} = actionFactory
    .withMappedName("setExpandedTreeNodes")
    .createAction<TreeNodeExpandedKeys>((state, action) => {
        expandedTreeNodeAdapter.upsertOne(state.constraintTreeExpandedKeys, action.payload);
    });