import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { CompanyProfile } from "../../../company/profile/store/types";

export const sliceName: string = "customer-dashboard";

export const actionFactory = new ActionFactory<CustomerDashboardState>(sliceName);

export interface SearchVendorDto
{
    nameSearch: string | null;
}


export interface CustomerDashboardState {
    loadingState: ActionStatus;  
    data: CompanyProfile[];      
}