import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { isValue } from "../../../../../utils/valueHelper";
import { inventoryAdapter, sectionInventoryListAdapter } from "../state";
import { actionFactory, InventoryView, LiveMenuState } from "../types";
import { loadSectionThumbnails } from "./loadSectionThumbnails";

export interface LoadSectionInventoryParams {
    sectionId: number;
}

export const loadSectionInventory = actionFactory.createAppAction<LoadSectionInventoryParams>("loadSectionInventory");

const sagaActions = actionFactory.createSagaWithProgressActions<LoadSectionInventoryParams, InventoryView[]>(loadSectionInventory.type);

export function loadSectionInventoryReducer(reducerFactory: IReducerFactory<LiveMenuState>): IReducerFactory<LiveMenuState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .onPending((state, action) => {
            sectionInventoryListAdapter.upsertOne(state.sectionInventoryLists, {
                id: action.payload.arg.sectionId,
                inventory: [],
                loadingState: {
                    isExecuting: true,
                    errorContent: null,
                    hasExecuted: false
                }
            });
        })
        .onRejected((state, action) => {
            sectionInventoryListAdapter.upsertOne(state.sectionInventoryLists, {
                id: action.payload.arg.sectionId,
                inventory: [],
                loadingState: {
                    isExecuting: false,
                    errorContent: action.payload.errorResult,
                    hasExecuted: true
                }
            });
        })
        .onFulfilled((state, action) => {
            sectionInventoryListAdapter.upsertOne(state.sectionInventoryLists, {
                id: action.payload.arg.sectionId,
                inventory: action.payload.result,
                loadingState: {
                    isExecuting: false,
                    errorContent: null,
                    hasExecuted: true
                }
            });
            inventoryAdapter.upsertMany(state.inventoryLookup, action.payload.result);
        })
        .build();
}

function performLoad(arg: LoadSectionInventoryParams, context: SagaAppContext): Promise<InventoryView[]> {
    const state = context.getState();
    const companyId = state.liveMenu.vendorMenu?.companyId;
    if (!isValue(companyId)) throw new Error("Cannot load inventory with a valid company");

    return context.marketplaceApiClient.liveMenu.getSectionInventory(companyId, arg.sectionId);
}

export function createLoadSectionInventorySaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions,
            successCallback: (arg, result, context) => {
                if (result.length > 0) {
                    context.dispatch(loadSectionThumbnails({ sectionId: arg.sectionId }));
                }                
            }
        },
        actionPattern: loadSectionInventory.type
    });
};