import { isValue } from "../../../../../utils/valueHelper";
import { menuSectionAdapter } from "../state";
import { actionFactory, CategorySectionCommand } from "../types";

export const {

    removeCategoryFromSection,
    removeCategoryFromSectionReducer,
    removeCategoryFromSectionSagaFactory

} = actionFactory
.withMappedName("removeCategoryFromSection")
.createAsyncAction<CategorySectionCommand, Response>({    
    actionStatusSelector: state => state.loadingState,
    asyncCall: (arg, context) => {

        const state = context.getState();
        const menuSection = state.menuAdmin.menuSections.entities[arg.menuSectionId];
        if (!isValue(menuSection)) throw new Error("Attempted to remove category to menu section without a valid menu section reference");

        return context.marketplaceApiClient.menuAdmin.setCategoryIdsForMenuSection(menuSection.id, menuSection.categoryIds.filter(id => id !== arg.categoryId));
    },
    onFulfilled: (state, action) => {

        const menuSection = state.menuSections.entities[action.payload.arg.menuSectionId];
        if (!isValue(menuSection)) throw new Error("Attempted to remove category to menu section without a valid menu section reference");

        menuSectionAdapter.updateOne(state.menuSections, {
            id: action.payload.arg.menuSectionId,
            changes: {
                categoryIds: menuSection.categoryIds.filter(id => id !== action.payload.arg.categoryId)                
            }
        })
    },
    debounceInMilliseconds: 250
});