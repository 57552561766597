import { IReducerFactory } from "../../../../../../store/factory/interfaces";
import { createCategoryAssignmentInitialState } from "../../state";
import { actionFactory, ProductManagementState } from "../../types";

export const cancelBulkCategoryAssignment = actionFactory.createAppAction("cancelBulkCategoryAssignment");

export function cancelBulkCategoryAssignmentReducers(reducerFactory: IReducerFactory<ProductManagementState>): IReducerFactory<ProductManagementState> {
    return reducerFactory
        .forAction(cancelBulkCategoryAssignment)
        .addHandler((state, _) => {
            state.bulkCategoryAssignmentState = createCategoryAssignmentInitialState();
        })
        .build();
}