import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { loadInventoryDetailsSagaFactory } from "../featureActions/loadInventoryDetails";
import { loadLiveMenuSagaFactory } from "../featureActions/loadLiveMenu";
import { loadVendorSagaFactory } from "../featureActions/loadVendor";

// Register this method in the root saga
export function createProductDetailsSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        loadInventoryDetailsSagaFactory(context),        
        loadVendorSagaFactory(context),
        loadLiveMenuSagaFactory(context)
    ]
}