import clsx from "clsx";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { isValue } from "../../utils/valueHelper";
import { Dialog } from 'primereact/dialog';
import { ErrorContent, isProblemDetail } from "../../features/errorHandling/types";
import { messageFromErrorContent } from "../../features/errorHandling/errorMessageHelper";
import { errorDialogZIndex } from "../../utils/zIndexHelper";

export interface ErrorDialogProps {
    error?: ErrorContent;
    title: string | null;    
    actionTitle: string;
    dismissAction: () => void;
    className?: string;
};

export const ErrorDialog: React.FC<ErrorDialogProps> = ({ error, className, actionTitle, dismissAction, title }) => {

    const titleText = isProblemDetail(error)
                        ? error.title
                        : title;
    const errorMessage = isValue(error) ? messageFromErrorContent(error) : undefined;

    return <Dialog
        visible={isValue(error)}
        onHide={() => { }}
        closeOnEscape={false}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        style={{ zIndex: errorDialogZIndex }}
    >
        <div
            className={clsx("", className)}
        >
            <div
                className='p-d-flex p-jc-start p-ai-center'
            >
                <i className={PrimeIcons.EXCLAMATION_CIRCLE} style={{ 'fontSize': '2em' }}></i>
                <h3 className="p-ml-2">{titleText}</h3>
            </div>

            <div
                className='p-d-flex p-jc-center p-ai-center'
            >
                <div>{errorMessage}</div>
            </div>

            <div
                className='p-d-flex p-jc-center p-ai-center p-mt-4 p-mb-2'
            >
                <Button label={actionTitle} onClick={dismissAction} />
            </div>

        </div>
    </Dialog>
}