import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import clsx from "clsx";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import React from "react";
import { useDispatch } from "react-redux";
import { isValue } from "../../utils/valueHelper";
import { SelectableTable, SelectableTableProps } from "./selectableTable";
import './assignmentPickList.css';

export interface AssignmentPickListProps<T> {
    availableListProps: SelectableTableProps<T>;
    assignedListProps: SelectableTableProps<T>;
    assignAction: ActionCreatorWithoutPayload;
    unassignAction: ActionCreatorWithoutPayload;
    className?: string;
    tableContainerClassName?: string;
}

export const AssignmentPickList = <T,>({ availableListProps, assignedListProps, assignAction, unassignAction, className, tableContainerClassName, children }: React.PropsWithChildren<AssignmentPickListProps<T>>) => {

    const dispatch = useDispatch();
    
    const moveSelectedAvailableToAssigned = () => {
        dispatch(assignAction());
    }

    const moveSelectedAssignedToAvailable = () => {
        dispatch(unassignAction());
    }

    return <div className={className}>
        <div className="p-grid p-justify-center" >
            <div className={clsx("p-col-fixed p-shadow-5 p-my-4", tableContainerClassName, {
                "assignment-picklist-table-container": !isValue(tableContainerClassName)                
            })} >
                <h3>Available</h3>
                <SelectableTable<T>
                    allItemsSelector={availableListProps.allItemsSelector}
                    selectedItemsSelector={availableListProps.selectedItemsSelector}
                    setSelectedItemAction={availableListProps.setSelectedItemAction}
                >
                    {children}
                </SelectableTable>
            </div>
            <div className="p-col-fixed p-col-align-center" style={{ width: "60px" }}>

                <div className="p-mb-2">
                    <Button icon={PrimeIcons.ANGLE_LEFT} onClick={moveSelectedAssignedToAvailable} />
                </div>

                <div className="p-mb-2">
                    <Button icon={PrimeIcons.ANGLE_RIGHT} onClick={moveSelectedAvailableToAssigned} />
                </div>

            </div>
            <div className={clsx("p-col-fixed p-shadow-5 p-my-4", tableContainerClassName, {
                "assignment-picklist-table-container": !isValue(tableContainerClassName)                
            })} >
                <h3>Assigned</h3>
                <SelectableTable<T>
                    allItemsSelector={assignedListProps.allItemsSelector}
                    selectedItemsSelector={assignedListProps.selectedItemsSelector}
                    setSelectedItemAction={assignedListProps.setSelectedItemAction}
                >
                    {children}
                </SelectableTable>
            </div>
        </div>
    </div>
}