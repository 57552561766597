import { ColumnProps } from "primereact/column";
import { DataTableSelectionChangeParams } from "primereact/datatable";
import React from "react";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { any } from "../../../../utils/arrayHelpers";
import { createFilteredBooleanColumnProps } from "../../../../utils/table/booleanColumn";
import { createCalculatedValueColumnProps } from "../../../../utils/table/calculatedValueColumn";
import { createMultiSelectFilterProps, MultiSelectFilterModel, MultiSelectFilterOption } from "../../../../utils/table/filters/multiSelectFilterElement";
import { createCircleCheckIconTemplate } from "../../../../utils/table/iconColumn";
import { createMultiActionColumnProps } from "../../../../utils/table/multiActionColumn";
import { createRowSelectionColumn } from "../../../../utils/table/multiSelectColumn";
import { createTextColumnProps } from "../../../../utils/table/stringColumn";
import { TypedCommandMenuItem } from "../../../../utils/table/typedMenuItem";
import { isValue } from "../../../../utils/valueHelper";
import { CustomTable } from "../../../userAppSettings/userTableSettings/ui";
import { beginCategoryAssignment } from "../store/featureActions/categoryAssignment/beginCategoryAssignment";
import { editProductBegin } from "../store/featureActions/productDetails/editProduct";
import { beginEditProductImages } from "../store/featureActions/productImages/beginEditProductImages";
import { setSelectedProducts } from "../store/featureActions/setSelectedProducts";
import { getIsBusy } from "../store/selectors/actionStatus";
import { getPricingModelMap, getPricingModels } from "../store/selectors/priceModels";
import { getProducts } from "../store/selectors/products";
import { getSelectedProducts } from "../store/selectors/selectedProducts";
import { Product } from "../store/types";
import { BulkActionToolbar } from "./bulkActionsToolbar";
import './productTable.css';

export const ProductTable: React.FC = () => {

    const dispatch = useDispatch();
    const products = useAppSelector(getProducts);
    const selectedProducts = useAppSelector(getSelectedProducts);
    const isBusy = useAppSelector(getIsBusy);
    const priceModelMap = useAppSelector(getPricingModelMap);
    const priceModels = useAppSelector(getPricingModels);
    const pricingFilterOptions = [{ label: "Not Set", value: -1 }]
        .concat(priceModels.map<MultiSelectFilterOption<number>>(pm => ({ label: pm.name, value: pm.id })));

    const onSelectionChange = (e: DataTableSelectionChangeParams): void => {
        dispatch(setSelectedProducts(e.value));
    };

    const onEditProductImages = (product: Product) => {
        dispatch(beginEditProductImages(product));
    };

    const onBeginCategoryAssignment = (product: Product) => {
        dispatch(beginCategoryAssignment(product));
    }
    const individualActionMenuItems: TypedCommandMenuItem<Product>[] = [
        {
            label: "Edit Images",
            command: onEditProductImages
        },
        {
            label: "Assign Categories",
            command: onBeginCategoryAssignment
        }
    ];

    const onEditProduct = (product: Product) => {
        dispatch(editProductBegin(product));
    }

    const getProductMenuAssignment = (product: Product): boolean => {
        return !!product.onMenuDirectly;
    }

    const getPriceModelDisplay = (product: Product) => {
        return isValue(product.pricingModelId)
            ? priceModelMap[product.pricingModelId].name
            : "";
    }


    const pricingFilterFunction = (value: number | null, filter: MultiSelectFilterModel<number>): boolean => {
        if (filter === null) return true;
        return (value === null)
            ? any(filter.selectedOptions, option => option === -1)
            : any(filter.selectedOptions, option => option === value);
    }

    const columns: ColumnProps[] = [
        createRowSelectionColumn(),
        createMultiActionColumnProps<Product>({
            columnKey: "id",
            header: "Actions",
            actionName: "Edit",
            actionHandler: onEditProduct,
            style: { width: "140px" },
            menuItems: individualActionMenuItems
        }),
        createFilteredBooleanColumnProps<Product>({
            style: { textAlign: "center", width: "120px" },
            field: "onMenuDirectly",
            header: "On Menu",
            trueLabel: "Assigned",
            falseLabel: "Not Assigned",
            customTemplateFn: createCircleCheckIconTemplate<Product>(getProductMenuAssignment)
        }),
        createCalculatedValueColumnProps<Product>({
            columnKey: "priceModel",
            field: "pricingModelId",
            header: "Price Model",
            getValueFn: getPriceModelDisplay,
            style: { width: "160px" },
            ...createMultiSelectFilterProps<number>({
                filterFunction: pricingFilterFunction,
                selectOptions: pricingFilterOptions
            }),
            sortable: true
        }),
        createTextColumnProps<Product>({ field: "licenseNumber", header: "License", style: { width: "120px" }, filter: true }),
        createTextColumnProps<Product>({ field: "metrcName", header: "Metrc Name", style: { width: "220px" }, filter: true }),
        createTextColumnProps<Product>({ field: "marketingName", header: "Marketing Name", style: { width: "220px" }, filter: true }),
        createTextColumnProps<Product>({ field: "marketingStrainName", header: "Marketing Strain", style: { width: "220px" }, filter: true }),
        createTextColumnProps<Product>({ field: "metrcStrainName", header: "Metrc Strain", style: { width: "220px" }, filter: true }),
        createTextColumnProps<Product>({ field: "metrcCategoryName", header: "Metrc Category Name", style: { width: "150px" }, filter: true }),
        createTextColumnProps<Product>({ field: "metrcCategoryType", header: "Metrc Category Type", style: { width: "150px" }, filter: true }),
        createTextColumnProps<Product>({ field: "unitOfMeasureName", header: "UOM", style: { width: "120px" }, filter: true })
    ];

    return <OverlayWaitDisplay isWaiting={isBusy}>
        <FlexColumnContainer gap="15px">

            <div className="p-d-flex p-jc-left p-ai-center">
                <BulkActionToolbar />
            </div>

            <CustomTable
                viewId="product-management"
                columns={columns}
                dataKey="id"
                value={products}
                scrollHeight='calc(100vh - 330px)'
                onSelectionChange={onSelectionChange}
                selection={selectedProducts}
                selectionMode="checkbox"
                editMode="cell"
                className="p-datatable-sm product-list"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
            />

        </FlexColumnContainer>
    </OverlayWaitDisplay>
}
