import React from "react";
import { useDispatch } from "react-redux";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { removeQualityAssessmentCancel, removeQualityAssessmentComplete } from "../store/featureActions/qualityGrades/removeQualityAssessment";
import { getRemoveQualityAssessmentState, getRemoveQualityAssessmentValue, getRemoveQualityAssessmentVisible } from "../store/selectors/qualityGrade";
import { RemoveQualityAssessment } from "../store/types";
import { RemoveQualityAssessmentEditor } from "./removeQualityAssessmentEditor";

export const RemoveQualityAssessmentDialog: React.FC = () => {

    const dispatch = useDispatch();

    const cancelEditing = () => {
        dispatch(removeQualityAssessmentCancel());
    }

    const completeEditing = (value: RemoveQualityAssessment) => {
        dispatch(removeQualityAssessmentComplete(value));
    }

    return <FormikModalEditor<RemoveQualityAssessment>
        statusSelector={getRemoveQualityAssessmentState}
        visibleSelector={getRemoveQualityAssessmentVisible}
        valueSelector={getRemoveQualityAssessmentValue}
        onCancel={cancelEditing}
        onSave={completeEditing}
        okButtonLabel="Remove"
        title="Remove quality assessment"
        className="remove-inv-quality-assessment-dialog"
        contentClassName="remove-inv-quality-assessment-dialog-content"
        headerClassName="remove-inv-quality-assessment-dialog-header"
    >
        <RemoveQualityAssessmentEditor />
    </FormikModalEditor>
}