import { InventorySearchFilter } from "../../../../inventorySetup/general/models/searchFilter";
import { Inventory } from "../../../../inventorySetup/inventoryManagement/store/types";
import { actionFactory } from "../types";

export const {

    searchInventory,
    searchInventoryReducer,
    searchInventorySagaFactory

} = actionFactory
    .withMappedName("searchInventory")
    .createAsyncAction<InventorySearchFilter, Inventory[]>({
        actionStatusSelector: state => state.inventorySearchState,
        asyncCall: (arg, context) => {                  
            return context.marketplaceApiClient.inventoryManagement.searchInventory(arg);
        },
        onFulfilled: (state, action) => {
            state.inventorySearchResults = action.payload.result;
        },
        debounceInMilliseconds: 300
    });