import { getFilteredEntities } from "../../../../../utils/entityStateHelpers";
import { loadAssesmentStateAdapter, qualityAssessmentAdapter, qualityAssessmentValueAdapter, saveAssesmentStateAdapter, saveValueStateAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    clearAssessmentState,
    clearAssessmentStateReducer

} = actionFactory
    .withMappedName("clearAssessmentState")
    .createAction<{ assessmentId: number }>((state, action) => {
        qualityAssessmentAdapter.removeOne(state.assessments, action.payload.assessmentId);

        const assessmentValueIds = getFilteredEntities(
            state.assessmentValues, 
            (assessmentValue) => assessmentValue.assessmentId === action.payload.assessmentId)
            .map(assessmentValue => assessmentValue.id);            

        qualityAssessmentValueAdapter.removeMany(state.assessmentValues, assessmentValueIds);

        loadAssesmentStateAdapter.removeOne(state.loadAssessmentState, action.payload.assessmentId);
        saveAssesmentStateAdapter.removeOne(state.saveAssessmentState, action.payload.assessmentId);

        saveValueStateAdapter.removeMany(state.saveValueState, assessmentValueIds);
    });