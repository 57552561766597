import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { NotAuthorizedState } from "../types";
import { notAuthorizedEvent } from "./notAuthorizedEventAction";

export function notAuthorizedEventReducers(reducerFactory: IReducerFactory<NotAuthorizedState>): IReducerFactory<NotAuthorizedState> {
    return reducerFactory        
        .forAction(notAuthorizedEvent)
        .addHandler((state, action) => {
            state.isNotAuthorized = true;
            state.route = action.payload.route;
        })
        .build();
}

