import { SalesOrderItem } from "../../../common/types";
import { salesOrderItemAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    deleteOrderItem,
    deleteOrderItemReducer,
    deleteOrderItemSagaFactory

} = actionFactory
    .withMappedName("deleteOrderItem")
    .createAsyncAction<SalesOrderItem, Response>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {                  
            return context.marketplaceApiClient.customer.deleteOrderItem(arg.id);
        },
        onFulfilled: (state, action) => {
            salesOrderItemAdapter.removeOne(state.salesOrderItems, action.payload.arg.id);
        }
    });