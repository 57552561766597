import { UserManagerSettings } from "oidc-client";

export const openIdConfiguration: UserManagerSettings = {
  client_id: 'wholesale-client',
  redirect_uri: `${process.env.REACT_APP_CLIENT_URL}signInCallback`,
  response_type: 'code',
  post_logout_redirect_uri: `${process.env.REACT_APP_CLIENT_URL}signOutCallback`,
  scope: 'profile tenant openid email wholesale-api',
  authority: process.env.REACT_APP_IDENTITY_URL,
  silent_redirect_uri: `${process.env.REACT_APP_CLIENT_URL}silentSignInCallback`,
  automaticSilentRenew: false,
  loadUserInfo: true,
  monitorSession: true,
  stopCheckSessionOnError: true
};