import jwtDecode, { JwtPayload } from "jwt-decode";
import { UserSettings } from "oidc-client";
import { openIdConfiguration } from "./oidc";
import { CurrentUserState, AccessTokenData } from "./store/types";

const prefix: string = "oidc.";
const key: string = `${prefix}user:${openIdConfiguration.authority}:${openIdConfiguration.client_id}`;

export function getAuthFromStorage(): CurrentUserState | null {
    const sessionStorageUser = sessionStorage.getItem(key);
    const storedUser: UserSettings | null = sessionStorageUser === null ? null : JSON.parse(sessionStorageUser);
    return storedUser === null ? null : {
        userSettings: storedUser,
        accessTokenData: parseJwt(storedUser.access_token)
    };
};

export function removeAuthFromStorage() {
    sessionStorage.removeItem(key);
};

export function isTokenExpired(accessToken: JwtPayload): boolean {
    if (accessToken.exp === undefined) return true;
    const now = Date.now() / 1000;
    const expiresIn = accessToken.exp - now;
    return expiresIn <= 0;
}

export function parseJwt(token: string): AccessTokenData {
    return jwtDecode<AccessTokenData>(token);
}