import { EntityState } from "@reduxjs/toolkit";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { PricingModel } from "../../sharedTypes";

export const sliceName: string = "price-model-dashboard";

export const actionFactory = new ActionFactory<PriceModelDashboardState>(sliceName);

export interface PriceModelDashboardState {
    loadingState: ActionStatus;  
    pricingModels: EntityState<PricingModel>;      
    addPricingModelState: ActionStatus;  
    navigateToNewModelId: number | null;
}