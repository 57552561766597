import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { confirmPopup } from 'primereact/confirmpopup';
import { InputTextarea } from 'primereact/inputtextarea';
import { Note } from '../../features/commonModels/note';
import { useAppSelector } from '../../store/configureStore';
import { RootState } from '../../store/rootReducer';
import { formatToDateAndTimeString } from '../../utils/datetimeHelper';
import { isValue } from '../../utils/valueHelper';
import { FlexColumnContainer } from '../containers/flexColumnContainer';
import { FlexRowContainer } from '../containers/flexRowContainer';

export interface NoteEditorProps<T extends Note> {
    id: number;
    dataSelector: (state: RootState, id: number) => T | undefined;
    onUpdateNote: (note: T) => void;
    onDeleteNote: (note: T) => void;
}

export const NoteEditor = <T extends Note>({
    id,
    dataSelector,
    onDeleteNote,
    onUpdateNote
}: NoteEditorProps<T>) => {

    const note = useAppSelector(state => dataSelector(state, id));
    const editorId = `notes-editor-${note?.id}`;

    const updateNote = (note: T, newNotes: string) => {
        if (note.notes !== newNotes) {
            onUpdateNote({ ...note, notes: newNotes });
        }
    }

    const deleteNote = (event: React.MouseEvent, note: T) => {

        confirmPopup({
            target: event.target as HTMLElement,
            message: "Are you sure you want to delete this note?",
            acceptLabel: "Delete this note",
            rejectLabel: "Keep this note",
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                onDeleteNote(note);
            },
            reject: () => { }
        });

    }

    // useMountEffect(() => {
    //     setTimeout(() => resize(), 550);
    // });

    // const resize = () => {
    //     const inputTextArea = document.getElementById(editorId);
    //     if (isValue(inputTextArea)) {
    //         if (inputTextArea.scrollHeight > 0) {
    //             console.log("inputTextArea.scrollHeight", inputTextArea.scrollHeight);
    //             console.log("inputTextArea.style", inputTextArea.style);
    //             inputTextArea.style.height = `${inputTextArea.scrollHeight}px`;
    //         }
    //     }
    // }

    return !isValue(note)
        ? <div></div>
        : <FlexColumnContainer
            key={note.id}
            gap="5px"
            padding="5px"
        >
            <FlexRowContainer gap="5px" fontSize="10px">
                <div>{note.createdBy}</div>
                <div>{formatToDateAndTimeString(note.createdUtc)}</div>
                {
                    !note.createdUtc.equals(note.modifiedUtc) && <div>
                        {`(modified)`}
                    </div>
                }
            </FlexRowContainer>
            <FlexRowContainer gap="5px" width="100%">
                <InputTextarea
                    autoResize
                    id={editorId}
                    className='notes-editor'
                    defaultValue={note.notes}
                    onBlur={(e) => updateNote(note, e.target.value)}
                />
                <Button type='button' icon={PrimeIcons.TIMES} style={{ height: "30px" }} onClick={(e) => deleteNote(e, note)} />
            </FlexRowContainer>
        </FlexColumnContainer>
}   