import React from "react";
import { useDispatch } from "react-redux";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { createQualityAssessmentCancel, createQualityAssessmentComplete } from "../store/featureActions/qualityGrades/createQualityAssessment";
import { getCreateQualityAssessmentState, getCreateQualityAssessmentValue, getCreateQualityAssessmentVisible } from "../store/selectors/qualityGrade";
import { QualityAssessmentAssignment } from "../store/types";
import { CreateQualityAssessmentEditor } from "./createQualityAssessmentEditor";

export const CreateQualityAssessmentDialog: React.FC = () => {

    const dispatch = useDispatch();
        
    const cancelEditing = () => {
        dispatch(createQualityAssessmentCancel());
    }

    const completeEditing = (value: QualityAssessmentAssignment) => {
        dispatch(createQualityAssessmentComplete(value));
    }

    return <FormikModalEditor<QualityAssessmentAssignment>
        statusSelector={getCreateQualityAssessmentState}
        visibleSelector={getCreateQualityAssessmentVisible}
        valueSelector={getCreateQualityAssessmentValue}
        onCancel={cancelEditing}
        onSave={completeEditing}
        okButtonLabel="Choose"
        title="Select a quality template"
        className="create-inv-quality-assessment-dialog"
        contentClassName="create-inv-quality-assessment-dialog-content"
        headerClassName="create-inv-quality-assessment-dialog-header"
    >
       <CreateQualityAssessmentEditor />
    </FormikModalEditor>
}