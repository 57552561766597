import React from "react";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { DateField } from "../../../../components/formik/dateField";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { syncInventoryCancel, syncInventoryComplete } from "../store/featureActions/syncInventory";
import { getAdminSyncState, getAdminSyncValue, getAdminSyncVisible } from "../store/selectors/syncRequest";
import { SyncInventoryRequest } from "../store/types";

export const SyncInventoryDialog: React.FC = () => {

    const dispatch = useDispatch();

    const cancelEditing = () => {
        dispatch(syncInventoryCancel());
    }

    const completeEditing = (value: SyncInventoryRequest) => {
        dispatch(syncInventoryComplete(value));
    }

    return <FormikModalEditor<SyncInventoryRequest>
        statusSelector={getAdminSyncState}
        visibleSelector={getAdminSyncVisible}
        valueSelector={getAdminSyncValue}
        onCancel={cancelEditing}
        onSave={completeEditing}
        okButtonLabel="Sync"
        title="Admin Inventory Sync"
    // className="remove-inv-quality-assessment-dialog"
    // contentClassName="remove-inv-quality-assessment-dialog-content"
    // headerClassName="remove-inv-quality-assessment-dialog-header"
    >
        <FlexColumnContainer padding="20px">
            <DateField<SyncInventoryRequest>
                defaultLabel="Sync Changes Since"
                fieldName="changesSince"
                labelStyle={{ marginRight: "20px" }}
            />
        </FlexColumnContainer>

    </FormikModalEditor>
}