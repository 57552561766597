
export enum ComparisonOperation {
    Equal = "Equal",
    NotEqual = "NotEqual",
    GreaterThan = "GreaterThan",
    GreaterThanOrEqual = "GreaterThanOrEqual",
    LessThan = "LessThan",
    LessThanOrEqual = "LessThanOrEqual",
    In = "In"
}

export const ComparisonOperationViews = [
    { label: "equal to", value: ComparisonOperation.Equal },
    { label: "not equal to", value: ComparisonOperation.NotEqual },
    { label: "greater than", value: ComparisonOperation.GreaterThan },
    { label: "greater than or equal to", value: ComparisonOperation.GreaterThanOrEqual },
    { label: "less than", value: ComparisonOperation.LessThan },
    { label: "less than or equal to", value: ComparisonOperation.LessThanOrEqual },
    { label: "in", value: ComparisonOperation.In },
];
