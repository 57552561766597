import { useDispatch } from "react-redux";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { isValue } from "../../../../utils/valueHelper";
import { Contact } from "../../common/types";
import { contactValidationSchema } from "../../validations/contactValidations";
import { editContactCancel, editContactComplete } from "../store/featureActions/editContact";
import { getEditContactStatus, getEditContactValue, getEditContactVisible } from "../store/selectors/contact";
import { ContactEditor } from "./contactEditor";


export const ContactDialog: React.FC = () => {

    const dispatch = useDispatch();

    const onCancel = () => {
        dispatch(editContactCancel());
    };

    const onSave = (value: Contact) => {
        if (!isValue(value)) return;
        dispatch(editContactComplete(value));
    }
   
    return <FormikModalEditor<Contact>
        title="Edit Contact"
        valueSelector={getEditContactValue}
        visibleSelector={getEditContactVisible}
        onSave={onSave}
        onCancel={onCancel}
        statusSelector={getEditContactStatus}
        style={{ width: "500px", maxWidth: "95vw" }}
        validationSchema={contactValidationSchema}
    >
       <ContactEditor /> 
    </FormikModalEditor>
} 