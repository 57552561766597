import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { inventoryNotesAdapter } from "../state";

export const getEditInventoryState = (state: RootState) => state.inventoryManagement.editInventoryState;
export const getEditInventoryValue = (state: RootState) => state.inventoryManagement.editInventoryState.editedValue;
export const getEditInventoryVisible = (state: RootState) => state.inventoryManagement.editInventoryState.isVisible;


const inventoryNotesSelectors = inventoryNotesAdapter.getSelectors((state: RootState) => state.inventoryManagement.editInventoryNotes);
export const getInventoryNoteById = inventoryNotesSelectors.selectById;
export const getEditedInventoryNoteIds = createSelector(
    [
        inventoryNotesSelectors.selectAll
    ],
    (allNotes) => {
        return allNotes
            .slice()
            .sort((left, right) => right.id - left.id)
            .map(note => note.id)
    }
);

export const getInventoryNoteLoadStatus = (state: RootState) => state.inventoryManagement.inventoryNoteLoadStatus;
export const getInventoryNoteSaveStatus = (state: RootState) => state.inventoryManagement.inventoryNoteSaveStatus;