import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { corporateEntityAdapter } from "../state";
import { actionFactory, CorporateEntityState } from "../types";

export const deleteCorporateEntity = actionFactory.createAppAction<number>("deleteCorporateEntity");

const sagaActions = actionFactory.createSagaWithProgressActions<number, Response>(deleteCorporateEntity.type);

export function deleteCorporateEntityReducers(reducerFactory: IReducerFactory<CorporateEntityState>): IReducerFactory<CorporateEntityState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.loadingState)
        .onFulfilled((state, action) => {
            corporateEntityAdapter.removeOne(state.corporateEntities, action.payload.arg);                
        })
        .build();
}

async function performAction(arg: number, context: SagaAppContext): Promise<Response> {
    return context.marketplaceApiClient.corporateEntity.deleteCorporateEntity(arg);    
}

export function createDeleteCorporateEntitySaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: deleteCorporateEntity.type,
        debounceInMilliseconds: 250
    });
};