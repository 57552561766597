import { actionFactory } from "../types";

export const {

    cancelSaveAs,
    cancelSaveAsReducer

} = actionFactory
    .withMappedName("cancelSaveAs")
    .createAction<void>((state, _action) => {
        state.saveAsState.visible = false;
        state.saveAsState.viewId = "";
    });