import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { addNewConstraintReducer } from './featureActions/addNewConstraint';
import { changeGradeKindReducers } from './featureActions/changeGradeKind';
import { clearTestAssessmentValueReducer } from './featureActions/clearTestAssessmentValue';
import { deleteTemplateConstraintReducer } from './featureActions/deleteTemplateConstraint';
import { deleteTemplateConstraintNodeReducer } from './featureActions/deleteTemplateConstraintNode';
import { evaluateTestAssessmentReducer } from './featureActions/evaluateTestAssessment';
import { loadSliceDataReducer } from './featureActions/loadSliceData';
import { loadTemplateConstraintNodesReducer } from './featureActions/loadTemplateConstraintNodes';
import { publishAssessmentTemplateReducer } from './featureActions/publishAssessmentTemplate';
import { removeMetricFromTemplateReducer } from './featureActions/removeMetricFromTemplate';
import { resequenceTemplateMetricReducer } from './featureActions/resequenceTemplateMetric';
import { saveAssessmentTemplateReducer } from './featureActions/saveAssessmentTemplate';
import { selectMetricsReducers } from './featureActions/selectMetrics';
import { setExpandedTreeNodesReducer } from './featureActions/setExpandedTreeNodes';
import { setTestAssessmentValueReducer } from './featureActions/setTestAssessmentValue';
import { updateTemplateConstraintReducer } from './featureActions/updateTemplateConstraint';
import { updateTemplateMetricReducer } from './featureActions/updateTemplateMetric';
import { upsertTemplateConstraintNodeReducer } from './featureActions/upsertTemplateConstraintNode';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(loadSliceDataReducer)
            .add(saveAssessmentTemplateReducer)
            .addRange(selectMetricsReducers)
            .add(updateTemplateMetricReducer)
            .add(removeMetricFromTemplateReducer)
            .add(resequenceTemplateMetricReducer)            
            .add(updateTemplateConstraintReducer)
            .addRange(changeGradeKindReducers)
            .add(publishAssessmentTemplateReducer)
            .add(evaluateTestAssessmentReducer)
            .add(setTestAssessmentValueReducer)
            .add(deleteTemplateConstraintReducer)
            .add(clearTestAssessmentValueReducer)
            .add(upsertTemplateConstraintNodeReducer)
            .add(addNewConstraintReducer)
            .add(deleteTemplateConstraintNodeReducer)
            .add(setExpandedTreeNodesReducer)
            .add(loadTemplateConstraintNodesReducer);
            // Create your feature actions in ./featureActions and then add them to the reducer factory here
            // with .Add(<your-action-reducer-name>);
            
    }
});

export default slice.reducer;

export const { resetState } = slice.actions;