import { EntityState } from "@reduxjs/toolkit";
import { isValue } from "./valueHelper";

export const getEntities = <T,>(entityState: EntityState<T>) : T[] => {
    return entityState
        .ids
        .map(id => entityState.entities[id])
        .filter(isValue);
}


export const getFilteredEntities = <T,>(entityState: EntityState<T>, predicate: (entity: T) => boolean) : T[] => {
    return entityState
        .ids
        .map(id => entityState.entities[id])
        .filter(isValue)
        .filter(predicate);
}