import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { addToFavoritesReducer } from './featureActions/addToFavorites';
import { loadFavoriteMenusReducer } from './featureActions/loadFavoriteMenus';
import { loadMenuInvitesReducer } from './featureActions/loadMenuInvites';
import { removeFromFavoritesReducer } from './featureActions/removeFromFavorites';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(loadFavoriteMenusReducer)
            .add(addToFavoritesReducer)
            .add(removeFromFavoritesReducer)
            .add(loadMenuInvitesReducer);
            // Create your feature actions in ./featureActions and then add them to the reducer factory here
            // with .Add(<your-action-reducer-name>);
            
    }
});

export default slice.reducer;

export const { resetState } = slice.actions;