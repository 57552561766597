import { RootState } from "../../../../../store/rootReducer";
import { BulkAssignmentKind } from "../../../../commonModels/bulkAssignmentState";

export const getBulkPricingModelSavingState = (state: RootState) => state.productManagement.bulkPricingModelAssignmentState.savingState;

export const getProductsForBulkPricingModelAssignment = (state: RootState) => state.productManagement.bulkPricingModelAssignmentState.products;

export const getPricingModelToBulkAssign = (state: RootState) => state.productManagement.bulkPricingModelAssignmentState.pricingModelIdToAssign;

export const getPricingModelAssignmentKind = (state: RootState) => state.productManagement.bulkPricingModelAssignmentState.bulkAssignmentKind;

export const getBulkPricingModelAssignmentDialogTitle = 
    (state: RootState) => 
        state.productManagement.bulkPricingModelAssignmentState.bulkAssignmentKind === BulkAssignmentKind.Assign
            ? `Assign Pricing Model: ${state.productManagement.bulkPricingModelAssignmentState.products.length} items will be updated`
            : `Clear Pricing Model: ${state.productManagement.bulkPricingModelAssignmentState.products.length} items will be updated`;