import { EntityState } from "@reduxjs/toolkit";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { SyncRequestDto } from "../../../commonModels/syncRequest";

export const sliceName: string = "admin-license-management";

export const actionFactory = new ActionFactory<SyncAdminLicenseState>(sliceName);

export interface License {
    id: number;
    tenantId: string;
    corporateEntityName: string;
    facilityName: string;
    number: string;
    includeInventory: boolean;
}

export interface EntityActionStatus {
    [id:number]: ActionStatus
};

export interface SyncAdminLicenseState {
    loadingState: ActionStatus;   
    licenses: EntityState<License>;
    latestSyncLicenseRequest: SyncRequestDto | null; 
    requestLicenseSyncState: ActionStatus;
    licenseSaveState: EntityActionStatus
}