import { EntityState } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ModalEditState } from "../../../../store/factory/modalEdit/modalEdit";
import { IdentityMap } from "../../../../utils/identity";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { BulkAssignmentKind } from "../../../commonModels/bulkAssignmentState";
import { EntityImage } from "../../../commonModels/entityImage";
import { EntityVideo } from "../../../commonModels/entityVideo";
import { IntegerId } from "../../../commonModels/integerId";
import { SyncRequestDto } from "../../../commonModels/syncRequest";
import { AssessmentTemplate } from "../../../qualityGradeTemplates/models/assessmentTemplate";
import { QualityGradeKind } from "../../../qualityGradeTemplates/models/gradeKind";
import { QualityGradeValue } from "../../../qualityGradeTemplates/models/gradeValue";
import { InventoryMenuAssignment } from "../../general/models/menuAssignment";
import { MetrcPackageType } from "../../../commonModels/metrcPackageType";

export const sliceName: string = "inventory-management";

export const actionFactory = new ActionFactory<InventoryManagementState>(sliceName);

export interface OrderedInventoryQuantity {
    inventoryId: number;
    quantity: number;
}

export interface OrderedInventoryDetails {
    inventoryId: number;
    quantity: number;
    salesOrderId: number;
    salesOrderItemID: number;
    customerName: string;
    orderNumber: string;
    unitOfMeasure: string;
}

export interface OrderedQuantityFilter {
    inventoryIds: number[];
}

export interface QualityAssessmentAssignment {
    inventoryIds: number[];
    qualityAssessmentTemplateId: number;
}

export interface RemoveQualityAssessment {
    inventoryIds: number[];
}

export interface ReadyDateAssignment {
    inventoryIds: number[];
    readyDate: DateTime | null;
}

export enum InventoryType {
    Metric="Metric",
    Custom="Custom"
}

export interface InventoryLicense extends IntegerId{
  number: string
}
export interface Inventory extends IntegerId {
    tenantId: string;
    productId: number | null;
    licenseId: number;
    menuThumbnailId: number | null;
    licenseNumber: string;
    metrcName: string | null;
    metrcStrainName: string | null;
    metrcPackageId: number | null;
    metrcPackageLabel: string;
    metrcPackageType: MetrcPackageType | null;
    quantity: number;
    unitOfMeasureName: string;
    marketingName: string | null
    marketingStrainName: string | null;
    marketingDescription: string | null
    thcPercentage: number | null;
    cbdPercentage: number | null;
    unitPrice: number | null;
    packagedDate: DateTime;
    inactiveDate: DateTime | null;
    readyDate: DateTime | null;
    excludeFromMarket: boolean;
    reservedQuantity: number | null;
    qualityGradeId: number | null;
    qualityAssessmentId: number | null;
    inventoryType: InventoryType

    // view projection used for table display
    onMenuDirectly?: boolean;
    onMenuViaProduct?: boolean;
    qualityAssessmentGradeValueId?: number | null;
}

export interface InventoryNote extends IntegerId {
    inventoryId: number;
    tenantId: string;
    notes: string;
    createdBy: string;
    createdUtc: DateTime;
    modifiedUtc: DateTime;
}

export interface ImageDetailsUpdate {
    id: number;
    imageTitle: string;
    useAsMenuThumbnail: boolean;
}

export interface CategoryAssignmentState {
    loadingState: ActionStatus;
    savingState: ActionStatus;
    parentModel?: Inventory;
}

//extends BulkSetAssignmentState<Inventory, Category>
export interface InventoryBulkCategoryAssignment {
    savingState: ActionStatus;
    parentModels: Inventory[];
}

export interface SelectableColumn {
    displayName: string;
    fieldName: keyof Inventory;
}

export enum BulkExcludeAction {
    Exclude,
    RemoveExclusion
}

export interface BulkQualityGradeAssignmentState {
    savingState: ActionStatus;
    inventory: Inventory[];
    qualityGradeIdToAssign: number | null;
    bulkAssignmentKind: BulkAssignmentKind;
};

export interface InventoryView extends Inventory {
    orderedQuantity: number;
    calulatedQualityGradeValueId?: number | null;
}

export interface SyncInventoryRequest {
    changesSince: DateTime;
}

export interface UnitOfMeasure extends IntegerId{
  name: string
}

export interface InventoryManagementState {
    loadingState: ActionStatus;
    latestSyncRequest: SyncRequestDto | null;
    requestSyncState: ActionStatus;

    inventory: EntityState<InventoryView>;

    selectedInventoryIds: number[];

    editInventoryState: ModalEditState<Inventory>;
    addInventoryState: ModalEditState<Inventory>
    inventoryDeleteStatus: ActionStatus;
    inventoryNoteLoadStatus: ActionStatus;
    inventoryNoteSaveStatus: ActionStatus;
    editInventoryNotes: EntityState<InventoryNote>;

    inventoryLicensesLoadState: ActionStatus,
    inventoryLicenses: InventoryLicense[];

    inventoryUnitOfMeasuresState: ActionStatus,
    inventoryUnitOfMeasures:UnitOfMeasure[],

    editMediaEntity?: Inventory;
    editImages: EntityImage[];
    editVideos: EntityState<EntityVideo>;
    addVideoState: ModalEditState<EntityVideo>;
    videoSaveState: ActionStatus;
    mediaLoadState: ActionStatus;
    editImageSaveState: ActionStatus;

    bulkCategoryAssignmentState: InventoryBulkCategoryAssignment;
    bulkClearCategoryAssignmentState: InventoryBulkCategoryAssignment;
    categoryAssignmentState: CategoryAssignmentState;

    bulkExcludeState: {
        showConfirmation: boolean;
        savingState: ActionStatus;
        action: BulkExcludeAction;
    }

    menuAssignmentsLoadState: ActionStatus;
    menuAssignments: InventoryMenuAssignment[];

    qualityGrades: EntityState<QualityGradeValue>;
    qualityGradeKinds: EntityState<QualityGradeKind>;
    bulkQualityGradeAssignmentState: BulkQualityGradeAssignmentState;

    orderedQuantities: IdentityMap<OrderedInventoryDetails[]>;

    viewOrdersForInventoryId?: number | null;
    qualityAssessmentTemplates: AssessmentTemplate[];
    assignQualityAssessmentState: ModalEditState<QualityAssessmentAssignment>;
    removeQualityAssessmentState: ModalEditState<RemoveQualityAssessment>;

    syncInventoryRequestState: ModalEditState<SyncInventoryRequest>;
    readyDateAssignmentState: ModalEditState<ReadyDateAssignment>;
}