import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import {DateTime } from 'luxon';

export const sliceName: string = "serverHealth";

export const actionFactory = new ActionFactory<ServerInformationState>(sliceName);

export interface ServerHealth {
    version: string;
    currentServerTime: DateTime;
    companyCount: number;
    databaseConnectivity: boolean;
    errorMessage: string | null;
}

export interface ServerInformationState {
    loadingState: ActionStatus;    
    serverHealth: ServerHealth | null;             
}