export interface InfoPanelProps {
    textContent: string
};

export const InfoPanel: React.FC<InfoPanelProps> = (props) => {

    return <div className="p-shadow-2 p-mx-1 p-my-4 p-p-3 p-grid">
        <div className="p-col-fixed" style={{ width: "30px" }}>
            <i className="pi pi-info-circle p-mr-4 p-mt-1 info-icon"></i>
        </div>
        <div className="p-col" style={{ display: "inline" }}>
            {props.textContent}
        </div>
    </div>


}