import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { addTemplateSagaFactory } from "../featureActions/addTemplate";
import { copyTemplateSagaFactory } from "../featureActions/copyTemplate";
import { deleteTemplateSagaFactory } from "../featureActions/deleteTemplate";
import { loadSliceDataSagaFactory } from "../featureActions/loadSliceData";

// Register this method in the root saga
export function createQualityTemplateDashboardSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        loadSliceDataSagaFactory(context),
        addTemplateSagaFactory(context),
        copyTemplateSagaFactory(context),
        deleteTemplateSagaFactory(context)
    ]
}