import clsx from "clsx";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog"
import { Divider } from "primereact/divider";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useAppSelector } from "../../../../store/configureStore"
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { smallDeviceQuery } from "../../../responsiveconfiguration";
import { loadAgeConfirmation } from "../store/featureActions/loadAgeConfirmation";
import { saveAgeConfirmation } from "../store/featureActions/saveAgeConfirmation";
import { getHasConfirmedAge, getHasLoadedAgeConfirmation } from "../store/selectors/ageConfirmation";
import './index.css';

export const ConfirmAgeDialog: React.FC = () => {

    const hasConfirmedAge = useAppSelector(getHasConfirmedAge);
    const hasLoaded = useAppSelector(getHasLoadedAgeConfirmation);
    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    const dispatch = useDispatch();

    useMountEffect(() => {
        dispatch(loadAgeConfirmation());
    });

    const onConfirmAge = () => {
        dispatch(saveAgeConfirmation());
    }

    return <div>
        <Dialog
            onHide={onConfirmAge}
            closable={false}
            showHeader={false}
            visible={!hasConfirmedAge && hasLoaded}
            className={clsx("p-shadow-24", {
                "confirm-age-dialog": !isSmallDevice,
                "confirm-age-dialog-small": isSmallDevice,

            })}
        >
            <Divider />
            <div className="p-d-flex p-jc-center">
                <i className={clsx(PrimeIcons.EXCLAMATION_TRIANGLE, "confirm-age-icon")}></i>
            </div>
            <Divider />
            <div className="p-d-flex p-jc-center p-ai-center">

                <h3 className={clsx({
                    "confirm-age-warning": !isSmallDevice,
                    "confirm-age-warning-small": isSmallDevice,
                })}>You must be 21 years of age or older to use this website.</h3>

            </div>
            <Divider />
            <div className="p-d-flex p-jc-center p-ai-center p-mt-4 p-p-4">
                <Button label="I am 21 or over" onClick={onConfirmAge} />
            </div>
        </Dialog>
    </div>
}