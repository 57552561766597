import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import { getIsAuthenticated } from "../../features/authentication/store/selectors/isAuthenticated";
import { smallDeviceQuery } from "../../features/responsiveconfiguration";
import { useAppSelector } from "../../store/configureStore";
import { BuyerSignUpCard } from "../responsiveCard/buyerSignUpCard";
import { LoginCard } from "../responsiveCard/loginCard";
import { SellerSignUpCard } from "../responsiveCard/sellerSignUpCard";
import { CenterContentResponsivePanel } from "../responsiveContainer";
import './index.css';

export interface NotAuthorizedProps {
    loginRequiredTitle?: string;
    notAuthorizedTitle?: string;    
}

export const NotAuthorized: React.FC<NotAuthorizedProps> = ({ 
    loginRequiredTitle = "You must be logged in to view this content",
    notAuthorizedTitle = "You are not authorized to access this content"
}) => {

    const isLoggedIn = useAppSelector(getIsAuthenticated);
    const isSmallDevice = useMediaQuery(smallDeviceQuery);

    return <div >
        {
            isLoggedIn
                ? <div className="p-d-flex p-flex-column p-ai-center" >
                    <div style={{ height: "25vh" }}>
                        <h2 className="p-text-center">{notAuthorizedTitle}</h2>
                    </div>
                    <div style={{ height: "25vh" }}>
                        <i className="pi pi-lock" style={{ 'fontSize': '4em', color: "#009b99" }}></i>
                    </div>
                </div>
                : <div className="p-d-flex p-flex-column p-jc-center p-ai-center p-mt-4">

                    <div
                        className={clsx({
                            "not-authorized-small-device-message": isSmallDevice,
                            "not-authorized-large-device-message": !isSmallDevice
                        })}
                    >
                        {loginRequiredTitle}
                    </div>

                    <CenterContentResponsivePanel>
                        <LoginCard />
                        <BuyerSignUpCard />
                        <SellerSignUpCard />
                    </CenterContentResponsivePanel>

                </div>
        }
    </div>
}

