import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { User, UserManager } from "oidc-client";
import { isValue } from "../../utils/valueHelper";
import { openIdConfiguration } from "./oidc";
import { AuthRoutePaths } from "./routes/paths";
import { postLoginRedirectUriStorageKey } from "./store/types";

export interface IAuthService {
    getUser: () => Promise<User | null>;

    newLogin: () => Promise<void>;

    completeLogin: () => Promise<User | null>;

    newLogout: () => Promise<void>;

    completeLogout: () => Promise<void>;   

    completeSilentRenew: () => Promise<void>;
}

export default class AuthService implements IAuthService {

    userManager: UserManager;
    private readonly _dispatch: Dispatch<AnyAction>;

    constructor(dispatch: Dispatch<AnyAction>) {

        this._dispatch = dispatch;

        this.userManager = new UserManager({
            ...openIdConfiguration
        });

        this.userManager.clearStaleState();

        this.userManager.events.addUserLoaded(() => {
            console.log("AuthService: User Loaded")
            this.userManager.events.removeUserSignedOut(this.userSignedOutHandler);
            this.userManager.events.addUserSignedOut(this.userSignedOutHandler);

            this.userManager.events.removeAccessTokenExpired(this.tokenExpired);
            this.userManager.events.addAccessTokenExpired(this.tokenExpired);
        });

        this.userManager.events.addUserSessionChanged(() => {
            console.log("AuthService: User Sessions Changed")
        });
    }

    userSignedOutHandler = () => {
        console.log("AuthService: User Signed Out")        
        this.userManager.removeUser().then(_ => {
            this._dispatch(push(AuthRoutePaths.logOutConfirmation));
        })
    }

    tokenExpired = () => {
        console.log("AuthService: Token Expired")        
        this._dispatch(push(AuthRoutePaths.sessionExpired));
    }

    completeSilentRenew = async () => {
        try {
            console.log("AuthService: SilentRenew");
            await this.userManager.signinSilentCallback();
        }
        catch (error) {
            console.log("AuthService: SilentRenew Error: ", error);
        }
    };   

    getUser = async (): Promise<User | null> => {
        return this.userManager.getUser().then(user => {

            if (isValue(user)) {
                this.userManager.events.removeUserSignedOut(this.userSignedOutHandler);
                this.userManager.events.addUserSignedOut(this.userSignedOutHandler);

                this.userManager.events.removeAccessTokenExpired(this.tokenExpired);
                this.userManager.events.addAccessTokenExpired(this.tokenExpired);
            }

            return user;
        });
    }

    newLogin = () => {
        console.log("AuthService: newLogin");
        localStorage.setItem(postLoginRedirectUriStorageKey, window.location.pathname)
        return this.userManager
            .signinRedirect();
    }

    completeLogin = () => {
        return this.userManager
            .signinRedirectCallback()
            .then(() => this.getUser());
    }

    newLogout = async () => {
        return this.userManager.signoutRedirect();        
    }

    completeLogout = () => {
        return this.userManager.signoutRedirectCallback()
            .then(() => this.userManager.removeUser());
    }
}