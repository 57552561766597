import { useDispatch } from "react-redux";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { QualityGradeKind } from "../../models/gradeKind";
import { qualityGradeKindValidationSchema } from "../../validations/qualityGradeKindValidations";
import { addQualityGradeKindCancel, addQualityGradeKindComplete } from "../store/featureActions/addQualityGradeKind";
import { getAddQualityGradeKindStatus, getAddQualityGradeKindValue, getAddQualityGradeKindVisible } from "../store/selectors/editing";
import { AddQualityGradeKindEditor } from "./addQualityGradeKindEditor";

export const AddQualityGradeKindDialog: React.FC = () => {

    const dispatch = useDispatch();

    const onSave = (values: QualityGradeKind): void => {
        dispatch(addQualityGradeKindComplete(values));
    };

    const onCancel = (): void => {
        dispatch(addQualityGradeKindCancel());
    }

    return <FormikModalEditor<QualityGradeKind>
        statusSelector={getAddQualityGradeKindStatus}
        valueSelector={getAddQualityGradeKindValue}
        visibleSelector={getAddQualityGradeKindVisible}
        //selector={getAddQualityGradeKindState}
        onSave={onSave}
        onCancel={onCancel}
        validationSchema={qualityGradeKindValidationSchema}        
        title="Add Quality Grade Kind"
    >
        <AddQualityGradeKindEditor />
    </FormikModalEditor>
}