import { Load4Result, parallelLoad } from "../../../../../utils/promiseHelpers";
import { CreateActionStatusInitialState, EntityActionStatus } from "../../../../actionStatus/actionStatus";
import { QualityGradeKind } from "../../../../qualityGradeTemplates/models/gradeKind";
import { QualityGradeValue } from "../../../../qualityGradeTemplates/models/gradeValue";
import { PricingModel, PricingModelGrade } from "../../../sharedTypes";
import { gradeKindAdapter, gradeValueAdapter, pricingModelGradeAdapter, pricingModelSaveStateAdapter } from "../state";
import { actionFactory } from "../types";

type LoadResult = Load4Result<PricingModel, PricingModelGrade[], QualityGradeKind[], QualityGradeValue[]>

export const {

    loadSliceData,
    loadSliceDataReducer,
    loadSliceDataSagaFactory

} = actionFactory
    .withMappedName("loadSliceData")
    .createAsyncAction<{ id: number }, LoadResult>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => parallelLoad(
            () => context.marketplaceApiClient.pricing.getPricingModel(arg.id),
            () => context.marketplaceApiClient.pricing.getPricingModelGrades(arg.id),
            () => context.marketplaceApiClient.quality.getQualityGradeKinds(),
            () => context.marketplaceApiClient.quality.getQualityGradeValues(),
        ),
        onFulfilled: (state, action) => {
            state.priceModel = action.payload.result.result1;

            pricingModelGradeAdapter.setAll(state.pricingModelGrades, action.payload.result.result2);

            gradeKindAdapter.setAll(state.gradeKinds, action.payload.result.result3);
            gradeValueAdapter.setAll(state.gradeValues, action.payload.result.result4);

            pricingModelSaveStateAdapter.setAll(
                state.pricingModelSaveStates,
                action.payload.result.result2
                    .map<EntityActionStatus>(pricingModelGrade => ({
                        id: pricingModelGrade.id,
                        ...CreateActionStatusInitialState()
                    })))
        }
    });
