import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { ErrorDisplayType } from "../../../../../store/factory/sagaWithProgress/types";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory, ApiKeyVerification, BuyerVerificationState, VerifyRequest } from "../types";
import { loadSliceData } from "./loadSliceData";

export const submitVerificationRequest = actionFactory.createAppAction<VerifyRequest>("submitVerificationRequest");

const sagaActions = actionFactory.createSagaWithProgressActions<VerifyRequest, ApiKeyVerification>(submitVerificationRequest.type);

export function submitVerificationRequestReducers(reducerFactory: IReducerFactory<BuyerVerificationState>): IReducerFactory<BuyerVerificationState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.submitState)              
        .build();
}

async function performAction(_arg: VerifyRequest, _context: SagaAppContext): Promise<ApiKeyVerification> {       
    const result = await _context.marketplaceApiClient.identityVerification.submitVerificationRequest(_arg);    
    _context.dispatch(loadSliceData())
    return result;
}

export function createSubmitVerificationRequestSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performAction,
            appContext: context,
            notificationActions: sagaActions,
            errorDisplay: ErrorDisplayType.Toast,
            errorTitle: "Submit Verification Failed"
        },
        actionPattern: submitVerificationRequest.type,
        debounceInMilliseconds: 250
    });
};