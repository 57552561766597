import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { firstOrUndefined } from "../../../../../utils/arrayHelpers";
import { isValue } from "../../../../../utils/valueHelper";
import { QualityMetricValue } from "../../../../qualityGradeTemplates/models/metricValue";
import { qualityGradeKindAdapter, qualityGradeValueAdapter, qualityMetricKindAdapter, qualityMetricValueAdapter, templateAdapter, templateMetricAdapter } from "../state";

const qualityMetricKindSelectors = qualityMetricKindAdapter.getSelectors((state: RootState) => state.qualityAssessmentEditor.metricKinds);
export const getQualityMetricKinds = qualityMetricKindSelectors.selectAll;
export const getQualityMetricKindById = qualityMetricKindSelectors.selectById;

const qualityMetricValueSelectors = qualityMetricValueAdapter.getSelectors((state: RootState) => state.qualityAssessmentEditor.metricValues);
export const getQualityMetricValues = qualityMetricValueSelectors.selectAll;

export const getQualityMetricValuesByKind = createSelector(
    [
        getQualityMetricValues
    ],
    (allValues) => {
        const kindToValuesMap: { [kindId: number]: QualityMetricValue[] } = {};

        allValues
            .slice()
            .sort((left, right) => left.sequence - right.sequence)
            .forEach(value => {
                let valueList = kindToValuesMap[value.metricKindId];
                if (!isValue(valueList)) {
                    valueList = [];
                    kindToValuesMap[value.metricKindId] = valueList;
                }
                valueList.push(value);
            })

        return kindToValuesMap;
    }
);

export const getQualityMetricValuesForKind = createSelector(
    [
        getQualityMetricValues
    ],
    (allValues) => {
        return (metricKindId: number) => {
            return allValues
                .filter(value => value.metricKindId === metricKindId);
        }
    }
);

const qualityGradeKindSelectors = qualityGradeKindAdapter.getSelectors((state: RootState) => state.qualityAssessmentEditor.gradeKinds);
export const getQualityGradeKinds = qualityGradeKindSelectors.selectAll;

const qualityGradeValueSelectors = qualityGradeValueAdapter.getSelectors((state: RootState) => state.qualityAssessmentEditor.gradeValues);
export const getQualityGradeValues = qualityGradeValueSelectors.selectAll;
export const getQualityGradeValueMap = qualityGradeValueSelectors.selectEntities;

const templateSelectors = templateAdapter.getSelectors((state: RootState) => state.qualityAssessmentEditor.templates);
export const getTemplateById = templateSelectors.selectById;

const templateMetricSelectors = templateMetricAdapter.getSelectors((state: RootState) => state.qualityAssessmentEditor.templateMetrics);
export const getAllTemplateMetrics = templateMetricSelectors.selectAll;

export const getTemplateMetricForMetricKind = createSelector(
    [
        getAllTemplateMetrics,
        getQualityMetricKindById
    ],
    (templateMetrics, metricKind) => {
        return isValue(metricKind)
            ? firstOrUndefined(
                templateMetrics,
                templateMetric => templateMetric.metricKindId === metricKind.id)
            : undefined;
    }
);
