
import { Card } from "primereact/card";
import { ActionStatus } from "../../features/actionStatus/actionStatus";
import { useAppSelector } from "../../store/configureStore";
import { RootState } from "../../store/rootReducer";
import { OverlayWaitDisplay } from "../waitDisplay";

export interface FeatureContentPanelProps {
    loadingStateSelector?: (state: RootState) => ActionStatus;
    title: string;
}

/**
 * Generic container with title, wait display and styling   
 */
export const FeatureContentPanel: React.FC<FeatureContentPanelProps> = ({
    loadingStateSelector = (_: RootState): ActionStatus => ({ isExecuting: false }),
    title,
    children
}) => {

    const loadingState = useAppSelector(loadingStateSelector);

    return <OverlayWaitDisplay
        isWaiting={loadingState.isExecuting}
    >
        <Card className="full-height-content-panel" title={title}>
            {children}
        </Card>
    </OverlayWaitDisplay>
} 