import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { corporateEntityAdapter } from "../state";
import { actionFactory, CorporateEntity, CorporateEntityState } from "../types";

export const loadSliceData = actionFactory.createAppAction("loadSliceData");

const sagaActions = actionFactory.createSagaWithProgressActions<void, CorporateEntity[]>(loadSliceData.type);

export function loadSliceDataReducers(reducerFactory: IReducerFactory<CorporateEntityState>): IReducerFactory<CorporateEntityState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.loadingState)
        .onFulfilled((state, action) => {                        
            corporateEntityAdapter.setAll(state.corporateEntities, action.payload.result);            
        })
        .build();
}

async function performLoad(_arg: void, context: SagaAppContext): Promise<CorporateEntity[]> {   
    return context.marketplaceApiClient.corporateEntity.getCorporateEntities();    
}

export function createLoadSliceDataSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: loadSliceData.type
    });
};