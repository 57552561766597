import { CenterContentResponsivePanel } from "../responsiveContainer";

export interface NotFoundProps {
    message?: string;
}

export const NotFound: React.FC<NotFoundProps> = ({
    message = "The requested content was not found. Try refreshing the page"
}) => {

    return <CenterContentResponsivePanel>
        {message}
    </CenterContentResponsivePanel>

}

