import { IntegerId } from "../../commonModels/integerId";
import { ComparisonOperation } from "./comparisonOperation12";

export enum ConstraintNodeKind {
    And = "And",
    Or = "Or",
    Constraint = "Constraint"
}

export interface TemplateConstraintNode extends IntegerId {
    rootNodeId: number | null;
    parentNodeId: number | null;
    kind: ConstraintNodeKind;

    metricKindId: number | null;
    comparison: ComparisonOperation | null;
    metricValueIds: number[];

    // view property, populated by client after load
    children?: TemplateConstraintNode[];
}

export const isTemplateConstraintNode = (value: TemplateConstraintNode | Partial<TemplateConstraintNode>): value is TemplateConstraintNode => {
    return (value as TemplateConstraintNode).metricKindId !== undefined &&
        (value as TemplateConstraintNode).comparison !== undefined &&
        (value as TemplateConstraintNode).kind !== undefined &&
        (value as TemplateConstraintNode).metricValueIds !== undefined;
}

const getSortValue = (nodeKind: ConstraintNodeKind): number => {
    return nodeKind === ConstraintNodeKind.Constraint
        ? 1
        : 0;
}


export const assignChildrenNodes = (allNodes: TemplateConstraintNode[]) => {

    allNodes.forEach(node => {
        const current = node;
        current.children = allNodes
            .filter(child => child.parentNodeId === current.id)
            .sort((left, right) => {
                if (left.kind === right.kind) {
                    if (left.id < 0 && right.id < 0) {
                        return left.id - right.id;
                    }
                    else if (left.id < 0 && right.id > 0) {
                        return 1;
                    }
                    else if (left.id > 0 && right.id < 0) {
                        return -1;
                    }
                    else {
                        return left.id - right.id;
                    }
                }
                return getSortValue(left.kind) - getSortValue(right.kind);
            });
    });
}