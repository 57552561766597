import { validStates } from '../../../../utils/usStates';
import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { CompanyProfileState } from './types';

export const createInitialState = (): CompanyProfileState => {
    return {
        profileLoadingState: CreateActionStatusInitialState(),
        profileSavingState: CreateActionStatusInitialState(),
        companyProfile: {
            displayName: "",
            id: 0,
            companyType: null,
            stateAbbreviation: "",
            tenantId: "",
            urlName: "",
            contactEmail: "",
            contactPhone: "",
            contactUrl: "",
            subscriptionUrl: ""
        },
        stateSearchResults: validStates,
        logoState: {
            loadState: CreateActionStatusInitialState(),
            saveState: CreateActionStatusInitialState(),
            currentImage: null
        },
        bannerState: {
            loadState: CreateActionStatusInitialState(),
            saveState: CreateActionStatusInitialState(),
            currentImage: null
        }       
    };
};

export const initialState: CompanyProfileState = createInitialState();