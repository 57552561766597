import { TemplateMetric } from "../../../models/templateMetric";
import { templateMetricAdapter } from "../state";
import { actionFactory } from "../types";

export const {

    removeMetricFromTemplate,
    removeMetricFromTemplateReducer,
    removeMetricFromTemplateSagaFactory

} = actionFactory
    .withMappedName("removeMetricFromTemplate")
    .createAsyncAction<TemplateMetric, TemplateMetric[]>({
        actionStatusSelector: state => state.templateMetricSaveState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.quality.removeMetricFromTemplate(arg);
        },
        onFulfilled: (state, action) => {
            templateMetricAdapter.setAll(state.templateMetrics, action.payload.result);
        }
    });