import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { delay } from "../../../utils/delay";
import { isValue } from "../../../utils/valueHelper";
import { HubMethodNames, JoinGroupRequest, JoinGroupResponse, HubMethodCallback } from "./store/types";

export class MarketplaceHub {
 
    private connection: HubConnection | null;
    private readonly initialRetryInterval: number = 1000;
    private retryInterval: number = this.initialRetryInterval;
    private registeredCallbackMethods: string[] = [];
    private registeredCallbacks: HubMethodCallback[] = [];
    private currentGroups: string[] = [];

    constructor() {
        this.connection = null;
    }

    public connect = (url: string, accessToken: string) => {

        if (isValue(this.connection)) {
            this.disconnect();
        }
        this.connection = new HubConnectionBuilder()
            .withUrl(url,
                {
                    accessTokenFactory: () => {
                        return accessToken;
                    }
                })
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    if (retryContext.elapsedMilliseconds > 30000) {                       
                        return 30000;
                    } else {
                        // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                        return retryContext.previousRetryCount * 1000;
                    }
                }
            })
            .build();

        if (isValue(this.connection)) {

            this.connection.onclose((error: Error | undefined) => {
                console.log("SignalR Connection Closed:", error);
                //this.start();
            });

            this.connection.onreconnected(this.onConnected);

            this.registerCallback(HubMethodNames.GroupAck, (response: JoinGroupResponse) => {
                console.log(`GroupAck: ${response.message}`);
            });

            this.start().then((result) => {
                if (result) {
                    this.onConnected();
                }
            });
        }
    }

    public disconnect() {
        const _connection = this.connection;
        if (isValue(_connection)) {

            try {
                this.registeredCallbackMethods.forEach(methodName => {
                    _connection.off(methodName);
                });

                if (_connection.state !== HubConnectionState.Disconnected && _connection.state !== HubConnectionState.Disconnecting) {
                    _connection.stop();
                }
            }
            catch (error) {
                console.log("SignalR failure during disconnect", error);
            }
        }
        this.connection = null;
    }

    /**
     * Registers a callback method to invoke when the hub method with the given name is invoked. If this method will be unregistered,
     * the same method instance must be passed to registerCallback and unregisterCallback.
     * @param methodName The name of the hub method that when invoked will invoke the callback.   
     * @param callback The callback to invoke when hub method is invoked
     */
    public registerCallback = <TMessage>(methodName: string, callback: (message: TMessage) => void) => {
        this.registeredCallbackMethods.push(methodName);
        this.registeredCallbacks.push({
            methodName,
            callback
        });

        if (isValue(this.connection)) {
            this.connection.on(methodName, callback);
        }
    }

    /**
     * Unregisters a previously registered callback. The callback instance must be the same instance used in registerCallback.
     * @param methodName The name of the hub method used during registration
     * @param callback The callback instance used during registration
     */
    public unregisterCallback = <TMessage>(methodName: string, callback: (message: TMessage) => void) => {

        this.registeredCallbacks = this.registeredCallbacks.filter(reg => reg.methodName !== methodName && reg.callback !== callback);

        if (isValue(this.connection)) {
            this.connection.off(methodName, callback);
        }
    }

    private start = async (): Promise<boolean> => {
        if (!isValue(this.connection)) throw new Error("Start cannot be called without a connected HubConnection");

        while (true) {
            try {
                if (!isValue(this.connection)) return false;
                if (this.connection.state !== HubConnectionState.Disconnected) return true;

                await this.connection.start();
                this.retryInterval = this.initialRetryInterval;
                console.log("SignalR Connected.");
                return true;
            } catch (err) {
                console.log("Error duing SignalR start loop", err);
                this.retryInterval = Math.min(this.retryInterval + 2000, 30000);
                console.log(`Retrying connection in ${this.retryInterval} milliseconds`);
                await delay(this.retryInterval);
            }
        }
    };

    private onConnected = () => {

        this.currentGroups.forEach(groupName => {
            const request: JoinGroupRequest = {
                groupName: groupName
            };
            this.connection?.invoke(HubMethodNames.JoinGroup, request);
        });

        this.registeredCallbacks.forEach(reg => {
            this.connection?.on(reg.methodName, reg.callback);
        });

    }

    public joinGroup = (groupName: string) => {
        this.currentGroups.push(groupName);

        if (!!this.connection && this.connection.state === HubConnectionState.Connected) {

            const request: JoinGroupRequest = { groupName: groupName };
            this.connection?.invoke(HubMethodNames.JoinGroup, request);

        }
    }

    public leaveGroup = (groupName: string) => {
        this.currentGroups = this.currentGroups.filter(existingGroup => existingGroup !== groupName);

        if (!!this.connection && this.connection.state === HubConnectionState.Connected) {

            const request: JoinGroupRequest = { groupName: groupName };
            this.connection?.invoke(HubMethodNames.LeaveGroup, request);

        }
    }
}