import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { getAssignedCategories } from "../../categoryAssignment/store/selectors/categoryAssignment";
import { CategoryAssignment } from "../../categoryAssignment/ui/categoryAssignment";
import { cancelCategoryAssignment } from "../store/featureActions/categoryAssignment/cancelCategoryAssignment";
import { completeCategoryAssignment } from "../store/featureActions/categoryAssignment/completeCategoryAssignment";
import { getCategoryAssignmentSaveState, getProductForCategoryAssignment } from "../store/selectors/categoryAssignment";

export const ProductCategoryAssignmentDialog: React.FC = () => {

    const productForCategoryAssignment = useAppSelector(getProductForCategoryAssignment);
    const assignedCategoryIds = useAppSelector(getAssignedCategories);
    const categoryAssignmentSaveState = useAppSelector(getCategoryAssignmentSaveState);
    const dispatch = useDispatch();
    
    const onHideDialog = () => { };

    const onSave = () => {
        dispatch(completeCategoryAssignment(assignedCategoryIds));
    }

    const onCancel = () => {
        dispatch(cancelCategoryAssignment());
    }

    return <Dialog
        onHide={onHideDialog}
        visible={isValue(productForCategoryAssignment)}
        closable={false}
        draggable={false}
        header={`Assign Categories To Item ${productForCategoryAssignment?.metrcName}`}
    >
        <CategoryAssignment />

        <div>
            <Button className="p-ml-2" label="Save" style={{ float: "right" }} onClick={onSave} loading={categoryAssignmentSaveState.isExecuting} />
            <Button label="Cancel" style={{ float: "right" }} onClick={onCancel} disabled={categoryAssignmentSaveState.isExecuting} />
        </div>
    </Dialog>
}