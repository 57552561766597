import { ColumnProps } from "primereact/column";
import React from 'react';

interface CalculatedValueColumnProps<TRow> extends Omit<ColumnProps, "header" | "body" | "columnKey"> {
    header: string,
    columnKey: string,
    getValueFn: (rowData: TRow) => string | null | undefined;
}
const createTemplate = <T,>({ getValueFn }: React.PropsWithChildren<CalculatedValueColumnProps<T>>) => {
    return (rowData: T) => {
        return getValueFn(rowData);
    }
};

export function createCalculatedValueColumnProps<T>(props: CalculatedValueColumnProps<T>): ColumnProps {

    return {
        ...props,
        body: createTemplate(props),
        columnKey: props.columnKey
    };
}
