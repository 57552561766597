import { PricingModel } from "../../../sharedTypes";
import { actionFactory } from "../types";

export const {

    upsertPricingModel,
    upsertPricingModelReducer,
    upsertPricingModelSagaFactory

} = actionFactory
    .withMappedName("upsertPricingModel")
    .createAsyncAction<PricingModel, PricingModel>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.pricing.upsertPricingModels(arg);
        },
        onFulfilled: (state, action) => {
            state.priceModel = action.payload.result;
        }
    });