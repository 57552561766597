import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { createLoadSliceDataSaga } from "../featureActions/loadSliceData";
import { createCompleteEditSaga } from "../featureActions/completeEdit";
import { createDeleteCorporateEntitySaga } from "../featureActions/deleteCorporateEntity";

// Register this method in the root saga
export function createCorporateEntitySagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        createLoadSliceDataSaga(context),
        createCompleteEditSaga(context),
        createDeleteCorporateEntitySaga(context)
    ]
}