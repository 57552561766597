import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { isValue } from "../../../../../utils/valueHelper";
import { MixedMedia } from "../../../../commonModels/mixedMedia";

export const getInventoryMedia = createSelector(
    [
        (state: RootState) => state.inventoryImages.images,
        (state: RootState) => state.inventoryImages.videos
    ],
    (images, videos) => {
        const mixedMediaList: MixedMedia[] = [];

        mixedMediaList.push(...images.slice().sort((left, right) => left.id - right.id));
        mixedMediaList.push(...videos.slice().sort((left, right) => left.id - right.id));

        return mixedMediaList;
    }
);

export const getMediaLoadingState = (state: RootState) => state.inventoryImages.imageLoadState;

export const getShowInventory = (state: RootState) => isValue(state.inventoryImages.inventoryId);

export const getIsLoadingGalleryForInventory = (state: RootState, inventoryId: number) => 
    isValue(state.inventoryImages.inventoryId) &&
    state.inventoryImages.inventoryId === inventoryId &&
    state.inventoryImages.imageLoadState.isExecuting;

