import TreeNode from "primereact/treenode";
import { all } from "../../../../utils/arrayHelpers";
import { hasValue } from "../../../../utils/valueHelper";
import { CategoryView, MenuCategoryView } from "../../general/models/menuCategoryView";

export const createCategoryKey = (categoryView: CategoryView): string => {
    return `Category-${categoryView.id}`;
}
export const isCategoryKey = (key: string): boolean => {
    return key.startsWith("Category");
}
export const createMenuKey = (menuView: MenuCategoryView): string => {
    return `Menu-${menuView.id}`;
}
export const mapCategoryViewToTreeNode = (categoryView: CategoryView): TreeNode => {
    return {
        key: createCategoryKey(categoryView),
        data: {
            name: categoryView.name,
            kind: "Category"
        },
        children: []
    }
}
export const mapMenuViewTreeNode = (menuView: MenuCategoryView): TreeNode => {
    return {
        key: createMenuKey(menuView),
        data: {
            name: menuView.name,
            kind: "Menu"
        },
        children: menuView.categoryViews.map(mapCategoryViewToTreeNode)
    }
}

export const getIdFromKey = (key: string): number => {
    const keyParts = key.split("-");
    return parseInt(keyParts[1]);
}

export interface TreeTableSelectionValue {
    checked: boolean,
    partialChecked: boolean
}

export interface TreeTableSelectionWithKey extends TreeTableSelectionValue {
    key: string,
    value: TreeTableSelectionValue
}

export const isTreeTableSelectionValue = (value: any): value is TreeTableSelectionValue => {
    return hasValue((value as TreeTableSelectionValue).checked) && hasValue((value as TreeTableSelectionValue).partialChecked)
}

export const isTreeTableSelectionWithKey = (value: any): value is TreeTableSelectionWithKey => {
    return hasValue((value as TreeTableSelectionWithKey).checked) &&
        hasValue((value as TreeTableSelectionWithKey).partialChecked) &&
        hasValue((value as TreeTableSelectionWithKey).key)
}

export const getSelectedKeys = (menuViews: MenuCategoryView[], selectedCategoryIds: number[]): { [key: number]: TreeTableSelectionValue } => {
    const selectedKeys: { [key: string]: TreeTableSelectionValue } = {};
    menuViews.forEach(menu => {
        const menuHasAllCategoriesChecked = all(menu.categoryViews, category => selectedCategoryIds.includes(category.id));
        const selectedMenuCategories = menu.categoryViews.filter(category => selectedCategoryIds.includes(category.id));
        if (selectedMenuCategories.length > 0) {
            selectedKeys[createMenuKey(menu)] = { checked: menuHasAllCategoriesChecked, partialChecked: !menuHasAllCategoriesChecked }
            selectedMenuCategories.forEach(category => {
                selectedKeys[createCategoryKey(category)] = { checked: true, partialChecked: false }
            });
        }
    });
    return selectedKeys;
}
