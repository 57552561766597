import clsx from 'clsx';
import { Field, FieldProps, FormikContextType, useFormikContext } from "formik";
import { Checkbox } from 'primereact/checkbox';
import { CSSProperties } from 'react';
import { BooleanKeyOf, getBooleanValue, PropertiesOfType } from '../../utils/typeHelpers';

export interface BooleanFieldProps<T extends PropertiesOfType<T, boolean>> {

    defaultLabel: string;
    fieldName: keyof T & string & BooleanKeyOf<T>;
    containerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    submitOnChange?: boolean;
}

export const BooleanField = <T extends PropertiesOfType<T, boolean>>({

    defaultLabel,
    fieldName,
    labelStyle,
    containerStyle,
    inputStyle,
    submitOnChange = false
}: BooleanFieldProps<T>) => {

    const formikProps: FormikContextType<T> = useFormikContext<T>();
    const labelText = defaultLabel;


    return <div style={containerStyle}>
        <label style={labelStyle} htmlFor={fieldName} >{labelText}</label>
        <Field
            name={fieldName}
        >
            {
                (props: FieldProps<T>) =>
                    <Checkbox
                        style={inputStyle}
                        id={fieldName}
                        name={fieldName}
                        className={clsx({ "p-invalid": !!formikProps.errors[fieldName] })}
                        checked={getBooleanValue(formikProps.values, fieldName) ?? undefined}
                        onChange={async (event) => {
                            await formikProps.setFieldValue(props.field.name, event.target.checked);
                            if (submitOnChange) {
                                formikProps.submitForm();
                            }
                        }}
                    />
            }
        </Field>
        <div>
            <small>{formikProps.errors[fieldName]}</small>
        </div>
    </div>
}
