import clsx from "clsx";
import { Button } from "primereact/button";
import { useMediaQuery } from "react-responsive";
import { smallDeviceQuery } from "../../features/responsiveconfiguration";
import './marketingCard.css';

export interface MarketingCardProps {
    isSelected: boolean,    
    image: string,
    title: string,
    actionTitle: string,
    onActionClick: () => void
}

export const MarketingCard: React.FC<MarketingCardProps> = ({isSelected, image, title, actionTitle, onActionClick}) => {

    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    
    return <div        
        className={clsx("p-shadow-8", "p-d-flex p-jc-start p-ai-center", {
            "p-shadow-16": isSelected,
            "signup-card": !isSmallDevice,
            "signup-card-small": isSmallDevice,
            "p-flex-column": !isSmallDevice,
        })}
    >
        <img
            src={image}
            alt=""
            className={clsx({
                "signup-card-header-selected": !isSmallDevice && isSelected,
                "signup-card-header-small-selected": isSmallDevice && isSelected,
                "signup-card-header": !isSmallDevice,
                "signup-card-header-small": isSmallDevice
            })}
        ></img>
        <h1
            className={clsx({
                "signup-card-title": !isSmallDevice,
                "signup-card-title-small": isSmallDevice
            })}
        >
            {title}
        </h1>
        <div className={clsx("p-d-flex p-jc-center p-ai-center", {
            "signup-card-action-small": isSmallDevice,
            "signup-card-action": !isSmallDevice,
        })} >
            <Button 
                label={isSmallDevice ? title : actionTitle} 
                onClick={onActionClick} 
                className={clsx({
                    "signup-card-action-button-small": isSmallDevice,
                    "signup-card-action-button-large": !isSmallDevice,
                })}
            />
        </div>
    </div>
}