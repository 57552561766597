import { DateTime, FixedOffsetZone } from "luxon";
import { getTypedKeys } from "../../utils/typeHelpers";
import { isValue } from "../../utils/valueHelper";

export type NullableDate = DateTime | null;

export type NullableDateProperties<T> = {
    [property in keyof T as T[property] extends NullableDate ? property : never]: NullableDate
}

/**
 * Converts string properties to Luxon DateTime. Assumes the serialized string was stored in the client local time.
 * @param model The model containing Luxon DateTime properties that are serialized as strings. The serialized string is a date stored in the clients local time zone
 * @param properties The properties of 'model' that should be deserialized
 */
export function deserializeLocalDateTime<T extends NullableDateProperties<T>>(model: T, properties: (keyof NullableDateProperties<T>)[]): void {
    properties.forEach(key => {
        const value = model[key];
        model[key] = EnsureDateTimeOrNull(value) as T[keyof NullableDateProperties<T>];
    });
}

/**
 * Converts string properties to Luxon DateTime. Assumes the serialized string was stored in Utc.
 * @param model The model containing Luxon DateTime properties that are serialized as strings. The serialized string is a date stored in Utc
 * @param properties The properties of 'model' that should be deserialized
 */
export function deserializeUtcDateTime<T extends NullableDateProperties<T>>(model: T, properties: (keyof NullableDateProperties<T>)[]): void {
    properties.forEach(key => {
        const value = model[key];
        model[key] = EnsureDateTimeOrNullUtc(value) as T[keyof NullableDateProperties<T>];
    });
}

export const createDeserializeUtcToLocalDateFns = <T extends NullableDateProperties<T>>(properties: (keyof NullableDateProperties<T>)[]) => {
    const singleFn = (model: T) => {
        deserializeUtcDateTime(model, properties);
        convertUtcToLocal(model, properties);
        return model;
    }
    const setFn = (models: T[]) => {
        models.forEach(singleFn);
        return models;
    }

    return {
        single: singleFn,
        set: setFn
    };
}

export const createDeserializeLocalDateFns = <T extends NullableDateProperties<T>>(properties: (keyof NullableDateProperties<T>)[]) => {
    const singleFn = (model: T) => {
        deserializeLocalDateTime(model, properties);
        return model;
    }
    const setFn = (models: T[]) => {
        models.forEach(singleFn);
        return models;
    }

    return {
        single: singleFn,
        set: setFn
    };
}


export const cloneAndTransformDatesToUtc = <T extends NullableDateProperties<T>>(model: T, properties: (keyof NullableDateProperties<T>)[]) => {
    const targetKeys = new Map<keyof T, keyof T>();
    properties.forEach(property => targetKeys.set(property, property));

    const clone: T = { ...model };
    const keys = getTypedKeys(clone).filter(key => targetKeys.has(key));

    keys
        .forEach(key => {
            const value = clone[key];

            if (isValue(value) && DateTime.isDateTime(value)) {
                clone[key] = value.toUTC() as T[keyof NullableDateProperties<T>];
            }
        });

    return clone;
}

/**
 * Clones the given object and translates all date properties (properties that are of type Luxon.DateTime) to UTC.
 * This is done so that all dates, with the exception of some Metrc dates that are stored in the clients local time,
 * are in UTC at the applications boundary (the api client). When objects come in, dates are translated to local
 * and when objects leave, dates are translated back to UTC.
 * Cloning is done such that any objects in memory have their date properties stay in local time.
 * @param properties
 * @returns 
 */
 export const cloneAndTransformAllDatesToUtcSingle = <T extends { [key: string]: any }>(model: T) => {

    const clone: T = { ...model };
    const keys = Object.keys(clone) as (keyof T)[];
    keys.forEach(key => {
        const value = clone[key];

        if (isValue(value) && DateTime.isDateTime(value)) {
            clone[key] = value.toUTC();
        }
    });

    return clone;
}

export const cloneAndRemoveTimeZone = <T extends NullableDateProperties<T>>(model: T, properties: (keyof NullableDateProperties<T>)[]) => {
    const targetKeys = new Map<keyof T, keyof T>();
    properties.forEach(property => targetKeys.set(property, property));

    const clone: T = { ...model };
    const keys = getTypedKeys(clone).filter(key => targetKeys.has(key));

    keys
        .forEach(key => {
            const value = clone[key];

            if (isValue(value) && DateTime.isDateTime(value)) {
                clone[key] = DateTime.utc(value.year, value.month, value.day, value.hour, value.minute, value.second, value.millisecond) as T[keyof NullableDateProperties<T>];
            }
        });

    return clone;
}

/**
 * Clones the given object and translates all date properties (properties that are of type Luxon.DateTime) to UTC.
 * This is done so that all dates, with the exception of some Metrc dates that are stored in the clients local time,
 * are in UTC at the applications boundary (the api client). When objects come in, dates are translated to local
 * and when objects leave, dates are translated back to UTC.
 * Cloning is done such that any objects in memory have their date properties stay in local time.
 * @param properties
 * @returns 
 */
 export const cloneAndRemoveAllTimeZones = <T extends { [key: string]: any }>(model: T) => {

    const clone: T = { ...model };
    const keys = Object.keys(clone) as (keyof T)[];
    keys.forEach(key => {
        const value = clone[key];

        if (isValue(value)) {
            if (DateTime.isDateTime(value)) {
                clone[key] = DateTime.utc(value.year, value.month, value.day, value.hour, value.minute, value.second, value.millisecond) as T[keyof NullableDateProperties<T>];
            }            
        } 
    });

    return clone;
}



/**
 * Converts Luxon DateTime properties from Utc to Local time.
 * @param model The model containing Luxon DateTime properties. The properties listed are assumed to be stored in Utc
 * @param properties The properties of 'model' that should be converted from Utc to local time
 */
export function convertUtcToLocal<T extends NullableDateProperties<T>>(model: T, properties: (keyof NullableDateProperties<T>)[]): void {
    properties.forEach(key => {
        const value = model[key];
        if (value === null) return;
        model[key] = value.toLocal() as T[keyof NullableDateProperties<T>];
    });
}

export function EnsureDateTimeOrNullUtc(value: DateTime | string | null): DateTime | null {
    if (value === null) {
        return null;
    }
    else if (typeof (value) === "string") {
        return DateTime.fromISO(value, { zone: FixedOffsetZone.utcInstance });
    }
    else {
        return value;
    }
}

function EnsureDateTimeOrNull(value: DateTime | string | null): DateTime | null {
    if (value === null) {
        return null;
    }
    else if (typeof (value) === "string") {
        return DateTime.fromISO(value);
    }
    else {
        return value;
    }
}