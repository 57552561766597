import { createEntityAdapter } from '@reduxjs/toolkit';
import { CreateInitialModalEditState } from '../../../../store/factory/modalEdit/modalEdit';
import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { BulkAssignmentKind, createBulkValueAssignmentInitialState } from '../../../commonModels/bulkAssignmentState';
import { EntityVideo } from '../../../commonModels/entityVideo';
import { BulkCategoryAssignmentState, Product, ProductManagementState, ProductNote } from './types';

export const productsAdapter = createEntityAdapter<Product>();
export const productVideoAdapter = createEntityAdapter<EntityVideo>();
export const productNotesAdapter = createEntityAdapter<ProductNote>();

export const createCategoryAssignmentInitialState = (): BulkCategoryAssignmentState => {
    return {
        savingState: CreateActionStatusInitialState(),
        products: []
    }
}

export const createInitialState = (): ProductManagementState => {
    return {
        loadingState: CreateActionStatusInitialState(),
        products: productsAdapter.getInitialState(),
        latestSyncRequest: null,
        requestProductSyncState: CreateActionStatusInitialState(),
        selectedProducts: [],
        editSaveState: CreateActionStatusInitialState(),
        editProductState: CreateInitialModalEditState(),
        editProductNotes: productNotesAdapter.getInitialState(),
        productNoteSaveStatus: CreateActionStatusInitialState(),
        productNoteLoadStatus: CreateActionStatusInitialState(),

        
        // editImageSaveState: CreateActionStatusInitialState(),
        // mediaLoadState: CreateActionStatusInitialState(),
        // editImages: [],
        editImageSaveState: CreateActionStatusInitialState(),        
        editImages: [],
        editVideos: productVideoAdapter.getInitialState(),        
        addVideoState: CreateInitialModalEditState(),
        videoSaveState: CreateActionStatusInitialState(),
        mediaLoadState: CreateActionStatusInitialState(),

        bulkCategoryAssignmentState: createCategoryAssignmentInitialState(),
        bulkClearCategoryAssignmentState: createCategoryAssignmentInitialState(),
        categoryAssignmentState: {
            loadingState: CreateActionStatusInitialState(),
            savingState: CreateActionStatusInitialState()
        },
        bulkSyncKindAssignmentState: createBulkValueAssignmentInitialState(),
        menuAssignmentsLoadState: CreateActionStatusInitialState(),
        menuAssignments: [],
        pricingModelMap: {},
        pricingModels: [],
        bulkPricingModelAssignmentState: {
            pricingModelIdToAssign: null,
            products: [],
            savingState: CreateActionStatusInitialState(),
            bulkAssignmentKind: BulkAssignmentKind.Assign
        }
    };
};

export const initialState: ProductManagementState = createInitialState();