import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useRef } from "react";
import { useAppSelector } from "../../../../store/configureStore";
import { createDateColumnProps } from "../../../../utils/table/dateColumn";
import { TypedMenuItem } from "../../../../utils/table/typedMenuItem";
import { createNumberColumnProps } from "../../../../utils/table/numberColumn";
import { createOverlayMenuColumnProps } from "../../../../utils/table/overlayMenuColumn";
import { createFilteredTextColumnProps } from "../../../../utils/table/stringColumn";
import { PurchaseOrder } from "../../sharedTypes";
import { getBuyerOrders } from "../store/selectors/orders";

export const OrdersTable: React.FC = () => {

    //const dispatch = useDispatch();
    const tableRef = useRef<DataTable>(null);
    const orders = useAppSelector(getBuyerOrders);

    const onCancelHoldRequest = (_holdRequest: PurchaseOrder) => {
        
    };

    const onScheduleVisit = (_holdRequest: PurchaseOrder) => {
        
    }
    const rowMenuItems: TypedMenuItem<PurchaseOrder>[] = [
        {
            label: "Cancel",
            command: onCancelHoldRequest
        },
        {
            label: "Schedule Visit",
            command: onScheduleVisit
        }
    ];

    const columns: ColumnProps[] = [
        createOverlayMenuColumnProps<PurchaseOrder>({
            field: "id",
            header: "Actions",
            menuTitle: "...",
            menuItems: rowMenuItems,
            columnKey: "id",
            style: { width: "50px" },
            sortable: false
        }),        
        createDateColumnProps<PurchaseOrder>({ field: "createdUtc", header: "Created", style: { width: "120px" } }),        
        createFilteredTextColumnProps<PurchaseOrder>({ field: "status", header: "Status", style: { width: "220px" } }),
        createFilteredTextColumnProps<PurchaseOrder>({ field: "companyName", header: "Vendor", style: { width: "220px" } }),
        createNumberColumnProps<PurchaseOrder>({ field: "total", header: "Item", style: { width: "220px" } })
    ];

    return <DataTable
        ref={tableRef}
        dataKey="id"
        value={orders}
        scrollable
        scrollHeight='calc(100vh - 400px)'        
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
    >

        {columns.map(c => <Column key={c.columnKey ?? c.field} {...c} />)}
    </DataTable>
} 