import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ErrorDisplayType } from "../../../../store/factory/sagaWithProgress/types";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { ErrorContent } from "../../../errorHandling/types";

export const sliceName: string = "error-queue";

export const actionFactory = new ActionFactory<ErrorQueueState>(sliceName);

export interface ErrorQueueEntry {
    error: ErrorContent;
    title: string | null;
    errorDisplayType: ErrorDisplayType;
}

export interface ErrorQueueState {
    loadingState: ActionStatus;
    errorQueue: ErrorQueueEntry[];
}