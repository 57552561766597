import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { any } from "../../../../../utils/arrayHelpers";
import { stringEquals } from "../../../../../utils/valueHelper";
import { messageFromErrorContent } from "../../../../errorHandling/errorMessageHelper";
import { ErrorQueueState } from "../types";
import { enqueueError } from "./enqueueErrorAction";

export function enqueueErrorReducers(reducerFactory: IReducerFactory<ErrorQueueState>): IReducerFactory<ErrorQueueState> {
    return reducerFactory        
        .forAction(enqueueError)
        .addHandler((state, action) => {       
            const errorDetail = messageFromErrorContent(action.payload.error);   

            // dont enqueue duplicates to prevent a cascade of notifications in cases where 
            // server connectivity is poor and multiple calls may fail.
            if (any(state.errorQueue, error => 
                    error.errorDisplayType === action.payload.errorDisplayType &&
                    stringEquals(messageFromErrorContent(error.error), errorDetail) &&
                    stringEquals(error.title, action.payload.title))) return;  

            state.errorQueue.push(action.payload);            
        })
        .build();
}
