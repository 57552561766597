import { actionFactory, UnitOfMeasure } from "../../types";

export const {
    loadUnitOfMeasures,
    loadUnitOfMeasuresReducer,
    loadUnitOfMeasuresSagaFactory

} = actionFactory
    .withMappedName("loadUnitOfMeasures")
    .createAsyncAction<void, UnitOfMeasure[]>({
        actionStatusSelector: state => state.inventoryUnitOfMeasuresState,
        asyncCall: (_, context) => {
            return context.marketplaceApiClient.inventoryManagement.getUnitOfMeasures();
        },
        onFulfilled: (state, action) => {
            state.inventoryUnitOfMeasures = action.payload.result;  
        }
    });