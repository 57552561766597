import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { removeCategoryFromSection } from "../store/featureActions/removeCategoryFromSection";
import { getCategoryById } from "../store/selectors/category";

export interface MenuSectionCategoryViewProps {
    menuSectionId: number;
    categoryId: number,
}

export const MenuSectionCategoryView: React.FC<MenuSectionCategoryViewProps> = ({ menuSectionId, categoryId }) => {

    const dispatch = useDispatch();
    const category = useAppSelector(state => getCategoryById(state, categoryId))

    const onRemoveCategoryFromMenuSection = () => {
        dispatch(removeCategoryFromSection({ menuSectionId, categoryId }))
    }

    return <div className="p-shadow-3 p-mb-2 p-mr-3 p-p-2" >
        <div className="p-d-flex">
            <div className="p-mr-2">
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined" style={{width: "25px", height: "25px"}} onClick={onRemoveCategoryFromMenuSection} />
            </div>
            <div className="p-mr-2">
                <div>{category?.name}</div>
            </div>
        </div>
    </div>
}