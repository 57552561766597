import ApiClient from "../../../apiCommunication/apiClient";
import { CorporateEntity } from "../store/types";

export default class CorporateEntityAdmin {

    apiClient: ApiClient;    

    constructor(apiClient: ApiClient) {             
        this.apiClient = apiClient;
    }

    getCorporateEntities = (): Promise<CorporateEntity[]> => {
        return this
            .apiClient
            .executeGet<CorporateEntity[]>(`CorporateEntity`);
    };

    saveCorporateEntity = (entity: CorporateEntity): Promise<CorporateEntity> => {

        return this
                .apiClient
                .executePostWithResult<CorporateEntity>(`CorporateEntity`, entity);
            
    };

    deleteCorporateEntity = (id: number): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`CorporateEntity/${id}`);
    };
}