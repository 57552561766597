import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { WaitDisplay } from "../../../components/waitDisplay";
import { useAppSelector } from "../../../store/configureStore";
import { useMountEffect } from "../../../utils/useEffectHelper";
import { isValue } from "../../../utils/valueHelper";
import { logOutConfirmationRoute } from "../routes/authRoutes";
import { completeLogout } from "../store/featureActions/completeLogout";
import { getAuthenticationState } from "../store/selectors/authState";

export const CompleteSignOut = () => {
    
    const dispatch = useDispatch();  
    const completeLogoutState = useAppSelector(getAuthenticationState);

    useMountEffect(() => {       
        dispatch(completeLogout());
    });
    
    return isValue(completeLogoutState.ErrorMessage)
        ? <div>An error ocurred while logging out</div>
        : isValue(completeLogoutState.CurrentUser) 
            ? <WaitDisplay />
            : <Redirect to={logOutConfirmationRoute.path}/>
}