import { DataView, DataViewLayoutType } from 'primereact/dataview';
import { useDispatch } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import { OverlayWaitDisplay } from "../../../../components/waitDisplay";
import { useAppSelector } from "../../../../store/configureStore";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { smallDeviceQuery } from '../../../responsiveconfiguration';
import { loadSectionInventory } from "../store/featureActions/loadSectionInventory";
import { getInventoryForSection, getInventoryLoadingStateForSection, getSectionLayout } from "../store/selectors/vendorMenu";
import { InventoryView } from "../store/types";
import { InventoryFlipCard } from './inventoryFlipCard';
import { InventoryListCard } from './inventoryListCard';
import { InventoryListCardHeader } from './inventoryListCardHeader';
import './menuSection.css';
import { MenuSectionTableView } from './menuSectionTableView';
import { SmallDeviceSectionListView } from './smallDeviceSectionListView';

export interface MenuSectionProps {
    sectionId: number;
}

export const MenuSection: React.FC<MenuSectionProps> = ({ sectionId }) => {

    const dispatch = useDispatch();

    const inventoryLoadState = useAppSelector(state => getInventoryLoadingStateForSection(state, sectionId));
    const inventory = useAppSelector(state => getInventoryForSection(state, sectionId));
    const isSmallDevice = useMediaQuery(smallDeviceQuery);
    const sectionLayout = useAppSelector(getSectionLayout);

    useMountEffect(() => {
        if (inventory.length === 0) {
            dispatch(loadSectionInventory({ sectionId: sectionId }))
        }
    });

    const itemTemplate = (item: InventoryView, layout: DataViewLayoutType): React.ReactNode => {

        if (layout === "grid") {
            return <InventoryFlipCard inventoryId={item.id} />            
        }
        else {
            return <InventoryListCard key={item.id} inventoryId={item.id} />
        }
    }

    return <OverlayWaitDisplay
        isWaiting={inventoryLoadState.isExecuting}
    >
        {
            isSmallDevice && sectionLayout === 'list'
                ? <SmallDeviceSectionListView sectionId={sectionId} />
                : sectionLayout === "table"
                    ? <MenuSectionTableView sectionId={sectionId} />
                    : < DataView
                        className='menu-inv-dataview'
                        dataKey="id"
                        itemTemplate={itemTemplate}
                        layout={sectionLayout}
                        value={inventory}
                        paginator
                        rows={50}
                        paginatorTemplate={
                            isSmallDevice
                                ? "PrevPageLink PageLinks NextPageLink"
                                : "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                        }
                        paginatorPosition={sectionLayout === 'list' ? "bottom" : "both"}
                        pageLinkSize={3}
                        alwaysShowPaginator={false}
                        header={
                            sectionLayout === 'grid'
                                ? undefined
                                : <InventoryListCardHeader />
                        }
                    />
        }
    </OverlayWaitDisplay>
}