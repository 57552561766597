import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { createCompleteBulkCategoryAssignmentSaga } from "../featureActions/bulkCategoryAssignment/completeBulkCategoryAssignment";
import { createCompleteBulkClearCategoryAssignmentSaga } from "../featureActions/bulkClearCategoryAssignment/completeBulkClearCategoryAssignment";
import { createCompleteBulkExcludeSaga } from "../featureActions/bulkExcludeFromMarketplace/completeBulkExclude";
import { bulkSetReadyDateSagaFactory } from "../featureActions/bulkSetReadyDate";
import { createBeginCategoryAssignmentSaga } from "../featureActions/categoryAssignment/beginInventoryCategoryAssignment";
import { createCompleteCategoryAssignmentSaga } from "../featureActions/categoryAssignment/completeInventoryCategoryAssignment";
import { editInventorySagaFactory } from "../featureActions/InventoryDetails/editInventory";
import { addInventorySagaFactory } from "../featureActions/InventoryDetails/addInventory";
import { addVideoSagaFactory } from "../featureActions/inventoryImages/addVideo";
import { createDeleteInventoryImageSaga } from "../featureActions/inventoryImages/deleteInventoryImage";
import { deleteInventoryVideoSagaFactory } from "../featureActions/inventoryImages/deleteInventoryVideo";
import { loadInventoryMediaSagaFactory } from "../featureActions/inventoryImages/loadInventoryMedia";
import { createUpdateImageDetailsSaga } from "../featureActions/inventoryImages/updateImageDetails";
import { createUploadNewInventoryImageSaga } from "../featureActions/inventoryImages/uploadNewInventoryImage";
import { upsertInventoryVideoSagaFactory } from "../featureActions/inventoryImages/upsertInventoryVideo";
import { loadInventorySagaFactory } from "../featureActions/loadInventory";
import { loadInventoryByIdsSagaFactory } from "../featureActions/loadInventoryByIds";
import { loadMenuAssignmentsSagaFactory } from "../featureActions/loadMenuAssignments";
import { loadSliceDataSagaFactory } from "../featureActions/loadSliceData";
import { deleteInventoryNoteSagaFactory } from "../featureActions/notes/deleteInventoryNote";
import { deleteInventorySagaFactory } from "../featureActions/InventoryDetails/deleteInventory";
import { loadInventoryNotesSagaFactory } from "../featureActions/notes/loadInventoryNotes";
import { loadInventoryLicensesSagaFactory } from "../featureActions/licenses/loadInventoryLicenses";
import { loadUnitOfMeasuresSagaFactory } from "../featureActions/unitOfMeasures/loadUnitOfMeasures";
import { upsertInventoryNoteSagaFactory } from "../featureActions/notes/upsertInventoryNote";
import { completeBulkQualityGradeAssignmentSagaFactory } from "../featureActions/qualityGrades/completeBulkQualityGradeAssignment";
import { createQualityAssessmentSagaFactory } from "../featureActions/qualityGrades/createQualityAssessment";
import { removeQualityAssessmentSagaFactory } from "../featureActions/qualityGrades/removeQualityAssessment";
import { createLoadLatestSyncRequestSaga } from "../featureActions/sync/loadLatestSyncRequest";
import { createRequestInventorySyncSaga } from "../featureActions/sync/requestInventorySync";
import { syncInventorySagaFactory } from "../featureActions/syncInventory";

// Register this method in the root saga
export function createInventoryManagementSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [
        loadSliceDataSagaFactory(context),
        loadInventorySagaFactory(context),        
        createLoadLatestSyncRequestSaga(context),
        createRequestInventorySyncSaga(context),
        editInventorySagaFactory(context),
        addInventorySagaFactory(context),
        createCompleteCategoryAssignmentSaga(context),
        createCompleteBulkCategoryAssignmentSaga(context),
        createDeleteInventoryImageSaga(context),
        loadInventoryMediaSagaFactory(context),
        createUpdateImageDetailsSaga(context),
        createUploadNewInventoryImageSaga(context),
        createCompleteBulkExcludeSaga(context),
        createBeginCategoryAssignmentSaga(context),
        loadMenuAssignmentsSagaFactory(context),
        createCompleteBulkClearCategoryAssignmentSaga(context),
        completeBulkQualityGradeAssignmentSagaFactory(context),
        upsertInventoryVideoSagaFactory(context),
        deleteInventoryVideoSagaFactory(context),
        addVideoSagaFactory(context),
        createQualityAssessmentSagaFactory(context),
        removeQualityAssessmentSagaFactory(context),
        loadInventoryByIdsSagaFactory(context),
        syncInventorySagaFactory(context),
        bulkSetReadyDateSagaFactory(context),
        deleteInventoryNoteSagaFactory(context),
        deleteInventorySagaFactory(context),
        loadInventoryNotesSagaFactory(context),
        upsertInventoryNoteSagaFactory(context),
        loadInventoryLicensesSagaFactory(context),
        loadUnitOfMeasuresSagaFactory(context)
    ]
}