import { isValue } from "../../../../../utils/valueHelper";
import { serializeTableState } from "../logic/settingsSerialization";
import { userTableSettingsAdapter } from "../state";
import { actionFactory, UpsertTableSettingsRequest, UserTableSettings } from "../types";
import { selectTableSettings } from "./selectTableSettings";

export const {

    saveNewUserTableSettings,
    saveNewUserTableSettingsReducer,
    saveNewUserTableSettingsSagaFactory

} = actionFactory
    .withMappedName("saveNewUserTableSettings")
    .createAsyncAction<{ viewId: string, name: string }, UserTableSettings>({
        actionStatusSelector: state => state.saveAsState.savingState,
        asyncCall: (arg, context) => {
            const sliceState = context.getState().userTableSettings;
            const currentTableSettings = sliceState.viewSettings.entities[arg.viewId];
            if (!isValue(currentTableSettings)) throw new Error("Save settings failed: current table settings were not available");

            const newUserTableSettings: UpsertTableSettingsRequest = {
                id: 0,
                name: arg.name,
                rank: 0,
                schemaVersion: 1,
                viewId: arg.viewId,
                settings: serializeTableState(currentTableSettings.currentState)
            }

            return context.marketplaceApiClient.userTableSettings.upsertUserTableSettings(newUserTableSettings);
        },
        onFulfilled: (state, action) => {
            userTableSettingsAdapter.upsertOne(state.userTableSettings, action.payload.result);
            state.saveAsState.visible = false;
            state.saveAsState.newName = "";
            state.saveAsState.viewId = "";
        },
        successCallback: (arg, result, context) => {
            context.dispatch(selectTableSettings({ viewId: arg.viewId, id: result.id }));
        }
    });