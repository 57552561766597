import { createEntityAdapter } from '@reduxjs/toolkit';
import { CreateInitialModalEditState } from '../../../../store/factory/modalEdit/modalEdit';
import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { Contact, CustomerNote, SalesOrder } from '../../common/types';
import { CustomerEditorState } from './types';

export const customerNoteAdapter = createEntityAdapter<CustomerNote>();
export const salesOrderAdapter = createEntityAdapter<SalesOrder>();
export const contactAdapter = createEntityAdapter<Contact>();

export const createInitialState = (): CustomerEditorState => {
    return {
        loadingState: CreateActionStatusInitialState(),
        saveCustomerNoteStatus: CreateActionStatusInitialState(),
        customer: null,
        customerNotes: customerNoteAdapter.getInitialState(),
        customerOrders: salesOrderAdapter.getInitialState(),
        customerContacts: contactAdapter.getInitialState(),
        editContactState: CreateInitialModalEditState()
    };
};

export const initialState: CustomerEditorState = createInitialState();