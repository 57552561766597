import { Button } from 'primereact/button';
import { ColumnProps } from "primereact/column";
import { confirmPopup } from 'primereact/confirmpopup';
import React, { MouseEvent } from 'react';
import { isValue } from '../valueHelper';

// Action
export interface ActionColumnOptions<T> extends Omit<ColumnProps, "field" | "header" | "body"> {        
    header: string;
    actionName: string | ((value: T) => string);
    loading?: boolean;
    disabled?: boolean;
    actionHandler: (value: T) => void;
    promptBeforeInvoke?: string;
    buttonClassName?: string;
    buttonIcon?: string;    
}
function createActionTemplate<T>(options: ActionColumnOptions<T>): (rowData: T) => React.ReactNode {
    return (value: T) => {

        const onInvokeAction = (event: MouseEvent<HTMLButtonElement>) => {

            if (isValue(options.promptBeforeInvoke)) {
                confirmPopup({
                    target: event.currentTarget,
                    message: options.promptBeforeInvoke,
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        options.actionHandler(value);
                    },
                    reject: () => { }
                });
            }
            else {
                options.actionHandler(value);
            }

        };

        return <Button 
            label={ typeof(options.actionName) === "string" ? options.actionName : options.actionName(value)} 
            disabled={options.disabled} 
            loading={options.loading} 
            onClick={onInvokeAction}
            className={options.buttonClassName}
            
            icon={options.buttonIcon}            
        ></Button>;
    };
}

export function createActionColumnProps<T>(
    props: ActionColumnOptions<T>
): ColumnProps {
    return {
        columnKey: props.columnKey,
        ...props,
        body: createActionTemplate(props)
    };
}
