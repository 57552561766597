import { IntegerId } from "../../../../../commonModels/integerId";
import { inventoryNotesAdapter } from "../../state";
import { actionFactory, InventoryNote } from "../../types";

export const {

    loadInventoryNotes,
    loadInventoryNotesReducer,
    loadInventoryNotesSagaFactory

} = actionFactory
    .withMappedName("loadInventoryNotes")
    .createAsyncAction<IntegerId, InventoryNote[]>({
        actionStatusSelector: state => state.inventoryNoteLoadStatus,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.inventoryManagement.getInventoryNotes(arg.id);
        },
        onFulfilled: (state, action) => {
            inventoryNotesAdapter.setAll(state.editInventoryNotes, action.payload.result);            
        }
    });