import { LocaleOptions, DateTimeFormatOptions, DateTime } from 'luxon';
import { isValue } from './valueHelper';

export const fullDateDisplay: LocaleOptions & DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short"
};

export function formatToShortDateString(dateValue: string | DateTime | Date | null | undefined): string {
    if (!isValue(dateValue)) return "";

    let dateTimeValue: DateTime;

    if (typeof (dateValue) === "string") {
        dateTimeValue = DateTime.fromISO(dateValue);
    }
    else if (DateTime.isDateTime(dateValue)) {
        dateTimeValue = dateValue;
    }
    else {
        dateTimeValue = DateTime.fromJSDate(dateValue)
    }

    return dateTimeValue.toLocal().toLocaleString({ year: "numeric", month: "2-digit", day: "2-digit" });
}

export function formatToDateAndTimeString(dateValue: string | DateTime | Date | null | undefined): string {
    if (!isValue(dateValue)) return "";

    let dateTimeValue: DateTime;

    if (typeof (dateValue) === "string") {
        dateTimeValue = DateTime.fromISO(dateValue);
    }
    else if (DateTime.isDateTime(dateValue)) {
        dateTimeValue = dateValue;
    }
    else {
        dateTimeValue = DateTime.fromJSDate(dateValue)
    }

    return dateTimeValue.toLocaleString(fullDateDisplay);
}