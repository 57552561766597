import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/rootReducer";
import { isValue } from "../../../../utils/valueHelper";

export const getAccessToken = createSelector(
    [
        (state: RootState) => state.authentication
    ],
    (authState) => {
        if (!isValue(authState.CurrentUser)) return null;
        if (!isValue(authState.CurrentUser.userSettings)) return null;        
        if (!isValue(authState.CurrentUser.userSettings.access_token)) return null;
        return authState.CurrentUser.userSettings.access_token;
    }
);