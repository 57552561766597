import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { VendorTopPanelState } from './types';

export const createInitialState = (): VendorTopPanelState => {
    return {        
        vendorLogo: null,
        vendorLogoLoadingState: CreateActionStatusInitialState(),
        vendorBanner: null,
        vendorBannerLoadingState: CreateActionStatusInitialState(),
    };
};

export const initialState: VendorTopPanelState = createInitialState();