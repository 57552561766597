import { FormikHelpers } from "formik";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { BooleanField } from "../../../../components/formik/booleanField";
import { FormikEmbeddedEditor } from "../../../../components/formik/embeddedEditor";
import { StringField } from "../../../../components/formik/stringField";
import { createLocalImageUrlFromEntityImage } from "../../../../utils/imageHelper";
import { EntityImage } from "../../../commonModels/entityImage";
import { ImageDetailsUpdate } from "../../inventoryManagement/store/types";

const imageSize: string | number = "150px";

export interface ImageActions {   
    onSave: (update: ImageDetailsUpdate) => void;
    onDelete: (data: EntityImage) => void;
}

export interface ImageListDisplayProps extends ImageActions {
    data: EntityImage;
    isMenuThumbnail: boolean;    
}

export const ImageListDisplay: React.FC<ImageListDisplayProps> = ({ data, isMenuThumbnail, onDelete, onSave }) => {

    const onSubmit = (values: ImageDetailsUpdate, _formikHelpers: FormikHelpers<ImageDetailsUpdate>): void | Promise<any> => {
        onSave(values);        
    };

    const initialValue: ImageDetailsUpdate = {
        id: data.id,
        imageTitle: data.imageTitle,
        useAsMenuThumbnail: isMenuThumbnail
    };

    const onDeleteImage = () => {
        onDelete(data);
    }

    return <FlexRowContainer className="entity-media-list-display" key={data.id}>

        <img src={createLocalImageUrlFromEntityImage(data)} alt={data.imageTitle} width={imageSize} height={imageSize} />

        <FormikEmbeddedEditor<ImageDetailsUpdate>
            initialValues={initialValue}
            onSave={onSubmit}
            className="entity-media-details-form"
        >
            <FlexColumnContainer gap="20px">

                <StringField<ImageDetailsUpdate>
                    fieldName="imageTitle"
                    defaultLabel="Title"
                    submitOnBlur={true}
                    inputStyle={{ width: "100%" }}
                />

                <BooleanField<ImageDetailsUpdate>
                    defaultLabel="Use as menu thumbnail"
                    fieldName="useAsMenuThumbnail"
                    submitOnChange={true}
                    labelStyle={{ marginRight: "20px" }}
                />
            </FlexColumnContainer>

        </FormikEmbeddedEditor>


        <Button onClick={onDeleteImage} style={{ float: "right" }} icon={PrimeIcons.TIMES} label="Delete" />

    </FlexRowContainer>
}