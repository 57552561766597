import { FlexRowContainer } from '../../../../components/containers/flexRowContainer';
import { useAppSelector } from '../../../../store/configureStore';
import { getPrimaryFont } from '../store/selectors/menuFonts';
import './inventoryListCard.css';

export const InventoryListCardHeader: React.FC = () => {

    const primaryFont = useAppSelector(getPrimaryFont);    

    return <FlexRowContainer centerContent width="100vw" gap="10px">

        <div className='node-list-card-header-column' style={{ width: '120px', fontFamily: primaryFont }}>Image</div>

        <div className='node-list-card-header-column' style={{ width: '240px', fontFamily: primaryFont }}>Product</div>

        <div className='node-list-card-header-column' style={{ width: '100px', fontFamily: primaryFont }}>Grade</div>

        <div className='node-list-card-header-column' style={{ width: '100px', fontFamily: primaryFont }}>Quantity</div>

        <div className='node-list-card-header-column' style={{ width: '100px', fontFamily: primaryFont }}>Unit Price</div>

        <div className='node-list-card-header-column' style={{ width: '100px', fontFamily: primaryFont }}>THC %</div>

        <div className='node-list-card-header-column' style={{ width: '100px', fontFamily: primaryFont }}>CBD %</div>

        <div className='node-list-card-header-column' style={{ width: '100px', fontFamily: primaryFont }}>Ready</div>

    </FlexRowContainer >

}