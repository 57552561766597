import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, SearchState } from "../types";

export const setSearchText = actionFactory.createAppAction<string>("setSearchText");

export function setSearchTextReducers(reducerFactory: IReducerFactory<SearchState>): IReducerFactory<SearchState> {
    return reducerFactory
        .forAction(setSearchText)
        .addHandler((state, action) => {
            state.searchRequest.searchText = action.payload;
        })
        .build();
}
