import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { isFunction } from "lodash";
import { CSSProperties } from "react";
import { useAppSelector } from "../../store/configureStore";
import { RootState } from "../../store/rootReducer";
import { createValueSelector } from "../../utils/selectors";
import { isValue } from "../../utils/valueHelper";

export interface FormikEmbeddedEditorProps<T> {
    initialValues: ((state: RootState) => T) | T;
    validationSchema?: any | (() => any);
    onSave: (value: T, formikHelpers: FormikHelpers<T>) => void;
    style?: CSSProperties;
    className?: string;
}

export const FormikEmbeddedEditor = <T extends FormikValues>({
    initialValues,
    validationSchema,
    onSave,
    style,
    className,
    children
}: React.PropsWithChildren<FormikEmbeddedEditorProps<T>>) => {

    const selector = isFunction(initialValues) ? initialValues : createValueSelector<RootState, T>(initialValues);
    const editState: T = useAppSelector(selector);

    const onSubmit = (value: T, formikHelpers: FormikHelpers<T>): void | Promise<any> => {
        onSave(value, formikHelpers);
        formikHelpers.setSubmitting(false);
    };


    return <div
        style={style}
        className={className}
    >
        {
            isValue(editState) &&
            <Formik<T>
                initialValues={editState}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {
                    (_formikProps) => <Form autoComplete="off">
                        {children}

                        {/* <div>{JSON.stringify(_formikProps.errors)}</div> */}

                    </Form>
                }
            </Formik>
        }
    </div>
}