import { DateTime } from "luxon";
import { createUtcDateSerializationFunction } from "../apiCommunication/serialization";
import { QueueStatus } from "./queueStatus";

export interface SyncRequestDto {
    tenantId: string;
    kind: string;
    correlationId: string;
    status: QueueStatus;
    statusTimeUtc: DateTime | null;
    errorDetail: string;
}

export const deserializeSyncRequestDates = createUtcDateSerializationFunction<SyncRequestDto>({ properties: ["statusTimeUtc"] });