export const primaryFontFamilies: string[] = [
    'Abel-Regular',
    'AbrilFatface-Regular',
    'DancingScript-Regular',
    'DelaGothicOne-Regular',
    'FredokaOne-Regular',
    'GravitasOne-Regular',
    'JosefinSans-Regular',
    'Montserrat-Regular',
    'Mulish-Regular',
    'Poppins-Regular',
    'Raleway-Regular',
    'Roboto-Regular',
    'Sanchez-Regular',
    'TradeWinds-Regular',
    'Ubuntu-Regular',
    'Ultra-Regular',
    'WorkSans-Regular'
];
 
export const secondaryFontFamilies: string[] = [
    'Abel-Regular',    
    'DancingScript-Regular',       
    'JosefinSans-Regular',
    'Montserrat-Regular',
    'Mulish-Regular',
    'Poppins-Regular',
    'Raleway-Regular',
    'Roboto-Regular',
    'Sanchez-Regular',    
    'Ubuntu-Regular',    
    'WorkSans-Regular'
];