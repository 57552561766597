import { createSlice } from '@reduxjs/toolkit';
import { createResetStateReducer, ReducerFactory } from '../../../../store/factory/reducerFactory';
import { addToFavoritesReducer } from './featureActions/addToFavorites';
import { loadDefaultThumbnailReducer } from './featureActions/loadDefaultThumbnail';
import { loadInventoryThumbnailReducer } from './featureActions/loadInventoryThumbnail';
import { loadIsFavoriteReducer } from './featureActions/loadIsFavorite';
import { loadLicensesReducer } from './featureActions/loadLicenses';
import { loadLiveMenuReducer } from './featureActions/loadLiveMenu';
import { loadSectionInventoryReducer } from './featureActions/loadSectionInventory';
import { loadSectionThumbnailsReducer } from './featureActions/loadSectionThumbnails';
import { loadVendorReducer } from './featureActions/loadVendor';
import { refreshInventoryReducers } from './featureActions/refreshInventory';
import { removeFromFavoritesReducer } from './featureActions/removeFromFavorites';
import { setCustomerRouteNameReducers } from './featureActions/setCustomerRouteName';
import { showOptionsDialogReducers } from './featureActions/setOptionsDialogVisibility';
import { setRefreshIntervalReducer } from './featureActions/setRefreshInterval';
import { setSearchTextReducers } from './featureActions/setSearchText';
import { setSectionLayoutReducers } from './featureActions/setSectionLayout';
import { setSortReducers } from './featureActions/setSort';
import { createInitialState, initialState } from './state';
import { sliceName } from './types';

const slice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        resetState: createResetStateReducer(createInitialState)
    },
    extraReducers: (builder) => {
        ReducerFactory
            .forSlice(builder)
            .add(loadLiveMenuReducer)                        
            .add(loadSectionInventoryReducer)
            .add(setCustomerRouteNameReducers)            
            .add(loadVendorReducer)
            .add(setSearchTextReducers)
            .add(loadIsFavoriteReducer)
            .add(addToFavoritesReducer)
            .add(removeFromFavoritesReducer)
            .add(setSortReducers)
            .add(showOptionsDialogReducers)
            .add(setRefreshIntervalReducer)
            .add(refreshInventoryReducers)
            .add(setSectionLayoutReducers)
            .add(loadInventoryThumbnailReducer)
            .add(loadSectionThumbnailsReducer)
            .add(loadLicensesReducer)
            .add(loadDefaultThumbnailReducer);
            
            // Create your feature actions in ./featureActions and then add them to the reducer factory here
            // with .Add(<your-action-reducer-name>);
            
    }
});

export default slice.reducer;

export const { resetState } = slice.actions;