import { MenuItem } from 'primereact/menuitem';
import { any } from '../../../utils/arrayHelpers';
import { isValue } from '../../../utils/valueHelper';
import { SecurityRole } from '../../authorization/roles';
import { isParameterizedRoute, isSecurityRolePredicate } from '../routes/types';
import { AppMenuModel } from './logicalMenu';

const userHasAccessToMenu = (model: AppMenuModel, userRoles: SecurityRole[]): boolean => {
  if (isSecurityRolePredicate(model.role)) {
    return model.role(userRoles) || any(model.children, childMenu => userHasAccessToMenu(childMenu, userRoles));
  }
  else {
    return model.role === SecurityRole.Anonymous ||
      userRoles.includes(model.role) ||
      any(model.children, childMenu => userHasAccessToMenu(childMenu, userRoles));
  }
};

export const mapMenuModelToMenu = (model: AppMenuModel, userRoles: SecurityRole[], onNavigate: (navigateTo: string) => void): MenuItem | undefined => {

  if (!userHasAccessToMenu(model, userRoles)) return undefined;

  let linkTo: string = !!model.routeModel
    ? !!model.routeParameter && isParameterizedRoute(model.routeModel)
      ? model.routeModel.createPath(model.routeParameter)
      : model.routeModel.path
    : "";

  const menuItem: MenuItem = {
    label: model.title,
    icon: model.icon
  }

  menuItem.command = !!model.children && model.children.length > 0
    ? undefined
    : () => onNavigate(linkTo);

  if (!!model.children && model.children.length > 0) {

    menuItem.items = [
      ...model.children
        .map(childModel => mapMenuModelToMenu(childModel, userRoles, onNavigate))
        .filter(isValue)
    ];

    menuItem.className = "node-parentmenu";
  }

  return menuItem;
}