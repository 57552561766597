import { QualityMetricKind } from "../../../models/metricKind";
import { actionFactory } from "../types";

export const {

    selectQualityMetricKind,
    selectQualityMetricKindReducer

} = actionFactory
    .withMappedName("selectQualityMetricKind")
    .createAction<QualityMetricKind | null>((state, action) => {
        state.selectedMetricKindId = action.payload?.id ?? null;
    })