import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ValidationResponder } from "../../../../components/formikExtensions/validationResponderProps";
import { OverlayWaitDisplay } from '../../../../components/waitDisplay';
import { useAppSelector } from '../../../../store/configureStore';
import { useMountEffect } from '../../../../utils/useEffectHelper';
import { loadMarketplaceProfile } from '../../../marketplaceUserProfile/manageProfile/store/featureActions/loadProfile';
import { updateProfile } from '../../../marketplaceUserProfile/manageProfile/store/featureActions/updateProfile';
import { getUserProfileLoadingState, getUserProfileSavingState } from '../../../marketplaceUserProfile/manageProfile/store/selectors/actionStatus';
import { getUserProfile } from '../../../marketplaceUserProfile/manageProfile/store/selectors/userProfile';
import { MarketplaceProfile } from '../../../marketplaceUserProfile/manageProfile/store/types';
import { UserProfileEditor } from '../../../marketplaceUserProfile/manageProfile/ui/userProfileEditor';
import { marketPlaceProfileValidationSchema } from '../../../marketplaceUserProfile/validations';
import { setUserProfileValid } from '../store/featureActions/setUserProfileIsValid';
import './index.css';

export interface UserProfileFormProps {
    formikRef: React.Ref<FormikProps<Partial<MarketplaceProfile>>>;
}

export const UserProfileForm: React.FC<UserProfileFormProps> = ({ formikRef }) => {

    const dispatch = useDispatch();

    const userProfile: Partial<MarketplaceProfile> = useAppSelector(getUserProfile);
    const loadingState = useAppSelector(getUserProfileLoadingState);
    const savingState = useAppSelector(getUserProfileSavingState);

    useMountEffect(() => {        
        dispatch(loadMarketplaceProfile());
    });

    const onSubmit = (values: Partial<MarketplaceProfile>, formikHelpers: FormikHelpers<Partial<MarketplaceProfile>>): void | Promise<any> => {
        dispatch(updateProfile(values));
        formikHelpers.setSubmitting(false);
    };

    const onChange = (isValid: boolean) => {
        dispatch(setUserProfileValid(isValid));
    }

    return <OverlayWaitDisplay
        isWaiting={loadingState.isExecuting || savingState.isExecuting}
    >
        <Formik
            innerRef={formikRef}
            initialValues={userProfile}
            enableReinitialize={true}
            validationSchema={marketPlaceProfileValidationSchema}
            validateOnMount={true}
            onSubmit={onSubmit}
        >
            <Form autoComplete="off">
                <ValidationResponder handleValidityChanged={onChange} />
                <UserProfileEditor />
            </Form>
        </Formik>
    </OverlayWaitDisplay>
}