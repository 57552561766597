import { Dispatch } from "@reduxjs/toolkit";
import { showMediaForInventory } from "../../inventoryImageGallery/store/featureActions/showMediaForInventory";
import { InventoryView } from "../store/types";
import { History } from 'history';
import { productDetailsRoute } from "../../../navigation/routes";
import { showVendorContactDetails } from "../../../dialogs/vendor/store/featureActions/loadVendor";
import { isValue } from "../../../../utils/valueHelper";

export interface InventoryCommand {
    label: string,
    command: (inventory: InventoryView) => void
}

export const createMenuItems = (dispatch: Dispatch<any>, history: History, vendorRouteName: string, menuUrlName: string): InventoryCommand[] => [
    {
        label: "View product media",
        command: (inv) => dispatch(showMediaForInventory(inv.id))
    },
    {
        label: "View product page",
        command: (inv => {
            history.push(productDetailsRoute.createPath(vendorRouteName, menuUrlName, inv.id))
        })
    },
    {
        label: "Contact vendor",
        command: (_ => {
            dispatch(showVendorContactDetails(vendorRouteName));
        })
    }
];

export const createProductDetailsMenuItems = (dispatch: Dispatch<any>, vendorRouteName?: string | null): InventoryCommand[] => {
    const commands: InventoryCommand[] = [];

    commands.push({
        label: "View product media",
        command: (inv) => dispatch(showMediaForInventory(inv.id))
    });

    if (isValue(vendorRouteName)) {
        commands.push({
            label: "Contact vendor",
            command: (_ => {
                dispatch(showVendorContactDetails(vendorRouteName));
            })
        })
    }

    return commands;
}
