import * as yup from 'yup';
import { VerifyRequest } from "../store/types";

export const verifyRequestValidationSchema: yup.SchemaOf<VerifyRequest> = yup.object().shape({
    apiKey: yup.string()           
                    .min(1, "Api Key must be at least 1 character")
                    .max(255, "Api Key must be at most 255 characters")         
                    .required("Api Key is required"),

    license: yup.string()                    
                    .min(1, "License must be at least 1 character")
                    .max(255, "License must be at most 255 characters")
                    .required("License is required"),
}); 