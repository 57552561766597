export enum SyncRequestKind {
    Licenses = "Licenses",
    Products = "Products",
    Inventory = "Inventory",
    Package = "Package"
}
export interface JoinGroupRequest {
    groupName: string;
}
export interface JoinGroupResponse {
    message: string;
}

export interface HubMethodCallback {
    methodName: string;
    callback: (...args: any[]) => void;
}


export interface SyncRequestComplete {
    correlationId: string;
    kind: SyncRequestKind;
    userMessage: string;
    success: boolean;
}

export enum HubMethodNames {
    SyncComplete = "SyncComplete",
    GroupAck = "GroupAck",
    JoinGroup = "JoinGroup",
    LeaveGroup = "LeaveGroup"
}

export enum GroupNames {
    SyncUpdates = "marketplace-sync-updates"
}