import { ActionReducerMapBuilder, CaseReducer, Draft, PayloadAction } from "@reduxjs/toolkit";
import { ActionStatus, ActionWithResult } from "../../../features/actionStatus/actionStatus";
import { EntityActionStatusOption } from "../action/asyncActionOptions";
import { IReducerFactory } from "../interfaces";
import { FulfilledPayload, PendingPayload, RejectedPayload, SagaActions } from "./types";

export interface ISagaWithProgressReducerFactory<TSliceState, TArg, TResult> {
    build(): IReducerFactory<TSliceState>;    
    withActionStatus(actionStatusSelector: (state: Draft<TSliceState>, arg: TArg) => ActionStatus): ISagaWithProgressReducerFactory<TSliceState, TArg, TResult>;
    withActionStatusResult(actionStatusSelector: (state: Draft<TSliceState>) => ActionWithResult<TResult>) : ISagaWithProgressReducerFactory<TSliceState, TArg, TResult>;

    withEntityActionStatus(actionStatusOptions: EntityActionStatusOption<TSliceState, TArg>): ISagaWithProgressReducerFactory<TSliceState, TArg, TResult>;
    
    onPending(handler: CaseReducer<TSliceState, PayloadAction<PendingPayload<TArg>>>): ISagaWithProgressReducerFactory<TSliceState, TArg, TResult>;
    onRejected(handler: CaseReducer<TSliceState, PayloadAction<RejectedPayload<TArg>>>): ISagaWithProgressReducerFactory<TSliceState, TArg, TResult>;
    onFulfilled(handler: CaseReducer<TSliceState, PayloadAction<FulfilledPayload<TArg, TResult>>>): ISagaWithProgressReducerFactory<TSliceState, TArg, TResult>;
};

export class SagaWithProgressReducerFactory<TSliceState, TArg, TResult> implements ISagaWithProgressReducerFactory<TSliceState, TArg, TResult> {

    private reducerFactory: IReducerFactory<TSliceState>;
    private builder: ActionReducerMapBuilder<TSliceState>;
    private sagaActions: SagaActions<TArg, TResult>;    

    private onPendingHandlers: CaseReducer<TSliceState, { payload: PendingPayload<TArg>; type: string; }>[];
    private onRejectedHandlers: CaseReducer<TSliceState, { payload: RejectedPayload<TArg>; type: string; }>[];
    private onFulfilledHandlers: CaseReducer<TSliceState, { payload: FulfilledPayload<TArg, TResult>; type: string; }>[];    

    constructor(
        reducerFactory: IReducerFactory<TSliceState>,
        builder: ActionReducerMapBuilder<TSliceState>,
        sagaActions: SagaActions<TArg, TResult>) {

        this.reducerFactory = reducerFactory;
        this.builder = builder;
        this.sagaActions = sagaActions;       
        this.onPendingHandlers = []; 
        this.onRejectedHandlers = [];
        this.onFulfilledHandlers = [];        
    }

    build = () : IReducerFactory<TSliceState> => {

        this.builder.addCase(this.sagaActions.pending, (state, action) => {
            this.onPendingHandlers.forEach(handler => handler(state, action));
        });

        this.builder.addCase(this.sagaActions.rejected, (state, action) => {
            this.onRejectedHandlers.forEach(handler => handler(state, action));
        });
        
        this.builder.addCase(this.sagaActions.fulfilled, (state, action) => {
            this.onFulfilledHandlers.forEach(handler => handler(state, action));
        });

        return this.reducerFactory;
    }

    withActionStatus = (actionStatusSelector: (state: Draft<TSliceState>, arg: TArg) => ActionStatus) : ISagaWithProgressReducerFactory<TSliceState, TArg, TResult> => {
        this.onPendingHandlers.push((state, action) => {
            const actionStatus = actionStatusSelector(state, action.payload.arg);
            actionStatus.isExecuting = true;
            actionStatus.errorContent = null;            
        });
        this.onRejectedHandlers.push((state, action) => {
            const actionStatus = actionStatusSelector(state, action.payload.arg);
            actionStatus.isExecuting = false;            
            actionStatus.errorContent = action.payload.errorResult;
            actionStatus.hasExecuted = true;
        });
        this.onFulfilledHandlers.push((state, action) => {
            const actionStatus = actionStatusSelector(state, action.payload.arg);    
            actionStatus.isExecuting = false;          
            actionStatus.errorContent = null;
            actionStatus.hasExecuted = true;
        });
       
        return this;
    }

    withEntityActionStatus = ({ adapter, adapterStateSelector, getId }: EntityActionStatusOption<TSliceState, TArg>): ISagaWithProgressReducerFactory<TSliceState, TArg, TResult> => {
        this.onPendingHandlers.push((state, action) => {
            const adapterState = adapterStateSelector(state);
            const entityId = getId(state, action.payload.arg);

            adapter.upsertOne(adapterState, {
                id: entityId,
                errorContent: null,
                isExecuting: true,
                hasExecuted: false
            });
        });
        this.onRejectedHandlers.push((state, action) => {
            const adapterState = adapterStateSelector(state);
            const entityId = getId(state, action.payload.arg);

            adapter.upsertOne(adapterState, {
                id: entityId,
                errorContent: action.payload.errorResult ?? null,
                isExecuting: false,
                hasExecuted: true
            });

        });
        this.onFulfilledHandlers.push((state, action) => {
            const adapterState = adapterStateSelector(state);
            const entityId = getId(state, action.payload.arg);

            adapter.upsertOne(adapterState, {
                id: entityId,
                errorContent: null,
                isExecuting: false,
                hasExecuted: true
            });            
        });

        return this;
    }

    withActionStatusResult = (actionStatusSelector: (state: Draft<TSliceState>) => ActionWithResult<TResult>) : ISagaWithProgressReducerFactory<TSliceState, TArg, TResult> => {
        this.withActionStatus(actionStatusSelector);
        this.onFulfilledHandlers.push((state, action) => {
            const actionStatus = actionStatusSelector(state);            
            actionStatus.result = action.payload.result;
        });
        return this;
    }

    onPending = (handler: CaseReducer<TSliceState, { payload: PendingPayload<TArg>; type: string; }>): ISagaWithProgressReducerFactory<TSliceState, TArg, TResult> => {
        this.onPendingHandlers.push(handler);
        return this;
    }
    onRejected = (handler: CaseReducer<TSliceState, { payload: RejectedPayload<TArg>; type: string; }>): ISagaWithProgressReducerFactory<TSliceState, TArg, TResult> => {        
        this.onRejectedHandlers.push(handler);
        return this;
    }
    onFulfilled = (handler: CaseReducer<TSliceState, { payload: FulfilledPayload<TArg, TResult>; type: string; }>): ISagaWithProgressReducerFactory<TSliceState, TArg, TResult> => {
        this.onFulfilledHandlers.push(handler);        
        return this;
    }
}