import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { InventoryImagesState } from './types';

export const createInitialState = (): InventoryImagesState => {
    return {
        imageLoadState: CreateActionStatusInitialState(),
        images: [],
        videos: [],
        inventoryId: undefined
    };
};

export const initialState: InventoryImagesState = createInitialState();