import { Load2Result, parallelLoad } from "../../../../../../utils/promiseHelpers";
import { EntityImage } from "../../../../../commonModels/entityImage";
import { EntityVideo } from "../../../../../commonModels/entityVideo";
import { inventoryVideoAdapter } from "../../state";
import { actionFactory } from "../../types";

export const {

    loadInventoryMedia,
    loadInventoryMediaReducer,
    loadInventoryMediaSagaFactory

} = actionFactory
    .withMappedName("loadInventoryMedia")
    .createAsyncAction<number, Load2Result<EntityImage[], EntityVideo[]>>({
        actionStatusSelector: state => state.mediaLoadState,
        asyncCall: (arg, context) => {
            return parallelLoad(
                () => context.marketplaceApiClient.inventoryManagement.getInventoryImages(arg),
                () => context.marketplaceApiClient.inventoryManagement.getInventoryVideos(arg),
            );
        },
        onFulfilled: (state, action) => {
            state.editImages = action.payload.result.result1;
            inventoryVideoAdapter.upsertMany(state.editVideos, action.payload.result.result2);
        }
    });