import { PrimeIcons } from "primereact/api";
import { AutoComplete, AutoCompleteChangeParams, AutoCompleteCompleteMethodParams } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { FileUpload, FileUploadSelectParams, FileUploadUploadParams } from "primereact/fileupload";
import { useRef, useState } from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { cancelEditTermsOfService } from "../store/featureActions/cancelEditTermsOfService";
import { completeEditTermsOfService } from "../store/featureActions/completeEditTermsOfService";
import { searchStates } from "../store/featureActions/searchStates";
import { setState } from "../store/featureActions/setState";
import { setUploadTermsDocument } from "../store/featureActions/setUploadTermsDocument";
import { getSaveState } from "../store/selectors/saveState";
import { getEditedState, getStateSearchResults } from "../store/selectors/states";
import { getTermsOfServiceById } from "../store/selectors/termsOfService";
import { getDocumentPreview } from "../store/selectors/uploadTermsDocument";

export interface TermsOfServiceEditorProps {
    termsOfServiceId: number | null;
}

export const TermsOfServiceEditor: React.FC<TermsOfServiceEditorProps> = ({ termsOfServiceId }) => {

    const dispatch = useDispatch();

    const documentUploadRef = useRef<FileUpload>(null);

    const termsOfService = useAppSelector(state => getTermsOfServiceById(state, termsOfServiceId));
    const stateSearchResults = useAppSelector(getStateSearchResults);
    const saveState = useAppSelector(getSaveState);
    const documentPreview = useAppSelector(getDocumentPreview);
    const editedState = useAppSelector(getEditedState);

    const [pdfPageCount, setPdfPageCount] = useState<number>(0);
    function onDocumentLoadSuccess(args: { numPages: number }) {
        setPdfPageCount(args.numPages);
    }

    const onSearchStates = (searchText: AutoCompleteCompleteMethodParams) => {
        dispatch(searchStates(searchText.query));
    }

    const onSubmit = () => {
        dispatch(completeEditTermsOfService());
    };

    const onCancel = () => {
        dispatch(cancelEditTermsOfService());
    };    

    const onSelectTermsDocument = async (e: FileUploadSelectParams) => {
        if (documentPreview !== undefined) {
            URL.revokeObjectURL(documentPreview);
        }

        const fileUrl = URL.createObjectURL(e.files[0]);
        dispatch(setUploadTermsDocument(fileUrl));

        documentUploadRef.current?.clear();
    }
 
    const onTemplateUpload = (_: FileUploadUploadParams) => {
    }

    const stateChanged = (e: AutoCompleteChangeParams) => {
        dispatch(setState(e.value));
    }

    return <div style={{ height: "80vh", maxHeight: "80vh", width: "50vw" }}>

        <div className="p-fluid">

            <div className="p-field">
                <label>State</label>

                <AutoComplete
                    dropdown
                    forceSelection
                    name="state"
                    value={editedState}
                    suggestions={stateSearchResults}
                    completeMethod={onSearchStates}
                    onChange={stateChanged}
                //onBlur={props.field.onBlur}
                />

            </div>

            <label>Terms of Service</label>
            <div className="p-col">
                <FileUpload
                    ref={documentUploadRef}
                    //auto={true}                
                    name="tosUpload"
                    url="./upload"
                    mode={"basic"}
                    multiple={false}
                    accept="application/pdf"
                    maxFileSize={10000000}
                    customUpload={true}
                    onUpload={onTemplateUpload}
                    onSelect={onSelectTermsDocument}
                />
            </div>

            <div className="p-shadow-2 p-mb-2" style={{ height: `calc(80vh - 200px)`, maxHeight: `calc(80vh - 200px)`, overflowY: "scroll", }}>
                <Document
                    file={documentPreview ?? termsOfService.termsAndConditions}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {
                        Array.from(new Array(pdfPageCount),
                            (_, index) => <Page key={index + 1} pageNumber={index + 1} width={800} />
                        )
                    }

                </Document>
            </div>

        </div>

        <div>
            <Button type="button" onClick={onSubmit} label="Save" style={{ float: "right" }} icon={PrimeIcons.SAVE} loading={saveState.isExecuting} />
            <Button className="p-mr-2" type="button" onClick={onCancel} label="Cancel" style={{ float: "right" }} icon={PrimeIcons.TIMES} />
        </div>

    </div>
}