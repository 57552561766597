import { CreateActionStatusInitialState } from '../../../actionStatus/actionStatus';
import { ContactVendorState } from './types';

export const createInitialState = (): ContactVendorState => {
    return {        
        loadingState: CreateActionStatusInitialState(),      
        vendor: null ,
        showVendor: false
    };
};

export const initialState: ContactVendorState = createInitialState();