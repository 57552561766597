import { EntityVideo } from "../../../../../commonModels/entityVideo";
import { inventoryVideoAdapter } from "../../state";
import { actionFactory } from "../../types";

export const {

    deleteInventoryVideo,
    deleteInventoryVideoReducer,
    deleteInventoryVideoSagaFactory

} = actionFactory
    .withMappedName("deleteInventoryVideo")
    .createAsyncAction<EntityVideo, Response>({
        actionStatusSelector: state => state.mediaLoadState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.inventoryManagement.deleteInventoryVideo(arg);
        },
        onFulfilled: (state, action) => {
            inventoryVideoAdapter.removeOne(state.editVideos, action.payload.arg.id);
        }
    });