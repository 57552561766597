import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { actionFactory, InventoryImagesState } from "../types";


export const showMediaForInventory = actionFactory.createAppAction<number>("showMediaForInventory");

export function showMediaForInventoryReducers(reducerFactory: IReducerFactory<InventoryImagesState>): IReducerFactory<InventoryImagesState> {
    return reducerFactory
        .forAction(showMediaForInventory)
        .addHandler((state, action) => {
            state.inventoryId = action.payload;
        })
        .build();
}