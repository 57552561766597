import { Card } from "primereact/card";
import React from "react";
import { useDispatch } from "react-redux";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { EntityImage } from "../../../commonModels/entityImage";
import { EntityVideo } from "../../../commonModels/entityVideo";
import { EntityMediaDialog } from "../../general/entityMediaEditing/entityMediaDialog";
import { loadMenuAssignments } from "../store/featureActions/loadMenuAssignments";
import { loadProducts } from "../store/featureActions/loadProducts";
import { loadPriceModels } from "../store/featureActions/priceModels/loadPriceModels";
import { addVideoBegin, addVideoCancel, addVideoComplete } from "../store/featureActions/productImages/addVideo";
import { completeEditProductImages } from "../store/featureActions/productImages/completeEditProductImages";
import { deleteProductImage } from "../store/featureActions/productImages/deleteProductImage";
import { deleteProductVideo } from "../store/featureActions/productImages/deleteProductVideo";
import { loadProductMedia } from "../store/featureActions/productImages/loadProductMedia";
import { updateImageDetails } from "../store/featureActions/productImages/updateImageDetails";
import { uploadNewProductImage } from "../store/featureActions/productImages/uploadNewProductImage";
import { upsertProductVideo } from "../store/featureActions/productImages/upsertProductVideo";
import { getAddVideoModel, getAddVideoState, getAddVideoVisible, getEditImagesProduct, getEditMediaProductName, getProductMedia, getMediaLoadState, getMenuThumbnailImageId } from "../store/selectors/productImage";
import { resetState } from "../store/slice";
import { ImageDetailsUpdate } from "../store/types";
import { BulkAssignCategoriesDialog } from "./bulkAssignCategoriesDialog";
import { BulkAssignPricingModelDialog } from "./bulkAssignPricingModelDialog";
import { BulkClearCategoriesDialog } from "./bulkClearCategoriesDialog";
import { EditProductDialog } from "./editProductDialog";
import { ProductCategoryAssignmentDialog } from "./productCategoryAssignmentDialog";
import { ProductTable } from "./productTable";

export const ProductManagementDashboard: React.FC = () => {

    const dispatch = useDispatch();
    
    useMountEffect(() => {
        dispatch(loadProducts());
        dispatch(loadMenuAssignments());
        dispatch(loadPriceModels());

        return () => {
            dispatch(resetState());
        }
    });
    
    const onSaveImage = (update: ImageDetailsUpdate) => {
        dispatch(updateImageDetails(update));
    }

    const onDeleteImage = (image: EntityImage) => {
        dispatch(deleteProductImage(image));
    }

    const onUploadImage = (entityId: number, localFileUrl: string ) => {
        dispatch(uploadNewProductImage({ productId: entityId, localFileUrl: localFileUrl }));
    }
    
    const onHideMediaEditor = () => {
        dispatch(completeEditProductImages());
    };

    const loadMedia = (entityId: number) => {
        dispatch(loadProductMedia(entityId));
    }

    const onAddVideoComplete = (value: EntityVideo) => {
        dispatch(addVideoComplete(value));
    }

    const onAddVideoCancel = () => {
        dispatch(addVideoCancel());
    }
    
    const onSaveVideo = (value: EntityVideo) => {
        dispatch(upsertProductVideo(value));        
    };

    const onDeleteVideo = (value: EntityVideo) => {
        dispatch(deleteProductVideo(value));
    }
    
    const showAddVideoDialog = (inventoryId: number) => {
        dispatch(addVideoBegin({
            embedUrl: "https://",
            id: 0,
            owningEntityId: inventoryId,
            tenantId: "",
            title: "New Video"
        }));
    }
    
    return <div >
        <EditProductDialog />

        <EntityMediaDialog
            addVideoProps={{
                onSave: onAddVideoComplete,
                onCancel: onAddVideoCancel,
                statusSelector: getAddVideoState,
                valueSelector: getAddVideoModel,
                visibleSelector: getAddVideoVisible
            }}
            imageActions={{
                onSave: onSaveImage,
                onDelete: onDeleteImage
            }}
            videoActions={{
                onSave: onSaveVideo,
                onDelete: onDeleteVideo
            }}            
            thumbnailSelector={getMenuThumbnailImageId}
            mediaSelector={getProductMedia}
            mediaLoadingSelector={getMediaLoadState}
            uploadImage={onUploadImage}
            showAddVideoDialog={showAddVideoDialog}
            loadMedia={loadMedia}
            getEntity={getEditImagesProduct}
            getEntityName={getEditMediaProductName}
            onHide={onHideMediaEditor}
        />

        <BulkAssignCategoriesDialog />

        <BulkClearCategoriesDialog />

        <BulkAssignPricingModelDialog />
        
        <ProductCategoryAssignmentDialog />

        <Card 
            className="full-height-content-panel" 
            title="Item Management" 
            //subTitle="Manage your METRC items and control which items's are available in the marketplace"
        >
            {/* <ProductSyncStatus /> */}
            <ProductTable />
        </Card>
    </div>
}