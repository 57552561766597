import React from "react";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { DateField } from "../../../../components/formik/dateField";
import { FormikModalEditor } from "../../../../components/formik/modalEditor";
import { bulkSetReadyDateCancel, bulkSetReadyDateComplete } from "../store/featureActions/bulkSetReadyDate";
import { getReadyDateAssignmentState, getReadyDateAssignmentValue, getReadyDateAssignmentVisible } from "../store/selectors/bulkReadyDateAssignment";
import { ReadyDateAssignment } from "../store/types";

export const AssignReadyDateDialog: React.FC = () => {

    const dispatch = useDispatch();

    const cancelEditing = () => {
        dispatch(bulkSetReadyDateCancel());
    }

    const completeEditing = (value: ReadyDateAssignment) => {
        dispatch(bulkSetReadyDateComplete(value));
    }

    return <FormikModalEditor<ReadyDateAssignment>
        statusSelector={getReadyDateAssignmentState}
        visibleSelector={getReadyDateAssignmentVisible}
        valueSelector={getReadyDateAssignmentValue}
        onCancel={cancelEditing}
        onSave={completeEditing}        
        title="Assign Ready Date"
        // className="remove-inv-quality-assessment-dialog"
        // contentClassName="remove-inv-quality-assessment-dialog-content"
        // headerClassName="remove-inv-quality-assessment-dialog-header"
    >
        <FlexColumnContainer padding="20px">
            <DateField<ReadyDateAssignment>
                defaultLabel="Ready Date"
                fieldName="readyDate"
                labelStyle={{ marginRight: "20px" }}
                showButtonBar={true}
            />
        </FlexColumnContainer>

    </FormikModalEditor>
}