import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { BulkExcludeAction } from "../types";
import { getSelectedInventoryIds } from "./bulkEdit";

export const getShowBulkExcludeConfirmation = (state: RootState) => state.inventoryManagement.bulkExcludeState.showConfirmation;

export const getBulkExcludeSaveState = (state: RootState) => state.inventoryManagement.bulkExcludeState.savingState;

export const getBulkExcludeAction = (state: RootState) => state.inventoryManagement.bulkExcludeState.action;

export const getActionTitle = (state: RootState) => state.inventoryManagement.bulkExcludeState.action === BulkExcludeAction.Exclude
    ? "Bulk Remove From Menus"
    : "Bulk Remove Menu Exclusions";

export const getActionDescription = createSelector(
    [
        getBulkExcludeAction,
        getSelectedInventoryIds
    ],
    (action, selectedItems) => {
        if (action === BulkExcludeAction.Exclude) {
            return `${selectedItems.length} items will be excluded from menus`;
        }
        else {
            return `${selectedItems.length} items will have their menu exclusion removed`;
        }
    });
