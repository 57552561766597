export const dragAndDropTypes = {
	Category: 'category',
    MenuSection: 'menu-section',
}

export interface DropCollectPayload {
    isOver: boolean,
    canDrop: boolean
}

export interface DragCollectPayload<T> {
    draggedItem: T
    isDragging: boolean,
    canDrag: boolean
}