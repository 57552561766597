import { IReducerFactory } from "../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../store/rootSaga";
import { messageFromError } from "../../../errorHandling/errorMessageHelper";
import { actionFactory, UserAuthState } from "../types";


export const silentRenew = actionFactory.createAppAction("silentRenew");

const sagaActions = actionFactory.createSagaWithProgressActions<void, void>(silentRenew.type);

export function silentRenewReducers(reducerFactory: IReducerFactory<UserAuthState>): IReducerFactory<UserAuthState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .onRejected((state, action) => {
            state.ErrorMessage = messageFromError(action.payload.errorResult);
        })
        .build();
}

async function login(_: void, context: SagaAppContext): Promise<void> {
    return context.authService.completeSilentRenew()
}

export function createSilentRenewSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: login,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: silentRenew.type
    });
};