import clsx from "clsx";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { FlexRowContainer } from "../../../../components/containers/flexRowContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { isValue } from "../../../../utils/valueHelper";
import { evaluateTestAssessment } from "../store/featureActions/evaluateTestAssessment";
import { getMetricKindsForTest, getTestEvaluationResult } from "../store/selectors/editing";
import { TestMetricEditor } from "./testMetricEditor";

export const TestAssessmentEditor: React.FC = () => {

    const dispatch = useDispatch();
    const metricKinds = useAppSelector(getMetricKindsForTest);
    const testResult = useAppSelector(getTestEvaluationResult);

    const onEvalutate = () => {
        dispatch(evaluateTestAssessment());
    }

    return <FlexRowContainer className="quality-assessment-test-editor">

        <FlexColumnContainer className="quality-assessment-test-entry-list">
            {
                metricKinds.map(kind => <TestMetricEditor key={kind.id} metricKindId={kind.id} />)
            }
        </FlexColumnContainer>

        <FlexColumnContainer className="quality-assessment-test-results">
            <Button className={clsx("quality-assessment-test-eval-button")} onClick={onEvalutate} >Evaluate</Button>

            {
                isValue(testResult) &&
                <div className="quality-assessment-test-result-value">{testResult.name}</div>
            }
        </FlexColumnContainer>

    </FlexRowContainer>
}