import { ActionFactory } from "../../../../store/factory/action/actionFactory";
import { ActionStatus } from "../../../actionStatus/actionStatus";
import { Vendor } from "../../../menu/productDetails/store/types";

export const sliceName: string = "contact-vendor";

export const actionFactory = new ActionFactory<ContactVendorState>(sliceName);

export interface ContactVendorState {
    loadingState: ActionStatus;  
    vendor: Vendor | null; 
    showVendor: boolean;
    
         
}