import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { Product } from "../../../../inventorySetup/productManagement/store/types";
import { actionFactory, CategoryManagementState } from "../types";

export const setSelectedAvailableProducts = actionFactory.createAppAction<Product[]>("setSelectedAvailableProducts");

export function setSelectedAvailableProductsReducers(reducerFactory: IReducerFactory<CategoryManagementState>): IReducerFactory<CategoryManagementState> {
    return reducerFactory        
        .forAction(setSelectedAvailableProducts)
        .addHandler((state, action) => {
            state.categoryProductAssignment.selectedAvailableProducts = action.payload;
        })
        .build();
}