import * as yup from 'yup';
import { MarketplaceProfile } from '../manageProfile/store/types';

export const marketPlaceProfileValidationSchema: yup.SchemaOf<Partial<MarketplaceProfile>> = yup.object().shape({

    firstName: yup.string()
        .min(1, "First name must be at least 1 character")
        .max(255, "First name must be at most 255 characters")
        .required("First name is required")
        .nullable(),

    lastName: yup.string()
        .min(1, "Last name must be at least 1 character")
        .max(255, "Last name must be at most 255 characters")
        .required("Last name is required")
        .nullable(),


    // Optional as not required at all
    phoneNumber: yup.string().optional().nullable(),

    // Optional during editing as they are managed by the server
    username: yup.string().optional(),
    id: yup.number().optional(),
    tenantId: yup.string().optional(),
    email: yup.string().optional(),

});