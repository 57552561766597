import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/rootReducer";
import { firstOrUndefined } from "../../../../../utils/arrayHelpers";
import { createIdentityMap } from "../../../../../utils/identity";
import { isValue } from "../../../../../utils/valueHelper";
import { TemplateMetric } from "../../../../qualityGradeTemplates/models/templateMetric";
import { QualityAssessmentValue } from "../../../models/qualityAssessmentValue";
import { qualityAssessmentAdapter, qualityAssessmentValueAdapter } from "../state";
import { getAllTemplateMetrics, getQualityGradeValueMap, getQualityMetricKinds } from "./data";

const assessmentSelectors = qualityAssessmentAdapter.getSelectors((state: RootState) => state.qualityAssessmentEditor.assessments);
export const getQualityAssessmentById = assessmentSelectors.selectById;

const assessmentValueSelectors = qualityAssessmentValueAdapter.getSelectors((state: RootState) => state.qualityAssessmentEditor.assessmentValues);
export const getQualityAssessmentValuesForAssessment = createSelector(
    [
        assessmentValueSelectors.selectAll
    ],
    (allValues) => {
        return (assessmentId: number) => {
            return allValues
                .filter(value => value.assessmentId === assessmentId);
        }
    }
);


export const getAssessmentValuesForAssessment = createSelector(
    [
        assessmentValueSelectors.selectAll,
        getQualityMetricKinds,
        getAllTemplateMetrics,
        getQualityAssessmentById
    ],
    (currentValues, metricKinds, templateMetrics, assessment) => {

        const assessmentValues: Partial<QualityAssessmentValue>[] = [];

        if (!isValue(assessment)) return assessmentValues;
        if (metricKinds.length === 0) return assessmentValues;
        if (templateMetrics.length === 0) return assessmentValues;

        const loggedValues = currentValues
            .filter(value => value.assessmentId === assessment.id);

        // add the currently persisted assessment values
        assessmentValues.push(...loggedValues);

        // Add an entry for each metric kind that is not yet evaluated
        const loggedMetricKinds = loggedValues
            .map(value => value.metricKindId);
        const loggedMetricKindMap = createIdentityMap(loggedMetricKinds, id => id);

        const templateMetricKindMap = createIdentityMap(
            templateMetrics
                .filter(value => value.assessmentTemplateId === assessment.assessmentTemplateId),
            templateMetric => templateMetric.metricKindId);

        const metricKindsForTemplate = metricKinds
            .filter(value => isValue(templateMetricKindMap[value.id]));

        const metricKindsNotEvaluated = metricKindsForTemplate
            .filter(kind => !isValue(loggedMetricKindMap[kind.id]))

        assessmentValues
            .push(...metricKindsNotEvaluated.map(kind => ({
                assessmentId: assessment.id,
                id: 0,
                measuredBy: "",                
                metricKindId: kind.id,
                metricValueId: undefined
            })));

        assessmentValues
            .sort((left, right) => {
                if (!isValue(left.metricKindId)) return -1;
                if (!isValue(right.metricKindId)) return 1;

                const leftTemplateMetric = templateMetricKindMap[left.metricKindId];
                const rightTemplateMetric = templateMetricKindMap[right.metricKindId];
                return leftTemplateMetric.sequence - rightTemplateMetric.sequence;
            });;

        return assessmentValues;
    }
);

export const getQualityAssessmentValueForMetricKind = createSelector(
    [
        assessmentValueSelectors.selectAll
    ],
    (allValues) => {
        return (assessmentId: number, metricKindId: number) => {
            return firstOrUndefined(allValues, value => value.metricKindId === metricKindId && value.assessmentId === assessmentId);
        }
    }
);

export const getMetricKindsForAssessment = createSelector(
    [
        getQualityMetricKinds,
        getAllTemplateMetrics,
        getQualityAssessmentById
    ],
    (metricKinds, templateMetrics, assessment) => {

        if (!isValue(assessment)) return [];

        const templateMetricKindMap = createIdentityMap(
            templateMetrics
                .filter(value => value.assessmentTemplateId === assessment.assessmentTemplateId),
            templateMetric => templateMetric.metricKindId);

        return metricKinds
            .filter(value => isValue(templateMetricKindMap[value.id]))
            .sort((left, right) => {
                const leftTemplateMetric = templateMetricKindMap[left.id];
                const rightTemplateMetric = templateMetricKindMap[right.id];
                return leftTemplateMetric.sequence - rightTemplateMetric.sequence;
            });
    }
);

export const getKindToTemplateMetricMapForAssesment = createSelector(
    [
        getAllTemplateMetrics,
        getQualityAssessmentById

    ],
    (templateMetrics, assessment) => {
        const kindToTemplateMetricMap: { [kindId: number]: TemplateMetric } = {};
        if (!isValue(assessment)) return kindToTemplateMetricMap;

        templateMetrics
            .filter(templateMetric => templateMetric.assessmentTemplateId === assessment.assessmentTemplateId)
            .forEach(templateMetric => {
                kindToTemplateMetricMap[templateMetric.metricKindId] = templateMetric;
            });

        return kindToTemplateMetricMap;
    }
);

export const getFinalGradeForAssessment = createSelector(
    [
        getQualityAssessmentById,
        getQualityGradeValueMap
    ],
    (assessment, gradeValueMap) => {

        const finalGradeValueId = assessment?.calculatedFinalGradeId;
        if (!isValue(finalGradeValueId)) return undefined;
        return gradeValueMap[finalGradeValueId];

    }
);


export const getIsAssessmentComplete = createSelector(
    [
        getQualityAssessmentById
    ],
    (assessment) => {
        return isValue(assessment) && isValue(assessment.completedOn);
    }
);


export const getQualityAssessmentModalEditState = (state: RootState) => state.qualityAssessmentEditor.modalEditState;
export const getQualityAssessmentModalEditValue = (state: RootState) => state.qualityAssessmentEditor.modalEditState.editedValue;
export const getQualityAssessmentModalEditVisible = (state: RootState) => state.qualityAssessmentEditor.modalEditState.isVisible;
