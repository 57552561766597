import React, { CSSProperties } from "react"

export interface FullyCenteredProps {
    className?: string;
    inlineStyle?: CSSProperties;
    fullScreen?: boolean;
}

export const FullyCentered = ({ inlineStyle, children, fullScreen = false }: React.PropsWithChildren<FullyCenteredProps>) => {

    return <div        
        style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            width: fullScreen ? "100vw" : "100%",
            height: fullScreen ? "100vh" : "100%",
            ...inlineStyle
        }}
    >
        {children}
    </div>
}
