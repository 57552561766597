import { ErrorDisplayType } from "../../../../../store/factory/sagaWithProgress/types";
import { ImageDto, ImageKind } from "../../../../commonModels/imageTypes";
import { actionFactory } from "../types";

export const {

    loadVendorLogo,
    loadVendorLogoReducer,
    loadVendorLogoSagaFactory

} = actionFactory
    .withMappedName("loadVendorLogo")
    .createAsyncAction<{ companyId: number }, ImageDto>({
        actionStatusSelector: state => state.vendorLogoLoadingState,
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.company.getImage(arg.companyId, ImageKind.Logo);
        },
        onFulfilled: (state, action) => {
            state.vendorLogo = action.payload.result;
        },
        debounceInMilliseconds: 250,
        errorDisplay: ErrorDisplayType.None
    });