import { CompanyType, CompanyProfile } from "../../../../company/profile/store/types";
import { actionFactory } from "../types";

export const {

    loadSliceData,
    loadSliceDataReducer,
    loadSliceDataSagaFactory

} = actionFactory
    .withMappedName("loadSliceData")
    .createAsyncAction<{ companyType: CompanyType }, CompanyProfile[]>({
        actionStatusSelector: state => state.loadingState,
        asyncCall: (arg, context) => {
            switch (arg.companyType) {
                case CompanyType.Distributor:
                    return context.marketplaceApiClient.company.getDistributorList({ nameSearch: "" });
                case CompanyType.Supplier:
                    return context.marketplaceApiClient.company.getSupplierList({ nameSearch: "" });
                case CompanyType.Retail:
                    return context.marketplaceApiClient.company.getRetailList({ nameSearch: "" });
                default:
                    throw new Error(`Company Type ${arg.companyType} is not supported`);                    
            }

        },
        onFulfilled: (state, action) => {
            state.data = action.payload.result;
        }
    });