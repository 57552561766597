import { inventoryNotesAdapter } from "../../state";
import { actionFactory, InventoryNote } from "../../types";

export const {

    upsertInventoryNote,
    upsertInventoryNoteReducer,
    upsertInventoryNoteSagaFactory

} = actionFactory
    .withMappedName("upsertInventoryNote")
    .createAsyncAction<InventoryNote, InventoryNote>({
        actionStatusSelector: state => state.inventoryNoteSaveStatus,
        onPending: (state, _action) => {
            state.inventoryNoteSaveStatus.hasExecuted = false;
        },
        asyncCall: (arg, context) => {
            return context.marketplaceApiClient.inventoryManagement.upsertInventoryNote(arg);
        },
        onFulfilled: (state, action) => {
            inventoryNotesAdapter.upsertOne(state.editInventoryNotes, action.payload.result);            
        }
    });