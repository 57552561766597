import { DataTablePageParams } from 'primereact/datatable';
import { DataView, DataViewLayoutType } from 'primereact/dataview';
import { useDispatch } from 'react-redux';
import { PopUpMenuButton } from '../../../../components/popMenuButton';
import { useAppSelector } from '../../../../store/configureStore';
import { TypedMenuItem } from '../../../../utils/table/typedMenuItem';
import { isValue } from '../../../../utils/valueHelper';
import { showVendorContactDetails } from '../../../dialogs/vendor/store/featureActions/loadVendor';
import { liveMenuRoute } from '../../../navigation/routes';
import { fetchNextPage } from '../store/featureActions/fetchNextPage';
import { setViewPageState } from '../store/featureActions/setViewPageState';
import { getInventorySearchResults, getNoMoreResults } from '../store/selectors/searchResults';
import { getViewPageState } from '../store/selectors/viewPageState';
import { InventorySearchResult } from '../store/types';
import './searchResults.css';

export const ListResultsView: React.FC = () => {

    const dispatch = useDispatch();
    const searchResults = useAppSelector(getInventorySearchResults);
    const noMoreResults = useAppSelector(getNoMoreResults);
    const pageParameters = useAppSelector(getViewPageState);
   
    const onViewCustomerMenu = (inventorySearchResult: InventorySearchResult) => {
        if (!isValue(inventorySearchResult.vendorUrlName)) return;
        window.open(liveMenuRoute.createFullUrl(inventorySearchResult.vendorUrlName));
    }

    const onContactVendor = (inventorySearchResult: InventorySearchResult) => {
        if (!isValue(inventorySearchResult.vendorUrlName)) return;
        dispatch(showVendorContactDetails(inventorySearchResult.vendorUrlName));
    }

    const onPageResults = (e: DataTablePageParams) => {
        dispatch(setViewPageState(e));
        if (noMoreResults) return;
        if (!isValue(e.page)) return;
        
        // If the user navigates to the last view page, load the next data page 
        // to see if there are more results
        if ((e.page + 1) * e.rows >= searchResults.length) {
            dispatch(fetchNextPage());
        }
    }

    const menuItems: TypedMenuItem<InventorySearchResult>[] = [        
        {
            label: "View Vendor Menu",
            command: onViewCustomerMenu,
            disabled: (result) => !isValue(result.vendorUrlName)
        },
        {
            label: "Contact Vendor",
            command: onContactVendor,
            disabled: (result) => !isValue(result.vendorUrlName)
        }
    ];

    const itemTemplate = (item: InventorySearchResult, _: DataViewLayoutType): React.ReactNode => {


        return <div className='p-shadow-8 p-mb-2' style={{ width: "100vw" }}>
            <div className='p-d-flex p-jc-left p-ai-center p-m-2'>
                <PopUpMenuButton
                    id="inventory-listview-popup-menu"
                    menuItems={menuItems}
                    value={item}
                />
                <div className='p-ml-2 inventory-list-view-title'>
                    {item.name}
                </div>
            </div>

            <div className='p-d-flex p-jc-left p-ai-center p-m-2'>

                <div className='p-ml-2'>
                    {item.strainName}
                </div>
            </div>

            <div className='p-d-flex p-jc-left p-ai-center p-m-2'>
                <div className='p-ml-2 p-text-nowrap'>
                    {`${item.quantity?.toLocaleString(undefined, { maximumFractionDigits: 2 })} ${item.unitOfMeasure}`}
                </div>
                <div className='p-ml-4 p-text-nowrap'>
                    {item?.thcPercentage && `${item?.thcPercentage?.toLocaleString(undefined, { style: "percent", maximumFractionDigits: 2 })} THC`}
                </div>
            </div>

            <div className='p-d-flex p-jc-left p-ai-center p-m-2 inventory-list-view-vendor'>
                <div className='p-ml-2'>
                    {item.vendorName}
                </div>

            </div>

        </div>
    };

    return <DataView
        dataKey="id"
        itemTemplate={itemTemplate}
        layout="list"
        value={searchResults.slice(pageParameters.first, pageParameters.first + pageParameters.rows)}
        paginator
        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
        paginatorPosition="both"
        pageLinkSize={3}
        first={pageParameters.first}
        rows={pageParameters.rows}
        onPage={onPageResults}
        totalRecords={searchResults.length}
        emptyMessage={searchResults.length > 0 && noMoreResults ? "There are no more records associated with this search." : undefined}
        rowsPerPageOptions={[10, 20, 50]}
        lazy
        alwaysShowPaginator={false}
    />
}
