import React from "react";
import { useDispatch } from "react-redux";
import { useMountEffect } from "../../../../utils/useEffectHelper";
import { useMarketplaceHub } from "../../../signalR/hubConnection/hubContext";
import { GroupNames, HubMethodNames, SyncRequestComplete, SyncRequestKind } from "../../../signalR/hubConnection/store/types";
import { SyncStatus } from "../../../../components/syncStatus/syncStatus";
import { loadInventory } from "../store/featureActions/loadInventory";
import { loadLatestSyncRequest } from "../store/featureActions/sync/loadLatestSyncRequest";
import { requestInventorySync } from "../store/featureActions/sync/requestInventorySync";
import { getLatestSyncRequest } from "../store/selectors/syncRequest";

export const InventorySyncStatus: React.FC = () => {

    const dispatch = useDispatch();
    const marketplaceHub = useMarketplaceHub();
    
    const onSyncComplete = (message: SyncRequestComplete) => {       
        if(message.kind === SyncRequestKind.Package || message.kind === SyncRequestKind.Inventory){            
            dispatch(loadInventory());
        }
    }
    
    useMountEffect(() => {        
        marketplaceHub.joinGroup(GroupNames.SyncUpdates);
        marketplaceHub.registerCallback(HubMethodNames.SyncComplete, onSyncComplete);
        
        return () => {
            marketplaceHub.unregisterCallback(HubMethodNames.SyncComplete, onSyncComplete);
            marketplaceHub.leaveGroup(GroupNames.SyncUpdates);
        }
    });

    const onSyncInventory = () => {
        dispatch(requestInventorySync());
    }

    return <SyncStatus        
        getLatestSyncRequestSelector={getLatestSyncRequest}
        loadLastestSyncRequestAction={loadLatestSyncRequest}
        requestNewSync={onSyncInventory}
    />
}