import { isValue } from "../../../../../utils/valueHelper";
import { loadInventory } from "../../../../inventorySetup/inventoryManagement/store/featureActions/loadInventory";
import { loadInventoryByIds } from "../../../../inventorySetup/inventoryManagement/store/featureActions/loadInventoryByIds";
import { actionFactory, ModalAssessmentEditArgs } from "../types";

export const {

    editQualityAssessmentBegin,
    editQualityAssessmentCancel,
    editQualityAssessmentComplete,
    editQualityAssessmentReducers,
    editQualityAssessmentSagaFactory

} = actionFactory
    .withMappedName("editQualityAssessment")
    .createModal<ModalAssessmentEditArgs, ModalAssessmentEditArgs, void>({
        modelFactory: (arg, _state) => {
            return arg;
        },
        selector: state => state.modalEditState,
        onCompleteEdit: (_value, _context) => {
            return Promise.resolve();
        },
        onFulfilled: (_state, _action) => { },
        successCallback: (arg, _result, context) => {
            if (arg.loadInventoryOnComplete) {
                if (isValue(arg.loadInventoryIds) && arg.loadInventoryIds.length > 0) {
                    context.dispatch(loadInventoryByIds({ inventoryIds: arg.loadInventoryIds }));
                }
                else {
                    context.dispatch(loadInventory());
                }

            }
        }
    });