import clsx from "clsx";
import { Field, FieldProps, FormikContextType, useFormikContext } from "formik";
import { MultiSelect, MultiSelectChangeParams } from 'primereact/multiselect';
import { CSSProperties } from 'react';
import { TypedKeyOf } from '../../utils/typeHelpers';
import { FlexColumnContainer } from "../containers/flexColumnContainer";
import { FormikEditorClassStyles } from "./classNameAttributes";

//const Option = Select.Option;

export interface MultiSelectFieldProps<TModel extends {}, TField, TSelect extends {}> extends FormikEditorClassStyles {
    defaultLabel: string;
    fieldName: string & TypedKeyOf<TModel, TField[]>;
    getValue: (model: TModel) => TField[];
    optionLabel: string & TypedKeyOf<TSelect, string>;
    optionValue: string & TypedKeyOf<TSelect, TField>;
    availableValues: TSelect[];

    containerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    placeHolder?: string;
    submitOnBlur?: boolean;
    disabled?: boolean;
    showErrors?: boolean;
    selectionLimit?: number;
}


export const MultiSelectField = <TModel extends {}, TField, TSelect extends {}>({
    defaultLabel,
    fieldName,
    availableValues,
    getValue,
    optionLabel,
    optionValue,
    containerStyle,
    labelStyle,
    inputStyle,
    placeHolder,
    submitOnBlur = false,
    disabled,
    showErrors = true,
    containerClassName,
    inputClassName,
    labelClassName,
    selectionLimit
}: MultiSelectFieldProps<TModel, TField, TSelect>) => {

    const formikProps: FormikContextType<TModel> = useFormikContext<TModel>();
    const labelText = defaultLabel;

    return <FlexColumnContainer>
        <div style={containerStyle} className={containerClassName}>

            <label className={labelClassName} style={labelStyle} htmlFor={fieldName} >{labelText}</label>
            <Field
                name={fieldName}
            >
                {
                    (props: FieldProps<TModel>) =>

                        <MultiSelect
                            placeholder={placeHolder}
                            style={inputStyle}
                            className={clsx(inputClassName, { "p-invalid": !!formikProps.errors[fieldName] })}
                            onChange={(e: MultiSelectChangeParams) => {
                                formikProps.setFieldValue(props.field.name, e.value);

                                
                                if (submitOnBlur) {                                    

                                    formikProps
                                        .validateForm()
                                        .then(_errors => {
                                            formikProps.submitForm();
                                        })
                                }
                            }}
                           
                            value={getValue(formikProps.values)}
                            options={availableValues}
                            optionLabel={optionLabel}
                            optionValue={optionValue}
                            disabled={disabled}
                            selectionLimit={selectionLimit}
                        />
                }
            </Field>
        </div>

        {
            showErrors &&
            <div>
                <small>{formikProps.errors[fieldName]}</small>
            </div>
        }
    </FlexColumnContainer>
}