import { StrictEffect } from "redux-saga/effects";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { changeGradeKindSagaFactory } from "../featureActions/changeGradeKind";
import { deleteTemplateConstraintSagaFactory } from "../featureActions/deleteTemplateConstraint";
import { deleteTemplateConstraintNodeSagaFactory } from "../featureActions/deleteTemplateConstraintNode";
import { evaluateTestAssessmentSagaFactory } from "../featureActions/evaluateTestAssessment";
import { loadSliceDataSagaFactory } from "../featureActions/loadSliceData";
import { loadTemplateConstraintNodesSagaFactory } from "../featureActions/loadTemplateConstraintNodes";
import { publishAssessmentTemplateSagaFactory } from "../featureActions/publishAssessmentTemplate";
import { removeMetricFromTemplateSagaFactory } from "../featureActions/removeMetricFromTemplate";
import { resequenceTemplateMetricSagaFactory } from "../featureActions/resequenceTemplateMetric";
import { saveAssessmentTemplateSagaFactory } from "../featureActions/saveAssessmentTemplate";
import { selectMetricsSagaFactory } from "../featureActions/selectMetrics";
import { updateTemplateConstraintSagaFactory } from "../featureActions/updateTemplateConstraint";
import { updateTemplateMetricSagaFactory } from "../featureActions/updateTemplateMetric";
import { upsertTemplateConstraintNodeSagaFactory } from "../featureActions/upsertTemplateConstraintNode";

// Register this method in the root saga
export function createQualityTemplateEditorSagas(context: SagaAppContext): (() => Generator<StrictEffect<any, any>, any, any>)[] {
    return [        
        loadSliceDataSagaFactory(context),
        saveAssessmentTemplateSagaFactory(context),
        selectMetricsSagaFactory(context),
        updateTemplateMetricSagaFactory(context),
        removeMetricFromTemplateSagaFactory(context),
        resequenceTemplateMetricSagaFactory(context),
        updateTemplateConstraintSagaFactory(context),
        changeGradeKindSagaFactory(context),
        publishAssessmentTemplateSagaFactory(context),
        evaluateTestAssessmentSagaFactory(context),
        deleteTemplateConstraintSagaFactory(context),
        upsertTemplateConstraintNodeSagaFactory(context),
        deleteTemplateConstraintNodeSagaFactory(context),
        loadTemplateConstraintNodesSagaFactory(context)
    ]
}