import ApiClient from "../../apiCommunication/apiClient";
import { UserSetupInfo } from "../../initialSetup/workflow/store/types";
import { Menu } from "../../menu/editor/store/types";
import { MarketplaceProfile } from "../manageProfile/store/types";

export default class UserProfile {

    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    getMarketplaceProfile = (): Promise<MarketplaceProfile> => {
        return this
            .apiClient
            .executeGet<MarketplaceProfile>(`UserProfile`);
    };

    saveMarketplaceProfile = (profile: MarketplaceProfile): Promise<MarketplaceProfile> => {
        return this
            .apiClient
            .executePostWithResult<MarketplaceProfile>(`UserProfile`, profile);
    };

    getUserSetupInfo = (): Promise<UserSetupInfo> => {
        return this
            .apiClient
            .executeGet<UserSetupInfo>(`UserProfile/UserSetupInfo`);
    };

    getFavoriteMenus = (): Promise<Menu[]> => {
        return this
            .apiClient
            .executeGet<Menu[]>(`UserProfile/menus/favorites`);
    };

    getMenuInvites = (): Promise<Menu[]> => {
        return this
            .apiClient
            .executeGet<Menu[]>(`UserProfile/menus/invites`);
    };

    addMenuToFavorites = (menuId: number): Promise<Response> => {
        return this
            .apiClient
            .executePost(`UserProfile/menus/favorites/${menuId}`);
    };

    removeMenuFromFavorites = (menuId: number): Promise<Response> => {
        return this
            .apiClient
            .executeDelete(`UserProfile/menus/favorites/${menuId}`);
    };
    
    
}