import { TabPanel, TabView, TabViewTabChangeParams } from 'primereact/tabview';
import { useState } from 'react';
import { useParams } from "react-router-dom";
import { isValue } from "../../../../utils/valueHelper";
import { BannerSetup } from './bannerSetup';
import { LogoSetup } from './logoSetup';
import { MenuDetails } from './menuDetails';
import { MenuLayout } from "./menuLayout";
import { ThumbnailSetup } from './thumbnailSetup';

export interface MenuPageParams {
    id: string | undefined
};

enum MenuTabIndexes {
    Layout,
    Invites
}
export const MenuTabView: React.FC = () => {

    const [activeIndex, setActiveIndex] = useState<MenuTabIndexes>(MenuTabIndexes.Layout);
    const params = useParams<MenuPageParams>();
    const menuId: number | undefined = isValue(params.id) ? parseInt(params.id) : undefined;    

    const tabChanged = (e: TabViewTabChangeParams) => {
        setActiveIndex(e.index);
    }

    return <TabView
        activeIndex={activeIndex}
        renderActiveOnly={false}
        onTabChange={tabChanged}
    >
        <TabPanel header="General">
            <MenuDetails />
        </TabPanel>

        <TabPanel header="Layout">
            <MenuLayout menuId={menuId} />
        </TabPanel>

        <TabPanel
            header="Logo"
        >
            <LogoSetup />
        </TabPanel>

        <TabPanel
            header="Banner"
        >
            <BannerSetup />
        </TabPanel>

        <TabPanel
            header="Default Thumbnail"
        >
            <ThumbnailSetup />
        </TabPanel>

    </TabView>
}