import { Column, ColumnProps } from "primereact/column";
import { isValue } from "../valueHelper";

export const mapPropsToColumns = (c: ColumnProps) => {
    return <Column key={c.columnKey ?? c.field} {...c} />
}

export const mapColumns = (columnProps: ColumnProps[]) => {
    return columnProps
        .map(c => <Column key={c.columnKey ?? c.field} {...c} />)
}

export const mapSelectedColumns = (columnProps: ColumnProps[], selectedColumnNames: string[]) => {
    return columnProps
        .filter(col => selectedColumnNames
            .includes(
                col.header as string) ||
                isValue(col.selectionMode) // always keep the column for selecting rows if present
        )
        .map(c => <Column key={c.columnKey ?? c.field} {...c} />)
}