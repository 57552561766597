import { PrimeIcons } from "primereact/api";
import { Column, ColumnProps } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable, DataTableRowReorderParams } from "primereact/datatable";
import { Message } from "primereact/message";
import { useDispatch } from "react-redux";
import { FlexColumnContainer } from "../../../../components/containers/flexColumnContainer";
import { useAppSelector } from "../../../../store/configureStore";
import { createActionColumnProps } from "../../../../utils/table/actionColumn";
import { createCalculatedValueColumnProps } from "../../../../utils/table/calculatedValueColumn";
import { createNumberColumnProps } from "../../../../utils/table/numberColumn";
import { createRowReorderColumnProps } from "../../../../utils/table/rowReorderColumn";
import { createSwitchColumnProps } from "../../../../utils/table/switchColumn";
import { TemplateMetric } from "../../models/templateMetric";
import { removeMetricFromTemplate } from "../store/featureActions/removeMetricFromTemplate";
import { resequenceTemplateMetric } from "../store/featureActions/resequenceTemplateMetric";
import { updateTemplateMetric } from "../store/featureActions/updateTemplateMetric";
import { getQualityMetricKindMap, getTemplateMetrics } from "../store/selectors/data";
import { getIsEditable } from "../store/selectors/editing";
import { getTemplateMetricSaveState } from "../store/selectors/loadingState";

export const TemplateMetricTable: React.FC = () => {

    const dispatch = useDispatch();
    const templateMetrics = useAppSelector(getTemplateMetrics);
    const metricKindMap = useAppSelector(getQualityMetricKindMap);
    const saveState = useAppSelector(getTemplateMetricSaveState);
    const isEditable = useAppSelector(getIsEditable);

    const onDelete = (templateMetric: TemplateMetric) => {
        confirmDialog({
            className: "publish-template-confirm-dialog",
            rejectLabel: "Keep this metric",
            acceptLabel: "Delete this metric",
            header: "Delete Quality Metric",
            message: <FlexColumnContainer gap="20px" padding="10px">
                <Message severity="warn" text="Deleting a metric will also delete any constraints using that metric."></Message>
                <div>Are you sure you want to delete this metric?</div>
            </FlexColumnContainer>,
            accept: () => {
                dispatch(removeMetricFromTemplate(templateMetric));
            }
        });
    }


    const columns: ColumnProps[] = [
        createRowReorderColumnProps({ style: { width: 30 } }),        
        createCalculatedValueColumnProps<TemplateMetric>({
            columnKey: "metricName",
            header: "Metric",
            style: { width: 280 },
            getValueFn: (templateMetric) => {
                const metricKind = metricKindMap[templateMetric.metricKindId];
                return metricKind?.name ?? "";
            }
        }),
        createSwitchColumnProps<TemplateMetric>({
            field: "isRequired",
            header: "Required",
            style: { width: 120 },
            disabledFn: () => saveState.isExecuting || !isEditable,
            onChange: (value, checked) => {
                dispatch(updateTemplateMetric({ ...value, isRequired: checked }));
            }
        }),
        createNumberColumnProps<TemplateMetric>({
            header: "Sequence",
            field: "sequence",
            style: { width: 90 },
            sortable: false
        }),
        createActionColumnProps<TemplateMetric>({
            header: "",
            columnKey: "remove-action",
            style: { width: 70 },
            disabled: !isEditable,
            actionName: "",
            actionHandler: onDelete,
            buttonClassName: "template-metric-table-button",
            buttonIcon: PrimeIcons.TRASH
        }),
    ];

    const onRowReorder = (e: DataTableRowReorderParams) => {

        // Odd behavior from prime react DataTable. When dragging down, the index appears to be one based.
        // but when dragging up, the index appears to be zero based. -\o/-
        const dropIndex: number = e.dropIndex > e.dragIndex ? e.dropIndex - 1 : e.dropIndex;

        const dragTarget = templateMetrics[e.dragIndex];
        const dropTarget = templateMetrics[dropIndex];

        dispatch(resequenceTemplateMetric({
            newSequenceValue: dropTarget.sequence,
            entityId: dragTarget.id
        }));
    }

    return <DataTable
        size="small"
        disabled={!isEditable || saveState.isExecuting}
        dataKey="id"
        value={templateMetrics}

        scrollable
        scrollDirection="both"
        scrollHeight='calc(100vh - 300px)'

        paginator
        rows={20}
        rowsPerPageOptions={[10, 20, 50]}
        alwaysShowPaginator={false}

        onRowReorder={onRowReorder}
    >
        {columns.map(c => <Column key={c.columnKey ?? c.field} {...c} />)}
    </DataTable>
}