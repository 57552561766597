import clsx from "clsx";
import { ColumnProps } from "primereact/column";
import { DataTable, DataTableColReorderParams, DataTableColumnResizeEndParams, DataTableProps } from "primereact/datatable";
import { CSSProperties, useRef } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/configureStore";
import { createIdentityMap, createValueMap } from "../../../../utils/identity";
import { mapColumns } from "../../../../utils/table/mapPropsToColumns";
import { useEffectWithPartialDependencies } from "../../../../utils/useEffectHelper";
import { isValue } from "../../../../utils/valueHelper";
import { initializeView } from "../store/featureActions/initializeView";
import { remountTable } from "../store/featureActions/remountTable";
import { requireTableRemount } from "../store/featureActions/requireTableRemount";
import { updateTableState } from "../store/featureActions/updateTableState";
import { getColumnId } from "../store/logic/columnHelper";
import { getColumnStateForView, getRemountStateForView, getUserTableSettingsLoaded } from "../store/selectors";
import { ColumnState, DataTableViewState, TableState } from "../store/types";
import { ControlPanel } from "./controlPanel";
import './index.css';
 
type AllowedProps = Omit<DataTableProps,
    "filterDisplay" |
    "tableClassName" |
    "ref" |
    "onColReorder" |
    "onColumnResizeEnd" |
    "columnResizeMode" |
    "resizableColumns" |
    "reorderableColumns" |
    "responsiveLayout " |
    "breakpoint" |
    "scrollable" |
    "scrollDirection" |
    "autoLayout" |
    "stripedRows" |
    "customSaveState" |
    "stateKey" |
    "stateStorage">


export interface CustomTableProps extends AllowedProps {
    viewId: string;
    columns: ColumnProps[];
    containerStyle?: CSSProperties;
}

export const CustomTable: React.FC<CustomTableProps> = ({ viewId, containerStyle, columns, ...rest }) => {
    const dispatch = useDispatch();
    const userTableSettingsLoaded = useAppSelector(getUserTableSettingsLoaded);
    const tableColumnState = useAppSelector(state => getColumnStateForView(state, viewId));
    const columnStateMap = createIdentityMap(tableColumnState, columnState => columnState.columnId);
    const columnStateIndexMap = createValueMap(tableColumnState, columnState => columnState.columnId, (_columnState, index) => index);
    const remountState = useAppSelector(state => getRemountStateForView(state, viewId));

    const tableRef = useRef<DataTable>(null);

    useEffectWithPartialDependencies(() => {
        if (userTableSettingsLoaded) {
            dispatch(initializeView({
                viewId: viewId,
                columns: columns
            }));
        }

    }, [userTableSettingsLoaded, viewId, columns]);


    const onSaveTableState = (state: DataTableViewState) => {
        if (remountState.remountInProgress) return;
        dispatch(updateTableState({
            viewId: viewId,
            flagSentinel: false,
            update: { additionalSettings: state }
        }));
    }

    const onSelectedSettingsChanged = (tableState: TableState) => {
        if (isValue(tableRef.current) && isValue(tableState.additionalSettings)) {
            tableRef.current.restoreTableState(tableState.additionalSettings);
        }
    }

    const onSelectedSettingsFocus = () => {
        if (remountState.tableRemountNeeded) {
            dispatch(remountTable({ viewId: viewId }));
        }
    }

    const onColumnReorder = (e: DataTableColReorderParams) => {

        const visibleColumns = tableColumnState.filter(col => col.isVisible);
        const draggedColumn = visibleColumns[e.dragIndex];
        const droppedColumn = visibleColumns[e.dropIndex];

        const dropColumnTotalIndex = columnStateIndexMap[droppedColumn.columnId];

        const withoutDraggedColumn: ColumnState[] = tableColumnState.filter(col => col.columnId !== draggedColumn.columnId);

        const newColumns = withoutDraggedColumn
            .slice(0, dropColumnTotalIndex)
            .concat([draggedColumn])
            .concat(withoutDraggedColumn.slice(dropColumnTotalIndex));


        dispatch(updateTableState({
            viewId: viewId,
            flagSentinel: false,
            update: { columnState: newColumns }
        }));

        dispatch(requireTableRemount({ viewId: viewId }))
    }

    const onColumnResize = (e: DataTableColumnResizeEndParams) => {

        const colId = getColumnId(e.column.props);

        const newColumnsState = tableColumnState.map<ColumnState>(columnState => columnState.columnId === colId
            ? {
                columnId: columnState.columnId,
                width: e.element.clientWidth,
                isVisible: columnState.isVisible,
                header: columnState.header
            }
            : columnState);

        dispatch(updateTableState({
            viewId: viewId,
            flagSentinel: false,
            update: { columnState: newColumnsState }
        }));
        dispatch(requireTableRemount({ viewId: viewId }))
    }

    const mappedColumns = columns
        .filter(col => {
            const colState = columnStateMap[getColumnId(col)];
            return colState?.isVisible ?? true;
        })
        .sort((left, right) => {
            const leftId = getColumnId(left);
            const rightId = getColumnId(right);
            if (!isValue(leftId) && !isValue(rightId)) return 0;
            if (!isValue(leftId) && isValue(rightId)) return -1;
            if (isValue(leftId) && !isValue(rightId)) return 1;
            if (isValue(leftId) && isValue(rightId)) {
                const leftIndex = columnStateIndexMap[leftId];
                const rightIndex = columnStateIndexMap[rightId];
                return leftIndex - rightIndex;
            }
            return 0;
        })
        .map<ColumnProps>((col) => {
            const columnState = columnStateMap[getColumnId(col)];
            return {
                ...col,
                style: { ...col.style, width: columnState?.width },
                className: clsx(col.className, "custom-table-column")
            };
        });



    return <div style={containerStyle}>
        <ControlPanel
            viewId={viewId}
            onSelectedSettingsChanges={onSelectedSettingsChanged}
            onSelectedSettingsFocused={onSelectedSettingsFocus}
        />

        {
            remountState.showTable &&
            <DataTable
                {...rest}
                filterDisplay="menu"
                tableClassName="custom-table"
                ref={tableRef}
                onColReorder={onColumnReorder}
                onColumnResizeEnd={onColumnResize}
                columnResizeMode="expand"
                resizableColumns={true}
                reorderableColumns={true}
                responsiveLayout="stack"
                breakpoint="1px"
                scrollDirection="both"
                autoLayout={false}
                stripedRows={true}
                customSaveState={onSaveTableState}
                stateKey="custom-table"
                stateStorage="custom"
            >
                {mapColumns(mappedColumns)}
            </DataTable>
        }

    </div>
} 