import { IReducerFactory } from "../../../../../store/factory/interfaces";
import { createExecuteWithProgressSaga } from "../../../../../store/factory/sagaWithProgress/executeWithProgress";
import { SagaAppContext } from "../../../../../store/rootSaga";
import { actionFactory, MenuDashboardState } from "../types";

export const deleteMenu = actionFactory.createAppAction<number>("deleteMenu");

const sagaActions = actionFactory.createSagaWithProgressActions<number, Response>(deleteMenu.type);

export function deleteMenuReducers(reducerFactory: IReducerFactory<MenuDashboardState>): IReducerFactory<MenuDashboardState> {
    return reducerFactory
        .forSagaWithProgress(sagaActions)
        .withActionStatus(state => state.loadingState)
        .onFulfilled((state, action) => {
            state.data = state.data.filter(menu => menu.id !== action.payload.arg);
        })
        .build();
}

async function performLoad(arg: number, _context: SagaAppContext): Promise<Response> {
    return _context.marketplaceApiClient.menuAdmin.deleteMenu(arg);
}

export function createDeleteMenuSaga(context: SagaAppContext) {

    return createExecuteWithProgressSaga({
        options: {
            action: performLoad,
            appContext: context,
            notificationActions: sagaActions
        },
        actionPattern: deleteMenu.type,
        debounceInMilliseconds: 250
    });
};